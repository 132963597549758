import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import Switch from 'react-switch'
import { Switch } from '@mui/material';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { gettoken } from '../../Localstorage/Store';
import Loadercomp from '../../components/Loadercomp';

const Editproduct = () => {
  const nvg = useNavigate()
  const {id} = useParams()
  // const [data, setData] = useState({});
  const gettokinval = gettoken();
  const [name, setname] = useState('')
  const [logo, setlogo] = useState('')
  const [logohelp, setlogohelp] = useState('')
  const [status, setstatus] = useState(false)
  const [updateresponse, setupdateresponse] = useState({})
  const [errorcls, seterrorcls] = useState({})
  const [loading, setloading] = useState(true);
  const [srtloader, setsrtloader] = useState(true);


//fetch user record ----> start

useEffect(()=>{
  if(gettokinval.staff == false){
    nvg('/dashboard')
  }
})


async function fetchData() {

  const config = {
    headers: {
      Authorization: `Bearer ${gettokinval.access}`,
    },
  };
  let url = `${process.env.REACT_APP_API_URL}api/product/${id}/`;
  const response = await axios.get(url, config);
  // console.log(response)
  // setData(response.data);
  setname(response.data.product_name)
  setlogohelp(response.data.logo)
  setlogo(null)
  if(response.data.status == "Active"){
    setstatus(true)
  }else{
    setstatus(false)
  }
  setsrtloader(false)
}
  useEffect(() => {
    
    fetchData();
  }, []);
//fetch user record ----> end


//change logo image when select new image ----> start
  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        setlogohelp(reader.result);
      });

      reader.readAsDataURL(file);
    }
  };
//change logo image when select new image ----> end


//update user record ----> start
async function submitform(e){
  e.preventDefault();
  setloading(true)
  const formdata = new FormData()
  formdata.append('product_name',name)
  if(logo != null){
    formdata.append('logo',logo)
  }
  if(status == true){
    formdata.append('status',true)
  }else{
    formdata.append('status',false)
  }

 try {
  const config = {
    headers: {
      Authorization: `Bearer ${gettokinval.access}`,
    },
  };
  let url = `${process.env.REACT_APP_API_URL}api/product/${id}/`;
  const response = await axios.patch(url, formdata, config);
    setupdateresponse(response);
    setloading(false)
 } catch (error) {
  setloading(false)
  seterrorcls(error.response.data)
 }
 
}
//update user record ----> end

const resetform = () =>{
fetchData()
}

  return (
    <div style={{width:'100%',minHeight:'100vh'}}>
      {/* <Header /> */}
      <div className="dashboardcontent px-2">
      {/* <Breadcup name={'Products'} /> */}
      {/* <div className="container py-4">
        
      </div> */}
      <div className="container-fuild px-2 desgin1 ">
      <div className="row bg-white ">
            {/* <div className="col-12 py-3 customtext23" style={{background: 'hsla(210, 85%, 32%, 0.2)',color:'#0C5398'}}>Edit Product</div> */}
            <div className="col-lg-12 d-flex justify-content-between py-2" style={{ background: 'hsla(210, 85%, 32%, 0.2)', color: '#0C5398' }}>
              <p className="m-0 customfont" >Edit Product</p>
              <div className="addnew d-block mb-2">
                <button className="btn text-white closebtn"><NavLink to='/productlist/0' style={{ textDecoration: 'none', color: 'white' }}> x Close</NavLink></button>
              </div>
            </div>
        </div>
      </div>
      {srtloader == true ? <div className="container-fuild bg-white"> <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"70%"}}>
        <div className='px-2'> <Loadercomp size={100} /></div>
        </div></div> : <div className="container-fuild pb-4 pt-3 px-2 bg-white">
      <form onSubmit={submitform} id='addproduct'>
       <div className="row bg-white pb-4 round" style={{border:'1px solid #E0E0E0',margin:"10px 0px",borderRadius: '3px'}}>
        <div className="col-md-6 px-3 pt-md-5 pt-4">
          <div className="row">
            <div className="col-lg-3">
              <label htmlFor="" className="form-label">
              Name <span style={{color:'red'}}>*</span>
              </label>
            </div>
            <div className="col-lg-9">
              <input type="text" className="form-control"  placeholder='Username' value={name} onChange={(e) => {setname(e.target.value)}} required />
              {errorcls ? errorcls.product_name ? <span style={{color:'red'}}> {errorcls.product_name[0]} </span> : '' : ''}
            </div>
          </div>
        </div>
        <div className="col-md-6 px-3 pt-md-5 pt-4">
          <div className="row">
            <div className="col-lg-3">
              <label htmlFor="" className="form-label customw">Product Logo <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-9">
            <input type="file" className="form-control" id="customFile" onChange={(e) => {setlogo(e.target.files[0]);handleImageChange(e);}} />
            {errorcls ? errorcls.logo ? <span style={{color:'red'}}> {errorcls.logo[0]} </span> : '' : ''}
            </div>
          </div>
        </div>
        {loading == false ? <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"70%",paddingTop:"65px"}}>
        <div className='px-2'>{loading == false ?  <Loadercomp /> : ""}</div>
        </div> : ''}
        
        <div className="col-md-6 px-3 pt-md-5 pt-4">
          <div className="row">
            <div className="col-lg-3">
              <label htmlFor="" className="form-label">Status <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-9">
            {/* <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" /> */}
            {status == true ? <Switch 
            // handleDiameter={30}
            // uncheckedIcon={false}
            // checkedIcon={false} height={20}
            // width={48}
            // className="react-switch"
            // id="material-switch" boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            // activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
             value={status} name='status' onChange={(e) => {setstatus(!status)}} checked /> : <Switch

             value={status} name='status' onChange={(e) => {setstatus(!status)}} />}
            </div>
          </div>
        </div>
        <div className="col-md-6 px-3 pt-md-5 pt-4">
          <div className="row">
            <div className="col-4">
              <label htmlFor="" className="form-label">Logo Image <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-8">
              <img src={logohelp} alt="adfg" width={50} />
            </div>
          </div>
        </div>
     <div className="col-12">
      <div className="row">
        <div className="col-12 py-4 px-4 d-flex justify-content-end" style={{gap:'4px'}}>
          <button type='button' onClick={()=>resetform()} className='btn4'>Cancel</button>
          <button type='submit' className='btn5'>Save</button>
        </div>
      </div>
     </div>
      </div>
      {updateresponse.status === 200 ? nvg('/productlist/2') : <div></div>}
      </form>
      </div>}
      </div>
    </div>
  )
}

export default Editproduct