import Header from '../../components/Header'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { gettoken } from '../../Localstorage/Store';
import Loadercomp from '../../components/Loadercomp';

const Locationdetail = () => {
    const {id} = useParams()
    const nvg = useNavigate()
    const [srtloader, setsrtloader] = useState(true);
   const[defaultv,setdefaultv] = useState('')
   const[lname,setLname] = useState('')
   const[lbranch,setLbranch] = useState('')
    const[lemail,setLemail] = useState('')
    const[lmob,setLmob] = useState('')
    const[ladd,setLadd] = useState('')
    const[lcountry,setLcountry] = useState('')
    const[lstate,setLstate] = useState('')
    const[lcity,setLcity] = useState('')
    const[lpincode,setLpincode] = useState('')
    const[comp,setcomp] = useState('')
    const gettokenvalue = gettoken()
    const [created_by, setcreated_by] = useState();
    const [created_at, setcreated_at] = useState();
    const [last_updated_by, setlast_updated_by] = useState();
    const [last_updated_at, setlast_updated_at] = useState();
    const[lat,setlat] = useState('')
    const[long,setlong] = useState('')



    useEffect(()=>{
        if(gettokenvalue.staff == false){
          nvg('/dashboard')
        }
      })
    useEffect(() => {
      async function fetchData() {
  
        const config = {
          headers: {
            Authorization: `Bearer ${gettokenvalue.access}`,
          },
        };
        let url = `${process.env.REACT_APP_API_URL}api/location/${id}/`;
        const response = await axios.get(url, config);
        setcomp(response.data.company)
        setLbranch(response.data.branch_name)
        setLemail(response.data.emailID)
        setLmob(response.data.mobile_no.substring(3))
        setLadd(response.data.address)
        setLcountry(response.data.country)
        setLstate(response.data.state)
        setLcity(response.data.city)
        setLpincode(response.data.pincode)
        setlong(response.data.longitude)
      setlat(response.data.latitude)
        setcreated_by(response.data.created_by)
        setcreated_at(response.data.created_at)
        setlast_updated_by(response.data.last_updated_by)
        setlast_updated_at(response.data.last_updated_at)
        setsrtloader(false)
      }
      fetchData();
    }, []);

    
    return (
        <div style={{ width: '100%' }}>
            {/* <Header /> */}
            <div className="dashboardcontent px-2">
                {/* <Breadcup name={'Location'} /> */}
                <div className="container-fuild desgin1 ">
                    <div className="row bg-white px-2 ">
                        {/* <div className="col-12 py-3 customtext23" style={{ background: 'hsla(210, 85%, 32%, 0.2)', color: '#0C5398' }}>Location Detail</div> */}
                        <div className="col-lg-12 d-flex justify-content-between py-2" style={{ background: 'hsla(210, 85%, 32%, 0.2)', color: '#0C5398' }}>
              <p className="m-0 customfont" > Agency Detail</p>
              <div className="addnew d-block mb-2">
                <button className="btn text-white closebtn"><NavLink to='/agencylist/0' style={{ textDecoration: 'none', color: 'white' }}> x Close</NavLink></button>
              </div>
            </div>
                    </div>
                </div>
                {srtloader == true ? <div className="container-fuild bg-white"> <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"100%"}}>
        <div className='px-2'> <Loadercomp size={100} /></div>
        </div></div> : <div className="container-fuild pb-4 pt-3 px-3 bg-white">
                    <div className="row bg-white pb-4 round" style={{ border: '1px solid #E0E0E0', margin: "10px 0px", borderRadius: '3px',position:'relative' }}>
                        {/* <div className="col-lg-6 px-4 pt-3">
                            <div className="row">
                                <div className="col-md-4 col-4">
                                    <label htmlFor="" className="form-label">
                                        Company
                                    </label>
                                </div>
                                <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
                                    <p className='customcolor'>{comp}</p>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-lg-6 px-4 pt-3">
                            <div className="row">
                                <div className="col-md-4 col-4">
                                    <label htmlFor="" className="form-label customw">Branch Name </label>
                                </div>
                                <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
                                    <p className='customcolor'>{lbranch}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 px-4 pt-3">
                            <div className="row">
                                <div className="col-md-4 col-4">
                                    <label htmlFor="" className="form-label">Email </label>
                                </div>
                                <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
                                    <p className='customcolor'>{lemail}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 px-4 pt-3">
                            <div className="row">
                                <div className="col-md-4 col-4">
                                    <label htmlFor="" className="form-label">Mobile No. </label>
                                </div>
                                <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
                                    <p className='customcolor'>{lmob}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 px-4 pt-3">
                            <div className="row">
                                <div className="col-md-4 col-4">
                                    <label htmlFor="" className="form-label">Latitude </label>
                                </div>
                                <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
                                    <p className='customcolor'>{lat}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 px-4 pt-3">
                            <div className="row">
                                <div className="col-md-4 col-4">
                                    <label htmlFor="" className="form-label">Longitude </label>
                                </div>
                                <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
                                    <p className='customcolor'>{long}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 px-4 pt-3">
                            <div className="row">
                                <div className="col-md-4 col-4">
                                    <label htmlFor="" className="form-label">Address </label>
                                </div>
                                <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
                                    <p className='customcolor'>{ladd}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 px-4 pt-3">
                            <div className="row">
                                <div className="col-md-4 col-4">
                                    <label htmlFor="" className="form-label">Country  </label>
                                </div>
                                <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
                                    <p className='customcolor'>{lcountry}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 px-4 pt-3">
                            <div className="row">
                                <div className="col-md-4 col-4">
                                    <label htmlFor="" className="form-label">State  </label>
                                </div>
                                <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
                                    <p className='customcolor'>{lstate}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 px-4 pt-3">
                            <div className="row">
                                <div className="col-md-4 col-4">
                                    <label htmlFor="" className="form-label">City </label>
                                </div>
                                <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
                                    <p className='customcolor'>{lcity}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 px-4 pt-3">
                            <div className="row">
                                <div className="col-md-4 col-4">
                                    <label htmlFor="" className="form-label">Pincode </label>
                                </div>
                                <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
                                    <p className='customcolor'>{lpincode}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 px-4 pt-3" >
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Created by</label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{created_by}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3" >
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Created Date & Time</label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{created_at}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3" >
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Last Modified by </label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{last_updated_by ? last_updated_by : "NOT MODIFIED"}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3" >
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Last Modified Date & Time </label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{last_updated_at ? last_updated_at : "NOT MODIFIED"}</p>
            </div>
          </div>
        </div>
                        {/* <div className="col-lg-6 px-4 pt-3">
                            <div className="row">
                                <div className="col-md-4 col-4">
                                    <label htmlFor="" className="form-label">Company Logo </label>
                                </div>
                                <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
                                    <img src={img1} alt="qwert" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 px-4 pt-3">
                            <div className="row">
                                <div className="col-md-4 col-4">
                                    <label htmlFor="" className="form-label">Status </label>
                                </div>
                                <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
                                    <Switch checked />
                                </div>
                            </div>
                        </div> */}
    
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default Locationdetail