import React, { useEffect } from 'react'
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import img3 from "../../assets/KYC Logo white-bg 2.jpg";
import { FaShoppingCart } from "react-icons/fa";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { ImLocation2 } from "react-icons/im";
import { FaEnvelope,FaFacebookF,FaInstagram,FaTwitter,FaLinkedinIn } from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import { BsQuestionLg } from "react-icons/bs";
import PhoneInput from 'react-phone-number-input'
import Select from "react-select";
import { useState } from 'react';
import csc from 'country-state-city';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { gettoken, removeToken } from '../../Localstorage/Store';
import Loadercomp from '../../components/Loadercomp';
import { useContext } from 'react';
import Balance from '../../context/Balance';
const Upgradeplanform = () => {
    const [planinfo, setplaninfo] = useState({});
    const [srtloader, setsrtloader] = useState(true);
    const [balno,setbalno] = useContext(Balance)
const {id} = useParams()
    const gettokinval = gettoken();



    const nvg = useNavigate()
// const navigatefeature = (num) =>{
// nvg('/',{state:{id:num}})
// }

const [Data,setData] = useState({})

const logoutevt = async () =>{
    removeToken()
    nvg('/login',{state:{id:700}})
          const formdata = new FormData()
          formdata.append('test',"test")
           try {
            
            const config = {
              headers: {
                Authorization: `Bearer ${gettokinval.access}`,
              },
            };
            let url = `${process.env.REACT_APP_API_URL}api/logout/`;
            const response = await axios.post(url, formdata, config);
            nvg('/login',{state:{id:7}})
        
           } catch (error) {
            // console.log({statusone:1,message:'Something Went Wrong Try again',error:error})
           }
        
        
        }

const submitform = async () =>{
  
    const formdata = new FormData()
    formdata.append('service_plan_id',planinfo.sub_id)
     try {
      
      const config = {
        headers: {
          Authorization: `Bearer ${gettokinval.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}api/subscriptions/${Data.id}/upgradePlan/`;
      const response = await axios.post(url, formdata, config);
console.log("super response", response.response)
if(response.data.remaining_days > 0){


const formdatathree = new FormData();
// if(idno == 1){
  formdatathree.append("amount", parseFloat(planinfo.total_amount));
  formdatathree.append("description", `Renew Subscription - ${gettokinval.id} - ${gettokinval.first_name} ${gettokinval.last_name}`);
let url = `${process.env.REACT_APP_API_URL}api/wallet/make_purchase/`;
const dedectresponse = await axios.post(
  url,
  formdatathree,
  config
);
console.log("make purchase", dedectresponse)
}
      logoutevt()
  
     } catch (error) {
      // console.log({statusone:1,message:'Something Went Wrong Try again',error:error})
     }
}

useEffect(() => {
    async function fetchData() {
        // setsrtloader(true)
        const config = {
            headers: {
          Authorization: `Bearer ${gettokinval.access}`,
        },
    };
    // https://backend.kycfirst.io/api/subscriptions/
    let url = `${process.env.REACT_APP_API_URL}api/subscriptions/`;
      const response = await axios.get(url, config);
      setData(response.data[0]);
      // setsrtloader(false)
    }
    fetchData();
    // console.log("dashboard bres",Data)
  },[]);


useEffect(() => {
  async function fetchData() {

    const config = {
      headers: {
        Authorization: `Bearer ${gettokinval.access}`,
        'Content-Type': 'application/json',
      },
    };
  let url = `${process.env.REACT_APP_API_URL}api/service-plans/${id}`;
    try {
      const response = await axios.get(url, config);
      setplaninfo(response.data);
      setsrtloader(false)
// console.log(response)


    } catch (error) {
      // setloading(false)
      // console.log(error)
    }
  }
  fetchData();

}, []);

  return (
    <div style={{margin:'0px',padding:'0px'}}>





<section className='superpadding'>

{srtloader == true ? <div className="container-fuild bg-white"> <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"86%"}}>
        <div className='px-2'> <Loadercomp size={100} /></div>
        </div></div> : <div className="container-fuild pb-4 pt-3 px-2 bg-white">
          <form autoComplete="off" onSubmit={submitform}>
            <div
              className="row bg-white pb-4 px-3 round"
              style={{
                border: "1px solid #E0E0E0",
                margin: "10px 0px",
                borderRadius: "3px",
                position:'relative'
              }}
            >

              
             <div className="col-md-12 px-2 pb-3 pt-4">
                <div className="row">
                  {/* <div className="col-lg-3">
                    <label htmlFor="" className="form-label"></label>
                  </div> */}
                  <div className="col-lg-7 d-flex justify-content-start">
                    <span style={{color:'rgba(12, 83, 152, 1)',fontSize:'24px',fontWeight:'600'}}>
                      {" "}
                     Upgrade Plan
                      {/* (<span style={{color:'black',fontSize:'27px'}}>Total=</span><span style={{color:'black',fontSize:'27px'}}>₹{planinfo.total_amount}</span>) */}
                    </span>
                  </div>


                  <div className="col-lg-5 d-flex justify-content-end align-items-center">
                    <NavLink to='/upgradeplan'>
                  <button type="button" className="btn5withoutradius" style={{height: "62%",
    background: "#0c5398"}}>
                 Back
                </button>
                </NavLink>
                  </div>
                </div>
              </div>
          



              {/* <div className="col-md-12 px-2 pt-4">
                <div className="row">
                  <div className="col-12">
                    <label htmlFor="" className="form-label" style={{color:'rgba(12, 83, 152, 1)',fontSize:'20px',fontWeight:'600'}}>
                    Subscription Details
                    </label>
                  </div>
                  <div className="col-12 outerunderline" style={{position:'relative',width:'98%',height:'3px',background:'grey',margin:'0 auto'}} >
    <div className="specialinderline"></div>
                 
                  </div>
                </div>
              </div> */}

{/* 
            
              <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor=""  className="form-label customw">
                     <span>Plan Name</span>  
                    </label>
                  </div>
                  <div className="col-lg-9 d-flex justify-content-center">
         <p>
{planinfo.sub_name}
         </p>
         
 
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label customw">
                      Duration {" "}
                    </label>
                  </div>
                  <div className="col-lg-9 d-flex justify-content-center">
                 <p>{planinfo.duration_days}</p>
             
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label customw">
                      Price {" "}
                    </label>
                  </div>
                  <div className="col-lg-9 d-flex justify-content-center">
                 <p>₹{planinfo.price}</p>
             
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label customw">
                    GST {" "}
                    </label>
                  </div>
                  <div className="col-lg-9 d-flex justify-content-center">
                 <p>{planinfo.gst_percentage} %</p>
             
                  </div>
                </div>
              </div>
              <div className="col-md-6 offset-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label customw">
                  Total Amount
                    </label>
                  </div>
                  <div className="col-lg-9 d-flex justify-content-center">
                 <p>₹{planinfo.total_amount}</p>
             
                  </div>
                </div>
              </div> */}




    {/* cart page start here */}

{/* 
<div className="col-md-12 px-2 pt-4">
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-5 mb-4">
                

<div className="owlwidth">
                <div className="single-pricing position-relative">
                
                  <div className="head-text" style={{padding:'0px',paddingBottom:parseInt(planinfo.discount) == 0 ? '20px' : '0px'}} >
                      <p style={{marginBottom:'0px'}}> <span className="subcarttitle" style={{background:planinfo.sub_name == 'BRONZE' ? '#CD7F32' : planinfo.sub_name == 'SILVER' ? '#A8A8A8' : planinfo.sub_name == 'GOLD' ? '#CBA135' : `linear-gradient(hsla(0, 0%, 35%, 1),hsla(0, 12%, 63%, 1),hsla(0, 0%, 71%, 1),hsla(0, 4%, 47%, 1),hsla(0, 0%, 100%, 1))`}}>{planinfo.sub_name}</span></p>
                  </div>
                  <div className="price-area" style={{display:parseInt(planinfo.discount) == 0 ? 'none' : ''}}>
                   <span className="duration" style={{display:parseInt(planinfo.discount) == 0 ? 'none' : ''}}> Rs.{parseInt(planinfo.price)}</span> +GST
                  </div>
                  <p style={{display:parseInt(planinfo.discount) == 0 ? 'none' : '',color:parseInt(planinfo.discount) == 0 ? 'transparent' : 'red',marginBottom:'0px',textAlign:'center'}}>Now at Just</p>
                  <div className="price-area p-0" style={{display:'flex',flexWrap:'wrap'}}>
                    <span className="normaltxt">Rs.</span>
                   <span className="duration23" style={{fontSize:'28px'}}> {planinfo.discounted_price}</span> <span className="normaltxt"> +GST</span>
                  </div>
                  <p style={{color:'white',marginBottom:'0px',textAlign:'center',fontSize:'30px'}}>{planinfo.sub_desc}</p>
                </div>
              </div>
                  </div>
                  <div className="col-xl-7 col-lg-6 col-md-5">
                  <h3 style={{color:'#282529',fontSize:'20px',fontWeight:'500'}}>Plan Name : <span>{planinfo.sub_name}</span></h3>
                  <h3 style={{color:'#282529',fontSize:'20px',fontWeight:'500'}}>Duration : <span>{planinfo.duration_days}</span></h3>
                 {parseInt(planinfo.discount) == 0 ? '' : <h3 style={{color:'#282529',fontSize:'20px',fontWeight:'500'}}>Discount : <span>{planinfo.discount}%</span></h3> } 
                  <h3 style={{color:'#282529',fontSize:'20px',fontWeight:'500'}}>GST : <span>{planinfo.gst_rate} %</span></h3>
                  <h3 style={{color:'#282529',fontSize:'20px',fontWeight:'500'}}>Price : <span>₹{planinfo.price}</span></h3>
                  <h3 style={{color:'#282529',fontSize:'20px',fontWeight:'500'}}> Total Amount : <span>₹{planinfo.total_amount}</span></h3>
                   
                  </div>
                </div>
              </div> */}

  
    {/* cart page start end */}








      
            
            <div
              className="row bg-white pb-4 px-3 round"
              style={{
                border: "1px solid #E0E0E0",
                margin: "10px 0px",
                borderRadius: "3px",
                position: "relative",
              }}
            >

             

              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label customw">
                      Email Address{" "}
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="email"
                      readOnly
value={gettokinval.email}
                      className="form-control"
                      // placeholder="Email"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label customw">
                      Mobile No.{" "}
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      readOnly
value={gettokinval.mobile}
                      className="form-control"
                      // placeholder="Mobile"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label customw">
                    Company  {" "}
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      readOnly
value={gettokinval.company_name}
                      className="form-control"
                      // placeholder="Email"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label customw">
                      Location{" "}
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      readOnly
value={gettokinval.branch_name}
                      className="form-control"
                      // placeholder="Email"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label customw">
                      Plan Name{" "}
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      readOnly
value={planinfo.sub_name}
                      className="form-control"
                      // placeholder="Email"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label customw">
                      Price{" "}
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="number"
                      readOnly
value={planinfo.price}
                      className="form-control"
                      // placeholder="Email"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label customw">
                      Price{" "}
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      readOnly
value={`${planinfo.gst_rate} %`}
                      className="form-control"
                      // placeholder="Email"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label customw">
                    Duration{" "}
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      readOnly
value={`${planinfo.duration_days} `}
                      className="form-control"
                      // placeholder="Email"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label customw">
                      Total Amount{" "}
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="number"
                      readOnly
value={planinfo.total_amount}
                      className="form-control"
                      // placeholder="Email"
                      required
                    />
                  </div>
                </div>
              </div>

    
<div className="col-8 pt-5">
  {/* {rechargesucc == true ? <div class="alert alert-success" role="alert">
 Recharge Successfully
</div> : ''}
  {rechargeerror == true ? <div class="alert alert-danger" role="alert">
 Something Went Wrong Try Again
</div> : ''} */}
</div>
              <div
                className="col-4 py-2 pt-5 px-2 d-flex justify-content-end"
                style={{ gap: "4px" }}
              >

{balno > parseFloat(planinfo.total_amount) ? <button type="button" onClick={()=>{submitform()}} className="btn5withoutradius">
Upgrade Subscription
                </button> : <button
                   className="btn5withoutradius"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal1450"
              >Upgrade Subscription</button> }
              
                {/* <button type="button" onClick={()=>{submitform()}} className="btn5withoutradius">
                  Renew Subscription
                </button> */}
      
              </div>
            </div>
            <div
        className="modal fade"
        id="exampleModal1450"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
    
          <BsQuestionLg className='question-logo' />
            {/* <span className="question-logo" style={{display:"flex",justifyContent:"center",alignItems:'center'}} ></span> */}
            {/* <i class="fa fa-exclamation" aria-hidden="true"></i> */}
            <div className="modal-header mod-line">
            </div>
            <div className="modal-body">
              <div className="row gy-3 mt-2">
                <h1 className="ccedit-h">Wallet Error</h1>
                <p className="ccedit-p">
                  Your Wallet Balance is Lower than plan . Please Recharge Your Wallet First
                </p>
              </div>
            </div>
            <div className="modal-footer mod-line m-auto">
            {/* <button type="button" className="btn closecancel" data-bs-dismiss="modal"
                aria-label="Close">Cancel</button> */}
 <NavLink to='/walletrecharge/1'>
              <button
 
                type="button"
                className="btn closebtn text-white"
                data-bs-dismiss="modal"
                // aria-label="Close"
              >
                Wallet Recharge
              </button>
                </NavLink>
            </div>
          </div>
        </div>
      </div>
      
      










            </div>








            <div
        className="modal fade"
        id="exampleModal1400"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
          <BsQuestionLg className='question-logo' />
            {/* <span className="question-logo" style={{display:"flex",justifyContent:"center",alignItems:'center'}} ></span> */}
            {/* <i class="fa fa-exclamation" aria-hidden="true"></i> */}
            <div className="modal-header mod-line">
            </div>
            <div className="modal-body">
              <div className="row gy-3 mt-2">
                <h1 className="ccedit-h">Wallet Error</h1>
                <p className="ccedit-p">
                  Your Wallet Balance is Lower than plan . Please Recharge Your Wallet First
                </p>
              </div>
            </div>
            <div className="modal-footer mod-line m-auto">
            {/* <button type="button" className="btn closecancel" data-bs-dismiss="modal"
                aria-label="Close">Cancel</button> */}
 <NavLink to='/walletrecharge/1'>
              <button
 
                type="button"
                className="btn closebtn text-white"
                data-bs-dismiss="modal"
                // aria-label="Close"
              >
                Wallet Recharge
              </button>
                </NavLink>
            </div>
          </div>
        </div>
      </div>









          </form>

        </div>}
</section>



































    </div>
  )
}

export default Upgradeplanform