import React, { useRef, useState } from 'react'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useEffect } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Loadercomp from '../../components/Loadercomp';
import 'react-phone-number-input/style.css'
import { gettoken } from '../../Localstorage/Store';

const EditagentWallet = () => {
  const gettokinval = gettoken();
  const {id} = useParams()
const nvg = useNavigate()
const pickimg = useRef(null)
const selectoption = useRef()
const selectlocationoption = useRef()
const [srtloader, setsrtloader] = useState(true);
  const [agentusername,setagentusername] = useState()
  const [agentname,setagentname] = useState()
  const [agentlast,setagentlast] = useState()
  const [superid,setsuperid] = useState()



















 // fetch agent details ----->start
 useEffect(() => {
   async function fetchData() {
     const config = {
       headers: {
         Authorization: `Bearer ${gettokinval.access}`,
       },
     };
     let url = `${process.env.REACT_APP_API_URL}api/admin/agent-wallets/${id}/`;
     const response = await axios.get(url, config);
    //  console.log(response,'response')
    //  setagentusername(response.data.username)
     setagentname(response.data.full_name)
     setsuperid(response.data.user)
    //  setagentlast(response.data.last_name)
     
     setsrtloader(false)
   }
   fetchData();


 }, []);








  async function submitform(e){
       e.preventDefault();
    
// console.log("first",agentlast)

const formdata = new FormData();
formdata.append("agent_id",superid);
formdata.append("amount",agentlast);
formdata.append("description", `Register Bonus `);

const config = {
  headers: {
    Authorization: `Bearer ${gettokinval.access}`,
  },
};
let url = `${process.env.REACT_APP_API_URL}api/admin/agent-wallets/`;
const response = await axios.post(url, formdata, config);
// console.log(response,'new is')
nvg("/agentwalletlist/1")
  }







  




  return (
    <div style={{width:'100%'}}>
      {/* <Header /> */}
      <div className="dashboardcontent px-2">
      {/* <Breadcup name={'Agent'} /> */}
      <div className="container-fuild px-2 desgin1">
      <div className="row bg-white ">
            {/* <div className="col-12 py-3 customtext23" style={{background: 'hsla(210, 85%, 32%, 0.2)',color:'#0C5398'}}>Edit Agent</div> */}
            <div className="col-lg-12 d-flex justify-content-between py-2" style={{ background: 'hsla(210, 85%, 32%, 0.2)', color: '#0C5398' }}>
              <p className="m-0 customfont" > Agent Wallet</p>
              <div className="addnew d-block mb-2">
                <button className="btn text-white closebtn"><NavLink to='/agentwalletlist/0' style={{ textDecoration: 'none', color: 'white' }}> x Close</NavLink></button>
              </div>
            </div>
        </div>


        
      
  
      </div>
      {srtloader == true ? <div className="container-fuild bg-white"> <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"92%"}}>
        <div className='px-2'> <Loadercomp size={100} /></div>
        </div></div> :
      <div className="container-fuild pb-4 pt-3 px-2 bg-white">
       <form onSubmit={submitform}>
       <div className="row bg-white pb-4 round" style={{border:'1px solid #E0E0E0',margin:"10px 0px",borderRadius: '3px',position:"relative"}}>
       <div className="col-12">
       {/* {agentresponse.status ? errorclass === true ? <div className="alert alert-danger mt-3" role="alert">
           Something Went Wrong 
       </div> : <div></div> : <div></div>} */}
       </div>
        
        
        <div className="col-md-6 px-2 pt-4">
          <div className="row">
            <div className="col-lg-3">
              <label htmlFor="" className="form-label">Agent Name <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-9">
              <input type="text" className="form-control" value={agentname} placeholder='First Name' onChange={(e) => {setagentname(e.target.value)}} required />
            </div>
          </div>
        </div>
        <div className="col-md-6 px-2 pt-4">
          <div className="row">
            <div className="col-lg-3">
              <label htmlFor="" className="form-label">Amount <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-9">
              <input type="number" className="form-control" value={agentlast} placeholder='Amount' onChange={(e) => {setagentlast(e.target.value)}} required />
            </div>
          </div>
        </div>
      
        <div className="col-12 py-4 px-4 d-flex justify-content-end" style={{gap:'4px'}}>
        {/* <div className='px-2'>{loading == true ?  <Loadercomp /> : ""}</div> */}
          {/* <button className='btn4'>Cancel</button> */}
          <button type='submit' className='btn5'>Add</button>
        </div>
        </div>
   
  


     </form>
      </div>}
      </div>
    </div>
  )
}

export default EditagentWallet