import Sidebar from "./Sidebar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import Agentlist from "./pages/Agent/Agentlist";
import Addagent from "./pages/Agent/Addagent";
import Editagent from "./pages/Agent/Editagent";
import Agentdetail from "./pages/Agent/Agentdetail";
import Addproduct from "./pages/Product/Addproduct";
import Productlist from "./pages/Product/Productlist";
import Productdetail from "./pages/Product/Productdetail";
import Editproduct from "./pages/Product/Editproduct";
import Companylist from "./pages/Company/Companylist";
import Addcompany from "./pages/Company/Addcompany";
import Companydetail from "./pages/Company/Companydetail";
import Editcompany from "./pages/Company/Editcompany";
import Customerlist from "./pages/Customer/Customerlist";
import Addcustomer from "./pages/Customer/Addcustomer";
import Customerdetail from "./pages/Customer/Customerdetail";
import Editcustomer from "./pages/Customer/Editcustomer";
import Remittancelist from "./pages/Remittance/Remittancelist";
import Addremittance from "./pages/Remittance/Addremittance";
import Editremittance from "./pages/Remittance/Editremittance";
import Login from "./pages/Login/Login";
import Remittancedetail from "./pages/Remittance/Remittancedetail";
import Cagentlist from "./pages/Checking/Cagentlist";
import Cagentdetail from "./pages/Checking/Cagentdetail";
import Cremittancelist from "./pages/Checking/Cremittancelist";
import Cremittancedetail from "./pages/Checking/Cremittancedetail";
import Ccustomerlist from "./pages/Checking/Ccustomerlist";
import Ccustomerdetail from "./pages/Checking/Ccustomerdetail";
import Aagentdetail from "./pages/Approval/Aagentdetail";
import Aagentlist from "./pages/Approval/Aagentlist";
import Acustomerdetail from "./pages/Approval/Acustomerdetail";
import Acustomerlist from "./pages/Approval/Acustomerlist";
import Aremittancedetail from "./pages/Approval/Aremittancedetail";
import Aremittancelist from "./pages/Approval/Aremittancelist";
import Locationlist from "./pages/Location/Locationlist";
import Addlocation from "./pages/Location/Addlocation";
import Locationdetail from "./pages/Location/Locationdetail";
import Editlocation from "./pages/Location/Editlocation";
import Addprofile from "./pages/Profile/Addprofile";
import Editprofile from "./pages/Profile/Editprofile";
import Profiledetail from "./pages/Profile/Profiledetail";
import Accountpwd from "./pages/Security/Accountpwd";
import Logactivitydetail from "./pages/Loginactivity/Logactivitydetail";
import Logtracklist from "./pages/Report/Logtracklist";
import Ceditcustomer from "./pages/Checking/Ceditcustomer";
import Ceditagent from "./pages/Checking/Ceditagent";
import Ceditremittance from "./pages/Checking/Ceditremittance";
import Aeditcustomer from "./pages/Approval/Aeditcustomer";
import Aeditagent from "./pages/Approval/Aeditagent";
import Aeditremittance from "./pages/Approval/Aeditremittance";
import Forgetpassword from "./pages/Login/Forgetpassword";
import Resetpassword from "./pages/Login/Resetpassword";
import Home from "./pages/Subscription/Home";
import Cart from "./pages/Subscription/Cart";
import Termandcondition from "./pages/Subscription/Termandcondition";
import Privacypolicy from "./pages/Subscription/Privacypolicy";
import Contact from "./pages/Subscription/Contact";
import { Balance, Wallet } from "@mui/icons-material";
import Walletrecharge from "./pages/Subscription/Walletrecharge";
import About from "./pages/Subscription/About";
import Wallethistory from "./pages/Subscription/Wallethistory";
import Refund from "./pages/Subscription/Refund";
import Subcriptionplan from "./pages/Subscription/Subcriptionplan";
import { useState } from "react";
import Checkout from "./pages/Subscription/Checkout";
import Subscriptionlist from "./pages/Subscriptionplan/Subscriptionlist";
import Addsubscription from "./pages/Subscriptionplan/Addsubscription";
import Editsubscription from "./pages/Subscriptionplan/Editcustomer";
import Subscriptiondetail from "./pages/Subscriptionplan/Customerdetail";
import Register from "./pages/Login/Register";
import Agentwalletlist from "./pages/Agentwallet/Agentwalletlist";
import EditagentWallet from "./pages/Agentwallet/Editagentwallet";
import Agentwalletdetail from "./pages/Agentwallet/Agentwalletdetail";
import Upgradeplan from "./pages/Subscription/Upgradeplan";
import Upgradeplanform from "./pages/Subscription/Upgradeplanform";
function App() {
  const privateurl = true;


  return (
    <div className="app">
      <Router>

      <Sidebar>
        <Routes>
         <Route path="/" element={<Home />} />
         <Route path="/about" element={<About />} />
         <Route path="/contact" element={<Contact />} />
         <Route path="/refund" element={<Refund />} />
         <Route path="/termandcondition" element={<Termandcondition />} />
         <Route path="/privacypolicy" element={<Privacypolicy />} />
         <Route path="/cart/:id" element={<Cart />} />
         <Route path="/login" element={<Login />} />
         <Route path="/register" element={<Register />} />
         <Route path="/checkout/:planidno" element={<Checkout />} />
         <Route path="/walletrecharge/:id" element={<Walletrecharge />} />
         <Route path="/subscriptionplan" element={<Subcriptionplan />} />
         <Route path="/upgradeplan" element={<Upgradeplan />} />
         <Route path="/upgradeplanform/:id" element={<Upgradeplanform />} />
         <Route path="/wallethistory" element={<Wallethistory />} />
        <Route path="/resetpassword" element={<Forgetpassword />} />
        <Route path="/forgetpassword/:id1/:id2" element={<Resetpassword />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/logtracklist" element={<Logtracklist/>} />
          <Route path="/logactivity" element={<Logactivitydetail/>} />
          <Route path="/addprofile" element={<Addprofile/>} />
          <Route path="/profiledetail" element={<Profiledetail/>} />
          <Route path="/accountpassword" element={<Accountpwd />} />
          <Route path="/editprofile" element={<Editprofile/>} />
          <Route path="/agencylist/:id" element={<Locationlist />} />
          <Route path="/addagency" element={<Addlocation />} />
          <Route path="/agencydetail/:id" element={<Locationdetail />} />
          <Route path="/agentwalletdetail/:id" element={<Agentwalletdetail />} />
          <Route path="/editagency/:id" element={<Editlocation />} />
          <Route path="/agentlist/:id" element={<Agentlist />} />
          <Route path="/agentwalletlist/:id" element={<Agentwalletlist />} />
          <Route path="/addagent" element={<Addagent />} />
          <Route path="/editagent/:id" element={<Editagent />} />
          <Route path="/editagentWallet/:id" element={<EditagentWallet />} />
          <Route path="/agentdetail/:id" element={<Agentdetail />} />
          <Route path="/addproduct" element={<Addproduct />} />
          <Route path="/editproduct/:id" element={<Editproduct />} />
          <Route path="/productlist/:id" element={<Productlist />} />
          <Route path="/subscriptionlist/:id" element={<Subscriptionlist />} />
          <Route path="/Productdetail/:id" element={<Productdetail />} />
          <Route path="/corporatelist/:id" element={<Companylist />} />
          <Route path="/addcorporate" element={<Addcompany />} />
          <Route path="/corporatedetail/:id" element={<Companydetail />} />
          <Route path="/editcorporate/:id" element={<Editcompany />} />
          <Route path="/customerlist/:id" element={<Customerlist />} />
          <Route path="/addcustomer" element={<Addcustomer />} />
          <Route path="/addsubscription" element={<Addsubscription />} />
          <Route path="/customerdetail/:id" element={<Customerdetail />} />
          <Route path="/subscriptiondetail/:id" element={<Subscriptiondetail />} />
          <Route path="/editcustomer/:id" element={<Editcustomer />} />
          <Route path="/editsubscription/:id" element={<Editsubscription />} />
          <Route path="/remittancelist/:id" element={<Remittancelist />} />
          <Route path="/addremittance" element={<Addremittance />} />
          <Route path="/editremittance/:id" element={<Editremittance />} />
          <Route path="/remittancedetail/:id" element={<Remittancedetail />} />
          <Route path="/checkagentlist/:id" element={<Cagentlist />} />
          <Route path="/checkagentdetail/:id" element={<Cagentdetail />} />
          <Route path="/checkremittancelist/:id" element={<Cremittancelist />} />
          <Route path="/checkremittancedetail/:id" element={<Cremittancedetail />} />
          <Route path="/checkcustomerlist/:id" element={<Ccustomerlist />} />
          <Route path="/checkcustomerdetail/:id" element={<Ccustomerdetail />} />
          <Route path="/approvalagentlist/:id" element={<Aagentlist />} />
          <Route path="/approvalagentdetail/:id" element={<Aagentdetail />} />
          <Route path="/approvalremittancelist/:id" element={<Aremittancelist />} />
          <Route path="/approvalremittancedetail/:id" element={<Aremittancedetail />} />
          <Route path="/approvalcustomerlist/:id" element={<Acustomerlist />} />
          <Route path="/approvalcustomerdetail/:id" element={<Acustomerdetail />} />
          <Route path="/approvaleditremittance/:id" element={<Aeditremittance />} />
          <Route path="/approvaleditagent/:id" element={<Aeditagent />} />
          <Route path="/checkeditcustomer/:id" element={<Ceditcustomer/>} />
          <Route path="/approvaleditcustomer/:id" element={<Aeditcustomer />} />
          <Route path="/checkeditagent/:id" element={<Ceditagent />} />
          <Route path="/checkeditremittance/:id" element={<Ceditremittance />} />
          <Route path="*" element={<> not found</>} />
        </Routes>
      </Sidebar>
  
    </Router>
    </div>
  );
}

export default App;
