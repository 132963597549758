import React from 'react'
import img1 from '../../assets/lplogo.png';
import img2 from '../../assets/kyc.png';
import img3 from "../../assets/KYC Logo white-bg 2.jpg";
import { useState } from 'react';
import { useEffect } from 'react';
import { gettoken, privateurl, tokenstore } from '../../Localstorage/Store';
import axios from 'axios';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Loadercomp from '../../components/Loadercomp';

const Login = () => {
  const locationhook = useLocation();
  const [emailfield,setemailfield] = useState()
  const [passwordfield,setpasswordfield] = useState()
  const [loginresponse,setloginresponse] = useState({})
  const [loading, setloading] = useState(false);
  const [showregisterresponse, setshowregisterresponse] = useState(false);
  const [showregisterresponsetwo, setshowregisterresponsetwo] = useState(false);
  const [upgraderes, setupgraderes] = useState(false);
  const [tandcerror, settandcerror] = useState(false);
  const [reset, setreset] = useState(false);
  const [tandc, settandc] = useState(false);
  const [reset2, setreset2] = useState(false);
  const [idnumber, setidnumber] = useState(0);
  // console.log("check location",locationhook)
  const nvg = useNavigate()
  useEffect(()=>{
     if(gettoken()){
      const lk = gettoken()
      if(lk.license_key){
        nvg('/dashboard');
      }else{
        nvg('/subscriptionplan')
      }

 
     }
  })

  const backhome = () => {
nvg('/')
  }

  useEffect(()=>{
    if(locationhook.state){
      if(locationhook.state.id){
        setidnumber(1)
        if(locationhook.state.id == 1){
          setshowregisterresponse(true)
          setTimeout(() => {
            setshowregisterresponse(false)
          }, 7000);
        }else{
          if(locationhook.state.id == 100){
            setreset(true)
            setTimeout(() => {
              setreset(false)
            }, 7000);
          }else{


            if(locationhook.state.id == 7){
              setreset2(true)
              setTimeout(() => {
                setreset2(false)
              }, 7000);
            }else{
              if(locationhook.state.id == 700){
                setupgraderes(true)
                setTimeout(() => {
                  setupgraderes(false)
                }, 7000);
              }else{
            setshowregisterresponsetwo(true)
            setTimeout(() => {
              setshowregisterresponsetwo(false)
            }, 7000);
          }
          }
          }
         
        }
      }
    }
 
  },[])

  async function submitform(e){
    e.preventDefault();
    if(tandc == true){

  
    setloading(true)
    const formdata = new FormData()
    formdata.append('email',emailfield)
    formdata.append('password',passwordfield)
    const jsondata = {
      email:emailfield,
      password:passwordfield
    }
  
   try {
    let url = `${process.env.REACT_APP_API_URL}api/login/`;
    const response = await axios.post(url, jsondata);
    if(response.data.staff == false && response.data.superuser == false && response.data.remaining_days <= 0){
      
      setloginresponse({statusone:1,message:"Your Subscription Plan Has Been Expired "})
    }else{
    setloginresponse(response);
    tokenstore(response.data);
    if(response.data.license_key == null){
        nvg('/subscriptionplan')
        window.location.reload();
      
    }else{
      if(response.data.remaining_days > 0){
      nvg('/dashboard')
      window.location.reload();
    }else{
      nvg('/subscriptionplan')
      window.location.reload();
    }
    }
  }
    setloading(false)

   } catch (error) {
    setloginresponse({statusone:1,message:error.response.data.detail})
    setloading(false)
   }
  }else{
    settandcerror(true)
    setTimeout(() => {
      settandcerror(false)
    }, 7000);
  }
   
  }

  useEffect(()=>{
    privateurl(false);
  })
  return (
    <div className='container loginpage d-flex justify-content-between align-items-center'>
    <div className="row">
    <div className="col-lg-6 lp-left px-lg-1 px-md-1">
        <div className="container px-lg-2 px-md-1">
<div className="row mb-4">
  <div className="col-lg-7 col-8 offset-lg-0">
  <img src={img3} style={{mixBlendMode:"darken"}} onClick={backhome} className="img-fluid" alt="" />
  
  </div>
</div>
<div className='row mb-4'>
  <div className="col-lg-10 col-md-10 col-12 offset-lg-0 offset-0">
  <p className='welcomeback'>Welcome Back</p>
  <h3 className='welcomeback2 mb-3'>Login to your account</h3>
  </div>
</div>
<div className="row">
  <div className="col-lg-10 col-md-10 col-12 offset-lg-0">
  <input type="text" className='form-control customheight mb-3' value={emailfield} onChange={(e) => {setemailfield(e.target.value)}} required  placeholder='Enter Your Email' />
  </div>
  <div className="col-lg-10 col-md-10 col-12 offset-lg-0">
  <input type="password" className='form-control customheight mb-3' value={passwordfield} onChange={(e) => {setpasswordfield(e.target.value)}} required placeholder='Password' />
  </div>
  <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"91%",zIndex:"3"}}>
        <div className='px-2'>{loading == true ?  <Loadercomp size={100} /> : ""}</div>
        </div>
  <div className="col-lg-10 col-md-10 col-12 offset-lg-0 d-flex justify-content-between">
  <div className="form-check ">
  <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
  <label className="form-check-label " htmlFor="flexCheckDefault">
  Remember me
  {/* Back to Home */}
  </label>
</div>
  <div><NavLink to="/resetpassword" style={{textDecoration:"none"}}><p className='customdesgi'>Forgot password ?</p></NavLink></div>
  </div>
  <div className="col-lg-10 col-md-10 col-12 offset-lg-0 d-flex justify-content-between">
  <div className="form-check ">
    {tandc == true ? <input className="form-check-input" type="checkbox" onClick={(e)=>{settandc(!tandc)}} id="tandc" checked /> : <input className="form-check-input" type="checkbox" onClick={(e)=>{settandc(!tandc)}} value="" id="flexCheckDefault" />}
  
  <label className="form-check-label " htmlFor="tandc">
  I Agree to Terms & Conditions
  {/* Back to Home */}
  </label>
</div>
  </div>
</div>
<div className="row">
  <div className="col-lg-10 col-md-10 col-12 offset-lg-0">
  <button className='btn text-white mt-4 customcolor2 customheight' onClick={submitform} style={{width:'100%',fontWeight:'bold'}}>Login</button>
  </div>
  <div className="col-lg-10 col-md-10 col-12 offset-lg-0">
  <button className=' mt-1 customheight' onClick={backhome} style={{width:'100%',fontWeight:'bold',color:'#0C5398',cursor:'pointer',border:'none',outline:'none'}}>Back to Home</button>
  </div>
  <div className="col-lg-10 col-md-10 col-12 offset-lg-0 ">
    {loginresponse.statusone === 1 ? <div className="alert alert-danger" role="alert">
           {loginresponse.message}
       </div> : ''}
    {tandcerror == true ? <div className="alert alert-danger" role="alert">
           Please Agree with Terms and Conditions Before Proceeding
       </div> : ''}
       
  </div>
</div>
      </div>
        </div>
        <div className=" col-6 lp-right isnone">
        {showregisterresponse == true ? <div className="alert alert-success" style={{margin: "0px",position: 'relative',zIndex: 3,top:'11px',left: '-2px'}} role="alert">
           Successfully Register
       </div> : ''}
       {showregisterresponsetwo == true ? <div className="alert alert-success" style={{margin: "0px",position: 'relative',zIndex: 3,top:'11px',left: '-2px'}} role="alert">
      Congratulations! You have Successfully Purchased the Subscription Please Login Again
       </div> : ''}
       {upgraderes == true ? <div className="alert alert-success" style={{margin: "0px",position: 'relative',zIndex: 3,top:'11px',left: '-2px'}} role="alert">
      Congratulations! You have Successfully Upgrade Your Subscription Plan
       </div> : ''}
       {reset == true ? <div className="alert alert-success" style={{margin: "0px",position: 'relative',zIndex: 3,top:'11px',left: '-2px'}} role="alert">
      Password Reset Successfully
       </div> : ''}
       {reset2 == true ? <div className="alert alert-success" style={{margin: "0px",position: 'relative',zIndex: 3,top:'11px',left: '-2px'}} role="alert">
      Renew Subscription Successfully
       </div> : ''}
       
        <div className='overlapimg'>
        <img src={img2} alt="mnbvc" style={{width:'100%'}} />
        </div>
      </div> 
      
    </div>
    </div>
  )
}

export default Login