import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { gettoken } from '../../Localstorage/Store';
import Loadercomp from '../../components/Loadercomp';

const Productdetail = () => {

  const {id} = useParams()
  const nvg = useNavigate()
  // const [data, setData] = useState({});
  const gettokinval = gettoken();
  const [name, setname] = useState('')
  const [logohelp, setlogohelp] = useState('')
  const [srtloader, setsrtloader] = useState(true);
  const [status, setstatus] = useState()
  const [created_by, setcreated_by] = useState();
  const [created_at, setcreated_at] = useState();
  const [last_updated_by, setlast_updated_by] = useState();
  const [last_updated_at, setlast_updated_at] = useState();

  useEffect(()=>{
    if(gettokinval.staff == false){
      nvg('/dashboard')
    }
  })
//fetch user record ----> start
  useEffect(() => {
    async function fetchData() {
      const config = {
        headers: {
          Authorization: `Bearer ${gettokinval.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}api/product/${id}/`;
      const response = await axios.get(url, config);
      setname(response.data.product_name)
      setlogohelp(response.data.logo)
        setstatus(response.data.status)
      setcreated_by(response.data.created_by)
      setcreated_at(response.data.created_at)
      setlast_updated_by(response.data.last_updated_by)
      setlast_updated_at(response.data.last_updated_at)
      setsrtloader(false)
    }
    fetchData();
  }, []);
//fetch user record ----> end

  return (
    <div style={{width:'100%',minHeight:'100vh'}}>
      {/* <Header /> */}
      <div className="dashboardcontent px-2">
      {/* <Breadcup name={'Products'} /> */}
      {/* <div className="container py-4">
        
      </div> */}
      <div className="container-fuild px-2 desgin1">
      <div className="row bg-white ">
            {/* <div className="col-12 py-3 customtext23" style={{background: 'hsla(210, 85%, 32%, 0.2)',color:'#0C5398'}}>Product Detail</div> */}
            <div className="col-lg-12 d-flex justify-content-between py-2" style={{ background: 'hsla(210, 85%, 32%, 0.2)', color: '#0C5398' }}>
              <p className="m-0 customfont" >Product Detail</p>
              <div className="addnew d-block mb-2">
                <button className="btn text-white closebtn"><NavLink to='/productlist/0' style={{ textDecoration: 'none', color: 'white' }}> x Close</NavLink></button>
              </div>
            </div>
        </div>
      </div>
      {srtloader == true ? <div className="container-fuild bg-white"> <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"70%"}}>
        <div className='px-2'> <Loadercomp size={100} /></div>
        </div></div> : <div className="container-fuild pb-4 pt-3 px-2 bg-white">
       <div className="row bg-white round" style={{border:'1px solid #E0E0E0',margin:"10px 0px",borderRadius: '3px',paddingBottom:'9rem'}}>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">
              Name 
              </label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
             <p className='customcolor ps-4'>{name}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label customw">Product Logo </label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end ps-4">
            <img src={logohelp} alt="" width={39} />
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Status </label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{status == true ? "Active" : "Inactive"}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Created by</label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{created_by}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Created Date & Time</label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{created_at}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Last Modified by </label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{last_updated_by ? last_updated_by : "NOT MODIFIED"}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Last Modified Date & Time </label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{last_updated_at ? last_updated_at : "NOT MODIFIED"}</p>
            </div>
          </div>
        </div>
      </div>
      </div>}
      </div>
    </div>
  )
}

export default Productdetail