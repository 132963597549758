import React, { useRef, useState } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import img1 from "../../assets/Group 40.png";
import img2 from "../../assets/Group 41.png";
import img3 from "../../assets/asdfg.png";
import img4 from "../../assets/abc.png";
import img5 from "../../assets/cba.png";
import img33 from "../../assets/images.png";
import img7 from "../../assets/ul.png";
import axios from "axios";
import { gettoken } from "../../Localstorage/Store";
import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Loadercomp from "../../components/Loadercomp";
import checkicon from '../../assets/Ellipse 28.png';
// import ReactImageMagnify from 'react-image-magnify';
import Webcam from "react-webcam";
import Webcamtwo from "react-webcam";
import 'react-phone-number-input/style.css'
import StepWizard from "react-step-wizard";
import PhoneInput from 'react-phone-number-input'
import csc from 'country-state-city';
import Select from "react-select";
import {CaptureFinger,MatchFinger} from "../mfs100";
import { BsFillArrowLeftCircleFill,BsFillArrowRightCircleFill,BsFillArrowUpCircleFill,BsFillArrowDownCircleFill } from "react-icons/bs";

const Addagent = () => {
  const nvg = useNavigate();
  const pickimg = useRef(null)
  const [agentusername, setagentusername] = useState();
  const [agentname, setagentname] = useState();
  const [agentlast, setagentlast] = useState();
  const [agentemail, setagentemail] = useState();
  const [agentgender, setagentgender] = useState("Male");
  const [agentnationality, setagentnationality] = useState("india");
  const [agentmobile, setagentmobile] = useState();
  const [agentcompany, setagentcompany] = useState("");
  const [agentlocation, setagentlocation] = useState("");
  const [agentchecker, setagentchecker] = useState(false);
  const [agentmaker, setagentmaker] = useState(false);
  const [agentapprover, setagentapprover] = useState(false);
  const [agentcountry, setagentcountry] = useState(null);
  const [agentstate, setagentstate] = useState(null);
  const [agentcity, setagentcity] = useState(null);
  const [ffn, setffn] = useState();
  const [otploading, setotploading] = useState(false);
  const [dfierror, setdfierror] = useState(false);
  const [dbierror, setdbierror] = useState(false);
  const [drireserror, setdrireserror] = useState(''); 
  const [dbireserror, setdbireserror] = useState('');
  const [dfierrortwo, setdfierrortwo] = useState(false);
  const [dbierrortwo, setdbierrortwo] = useState(false);
  const [drireserrortwo, setdrireserrortwo] = useState(''); 
  const [dbireserrortwo, setdbireserrortwo] = useState('');
  const [hideorshowtwo, sethideorshowtwo] = useState(false);
  const [hideorshowthree, sethideorshowthree] = useState(false);
  const [varxtwo, setvarxtwo] = useState(10);
  const [varytwo, setvarytwo] = useState(10);
  const [agentaddress, setagentaddress] = useState();
  const [agentpincode, setagentpincode] = useState();
  const [agentstatus, setagentstatus] = useState();
  const [thumbimg2, setthumbimg2] = useState('');
  const [rifimg2, setrifimg2] = useState('');
  const [rmfimg2, setrmfimg2] = useState('');
  const [rrfimg2, setrrfimg2] = useState('');
  const [rlfimg2, setrlfimg2] = useState('');
  const [ltfimg2, setltfimg2] = useState('');
  const [lifimg2, setlifimg2] = useState('');
  const [lmfimg2, setlmfimg2] = useState('');
  const [lrfimg2, setlrfimg2] = useState('');
  const [llfimg2, setllfimg2] = useState('');
  const [doc1, setdoc1] = useState(true);
  const [doc2, setdoc2] = useState(true);


  const [ansithumbimg5, setansithumbimg5] = useState('');
  const [ansirifimg5, setansirifimg5] = useState('');
  const [ansirmfimg5, setansirmfimg5] = useState('');
  const [ansirrfimg5, setansirrfimg5] = useState('');
  const [ansirlfimg5, setansirlfimg5] = useState('');
  const [ansiltfimg5, setansiltfimg5] = useState('');
  const [ansilifimg5, setansilifimg5] = useState('');
  const [ansilmfimg5, setansilmfimg5] = useState('');
  const [ansilrfimg5, setansilrfimg5] = useState('');
  const [ansillfimg5, setansillfimg5] = useState('');

  const [rightnumberoffingers12, setrightnumberoffingers12] = useState(0);
  const [leftnumberoffingers12, setleftnumberoffingers12] = useState(0);



  const [rtf3error ,setrtf3error] = useState(false)
  const [rif3error ,setrif3error] = useState(false)
  const [rmf3error ,setrmf3error] = useState(false)
  const [rrf3error ,setrrf3error] = useState(false)
  const [rlf3error ,setrlf3error] = useState(false)
  const [ltf3error ,setltf3error] = useState(false)
  const [lif3error ,setlif3error] = useState(false)
  const [lmf3error ,setlmf3error] = useState(false)
  const [lrf3error ,setlrf3error] = useState(false)
  const [llf3error ,setllf3error] = useState(false)
  const [agentstage, setagentstage] = useState();
  const [aadhaarImagetwo, setAadhaarImagetwo] = useState(null);
  const [agentphotograph, setagentphotograph] = useState();
  const [agentr_thumb, setagentr_thumb] = useState('');
  const [agentr_index_finger, setagentr_index_finger] = useState('');
  const [agentr_middle_finger, setagentr_middle_finger] = useState('');
  const [agentr_ring_finger, setagentr_ring_finger] = useState('');
  const [agentr_little_finger, setagentr_little_finger] = useState('');
  const [agentl_thumb, setagentl_thumb] = useState('');
  const [agentl_index_finger, setagentl_index_finger] = useState('');
  const [agentl_middle_finger, setagentl_middle_finger] = useState('');
  const [agentl_ring_finger, setagentl_ring_finger] = useState('');
  const [agentl_little_finger, setagentl_little_finger] = useState('');
  const [agentdocument_type, setagentdocument_type] = useState();
  const [agentdocument_no, setagentdocument_no] = useState();
  const [agentpassword, setagentpassword] = useState();
  const [agentconfirmpassword, setagentconfirmpassword] = useState();
  const [agentresponse, setagentresponse] = useState({});
  const [errorclass, seterrorclass] = useState(false);
  const [notmatch, setnotmatch] = useState(false);
  const [data, setData] = useState([]);
  const [varx, setvarx] = useState(10);
  const [vary, setvary] = useState(10);
  const [dataerrorstatus, setDataerrorstatus] = useState(false);
  const [dataerrortxt, setDataerrortxt] = useState();
  const [datatwo, setDatatwo] = useState([]);
  const [datatwoerrorstatus, setDatatwoerrorstatus] = useState(false);
  const [datatwoerrortxt, setDatatwoerrortxt] = useState();
  const [errorcls, seterrorcls] = useState({});
  const [loading, setloading] = useState(false);
  const [srtloader, setsrtloader] = useState(false);
  const [fingerperror, setfingerperror] = useState(false);
  const [sandphoto,setsandphoto] = useState('')
  const [displayphoto,setdisplayphoto] = useState('')
  const [hideorshow, sethideorshow] = useState(false);
  const [sandphotoerror, setsandphotoerror] = useState(false);
  const [documentprivateerror, setdocumentprivateerror] = useState(false);
  const [documentprivateerrortwo, setdocumentprivateerrortwo] = useState(false);
  const [documenttype, setdocumenttype] = useState("PAN");
  const [idnumber, setidnumber] = useState("");
  const [documentimage, setdocumentimage] = useState();
  const [documentissue, setdocumentissue] = useState();
  const [documentexpiry, setdocumentexpiry] = useState();
  const [documenterror, setdocumenterror] = useState(false);
  const [documenterrorres, setdocumenterrorres] = useState(false);
  const [documentresponse, setdocumentresponse] = useState({});
  const [documentloader, setdocumentloader] = useState(false);
  const [documentstatus, setdocumentstatus] = useState({});
  const [notapplicable, setnotapplicable] = useState(false);
  const [dob, setdob] = useState();
  const [documentimageback, setdocumentimageback] = useState(null);
  const [documentimagebacktwo, setdocumentimagebacktwo] = useState(null);
  const [idno, setidno] = useState(0);
  const [aadhaarImage, setAadhaarImage] = useState(null);
  const [modifiedImage, setModifiedImage] = useState(null);
  const [rightnumberoffingers, setrightnumberoffingers] = useState(0);
  const [leftnumberoffingers, setleftnumberoffingers] = useState(0);
  const [rigthfingerserror, setrigthfingerserror] = useState('');



  const [megaerror, setmegaerror] = useState(false);
  const [megaerrormsg, setmegaerrormsg] = useState('');




  const [thumbimg5, setthumbimg5] = useState('');
  const [rifimg5, setrifimg5] = useState('');
  const [rmfimg5, setrmfimg5] = useState('');
  const [rrfimg5, setrrfimg5] = useState('');
  const [rlfimg5, setrlfimg5] = useState('');
  const [ltfimg5, setltfimg5] = useState('');
  const [lifimg5, setlifimg5] = useState('');
  const [lmfimg5, setlmfimg5] = useState('');
  const [lrfimg5, setlrfimg5] = useState('');
  const [llfimg5, setllfimg5] = useState('');








  const webcamRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];

  function handleImageUpload(event) {
    const file = event.target.files[0];
  
    // Perform any necessary validation on the uploaded file
  
    // Read the file as a data URL
    const reader = new FileReader();
    reader.onload = () => {
      const imageDataUrl = reader.result;
  
      // Update the state with the uploaded image data
      setAadhaarImage(imageDataUrl);
  
      // Modify the image and generate the modified image URL
      // const modifiedImageUrl = modifyImage(imageDataUrl,varx,vary);
      // setModifiedImage(modifiedImageUrl);
      if(documenttype == 'AADHAAR'){
        const modifiedImageUrl = modifyImage(imageDataUrl,varx,vary);
        setModifiedImage(modifiedImageUrl);
      }else{
        setModifiedImage(imageDataUrl);
      }
    };
  
    // Read the uploaded file as a data URL
    reader.readAsDataURL(file);
  }








  function modifyImage(imageDataUrl,xl,yt) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
  
    // Create an Image object and load the Aadhaar card image data
    const image = new Image();
    image.onload = () => {
      // Set the canvas dimensions to match the image dimensions
      canvas.width = image.width;
      canvas.height = image.height;
  
      // Draw the image on the canvas
      context.drawImage(image, 0, 0);
  
      // Modify the pixels in the desired area to hide the numbers
      //const x = xl; X-coordinate of the area to hide
      //const y = yt; // Y-coordinate of the area to hide
      const width = 240; // Width of the area to hide
      const height = 50; // Height of the area to hide
      context.fillStyle = 'black';
      context.fillRect(xl, yt, width, height);
  
      // Generate the modified image URL from the canvas
      const modifiedImageUrl = canvas.toDataURL('image/jpeg');
  
      // Update the state with the modified image URL
      setModifiedImage(modifiedImageUrl);
    };
  
    // Set the Image object's source to the Aadhaar card image data
    image.src = imageDataUrl;
  }



  function backmodifyImage(imageDataUrl,xl,yt) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
  
    // Create an Image object and load the Aadhaar card image data
    const image = new Image();
    image.onload = () => {
      // Set the canvas dimensions to match the image dimensions
      canvas.width = image.width;
      canvas.height = image.height;
  
      // Draw the image on the canvas
      context.drawImage(image, 0, 0);
  
      // Modify the pixels in the desired area to hide the numbers
      //const x = xl; X-coordinate of the area to hide
      //const y = yt; // Y-coordinate of the area to hide
      const width = 240; // Width of the area to hide
      const height = 50; // Height of the area to hide
      context.fillStyle = 'black';
      context.fillRect(xl, yt, width, height);
  
      // Generate the modified image URL from the canvas
      const modifiedImageUrl = canvas.toDataURL('image/jpeg');
  
      // Update the state with the modified image URL
      setdocumentimagebacktwo(modifiedImageUrl);
    };
  
    // Set the Image object's source to the Aadhaar card image data
    image.src = imageDataUrl;
  }
  
// capture image from webcam start here
const captureimage = (num) =>{
  setdisplayphoto(webcamRefs[num].current.getScreenshot())
  sethideorshow(!hideorshow)
}
// capture image from webcam end here

// capture image from webcam start here
const captureimagetwo = (num) =>{
  // if(num == 0){
    
    if(documenttype == 'AADHAAR'){
      const modifiedImageUrl = modifyImage(webcamRefs[num].current.getScreenshot(),varx,vary);
      setModifiedImage(modifiedImageUrl);
      setAadhaarImage(webcamRefs[num].current.getScreenshot())
    }else{
      setModifiedImage(webcamRefs[num].current.getScreenshot());
    }
    // setdisplayphototwo(pickimgtwo.current.getScreenshot())
    sethideorshowtwo(!hideorshowtwo)
  // }else{
  //   console.log("ldlddldldldldldlddldl")
  //   if(documenttype == 'AADHAAR'){
  //     const modifiedImageUrl = modifyImage(pickimgtwo.current.getScreenshot(),varxtwo,varytwo);
  //     setdocumentimagebacktwo(modifiedImageUrl);
  //     setAadhaarImagetwo(pickimgtwo.current.getScreenshot())
  //     sethideorshowthree(!hideorshowthree)
  //   console.log("ldlddldldldldldlddldrrrrrrrrrrl",documentimagebacktwo)
  //   }else{
  //     setdocumentimagebacktwo(pickimgtwo.current.getScreenshot());
  //     sethideorshowthree(!hideorshowthree)
  //   console.log("ldlddldldldldldlddldlyyyyyyyyyyyyyyyy",documentimagebacktwo)
  
  //   }
  //   console.log("ldlddldldldldldlddldl",documentimagebacktwo)
  // }

}
// capture image from webcam end here



const captureimagethree = (num) =>{
  if(documenttype == 'AADHAAR'){
    const modifiedImageUrl = backmodifyImage(webcamRefs[num].current.getScreenshot(),varxtwo,varytwo);
    setdocumentimagebacktwo(modifiedImageUrl);
    setAadhaarImagetwo(webcamRefs[num].current.getScreenshot())
    sethideorshowthree(!hideorshowthree)
  }else{
    setdocumentimagebacktwo(webcamRefs[num].current.getScreenshot());
    sethideorshowthree(!hideorshowthree)

  }

  // setdisplayphototwo(pickimgtwo.current.getScreenshot())
}




const [rtferror ,setrtferror] = useState(false)
const [riferror ,setriferror] = useState(false)
const [rmferror ,setrmferror] = useState(false)
const [rrferror ,setrrferror] = useState(false)
const [rlferror ,setrlferror] = useState(false)
const [ltferror ,setltferror] = useState(false)
const [liferror ,setliferror] = useState(false)
const [lmferror ,setlmferror] = useState(false)
const [lrferror ,setlrferror] = useState(false)
const [llferror ,setllferror] = useState(false)





const [ansithumbimg2, setansithumbimg2] = useState('');
const [ansirifimg2, setansirifimg2] = useState('');
const [ansirmfimg2, setansirmfimg2] = useState('');
const [ansirrfimg2, setansirrfimg2] = useState('');
const [ansirlfimg2, setansirlfimg2] = useState('');
const [ansiltfimg2, setansiltfimg2] = useState('');
const [ansilifimg2, setansilifimg2] = useState('');
const [ansilmfimg2, setansilmfimg2] = useState('');
const [ansilrfimg2, setansilrfimg2] = useState('');
const [ansillfimg2, setansillfimg2] = useState('');

const stepWizardInstance = useRef(null);
const [formdatao, setformDatao] = useState({});




const [thumbimg2sand, setthumbimg2sand] = useState('');
const [rifimg2sand, setrifimg2sand] = useState('');
const [rmfimg2sand, setrmfimg2sand] = useState('');
const [rrfimg2sand, setrrfimg2sand] = useState('');
const [rlfimg2sand, setrlfimg2sand] = useState('');
const [ltfimg2sand, setltfimg2sand] = useState('');
const [lifimg2sand, setlifimg2sand] = useState('');
const [lmfimg2sand, setlmfimg2sand] = useState('');
const [lrfimg2sand, setlrfimg2sand] = useState('');
const [llfimg2sand, setllfimg2sand] = useState('');


const [fingerperror12, setfingerperror12] = useState(false);

const [rigthfingerserror12, setrigthfingerserror12] = useState('');



const [form60, setform60] = useState(null);
const [displaydocument,setdisplaydocument] = useState('')
const [isformonecheck ,setisformonecheck] = useState(true)



const [documenttypetwo, setdocumenttypetwo] = useState();
const [idnumbertwo, setidnumbertwo] = useState();
const [documentissuetwo, setdocumentissuetwo] = useState();
const [documentexpirytwo, setdocumentexpirytwo] = useState();
const [modifiedImagenumbertwo, setModifiedImagenumbertwo] = useState(null);
const [aadhaarImage2, setAadhaarImage2] = useState(null);
const [varx2, setvarx2] = useState(10);
const [vary2, setvary2] = useState(10);
const [documentimageback2, setdocumentimageback2] = useState(null);
const [aadhaarback2, setAadhaarback2] = useState(null);
const [varxtwo2, setvarxtwo2] = useState(10);
const [varytwo2, setvarytwo2] = useState(10);
const [hideorshow4, sethideorshow4] = useState(false);
const [hideorshow3, sethideorshow3] = useState(false);
const [seconddocopen, setseconddocopen] = useState(true);

const [otp ,setotp] = useState('')
const [otp2 ,setotp2] = useState('')
const [otpshowresmobile ,setotpshowresmobile] = useState(false)
  const [otpshowres ,setotpshowres] = useState(false)
  const [invalidotp ,setinvalidotp] = useState(false)
  const [invalidotpmobile ,setinvalidotpmobile] = useState(false)
  const [verifyyourotp ,setverifyyourotp] = useState(false)

const [sendotpres ,setsendotpres] = useState(false)

const [sendotpresmobile ,setsendotpresmobile] = useState(false)
const [otpnext ,setotpnext] = useState(false)
const [otpnextmobile ,setotpnextmobile] = useState(false)
const [registerpro ,setregisterpro] = useState(false)
const [registerpromobile ,setregisterpromobile] = useState(false)
const [showsuperotp ,setshowsuperotp] = useState(false)
const [passotp ,setpassotp] = useState(false)
function backmodifyImage3(imageDataUrl,xl,yt) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  // Create an Image object and load the Aadhaar card image data
  const image = new Image();
  image.onload = () => {
    // Set the canvas dimensions to match the image dimensions
    canvas.width = image.width;
    canvas.height = image.height;

    // Draw the image on the canvas
    context.drawImage(image, 0, 0);


    var width 
    var height 
    // Modify the pixels in the desired area to hide the numbers
    //const x = xl; X-coordinate of the area to hide
    //const y = yt; // Y-coordinate of the area to hide
    if(doc2 == true){
       width = 240; // Width of the area to hide
       height = 50; // Height of the area to hide
      context.fillStyle = 'black';
      context.fillRect(xl, yt, width, height);
  
      // Generate the modified image URL from the canvas
      const modifiedImageUrl = canvas.toDataURL('image/jpeg');
  
      // Update the state with the modified image URL
      setdocumentimageback2(modifiedImageUrl);
    }else{
       width = 140; // Width of the area to hide
     height = 40; // Height of the area to hide
    context.fillStyle = 'black';
    context.fillRect(xl, yt, width, height);

    // Generate the modified image URL from the canvas
    const modifiedImageUrl = canvas.toDataURL('image/jpeg');

    // Update the state with the modified image URL
    setdocumentimageback2(modifiedImageUrl);
    }
    
  };

  // Set the Image object's source to the Aadhaar card image data
  image.src = imageDataUrl;
}

function modifyImagethree(imageDataUrl,xl,yt) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  // Create an Image object and load the Aadhaar card image data
  const image = new Image();
  image.onload = () => {
    // Set the canvas dimensions to match the image dimensions
    canvas.width = image.width;
    canvas.height = image.height;

    // Draw the image on the canvas
    context.drawImage(image, 0, 0);

    // Modify the pixels in the desired area to hide the numbers
    //const x = xl; X-coordinate of the area to hide
    //const y = yt; // Y-coordinate of the area to hide
    var width;
    var height
    
    if(doc2 == true){

       width = 240; // Width of the area to hide
       height = 50; // Height of the area to hide
      context.fillStyle = 'black';
      context.fillRect(xl, yt, width, height);
  
      // Generate the modified image URL from the canvas
      const modifiedImageUrl = canvas.toDataURL('image/jpeg');
  
      // Update the state with the modified image URL
      setModifiedImagenumbertwo(modifiedImageUrl);
    }else{
       width = 140; // Width of the area to hide
       height = 40; // Height of the area to hide
      context.fillStyle = 'black';
      context.fillRect(xl, yt, width, height);
  
      // Generate the modified image URL from the canvas
      const modifiedImageUrl = canvas.toDataURL('image/jpeg');
  
      // Update the state with the modified image URL
      setModifiedImagenumbertwo(modifiedImageUrl);
    }
   
  };

  // Set the Image object's source to the Aadhaar card image data
  image.src = imageDataUrl;
}



const captureimagethreetwo = (num) =>{
  if(documenttypetwo == 'AADHAAR'){
    const modifiedImageUrl = backmodifyImage3(webcamRefs[num].current.getScreenshot(),varxtwo,varytwo);
    setdocumentimageback2(modifiedImageUrl);
    setAadhaarback2(webcamRefs[num].current.getScreenshot())
    sethideorshow3(!hideorshow3)
  }else{
    setdocumentimageback2(webcamRefs[num].current.getScreenshot());
    sethideorshow3(!hideorshow3)

  }

  // setdisplayphototwo(pickimgtwo.current.getScreenshot())
}



// capture image from webcam start here
const captureimagesuper2 = (num) =>{
  // if(num == 0){
    
    if(documenttypetwo == 'AADHAAR'){
      const modifiedImageUrl = modifyImagethree(webcamRefs[num].current.getScreenshot(),varx,vary);
      setModifiedImagenumbertwo(modifiedImageUrl);
      setAadhaarImage2(webcamRefs[num].current.getScreenshot())
    }else{
      setModifiedImagenumbertwo(webcamRefs[num].current.getScreenshot());
    }
    // setdisplayphototwo(pickimgtwo.current.getScreenshot())
    sethideorshow3(!hideorshow3)
  // }else{
  //   console.log("ldlddldldldldldlddldl")
  //   if(documenttype == 'AADHAAR'){
  //     const modifiedImageUrl = modifyImage(pickimgtwo.current.getScreenshot(),varxtwo,varytwo);
  //     setdocumentimagebacktwo(modifiedImageUrl);
  //     setAadhaarImagetwo(pickimgtwo.current.getScreenshot())
  //     sethideorshowthree(!hideorshowthree)
  //   console.log("ldlddldldldldldlddldrrrrrrrrrrl",documentimagebacktwo)
  //   }else{
  //     setdocumentimagebacktwo(pickimgtwo.current.getScreenshot());
  //     sethideorshowthree(!hideorshowthree)
  //   console.log("ldlddldldldldldlddldlyyyyyyyyyyyyyyyy",documentimagebacktwo)
  
  //   }
  //   console.log("ldlddldldldldldlddldl",documentimagebacktwo)
  // }

}
// capture image from webcam end here




const handleStepChange = (step) => {
  console.log(`Step changed to ${step}`);
  // You can add custom logic here based on the step change
};







  function modifyImage(imageDataUrl,xl,yt) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
  
    // Create an Image object and load the Aadhaar card image data
    const image = new Image();
    image.onload = () => {
      // Set the canvas dimensions to match the image dimensions
      canvas.width = image.width;
      canvas.height = image.height;
  
      // Draw the image on the canvas
      context.drawImage(image, 0, 0);
  
      // Modify the pixels in the desired area to hide the numbers
      //const x = xl; X-coordinate of the area to hide
      //const y = yt; // Y-coordinate of the area to hide
      const width = 240; // Width of the area to hide
      const height = 50; // Height of the area to hide
      context.fillStyle = 'black';
      context.fillRect(xl, yt, width, height);
  
      // Generate the modified image URL from the canvas
      const modifiedImageUrl = canvas.toDataURL('image/jpeg');
  
      // Update the state with the modified image URL
      setModifiedImage(modifiedImageUrl);
    };
  
    // Set the Image object's source to the Aadhaar card image data
    image.src = imageDataUrl;
  }
  
  const gettokinval = gettoken();
  // const captureimage = () =>{
  //   setdisplayphoto(pickimg.current.getScreenshot())
  //   sethideorshow(!hideorshow)
  // }



  const [error, setError] = useState(null);
  const [pineror, setpineror] = useState('');
  const [stateerror, setstateerror] = useState('');
  const [cityerror, setcityerror] = useState('');


  useEffect(()=>{
    setagentcompany(gettokinval.company_name)
    setagentlocation(gettokinval.branch_name)
  },[])





  const [allreadyemail,setallreadyemail] = useState(false)
  const [allreadyemailmsg,setallreadyemailmsg] = useState('')
  const sendotp = async () =>{
    if(agentemail !== ''){
      const jsondata = {
        email:agentemail,
      }
          try {
            setotploading(true)
            let url = `${process.env.REACT_APP_API_URL}api/send-otp/`;
            const response = await axios.post(url, jsondata);

            console.log("xxxxxxxxxxxxxxxxxxxxxemail",response);
            if(response.status == 201 || response.status == 200){
              setsendotpres(true)
              setotpnext(true)
              setotploading(false)
              setallreadyemail(false)
           setallreadyemailmsg("")
           sendotpmobile()
              setTimeout(function() {
                setsendotpres(false)
               }, 5000); // 5000 milliseconds (5 seconds) delay
            }
          } catch (error) {
           console.log("qwertyuiop456789034ascv ",error)
           setotploading(false)
           setallreadyemail(true)
           setallreadyemailmsg("Email ID already exists.")

          }
    }
  }





  const verifyotp = async () =>{
    if(otp !== ''){
      const formdata = new FormData();
      formdata.append("email",agentemail);
      formdata.append("otp",otp);
          try {
            setotploading(true)
            let url = `${process.env.REACT_APP_API_URL}api/verify-otp/`;
            const response = await axios.post(url,formdata);
            // console.log("ooooooooooooooooooooo",response);
            if(response.status == 201 || response.status == 200){
              setotpshowres(true)
              setregisterpro(true)
              setotpnext(false)
              setotploading(false)
              setshowsuperotp(false)
              setpassotp(true)
              console.log("popup working now verift")
              setisformonecheck(false)
              stepWizardInstance.current.nextStep()
              // minsubmitform(true)
              setTimeout(function() {
                setotpshowres(false)
               }, 5000); // 5000 milliseconds (5 seconds) delay
            }
          } catch (error) {
            setotploading(false)
            setinvalidotp(true)
          //  console.log(error.response.data.error)
           setTimeout(function() {
            setinvalidotp(false)
           }, 5000); // 5000 milliseconds (5 seconds) delay
          }
    }
  }




  const [allreadynumber,setallreadynumber] = useState(false)
  const [allreadynumbermsg,setallreadynumbermsg] = useState('')
  const sendotpmobile = async () =>{
    if(agentmobile !== '' && agentmobile !== undefined && agentmobile !== null){
      const jsondata = {
        phone:agentmobile,
        username:agentname,
      }
          try {
            setotploading(true)
            let url = `${process.env.REACT_APP_API_URL}api/send-otp-phone/`;
            const response = await axios.post(url, jsondata);
            // console.log("xxxxxxxxxxxxxxxxxxxxx",response);
            if(response.status == 201 || response.status == 200){
              setshowsuperotp(true)
           setallreadynumber(false)
           setallreadynumbermsg("")
              setsendotpresmobile(true)
              setotpnextmobile(true)
              setotploading(false)
              setTimeout(function() {
                setsendotpresmobile(false)
               }, 5000); // 5000 milliseconds (5 seconds) delay
            }
          } catch (error) {
          //  console.log(error)
           setotploading(false)
           setshowsuperotp(false)
           setallreadynumber(true)
           setallreadynumbermsg("Mobile number already exists.")
          }
    }
  
  }



  const verifyotpmobile = async () =>{
    if(agentemail == '' || agentemail == undefined || agentemail == null){
    if(otp2 !== ''){
      const formdata = new FormData();
      formdata.append("phone",agentmobile);
      formdata.append("otp",otp2);
          try {
            setotploading(true)
            let url = `${process.env.REACT_APP_API_URL}api/verify-otp-phone/`;
            const response = await axios.post(url,formdata);
            // console.log("ooooooooooooooooooooo",response);
            if(response.status == 201 || response.status == 200){
              setotpshowresmobile(true)
              setregisterpromobile(true)
              setotpnextmobile(false)
              setotploading(false)
              setshowsuperotp(false)
setpassotp(true)
              console.log("popup working now verift")
              setisformonecheck(false)
              stepWizardInstance.current.nextStep()
              // minsubmitform(true)
              setTimeout(function() {
                setotpshowresmobile(false)
               }, 5000); // 5000 milliseconds (5 seconds) delay
            }
          } catch (error) {
            setotploading(false)
            setinvalidotpmobile(true)
          //  console.log(error.response.data.error)
           setTimeout(function() {
            setinvalidotpmobile(false)
           }, 7000); // 5000 milliseconds (5 seconds) delay
          }
    }
  }else{
    if(otp2 !== ''){
      const formdata = new FormData();
      formdata.append("phone",agentmobile);
      formdata.append("otp",otp2);
          try {
            setotploading(true)
            let url = `${process.env.REACT_APP_API_URL}api/verify-otp-phone/`;
            const response = await axios.post(url,formdata);
            // console.log("ooooooooooooooooooooo",response);
            if(response.status == 201 || response.status == 200){
              setotpshowresmobile(true)
              setregisterpromobile(true)
              setotpnextmobile(false)
              setotploading(false)
              // setshowsuperotp(false)
// setpassotp(true)
verifyotp()
              // console.log("popup working now verift")
              // minsubmitform(true)
              setTimeout(function() {
                setotpshowresmobile(false)
               }, 5000); // 5000 milliseconds (5 seconds) delay
            }
          } catch (error) {
            setotploading(false)
            setinvalidotpmobile(true)
          //  console.log(error.response.data.error)
           setTimeout(function() {
            setinvalidotpmobile(false)
           }, 7000); // 5000 milliseconds (5 seconds) delay
          }
    }
  }
  }

  const handleSubmit = () => {
    if(agentcountry !== undefined || agentcountry !== '' || agentcountry !== null){
      setError(null);

      axios.get(`https://api.zippopotam.us/${agentcountry.sortname}/${agentpincode}`)
        .then((response) => {
          // console.log(response)
          setagentstate(response.data.places[0].state)
          setagentcity(response.data.places[0]['place name'])
          setpineror('')
          setstateerror('')
          setcityerror('')
        })
        .catch((error) => {
          setError('Failed to fetch location data. Please check the pincode.');
          // console.log(error.response)
          setpineror('Please Check Your Country and Pincode')
        });
    }
  
  };



  const countryfocus = () =>{
    if(agentcountry == undefined || agentcountry == '' || agentcountry == null){
      setpineror('Please Select Country First')
    }else{
      setpineror('')
    }
  }



  const countries = csc.getAllCountries();

const updatedCountries = countries.map((country) => ({
  label: country.name,
  value: country.id,
  ...country
}));


const updatedStates = (countryId) =>
  csc.getStatesOfCountry(countryId)
  .map((state) => ({ label: state.name, value: state.id, ...state }));
const updatedCities = (stateId) =>

  csc.getCitiesOfState(stateId)
  .map((city) => ({ label: city.name, value: city.id, ...city }));

  // document verification start here 
  function generateUniqueRandomNumber() {
    return Math.floor(Math.random() * 10000) + Date.now();
  }
  const randomNumber = generateUniqueRandomNumber();


const handleImageChange11 = () => {
  setthumbimg2('')
  setagentr_thumb('');
  setrtferror(true)
  setTimeout(() => {
  const client = new CaptureFinger()
 setthumbimg2(client.data.BitmapData)
 setagentr_thumb(client.data.BitmapData);
 setansithumbimg2(client.data.AnsiTemplate)
 if(client.data.BitmapData){
  setrightnumberoffingers(1)
  setrtferror(false)
}
}, 1000);
};
// const handleImageChange12 = (event) => {
//   const file = event.target.files[0];
//   setcr_index_finger(file);

//   if (file) {
//     const reader = new FileReader();

//     reader.addEventListener('load', () => {
//       setrifimg2(reader.result);
//     });

//     reader.readAsDataURL(file);
//   }
// };
const handleImageChange12 = () => {
  setrifimg2('')
  setagentr_index_finger('');
  setriferror(true)
  setTimeout(() => {
  const client = new CaptureFinger();
  setrifimg2(client.data.BitmapData)
  setagentr_index_finger(client.data.BitmapData);
  setansirifimg2(client.data.AnsiTemplate)
  if(client.data.BitmapData){
    setrightnumberoffingers(1)
    setriferror(false)
  }
}, 1000);
};
// const handleImageChange13 = (event) => {
//   const file = event.target.files[0];
//   setcr_middle_finger(file);

//   if (file) {
//     const reader = new FileReader();

//     reader.addEventListener('load', () => {
//       setrmfimg2(reader.result);
//     });

//     reader.readAsDataURL(file);
//   }
// };
const handleImageChange13 = () => {
  setrmfimg2('')
  setagentr_middle_finger('')
  setrmferror(true)
  setTimeout(() => {
  const client = new CaptureFinger();
  setrmfimg2(client.data.BitmapData)
  setagentr_middle_finger(client.data.BitmapData)
  setansirmfimg2(client.data.AnsiTemplate)
  if(client.data.BitmapData){
    setrightnumberoffingers(1)
    setrmferror(false)
  }
}, 1000);
};
// const handleImageChange14 = (event) => {
//   const file = event.target.files[0];
//   setcr_ring_finger(file);

//   if (file) {
//     const reader = new FileReader();

//     reader.addEventListener('load', () => {
//       setrrfimg2(reader.result);

//     });

//     reader.readAsDataURL(file);
//   }
// };
const handleImageChange14 = () => {
  setrrfimg2('')
  setagentr_ring_finger('')
  setrrferror(true)
  setTimeout(() => {
  const client = new CaptureFinger();
  setrrfimg2(client.data.BitmapData)
  setagentr_ring_finger(client.data.BitmapData)
  setansirrfimg2(client.data.AnsiTemplate)
  if(client.data.BitmapData){
    setrightnumberoffingers(1)
    setrrferror(false)
  }
}, 1000);
};
// const handleImageChange15 = (event) => {
//   const file = event.target.files[0];
//   setcr_little_finger(file);

//   if (file) {
//     const reader = new FileReader();

//     reader.addEventListener('load', () => {
//       setrlfimg2(reader.result);
//     });

//     reader.readAsDataURL(file);
//   }
// };
const changedocument = (e) =>{
if(e == "india"){
  setdocumenttype("PAN");
}else{
  setdocumenttype("PASSPORT");
  setnotapplicable(false)
}
}
const handleImageChange15 = () => {
  setrlfimg2('')
  setagentr_little_finger('')
  setrlferror(true)
  setTimeout(() => {
  const client = new CaptureFinger();
  setrlfimg2(client.data.BitmapData)
  setagentr_little_finger(client.data.BitmapData)
  setansirlfimg2(client.data.AnsiTemplate)
  if(client.data.BitmapData){
    setrightnumberoffingers(1)
    setrlferror(false)
  }
}, 1000);
};
// const handleImageChange16 = (event) => {
//   const file = event.target.files[0];
//   setcl_thumb(file);

//   if (file) {
//     const reader = new FileReader();

//     reader.addEventListener('load', () => {
//       setltfimg2(reader.result);

//     });

//     reader.readAsDataURL(file);
//   }
// };
const handleImageChange16 = () => {
  setltfimg2('')
  setagentl_thumb('')
  setltferror(true)
  setTimeout(() => {
  const client = new CaptureFinger();
  setltfimg2(client.data.BitmapData)
  setagentl_thumb(client.data.BitmapData)
  setansiltfimg2(client.data.AnsiTemplate)
  if(client.data.BitmapData){
    setleftnumberoffingers(1)
    setltferror(false)
  }
}, 1000);
};
// const handleImageChange17 = (event) => {
//   const file = event.target.files[0];
//   setcl_index_finger(file);

//   if (file) {
//     const reader = new FileReader();

//     reader.addEventListener('load', () => {
//       setlifimg2(reader.result);
//     });

//     reader.readAsDataURL(file);
//   }
// };
const handleImageChange17 = () => {
  setlifimg2('')
  setagentl_index_finger('')
  setliferror(true)
  setTimeout(() => {
  const client = new CaptureFinger();
  setlifimg2(client.data.BitmapData)
  setagentl_index_finger(client.data.BitmapData)
  setansilifimg2(client.data.AnsiTemplate)
  if(client.data.BitmapData){
    setleftnumberoffingers(1)
    setliferror(false)
  }
}, 1000);
};
// const handleImageChange18 = (event) => {
//   const file = event.target.files[0];
//   setcl_middle_finger(file);

//   if (file) {
//     const reader = new FileReader();

//     reader.addEventListener('load', () => {
//       setlmfimg2(reader.result);
//     });

//     reader.readAsDataURL(file);
//   }
// };
const handleImageChange18 = () => {
  setlmfimg2('')
  setagentl_middle_finger('')
  setlmferror(true)
  setTimeout(() => {
  const client = new CaptureFinger();
  setlmfimg2(client.data.BitmapData)
  setagentl_middle_finger(client.data.BitmapData)
  setansilmfimg2(client.data.AnsiTemplate)
  if(client.data.BitmapData){
    setleftnumberoffingers(1)
    setlmferror(false)
  }
}, 1000);
};
// const handleImageChange19 = (event) => {
//   const file = event.target.files[0];
//   setcl_ring_finger(file);

//   if (file) {
//     const reader = new FileReader();

//     reader.addEventListener('load', () => {
//       setlrfimg2(reader.result);
//     });

//     reader.readAsDataURL(file);
//   }
// };
const handleImageChange19 = () => {
  setlrfimg2('')
  setagentl_ring_finger('')
  setlrferror(true)
  setTimeout(() => {
  const client = new CaptureFinger();
  setlrfimg2(client.data.BitmapData)
  setagentl_ring_finger(client.data.BitmapData)
  setansilrfimg2(client.data.AnsiTemplate)
  if(client.data.BitmapData){
    setleftnumberoffingers(1)
    setlrferror(false)
  }
}, 1000);
};
// const handleImageChange20 = (event) => {
//   const file = event.target.files[0];
//   setcl_little_finger(file);

//   if (file) {
//     const reader = new FileReader();

//     reader.addEventListener('load', () => {
//       setllfimg2(reader.result);
//     });

//     reader.readAsDataURL(file);
//   }
// };

const handleImageChange20 = () => {
  setllfimg2('')
  setagentl_little_finger('')
  setllferror(true)
  setTimeout(() => {
  const client = new CaptureFinger();
  setllfimg2(client.data.BitmapData)
  setagentl_little_finger(client.data.BitmapData)
  setansillfimg2(client.data.AnsiTemplate)
  if(client.data.BitmapData){
    setleftnumberoffingers(1)
    setllferror(false)
  }
}, 1000);
};


const captureimage123 = (num) =>{
  setdisplaydocument(webcamRefs[num].current.getScreenshot())
  sethideorshow(!hideorshow)
}

const handleImageChange24 = (event) => {
  const file = event.target.files[0];
  setdocumentimageback(file);

  if (file) {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      setdocumentimagebacktwo(reader.result);

    });

    reader.readAsDataURL(file);
  }
};









const [artferror, setartferror] = useState('')
const handleImageChange110 = () => {
  setthumbimg2sand('')
  setthumbimg5('');
  setansithumbimg5('')
  setrtf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_r_thumb);
  if(client.data.Status){
    setthumbimg2sand(formdatao.r_thumb)
    setthumbimg5(formdatao.r_thumb);
    setansithumbimg5(formdatao.ansi_r_thumb)
    setrightnumberoffingers12(1)
        setartferror('')
  }else{
    // console.log("980090000")
    setartferror(" Not Match")
  }
  setrtf3error(false)
}, 1000);
};
const [ariferror, setariferror] = useState('')
const handleImageChange120 = () => {
  setrifimg2sand('')
  setrifimg5('');
  setansirifimg5();
  setrif3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_r_index_finger);
if(client.data.Status){
  // console.log("3454355")
  setrifimg2sand(formdatao.r_index_finger)
  setrifimg5(formdatao.r_index_finger);
      setrightnumberoffingers12(1)
      setariferror('')
      setansirifimg5(formdatao.ansi_r_index_finger)
}else{
  // console.log("980090000")
  setariferror(" Not Match")
}
setrif3error(false)
}, 1000);
};
const [armferror, setarmferror] = useState('')
const handleImageChange130 = () => {
  setrmfimg2sand('')
  setrmfimg5('');
  setrmf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_r_middle_finger);
  if(client.data.Status){
    // console.log("3454355")
    setrmfimg2sand(formdatao.r_middle_finger)
    setrmfimg5(formdatao.r_middle_finger);
    setansirmfimg5(formdatao.ansi_r_middle_finger)
    setrightnumberoffingers12(1)
        setarmferror('')
  }else{
    // console.log("980090000")
    setarmferror(" Not Match")
  }
  setrmf3error(false)
}, 1000);
};


const [arrferror, setarrferror] = useState('')
const handleImageChange140 = () => {
  setrrfimg2sand('')
  setrrfimg5('');
  setrrf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_r_ring_finger);
  if(client.data.Status){
    // console.log("3454355")
    setrrfimg2sand(formdatao.r_ring_finger)
    setrrfimg5(formdatao.r_ring_finger);
    setansirrfimg5(formdatao.ansi_r_ring_finger)
    setrightnumberoffingers12(1)
        setarrferror('')
  }else{
    // console.log("980090000")
    setarrferror(" Not Match")
  }
  setrrf3error(false)
}, 1000);
};


const [arlferror, setarlferror] = useState('')

const handleImageChange150 = () => {
  setrlfimg2sand('')
  setrlfimg5('');
  setrlf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_r_little_finger);
  if(client.data.Status){
    // console.log("3454355")
    setrlfimg2sand(formdatao.r_little_finger)
    setrlfimg5(formdatao.r_little_finger);
    setansirlfimg5(formdatao.ansi_r_little_finger)
    setrightnumberoffingers12(1)
        setarlferror('')
  }else{
    // console.log("980090000")
    setarlferror(" Not Match")
  }
  setrlf3error(false)
}, 1000);
};
const [altferror, setaltferror] = useState('')

const handleImageChange160 = () => {
  setltfimg2sand('')
    setltfimg5('');
  setltf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_l_thumb);
  if(client.data.Status){
    // console.log("3454355")
    setltfimg2sand(formdatao.l_thumb)
    setltfimg5(formdatao.l_thumb);
    setansiltfimg5(formdatao.ansi_l_thumb)
    setrightnumberoffingers12(1)
        setaltferror('')
  }else{
    // console.log("980090000")
    setaltferror(" Not Match")
  }
  setltf3error(false)
}, 1000);
};

const [aliferror, setaliferror] = useState('')

const handleImageChange170 = () => {
  setlifimg2sand('')
  setlifimg5('');
  setlif3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_l_index_finger);
  if(client.data.Status){
    // console.log("3454355")
    setlifimg2sand(formdatao.l_index_finger)
    setlifimg5(formdatao.l_index_finger);
    setansilifimg5(formdatao.ansi_l_index_finger)
    setrightnumberoffingers12(1)
        setaliferror('')
  }else{
    // console.log("980090000")
    setaliferror(" Not Match")
  }
  setlif3error(false)
}, 1000);
};

const [almferror, setalmferror] = useState('')

const handleImageChange180 = () => {
  setlmfimg2sand('')
    setlmfimg5('');
  setlmf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_l_middle_finger);
  if(client.data.Status){
    // console.log("3454355")
    setlmfimg2sand(formdatao.l_middle_finger)
    setlmfimg5(formdatao.l_middle_finger);
    setansilmfimg5(formdatao.ansi_l_middle_finger)
    setrightnumberoffingers12(1)
        setalmferror('')
  }else{
    // console.log("980090000")
    setalmferror(" Not Match")
  }
  setlmf3error(false)
}, 1000);
};
const [alrferror, setalrferror] = useState('')

const handleImageChange190 = () => {
  setlrfimg2sand('')
    setlrfimg5('');
  setlrf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_l_ring_finger);
  if(client.data.Status){
    // console.log("3454355")
    setlrfimg2sand(formdatao.l_ring_finger)
    setlrfimg5(formdatao.l_ring_finger);
    setansilrfimg5(formdatao.ansi_l_ring_finger)
    setrightnumberoffingers12(1)
    setalrferror('')
  }else{
    // console.log("980090000")
    setalrferror(" Not Match")
  }
  setlrf3error(false)
}, 1000);
};
const [allferror, setallferror] = useState('')

const handleImageChange200 = () => {
  setllfimg2sand('')
    setllfimg5('');
  setllf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_l_little_finger);
  if(client.data.Status){
    // console.log("3454355")
    setllfimg2sand(formdatao.l_little_finger)
    setllfimg5(formdatao.l_little_finger);
    setansillfimg5(formdatao.ansi_l_little_finger)
    setrightnumberoffingers12(1)
        setallferror('')
  }else{
    // console.log("980090000")
    setallferror(" Not Match")
  }
  setllf3error(false)
}, 1000);
};

const [reloadin,setreloadin] = useState(true)


const reloadcam = ()=>{
setreloadin(false)
setTimeout(() => {
setreloadin(true)
}, 1000);
}


const changefrontimg = (e) =>{
  // console.log("qqqqqqqqqqqqqqqq",URL.createObjectURL(e))

  const reader = new FileReader();
  reader.readAsDataURL(e);
  reader.onload = () => {
    // console.log("oooooo",reader.result)
    if(documenttype == 'AADHAAR'){
      const modifiedImageUrl = modifyImage(reader.result,varx,vary);
      setModifiedImage(modifiedImageUrl);
      setAadhaarImage(reader.result)
    }else{
      setModifiedImage(reader.result);
    }
    
  };
}



const changebackimg = (e) =>{



  const reader = new FileReader();
  reader.readAsDataURL(e);
  reader.onload = () => {
    // console.log("oooooo",reader.result)

    if(documenttype == 'AADHAAR'){
      const modifiedImageUrl = backmodifyImage(reader.result,varxtwo,varytwo);
      setdocumentimagebacktwo(modifiedImageUrl);
      setAadhaarImagetwo(reader.result)
    }else{
      setdocumentimagebacktwo(reader.result);
  
    }
  }
 

  // setdisplayphototwo(pickimgtwo.current.getScreenshot())
}

const twochangefrontimg = (e) =>{
  const reader = new FileReader();
  reader.readAsDataURL(e);
  reader.onload = () => {
    // console.log("oooooo",reader.result)
    
    if(documenttypetwo == 'AADHAAR'){
      const modifiedImageUrl = modifyImagethree(reader.result,varx,vary);
      setModifiedImagenumbertwo(modifiedImageUrl);
      setAadhaarImage2(reader.result)
    }else{
      setModifiedImagenumbertwo(reader.result);
    }

  }
}


const twochangebackimg = (e) =>{

  const reader = new FileReader();
  reader.readAsDataURL(e);
  reader.onload = () => {
    // console.log("oooooo",reader.result)
    
  if(documenttypetwo == 'AADHAAR'){
    const modifiedImageUrl = backmodifyImage3(reader.result,varxtwo,varytwo);
    setdocumentimageback2(modifiedImageUrl);
    setAadhaarback2(reader.result)
  }else{
    setdocumentimageback2(reader.result);

  }
  }
  // setdisplayphototwo(pickimgtwo.current.getScreenshot())
}
  useEffect(() => {
    async function fetchData() {
      setsrtloader(true)
      const config = {
        headers: {
          Authorization: `Bearer ${gettokinval.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}api/active_company/`;
      const response = await axios.get(url, config);
      setData(response.data);
      setsrtloader(false)
    }

   
    fetchData();
    // fetchDatatwo();
  }, [setData, setDatatwo]);
  async function fetchDatatwo(id) {
    setsrtloader(true)
    const config = {
      headers: {
        Authorization: `Bearer ${gettokinval.access}`,
      },
    };
    let url = `${process.env.REACT_APP_API_URL}api/active_company/${id}/active_location/`;
    const response = await axios.get(url, config);
    setDatatwo(response.data);
    setsrtloader(false)
  }


  const changedocumetimg = (e) =>{
    // if(e == 'AADHAAR'){
    //   if(modifiedImage !== null){
    //     const modifiedImageUrl = modifyImage(modifiedImage,varx,vary);
    //     setModifiedImage(modifiedImageUrl);
    //     setAadhaarImage(modifiedImage)
    //   }
    // }else{
      setModifiedImage(null);
        setAadhaarImage(null)
        setdocumentimagebacktwo(null)
        setAadhaarImagetwo(null)
        setAadhaarImage2(null);
        setModifiedImagenumbertwo(null)
        setAadhaarback2(null)
        setdocumentimageback2(null)
    // }
  }



  const changedocumetimgtwo = (e) =>{
    // if(e == 'AADHAAR'){
    //   if(modifiedImage !== null){
    //     const modifiedImageUrl = modifyImage(modifiedImage,varx,vary);
    //     setModifiedImage(modifiedImageUrl);
    //     setAadhaarImage(modifiedImage)
    //   }
    // }else{
      setAadhaarImage2(null);
      setModifiedImagenumbertwo(null)
      setAadhaarback2(null)
      setdocumentimageback2(null)
    // }
  }


  
  
const abc = 123;

  async function minsubmitform(numberststus,shorornot) {
    // if(registerpro == true || registerpromobile == true){
  
      if(agentstate == undefined || agentstate == '' || agentstate == null || agentcity == undefined || agentcity == '' || agentcity == null){
        if(agentstate == undefined || agentstate == '' || agentstate == null){
          setstateerror('State is Required')
        }else{
          setstateerror('')
        }
        if(agentcity == undefined || agentcity == '' || agentcity == null){
          setcityerror('City is Required')
        }else{
          setcityerror('')
        }
      }else{
        setstateerror('')
        setcityerror('')
      if (agentcompany === "") {
        setDataerrorstatus(true);
        setDataerrortxt("please select company");
      } else {
        setDataerrorstatus(false);
        if (agentlocation === "") {
                 setDataerrorstatus(false);
          setDataerrortxt("");
          setDatatwoerrorstatus(true);
          setDatatwoerrortxt("please select Location");
        } else {
          setDatatwoerrorstatus(false);
          setDatatwoerrortxt("");
  
  
  
          if(rightnumberoffingers12 == 0 && gettokinval.email !== "test@gmail.com" && shorornot == false ){
                     setfingerperror12(true)
            // setrigthfingerserror12('Capture atleast One Finger in Your Right Hand')
            setrigthfingerserror12('Verify atleast One Finger Print')
          }else{
            setrigthfingerserror12('')
  
            // if(leftnumberoffingers12 == 0 && gettokinval.email !== "test@gmail.com"){
            //               setfingerperror12(true)
            //   setrigthfingerserror12('Capture atleast One Finger in Your Left Hand')
            //   }else{
    
                setfingerperror12(false)
            //     setrigthfingerserror12('')
  
                if(rightnumberoffingers == 0 && gettokinval.email !== "test@gmail.com" && shorornot == false){
                                  setfingerperror(true)
                  setrigthfingerserror('Capture atleast One Finger in Your Right Hand')
        
                }else{
                  setrigthfingerserror('')
                  if(leftnumberoffingers == 0 && gettokinval.email !== "test@gmail.com" && shorornot == false){
                    setfingerperror(true)
                    setrigthfingerserror('Capture atleast One Finger in Your Left Hand')
                  }else{
                      setfingerperror(false)
                      if(displayphoto == '' && shorornot == false){
                            setsandphotoerror(true)
                      }else{
                        setsandphotoerror(false)
                        if(numberststus == true){
                          console.log("mobile respose")
                        if(notapplicable == false){
                          //  pan card not available
  
                          if(documenttype == "defualt" || documenttype == undefined){
                            setdocumentprivateerror(true)
                          }else{
                            setdocumentprivateerror(false)
                            if(modifiedImage == null){
                              setdfierror(true)
                              setdrireserror('Please Capture Your Document')
                            }else{
                              setdfierror(false)
                              
                              if(documentimagebacktwo == null){
                                setdbierror(true)
                                setdbireserror('Please Capture Your Document')
                              }else{
                                setdbierror(false)
                                if(documenttypetwo == "defualt" || documenttypetwo == undefined){
                                  setdocumentprivateerrortwo(true)
                                }else{
                                  setdocumentprivateerrortwo(false)
                                  if(modifiedImagenumbertwo == null){
                                    setdfierrortwo(true)
                                    setdrireserrortwo('Please Capture Your Document')
                                  }else{
                                    setdfierrortwo(false)
                                    if(documentimageback2 == null){
                                      setdbierrortwo(true)
                                      setdbireserrortwo('Please Capture Your Document')
                                    }else{
                                      setdbierrortwo(false)
                                      if (agentpassword == agentconfirmpassword) {
                                        setloading(true);
                                        setfingerperror(false)
                                        const formdata = new FormData();
                                        formdata.append("username",agentusername);
                                        formdata.append("email",agentemail);
                                        formdata.append("mobile_no",agentmobile);
                                        formdata.append("nationality",agentnationality);
                                        formdata.append("password",agentpassword);
                                        formdata.append("first_name",agentname);
                                        formdata.append("last_name",agentlast);
                                        formdata.append("gender",agentgender);
                                      formdata.append('photograph',displayphoto)
                                      formdata.append('fathersFullNm',ffn)
                                      formdata.append('document_type2',documenttypetwo)
                                      formdata.append('document_no2',idnumbertwo)
    
    
    
    if(agentnationality == "india"){
      if(notapplicable == false){
        formdata.append('document_type',documenttype)
        formdata.append('document_no',idnumber)
        formdata.append("docImg_front", modifiedImage);
        formdata.append("docImg_back", documentimagebacktwo);                      
        if(documentexpiry != undefined || documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT'){
          formdata.append('expiry_date',documentexpiry)
        }else{
          // console.log('not expiry date')
        }
        if(documentissue != undefined || documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT'){
          formdata.append('issue_date',documentissue)
        }else{
          // console.log('not issue date')
        }
      }else{
        if(displaydocument !== ''){
          formdata.append("Form_60_image", displaydocument);
        }
    
        if(form60 !== null){
          formdata.append("Form_60_pdf", form60);
        }
      }
    }else{
      formdata.append('document_type',documenttype)
      formdata.append('document_no',idnumber)
      formdata.append("docImg_front", modifiedImage);
      formdata.append("docImg_back", documentimagebacktwo);                      
      if(documentexpiry != undefined || documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT'){
        formdata.append('expiry_date',documentexpiry)
      }else{
        // console.log('not expiry date')
      }
      if(documentissue != undefined || documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT'){
        formdata.append('issue_date',documentissue)
      }else{
        // console.log('not issue date')
      }
    }
    
    
    
                                     
    
    
                                      if(documentexpirytwo != undefined || documenttypetwo == 'DRIVING_LICENSE' || documenttypetwo == "RESIDENT_PERMIT" || documenttypetwo == "VISA" || documenttypetwo == "REFUGEE_CARD" || documenttypetwo == 'PASSPORT'){
                                        formdata.append('expiry_date2',documentexpirytwo)
                                      }else{
                                        // console.log('not expiry date')
                                      }
                                      if(documentissuetwo != undefined || documenttypetwo == 'DRIVING_LICENSE' || documenttypetwo == "RESIDENT_PERMIT" || documenttypetwo == "VISA" || documenttypetwo == "REFUGEE_CARD" || documenttypetwo == 'PASSPORT'){
                                        formdata.append('issue_date2',documentissuetwo)
                                      }else{
                                        // console.log('not issue date')
                                      }
                                      
                                      
                                     
                                        formdata.append("company", gettokinval.company_id);
                                        formdata.append("location", gettokinval.location_id);
                                        formdata.append("country", agentcountry.label);
                                        formdata.append("state", agentstate.label);
                                        formdata.append("city", agentcity.label);
                                        formdata.append("pincode", agentpincode);
                                        formdata.append("address", agentaddress);
                                        formdata.append("dob", dob);
                              
                                        formdata.append("Approver", agentapprover);
                                        formdata.append("Checker", agentchecker);
                                        formdata.append("Maker", agentmaker);
                                        // formdata.append("r_thumb", agentr_thumb);
                                        // formdata.append("r_index_finger", agentr_index_finger);
                                        // formdata.append("r_middle_finger", agentr_middle_finger);
                                        // formdata.append("r_ring_finger", agentr_ring_finger);
                                        // formdata.append("r_little_finger", agentr_little_finger);
                                        // formdata.append("l_thumb", agentl_thumb);
                                        // formdata.append("l_index_finger", agentl_index_finger);
                                        // formdata.append("l_middle_finger", agentl_middle_finger);
                                        // formdata.append("l_ring_finger", agentl_ring_finger);
                                        // formdata.append("l_little_finger", agentl_little_finger);
                                        
                                        
                                        
                                        
                
                                        // if(agentr_thumb !== ''){
                                        //   formdata.append("r_thumb", agentr_thumb);
                                        //   formdata.append("ansi_r_thumb", ansithumbimg2);
                                        // }
                                        // if(agentr_index_finger !== ''){
                                        //   formdata.append("r_index_finger", agentr_index_finger);
                                        //   formdata.append("ansi_r_index_finger", ansirifimg2);
                                        // }
                                        // if(agentr_middle_finger !== ''){
                                        //   formdata.append("r_middle_finger", agentr_middle_finger);
                                        //   formdata.append("ansi_r_middle_finger", ansirmfimg2);
                                        // }
                                        // if(agentr_ring_finger !== ''){
                                        //   formdata.append("r_ring_finger", agentr_ring_finger);
                                        //   formdata.append("ansi_r_ring_finger", ansirrfimg2);
                                        // }
                                        // if(agentr_little_finger !== ''){
                                        //   formdata.append("r_little_finger", agentr_little_finger);
                                        //   formdata.append("ansi_r_little_finger", ansirlfimg2);
                                        // }
                                        // if(agentl_thumb !== ''){
                                        //   formdata.append("l_thumb", agentl_thumb);
                                        //   formdata.append("ansi_l_thumb", ansiltfimg2);
                                        // }
                                        // if(agentl_index_finger !== ''){
                                        //   formdata.append("l_index_finger", agentl_index_finger);
                                        //   formdata.append("ansi_l_index_finger", ansilifimg2);
                                        // }
                                        // if(agentl_middle_finger !== ''){
                                        //   formdata.append("l_middle_finger", agentl_middle_finger);
                                        //   formdata.append("ansi_l_middle_finger", ansilmfimg2);
                                        // }
                                        // if(agentl_ring_finger !== ''){
                                        //   formdata.append("l_ring_finger", agentl_ring_finger);
                                        //   formdata.append("ansi_l_ring_finger", ansilrfimg2);
                                        // }
                                        // if(agentl_little_finger !== ''){
                                        //   formdata.append("l_little_finger", agentl_little_finger);
                                        //   formdata.append("ansi_l_little_finger", ansillfimg2);
                                        // }
                
                
                
                
    
                                        formdata.append("docImg_front2", modifiedImagenumbertwo);
                                        formdata.append("docImg_back2", documentimageback2);
                                        // if(documentimageback !== null){
                                        // }
                                        try {
                                          const config = {
                                            headers: {
                                              Authorization: `Bearer ${gettokinval.access}`,
                                            },
                                          };
                                          let url = `${process.env.REACT_APP_API_URL}api/agent/`;
                                          const response = await axios.post(url, formdata, config);
                                          if(response.data?.id){

                                        const formdatabio = new FormData();

                                        if(agentr_thumb !== ''){
                                          formdatabio.append("r_thumb", agentr_thumb);
                                          formdatabio.append("ansi_r_thumb", ansithumbimg2);
                                        }
                                        if(agentr_index_finger !== ''){
                                          formdatabio.append("r_index_finger", agentr_index_finger);
                                          formdatabio.append("ansi_r_index_finger", ansirifimg2);
                                        }
                                        if(agentr_middle_finger !== ''){
                                          formdatabio.append("r_middle_finger", agentr_middle_finger);
                                          formdatabio.append("ansi_r_middle_finger", ansirmfimg2);
                                        }
                                        if(agentr_ring_finger !== ''){
                                          formdatabio.append("r_ring_finger", agentr_ring_finger);
                                          formdatabio.append("ansi_r_ring_finger", ansirrfimg2);
                                        }
                                        if(agentr_little_finger !== ''){
                                          formdatabio.append("r_little_finger", agentr_little_finger);
                                          formdatabio.append("ansi_r_little_finger", ansirlfimg2);
                                        }
                                        if(agentl_thumb !== ''){
                                          formdatabio.append("l_thumb", agentl_thumb);
                                          formdatabio.append("ansi_l_thumb", ansiltfimg2);
                                        }
                                        if(agentl_index_finger !== ''){
                                          formdatabio.append("l_index_finger", agentl_index_finger);
                                          formdatabio.append("ansi_l_index_finger", ansilifimg2);
                                        }
                                        if(agentl_middle_finger !== ''){
                                          formdatabio.append("l_middle_finger", agentl_middle_finger);
                                          formdatabio.append("ansi_l_middle_finger", ansilmfimg2);
                                        }
                                        if(agentl_ring_finger !== ''){
                                          formdatabio.append("l_ring_finger", agentl_ring_finger);
                                          formdatabio.append("ansi_l_ring_finger", ansilrfimg2);
                                        }
                                        if(agentl_little_finger !== ''){
                                          formdatabio.append("l_little_finger", agentl_little_finger);
                                          formdatabio.append("ansi_l_little_finger", ansillfimg2);
                                        }
                                            const config = {
                                              headers: {
                                                Authorization: `Bearer ${gettokinval.access}`,
                                              },
                                            };
                                            let url234 = `${process.env.REACT_APP_API_URL}api/agent/${response.data.id}/`;
                                            const responsebio = await axios.patch(url234, formdatabio, config);
                                          
                                          console.log("first response of agent",response)
                                          setagentresponse(response);
                                          setloading(false);
                                          setagentusername("");
                                          setagentemail("");
                                          setagentmobile("");
                                          setagentpassword("");
                                          setagentname("");
                                          setagentlast("");
                                          setagentcompany("");
                                          setagentlocation("");
                                          setagentcountry("");
                                          setagentcity("");
                                          setagentstate("");
                                          setagentpincode("");
                                          setagentstatus("");
                                          setagentconfirmpassword("");
                                          setagentdocument_no("");
                                          setagentdocument_type("");
                                          seterrorclass(false);
                                          setmegaerror(false)
                                      setmegaerrormsg("");
                                          setagentaddress("");
                                          }
                                        } catch (error) {
console.log("firstff",error)
                                          if(error.code == "ERR_NETWORK"){
                                            setmegaerror(true)
                                            setmegaerrormsg("Request Entity Too Large. Please Upload your Images less Than 170kb");
            
                                            setTimeout(() => {
                                              setmegaerror(false)
                                            }, 7000);
                                           
                                          }else{
                                          setagentresponse({
                                            statusone: 1,
                                            message: "Something Went Wrong Try again",
                                          });
                                          seterrorcls(error.response.data);
                                        }
                                        seterrorclass(true);
                                          setloading(false);
                                        }
                                      } else {
                                        setnotmatch(true);
                                      }
                                    }
                                  }      
                                }
          
                               
                              }
                              
                            }
                     
                    }
                        }else{
                          // console.log("stage 9")
                          if(documenttypetwo == "defualt" || documenttypetwo == undefined){
                            setdocumentprivateerrortwo(true)
                          }else{
                            setdocumentprivateerrortwo(false)
                            if(modifiedImagenumbertwo == null){
                              setdfierrortwo(true)
                              setdrireserrortwo('Please Capture Your Document')
                            }else{
                              setdfierrortwo(false)
                              if(documentimageback2 == null){
                                setdbierrortwo(true)
                                setdbireserrortwo('Please Capture Your Document')
                              }else{
                                setdbierrortwo(false)
                                if (agentpassword == agentconfirmpassword) {
                                  setloading(true);
                                  setfingerperror(false)
                                  const formdata = new FormData();
                                  formdata.append("username",agentusername);
                                  formdata.append("email",agentemail);
                                  formdata.append("mobile_no",agentmobile);
                                  formdata.append("nationality",agentnationality);
                                  formdata.append("password",agentpassword);
                                  formdata.append("first_name",agentname);
                                  formdata.append("last_name",agentlast);
                                  formdata.append("gender",agentgender);
                                formdata.append('photograph',displayphoto)
                                formdata.append('fathersFullNm',ffn)
                                formdata.append('document_type2',documenttypetwo)
                                formdata.append('document_no2',idnumbertwo)
  
  
  
  if(agentnationality == "india"){
  if(notapplicable == false){
  formdata.append('document_type',documenttype)
  formdata.append('document_no',idnumber)
  formdata.append("docImg_front", modifiedImage);
  formdata.append("docImg_back", documentimagebacktwo);                      
  if(documentexpiry != undefined || documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT'){
    formdata.append('expiry_date',documentexpiry)
  }else{
    // console.log('not expiry date')
  }
  if(documentissue != undefined || documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT'){
    formdata.append('issue_date',documentissue)
  }else{
    // console.log('not issue date')
  }
  }else{
  if(displaydocument !== ''){
    formdata.append("Form_60_image", displaydocument);
  }
  
  if(form60 !== null){
    formdata.append("Form_60_pdf", form60);
  }
  }
  }else{
  formdata.append('document_type',documenttype)
  formdata.append('document_no',idnumber)
  formdata.append("docImg_front", modifiedImage);
  formdata.append("docImg_back", documentimagebacktwo);                      
  if(documentexpiry != undefined || documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT'){
  formdata.append('expiry_date',documentexpiry)
  }else{
  // console.log('not expiry date')
  }
  if(documentissue != undefined || documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT'){
  formdata.append('issue_date',documentissue)
  }else{
  // console.log('not issue date')
  }
  }
  
  
  
                               
  
  
                                if(documentexpirytwo != undefined || documenttypetwo == 'DRIVING_LICENSE' || documenttypetwo == "RESIDENT_PERMIT" || documenttypetwo == "VISA" || documenttypetwo == "REFUGEE_CARD" || documenttypetwo == 'PASSPORT'){
                                  formdata.append('expiry_date2',documentexpirytwo)
                                }else{
                                  // console.log('not expiry date')
                                }
                                if(documentissuetwo != undefined || documenttypetwo == 'DRIVING_LICENSE' || documenttypetwo == "RESIDENT_PERMIT" || documenttypetwo == "VISA" || documenttypetwo == "REFUGEE_CARD" || documenttypetwo == 'PASSPORT'){
                                  formdata.append('issue_date2',documentissuetwo)
                                }else{
                                  // console.log('not issue date')
                                }
                                
                                
                               
                                  formdata.append("company", gettokinval.company_id);
                                  formdata.append("location", gettokinval.location_id);
                                  formdata.append("country", agentcountry.label);
                                  formdata.append("state", agentstate.label);
                                  formdata.append("city", agentcity.label);
                                  formdata.append("pincode", agentpincode);
                                  formdata.append("address", agentaddress);
                                  formdata.append("dob", dob);
                        
                                  formdata.append("Approver", agentapprover);
                                  formdata.append("Checker", agentchecker);
                                  formdata.append("Maker", agentmaker);
                                  // formdata.append("r_thumb", agentr_thumb);
                                  // formdata.append("r_index_finger", agentr_index_finger);
                                  // formdata.append("r_middle_finger", agentr_middle_finger);
                                  // formdata.append("r_ring_finger", agentr_ring_finger);
                                  // formdata.append("r_little_finger", agentr_little_finger);
                                  // formdata.append("l_thumb", agentl_thumb);
                                  // formdata.append("l_index_finger", agentl_index_finger);
                                  // formdata.append("l_middle_finger", agentl_middle_finger);
                                  // formdata.append("l_ring_finger", agentl_ring_finger);
                                  // formdata.append("l_little_finger", agentl_little_finger);
                                  
                                  
                                  
                                  
          
                                  // if(agentr_thumb !== ''){
                                  //   formdata.append("r_thumb", agentr_thumb);
                                  //   formdata.append("ansi_r_thumb", ansithumbimg2);
                                  // }
                                  // if(agentr_index_finger !== ''){
                                  //   formdata.append("r_index_finger", agentr_index_finger);
                                  //   formdata.append("ansi_r_index_finger", ansirifimg2);
                                  // }
                                  // if(agentr_middle_finger !== ''){
                                  //   formdata.append("r_middle_finger", agentr_middle_finger);
                                  //   formdata.append("ansi_r_middle_finger", ansirmfimg2);
                                  // }
                                  // if(agentr_ring_finger !== ''){
                                  //   formdata.append("r_ring_finger", agentr_ring_finger);
                                  //   formdata.append("ansi_r_ring_finger", ansirrfimg2);
                                  // }
                                  // if(agentr_little_finger !== ''){
                                  //   formdata.append("r_little_finger", agentr_little_finger);
                                  //   formdata.append("ansi_r_little_finger", ansirlfimg2);
                                  // }
                                  // if(agentl_thumb !== ''){
                                  //   formdata.append("l_thumb", agentl_thumb);
                                  //   formdata.append("ansi_l_thumb", ansiltfimg2);
                                  // }
                                  // if(agentl_index_finger !== ''){
                                  //   formdata.append("l_index_finger", agentl_index_finger);
                                  //   formdata.append("ansi_l_index_finger", ansilifimg2);
                                  // }
                                  // if(agentl_middle_finger !== ''){
                                  //   formdata.append("l_middle_finger", agentl_middle_finger);
                                  //   formdata.append("ansi_l_middle_finger", ansilmfimg2);
                                  // }
                                  // if(agentl_ring_finger !== ''){
                                  //   formdata.append("l_ring_finger", agentl_ring_finger);
                                  //   formdata.append("ansi_l_ring_finger", ansilrfimg2);
                                  // }
                                  // if(agentl_little_finger !== ''){
                                  //   formdata.append("l_little_finger", agentl_little_finger);
                                  //   formdata.append("ansi_l_little_finger", ansillfimg2);
                                  // }
          
          
          
          
  
                                  formdata.append("docImg_front2", modifiedImagenumbertwo);
                                  formdata.append("docImg_back2", documentimageback2);
                                  // if(documentimageback !== null){
                                  // }
                                  try {
                                    const config = {
                                      headers: {
                                        Authorization: `Bearer ${gettokinval.access}`,
                                      },
                                    };
                                    let url = `${process.env.REACT_APP_API_URL}api/agent/`;
                                    const response = await axios.post(url, formdata, config);

                                    if(response.data?.id){

                                      const formdatabio = new FormData();

                                      if(agentr_thumb !== ''){
                                        formdatabio.append("r_thumb", agentr_thumb);
                                        formdatabio.append("ansi_r_thumb", ansithumbimg2);
                                      }
                                      if(agentr_index_finger !== ''){
                                        formdatabio.append("r_index_finger", agentr_index_finger);
                                        formdatabio.append("ansi_r_index_finger", ansirifimg2);
                                      }
                                      if(agentr_middle_finger !== ''){
                                        formdatabio.append("r_middle_finger", agentr_middle_finger);
                                        formdatabio.append("ansi_r_middle_finger", ansirmfimg2);
                                      }
                                      if(agentr_ring_finger !== ''){
                                        formdatabio.append("r_ring_finger", agentr_ring_finger);
                                        formdatabio.append("ansi_r_ring_finger", ansirrfimg2);
                                      }
                                      if(agentr_little_finger !== ''){
                                        formdatabio.append("r_little_finger", agentr_little_finger);
                                        formdatabio.append("ansi_r_little_finger", ansirlfimg2);
                                      }
                                      if(agentl_thumb !== ''){
                                        formdatabio.append("l_thumb", agentl_thumb);
                                        formdatabio.append("ansi_l_thumb", ansiltfimg2);
                                      }
                                      if(agentl_index_finger !== ''){
                                        formdatabio.append("l_index_finger", agentl_index_finger);
                                        formdatabio.append("ansi_l_index_finger", ansilifimg2);
                                      }
                                      if(agentl_middle_finger !== ''){
                                        formdatabio.append("l_middle_finger", agentl_middle_finger);
                                        formdatabio.append("ansi_l_middle_finger", ansilmfimg2);
                                      }
                                      if(agentl_ring_finger !== ''){
                                        formdatabio.append("l_ring_finger", agentl_ring_finger);
                                        formdatabio.append("ansi_l_ring_finger", ansilrfimg2);
                                      }
                                      if(agentl_little_finger !== ''){
                                        formdatabio.append("l_little_finger", agentl_little_finger);
                                        formdatabio.append("ansi_l_little_finger", ansillfimg2);
                                      }
                                          const config = {
                                            headers: {
                                              Authorization: `Bearer ${gettokinval.access}`,
                                            },
                                          };
                                          let url234 = `${process.env.REACT_APP_API_URL}api/agent/${response.data.id}/`;
                                          const responsebio = await axios.patch(url234, formdatabio, config);
                                        
                                    console.log("first response of agent",response)
                                    setmegaerror(false)
                                      setmegaerrormsg("");
                                    setagentresponse(response);
                                    setloading(false);
                                    setagentusername("");
                                    setagentemail("");
                                    setagentmobile("");
                                    setagentpassword("");
                                    setagentname("");
                                    setagentlast("");
                                    setagentcompany("");
                                    setagentlocation("");
                                    setagentcountry("");
                                    setagentcity("");
                                    setagentstate("");
                                    setagentpincode("");
                                    setagentstatus("");
                                    setagentconfirmpassword("");
                                    setagentdocument_no("");
                                    setagentdocument_type("");
                                    seterrorclass(false);
                                    setagentaddress("");
                                        }
                                  } catch (error) {

console.log("first",error)
                                    if(error.code = "ERR_NETWORK"){
                                      setmegaerror(true)
                                      setmegaerrormsg("Request Entity Too Large. Please Upload your Images less Than 170kb");
      
                                      setTimeout(() => {
                                        setmegaerror(false)
                                      }, 7000);
                                     
                                    }else{
                                    setagentresponse({
                                      statusone: 1,
                                      message: "Something Went Wrong Try again",
                                    });
                                    seterrorcls(error.response.data);
                                  }
                                    seterrorclass(true);
                                    setloading(false);
                                  }
                                } else {
                                  setnotmatch(true);
                                }
                              }
                            }      
                          }
                        }
                          //  pan card not available
                        }else{
                          console.log("send otp success")
                          // setshowsuperotp(true)
                          sendotp()
                          // sendotpmobile()
                        }
                        
                        // console.log("check doc7et ",idnumbertwo,documentexpirytwo,documentissuetwo,documenttypetwo,modifiedImagenumbertwo,documentimageback2)
                    
                  }
                      }
              }
              // }  
  
           
          }
         
        }
      }
    }
 
    // }else{
    //   setverifyyourotp(true)
    //   setTimeout(() => {
    //     setverifyyourotp(false)
    //   }, 7000);
    // }
  }

  const [doberror,setdoberror] = useState(false)
  async function submitform(e) {
  
    e.preventDefault();
    const currentDate = new Date();
    const birthDate = new Date(dob);
    const ageDifference = currentDate.getFullYear() - birthDate.getFullYear();
    if (ageDifference < 18) {
      setdoberror(true)
    }else{
      setdoberror(false)

    if(passotp == true){
      if(isformonecheck == true){
        minsubmitform(true,true)
      }else{
        minsubmitform(true,false)
      }
    }else{
      if(isformonecheck == true){
        minsubmitform(false,true)
      }else{
        minsubmitform(false,false)
      }
    }
  }
}




  useEffect(() => {
    async function fetchBio() {
      const config = {
        headers: {
          Authorization: `Bearer ${gettokinval.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}api/agent-biometric/${gettokinval.id}/`;
      const response = await axios.get(url, config);
      // setfname(response.data.customer_fname)
      // setlname(response.data.customer_lname)
      setsrtloader(false)
      setformDatao(response.data)
      setllfimg2sand(response.data.l_little_finger)
      setlrfimg2sand(response.data.l_ring_finger)
      setlmfimg2sand(response.data.l_middle_finger)
      setlifimg2sand(response.data.l_index_finger)
      setltfimg2sand(response.data.l_thumb)
      setrlfimg2sand(response.data.r_little_finger)
      setrrfimg2sand(response.data.r_ring_finger)
      setrmfimg2sand(response.data.r_middle_finger)
      setrifimg2sand(response.data.r_index_finger)
      setthumbimg2sand(response.data.r_thumb)

// console.log(response)

   
    }
    fetchBio();




    }, []); 

  // console.log("check do39393993939339c7et ",documentimageback2)

  return (
    <div style={{ width: "100%" }}>
      {/* <Header /> */}
      <div className="dashboardcontent px-2">
        {/* <Breadcup name={'Agent'} /> */}
        {/* <div className="container py-4">
        
      </div> */}
        <div className="container-fuild px-2 desgin1">
          <div className="row bg-white ">
            {/* <div className="col-12 py-3 customtext23" style={{background: 'hsla(210, 85%, 32%, 0.2)',color:'#0C5398'}}>Add Agent</div> */}
            <div
              className="col-lg-12 d-flex justify-content-between py-2"
              style={{
                background: "hsla(210, 85%, 32%, 0.2)",
                color: "#0C5398",
              }}
            >
              <p className="m-0 customfont">Add Agent</p>
              <div className="addnew d-block mb-2">
                <button className="btn text-white closebtn">
                  <NavLink
                    to="/agentlist/0"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    {" "}
                    x Close
                  </NavLink>
                </button>
              </div>
            </div>
          </div>

          <div
        className="col-12 py-1 px-2 d-flex justify-content-center" 
      >
        
      
      </div>
            {/* <div
        className="col-12 px-2 d-flex justify-content-end" 
      >
      
        <div className="alert alert-danger mb-1" role="alert"  style={{width:'100%',display:invalidotp == true ? 'block' : 'none'}}>
        Invalid OTP.
</div>
</div>
<div
        className="col-12 px-2 d-flex justify-content-end" 
      >
        <div className="alert alert-danger mb-1" role="alert"  style={{width:'100%',display:invalidotpmobile == true ? 'block' : 'none'}}>
        Invalid OTP.
</div>
</div> */}
{/* <div
        className="col-12 px-2 d-flex justify-content-end" 
      >
        <div className="alert alert-success mb-1" role="alert"  style={{width:'100%',display:sendotpres == true ? 'block' : 'none'}}>
        OTP sent to your Email Successfully.
</div>
</div> */}
{/* <div
        className="col-12 px-2 d-flex justify-content-end" 
      >
        <div className="alert alert-success mb-1" role="alert"  style={{width:'100%',display:sendotpresmobile == true ? 'block' : 'none'}}>
        OTP sent to your Mobile Successfully.
</div>
</div> */}
<div
        className="col-12 px-2 d-flex justify-content-end" 
      >
        <div className="alert alert-success mb-1" role="alert"  style={{width:'100%',display:otpshowres == true ? 'block' : 'none'}}>
        Email Verified Successfully.
</div>
</div>
<div
        className="col-12 px-2 d-flex justify-content-end" 
      >
        <div className="alert alert-success mb-1" role="alert"  style={{width:'100%',display:otpshowresmobile == true ? 'block' : 'none'}}>
        Mobile Number Verified Successfully.
</div>
</div>
<div
        className="col-12 px-2 d-flex justify-content-end" 
      >
        <div className="alert alert-danger mb-1" role="alert"  style={{width:'100%',display:verifyyourotp == true ? 'block' : 'none'}}>
        {registerpro == false ? registerpromobile == false ? "Please Verify Your Email and Phone No ." : "Please Verify Your Email."  : registerpromobile == false ? "Please Verify Your Phone No ." : ""}   
</div>
      </div>
        </div>
        {srtloader == true ? <div className="container-fuild bg-white"> <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"100%"}}>
        <div className='px-2'> <Loadercomp size={100} /></div>
        </div></div> : <div className="container-fuild pb-4 pt-3 px-2 bg-white">
          <form autoComplete="off" onSubmit={submitform}>
            <div
              className="row bg-white pb-4 round"
              style={{
                border: "1px solid #E0E0E0",
                margin: "10px 0px",
                borderRadius: "3px",
                position:'relative'
              }}
            >
              <div className="col-12">
                {agentresponse.status === 200 ? (
                  <div className="alert alert-success mt-3" role="alert">
                    successfully add
                  </div>
                ) : (
                  <div></div>
                )}
                {agentresponse.status ? (
                  errorclass === true ? (
                    <div className="alert alert-danger mt-3" role="alert">
                      Something Went Wrong
                    </div>
                  ) : (
                    <div></div>
                  )
                ) : (
                  <div></div>
                )}
              </div>
              <StepWizard ref={stepWizardInstance}  onStepChange={handleStepChange}>
              <div className="row">
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      First Name <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      className="form-control"
                      value={agentname}
                      placeholder="First Name"
                      onChange={(e) => {
                        setagentname(e.target.value);
                      }}
                      required
                    />
                    {errorcls ? (
                      errorcls.first_name ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.first_name[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Last Name <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      className="form-control"
                      value={agentlast}
                      placeholder="Last Name"
                      onChange={(e) => {
                        setagentlast(e.target.value);
                      }}
                    />
                    {errorcls ? (
                      errorcls.last_name ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.last_name[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            
              <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor=""  className="form-label customw">
                     <span>Father Name</span>  <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Father Name"
                      value={ffn}
                      onChange={(e) => {
                        setffn(e.target.value);
                      }}
                      required
                    />
                    {errorcls ? (
                      errorcls.fathersFullNm ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.fathersFullNm[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Gender <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    {/* <input type="text" className="form-control" placeholder='Gender' value={agentgender} onChange={(e) => {setagentgender(e.target.value)}} required /> */}
                    <select
                      name="select"
                      id="sec2"
                      className="form-select"
                      value={agentgender}
                      onChange={(e) => {
                        setagentgender(e.target.value);
                      }}
                      aria-label="Default select example"
                    >
                      <option value="Male">male</option>
                      <option value="Female">female</option>
                      <option value="Others">others</option>
                    </select>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label customw">
                      Email Address <span style={{ color: "red" }}>*</span>{" "}
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="email"
                      className="form-control"
                      value={agentemail}
                      placeholder="Email Address"
                      onChange={(e) => {
                        setagentemail(e.target.value);
                      }}
                      required
                    />
                    {errorcls ? (
                      errorcls.email ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.email[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div> */}

              <div className="col-md-6 px-2 " style={{paddingTop:"28px"}}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Email <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                   
{otpnext == true ? <div className="input-group ">
  <input type="text" className="form-control"   value={agentemail}
                      onChange={(e) => {
                        setagentemail(e.target.value);
                      }} placeholder="Email" aria-label="Recipient's username" required aria-describedby="basic-addon2" />
  {/* <span className="input-group-text" onClick={sendotp} style={{cursor:"pointer"}} id="basic-addon2">RESEND OTP</span> */}
</div> : <div className="input-group ">
  <input type="text" className="form-control"   value={agentemail}
                      onChange={(e) => {
                        setagentemail(e.target.value);
                      }} placeholder="Email"  required
                      // aria-label="Recipient's username" aria-describedby="basic-addon2" readOnly={registerpro == true ? true : false} 
                      />
  {/* {registerpro == true ? "" : <span className="input-group-text" style={{cursor:"pointer"}} onClick={sendotp} id="basic-addon2">SEND OTP</span>} */}
</div>}
{allreadyemail ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {allreadyemailmsg}
                        </span>
                      ) : (
                        ""
                       )}
{errorcls ? (
                      errorcls.email ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.email[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
            
                  </div>
                 
                </div>
              </div>
             
             


              <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      DOB <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="date"
                      className="form-control"
                      minLength={6}
                      maxLength={6}
                      value={dob}
                      placeholder="date"
                      onChange={(e) => {setdob(e.target.value)}}
                      required
                    />

                    {doberror == true ? <span style={{color:'red'}}>You must be at least 18 years old.</span> : ''}
                    {errorcls ? (
                      errorcls.dob ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.dob[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              {/* {otpnext == true ? <div className="col-md-6 px-2 " style={{paddingTop:"28px"}}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Email OTP <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                   
                  </div>
                 
                </div>
              </div> : ''} */}














              <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Mobile No. <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    {/* <input
                      type="tel"
                      className="form-control"
                      maxLength={10}
                      minLength={10}
                      value={mobile_no}
                      placeholder="Mobile No"
                      onChange={(e) => {
                        setmobile_no(e.target.value.replace(/[^0-9]/gi, ""));
                      }}
                    /> */}




{otpnextmobile == true ? <div className="input-group ">
<PhoneInput
placeholder="Enter phone number"
className="form-control"
value={agentmobile}
// onChange={setagentmobile}
onChange={(e)=>{setagentmobile(e);setpassotp(false);}}
required
/>
  {/* <span className="input-group-text" style={{cursor:"pointer"}} onClick={sendotpmobile} id="basic-addon2">RESEND OTP</span> */}
</div> : <div className="input-group ">
<PhoneInput
placeholder="Enter phone number"
className="form-control"
value={agentmobile}
// onChange={setagentmobile}
onChange={(e)=>{setagentmobile(e);setpassotp(false);}}
// readOnly={registerpromobile == true ? true : false}
required
/>
  {/* {registerpromobile == true ? "" : <span className="input-group-text" style={{cursor:"pointer"}} onClick={sendotpmobile} id="basic-addon2">SEND OTP</span>} */}
</div>}





{/* <div className="input-group ">

<PhoneInput
placeholder="Enter phone number"
className="form-control"
value={mobile_no}
onChange={setmobile_no}
required
/>
<span className="input-group-text" onClick={verifyotp} id="basic-addon2">VERIFY OTP</span>
</div> */}
                    {errorcls ? (
                      errorcls.mobile_no ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.mobile_no[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {allreadynumber ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {allreadynumbermsg}
                        </span>
                      ) : (
                        ""
                       )}
                  </div>
                </div>
              </div>
             
              {/* <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Mobile No. <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <PhoneInput
placeholder="Enter phone number"
className="form-control"
value={agentmobile}
onChange={setagentmobile}
required
/>
                    {errorcls ? (
                      errorcls.mobile_no ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.mobile_no[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div> */}
{/* 
              <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      DOB <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="date"
                      className="form-control"
                      minLength={6}
                      maxLength={6}
                      value={dob}
                      placeholder="date"
                      onChange={(e) => {setdob(e.target.value)}}
                      required
                    />
                    {errorcls ? (
                      errorcls.dob ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.dob[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
           */}
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Address <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      className="form-control"
                      value={agentaddress}
                      placeholder="Address"
                      onChange={(e) => {
                        setagentaddress(e.target.value);
                      }}
                      required
                    />
                    {errorcls ? (
                      errorcls.address ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.address[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              {/* {otpnextmobile == true ? <div className="col-md-6 px-2 " style={{paddingTop:"28px"}}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Mobile OTP <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                   
{otpnextmobile == true ? <div className="input-group ">
  <input type="number" className="form-control"   value={otp2}
                      onChange={(e) => {
                        setotp2(e.target.value);
                      }} placeholder="OTP" aria-label="Recipient's username" aria-describedby="basic-addon2" />
  <span className="input-group-text" onClick={verifyotpmobile} style={{cursor:"pointer"}} id="basic-addon2">VERIFY OTP</span>
</div> : ''}


            
                  </div>
                 
                </div>
              </div> : ''} */}
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Country <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    {/* <input
                      type="text"
                      className="form-control"
                      value={agentcountry}
                      placeholder="Country"
                      onChange={(e) => {
                        setagentcountry(e.target.value);
                      }}
                      required
                    /> */}
                    <Select
               
               placeholder="Select Country"
               options={updatedCountries}
               value={agentcountry}
               onChange={(e) => {
                setagentcountry(e);setagentstate('');setagentcity('');
              }}
               required
             />
                    {errorcls ? (
                      errorcls.country ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.country[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Pincode <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                  <input type="tel" className="form-control" maxLength={6}
                  minLength={6} value={agentpincode} placeholder='Pincode' onChange={(e) => {setagentpincode(e.target.value.replace(/[^0-9]/gi, ""))}} required />
                  {errorcls ? errorcls.pincode ? <span style={{color:'red'}}> {errorcls.pincode[0]} </span> : '' : ''}
                  {/* <div className="input-group mb-3">
  <input type="tel" className="form-control" placeholder="Enter Pincode" maxLength={6} onFocus={countryfocus}
                  minLength={6} value={agentpincode}  onChange={(e) => {setagentpincode(e.target.value.replace(/[^0-9]/gi, ""));setagentstate('');setagentcity('')}} required aria-label="Recipient's username" aria-describedby="button-addon2" />
  <button type="button" className="btn btn-outline-secondary" onClick={handleSubmit} style={{ textDecoration: 'none', color: 'white',backgroundColor:"#165a99" }} id="button-addon2">Search</button>
</div>
{errorcls ? errorcls.pincode ? <span style={{color:'red'}}> {errorcls.pincode[0]} </span> : '' : ''}
{pineror !== '' ? <span style={{color:'red'}}> {pineror} </span> : ''} */}
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      State <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    {/* <input
                      type="text"
                      className="form-control"
                      value={agentstate}
                      placeholder="State"
                      readOnly
                      required
                    />
              {stateerror !== '' ? <span style={{color:'red'}}> {stateerror} </span> : '' } */}

                    {/* <input
                      type="text"
                      className="form-control"
                      value={agentstate}
                      placeholder="State"
                      onChange={(e) => {
                        setagentstate(e.target.value);
                      }}
                      required
                    /> */}
                     <Select
          id="state"
          name="state"
          options={updatedStates(agentcountry == null ? null : agentcountry.value)}
          placeholder="Select State"
          onChange={(e) => {
            setagentstate(e);
          }}
          required
        />
                    {errorcls ? (
                      errorcls.state ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.state[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      City <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    {/* <input
                      type="text"
                      className="form-control"
                      value={agentcity}
                      placeholder="city"
                      readOnly
                      required
                    />
              {cityerror !== '' ? <span style={{color:'red'}}> {cityerror} </span> : ''} */}

                    {/* <input
                      type="text"
                      className="form-control"
                      value={agentcity}
                      placeholder="city"
                      onChange={(e) => {
                        setagentcity(e.target.value);
                      }}
                      required
                    /> */}
                     <Select
                     placeholder="Select City"
          id="city"
          name="city"
          options={updatedCities(agentstate == null ? null : agentstate.value)}
          value={agentcity}
          onChange={(e) => {
            setagentcity(e);
          }}
          required
        />
                    {errorcls ? (
                      errorcls.city ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.city[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                    Nationality <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    {/* <input type="text" className="form-control" placeholder='Gender' value={agentgender} onChange={(e) => {setagentgender(e.target.value)}} required /> */}
                    <select
                      className="form-select"
                      value={agentnationality}
                      onChange={(e) => {
                        setagentnationality(e.target.value);
                        changedocument(e.target.value);
                      }}
                      aria-label="Default select example"
                    >
                      <option value="india">India</option>
                      <option value="Others">others</option>
                    </select>
                  </div>
                </div>
              </div>
              <div
        className="col-12 py-2 px-4 d-flex justify-content-end"
        style={{ gap: "4px" }}
      >
        
          {/* <div className='px-2'>{loading == true ?  <Loadercomp /> : ""}</div> */}
        {/* <button className="btn4">Cancel</button> */}
        {/* next step */}
        <button type='submit' className="btn5" onClick={()=>{setisformonecheck(true)}}>Next</button>
      </div>
              </div>
             
              <div>
              <div className="col-12">
                <div className="row px-4 pt-4">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-12">
                        <label htmlFor="" className="form-label">
                          Biometric Detail{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <div className="col-12">
                        <div className="border round py-3 d-flex justify-content-center">
                        <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
     <label htmlFor="">Right Hand</label>
     <img src={thumbimg2 == '' ? img5 : img4} alt="rtf" style={{position:"absolute",top:"80px",left:"13px"}} />
     <img src={rifimg2 == '' ? img5 : img4} alt="rif" style={{position:"absolute",top:"37px",left:"35px"}} />
     <img src={rmfimg2 == '' ? img5 : img4} alt="rmf" style={{position:"absolute",top:"30px",left:"65px"}} />
     <img src={rrfimg2 == '' ? img5 : img4} alt="rrf" style={{position:"absolute",top:"36px",left:"84px"}} />
     <img src={rlfimg2 == '' ? img5 : img4} alt="rlf" style={{position:"absolute",top:"55px",left:"103px"}} />
                          <img src={img1} alt="qwerty" />
                          </div>
                          <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
<label htmlFor="">Left Hand</label>
<img src={llfimg2 == '' ? img5 : img4} alt="llf" style={{position:"absolute",top:"56px",left:"13px"}} />
     <img src={lrfimg2 == '' ? img5 : img4} alt="lrf" style={{position:"absolute",top:"36px",left:"33px"}} />
     <img src={lmfimg2 == '' ? img5 : img4} alt="lmf" style={{position:"absolute",top:"31px",left:"55px"}} />
     <img src={lifimg2 == '' ? img5 : img4} alt="lif" style={{position:"absolute",top:"38px",left:"80px"}} />
     <img src={ltfimg2 == '' ? img5 : img4} alt="ltf" style={{position:"absolute",top:"80px",left:"102px"}} />
                          <img src={img2} alt="trew" />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 py-2 px-4 d-flex justify-content-between">
                <button
                type='button'
                  // className="btn1"
                  style={{backgroundColor:"#0C5398"}}
                  className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Capture
                </button>
              </div>
                    </div>
                    { fingerperror == true ? <span style={{color:'red'}}> {rigthfingerserror}</span> : ''}
                  </div>
                  <div className="col-md-6">
                  <div className="row">
            <div className="col-12"><label htmlFor="" className="form-label">Photograph <span style={{color:'red'}}>*</span></label></div>
            <div className="col-12 ">
            <div className="border round py-3 d-flex justify-content-center">
              <button
                type='button'
                style={{border:'none',outline:'none'}}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModaltwo"
                >
              <img src={displayphoto == '' ? img3 : displayphoto} alt="zxcvbnm" width="140px" height="162px" />
              </button>
              {/* <ReactImageMagnify
                            {...{
                                smallImage: {
                                    alt: 'Wristwatch by Versace',
                                    isFluidWidth: true,
                                    src: displayphoto == '' ? img3 : displayphoto,
                                    width:140,
                                    height:162
                                },
                                largeImage: {
                                    src: displayphoto == '' ? img3 : displayphoto,
                                    width: 836,
                                    height: 1100
                                },
                                // enlargedImagePosition: 'over',
                                lensStyle: { backgroundColor: 'rgba(0,0,0,.6)' }
                            }}
                            
                        /> */}
   
             </div>
             <div className="col-12 py-2">
             <button
             style={{backgroundColor:"#0C5398"}}
                type='button'
                className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalwebcam"
                  onClick={()=>{sethideorshow(!hideorshow)}}
                >
                  Capture
                </button>
             {/* <label htmlFor="photorap" className='btn btn-primary' style={{backgroundColor:"#0C5398"}}> Browse</label> */}
             </div>
              {/* <input type="file" style={{opacity:"0",position:'absolute',left:"205555555"}} id="photorap" onChange={(e) => {handleImageChangespecial(e)}} required /> */}
                 </div>
                 {errorcls ? errorcls.photograph ? <span style={{color:'red'}}> {errorcls.photograph[0]} </span> : '' : ''}
          { sandphotoerror == true ? <span style={{color:'red'}}> Please Capture your Photograph </span> : ''}

                   {/* { sandphotoerror == true ? <span style={{color:'red'}}> Please Select Product </span> : ''} */}
                 </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
    <div className="row">
      <div className="col-12 ccolor py-3 px-4">Document</div>
{/* <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
        Document Type <span style={{ color: "red" }}>*</span>
        </label>
      </div>
      <div className="col-lg-8">
      <select
              name=""
              id=""
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => {
                setdocumenttype(e.target.value);
                changedocumetimg(e.target.value);
              }}
              required
            >
              <option value="defualt">Select Document</option>
              <option value="AADHAAR">AADHAAR CARD</option>
              <option value="PAN">PAN CARD</option>
              <option value="DRIVING_LICENSE">DRIVING LICENSE</option>
              <option value="VOTERID">VOTER ID</option>
              <option value="PASSPORT">PASSPORT</option>
            </select>
            {documenterror === true ? documenttype == undefined || documenttype == "defualt"  ? (
                      <span style={{ color: "red" }}>Please Select Document</span>
                    ) : ( "") : (
                      ""
                    )}
                    {documentprivateerror === true ? documenttype == undefined || documenttype == "defualt"  ? (
                      <span style={{ color: "red" }}>Please Select Document</span>
                    ) : ( "") : (
                      ""
                    )}
                 {errorcls ? errorcls.document_type ? <span style={{color:'red'}}> {errorcls.document_type[0]} </span> : '' : ''}

      </div>
    </div>
  </div> */}
{/* {agentnationality == "india" ? <div className="col-md-12 px-2" style={{ paddingTop: "0px" }}>
    <div className="row">
      <div className="col-lg-2">
        <label htmlFor="" className="form-label">
        </label>
      </div>
      <div className="col-lg-8">
      <input className="form-check-input" type="checkbox" value={notapplicable} 
      onChange={(e) => {
                            setnotapplicable(!notapplicable);
                          }} 
                           id="flexCheckChecked" /> {" "}
   Pan Card Not Available
      </div>
    </div>
  </div> : '' } */}


  <div className="col-md-6 px-2" style={{ paddingTop: "28px",display:notapplicable == true ? "none" : "block" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
        Document Type <span style={{ color: "red" }}>*</span>
        </label>
      </div>
      <div className="col-lg-8">
      <input type="text" className="form-control" value={documenttype} autoComplete="off" placeholder={documenttype == 'PAN' ? `PAN No.` : documenttype == 'VOTERID' ? `VOTER ID`: documenttype == 'PASSPORT' ? `PASSPORT No.`:  documenttype == 'DRIVING_LICENSE' ? `DRIVING LICENSE No.`:  documenttype == 'AADHAAR' ?`Aadhar No.` : `Select Document`} readOnly onChange={(e) => {
                        setdocumenttype(e.target.value);
                      }}
                     />
                        {/* {documenterror === true ? idnumber == undefined || idnumber == "" ? (
                      <span style={{ color: "red" }}>Please Enter Your Document No</span>
                    ) : ( "") : (
                      ""
                    )} */}
         {errorcls ? (
          errorcls.document_type ? (
            <span style={{ color: "red" }}>
              {" "}
              {errorcls.document_type[0]}{" "}
            </span>
          ) : (
            ""
          )
        ) : (
          ""
        )}

      </div>
    </div>
  </div>




  <div className="col-md-6 px-2" style={{ paddingTop: "28px",display:notapplicable == true ? "none" : "block" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
        Document No. <span style={{ color: "red" }}>*</span>
        </label>
      </div>
      <div className="col-lg-8">
        {notapplicable == true ? '' : isformonecheck == true ? '' : <input type="text" className="form-control" value={idnumber} autoComplete="off" placeholder={documenttype == 'PAN' ? `PAN No.` : documenttype == 'VOTERID' ? `VOTER ID`: documenttype == 'PASSPORT' ? `PASSPORT No.`:  documenttype == 'DRIVING_LICENSE' ? `DRIVING LICENSE No.`:  documenttype == 'AADHAAR' ?`Aadhar No.` : `Select Document`} onChange={(e) => {
                        setidnumber(e.target.value);
                      }}
                      required />}
      
                        {documenterror === true ? idnumber == undefined || idnumber == "" ? (
                      <span style={{ color: "red" }}>Please Enter Your Document No</span>
                    ) : ( "") : (
                      ""
                    )}
         {errorcls ? (
          errorcls.document_no ? (
            <span style={{ color: "red" }}>
              {" "}
              {errorcls.document_no[0]}{" "}
            </span>
          ) : (
            ""
          )
        ) : (
          ""
        )}

      </div>
    </div>
  </div>




  {documentloader == true ? <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"100%"}}>
<div className='px-2'>{documentloader == true ?  <Loadercomp size={100}  /> : ""}</div>
</div> : ''}


{documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT' ? <div className="col-md-6 px-2" style={{ paddingTop: "28px",display:notapplicable == true ? "none" : "block" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
          Expiry date <span style={{ color: "red" }}>*</span>
        </label>
      </div>
      <div className="col-lg-8">
       {isformonecheck == true ? '' : <input
          type="date"
          className="form-control"
          value={documentexpiry}
          // placeholder="State"
          onChange={(e) => {
            setdocumentexpiry(e.target.value);
          }}
          required
        />}
              {documenterror === true ? documentexpiry == undefined ? (
                      <span style={{ color: "red" }}>Please Ender your Expiry date</span>
                    ) : ( "") : (
                      ""
                    )}
        {/* {errorcls ? (
          errorcls.state ? (
            <span style={{ color: "red" }}>
              {" "}
              {errorcls.state[0]}{" "}
            </span>
          ) : (
            ""
          )
        ) : (
          ""
        )} */}

      </div>
    </div>
  </div> : ''}
  {documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT' ? <div className="col-md-6 px-2" style={{ paddingTop: "28px",display:notapplicable == true ? "none" : "block" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
          Issue date <span style={{ color: "red" }}>*</span>
        </label>
      </div>
      <div className="col-lg-8">
      {isformonecheck == true ? '' : <input
          type="date"
          className="form-control"
          value={documentissue}
        //   placeholder="Issue"
          onChange={(e) => {
            setdocumentissue(e.target.value);
          }}
          required
        />}
           {documenterror === true ? documentissue == undefined ? (
                      <span style={{ color: "red" }}>Please Ender your Issue date</span>
                    ) : ( "") : (
                      ""
                    )}
        {/* {errorcls ? (
          errorcls.state ? (
            <span style={{ color: "red" }}>
              {" "}
              {errorcls.state[0]}{" "}
            </span>
          ) : (
            ""
          )
        ) : (
          ""
        )} */}
      </div>
    </div>
  </div> : ''}


  <div className="col-12 px-2" style={{ paddingTop: "28px",display:"block" }}>
    <div className="row">
      <div className="col-lg-2">
        <label htmlFor="" className="form-label">
        </label>
      </div>
      <div className="col-lg-8 ">
      <input className="form-check-input" type="checkbox" value={doc1} 
      onChange={(e) => {
                            setdoc1(!doc1);
                          }} 
                           id="flexCheckChecked3" /> {" "}
   Don't Have Document Images


      </div>
    </div>
  </div>
  <div className="col-md-6 px-2" style={{ paddingTop: "28px",display:notapplicable == true ? "none" : "block" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
       Front Image <span style={{ color: "red" }}>*</span>
        </label>
        {modifiedImage !== null && documenttype == 'AADHAAR' ? <div style={{display:"flex",gap:"5px",padding:"10px 4px"}}>
          <button onClick={()=>{setvarx(varx-15);modifyImage(aadhaarImage,varx,vary)}}  style={{backgroundColor:"#0C5398"}}
                type='button'
                className='btn btn-primary'><BsFillArrowLeftCircleFill /></button>
                 <button onClick={()=>{setvarx(varx+15);modifyImage(aadhaarImage,varx,vary)}} style={{backgroundColor:"#0C5398"}}
                type='button'
                className='btn btn-primary'><BsFillArrowRightCircleFill /></button>
        </div> : ''}
        {modifiedImage !== null && documenttype == 'AADHAAR' ? <div style={{display:"flex",gap:"5px",padding:"0px 4px"}}>
          <button  style={{backgroundColor:"#0C5398"}}
                type='button'
                onClick={()=>{setvary(vary-15);modifyImage(aadhaarImage,varx,vary)}}
                className='btn btn-primary'><BsFillArrowUpCircleFill /></button>
                 <button  style={{backgroundColor:"#0C5398"}}
                type='button'
                onClick={()=>{setvary(vary+15);modifyImage(aadhaarImage,varx,vary)}}
                className='btn btn-primary'><BsFillArrowDownCircleFill /></button>
        </div> : ''}
      </div>
      <div className="col-lg-8 ">
      {doc1 == true ? <button
                type='button'
                style={{border:'none',outline:'none',width:"100%"}}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModaldocument"
                  onClick={()=>{sethideorshowtwo(!hideorshowtwo)}}
                >
              <div style={{border:"1px solid #dee2e6",backgroundColor:"white",width:"100%",height:"162px",display:"flex",justifyContent:"center",alignItems:"center"}}>
              {modifiedImage == null ? <img src={img7} alt="zxcvbnm" width="50%" height="102px" /> : <img src={modifiedImage} alt="zxcvbnm" width="100%" height="162px" />}

              </div>
              </button> : 
              <div style={{border:"1px solid #dee2e6",backgroundColor:"white",width:"100%",height:"162px",display:"flex",justifyContent:"center",alignItems:"center"}}>
                <label htmlFor="img1doc" style={{width:'100%',height:'100%',display:'contents'}}>
              {modifiedImage == null || modifiedImage == "none" ? <img src={img7} alt="zxcvbnm" width="50%" height="102px" /> : <img src={modifiedImage} alt="zxcvbnm" width="100%" height="162px" />}
              </label>
              <input accept="image/*" type="file" id="img1doc" style={{display:'none'}} onChange={(e)=>{changefrontimg(e.currentTarget.files[0])}} alt="304" />
              </div>
              }

              {modifiedImage == null ? '' : <button
style={{backgroundColor:"#0C5398"}}
type='button'
className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal34"
                >
View
                </button> }
      {/* <input type="file" className="form-control " onChange={handleImageUpload} required /> */}
      {errorcls ? errorcls.docImg_front ? <span style={{color:'red'}}> {errorcls.docImg_front[0]} </span> : '' : ''}

      {dfierror == true ? <span style={{color:'red'}}> {drireserror !== "" ? drireserror : ''} </span> : ''}


      </div>
    </div>
  </div>


{/* back image document */}
<div className="col-md-6 px-2" style={{ paddingTop: "28px",display:notapplicable == true ? "none" : "block" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
       Back Image <span style={{ color: "red" }}>*</span>
        </label>
        {documentimagebacktwo !== null && documenttype == 'AADHAAR' ? <div style={{display:"flex",gap:"5px",padding:"10px 4px"}}>
          <button onClick={()=>{setvarxtwo(varxtwo-15);backmodifyImage(aadhaarImagetwo,varxtwo,varytwo)}}  style={{backgroundColor:"#0C5398"}}
                type='button'
                className='btn btn-primary'><BsFillArrowLeftCircleFill /></button>
                 <button onClick={()=>{setvarxtwo(varxtwo+15);backmodifyImage(aadhaarImagetwo,varxtwo,varytwo)}} style={{backgroundColor:"#0C5398"}}
                type='button'
                className='btn btn-primary'><BsFillArrowRightCircleFill /></button>
        </div> : ''}
        {documentimagebacktwo !== null && documenttype == 'AADHAAR' ? <div style={{display:"flex",gap:"5px",padding:"0px 4px"}}>
          <button  style={{backgroundColor:"#0C5398"}}
                type='button'
                onClick={()=>{setvarytwo(varytwo-15);backmodifyImage(aadhaarImagetwo,varxtwo,varytwo)}}
                className='btn btn-primary'><BsFillArrowUpCircleFill /></button>
                 <button  style={{backgroundColor:"#0C5398"}}
                type='button'
                onClick={()=>{setvarytwo(varytwo+15);backmodifyImage(aadhaarImagetwo,varxtwo,varytwo)}}
                className='btn btn-primary'><BsFillArrowDownCircleFill /></button>
        </div> : ''}
      </div>
      <div className="col-lg-8 ">
      {doc1 == true ? <button
                type='button'
                style={{border:'none',outline:'none',width:"100%"}}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModaldocumenttwowwe"
                  onClick={()=>{sethideorshowthree(!hideorshowthree)}}
                >
              <div style={{border:"1px solid #dee2e6",backgroundColor:"white",width:"100%",height:"162px",display:"flex",justifyContent:"center",alignItems:"center"}}>
              {documentimagebacktwo == null ? <img src={img7} alt="zxcvbnm" width="50%" height="102px" /> : <img src={documentimagebacktwo} alt="zxcvbnm" width="100%" height="162px" />}

              </div>
              </button> :
              <div style={{border:"1px solid #dee2e6",backgroundColor:"white",width:"100%",height:"162px",display:"flex",justifyContent:"center",alignItems:"center"}}>
             <label htmlFor="img2doc" style={{width:'100%',height:'100%',display:'contents'}}>
              {documentimagebacktwo == null || documentimagebacktwo == "none" ? <img src={img7} alt="zxcvbnm" width="50%" height="102px" /> : <img src={documentimagebacktwo} alt="zxcvbnm" width="100%" height="162px" />}
              </label>
              <input accept="image/*" type="file" id="img2doc" style={{display:'none'}} onChange={(e)=>{changebackimg(e.currentTarget.files[0])}} alt="304" />
             
              </div>
             }


                          {documentimagebacktwo == null ? '' : <button
style={{backgroundColor:"#0C5398"}}
type='button'
className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal35"
                >
View
                </button> }
      {/* <input type="file" className="form-control " onChange={handleImageUpload} required /> */}
      {errorcls ? errorcls.docImg_back ? <span style={{color:'red'}}> {errorcls.docImg_back[0]} </span> : '' : ''}

      {dbierror == true ? <span style={{color:'red'}}> {dbireserror !== "" ? dbireserror : ''} </span> : ''}



      </div>
    </div>
  </div>
  {/* {modifiedImage !== null && documenttype == 'AADHAAR' ? <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <div style={{display:"flex",gap:"5px",padding:"10px 4px"}}>
          <button onClick={()=>{setvarx(varx-15);modifyImage(aadhaarImage,varx,vary)}}  style={{backgroundColor:"#0C5398"}}
                type='button'
                className='btn btn-primary'><BsFillArrowLeftCircleFill /></button>
                 <button onClick={()=>{setvarx(varx+15);modifyImage(aadhaarImage,varx,vary)}} style={{backgroundColor:"#0C5398"}}
                type='button'
                className='btn btn-primary'><BsFillArrowRightCircleFill /></button>
        </div>
        <div style={{display:"flex",gap:"5px",padding:"0px 4px"}}>
          <button  style={{backgroundColor:"#0C5398"}}
                type='button'
                onClick={()=>{setvary(vary-15);modifyImage(aadhaarImage,varx,vary)}}
                className='btn btn-primary'><BsFillArrowUpCircleFill /></button>
                 <button  style={{backgroundColor:"#0C5398"}}
                type='button'
                onClick={()=>{setvary(vary+15);modifyImage(aadhaarImage,varx,vary)}}
                className='btn btn-primary'><BsFillArrowDownCircleFill /></button>
        </div>
      </div>
      <div className="col-lg-8">
      <img src={modifiedImage} alt="Modified Aadhaar Card" style={{width:"100%",height:"100%"}} />
      </div>
    </div>
  </div> : aadhaarImage !== null ?<div className="offset-col-6 col-md-6 px-2" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
      </div>
      <div className="col-lg-8">
      <img src={aadhaarImage} alt="Modified Aadhaar Card" style={{width:"100%",height:"100%"}} />
      </div>
    </div>
  </div> : ''} */}





<div className="col-md-5 px-2" style={{ paddingTop: "28px",display:notapplicable == false ? "none" : "block" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
        Form 60 <span style={{ color: "red" }}>*</span>
        </label>
      </div>
      <div className="col-lg-8">
        {notapplicable == true ? <input type="file" className="form-control"  onChange={(e) => {
                        setform60(e.target.files[0]);
                      }} /> : ''}

      </div>
    </div>
  </div>


<div className="col-md-1" style={{ paddingTop: "28px",display:notapplicable == false ? "none" : "block" }}>
    <div className="row">
      <div className="col-lg-12" style={{display:"flex",justifyContent:'center',alignItems:"center",fontSize:'24px',fontWeight:'bold'}}>
        OR 
      </div>
    </div>
  </div>
  {notapplicable == true ? <div className="col-lg-6" >
        <div className="row">
        <div className="col-12 px-4">
  </div>
            <div className="col-12"><label htmlFor="" className="form-label"> <span style={{color:'red'}}></span></label></div>
            <div className="col-12 ">
            <div className="border round py-3 d-flex justify-content-center">
              <div>
              {/* <Webcam /> */}
              </div>

                     <button
                type='button'
                style={{border:'none',outline:'none'}}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModaltwo123"
                >
              <img src={displaydocument == '' ? img33 : displaydocument} alt="zxcvbnm" width="140px" height="162px" />
              </button>
             </div>
             <div className="col-12 py-2">
             <button
             style={{backgroundColor:"#0C5398"}}
                type='button'
                className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalwebcam123"
                  onClick={()=>{sethideorshow(!hideorshow)}}
                >
                  Capture
                </button>
             {/* <label htmlFor="photorap" className='btn btn-primary' > Browse</label> */}
             </div>
              {/* <input type="file" style={{opacity:"0",position:'absolute',left:"205555555"}} id="photorap" onChange={(e) => {handleImageChangespecial(e)}} required /> */}
            </div>
            {/* {errorcls ? errorcls.photograph ? <span style={{color:'red'}}> {errorcls.photograph[0]} </span> : '' : ''} */}
          {/* { sandphotoerror == true ? <span style={{color:'red'}}> Please Capture your Photograph </span> : ''} */}
          </div>
        </div> : ''} 


{seconddocopen == true ?  <div className="col-12 py-3 px-4">
        
        <div
          style={{
            background: "#D9D9D9",
            height: "1px",
            width: "100%",
          }}
        ></div>
      </div> : ''}



     {seconddocopen == true ? <div className="col-12 ccolor py-3 px-4">Document 2</div> : ''}




  {seconddocopen == true ? <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
        Document Type <span style={{ color: "red" }}>*</span>
        </label>
      </div>
      <div className="col-lg-8">
      {isformonecheck == true ? '' : <select
              name="ske"
              id="select1"
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => {
                setdocumenttypetwo(e.target.value);
                changedocumetimgtwo(e.target.value);
              }}

              required
            >
              <option value="defualt">Select Document</option>
              {/* {documenttype !== 'PAN' ?<option value="PAN">PAN CARD</option> : <></> } */}
              {agentnationality == 'india' ? <option value="AADHAAR">AADHAAR CARD</option> : <></> }
              {agentnationality == 'india' ?<option value="DRIVING_LICENSE">DRIVING LICENSE</option>: <></> }
              {agentnationality == 'india' ? <option value="VOTERID">VOTER ID</option> : <></> }
              {agentnationality == 'india' ? <option value="PASSPORT">PASSPORT</option> : <></> }
              {agentnationality == 'Others' ? <option value="VISA">VISA</option> : <></> }
              {agentnationality == 'Others' ?<option value="RESIDENT_PERMIT">RESIDENT PERMIT</option> : <></> }
              {agentnationality == 'Others' ?<option value="REFUGEE_CARD">REFUGEE CARD</option>: <></> }
            </select>}
            {/* {documenterror === true ? documenttype == undefined || documenttype == "defualt"  ? (
                      <span style={{ color: "red" }}>Please Select Document</span>
                    ) : ( "") : (
                      ""
                    )} */}
                     {documentprivateerrortwo === true ? documenttypetwo == undefined || documenttypetwo == "defualt"  ? (
                      <span style={{ color: "red" }}>Please Select Document</span>
                    ) : ( "") : (
                      ""
                    )}
                 {errorcls ? errorcls.document_type2 ? <span style={{color:'red'}}> {errorcls.document_type2[0]} </span> : '' : ''}


      </div>
    </div>
  </div> : ''} 












  {seconddocopen == true ? <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
        Document No. <span style={{ color: "red" }}>*</span>
        </label>
      </div>
      <div className="col-lg-8">
      {isformonecheck == true ? '' : <input type="text" className="form-control" value={idnumbertwo} placeholder={documenttypetwo == 'PAN' ? `PAN No.` : documenttypetwo == 'VOTERID' ? `VOTER ID`: documenttypetwo == 'PASSPORT' ? `PASSPORT No.`:  documenttypetwo == 'DRIVING_LICENSE' ? `DRIVING LICENSE No.`:  documenttypetwo == 'AADHAAR' ?`Aadhar No.` : documenttypetwo == 'VISA' ? `VISA` : documenttypetwo == 'RESIDENT_PERMIT' ? `RESIDENT PERMIT` : documenttypetwo == 'REFUGEE_CARD' ? `REFUGEE CARD` : `Select Document`} onChange={(e) => {
                        setidnumbertwo(e.target.value);
                      }}
                      required
                     />}
                        {/* {documenterror === true ? idnumber == undefined || idnumber == "" ? (
                      <span style={{ color: "red" }}>Please Enter Your Document No</span>
                    ) : ( "") : (
                      ""
                    )} */}
        {errorcls ? (
          errorcls.document_no2 ? (
            <span style={{ color: "red" }}>
              {" "}
              {errorcls.document_no2[0]}{" "}
            </span>
          ) : (
            ""
          )
        ) : (
          ""
        )}

      </div>
    </div>
  </div> : ''}



  {seconddocopen == true ? documenttypetwo == 'DRIVING_LICENSE' || documenttypetwo == "RESIDENT_PERMIT" || documenttypetwo == "VISA" || documenttypetwo == "REFUGEE_CARD" || documenttypetwo == 'PASSPORT' ? <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
          Expiry date <span style={{ color: "red" }}>*</span>
        </label>
      </div>
      <div className="col-lg-8">
      {isformonecheck == true ? '' : <input
          type="date"
          className="form-control"
          value={documentexpirytwo}
          // placeholder="State"
          onChange={(e) => {
            setdocumentexpirytwo(e.target.value);
          }}
          required
        /> }
              {/* {documenterror === true ? documentexpiry == undefined ? (
                      <span style={{ color: "red" }}>Please Ender your Expiry date</span>
                    ) : ( "") : (
                      ""
                    )} */}

      </div>
    </div>
  </div> : '' : ''}
  {seconddocopen == true ? documenttypetwo == 'DRIVING_LICENSE' || documenttypetwo == "RESIDENT_PERMIT" || documenttypetwo == "VISA" || documenttypetwo == "REFUGEE_CARD" || documenttypetwo == 'PASSPORT' ? <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
          Issue date <span style={{ color: "red" }}>*</span>
        </label>
      </div>
      <div className="col-lg-8">
      {isformonecheck == true ? '' : <input
          type="date"
          className="form-control"
          value={documentissuetwo}
        //   placeholder="Issue"
          onChange={(e) => {
            setdocumentissuetwo(e.target.value);
          }}
          required
        />}
           {/* {documenterror === true ? documentissue == undefined ? (
                      <span style={{ color: "red" }}>Please Ender your Issue date</span>
                    ) : ( "") : (
                      ""
                    )} */}

      </div>
    </div>
  </div> : '' : ''}

  <div className="col-12 px-2" style={{ paddingTop: "28px",display:"block" }}>
    <div className="row">
      <div className="col-lg-2">
        <label htmlFor="" className="form-label">
        </label>
      </div>
      <div className="col-lg-8 ">
      <input className="form-check-input" type="checkbox" value={doc2} 
      onChange={(e) => {
                            setdoc2(!doc2);
                          }} 
                           id="flexCheckChecked4" /> {" "}
   Don't Have Document 2 Images


      </div>
    </div>
  </div>
  {seconddocopen == true ? <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
       Front Image <span style={{ color: "red" }}>*</span>
        </label>
        {modifiedImagenumbertwo !== null && documenttypetwo == 'AADHAAR' ? <div style={{display:"flex",gap:"5px",padding:"10px 4px"}}>
          <button onClick={()=>{setvarx2(varx2-15);modifyImagethree(aadhaarImage2,varx2,vary2)}}  style={{backgroundColor:"#0C5398"}}
                type='button'
                className='btn btn-primary'><BsFillArrowLeftCircleFill /></button>
                 <button onClick={()=>{setvarx2(varx2+15);modifyImagethree(aadhaarImage2,varx2,vary2)}} style={{backgroundColor:"#0C5398"}}
                type='button'
                className='btn btn-primary'><BsFillArrowRightCircleFill /></button>
        </div> : ''}
        {modifiedImagenumbertwo !== null && documenttypetwo == 'AADHAAR' ? <div style={{display:"flex",gap:"5px",padding:"0px 4px"}}>
          <button  style={{backgroundColor:"#0C5398"}}
                type='button'
                onClick={()=>{setvary2(vary2-15);modifyImagethree(aadhaarImage2,varx2,vary2)}}
                className='btn btn-primary'><BsFillArrowUpCircleFill /></button>
                 <button  style={{backgroundColor:"#0C5398"}}
                type='button'
                onClick={()=>{setvary2(vary2+15);modifyImagethree(aadhaarImage2,varx2,vary2)}}
                className='btn btn-primary'><BsFillArrowDownCircleFill /></button>
        </div> : ''}
      </div>
      <div className="col-lg-8 ">
      {doc2 == true ? <button
                type='button'
                style={{border:'none',outline:'none',width:"100%"}}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModaldocument2"
                  onClick={()=>{sethideorshow3(!hideorshow3)}}
                >
              <div style={{border:"1px solid #dee2e6",backgroundColor:"white",width:"100%",height:"162px",display:"flex",justifyContent:"center",alignItems:"center"}}>
              {modifiedImagenumbertwo == null ? <img src={img7} alt="zxcvbnm" width="50%" height="102px" /> : <img src={modifiedImagenumbertwo} alt="zxcvbnm" width="100%" height="162px" />}

              </div>
              </button> : 
              <div style={{border:"1px solid #dee2e6",backgroundColor:"white",width:"100%",height:"162px",display:"flex",justifyContent:"center",alignItems:"center"}}>
              <label htmlFor="img22doc" style={{width:'100%',height:'100%',display:'contents'}}>
              {modifiedImagenumbertwo == null ? <img src={img7} alt="zxcvbnm" width="50%" height="102px" /> : <img src={modifiedImagenumbertwo} alt="zxcvbnm" width="100%" height="162px" />}
              </label>
              <input accept="image/*" type="file" id="img22doc" style={{display:'none'}} onChange={(e)=>{twochangefrontimg(e.currentTarget.files[0])}} alt="304" />
              
              </div>
              }

              {modifiedImagenumbertwo == null ? '' : <button
style={{backgroundColor:"#0C5398"}}
type='button'
className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal340"
                >
View
                </button> }
      {/* <input type="file" className="form-control " onChange={handleImageUpload} required /> */}
      {errorcls ? errorcls.docImg_front2 ? <span style={{color:'red'}}> {errorcls.docImg_front2[0]} </span> : '' : ''}

      {dfierrortwo == true ? <span style={{color:'red'}}> {drireserrortwo !== "" ? drireserrortwo : ''} </span> : ''}


      </div>
    </div>
  </div> : ''}

{/* back image document */}
  {seconddocopen == true ? <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
       Back Image <span style={{ color: "red" }}>*</span>
        </label>
        {documentimageback2 !== null && documenttypetwo == 'AADHAAR' ? <div style={{display:"flex",gap:"5px",padding:"10px 4px"}}>
          <button onClick={()=>{setvarxtwo2(varxtwo2-15);backmodifyImage3(aadhaarback2,varxtwo2,varytwo2)}}  style={{backgroundColor:"#0C5398"}}
                type='button'
                className='btn btn-primary'><BsFillArrowLeftCircleFill /></button>
                 <button onClick={()=>{setvarxtwo2(varxtwo2+15);backmodifyImage3(aadhaarback2,varxtwo2,varytwo2)}} style={{backgroundColor:"#0C5398"}}
                type='button'
                className='btn btn-primary'><BsFillArrowRightCircleFill /></button>
        </div> : ''}
        {documentimageback2 !== null && documenttypetwo == 'AADHAAR' ? <div style={{display:"flex",gap:"5px",padding:"0px 4px"}}>
          <button  style={{backgroundColor:"#0C5398"}}
                type='button'
                onClick={()=>{setvarytwo2(varytwo2-15);backmodifyImage3(aadhaarback2,varxtwo2,varytwo2)}}
                className='btn btn-primary'><BsFillArrowUpCircleFill /></button>
                 <button  style={{backgroundColor:"#0C5398"}}
                type='button'
                onClick={()=>{setvarytwo2(varytwo2+15);backmodifyImage3(aadhaarback2,varxtwo2,varytwo2)}}
                className='btn btn-primary'><BsFillArrowDownCircleFill /></button>
        </div> : ''}
      </div>
      <div className="col-lg-8 ">
      {doc2 == true ? <button
                type='button'
                style={{border:'none',outline:'none',width:"100%"}}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModaldocumenttwowwetwo"
                  onClick={()=>{sethideorshow3(!hideorshow3)}}
                >
              <div style={{border:"1px solid #dee2e6",backgroundColor:"white",width:"100%",height:"162px",display:"flex",justifyContent:"center",alignItems:"center"}}>
              {documentimageback2 == null ? <img src={img7} alt="zxcvbnm" width="50%" height="102px" /> : <img src={documentimageback2} alt="zxcvbnm" width="100%" height="162px" />}

              </div>
              </button> : 

<div style={{border:"1px solid #dee2e6",backgroundColor:"white",width:"100%",height:"162px",display:"flex",justifyContent:"center",alignItems:"center"}}>
<label htmlFor="img33doc" style={{width:'100%',height:'100%',display:'contents'}}>
{documentimageback2 == null ? <img src={img7} alt="zxcvbnm" width="50%" height="102px" /> : <img src={documentimageback2} alt="zxcvbnm" width="100%" height="162px" />}
</label>
<input accept="image/*" type="file" id="img33doc" style={{display:'none'}} onChange={(e)=>{twochangebackimg(e.currentTarget.files[0])}} alt="304" />

</div>
}
              {documentimageback2 == null ? '' : <button
style={{backgroundColor:"#0C5398"}}
type='button'
className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal350"
                >
View
                </button> }
      {/* <input type="file" className="form-control " onChange={handleImageUpload} required /> */}
      {errorcls ? errorcls.docImg_back2 ? <span style={{color:'red'}}> {errorcls.docImg_back2[0]} </span> : '' : ''}

      {dbierrortwo == true ? <span style={{color:'red'}}> {dbireserrortwo !== "" ? dbireserrortwo : ''} </span> : ''}



      </div>
    </div>
  </div> : ''}



  {idno == 1 ? documentresponse != {} ? documentresponse.status == "SUCCESS" ? <div className="col-11 alert alert-success mt-3" role="alert">
            <h5 style={{padding:'0px',margin:"0px"}}>
         Verification Successfully 
            </h5>
       </div> : '' : '' : ''}

       {idno == 2 ? documenterrorres == true ? <div className="col-11 col-offset-2 alert alert-danger mt-3 ms-1" role="alert">
            <h5 style={{padding:'0px',margin:"0px"}}>
         Something Went Wrong Try Again
            </h5>
       </div> : '' : '' }
  
  
       <div className="col-12 px-4">
  {loading == true ? <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"100%"}}>
<div className='px-2'>{loading == true ?  <Loadercomp size={100}  /> : ""}</div>
</div> : ''}
  </div>
      {/* <div className="col-12 py-5 px-4 d-flex justify-content-start"> */}
      <div className="col-12 py-1 px-4 d-flex justify-content-start">
        {/* <button type="button" className="btn1" onClick={()=>setseconddocopen(!seconddocopen)}> +Add</button> */}
        {/* <button onClick={documentloader == false ? documentverify : ''} type='button' className="btn2"> Submit</button> */}
      </div>
      <div className="col-12 py-3 px-4">
        
        <div
          style={{
            background: "#D9D9D9",
            height: "1px",
            width: "100%",
          }}
        ></div>
      </div>

      {/* <div className="col-6 py-4 px-4">
<div className="row">
<div className="col-lg-3">
  <label htmlFor="" className="form-label customw">Password <span style={{color:'red'}}>*</span> </label>
</div>
<div className="col-lg-9">
  <input type="text" className="form-control" placeholder='Password' />
</div>
</div>
</div>
<div className="col-6 py-4 px-4">
<div className="row">
<div className="col-lg-3">
  <label htmlFor="" className="form-label customw">Confirm Password <span style={{color:'red'}}>*</span> </label>
</div>
<div className="col-lg-9">
  <input type="text" className="form-control" placeholder='Confirm Password' />
</div>
</div>
</div> */}
         <div className="col-12 px-4 pt-4">
                <div className="row">
                  <div className="col-3">
                    <label htmlFor="" className="form-label">
                      Role <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-3">
                    {" "}
                    <label htmlFor="Checker" className="form-label">
                      Checker
                    </label>{" "}
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={agentchecker}
                      onChange={(e) => {
                        setagentchecker(!agentchecker);
                      }}
                      id="Checker"
                    />
                  </div>
                  <div className="col-lg-3">
                    {" "}
                    <label htmlFor="Maker" className="form-label">
                      Maker
                    </label>{" "}
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={agentmaker}
                      onChange={(e) => {
                        setagentmaker(!agentmaker);
                      }}
                      id="Maker"
                    />
                  </div>
                  <div className="col-lg-3">
                    {" "}
                    <label htmlFor="Approver" className="form-label">
                      Approver
                    </label>{" "}
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={agentapprover}
                      onChange={(e) => {
                        setagentapprover(!agentapprover);
                      }}
                      id="Approver"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Username <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                  {isformonecheck == true ? '' : <input
                      type="text"
                      className="form-control"
                      value={agentusername}
                      placeholder="Username"
                      onChange={(e) => {
                        setagentusername(
                          e.target.value.replace(/[^a-zA-Z\ ]/gi, "")
                        );
                      }}
                      required
                    />}
                    {errorcls ? (
                      errorcls.username ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.username[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Company <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input type="text" className="form-control" placeholder='Company' value={agentcompany} readOnly  />
                    {/* <select
                      className="form-select"
                      aria-label="Default select example"
                      value={agentcompany}
                      placeholder="Company Name"
                      onChange={(e) => {
                        setagentcompany(e.target.value);
                        fetchDatatwo(e.target.value);
                      }}
                    >
                      <option value="" selected>
                        Select Company
                      </option>
                      {data.map((item) => (
                        <option key={item.company_id} value={item.company_id}>
                          {item.company_name}
                        </option>
                      ))}
                    </select> */}
                    {dataerrorstatus === true ? (
                      <span style={{ color: "red" }}>{dataerrortxt}</span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Location <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input type="text" className="form-control" value={agentlocation} placeholder='Location' readOnly  />
                    {/* <select
                      className="form-select"
                      aria-label="Default select example"
                      value={agentlocation}
                      placeholder="Company Name"
                      onChange={(e) => {
                        setagentlocation(e.target.value);
                      }}
                    >
                      <option value="" selected>
                        Select Location
                      </option>
                      {datatwo.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.branch_name}
                        </option>
                      ))}
                    </select> */}
                    {datatwoerrorstatus === true ? (
                      <span style={{ color: "red" }}>{datatwoerrortxt}</span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
                  <div className="col-md-6 pt-4 px-2">
                    <div className="row">
                      <div className="col-lg-3">
                        <label htmlFor="" className="form-label customw">
                          Password <span style={{ color: "red" }}>*</span>{" "}
                        </label>
                      </div>
                      <div className="col-lg-9">
                      {isformonecheck == true ? '' : <input
                          type="password"
                          className="form-control"
                          placeholder="Password"
                          autoComplete="off"

                          value={agentpassword}
                          onChange={(e) => {
                            setagentpassword(e.target.value);
                          }}
                          required
                        />}
                      </div>
                    </div>
                  </div>
                  
                  <div className="col-md-6 py-4 px-2">
                    <div className="row">
                      <div className="col-lg-3">
                        <label htmlFor="" className="form-label customw">
                          Confirm Password{" "}
                          <span style={{ color: "red" }}>*</span>{" "}
                        </label>
                      </div>
                      <div className="col-lg-9">
                      {isformonecheck == true ? '' : <input
                          type="password"
                          className="form-control"
                          placeholder="Confirm Password"
                          autoComplete="off"
                          value={agentconfirmpassword}
                          onChange={(e) => {
                            setagentconfirmpassword(e.target.value);
                          }}
                          required
                        /> }
                        {notmatch == true ? (
                          <span style={{ color: "red" }}>
                            Confirm Password didn't Match
                          </span>
                        ) : (
                          <span></span>
                        )}
                      </div>
                    </div>
                  </div>
                  {megaerror == true ? <div className="col-11 col-offset-2 alert alert-danger mt-3 ms-1" role="alert">
            <h5 style={{padding:'0px',margin:"0px"}}>
        {megaerrormsg}
            </h5>
       </div> : ''}




                  {seconddocopen == true ?  <div className="col-12 py-3 px-4">
        
        <div
          style={{
            background: "#D9D9D9",
            height: "1px",
            width: "100%",
          }}
        ></div>
      </div> : ''}
  
      {seconddocopen == true ? <div className="col-12 ccolor py-3 px-4">Verify Agent</div> : ''}

      <div className="col-md-6">
          <div className="row">
            <div className="col-12"><label htmlFor="" className="form-label">Biometric Detail <span style={{color:'red'}}>*</span></label></div>
            <div className="col-12">
                        <div className="border round py-3 d-flex justify-content-center">
                        <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
     <label htmlFor="">Right Hand</label>
     <img src={thumbimg2sand == '' || thumbimg2sand == null  ? img5 : img4} alt="rtf" style={{position:"absolute",top:"80px",left:"13px"}} />
     <img src={rifimg2sand == '' || rifimg2sand == null  ? img5 : img4} alt="rif" style={{position:"absolute",top:"37px",left:"35px"}} />
     <img src={rmfimg2sand == '' || rmfimg2sand == null  ? img5 : img4} alt="rmf" style={{position:"absolute",top:"30px",left:"65px"}} />
     <img src={rrfimg2sand == '' || rrfimg2sand == null  ? img5 : img4} alt="rrf" style={{position:"absolute",top:"36px",left:"84px"}} />
     <img src={rlfimg2sand == '' || rlfimg2sand == null  ? img5 : img4} alt="rlf" style={{position:"absolute",top:"55px",left:"103px"}} />
                          <img src={img1} alt="qwerty" />
                          </div>
                          <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
<label htmlFor="">Left Hand</label>
<img src={llfimg2sand == '' || llfimg2sand == null  ? img5 : img4} alt="llf" style={{position:"absolute",top:"56px",left:"13px"}} />
     <img src={lrfimg2sand == '' || lrfimg2sand == null  ? img5 : img4} alt="lrf" style={{position:"absolute",top:"36px",left:"33px"}} />
     <img src={lmfimg2sand == '' || lmfimg2sand == null  ? img5 : img4} alt="lmf" style={{position:"absolute",top:"31px",left:"55px"}} />
     <img src={lifimg2sand == '' || lifimg2sand == null  ? img5 : img4} alt="lif" style={{position:"absolute",top:"38px",left:"80px"}} />
     <img src={ltfimg2sand == '' || ltfimg2sand == null  ? img5 : img4} alt="ltf" style={{position:"absolute",top:"80px",left:"102px"}} />
                          <img src={img2} alt="trew" />
                          </div>
                        </div>
                      </div>
            { fingerperror12 == true ? <span style={{color:'red'}}> {rigthfingerserror12} </span> : ''}
            {/* {errorcls ? errorcls.r_index_finger ? <span style={{color:'red'}}> "Something Went wrong try again" </span> : '' : ''} */}
            <div className="col-12 py-2 px-4 d-flex justify-content-between">
                <button
                type='button'
                  // className="btn1"
             style={{backgroundColor:"#0C5398"}}
             className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal009"
                >
                  Capture
                </button>
              </div>
          </div>
        </div>
      <div
        className="col-12 py-2 px-4 d-flex justify-content-end"
        style={{ gap: "4px" }}
      >
        
          {/* <div className='px-2'>{loading == true ?  <Loadercomp /> : ""}</div> */}
        <button type="button" className="btn4" onClick={()=>{setisformonecheck(true);setpassotp(false);stepWizardInstance.current.previousStep();}} >Back</button>
        <button type="Submit" className="btn5">Submit</button>
      </div>
    </div>
  </div>
  </div>
  </StepWizard>
            </div>




            <div className="modal fade" id="exampleModalwebcam123" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => {sethideorshow(!hideorshow)}} aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px' }}>

  {hideorshow == true ? <div className="col-12 d-flex py-3 justify-content-center">
<div className='px-2'>{hideorshow == true ?  <Webcam ref={webcamRefs[5]} /> : ""}</div></div>:''}

</div>
                </div>
                <div className="modal-footer mod-line m-auto">
                  {/* <button type="button" className="btn closecancel">Cancel</button> */}
                  <button type="button" onClick={()=>{captureimage123(5)}} aria-label="Close" data-bs-dismiss="modal" className="btn closebtn text-white">Capture</button>
                </div>
              </div>
            </div>
          </div>



            <div className="modal fade" id="exampleModal34" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>


  
  <div className="col-12 px-2 pt-3">
  <img src={modifiedImage == null ? img3 : modifiedImage} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>


          <div className="modal fade" id="exampleModal35" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>


  
  <div className="col-12 px-2 pt-3">
  <img src={documentimagebacktwo == null ? img3 : documentimagebacktwo} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>

















          <div className="modal fade" id="exampleModal009" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                  <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>
                  {formdatao.r_thumb == null || formdatao.r_thumb == "" ? '': <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="tf2" className="form-label ">Right Thumb <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" id='tf2' name="image2" style={{opacity:"0",position:'absolute'}}  onChange={(e) => {handleImageChange11(e)}}  /> */}
                          {/* <label htmlFor="tf"> */}
                          {/* <div onClick={(e) => {handleImageChange11()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: thumbimg2 != '' ? '' : '3rem',width:"20%" }}> {thumbimg2 != '' ? <img src={`data:image/png;base64,${thumbimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange110()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: thumbimg5 != '' ? '' : rtf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {thumbimg5 != '' ? <img src={`data:image/png;base64,${thumbimg5}`} width="70px" height="60px" alt="asd" /> : rtf3error == true ? <Loadercomp size={30} /> : ''}  </div>
                          
                          {thumbimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {artferror == '' ? '' : artferror}</p>
                        </div>
                      </div>
                    </div>}
                  {formdatao.r_thumb == null || formdatao.r_thumb == "" ? formdatao.l_thumb == null || formdatao.l_thumb == "" ? formdatao.r_index_finger == null || formdatao.r_index_finger == "" ? formdatao.l_index_finger == null || formdatao.l_index_finger == "" ? formdatao.r_middle_finger == null || formdatao.r_middle_finger == "" ? formdatao.l_middle_finger == null || formdatao.l_middle_finger == "" ? formdatao.r_ring_finger == null || formdatao.r_ring_finger == ""  ? formdatao.l_ring_finger == null || formdatao.l_ring_finger == "" ? formdatao.r_little_finger == null || formdatao.r_little_finger == "" ? formdatao.l_little_finger == null || formdatao.l_little_finger == "" ? <div className="col-lg-12 px-2 pt-3">
                      <div className="row">
                        <div className="col-12">
                          <p style={{color:"red",textAlign:'center',fontSize:'26px',fontWeight:'600'}}>
                          Please Submit Your Finger Prints</p>
                        </div>
                      </div>
                    </div> : '' :'' : '' : '' : '' : '' : '' : '' : '' : ''}
                    {formdatao.l_thumb == null || formdatao.l_thumb == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="ltf2" className="form-label ">Left Thumb  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="ltf2" onChange={(e) => {handleImageChange16(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange16()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: ltfimg2 != '' ? '' : '3rem',width:"20%" }}> {ltfimg2 != '' ? <img src={`data:image/png;base64,${ltfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange160()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: ltfimg5 != '' ? '' : ltf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {ltfimg5 != '' ? <img src={`data:image/png;base64,${ltfimg5}`} width="70px" height="60px" alt="asd" /> : ltf3error == true ? <Loadercomp size={30} /> : ''}  </div> 
                          {ltfimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {altferror == '' ? '' : altferror}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao.r_index_finger == null || formdatao.r_index_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="rif2" className="form-label ">Right Index Finger <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rif2" onChange={(e) => {handleImageChange12(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange12()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rifimg2 != '' ? '' : '3rem',width:"20%" }}> {rifimg2 != '' ? <img src={`data:image/png;base64,${rifimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange120()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rifimg5 != '' ? '' : rif3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rifimg5 != '' ? <img src={`data:image/png;base64,${rifimg5}`} width="70px" height="60px" alt="asd" /> : rif3error == true ? <Loadercomp size={30} /> : ''}  </div>
          
                          {rifimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {ariferror == '' ? '' : ariferror}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao.l_index_finger == null || formdatao.l_index_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="lif2" className="form-label ">Left Index Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lif2" onChange={(e) => {handleImageChange17(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange17()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lifimg2 != '' ? '' : '3rem',width:"20%" }}> {lifimg2 != '' ? <img src={`data:image/png;base64,${lifimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange170()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: lifimg5 != '' ? '' : lif3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {lifimg5 != '' ? <img src={`data:image/png;base64,${lifimg5}`} width="70px" height="60px" alt="asd" /> : lif3error == true ? <Loadercomp size={30} /> : ''}  </div>
          
                          {lifimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {aliferror == '' ? '' : aliferror}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao.r_middle_finger == null || formdatao.r_middle_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="rmf2" className="form-label ">Right Middle Finger<span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rmf2" onChange={(e) => {handleImageChange13(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange13()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rmfimg2 != '' ? '' : '3rem',width:"20%" }}> {rmfimg2 != '' ? <img src={`data:image/png;base64,${rmfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange130()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rmfimg5 != '' ? '' : rmf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rmfimg5 != '' ? <img src={`data:image/png;base64,${rmfimg5}`} width="70px" height="60px" alt="asd" /> : rmf3error == true ? <Loadercomp size={30} /> : ''}  </div>
                  
                          {rmfimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {armferror == '' ? '' : armferror}</p>
                        </div>
                      </div>

                    </div>}
                    {formdatao.l_middle_finger == null || formdatao.l_middle_finger == "" ? '' :<div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="lmf2" className="form-label ">Left Middle Finger <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lmf2" onChange={(e) => {handleImageChange18(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange18()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lmfimg2 != '' ? '' : '3rem',width:"20%" }}> {lmfimg2 != '' ? <img src={`data:image/png;base64,${lmfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange180()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: lmfimg5 != '' ? '' : lmf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {lmfimg5 != '' ? <img src={`data:image/png;base64,${lmfimg5}`} width="70px" height="60px" alt="asd" /> : lmf3error == true ? <Loadercomp size={30} /> : ''}  </div>
        
                          {lmfimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                      </div>
                    </div>}
                    {formdatao.r_ring_finger == null || formdatao.r_ring_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="rrf2" className="form-label ">Right Ring Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rrf2" onChange={(e) => {handleImageChange14(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange14()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rrfimg2 != '' ? '' : '3rem',width:"20%" }}> {rrfimg2 != '' ? <img src={`data:image/png;base64,${rrfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange140()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rrfimg5 != '' ? '' : rrf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rrfimg5 != '' ? <img src={`data:image/png;base64,${rrfimg5}`} width="70px" height="60px" alt="asd" /> : rrf3error == true ? <Loadercomp size={30} /> : ''}  </div>

                          {rrfimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {arrferror == '' ? '' : arrferror}</p>
                        </div>
                      </div>

                    </div> }
                    {formdatao.l_ring_finger == null || formdatao.l_ring_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="lrf2" className="form-label ">Left Ring Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lrf2" onChange={(e) => {handleImageChange19(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange19()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lrfimg2 != '' ? '' : '3rem',width:"20%" }}> {lrfimg2 != '' ? <img src={`data:image/png;base64,${lrfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange190()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: lrfimg5 != '' ? '' : lrf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {lrfimg5 != '' ? <img src={`data:image/png;base64,${lrfimg5}`} width="70px" height="60px" alt="asd" /> : lrf3error == true ? <Loadercomp size={30} /> : ''}  </div>
                          
                          {lrfimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {alrferror == '' ? '' : alrferror}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao.r_little_finger == null || formdatao.r_little_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="rlf2" className="form-label ">Right Little Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rlf2" onChange={(e) => {handleImageChange15(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange15()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rlfimg2 != '' ? '' : '3rem',width:"20%" }}> {rlfimg2 != '' ? <img src={`data:image/png;base64,${rlfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange150()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rlfimg5 != '' ? '' : rlf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rlfimg5 != '' ? <img src={`data:image/png;base64,${rlfimg5}`} width="70px" height="60px" alt="asd" /> : rlf3error == true ? <Loadercomp size={30} /> : ''}  </div>
                          
                          {rlfimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {arlferror == '' ? '' : arlferror}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao.l_little_finger == null || formdatao.l_little_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="llf2" className="form-label ">Left Little Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="llf2" onChange={(e) => {handleImageChange20(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange20()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: llfimg2 != '' ? '' : '3rem',width:"20%" }}> {llfimg2 != '' ? <img src={`data:image/png;base64,${llfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange200()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: llfimg5 != '' ? '' : llf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {llfimg5 != '' ? <img src={`data:image/png;base64,${llfimg5}`} width="70px" height="60px" alt="asd" /> : llf3error == true ? <Loadercomp size={30} /> : ''}  </div>
                  
                          {llfimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {allferror == '' ? '' : allferror}</p>
                        </div>
                      </div>
                    </div>}
                  </div>
                </div>
                {/* {grandresponse.status === 201 ? nvg('/Remittancelist/1') : <div></div>} */}
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>



















          <div className="modal fade" id="exampleModaldocument2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => {sethideorshow3(!hideorshow3)}} aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px' }}>

  {hideorshow3 == true ? <div className="col-12 d-flex py-3 justify-content-center">
<div className='px-2'>{reloadin == false ? "" : hideorshow3 == true ?  <Webcam ref={webcamRefs[3]} /> : ""}</div></div>:''}

</div>
                </div>
                <div className="modal-footer mod-line m-auto">
                  {/* <button type="button" className="btn closecancel">Cancel</button> */}
                  <button type="button" className="btn closecancel" onClick={()=>{reloadcam()}}>Reload</button>
                  <button type="button" onClick={()=>{captureimagesuper2(3)}} aria-label="Close" data-bs-dismiss="modal" className="btn closebtn text-white">Capture</button>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="exampleModal340" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>


  
  <div className="col-12 px-2 pt-3">
  <img src={modifiedImagenumbertwo == null ? img3 : modifiedImagenumbertwo} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>


          <div className="modal fade" id="exampleModal350" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>


  
  <div className="col-12 px-2 pt-3">
  <img src={documentimageback2 == null ? img3 : documentimageback2} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>
          <div className="modal fade" id="exampleModaldocumenttwowwetwo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => {sethideorshow3(!hideorshow3)}} aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px' }}>

  {hideorshow3 == true ? <div className="col-12 d-flex py-3 justify-content-center">
<div className='px-2'>{reloadin == false ? "" : hideorshow3 == true ?  <Webcamtwo ref={webcamRefs[4]} /> : ""}</div></div>:''}

</div>
                </div>
                <div className="modal-footer mod-line m-auto">
                  {/* <button type="button" className="btn closecancel">Cancel</button> */}
                  <button type="button" className="btn closecancel" onClick={()=>{reloadcam()}}>Reload</button>
                  <button type="button" onClick={()=>{captureimagethreetwo(4)}} aria-label="Close" data-bs-dismiss="modal" className="btn closebtn text-white">Capture</button>
                </div>
              </div>
            </div>
          </div>



            <div className="modal fade" id="exampleModaltwo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px auto", borderRadius: '3px', paddingBottom: '3rem' }}>
                <div className="col-12 px-2 pt-3">
  <img src={displayphoto == '' ? img3 : displayphoto} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
              </div>
            </div>
          </div>     
            <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>

<div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="tf2" className="form-label ">Right Thumb  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" id='tf2' name="image2" style={{opacity:"0",position:'absolute'}}  onChange={(e) => {handleImageChange11(e)}}  /> */}
        {/* <label htmlFor="tf"> */}
        {/* <div onClick={(e) => {handleImageChange11()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: thumbimg2 != '' ? '' : '3rem',width:"20%" }}> {thumbimg2 != '' ? <img src={`data:image/png;base64,${thumbimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange11()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: thumbimg2 != '' ? '' : rtferror == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {thumbimg2 != '' ? <img src={`data:image/png;base64,${thumbimg2}`} width="70px" height="60px" alt="asd" /> : rtferror == true ? <Loadercomp size={30} /> : ''}  </div>
        {thumbimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="ltf2" className="form-label ">Left Thumb  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="ltf2" onChange={(e) => {handleImageChange16(e)}} /> */}
        {/* <div onClick={(e) => {handleImageChange16()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: ltfimg2 != '' ? '' : '3rem',width:"20%" }}> {ltfimg2 != '' ? <img src={`data:image/png;base64,${ltfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange16()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: ltfimg2 != '' ? '' : ltferror == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {ltfimg2 != '' ? <img src={`data:image/png;base64,${ltfimg2}`} width="70px" height="60px" alt="asd" /> : ltferror == true ? <Loadercomp size={30} /> : ''}  </div>
        {ltfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rif2" className="form-label ">Right Index Finger <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rif2" onChange={(e) => {handleImageChange12(e)}} /> */}
        {/* <div onClick={(e) => {handleImageChange12()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rifimg2 != '' ? '' : '3rem',width:"20%" }}> {rifimg2 != '' ? <img src={`data:image/png;base64,${rifimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange12()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rifimg2 != '' ? '' : riferror == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rifimg2 != '' ? <img src={`data:image/png;base64,${rifimg2}`} width="70px" height="60px" alt="asd" /> : riferror == true ? <Loadercomp size={30} /> : ''}  </div>
        {rifimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="lif2" className="form-label ">Left Index Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lif2" onChange={(e) => {handleImageChange17(e)}} /> */}
        {/* <div onClick={(e) => {handleImageChange17()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lifimg2 != '' ? '' : '3rem',width:"20%" }}> {lifimg2 != '' ? <img src={`data:image/png;base64,${lifimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange17()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: lifimg2 != '' ? '' : liferror == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {lifimg2 != '' ? <img src={`data:image/png;base64,${lifimg2}`} width="70px" height="60px" alt="asd" /> : liferror == true ? <Loadercomp size={30} /> : ''}  </div>
        {lifimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rmf2" className="form-label ">Right Middle Finger<span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rmf2" onChange={(e) => {handleImageChange13(e)}} /> */}
        {/* <div onClick={(e) => {handleImageChange13()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rmfimg2 != '' ? '' : '3rem',width:"20%" }}> {rmfimg2 != '' ? <img src={`data:image/png;base64,${rmfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange13()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rmfimg2 != '' ? '' : rmferror == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rmfimg2 != '' ? <img src={`data:image/png;base64,${rmfimg2}`} width="70px" height="60px" alt="asd" /> : rmferror == true ? <Loadercomp size={30} /> : ''}  </div>
        {rmfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
      </div>
    </div>

  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="lmf2" className="form-label ">Left Middle Finger <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lmf2" onChange={(e) => {handleImageChange18(e)}} /> */}
        {/* <div onClick={(e) => {handleImageChange18()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lmfimg2 != '' ? '' : '3rem',width:"20%" }}> {lmfimg2 != '' ? <img src={`data:image/png;base64,${lmfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange18()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: lmfimg2 != '' ? '' : lmferror == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {lmfimg2 != '' ? <img src={`data:image/png;base64,${lmfimg2}`} width="70px" height="60px" alt="asd" /> : lmferror == true ? <Loadercomp size={30} /> : ''}  </div>
        {lmfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rrf2" className="form-label ">Right Ring Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rrf2" onChange={(e) => {handleImageChange14(e)}} /> */}
        {/* <div onClick={(e) => {handleImageChange14()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rrfimg2 != '' ? '' : '3rem',width:"20%" }}> {rrfimg2 != '' ? <img src={`data:image/png;base64,${rrfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange14()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rrfimg2 != '' ? '' : rrferror == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rrfimg2 != '' ? <img src={`data:image/png;base64,${rrfimg2}`} width="70px" height="60px" alt="asd" /> : rrferror == true ? <Loadercomp size={30} /> : ''}  </div>
        {rrfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
      </div>
    </div>

  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="lrf2" className="form-label ">Left Ring Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lrf2" onChange={(e) => {handleImageChange19(e)}} /> */}
        {/* <div onClick={(e) => {handleImageChange19()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lrfimg2 != '' ? '' : '3rem',width:"20%" }}> {lrfimg2 != '' ? <img src={`data:image/png;base64,${lrfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange19()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: lrfimg2 != '' ? '' : lrferror == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {lrfimg2 != '' ? <img src={`data:image/png;base64,${lrfimg2}`} width="70px" height="60px" alt="asd" /> : lrferror == true ? <Loadercomp size={30} /> : ''}  </div>
        {lrfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rlf2" className="form-label ">Right Little Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rlf2" onChange={(e) => {handleImageChange15(e)}} /> */}
        {/* <div onClick={(e) => {handleImageChange15()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rlfimg2 != '' ? '' : '3rem',width:"20%" }}> {rlfimg2 != '' ? <img src={`data:image/png;base64,${rlfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange15()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rlfimg2 != '' ? '' : rlferror == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rlfimg2 != '' ? <img src={`data:image/png;base64,${rlfimg2}`} width="70px" height="60px" alt="asd" /> : rlferror == true ? <Loadercomp size={30} /> : ''}  </div>
        {rlfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
      </div>
    </div>
  </div>

  
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="llf2" className="form-label ">Left Little Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="llf2" onChange={(e) => {handleImageChange20(e)}} /> */}
        {/* <div onClick={(e) => {handleImageChange20()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: llfimg2 != '' ? '' : '3rem',width:"20%" }}> {llfimg2 != '' ? <img src={`data:image/png;base64,${llfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange20()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: llfimg2 != '' ? '' : llferror == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {llfimg2 != '' ? <img src={`data:image/png;base64,${llfimg2}`} width="70px" height="60px" alt="asd" /> : llferror == true ? <Loadercomp size={30} /> : ''}  </div>
        {llfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
      </div>
    </div>
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>
                   {/* capture image box  */}


                   <div className="modal fade" id="exampleModalwebcam" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => {sethideorshow(!hideorshow)}} aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px' }}>

  {/* <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="llf2" className="form-label ">Left Little Finger  <span style={{ color: 'red' }}>*</span></label>
        <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="llf2" onChange={(e) => {handleImageChange20(e)}} />
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: llfimg2 != '' ? '' : '3rem',width:"20%" }}> {llfimg2 != '' ? <img src={llfimg2} width="70px" height="60px" alt="asd" /> : ''}  </div>
        {llfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
      </div>
    </div>
  </div> */}


  {hideorshow == true ? <div className="col-12 d-flex py-3 justify-content-center">
<div className='px-2'>{reloadin == false ? "" : hideorshow == true ?  <Webcam ref={webcamRefs[2]} /> : ""}</div></div>:''}

</div>
                </div>
                <div className="modal-footer mod-line m-auto">
                  {/* <button type="button" className="btn closecancel">Cancel</button> */}
                  <button type="button" className="btn closecancel" onClick={()=>{reloadcam()}}>Reload</button>
                  <button type="button" onClick={()=>{captureimage(2)}} aria-label="Close" data-bs-dismiss="modal" className="btn closebtn text-white">Capture</button>
                </div>
              </div>
            </div>
          </div>
            {agentresponse.status === 201 ? nvg("/agentlist/1") : <div></div>}
            <div className={showsuperotp == true ? "modal fade show" : "modal fade"} id="OTPPOPUP" tabindex="-1" style={{display:showsuperotp ? "block" : "none"}}  aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={()=>{setshowsuperotp(false)}} aria-label="Close"></button>
                </div>
                <div className="modal-body">

                  <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '1rem' }}>
                  {/* {otpnextmobile == true ?  */}
                
                  <div className="col-12 ccolor pt-2 px-4 text-center " style={{textDecoration:"underline"}}> VERIFY OTP</div>
                  <div className="col-12 d-flex justify-content-center"> {otploading == true ? <Loadercomp size={70} /> : ''}</div>
                 
                 {otploading == true ? '' : <div className="col-md-6 pb-1 px-2 " style={{paddingTop:"28px"}}>
                <div className="row">
                  <div className="col-lg-12">
                    <label htmlFor="" className="form-label">
                      Mobile OTP <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-12">
                  
{/* {otpnextmobile == true ?  */}
<div className="input-group ">
  <input type="number" className="form-control"   value={otp2}
                      onChange={(e) => {
                        setotp2(e.target.value);
                      }} placeholder="Mobile OTP" />
  {/* <span className="input-group-text" style={{cursor:"pointer"}} onClick={verifyotpmobile} id="basic-addon2">VERIFY OTP</span> */}
</div> 
{/* : ''} */}

            
                  </div>
                 
                </div>
              </div> }

              
              {/* : ''} */}

              {otploading == true ? '' : otpnext == true ?
                <div className="col-md-6 pb-1 px-2 " style={{paddingTop:"28px"}}>
                <div className="row">
                  <div className="col-lg-12">
                    <label htmlFor="" className="form-label">
                      Email OTP <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-12"> 
{otpnext == true ? <div className="input-group ">
  <input type="number" className="form-control"   value={otp}
                      onChange={(e) => {
                        setotp(e.target.value);
                      }} placeholder="Email OTP" /> 
  {/* <span className="input-group-text" onClick={verifyotp} style={{cursor:"pointer"}} id="basic-addon2">VERIFY OTP</span> */}
 </div>  
 : ''} 
            
                 </div>
                 
                </div>
              </div>
               : ''} 
               <div
        className="col-12 px-2 d-flex justify-content-end" 
      >
      
        <div class="alert alert-danger" role="alert"  style={{width:'100%',display:invalidotp == true ? 'block' : 'none'}}>
        Invalid Email OTP.
</div>
</div>
<div
        className="col-12 px-2 d-flex justify-content-end" 
      >
        <div class="alert alert-danger" role="alert"  style={{width:'100%',display:invalidotpmobile == true ? 'block' : 'none'}}>
        Invalid Mobile OTP.
</div>
</div>
<div
        className="col-12 px-2 d-flex justify-content-end" 
      >
        <div class="alert alert-success" role="alert"  style={{width:'100%',display:sendotpres == true ? 'block' : 'none'}}>
        OTP sent to your Email Successfully.
</div>
</div>
<div
        className="col-12 px-2 d-flex justify-content-end" 
      >
        <div class="alert alert-success" role="alert"  style={{width:'100%',display:sendotpresmobile == true ? 'block' : 'none'}}>
        OTP sent to your Mobile Successfully.
</div>
</div>
<div
        className="col-12 px-2 d-flex justify-content-end" 
      >
        <div class="alert alert-success" role="alert"  style={{width:'100%',display:otpshowres == true ? 'block' : 'none'}}>
        Email Verified Successfully.
</div>
</div>
<div
        className="col-12 px-2 d-flex justify-content-end" 
      >
        <div class="alert alert-success" role="alert"  style={{width:'100%',display:otpshowresmobile == true ? 'block' : 'none'}}>
        Mobile Number Verified Successfully.
</div>
</div>
<div
        className="col-12 px-2 d-flex justify-content-end" 
      >
        <div class="alert alert-danger" role="alert"  style={{width:'100%',display:verifyyourotp == true ? 'block' : 'none'}}>
        {registerpro == false ? registerpromobile == false ? "Please Verify Your Phone No ." : "Please Verify Your Email."  : registerpromobile == false ? "Please Verify Your Phone No ." : ""}   
</div>
{/* <div class="alert alert-danger" role="alert"  style={{width:'100%',display:baler == true ? 'block' : 'none'}}>
        { baler == true ? "Your Wallet Balance is low please Recharge First." : ''}   
</div> */}
      </div>
                  </div>
                </div>
                {/* {grandresponse.status === 201 ? nvg('/Remittancelist/1') : <div></div>} */}
                <div className="modal-footer mod-line m-auto">
                  <button type="button" onClick={()=>{setshowsuperotp(false)}} className="btn closecancel">Cancel</button>
                  <button type="button" onClick={()=>{verifyotpmobile()}} className="btn closebtn text-white">Verify</button>
                </div>
              </div>
            </div>
          </div>



            <div className="modal fade" id="exampleModaltwo123" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>
                <div className="col-12 px-2 pt-3">
  <img src={displaydocument == '' ? img33 : displaydocument} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
              </div>
            </div>
          </div>  


            <div className="modal fade" id="exampleModaldocument" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => {sethideorshowtwo(!hideorshowtwo)}} aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px' }}>

  {hideorshowtwo == true ? <div className="col-12 d-flex py-3 justify-content-center">
<div className='px-2'>{reloadin == false ? "" : hideorshowtwo == true ?  <Webcam ref={webcamRefs[0]} /> : ""}</div></div>:''}

</div>
                </div>
                <div className="modal-footer mod-line m-auto">
                  {/* <button type="button" className="btn closecancel">Cancel</button> */}
                  <button type="button" className="btn closecancel" onClick={()=>{reloadcam()}}>Reload</button>
                  <button type="button" onClick={()=>{captureimagetwo(0)}} aria-label="Close" data-bs-dismiss="modal" className="btn closebtn text-white">Capture</button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="exampleModaldocumenttwowwe" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => {sethideorshowthree(!hideorshowthree)}} aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px' }}>

  {hideorshowthree == true ? <div className="col-12 d-flex py-3 justify-content-center">
<div className='px-2'>{reloadin == false ? "" : hideorshowthree == true ?  <Webcamtwo ref={webcamRefs[1]} /> : ""}</div></div>:''}

</div>
                </div>
                <div className="modal-footer mod-line m-auto">
                  {/* <button type="button" className="btn closecancel">Cancel</button> */}
                  <button type="button" className="btn closecancel" onClick={()=>{reloadcam()}}>Reload</button>
                  <button type="button" onClick={()=>{captureimagethree(1)}} aria-label="Close" data-bs-dismiss="modal" className="btn closebtn text-white">Capture</button>
                </div>
              </div>
            </div>
          </div>
          </form>

        </div>}
      </div>
    </div>
  );
};

export default Addagent;
