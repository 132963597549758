import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import img1 from '../../assets/finger.png';
import img6 from '../../assets/Group 41.png';
import img2 from '../../assets/Group 40.png';
import img690 from "../../assets/sign.jpg";
import img3 from '../../assets/asdfg.png';
import img4 from "../../assets/abc.png";
import checkicon from '../../assets/Ellipse 28.png';
import img5 from "../../assets/cba.png";
// import checkicon from '../../assets/Ellipse 28.png';
import csc from 'country-state-city';
import axios from 'axios';
import { gettoken } from '../../Localstorage/Store';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import {css} from "@emotion/react";
import Loadercomp from '../../components/Loadercomp';
import ReactImageMagnify from 'react-image-magnify';
import { useRef } from 'react';
import Webcam from 'react-webcam';
import {CaptureFinger, MatchFinger} from "../mfs100";
import Select from "react-select";

const Editremittance = () => {
  const pickimg = useRef(null)
  const {id} = useParams()
  const nvg = useNavigate();
  const [data, setData] = useState([]);
  const [datatwo, setDatatwo] = useState([]);
  const [formdatao, setformDatao] = useState([]);
  const [thumbimg2, setthumbimg2] = useState('');
  const [rifimg2, setrifimg2] = useState('');
  const [rmfimg2, setrmfimg2] = useState('');
  const [rrfimg2, setrrfimg2] = useState('');
  const [rlfimg2, setrlfimg2] = useState('');
  const [ltfimg2, setltfimg2] = useState('');
  const [lifimg2, setlifimg2] = useState('');
  const [lmfimg2, setlmfimg2] = useState('');
  const [lrfimg2, setlrfimg2] = useState('');
  const [llfimg2, setllfimg2] = useState('');
  const [thumbimg, setthumbimg] = useState('');
  const [rifimg, setrifimg] = useState('');
  const [rmfimg, setrmfimg] = useState('');
  const [rrfimg, setrrfimg] = useState('');
  const [rlfimg, setrlfimg] = useState('');
  const [ltfimg, setltfimg] = useState('');
  const [lifimg, setlifimg] = useState('');
  const [lmfimg, setlmfimg] = useState('');
  const [lrfimg, setlrfimg] = useState('');
  const [llfimg, setllfimg] = useState('');
  const [srtloader, setsrtloader] = useState(true);

  const [rightnumberoffingers, setrightnumberoffingers] = useState(0);
  const [rigthfingerserror, setrigthfingerserror] = useState('');
  const [leftnumberoffingers, setleftnumberoffingers] = useState(0);
  const [ansithumbimg2, setansithumbimg2] = useState('');
  const [ansirifimg2, setansirifimg2] = useState('');
  const [ansirmfimg2, setansirmfimg2] = useState('');
  const [ansirrfimg2, setansirrfimg2] = useState('');
  const [ansirlfimg2, setansirlfimg2] = useState('');
  const [ansiltfimg2, setansiltfimg2] = useState('');
  const [ansilifimg2, setansilifimg2] = useState('');
  const [ansilmfimg2, setansilmfimg2] = useState('');
  const [ansilrfimg2, setansilrfimg2] = useState('');
  const [ansillfimg2, setansillfimg2] = useState('');
  const webcamRefs = [useRef(null), useRef(null), useRef(null) ];
  const [thumbimg2sand, setthumbimg2sand] = useState('');
  const [rifimg2sand, setrifimg2sand] = useState('');
  const [rmfimg2sand, setrmfimg2sand] = useState('');
  const [rrfimg2sand, setrrfimg2sand] = useState('');
  const [rlfimg2sand, setrlfimg2sand] = useState('');
  const [ltfimg2sand, setltfimg2sand] = useState('');
  const [lifimg2sand, setlifimg2sand] = useState('');
  const [lmfimg2sand, setlmfimg2sand] = useState('');
  const [lrfimg2sand, setlrfimg2sand] = useState('');
  const [llfimg2sand, setllfimg2sand] = useState('');
  const [showform, setshowform] = useState(false);
  const [pcountry,setpcountry] = useState(null)

  // product form state 




  const [hideorshow, sethideorshow] = useState(false);
  const [fname, setfname] = useState();
  const [lname, setlname] = useState();
  const [mobile_no, setmobile_no] = useState();
  const [gender, setgender] = useState("MALE");
  const [emailID, setemailID] = useState();
  const [company, setcompany] = useState("");
  const [dob, setdob] = useState();
  const [age, setage] = useState();
  const [location, setlocation] = useState("");
  const [address, setaddress] = useState();
  const [country, setcountry] = useState();
  const [doccountry, setdoccountry] = useState();
  const [state, setstate] = useState();
  const [city, setcity] = useState();
  const [pincode, setpincode] = useState();
  const [customer, setcustomer] = useState();
  const [realcustomer, setrealcustomer] = useState();
  const [transaction_no, settransaction_no] = useState();
  const [selectedprod, setselectedprod] = useState();
  const [photograph, setphotograph] = useState("");
  const [customerphotograph, setcustomerphotograph] = useState("");



  const [documenttype, setdocumenttype] = useState();
  const [idnumber, setidnumber] = useState();
  const [documentimage, setdocumentimage] = useState();
  const [documentissue, setdocumentissue] = useState();
  const [documentexpiry, setdocumentexpiry] = useState();
 
 
  const [documenttypetwo, setdocumenttypetwo] = useState();
  const [idnumbertwo, setidnumbertwo] = useState();
  const [documentissuetwo, setdocumentissuetwo] = useState();
  const [documentexpirytwo, setdocumentexpirytwo] = useState();


  const [rtf3error ,setrtf3error] = useState(false)
  const [rif3error ,setrif3error] = useState(false)
  const [rmf3error ,setrmf3error] = useState(false)
  const [rrf3error ,setrrf3error] = useState(false)
  const [rlf3error ,setrlf3error] = useState(false)
  const [ltf3error ,setltf3error] = useState(false)
  const [lif3error ,setlif3error] = useState(false)
  const [lmf3error ,setlmf3error] = useState(false)
  const [lrf3error ,setlrf3error] = useState(false)
  const [llf3error ,setllf3error] = useState(false)


  const [thumbimg5, setthumbimg5] = useState('');
  const [rifimg5, setrifimg5] = useState('');
  const [rmfimg5, setrmfimg5] = useState('');
  const [rrfimg5, setrrfimg5] = useState('');
  const [rlfimg5, setrlfimg5] = useState('');
  const [ltfimg5, setltfimg5] = useState('');
  const [lifimg5, setlifimg5] = useState('');
  const [lmfimg5, setlmfimg5] = useState('');
  const [lrfimg5, setlrfimg5] = useState('');
  const [llfimg5, setllfimg5] = useState('');
  const [ansithumbimg5, setansithumbimg5] = useState('');
  const [ansirifimg5, setansirifimg5] = useState('');
  const [ansirmfimg5, setansirmfimg5] = useState('');
  const [ansirrfimg5, setansirrfimg5] = useState('');
  const [ansirlfimg5, setansirlfimg5] = useState('');
  const [ansiltfimg5, setansiltfimg5] = useState('');
  const [ansilifimg5, setansilifimg5] = useState('');
  const [ansilmfimg5, setansilmfimg5] = useState('');
  const [ansilrfimg5, setansilrfimg5] = useState('');
  const [ansillfimg5, setansillfimg5] = useState('');






  const [rightnumberoffingers12, setrightnumberoffingers12] = useState(0);
  const [leftnumberoffingers12, setleftnumberoffingers12] = useState(0);


  const [thumbimg3sand, setthumbimg3sand] = useState('');
  const [rifimg3sand, setrifimg3sand] = useState('');
  const [rmfimg3sand, setrmfimg3sand] = useState('');
  const [rrfimg3sand, setrrfimg3sand] = useState('');
  const [rlfimg3sand, setrlfimg3sand] = useState('');
  const [ltfimg3sand, setltfimg3sand] = useState('');
  const [lifimg3sand, setlifimg3sand] = useState('');
  const [lmfimg3sand, setlmfimg3sand] = useState('');
  const [lrfimg3sand, setlrfimg3sand] = useState('');
  const [llfimg3sand, setllfimg3sand] = useState('');



  const [rigthfingerserror12, setrigthfingerserror12] = useState('');
  const [fingerperror12, setfingerperror12] = useState(false);

  const [transtionid,settranstionid] = useState()
  const [sandphoto,setsandphoto] = useState('')
  const [displayphoto,setdisplayphoto] = useState('')
  const [selectproduct,setselectproduct] = useState('aa')
  const [producterror,setproducterror] = useState(false)
  const [sandphotoerror,setsandphotoerror] = useState(false)
  const [fingerperror,setfingerperror] = useState(false)
  const [grandresponse,setgrandresponse] = useState({})
  const [errorcls, seterrorcls] = useState({});
  const [formdatao2, setformDatao2] = useState({});
  const gettokinval = gettoken();
  const [loading, setloading] = useState(false);
  const [grandloading, setgrandloading] = useState(false);
  let color = "blue";
  const override = css`   
    borderColor: "red",
  `;

  // capture image from webcam start here

const captureimage = (num) =>{
  setdisplayphoto(webcamRefs[num].current.getScreenshot())
  setsandphoto(webcamRefs[num].current.getScreenshot())

  sethideorshow(!hideorshow)
}
// capture image from webcam end here



const [reloadin,setreloadin] = useState(true)


const reloadcam = ()=>{
setreloadin(false)
setTimeout(() => {
setreloadin(true)
}, 1000);
}

const capturesignimage = (num) =>{
  setsigned(webcamRefs[num].current.getScreenshot())
  sethideorshow(!hideorshow)
}
// show all fingers images start here :--------------------->

const [artferror, setartferror] = useState('')
const handleImageChange11 = () => {
  setthumbimg2sand('')
  setthumbimg2('');
  setansithumbimg2('')
  setrtf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_r_thumb);
  if(client.data.Status){
    // console.log("3454355")
    setthumbimg2sand(formdatao.r_thumb)
    setthumbimg2(formdatao.r_thumb);
    setansithumbimg2(formdatao.ansi_r_thumb)
    setrightnumberoffingers(1)
        setartferror('')
  }else{
    // console.log("980090000")
    setartferror(" Not Match")
  }
  setrtf3error(false)
}, 1000);
};
const [ariferror, setariferror] = useState('')
const handleImageChange12 = () => {
  setrifimg2sand('')
  setrifimg2('');
  setrif3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_r_index_finger);
if(client.data.Status){
  // console.log("3454355")
  setrifimg2sand(formdatao.r_index_finger)
  setrifimg2(formdatao.r_index_finger);
      setrightnumberoffingers(1)
      setariferror('')
      setansirifimg2(formdatao.ansi_r_index_finger)
}else{
  // console.log("980090000")
  setariferror(" Not Match")
}
setrif3error(false)
}, 1000);
};
const [armferror, setarmferror] = useState('')
const handleImageChange13 = () => {
  setrmfimg2sand('')
  setrmfimg2('');
  setrmf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_r_middle_finger);
  if(client.data.Status){
    // console.log("3454355")
    setrmfimg2sand(formdatao.r_middle_finger)
    setrmfimg2(formdatao.r_middle_finger);
    setansirmfimg2(formdatao.ansi_r_middle_finger)
    setrightnumberoffingers(1)
        setarmferror('')
  }else{
    // console.log("980090000")
    setarmferror(" Not Match")
  }
  setrmf3error(false)
}, 1000);
};
const [arrferror, setarrferror] = useState('')
const handleImageChange14 = () => {
  setrrfimg2sand('')
  setrrfimg2('');
  setrrf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_r_ring_finger);
  if(client.data.Status){
    // console.log("3454355")
    setrrfimg2sand(formdatao.r_ring_finger)
    setrrfimg2(formdatao.r_ring_finger);
    setansirrfimg2(formdatao.ansi_r_ring_finger)
    setrightnumberoffingers(1)
        setarrferror('')
  }else{
    // console.log("980090000")
    setarrferror(" Not Match")
  }
  setrrf3error(false)
}, 1000);
};


const [arlferror, setarlferror] = useState('')

const handleImageChange15 = () => {
  setrlfimg2sand('')
  setrlfimg2('');
  setrlf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_r_little_finger);
  if(client.data.Status){
    // console.log("3454355")
    setrlfimg2sand(formdatao.r_little_finger)
    setrlfimg2(formdatao.r_little_finger);
    setansirlfimg2(formdatao.ansi_r_little_finger)
    setrightnumberoffingers(1)
        setarlferror('')
  }else{
    // console.log("980090000")
    setarlferror(" Not Match")
  }
  setrlf3error(false)
}, 1000);
};
const [altferror, setaltferror] = useState('')

const handleImageChange16 = () => {
  setltfimg2sand('')
  setltfimg2('');
setltf3error(true)
setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_l_thumb);
  if(client.data.Status){
    // console.log("3454355")
    setltfimg2sand(formdatao.l_thumb)
    setltfimg2(formdatao.l_thumb);
    setansiltfimg2(formdatao.ansi_l_thumb)
    setrightnumberoffingers(1)
        setaltferror('')
  }else{
    // console.log("980090000")
    setaltferror(" Not Match")
  }
  setltf3error(false)
}, 1000);
};

const [aliferror, setaliferror] = useState('')

const handleImageChange17 = () => {
  setlifimg2sand('')
  setlifimg2('');
  setlif3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_l_index_finger);
  if(client.data.Status){
    // console.log("3454355")
    setlifimg2sand(formdatao.l_index_finger)
    setlifimg2(formdatao.l_index_finger);
    setansilifimg2(formdatao.ansi_l_index_finger)
    setrightnumberoffingers(1)
        setaliferror('')
  }else{
    // console.log("980090000")
    setaliferror(" Not Match")
  }
  setlif3error(false)
}, 1000);
};

const [almferror, setalmferror] = useState('')

const handleImageChange18 = () => {
  setlmfimg2sand('')
  setlmfimg2('');
setlmf3error(true)
setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_l_middle_finger);
  if(client.data.Status){
    // console.log("3454355")
    setlmfimg2sand(formdatao.l_middle_finger)
    setlmfimg2(formdatao.l_middle_finger);
    setansilmfimg2(formdatao.ansi_l_middle_finger)
    setrightnumberoffingers(1)
        setalmferror('')
  }else{
    // console.log("980090000")
    setalmferror(" Not Match")
  }
  setlmf3error(false)
}, 1000);
};
const [alrferror, setalrferror] = useState('')

const handleImageChange19 = () => {
  setlrfimg2sand('')
  setlrfimg2('');
setlrf3error(true)
setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_l_ring_finger);
  if(client.data.Status){
    // console.log("3454355")
    setlrfimg2sand(formdatao.l_ring_finger)
    setlrfimg2(formdatao.l_ring_finger);
    setansilrfimg2(formdatao.ansi_l_ring_finger)
    setrightnumberoffingers(1)
    setalrferror('')
  }else{
    // console.log("980090000")
    setalrferror(" Not Match")
  }
  setlrf3error(false)
}, 1000);
};
const [allferror, setallferror] = useState('')

const handleImageChange20 = () => {

  setllfimg2sand('')
  setllfimg2('');
setllf3error(true)
setTimeout(() => {


  const client = new MatchFinger(80,5000,formdatao.ansi_l_little_finger);
  if(client.data.Status){
    // console.log("3454355")
    setllfimg2sand(formdatao.l_little_finger)
    setllfimg2(formdatao.l_little_finger);
    setansillfimg2(formdatao.ansi_l_little_finger)
    setrightnumberoffingers(1)
        setallferror('')
  }else{
    // console.log("980090000")
    setallferror(" Not Match")
  }
  setllf3error(false)
}, 1000);
};








const [artf2error, setartf2error] = useState('')
const handleImageChange110 = () => {
  setthumbimg2sand('')
  setthumbimg5('');
  setansithumbimg5('')
  setrtf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao2.ansi_r_thumb);
  if(client.data.Status){
    setthumbimg2sand(formdatao2.r_thumb)
    setthumbimg5(formdatao2.r_thumb);
    setansithumbimg5(formdatao2.ansi_r_thumb)
    setrightnumberoffingers12(1)
        setartf2error('')
  }else{
    // console.log("980090000")
    setartf2error(" Not Match")
  }
  setrtf3error(false)
}, 1000);
};
const [arif2error, setarif2error] = useState('')
const handleImageChange120 = () => {
  setrifimg2sand('')
  setrifimg5('');
  setansirifimg5();
  setrif3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao2.ansi_r_index_finger);
if(client.data.Status){
  // console.log("3454355")
  setrifimg2sand(formdatao2.r_index_finger)
  setrifimg5(formdatao2.r_index_finger);
      setrightnumberoffingers12(1)
      setarif2error('')
      setansirifimg5(formdatao2.ansi_r_index_finger)
}else{
  // console.log("980090000")
  setarif2error(" Not Match")
}
setrif3error(false)
}, 1000);
};
const [armf2error, setarmf2error] = useState('')
const handleImageChange130 = () => {
  setrmfimg2sand('')
  setrmfimg5('');
  setrmf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao2.ansi_r_middle_finger);
  if(client.data.Status){
    // console.log("3454355")
    setrmfimg2sand(formdatao2.r_middle_finger)
    setrmfimg5(formdatao2.r_middle_finger);
    setansirmfimg5(formdatao2.ansi_r_middle_finger)
    setrightnumberoffingers12(1)
        setarmf2error('')
  }else{
    // console.log("980090000")
    setarmf2error(" Not Match")
  }
  setrmf3error(false)
}, 1000);
};


const [arrf2error, setarrf2error] = useState('')
const handleImageChange140 = () => {
  setrrfimg2sand('')
  setrrfimg5('');
  setrrf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao2.ansi_r_ring_finger);
  if(client.data.Status){
    // console.log("3454355")
    setrrfimg2sand(formdatao2.r_ring_finger)
    setrrfimg5(formdatao2.r_ring_finger);
    setansirrfimg5(formdatao2.ansi_r_ring_finger)
    setrightnumberoffingers12(1)
        setarrf2error('')
  }else{
    // console.log("980090000")
    setarrf2error(" Not Match")
  }
  setrrf3error(false)
}, 1000);
};


const [arlf2error, setarlf2error] = useState('')

const handleImageChange150 = () => {
  setrlfimg2sand('')
  setrlfimg5('');
  setrlf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao2.ansi_r_little_finger);
  if(client.data.Status){
    // console.log("3454355")
    setrlfimg2sand(formdatao2.r_little_finger)
    setrlfimg5(formdatao2.r_little_finger);
    setansirlfimg5(formdatao2.ansi_r_little_finger)
    setrightnumberoffingers12(1)
        setarlf2error('')
  }else{
    // console.log("980090000")
    setarlf2error(" Not Match")
  }
  setrlf3error(false)
}, 1000);
};
const [altf2error, setaltf2error] = useState('')

const handleImageChange160 = () => {
  setltfimg2sand('')
    setltfimg5('');
  setltf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao2.ansi_l_thumb);
  if(client.data.Status){
    // console.log("3454355")
    setltfimg2sand(formdatao2.l_thumb)
    setltfimg5(formdatao2.l_thumb);
    setansiltfimg5(formdatao2.ansi_l_thumb)
    setrightnumberoffingers12(1)
        setaltf2error('')
  }else{
    // console.log("980090000")
    setaltf2error(" Not Match")
  }
  setltf3error(false)
}, 1000);
};

const [alif2error, setalif2error] = useState('')

const handleImageChange170 = () => {
  setlifimg2sand('')
  setlifimg5('');
  setlif3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao2.ansi_l_index_finger);
  if(client.data.Status){
    // console.log("3454355")
    setlifimg2sand(formdatao2.l_index_finger)
    setlifimg5(formdatao2.l_index_finger);
    setansilifimg5(formdatao2.ansi_l_index_finger)
    setrightnumberoffingers12(1)
        setalif2error('')
  }else{
    // console.log("980090000")
    setalif2error(" Not Match")
  }
  setlif3error(false)
}, 1000);
};

const [almf2error, setalmf2error] = useState('')

const handleImageChange180 = () => {
  setlmfimg2sand('')
    setlmfimg5('');
  setlmf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao2.ansi_l_middle_finger);
  if(client.data.Status){
    // console.log("3454355")
    setlmfimg2sand(formdatao2.l_middle_finger)
    setlmfimg5(formdatao2.l_middle_finger);
    setansilmfimg5(formdatao2.ansi_l_middle_finger)
    setrightnumberoffingers12(1)
        setalmf2error('')
  }else{
    // console.log("980090000")
    setalmf2error(" Not Match")
  }
  setlmf3error(false)
}, 1000);
};
const [alrf2error, setalrf2error] = useState('')

const handleImageChange190 = () => {
  setlrfimg2sand('')
    setlrfimg5('');
  setlrf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao2.ansi_l_ring_finger);
  if(client.data.Status){
    // console.log("3454355")
    setlrfimg2sand(formdatao2.l_ring_finger)
    setlrfimg5(formdatao2.l_ring_finger);
    setansilrfimg5(formdatao2.ansi_l_ring_finger)
    setrightnumberoffingers12(1)
    setalrf2error('')
  }else{
    // console.log("980090000")
    setalrf2error(" Not Match")
  }
  setlrf3error(false)
}, 1000);
};
const [allf2error, setallf2error] = useState('')

const handleImageChange200 = () => {
  setllfimg2sand('')
    setllfimg5('');
  setllf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao2.ansi_l_little_finger);
  if(client.data.Status){
    // console.log("3454355")
    setllfimg2sand(formdatao2.l_little_finger)
    setllfimg5(formdatao2.l_little_finger);
    setansillfimg5(formdatao2.ansi_l_little_finger)
    setrightnumberoffingers12(1)
        setallf2error('')
  }else{
    // console.log("980090000")
    setallf2error(" Not Match")
  }
  setllf3error(false)
}, 1000);
};





let gettokenvalue = gettoken()
  useEffect(() => {
    if (gettokenvalue.Checker == false && gettokenvalue.Approver === false && gettokenvalue.superuser === false) {
      nvg('/remittancelist/0');
    }else{
   
    }
  })
// show all fingers images End here :--------------------->


  // fetch all dropdown in page start here :-----------
  // useEffect(() => {
  //   async function fetchData() {
  //     const config = {
  //       headers: {
  //         Authorization: `Bearer ${gettokinval.access}`,
  //       },
  //     };
  //     let url = 'https://backend.yourweeklystory.com/api/customer/';
  //     const response = await axios.get(url, config);
  //     setData(response.data);
  //     setsrtloader(false)
  //   }
  //   async function fetchDatatwo() {
  //     const config = {
  //       headers: {
  //         Authorization: `Bearer ${gettokinval.access}`,
  //       },
  //     };
  //     let url = "https://backend.yourweeklystory.com/api/product/";
  //     const response = await axios.get(url, config);
  //     setDatatwo(response.data);
  //   }
  //   fetchDatatwo();
  //   fetchData();
  // }, []);
     // fetch all dropdown in page end here :-----------

    //  filter form start here :-------------->
  async function filterformhere(e){
    e.preventDefault();

    if(rightnumberoffingers12 == 0 && gettokinval.email !== "test@gmail.com"){
      setfingerperror12(true)
setrigthfingerserror12('Verify atleast One Finger Print')
}else{
setrigthfingerserror12('')

    // if(leftnumberoffingers12 == 0 && gettokinval.email !== "test@gmail.com"){
    //   setfingerperror12(true)
    // setrigthfingerserror12('Capture atleast One Finger in Your Left Hand')
    // }else{
    
    setfingerperror12(false)
    // setrigthfingerserror12('')
    if(rightnumberoffingers == 0 && gettokinval.email !== "test@gmail.com"){
      setfingerperror(true)
      setrigthfingerserror('Verify Atleast One finger Print')
    }else{

      setrigthfingerserror('')
      // if(leftnumberoffingers == 0 && gettokinval.email !== "test@gmail.com"){
      // setfingerperror(true)
      // setrigthfingerserror('Match atleast One Finger in Your Left Hand')
      // }else{
      setfingerperror(false)
          setgrandloading(true)
          const formdata = new FormData()
          formdata.append('transaction_no',transaction_no)
          formdata.append('transaction_image',signed)
          formdata.append("customer", realcustomer);
          if(pcountry.label){
            formdata.append("country", pcountry.label);
          }
          if(sandphoto != ''){
            formdata.append('photograph',sandphoto)
          }
          if(selectproduct != 'aa'){
            formdata.append('product',selectproduct)
          }
          if(thumbimg2sand != ''){
            formdata.append("r_thumb", thumbimg2sand);
            formdata.append("ansi_r_thumb", ansithumbimg2);
          }
          if(rifimg2sand != ''){
            formdata.append("r_index_finger", rifimg2sand);
            formdata.append("ansi_r_index_finger", ansirifimg2);
          }
          if(rmfimg2sand != ''){
            formdata.append("r_middle_finger", rmfimg2sand);
            formdata.append("ansi_r_middle_finger", ansirmfimg2);
          }
          if(rrfimg2sand != ''){
            formdata.append("r_ring_finger", rrfimg2sand);
            formdata.append("ansi_r_ring_finger", ansirrfimg2);

          }
          if(rlfimg2sand != ''){
            formdata.append("r_little_finger", rlfimg2sand);
            formdata.append("ansi_r_little_finger", ansirlfimg2);
          }
          if(ltfimg2sand != ''){
            formdata.append("l_thumb", ltfimg2sand);
            formdata.append("ansi_l_thumb", ansiltfimg2);
          }
          if(lifimg2sand != ''){
            formdata.append("l_index_finger", lifimg2sand);
            formdata.append("ansi_l_index_finger", ansilifimg2);
          }
          if(lmfimg2sand != ''){
            formdata.append("l_middle_finger", lmfimg2sand);
            formdata.append("ansi_l_middle_finger", ansilmfimg2);
          }
          if(lrfimg2sand != ''){
            formdata.append("l_ring_finger", lrfimg2sand);
            formdata.append("ansi_l_ring_finger", ansilrfimg2);
          }
          if(llfimg2sand != ''){
            formdata.append("l_little_finger", llfimg2sand);
            formdata.append("ansi_l_little_finger", ansillfimg2);
          }
              try {
                setgrandloading(true)
                const config = {
            headers: {
              Authorization: `Bearer ${gettokinval.access}`,
            },
          };
          // let url = `https://backend.yourweeklystory.com/api/remittance/${id}/`;
          const response = await axios.patch(`${process.env.REACT_APP_API_URL}api/remittance/${id}/`,formdata, config);
            setgrandresponse(response);
            setshowform(true);
            setgrandloading(false);
            if(response.status == 200){
              nvg('/Remittancelist/2')
            }
          } catch (error) {
            setshowform(false)
            // console.log('something went wrong try again',error)  
            seterrorcls(error.response.data)
            setgrandloading(false)
          }   


        // }
      }
      // }
    }
  }
  //  filter form start here :-------------->
  
  
  
  let defaultValue;
  let updatedCountries;



  const countries = csc.getAllCountries();

  updatedCountries = countries.map((country) => ({
   label: country.name,
   value: country.id,
   ...country
 }));
  
  //  filter form start here :-------------->
  useEffect(() => {
    async function fetchData() {
      const config = {
        headers: {
          Authorization: `Bearer ${gettokinval.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}api/remittance/${id}/`;
      const response = await axios.get(url, config);
      // console.log(response)
      // setname(response.data.product_name)
      // setlogohelp(response.data.logo)
      setsrtloader(false)
      // setformdatao(response.data)
      setfname(response.data.customer_fname)
      setlname(response.data.customer_lname)
      setgender(response.data.customer_gender)
      setmobile_no(response.data.customer_mobile_no)
      setcompany(response.data.customer_company)
      setlocation(response.data.customer_company_location)
      setcountry(response.data.customer_country)
      setdoccountry(response.data.customer_country_doc2)
      setstate(response.data.customer_state)
      setaddress(response.data.customer_address)
      setcity(response.data.customer_city)
      setpincode(response.data.customer_pincode)
      setemailID(response.data.customer_emailID)
      setphotograph(response.data.photograph)
      settransaction_no(response.data.transaction_no)
      setcustomerphotograph(response.data.customer_photograph)
      setselectedprod(response.data.product)
      setcustomer(response.data.customer_id)
      setrealcustomer(response.data.customer_id_cus)
      setdisplayphoto(response.data.photograph)
      setidnumber(response.data.customer_document_no)
      setdocumenttype(response.data.customer_document_type)
      setdocumentexpiry(response.data.expiry_date)
      setdocumentissue(response.data.issue_date)
      setidnumbertwo(response.data.customer_document_no2)
      setdocumenttypetwo(response.data.customer_document_type2)
      setdocumentexpirytwo(response.data.expiry_date2)
      setdocumentissuetwo(response.data.issue_date2)
      // setllfimg2(response.data.l_little_finger)
      // setlrfimg2(response.data.l_ring_finger)
      // setlmfimg2(response.data.l_middle_finger)
      // setlifimg2(response.data.l_index_finger)
      // setltfimg2(response.data.l_thumb)
      // setrlfimg2(response.data.r_little_finger)
      // setrrfimg2(response.data.r_ring_finger)
      // setrmfimg2(response.data.r_middle_finger)
      // setrifimg2(response.data.r_index_finger)
      // setthumbimg2(response.data.r_thumb)
      setdob(response.data.customer_dob)
      setpcountry(response.data.country)
      setage(response.data.customer_age)
      setsigned(response.data.transaction_image)
      accountinfo(response.data.customer);
      setformDatao(response.data)





   
    }
    fetchData();



   
   
     defaultValue = updatedCountries.find((option) => option.label === pcountry);
    }, []);   
    
    defaultValue = updatedCountries.find((option) => option.label === pcountry);

  useEffect(() => {
  async function fetchDatatwo() {
    const config = {
      headers: {
        Authorization: `Bearer ${gettokinval.access}`,
      },
    };
    let url = `${process.env.REACT_APP_API_URL}api/active_product/`;
    const response = await axios.get(url, config);
    setDatatwo(response.data);
  }
  fetchDatatwo();

}, []);
    //  filter form start here :-------------->






    const [accountdata,setaccountdata] = useState([])
    const [addaccount, setaddaccount] = useState(false);
    const [accountno, setaccountno] = useState('');
    const [ifsccode, setifsccode] = useState('');
    const [accountname, setaccountname] = useState(null);
    const [alreadycreated, setalreadycreated] = useState(false);
    const [accountverified,setaccountverified] = useState(false)
    const [signed,setsigned] = useState('')

    async function accountinfo(id) {

      const config = {
        headers: {
          Authorization: `Bearer ${gettokinval.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}api/bank-accounts/get_by_id/${id}/`;
      const response = await axios.get(url, config);
      console.log(" lll;;;;;;",response)
      setaccountdata(response.data)
    
    if(response.data[0].beneficiaryAccount){
    setaddaccount(true);
    setalreadycreated(true)
    setaccountverified(true)
    setaccountno(response.data[0].beneficiaryAccount);
    setifsccode(response.data[0].beneIFSC);
    setaccountname(response.data[0].beneName);
    console.log("8900000",accountno,ifsccode,accountname)
    console.log("oppp")
    }else{
    setaddaccount(false);
    }
    }

    useEffect(() => {
      async function fetchBio() {
        const config = {
          headers: {
            Authorization: `Bearer ${gettokinval.access}`,
          },
        };
        let url = `${process.env.REACT_APP_API_URL}api/agent-biometric/${gettokinval.id}/`;
        const response = await axios.get(url, config);
        // setfname(response.data.customer_fname)
        // setlname(response.data.customer_lname)
        // setsrtloader(false)
        setformDatao2(response.data)
        setllfimg3sand(response.data.l_little_finger)
        setlrfimg3sand(response.data.l_ring_finger)
        setlmfimg3sand(response.data.l_middle_finger)
        setlifimg3sand(response.data.l_index_finger)
        setltfimg3sand(response.data.l_thumb)
        setrlfimg3sand(response.data.r_little_finger)
        setrrfimg3sand(response.data.r_ring_finger)
        setrmfimg3sand(response.data.r_middle_finger)
        setrifimg3sand(response.data.r_index_finger)
        setthumbimg3sand(response.data.r_thumb)
  
  // console.log(response)
  
     
      }
      fetchBio();
  
  
  
  
      }, []); 


      
      const twochangefrontimg = (e) =>{
        const reader = new FileReader();
        reader.readAsDataURL(e);
        reader.onload = () => {
          // console.log("oooooo",reader.result)
            setsigned(reader.result);
      
        }
      }


  return (
    <div style={{width:'100%',minHeight:'100vh'}}>
      {/* <Header /> */}
      <div className="dashboardcontent px-2">
      <div className="container-fuild px-2 desgin1 ">
      <div className="row bg-white ">
            {/* <div className="col-12 py-3 customtext23" style={{background: 'hsla(210, 85%, 32%, 0.2)',color:'#0C5398'}}>Add Remittance</div> */}
            <div className="col-lg-12 d-flex justify-content-between py-2" style={{ background: 'hsla(210, 85%, 32%, 0.2)', color: '#0C5398' }}>
              <p className="m-0 customfont" >Edit Remittance</p>
              <div className="addnew d-block mb-2">
                <button className="btn text-white closebtn"><NavLink to='/remittancelist/0' style={{ textDecoration: 'none', color: 'white' }}> x Close</NavLink></button>
              </div>
            </div>
        </div>

      </div>
      {srtloader == true ? <div className="container-fuild bg-white"> <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"90%"}}>
        <div className='px-2'> <Loadercomp size={100} /></div>
        </div></div> :  <div className="container-fuild pb-4 pt-3 px-2 bg-white">
      <form onSubmit={filterformhere}>
      <div className="row bg-white pb-4 round" style={{border:'1px solid #E0E0E0',margin:"10px 0px",borderRadius: '3px',position:'relative'}}>
        <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-4">
              <label htmlFor="" className="form-label">
              Customer Id <span style={{color:'red'}}>*</span>
              </label>
            </div>
            <div className="col-lg-8">
              <input type="text" className="form-control" value={customer} readOnly />
            </div>
          </div>
        </div>
        <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-4">
              <label htmlFor="" className="form-label customw">Email Address. <span style={{color:'red'}}>*</span> </label>
            </div>
            <div className="col-lg-8">
              <input type="email" className="form-control" value={emailID} readOnly />
            </div>
          </div>
        </div>
        <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-4">
              <label htmlFor="" className="form-label">First Name <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-8">
              <input type="text" className="form-control" value={fname} readOnly />
            </div>
          </div>
        </div>
        <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-4">
              <label htmlFor="" className="form-label">Last Name <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-8">
              <input type="text" className="form-control" value={lname} readOnly />
            </div>
          </div>
        </div>
        <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-4">
              <label htmlFor="" className="form-label">Gender <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-8">
              <input type="text" className="form-control" value={gender} readOnly />
            </div>
          </div>
        </div>
        <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-4">
              <label htmlFor="" className="form-label">Mobile No. <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-8">
              <input type="text" className="form-control" value={mobile_no} readOnly />
            </div>
          </div>
        </div>
        <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-4">
              <label htmlFor="" className="form-label">DOB <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-8">
              <input type="text" className="form-control" value={dob} readOnly />
            </div>
          </div>
        </div>
        <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-4">
              <label htmlFor="" className="form-label">Age <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-8">
              <input type="text" className="form-control" value={age} readOnly />
            </div>
          </div>
        </div>
        <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-4">
              <label htmlFor="" className="form-label">Country <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-8">
              <input type="text" className="form-control" value={country}  readOnly />
            </div>
          </div>
        </div>
        <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-4">
              <label htmlFor="" className="form-label">State <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-8">
              <input type="text" className="form-control" value={state}  readOnly />
            </div>
          </div>
        </div>
        <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-4">
              <label htmlFor="" className="form-label">City <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-8">
              <input type="text" className="form-control" value={city}  readOnly />
            </div>
          </div>
        </div>
        <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-4">
              <label htmlFor="" className="form-label">Pincode <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-8">
              <input type="text" className="form-control" value={pincode}  readOnly />
            </div>
          </div>
        </div>
        <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-4">
              <label htmlFor="" className="form-label">Company <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-8">
              <input type="text" className="form-control" value={company}  readOnly />
            </div>
          </div>
        </div>
        <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-4">
              <label htmlFor="" className="form-label">Location <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-8">
              <input type="text" className="form-control" value={location} readOnly />
            </div>
          </div>
        </div>
        <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-4">
              <label htmlFor="" className="form-label">Nationality <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-8">
              <input type="text" className="form-control" value={formdatao.customer_nationality} readOnly />
            </div>
          </div>
        </div>
       <div className="col-12">
       <div className="row px-2 pt-3">
       <div className="col-md-6">
          <div className="row">
            <div className="col-12"><label htmlFor="" className="form-label">Biometric Detail <span style={{color:'red'}}>*</span></label></div>
            <div className="col-12">
                        <div className="border round py-3 d-flex justify-content-center">
                        <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
     <label htmlFor="">Right Hand</label>
     <img src={formdatao.r_thumb == null || formdatao.r_thumb == "" ? img5 : img4} alt="rtf" style={{position:"absolute",top:"80px",left:"13px"}} />
     <img src={formdatao.r_index_finger == null || formdatao.r_index_finger == "" ? img5 : img4} alt="rif" style={{position:"absolute",top:"37px",left:"35px"}} />
     <img src={formdatao.r_middle_finger == null || formdatao.r_middle_finger == "" ? img5 : img4} alt="rmf" style={{position:"absolute",top:"30px",left:"65px"}} />
     <img src={formdatao.r_ring_finger == null || formdatao.r_ring_finger == "" ? img5 : img4} alt="rrf" style={{position:"absolute",top:"36px",left:"84px"}} />
     <img src={formdatao.r_little_finger == null || formdatao.r_little_finger == "" ? img5 : img4} alt="rlf" style={{position:"absolute",top:"55px",left:"103px"}} />
                          <img src={img2} alt="qwerty" />
                          </div>
                          <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
<label htmlFor="">Left Hand</label>
<img src={formdatao.l_little_finger == null || formdatao.l_little_finger == "" ? img5 : img4} alt="llf" style={{position:"absolute",top:"56px",left:"13px"}} />
     <img src={formdatao.l_ring_finger == null || formdatao.l_ring_finger == "" ? img5 : img4} alt="lrf" style={{position:"absolute",top:"36px",left:"33px"}} />
     <img src={formdatao.l_middle_finger == null || formdatao.l_middle_finger == "" ? img5 : img4} alt="lmf" style={{position:"absolute",top:"31px",left:"55px"}} />
     <img src={formdatao.l_index_finger == null || formdatao.l_index_finger == "" ? img5 : img4} alt="lif" style={{position:"absolute",top:"38px",left:"80px"}} />
     <img src={formdatao.l_thumb == null || formdatao.l_thumb == "" ? img5 : img4} alt="ltf" style={{position:"absolute",top:"80px",left:"102px"}} />
                          <img src={img6} alt="trew" />
                          </div>
                        </div>
                      </div>
          </div>
          <div className="col-12 py-2 px-4 d-flex justify-content-between">
                <button
                type='button'
                  // className="btn1"
                  style={{backgroundColor:"#0C5398"}}
                  className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal3"
                >
                  View
                </button>
              </div>
        </div>
        <div className="col-md-6">
        <div className="row">
            <div className="col-12"><label htmlFor="" className="form-label">Photograph <span style={{color:'red'}}>*</span></label></div>
            <div className="col-12 ">
            <div className="border round py-3 d-flex justify-content-center">
              {/* <img src={customerphotograph} alt="zxcvbnm" width="140px" height="162px" /> */}
              <button
                type='button'
                style={{border:'none',outline:'none'}}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModaltwo"
                >
              <img src={customerphotograph == null ? img3 : customerphotograph} alt="zxcvbnm" width="140px" height="162px" />
              </button>
              {/* <ReactImageMagnify
                            {...{
                                smallImage: {
                                    alt: 'Wristwatch by Versace',
                                    isFluidWidth: true,
                                    src: customerphotograph == null ? img3 : customerphotograph,
                                    width:140,
                                    height:162
                                },
                                largeImage: {
                                    src: customerphotograph == null ? img3 : customerphotograph,
                                    width: 836,
                                    height: 1100
                                },
                                // enlargedImagePosition: 'over',
                                lensStyle: { backgroundColor: 'rgba(0,0,0,.6)' }
                            }}
                            
                        /> */}
             </div>
            </div>
          </div>
        </div>
       </div>
       </div>
     <div className="col-12">
      <div className="row">
        <div className="col-12 ccolor py-2 px-4">Document</div>



        { documenttype == "AADHAAR" || documenttype == "AADHAR CARD" || documenttype == "" ? formdatao.customer_Form_60_pdf != null && formdatao.customer_Form_60_pdf != "" ? <div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        Form 60 PDF
      </div>

      <div className=" col-lg-8">
      {formdatao.customer_Form_60_pdf == null || formdatao.customer_Form_60_pdf == ''? '' : <a href={formdatao.customer_Form_60_pdf} style={{backgroundColor:"#0C5398",marginLeft:"5px"}}
type='button'
className='btn btn-primary' target="_blank" rel="noopener noreferrer"> View pdf</a>}
      </div>
    </div>
  </div> : '' : ''}

  { documenttype == "AADHAAR" || documenttype == "AADHAR CARD" || documenttype == "" ? formdatao.customer_Form_60_image != null && formdatao.customer_Form_60_image != "" ? <div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        Form 60 Image
      </div>
      <div className="col-lg-8">
      <img src={formdatao.customer_Form_60_image} alt="Modified Aadhaar Card" style={{width:"100%",height:"100%"}} />
      </div>
      <div className=" offset-lg-4 col-lg-8">
      <button
style={{backgroundColor:"#0C5398",marginTop:"5px"}}
type='button'
className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal35"
                >
View
                </button>
      </div>
    </div>
  </div> : '' : ''}
  {documenttype == "AADHAAR" || documenttype == "AADHAR CARD" || documenttype == "" ? '' : <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
        Document Type <span style={{ color: "red" }}>*</span>
        </label>
      </div>
      <div className="col-lg-8">

      <input type="text" className="form-control" value={documenttype} />
      </div>
    </div>
  </div> }


  {documenttype == "AADHAAR" || documenttype == "AADHAR CARD" || documenttype == "" ? '' : <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
        Document No. <span style={{ color: "red" }}>*</span>
        </label>
      </div>
      <div className="col-lg-8">
      <input type="text" className="form-control" value={idnumber}  />
      </div>
    </div>
  </div>}



  {documenttype == "AADHAAR" || documenttype == "AADHAR CARD" || documenttype == "" ? '' : documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT' ? <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
                <div className="row">
                  <div className="col-lg-4">
                    <label htmlFor="" className="form-label">
                      Expiry date <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-8">
                    <input
                      type="date"
                      className="form-control"
                      value={formdatao.customer_doc_expiry_date}
                      // placeholder="State"
                      // onChange={(e) => {
                      //   setstate(e.target.value);
                      // }}
                      // required
                    />
                    {/* {errorcls ? (
                      errorcls.state ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.state[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )} */}

                  </div>
                </div>
              </div> : ''}
              {documenttype == "AADHAAR" || documenttype == "AADHAR CARD" || documenttype == "" ? '' : documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT' ? <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
                <div className="row">
                  <div className="col-lg-4 ">
                    <label htmlFor="" className="form-label">
                      Issue date <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-8">
                    <input
                      type="date"
                      className="form-control"
                      value={formdatao.customer_doc_issue_date}
                      placeholder="Issue"
                      // onChange={(e) => {
                      //   setstate(e.target.value);
                      // }}
                      // required
                    />
                    {/* {errorcls ? (
                      errorcls.state ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.state[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
              </div> :''}
              {documenttype == "AADHAAR" || documenttype == "AADHAR CARD" || documenttype == "" ? '' : documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT' ? <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
                <div className="row">
                  <div className="col-lg-4 ">
                    <label htmlFor="" className="form-label">
                      Country <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-8">
                    <input
                      type="text"
                      className="form-control"
                      value={formdatao.customer_country_doc2}
                      placeholder="country"
                     
                    />
             
                  </div>
                </div>
              </div> :''}
              {documenttype == "AADHAAR" || documenttype == "AADHAR CARD" || documenttype == "" ? '' : documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT' ? <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
                {/* <div className="row">
                  <div className="col-lg-4 ">
                    <label htmlFor="" className="form-label">
                      Country <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-8">
                    <input
                      type="text"
                      className="form-control"
                      value={formdatao.customer_country_doc2}
                      placeholder="country"
                     
                    />
             
                  </div>
                </div> */}
              </div> :''}


              {documenttype == "AADHAAR" || documenttype == "AADHAR CARD" || documenttype == "" ? '' : <div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        Document Front Image
      </div>
      <div className="col-lg-8">
      <img src={formdatao.customer_docImg_front} alt="Modified Aadhaar Card" style={{width:"100%",height:"100%"}} />
      </div>
      <div className=" offset-lg-4 col-lg-8">
      <button
style={{backgroundColor:"#0C5398",marginTop:"5px"}}
type='button'
className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal34"
                >
View
                </button>
      </div>
    </div>
  </div>}


  {documenttype == "AADHAAR" || documenttype == "AADHAR CARD" || documenttype == "" ? '' : <div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        Document Back Image
      </div>
      <div className="col-lg-8">
      <img src={formdatao.customer_docImg_back} alt="Modified Aadhaar Card" style={{width:"100%",height:"100%"}} />
      </div>
      <div className=" offset-lg-4 col-lg-8">
      <button
style={{backgroundColor:"#0C5398",marginTop:"5px"}}
type='button'
className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal35"
                >
View
                </button>
      </div>
    </div>
  </div> }









        <div className="col-12 py-2 px-4">
          <div style={{background:'#D9D9D9',height:'1px',width:'100%'}}></div>
        </div>

        <div className="col-12 ccolor py-2 px-4">Document 2</div>
        <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
        Document Type <span style={{ color: "red" }}>*</span>
        </label>
      </div>
      <div className="col-lg-8">

      <input type="text" className="form-control" value={documenttypetwo} />
      </div>
    </div>
  </div>


  <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
        Document No. <span style={{ color: "red" }}>*</span>
        </label>
      </div>
      <div className="col-lg-8">
      <input type="text" className="form-control" value={idnumbertwo}  />
      </div>
    </div>
  </div>



  {documenttypetwo == 'DRIVING_LICENSE' || documenttypetwo == "RESIDENT_PERMIT" || documenttypetwo == "VISA" || documenttypetwo == "REFUGEE_CARD" || documenttypetwo == 'PASSPORT' ? <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
                <div className="row">
                  <div className="col-lg-4">
                    <label htmlFor="" className="form-label">
                      Expiry date <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-8">
                    <input
                      type="date"
                      className="form-control"
                      value={formdatao.customer_doc_expiry_date2}
                      // placeholder="State"
                      // onChange={(e) => {
                      //   setstate(e.target.value);
                      // }}
                      // required
                    />
                    {/* {errorcls ? (
                      errorcls.state ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.state[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )} */}

                  </div>
                </div>
              </div> : ''}
              {documenttypetwo == 'DRIVING_LICENSE' || documenttypetwo == "RESIDENT_PERMIT" || documenttypetwo == "VISA" || documenttypetwo == "REFUGEE_CARD" || documenttypetwo == 'PASSPORT' ? <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
                <div className="row">
                  <div className="col-lg-4 ">
                    <label htmlFor="" className="form-label">
                      Issue date <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-8">
                    <input
                      type="date"
                      className="form-control"
                      value={formdatao.customer_doc_issue_date2}
                      placeholder="Issue"
                      // onChange={(e) => {
                      //   setstate(e.target.value);
                      // }}
                      // required
                    />
                    {/* {errorcls ? (
                      errorcls.state ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.state[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
              </div> :''}
              <div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        Document Front Image
      </div>
      <div className="col-lg-8">
      <img src={formdatao.customer_docImg_front2} alt="Modified Aadhaar Card" style={{width:"100%",height:"100%"}} />
      </div>
      <div className=" offset-lg-4 col-lg-8">
      <button
style={{backgroundColor:"#0C5398",marginTop:"5px"}}
type='button'
className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal3400"
                >
View
                </button>
      </div>
    </div>
  </div>
    <div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        Document Back Image
      </div>
      <div className="col-lg-8">
      <img src={formdatao.customer_docImg_back2} alt="Modified Aadhaar Card" style={{width:"100%",height:"100%"}} />
      </div>
      <div className=" offset-lg-4 col-lg-8">
      <button
style={{backgroundColor:"#0C5398",marginTop:"5px"}}
type='button'
className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal3500"
                >
View
                </button>
      </div>
    </div>
  </div>





  
  {addaccount == true ?  <div className="col-12 py-3 px-4">
        
        <div
          style={{
            background: "#D9D9D9",
            height: "1px",
            width: "100%",
          }}
        ></div>
      </div> : ''}
  {addaccount == true ? <div className="col-12 ccolor py-3 px-4">Bank Account Verify</div> : ''}

{addaccount == true ? <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
<div className="row">
 <div className="col-lg-4">
   <label htmlFor="" className="form-label">
      Account No. <span style={{ color: "red" }}>*</span>
   </label>
 </div>
 <div className="col-lg-8">
   <input
     type="Number"
     className="form-control"
     value={accountno}
     placeholder="Account Number"
     readOnly
     required
   />

 </div>
</div>
</div> : "" } 

{addaccount == true ? <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
<div className="row">
 <div className="col-lg-4">
   <label htmlFor="" className="form-label">
   IFSC Code <span style={{ color: "red" }}>*</span>
   </label>
 </div>
 <div className="col-lg-8">
   <input
     type="text"
     className="form-control"
     value={ifsccode}
     placeholder="IFSC Code"
readOnly
     required
   />


 </div>
</div>
</div> : ""}
{addaccount == true ? accountname == null ? "" : <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
<div className="row">
 <div className="col-lg-4">
   <label htmlFor="" className="form-label">
   Holder Name <span style={{ color: "red" }}>*</span>
   </label>
 </div>
 <div className="col-lg-8">
   <input
     type="text"
     className="form-control"
     value={accountname}
     placeholder="Beneficiary Name"
   readOnly
   />

 </div>
</div>
</div> : ""}
<div className="col-12 py-2 px-4">
          <div style={{background:'#D9D9D9',height:'1px',width:'100%'}}></div>
        </div>
        <div className="col-md-12 py-2 px-4">
        <div className="row">
            <div className="col-lg-2">
              <label htmlFor="" className="form-label customw">Select Product <span style={{color:'red'}}>*</span> </label>
            </div>
            <div className="col-lg-10">
            <select name="" id="" className="form-select" onChange={(e) => { setselectproduct(e.target.value) }}  aria-label="Default select example">
            { datatwo.map((item) => (
              selectedprod === item.product_name ?
            <option value={item.id} key={item.id}> <img src={`${item.logo}`} width={30} alt="zxcvbnm"  /> {item.product_name}</option> : ''      
            ))}
            { datatwo.map((item) => (
              selectedprod != item.product_name ?
            <option value={item.id} key={item.id}> <img src={`${item.logo}`} width={30} alt="zxcvbnm" /> {item.product_name}</option> : ''      
            ))}
          </select>
          {/* { producterror == true ? <span style={{color:'red'}}> Please Select Product </span> : ''} */}

            </div>
          </div>
        </div>
        <div className="col-md-6 py-4 px-4">
        <div className="row px-2">
            <div className="col-lg-3 px-0">
              <label htmlFor="" className="form-label customw px-0">Transaction No.<span style={{color:'red'}}>*</span> </label>
            </div>
            <div className="col-lg-9">
              <input type="number" className="form-control" placeholder=' Transaction No.' value={transaction_no} onChange={(e) => { settransaction_no(e.target.value) }} required />
              {errorcls ? errorcls.transaction_no ? <span style={{color:'red'}}> {errorcls.transaction_no[0]} </span> : '' : ''}

            </div>
          </div>
        </div>
        <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      {/* Nationality  */}Country
                      <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    {/* <input
                      type="text"
                      value={country}
                      className="form-control"
                      placeholder="Country"
                      onChange={(e) => {
                        setcountry(e.target.value);
                      }}
                      required
                    /> */}

<Select
               
                      placeholder="Select Country"
                      options={updatedCountries}
                      value={defaultValue}
                      onChange={(e) => {
                        setpcountry(e)
                      }}
                      required
                    />
                      {/* <option value="" selected>
                        Select Country
                      </option> */}
                      {/* {countries.map((item) => (
                        <option  value={item.name}>
                          {item.name}
                        </option>
                      ))} */}
                    {/* </select> */}

                    {errorcls ? (
                      errorcls.country ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.country[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
        <div className="col-12">
       <div className="row px-2 pt-3">
        <div className="col-md-6">
          <div className="row">
            <div className="col-12"><label htmlFor="" className="form-label">Biometric Detail <span style={{color:'red'}}>*</span></label></div>
            <div className="col-12">
                        <div className="border round py-3 d-flex justify-content-center">
                        <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
     <label htmlFor="">Right Hand</label>
     <img src={thumbimg2 == '' || thumbimg2 == null ? img5 : img4} alt="rtf" style={{position:"absolute",top:"80px",left:"13px"}} />
     <img src={rifimg2 == '' || rifimg2 == null ? img5 : img4} alt="rif" style={{position:"absolute",top:"37px",left:"35px"}} />
     <img src={rmfimg2 == '' || rmfimg2 == null ? img5 : img4} alt="rmf" style={{position:"absolute",top:"30px",left:"65px"}} />
     <img src={rrfimg2 == '' || rrfimg2 == null ? img5 : img4} alt="rrf" style={{position:"absolute",top:"36px",left:"84px"}} />
     <img src={rlfimg2 == '' || rlfimg2 == null ? img5 : img4} alt="rlf" style={{position:"absolute",top:"55px",left:"103px"}} />
                          <img src={img2} alt="qwerty" />
                          </div>
                          <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
<label htmlFor="">Left Hand</label>
<img src={llfimg2 == '' || llfimg2 == null ? img5 : img4} alt="llf" style={{position:"absolute",top:"56px",left:"13px"}} />
     <img src={lrfimg2 == '' || lrfimg2 == null ? img5 : img4} alt="lrf" style={{position:"absolute",top:"36px",left:"33px"}} />
     <img src={lmfimg2 == '' || lmfimg2 == null ? img5 : img4} alt="lmf" style={{position:"absolute",top:"31px",left:"55px"}} />
     <img src={lifimg2 == '' || lifimg2 == null ? img5 : img4} alt="lif" style={{position:"absolute",top:"38px",left:"80px"}} />
     <img src={ltfimg2 == '' || ltfimg2 == null ? img5 : img4} alt="ltf" style={{position:"absolute",top:"80px",left:"102px"}} />
                          <img src={img6} alt="trew" />
                          </div>
                        </div>
                      </div>
            <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"90%"}}>
        <div className='px-2'>{grandloading == true ?  <Loadercomp  /> : ""}</div>
        </div>
            { fingerperror == true ? <span style={{color:'red'}}> Please Upload Biometric Details </span> : ''}
            {errorcls ? errorcls.r_index_finger ? <span style={{color:'red'}}> "Something Went wrong try again" </span> : '' : ''}
            <div className="col-12 py-2 px-4 d-flex justify-content-between">
                <button
                type='button'
                  // className="btn1"
                  style={{backgroundColor:"#0C5398"}}
                  className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Capture
                </button>
              </div>
          </div>
        </div>
        <div className="col-md-3">
        <div className="row">
            <div className="col-12"><label htmlFor="" className="form-label">Photograph <span style={{color:'red'}}>*</span></label></div>
            <div className="col-12 ">
            <div className="border round py-3 d-flex justify-content-center">
              {/* <label htmlFor=""> */}
              {/* <img src={displayphoto == '' ? img3 : displayphoto} alt="zxcvbnm" width="140px" height="162px" /> */}
              <button
                type='button'
                style={{border:'none',outline:'none'}}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalthree"
                >
              <img src={displayphoto == '' ? img3 : displayphoto} alt="zxcvbnm" width="140px" height="162px" />
              </button>
              {/* <ReactImageMagnify
                            {...{
                                smallImage: {
                                    alt: 'Wristwatch by Versace',
                                    isFluidWidth: true,
                                    src: displayphoto == null ? img3 : displayphoto,
                                    width:140,
                                    height:162
                                },
                                largeImage: {
                                    src: displayphoto == null ? img3 : displayphoto,
                                    width: 836,
                                    height: 1100
                                },
                                // enlargedImagePosition: 'over',
                                lensStyle: { backgroundColor: 'rgba(0,0,0,.6)' }
                            }}
                            
                        /> */}
              {/* </label> */}
             </div>
             <div className="col-12 py-2">
             <button
             style={{backgroundColor:"#0C5398"}}
                type='button'
                className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalwebcam"
                  onClick={()=>{sethideorshow(!hideorshow)}}
                >
                  Capture
                </button>
             {/* <label htmlFor="photorap" className='btn btn-primary' style={{backgroundColor:"#0C5398"}}> Browse</label> */}
             </div>
              {/* <input type="file" style={{opacity:"0",position:'absolute',left:"205555555"}} id="photorap" onChange={(e) => {handleImageChangespecial(e)}} /> */}
            </div>
            {errorcls ? errorcls.photograph ? <span style={{color:'red'}}> {errorcls.photograph[0]} </span> : '' : ''}
          { sandphotoerror == true ? <span style={{color:'red'}}> Please Select Product </span> : ''}
          </div>
        </div>
        <div className="col-md-3">
        <div className="row">
            <div className="col-12"><label htmlFor="" className="form-label">Customer Signed <span style={{color:'red'}}>*</span></label></div>
            <div className="col-12 ">
            <div className="border round py-3 d-flex justify-content-around">
            <button
                type='button'
                style={{border:'none',outline:'none'}}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal690"
                >
              <img src={signed == '' || signed == null ? img690 : signed} alt="zxcvbnm" width="140px" height="162px" />
              </button>
            
             </div>
             <div className="col-12 py-2">
             <button
             style={{backgroundColor:"#0C5398"}}
                type='button'
                className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalwebcam690"
                  onClick={()=>{sethideorshow(!hideorshow)}}
                >
                  Capture
                </button>
           
              <div className='btn btn-primary' style={{backgroundColor:"#0C5398",marginLeft:"5px"}}>
              <label htmlFor="img22doc" style={{width:'100%',height:'100%',display:'contents'}}>
              Upload
              </label>
              <input accept="image/*" type="file" id="img22doc" style={{display:'none'}} onChange={(e)=>{twochangefrontimg(e.currentTarget.files[0])}} alt="304" />
              
              </div>
           
             </div>
            </div>
            {errorcls ? errorcls.transaction_image
 ? <span style={{color:'red'}}> {errorcls.transaction_image
[0]} </span> : '' : ''}
          {/* { singederror == true ? <span style={{color:'red'}}> Please Capture or Upload your signature </span> : ''} */}
          </div>
        </div>
       </div>
       <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                  <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>
                  {formdatao.r_thumb == null || formdatao.r_thumb == "" ? '': <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="tf2" className="form-label ">Right Thumb <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" class="form-control" id='tf2' name="image2" style={{opacity:"0",position:'absolute'}}  onChange={(e) => {handleImageChange11(e)}}  /> */}
                          {/* <label htmlFor="tf"> */}
                          {/* <div onClick={(e) => {handleImageChange11()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: thumbimg2 != '' ? '' : '3rem',width:"20%" }}> {thumbimg2 != '' ? <img src={`data:image/png;base64,${thumbimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange11()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: thumbimg2 != '' ? '' : rtf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {thumbimg2 != '' ? <img src={`data:image/png;base64,${thumbimg2}`} width="70px" height="60px" alt="asd" /> : rtf3error == true ? <Loadercomp size={30} /> : ''}  </div>
                          
                          {thumbimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {artferror == '' ? '' : artferror}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao.l_thumb == null || formdatao.l_thumb == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="ltf2" className="form-label ">Left Thumb  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="ltf2" onChange={(e) => {handleImageChange16(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange16()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: ltfimg2 != '' ? '' : '3rem',width:"20%" }}> {ltfimg2 != '' ? <img src={`data:image/png;base64,${ltfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange16()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: ltfimg2 != '' ? '' : ltf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {ltfimg2 != '' ? <img src={`data:image/png;base64,${ltfimg2}`} width="70px" height="60px" alt="asd" /> : ltf3error == true ? <Loadercomp size={30} /> : ''}  </div> 
                          
                          {ltfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {altferror == '' ? '' : altferror}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao.r_index_finger == null || formdatao.r_index_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="rif2" className="form-label ">Right Index Finger <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rif2" onChange={(e) => {handleImageChange12(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange12()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rifimg2 != '' ? '' : '3rem',width:"20%" }}> {rifimg2 != '' ? <img src={`data:image/png;base64,${rifimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange12()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rifimg2 != '' ? '' : rif3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rifimg2 != '' ? <img src={`data:image/png;base64,${rifimg2}`} width="70px" height="60px" alt="asd" /> : rif3error == true ? <Loadercomp size={30} /> : ''}  </div>
            
                          {rifimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {ariferror == '' ? '' : ariferror}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao.l_index_finger == null || formdatao.l_index_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="lif2" className="form-label ">Left Index Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lif2" onChange={(e) => {handleImageChange17(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange17()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lifimg2 != '' ? '' : '3rem',width:"20%" }}> {lifimg2 != '' ? <img src={`data:image/png;base64,${lifimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange17()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: lifimg2 != '' ? '' : lif3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {lifimg2 != '' ? <img src={`data:image/png;base64,${lifimg2}`} width="70px" height="60px" alt="asd" /> : lif3error == true ? <Loadercomp size={30} /> : ''}  </div>
                          {lifimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {aliferror == '' ? '' : aliferror}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao.r_middle_finger == null || formdatao.r_middle_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="rmf2" className="form-label ">Right Middle Finger<span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rmf2" onChange={(e) => {handleImageChange13(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange13()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rmfimg2 != '' ? '' : '3rem',width:"20%" }}> {rmfimg2 != '' ? <img src={`data:image/png;base64,${rmfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange13()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rmfimg2 != '' ? '' : rmf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rmfimg2 != '' ? <img src={`data:image/png;base64,${rmfimg2}`} width="70px" height="60px" alt="asd" /> : rmf3error == true ? <Loadercomp size={30} /> : ''}  </div>
                          
                          {rmfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {armferror == '' ? '' : armferror}</p>
                        </div>
                      </div>

                    </div>}
                    {formdatao.l_middle_finger == null || formdatao.l_middle_finger == "" ? '' :<div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="lmf2" className="form-label ">Left Middle Finger <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lmf2" onChange={(e) => {handleImageChange18(e)}} /> */}
        <div onClick={(e) => {handleImageChange18()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: lmfimg2 != '' ? '' : lmf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {lmfimg2 != '' ? <img src={`data:image/png;base64,${lmfimg2}`} width="70px" height="60px" alt="asd" /> : lmf3error == true ? <Loadercomp size={30} /> : ''}  </div>                         
                          {/* <div onClick={(e) => {handleImageChange18()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lmfimg2 != '' ? '' : '3rem',width:"20%" }}> {lmfimg2 != '' ? <img src={`data:image/png;base64,${lmfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
                          {lmfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                      </div>
                    </div>}
                    {formdatao.r_ring_finger == null || formdatao.r_ring_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="rrf2" className="form-label ">Right Ring Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rrf2" onChange={(e) => {handleImageChange14(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange14()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rrfimg2 != '' ? '' : '3rem',width:"20%" }}> {rrfimg2 != '' ? <img src={`data:image/png;base64,${rrfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange14()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rrfimg2 != '' ? '' : rrf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rrfimg2 != '' ? <img src={`data:image/png;base64,${rrfimg2}`} width="70px" height="60px" alt="asd" /> : rrf3error == true ? <Loadercomp size={30} /> : ''}  </div>
                         
                          {rrfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {arrferror == '' ? '' : arrferror}</p>
                        </div>
                      </div>

                    </div> }
                    {formdatao.l_ring_finger == null || formdatao.l_ring_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="lrf2" className="form-label ">Left Ring Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lrf2" onChange={(e) => {handleImageChange19(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange19()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lrfimg2 != '' ? '' : '3rem',width:"20%" }}> {lrfimg2 != '' ? <img src={`data:image/png;base64,${lrfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange19()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: lrfimg2 != '' ? '' : lrf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {lrfimg2 != '' ? <img src={`data:image/png;base64,${lrfimg2}`} width="70px" height="60px" alt="asd" /> : lrf3error == true ? <Loadercomp size={30} /> : ''}  </div>
                          
                          {lrfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {alrferror == '' ? '' : alrferror}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao.r_little_finger == null || formdatao.r_little_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="rlf2" className="form-label ">Right Little Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rlf2" onChange={(e) => {handleImageChange15(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange15()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rlfimg2 != '' ? '' : '3rem',width:"20%" }}> {rlfimg2 != '' ? <img src={`data:image/png;base64,${rlfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange15()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rlfimg2 != '' ? '' : rlf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rlfimg2 != '' ? <img src={`data:image/png;base64,${rlfimg2}`} width="70px" height="60px" alt="asd" /> : rlf3error == true ? <Loadercomp size={30} /> : ''}  </div>
                          
                          {rlfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {arlferror == '' ? '' : arlferror}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao.l_little_finger == null || formdatao.l_little_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="llf2" className="form-label ">Left Little Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="llf2" onChange={(e) => {handleImageChange20(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange20()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: llfimg2 != '' ? '' : '3rem',width:"20%" }}> {llfimg2 != '' ? <img src={`data:image/png;base64,${llfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange20()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: llfimg2 != '' ? '' : llf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {llfimg2 != '' ? <img src={`data:image/png;base64,${llfimg2}`} width="70px" height="60px" alt="asd" /> : llf3error == true ? <Loadercomp size={30} /> : ''}  </div>
                          
                          {llfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {allferror == '' ? '' : allferror}</p>
                        </div>
                      </div>
                    </div>}
                  </div>
                </div>
                {grandresponse.status === 201 ? nvg('/Remittancelist/1') : <div></div>}
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>
                    {/* capture image box  */}


                    <div className="modal fade" id="exampleModalwebcam" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => {sethideorshow(!hideorshow)}} aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px' }}>

  {/* <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="llf2" className="form-label ">Left Little Finger  <span style={{ color: 'red' }}>*</span></label>
        <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="llf2" onChange={(e) => {handleImageChange20(e)}} />
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: llfimg2 != '' ? '' : '3rem',width:"20%" }}> {llfimg2 != '' ? <img src={llfimg2} width="70px" height="60px" alt="asd" /> : ''}  </div>
        // {llfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
      </div>
    </div>
  </div> */}


  {hideorshow == true ? <div className="col-12 d-flex py-3 justify-content-center">
<div className='px-2'>{reloadin == false ? "" : hideorshow == true ?  <Webcam ref={webcamRefs[0]} /> : ""}</div></div>:''}

</div>
                </div>
                <div className="modal-footer mod-line m-auto">
                  {/* <button type="button" className="btn closecancel">Cancel</button> */}
                  <button type="button" className="btn closecancel" onClick={()=>{reloadcam()}}>Reload</button>
                  <button type="button" onClick={()=>{captureimage(0)}} aria-label="Close" data-bs-dismiss="modal" className="btn closebtn text-white">Capture</button>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="exampleModalwebcam690" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => {sethideorshow(!hideorshow)}} aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px' }}>



  {hideorshow == true ? <div className="col-12 d-flex py-3 justify-content-center">
<div className='px-2'>{reloadin == false ? "" : hideorshow == true ?  <Webcam ref={webcamRefs[1]} /> : ""}</div></div>:''}

</div>
                </div>
                <div className="modal-footer mod-line m-auto">
                  {/* <button type="button" className="btn closecancel">Cancel</button> */}
                  <button type="button" className="btn closecancel" onClick={()=>{reloadcam()}}>Reload</button>
                  <button type="button" onClick={()=>{capturesignimage(1)}} aria-label="Close" data-bs-dismiss="modal" className="btn closebtn text-white">Capture</button>
                </div>
              </div>
            </div>
          </div>
       </div>



       <div className="col-12 py-3 px-4">
        
        <div
          style={{
            background: "#D9D9D9",
            height: "1px",
            width: "100%",
          }}
        ></div>
      </div> 
  
<div className="col-12 ccolor py-3 px-4">Verify Agent</div>

      <div className="col-md-6">
          <div className="row">
            <div className="col-12"><label htmlFor="" className="form-label">Biometric Detail <span style={{color:'red'}}>*</span></label></div>
            <div className="col-12">
                        <div className="border round py-3 d-flex justify-content-center">
                        <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
     <label htmlFor="">Right Hand</label>
     <img src={thumbimg3sand == '' || thumbimg3sand == null ? img5 : img4} alt="rtf" style={{position:"absolute",top:"80px",left:"13px"}} />
     <img src={rifimg3sand == '' || rifimg3sand == null ? img5 : img4} alt="rif" style={{position:"absolute",top:"37px",left:"35px"}} />
     <img src={rmfimg3sand == '' || rmfimg3sand == null ? img5 : img4} alt="rmf" style={{position:"absolute",top:"30px",left:"65px"}} />
     <img src={rrfimg3sand == '' || rrfimg3sand == null ? img5 : img4} alt="rrf" style={{position:"absolute",top:"36px",left:"84px"}} />
     <img src={rlfimg3sand == '' || rlfimg3sand == null ? img5 : img4} alt="rlf" style={{position:"absolute",top:"55px",left:"103px"}} />
                          <img src={img2} alt="qwerty" />
                          </div>
                          <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
<label htmlFor="">Left Hand</label>
<img src={llfimg3sand == '' || llfimg3sand == null ? img5 : img4} alt="llf" style={{position:"absolute",top:"56px",left:"13px"}} />
     <img src={lrfimg3sand == '' || lrfimg3sand == null ? img5 : img4} alt="lrf" style={{position:"absolute",top:"36px",left:"33px"}} />
     <img src={lmfimg3sand == '' || lmfimg3sand == null ? img5 : img4} alt="lmf" style={{position:"absolute",top:"31px",left:"55px"}} />
     <img src={lifimg3sand == '' || lifimg3sand == null ? img5 : img4} alt="lif" style={{position:"absolute",top:"38px",left:"80px"}} />
     <img src={ltfimg3sand == '' || ltfimg3sand == null ? img5 : img4} alt="ltf" style={{position:"absolute",top:"80px",left:"102px"}} />
                          <img src={img6} alt="trew" />
                          </div>
                        </div>
                      </div>
            { fingerperror12 == true ? <span style={{color:'red'}}> {rigthfingerserror12} </span> : ''}
            {/* {errorcls ? errorcls.r_index_finger ? <span style={{color:'red'}}> "Something Went wrong try again" </span> : '' : ''} */}
            <div className="col-12 py-2 px-4 d-flex justify-content-between">
                <button
                type='button'
                  // className="btn1"
             style={{backgroundColor:"#0C5398"}}
             className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal009"
                >
                  Capture
                </button>
              </div>
          </div>
        </div>
        <div className="col-12 py-5 px-4 d-flex justify-content-end" style={{gap:'4px'}}>
          <button className='btn4'>Cancel</button>
          <button type='submit' className='btn5'>Save</button>
        </div>
      </div>
     </div>
      </div></form>
      </div>}
      </div>









      <div className="modal fade" id="exampleModal34" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>


  
  <div className="col-12 px-2 pt-3">
  <img src={formdatao.customer_docImg_front == null ? img3 : formdatao.customer_docImg_front} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>

      <div className="modal fade" id="exampleModal3400" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>


  
  <div className="col-12 px-2 pt-3">
  <img src={formdatao.customer_docImg_front2 == null ? img3 : formdatao.customer_docImg_front2} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>



          <div className="modal fade" id="exampleModal009" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                  <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>
                  {formdatao2.r_thumb == null || formdatao2.r_thumb == "" ? '': <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="tf2" className="form-label ">Right Thumb <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" class="form-control" id='tf2' name="image2" style={{opacity:"0",position:'absolute'}}  onChange={(e) => {handleImageChange11(e)}}  /> */}
                          {/* <label htmlFor="tf"> */}
                          {/* <div onClick={(e) => {handleImageChange11()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: thumbimg2 != '' ? '' : '3rem',width:"20%" }}> {thumbimg2 != '' ? <img src={`data:image/png;base64,${thumbimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange110()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: thumbimg5 != '' ? '' : rtf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {thumbimg5 != '' ? <img src={`data:image/png;base64,${thumbimg5}`} width="70px" height="60px" alt="asd" /> : rtf3error == true ? <Loadercomp size={30} /> : ''}  </div>
                          
                          {thumbimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {artf2error == '' ? '' : artf2error}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao2.l_thumb == null || formdatao2.l_thumb == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="ltf2" className="form-label ">Left Thumb  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="ltf2" onChange={(e) => {handleImageChange16(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange16()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: ltfimg2 != '' ? '' : '3rem',width:"20%" }}> {ltfimg2 != '' ? <img src={`data:image/png;base64,${ltfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange160()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: ltfimg5 != '' ? '' : ltf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {ltfimg5 != '' ? <img src={`data:image/png;base64,${ltfimg5}`} width="70px" height="60px" alt="asd" /> : ltf3error == true ? <Loadercomp size={30} /> : ''}  </div> 
                          {ltfimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {altf2error == '' ? '' : altf2error}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao2.r_index_finger == null || formdatao2.r_index_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="rif2" className="form-label ">Right Index Finger <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rif2" onChange={(e) => {handleImageChange12(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange12()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rifimg2 != '' ? '' : '3rem',width:"20%" }}> {rifimg2 != '' ? <img src={`data:image/png;base64,${rifimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange120()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rifimg5 != '' ? '' : rif3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rifimg5 != '' ? <img src={`data:image/png;base64,${rifimg5}`} width="70px" height="60px" alt="asd" /> : rif3error == true ? <Loadercomp size={30} /> : ''}  </div>
          
                          {rifimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {arif2error == '' ? '' : arif2error}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao2.l_index_finger == null || formdatao2.l_index_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="lif2" className="form-label ">Left Index Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lif2" onChange={(e) => {handleImageChange17(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange17()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lifimg2 != '' ? '' : '3rem',width:"20%" }}> {lifimg2 != '' ? <img src={`data:image/png;base64,${lifimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange170()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: lifimg5 != '' ? '' : lif3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {lifimg5 != '' ? <img src={`data:image/png;base64,${lifimg5}`} width="70px" height="60px" alt="asd" /> : lif3error == true ? <Loadercomp size={30} /> : ''}  </div>
          
                          {lifimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {alif2error == '' ? '' : alif2error}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao2.r_middle_finger == null || formdatao2.r_middle_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="rmf2" className="form-label ">Right Middle Finger<span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rmf2" onChange={(e) => {handleImageChange13(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange13()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rmfimg2 != '' ? '' : '3rem',width:"20%" }}> {rmfimg2 != '' ? <img src={`data:image/png;base64,${rmfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange130()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rmfimg5 != '' ? '' : rmf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rmfimg5 != '' ? <img src={`data:image/png;base64,${rmfimg5}`} width="70px" height="60px" alt="asd" /> : rmf3error == true ? <Loadercomp size={30} /> : ''}  </div>
                  
                          {rmfimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {armf2error == '' ? '' : armf2error}</p>
                        </div>
                      </div>

                    </div>}
                    {formdatao2.l_middle_finger == null || formdatao2.l_middle_finger == "" ? '' :<div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="lmf2" className="form-label ">Left Middle Finger <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lmf2" onChange={(e) => {handleImageChange18(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange18()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lmfimg2 != '' ? '' : '3rem',width:"20%" }}> {lmfimg2 != '' ? <img src={`data:image/png;base64,${lmfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange180()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: lmfimg5 != '' ? '' : lmf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {lmfimg5 != '' ? <img src={`data:image/png;base64,${lmfimg5}`} width="70px" height="60px" alt="asd" /> : lmf3error == true ? <Loadercomp size={30} /> : ''}  </div>
        
                          {lmfimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                      </div>
                    </div>}
                    {formdatao2.r_ring_finger == null || formdatao2.r_ring_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="rrf2" className="form-label ">Right Ring Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rrf2" onChange={(e) => {handleImageChange14(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange14()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rrfimg2 != '' ? '' : '3rem',width:"20%" }}> {rrfimg2 != '' ? <img src={`data:image/png;base64,${rrfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange140()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rrfimg5 != '' ? '' : rrf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rrfimg5 != '' ? <img src={`data:image/png;base64,${rrfimg5}`} width="70px" height="60px" alt="asd" /> : rrf3error == true ? <Loadercomp size={30} /> : ''}  </div>

                          {rrfimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {arrf2error == '' ? '' : arrf2error}</p>
                        </div>
                      </div>

                    </div> }
                    {formdatao2.l_ring_finger == null || formdatao2.l_ring_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="lrf2" className="form-label ">Left Ring Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lrf2" onChange={(e) => {handleImageChange19(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange19()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lrfimg2 != '' ? '' : '3rem',width:"20%" }}> {lrfimg2 != '' ? <img src={`data:image/png;base64,${lrfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange190()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: lrfimg5 != '' ? '' : lrf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {lrfimg5 != '' ? <img src={`data:image/png;base64,${lrfimg5}`} width="70px" height="60px" alt="asd" /> : lrf3error == true ? <Loadercomp size={30} /> : ''}  </div>
                          
                          {lrfimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {alrf2error == '' ? '' : alrf2error}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao2.r_little_finger == null || formdatao2.r_little_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="rlf2" className="form-label ">Right Little Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rlf2" onChange={(e) => {handleImageChange15(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange15()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rlfimg2 != '' ? '' : '3rem',width:"20%" }}> {rlfimg2 != '' ? <img src={`data:image/png;base64,${rlfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange150()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rlfimg5 != '' ? '' : rlf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rlfimg5 != '' ? <img src={`data:image/png;base64,${rlfimg5}`} width="70px" height="60px" alt="asd" /> : rlf3error == true ? <Loadercomp size={30} /> : ''}  </div>
                          
                          {rlfimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {arlf2error == '' ? '' : arlf2error}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao2.l_little_finger == null || formdatao2.l_little_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="llf2" className="form-label ">Left Little Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="llf2" onChange={(e) => {handleImageChange20(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange20()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: llfimg2 != '' ? '' : '3rem',width:"20%" }}> {llfimg2 != '' ? <img src={`data:image/png;base64,${llfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange200()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: llfimg5 != '' ? '' : llf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {llfimg5 != '' ? <img src={`data:image/png;base64,${llfimg5}`} width="70px" height="60px" alt="asd" /> : llf3error == true ? <Loadercomp size={30} /> : ''}  </div>
                  
                          {llfimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {allf2error == '' ? '' : allf2error}</p>
                        </div>
                      </div>
                    </div>}
                  </div>
                </div>
                {/* {grandresponse.status === 201 ? nvg('/Remittancelist/1') : <div></div>} */}
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>

          <div className="modal fade" id="exampleModal690" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>
                <div className="col-12 px-2 pt-3">
  <img src={signed == '' ? img690 : signed} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
              </div>
            </div>
          </div>  
          <div className="modal fade" id="exampleModal35" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>


  
  <div className="col-12 px-2 pt-3">
  <img src={formdatao.customer_docImg_back == null ? img3 : formdatao.customer_docImg_back} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>

          <div className="modal fade" id="exampleModal3500" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>


  
  <div className="col-12 px-2 pt-3">
  <img src={formdatao.customer_docImg_back2 == null ? img3 : formdatao.customer_docImg_back2} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>










      <div className="modal fade" id="exampleModalthree" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>
                <div className="col-12 px-2 pt-3">
  <img src={displayphoto == '' ? img3 : displayphoto} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
              </div>
            </div>
          </div>  
      <div className="modal fade" id="exampleModaltwo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>
                <div className="col-12 px-2 pt-3">
  <img src={customerphotograph == null ? img3 : customerphotograph} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
              </div>
            </div>
          </div>  
          <div className="modal fade" id="exampleModal3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>

<div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="tf2" className="form-label ">Right Thumb  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" class="form-control" id='tf2' name="image2" style={{opacity:"0",position:'absolute'}}  onChange={(e) => {handleImageChange11(e)}}  /> */}
        {/* <label htmlFor="tf"> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: formdatao.r_thumb != '' && formdatao.r_thumb != null ? '' : '3rem',width:"20%" }}> {formdatao.r_thumb != '' && formdatao.r_thumb != null ? <img src={`data:image/png;base64,${formdatao.r_thumb}`} width="70px" height="60px" alt="asd" /> : ''}  </div>

      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="ltf2" className="form-label ">Left Thumb  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="ltf2" onChange={(e) => {handleImageChange16(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: formdatao.l_thumb != '' && formdatao.l_thumb != null ? '' : '3rem',width:"20%" }}> {formdatao.l_thumb != '' && formdatao.l_thumb != null ? <img src={`data:image/png;base64,${formdatao.l_thumb}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rif2" className="form-label ">Right Index Finger <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rif2" onChange={(e) => {handleImageChange12(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: formdatao.r_index_finger != '' && formdatao.r_index_finger != null ? '' : '3rem',width:"20%" }}> {formdatao.r_index_finger != '' && formdatao.r_index_finger != null ? <img src={`data:image/png;base64,${formdatao.r_index_finger}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="lif2" className="form-label ">Left Index Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lif2" onChange={(e) => {handleImageChange17(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: formdatao.l_index_finger != '' && formdatao.l_index_finger != null ? '' : '3rem',width:"20%" }}> {formdatao.l_index_finger != '' && formdatao.l_index_finger != null ? <img src={`data:image/png;base64,${formdatao.l_index_finger}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rmf2" className="form-label ">Right Middle Finger<span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rmf2" onChange={(e) => {handleImageChange13(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: formdatao.r_middle_finger != '' && formdatao.r_middle_finger != null ? '' : '3rem',width:"20%" }}> {formdatao.r_middle_finger != '' && formdatao.r_middle_finger != null ? <img src={`data:image/png;base64,${formdatao.r_middle_finger}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      </div>
    </div>

  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="lmf2" className="form-label ">Left Middle Finger <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lmf2" onChange={(e) => {handleImageChange18(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: formdatao.l_middle_finger != '' && formdatao.l_middle_finger != null ? '' : '3rem',width:"20%" }}> {formdatao.l_middle_finger != '' && formdatao.l_middle_finger != null ? <img src={`data:image/png;base64,${formdatao.l_middle_finger}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
        
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rrf2" className="form-label ">Right Ring Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rrf2" onChange={(e) => {handleImageChange14(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: formdatao.r_ring_finger != '' && formdatao.r_ring_finger != null ? '' : '3rem',width:"20%" }}> {formdatao.r_ring_finger != '' && formdatao.r_ring_finger != null ? <img src={`data:image/png;base64,${formdatao.r_ring_finger}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
       
      </div>
    </div>

  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="lrf2" className="form-label ">Left Ring Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lrf2" onChange={(e) => {handleImageChange19(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: formdatao.l_ring_finger != '' && formdatao.l_ring_finger != null ? '' : '3rem',width:"20%" }}> {formdatao.l_ring_finger != '' && formdatao.l_ring_finger != null ? <img src={`data:image/png;base64,${formdatao.l_ring_finger}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
        
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rlf2" className="form-label ">Right Little Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rlf2" onChange={(e) => {handleImageChange15(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: formdatao.r_little_finger != '' && formdatao.r_little_finger != null ? '' : '3rem',width:"20%" }}> {formdatao.r_little_finger != '' && formdatao.r_little_finger != null ? <img src={`data:image/png;base64,${formdatao.r_little_finger}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
     
      </div>
    </div>
  </div>


 
 
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="llf2" className="form-label ">Left Little Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="llf2" onChange={(e) => {handleImageChange20(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: formdatao.l_little_finger != '' && formdatao.l_little_finger != null ? '' : '3rem',width:"20%" }}> {formdatao.l_little_finger != '' && formdatao.l_little_finger != null ? <img src={`data:image/png;base64,${formdatao.l_little_finger}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      
      </div>
    </div>
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>
    </div>
  )
}

export default Editremittance