import React, { useEffect, useRef, useState } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import img3 from "../../assets/KYC Logo white-bg 2.jpg";
import img4 from "../../assets/Frame 177.png";
import img7 from "../../assets/Rectangle 299.png";
import img54 from "../../assets/Arrow 2.png";
import img15 from "../../assets/Ellipse 66.png";
import img16 from "../../assets/Ellipse 67.png";
import img17 from "../../assets/Ellipse 68.png";
import img18 from "../../assets/Ellipse 69.png";
import img19 from "../../assets/Ellipse 70.png";
import { FaShoppingCart } from "react-icons/fa";
import { ImLocation2 } from "react-icons/im";
import {
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import { NavLink, useNavigate } from "react-router-dom";

const About = () => {
  useEffect(() => {
    const handleBeforeUnload = () => {
      // Save the scroll position to localStorage before the page is unloaded
      localStorage.setItem("scrollPosition", JSON.stringify(window.scrollY));
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // Clean up by removing the event listener when the component unmounts
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    // When the component mounts, check if a scroll position was saved in localStorage
    const scrollPosition = JSON.parse(localStorage.getItem("scrollPosition"));
    if (scrollPosition) {
      // Scroll to the position saved in localStorage
      window.scrollTo(0, scrollPosition);
      // Clear the saved scroll position from localStorage after using it
      localStorage.removeItem("scrollPosition");
    }
  }, []);

  const [topto, settopto] = useState(0);
  const [bottomto, setbottomto] = useState(0);
  const [IsFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const divElement = document.getElementById("myDiv"); // आपके डिव का आईडी यहां उपयोग करें
    const end = document.getElementById("endsidebar"); // आपके डिव का आईडी यहां उपयोग करें
    if (divElement) {
      const rect = divElement.getBoundingClientRect();
      const distanceFromTop = rect.top;
      settopto(distanceFromTop);
    }
    if (end) {
      const rect2 = end.getBoundingClientRect();
      const distanceFromTop2 = rect2.top;
      setbottomto(distanceFromTop2);
    }
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;

    if (scrollPosition > topto && scrollPosition < bottomto) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  window.addEventListener("scroll", handleScroll);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);
  const section5 = useRef(null);
  const section6 = useRef(null);
  const section7 = useRef(null);
  const section8 = useRef(null);
  const redirecthead = (num) => {
    section1.current?.scrollIntoView({ behavior: "smooth" });
  };

  const redirecthead2 = (num) => {
    section2.current?.scrollIntoView({ behavior: "smooth" });
  };
  const redirecthead3 = (num) => {
    section3.current?.scrollIntoView({ behavior: "smooth" });
  };
  const redirecthead4 = (num) => {
    section4.current?.scrollIntoView({ behavior: "smooth" });
  };
  const redirecthead5 = (num) => {
    section5.current?.scrollIntoView({ behavior: "smooth" });
  };
  const redirecthead6 = (num) => {
    section6.current?.scrollIntoView({ behavior: "smooth" });
  };
  const redirecthead7 = (num) => {
    section7.current?.scrollIntoView({ behavior: "smooth" });
  };
  const redirecthead8 = (num) => {
    section8.current?.scrollIntoView({ behavior: "smooth" });
  };

  const nvg = useNavigate();
  const navigatefeature = (num) => {
    nvg("/", { state: { id: num } });
  };

  return (
    <div style={{ margin: "0px", padding: "0px" }}>
      <section>
        <nav
          className="navbar navbar-expand-lg navbar-light bg-body p-0"
          aria-label="Eighth navbar example"
        >
          <div className="container-fluid">
            <div className="img-fluid pb-1 imgdivsize">
              <NavLink to="/">
                <img src={img3} className="imgsize" alt="logo" />
              </NavLink>
            </div>
            <button
              className="navbar-toggler collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarsExample07"
              aria-controls="navbarsExample07"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarsExample07"
              style={{ width: "61%" }}
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <p
                    className="nav-link text-black"
                    style={{ cursor: "pointer", marginBottom: "0px" }}
                    onClick={() => {
                      navigatefeature(0);
                    }}
                    role="button"
                  >
                    Features
                  </p>
                </li>
                <li className="nav-item">
                  <p
                    className="nav-link text-black"
                    style={{ cursor: "pointer", marginBottom: "0px" }}
                    onClick={() => {
                      navigatefeature(1);
                    }}
                  >
                    Industries
                  </p>
                </li>
                {/* <li className="nav-item">
                  <p
                    className="nav-link text-black"
                    style={{ cursor: "pointer", marginBottom: "0px" }}
                    onClick={() => {
                      navigatefeature(2);
                    }}
                  >
                    Register
                  </p>
                </li> */}
                <li className="nav-item dropdown">
                  <a className="nav-link text-black" href="/about">
                    About{" "}
                  </a>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link text-black" to="/contact">
                    Contact Us
                  </NavLink>
                </li>
              </ul>

              <div className=" p-1 d-flex" style={{gap:'4px'}}>
              <li className="nav-item">
            <p className="bg-white text-primary btn btn-outline-primary" style={{cursor:'pointer',marginBlock:'0px'}} onClick={()=>{navigatefeature(2)}}>Register</p>
          </li>
                <NavLink to="/login" className="btn btn-outline-primary">
                  Log in
                </NavLink>
                {/* <button type="button" className="btn">
                  <NavLink to="/cart">
                    <FaShoppingCart
                      style={{ fontSize: "24px", color: "red" }}
                    />
                  </NavLink>
                </button> */}
              </div>
            </div>
          </div>
        </nav>
      </section>

      <section class="banner-about" style={{ backgroundColor: "white" }}>
        <div class="react-overlay121">
          <div class="container-fuild">
            <div class="row m-0 d-flex justify-content-center">
              <p
                className="text-white text-center spaceofx m-0"
                style={{ letterSpacing: "3px" }}
              >
                ABOUT US
              </p>
              <h4 class=" mt-3 bann-txt page-title ">
                Transform Your Customer Onboarding Process and Strengthen Your
                Compliance Efforts
              </h4>
              {/* <div className='d-flex justify-content-center'>
                        
                        <button type="button" className="btn btn-outline-primary" style={{background:'transparent',border:'1.4px solid white',color:'white',fontWeight:'500'}}>
                    Get Started
          </button>
                        </div> */}
            </div>
          </div>
        </div>
      </section>

      <section style={{ paddingTop: "5px", backgroundColor: "white" }}>
        <div className="container-fuild  px-4 py-3 changedir">
          <div
            className="row align-items-center g-5 "
            style={{ justifyContent: "center" }}
          >
            <div className="col-lg-6">
              <h1 className="title2 mb-3 ">
                Streamline and Enhance the Know Your Customer Process
              </h1>
              <p className="desc2">
                Welcome to KYC First, a leading KYC solution provider that
                specializes in cutting-edge technology to streamline and enhance
                the Know Your Customer process. In today's rapidly evolving
                regulatory landscape, ensuring compliance and trust in financial
                transactions is of utmost importance. Our innovative KYC
                solutions enable businesses across various industries to verify
                the identities of their customers efficiently, securely, and in
                a user-friendly manner.
              </p>
            </div>
            <div
              className="col-10 col-sm-12 col-lg-6"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img
                src={img4}
                className="d-block mx-lg-auto img-fluid"
                alt="group7"
                width={500}
                height={500}
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="royal-sec" style={{backgroundColor:'white'}}>
        <div className="container-fluid position-relative" style={{overflow:'hidden'}}>
          <div className="row backgroundbox123"></div>
          <div className="row">
            <div className="col-12 mt-4" style={{ zIndex: "4" }}>
              <div className="row">
                <div className="col-6 d-flex flex-column justify-content-center align-items-center" style={{paddingBottom:'23px'}}>
                 <img src={img7} alt="304" style={{width:'87%'}} />
                </div>
                <div className="col-md-6 col-sm-6 mt-4" style={{paddingTop:'194px'}}>
                  <div className="article">
                    <div className="over-royal" />
                    <p className="image865">
                      <img src={img54} alt="304"  />
                    </p>
                    <p
                    className="title2"
                    style={{ color:'white' }}
                  >
                   Our Mission
                  </p>
                  <p className="desc1202" style={{color:'white'}}>
                  At KYC First, our mission is to empower businesses with comprehensive and reliable KYC solutions that facilitate seamless customer onboarding and compliance adherence. We are dedicated to safeguarding businesses against potential risks, fraudulent activities, and money laundering while promoting a secure and trustworthy environment.
                  </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="col-12 py-3 pb-3 mt-4 bg-white">
          <p className="headline text-center ">Why Choose KYC First?</p>
        </div>
        <div className="container-fluid bg-white px-4 extrapadding" >
          <div className="row">
            <div className="col-lg-4 col-md-6 mt-3">
              <div className="imgbox">
                <img src={img15} alt="404" />
              </div>
              <div className="headline234">
                <p className="m-0">Expertise and Experience</p>
              </div>
              <div className="desc493">
                <p
                  className="m-0 text-center"
                  style={{
                    color: "rgba(0, 0, 0, 1)",
                    textAlign: "justify",
                    fontSize: "15px",
                  }}
                >
                  With years of experience in the KYC domain, our team comprises
                  industry experts and technology enthusiasts who understand the
                  challenges faced by businesses in complying with regulations
                  while maintaining smooth operations.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 mt-3">
              <div className="imgbox">
                <img src={img16} alt="404" />
              </div>
              <div className="headline234">
                <p className="m-0">Innovation and Technology</p>
              </div>
              <div className="desc493">
                <p
                  className="m-0 text-center"
                  style={{
                    color: "rgba(0, 0, 0, 1)",
                    textAlign: "justify",
                    fontSize: "15px",
                  }}
                >
                  We pride ourselves on staying at the forefront of
                  technological advancements. Our solutions leverage AI, machine
                  learning, and biometric authentication to deliver unparalleled
                  accuracy and efficiency
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 mt-3">
              <div className="imgbox">
                <img src={img17} alt="404" />
              </div>
              <div className="headline234">
                <p className="m-0">Customer-Centric Approach</p>
              </div>
              <div className="desc493">
                <p
                  className="m-0 text-center"
                  style={{
                    color: "rgba(0, 0, 0, 1)",
                    textAlign: "justify",
                    fontSize: "15px",
                  }}
                >
                  At KYC First, customer satisfaction is our top priority. We
                  provide dedicated customer support and tailor our solutions to
                  meet the unique requirements of each client.
                </p>
              </div>
            </div>

            <div className=" offset-lg-2 col-lg-4 col-md-6 mt-3">
              <div className="imgbox">
                <img src={img18} alt="404" />
              </div>
              <div className="headline234">
                <p className="m-0">Cost-Effective Solutions</p>
              </div>
              <div className="desc493">
                <p
                  className="m-0 text-center"
                  style={{
                    color: "rgba(0, 0, 0, 1)",
                    textAlign: "justify",
                    fontSize: "15px",
                  }}
                >
                  Our KYC services are competitively priced, offering a high
                  return on investment by saving both time and resources
                  associated with manual verification processes.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-3">
              <div className="imgbox">
                <img src={img19} alt="404" />
              </div>
              <div className="headline234">
                <p className="m-0">Scalability and Growth</p>
              </div>
              <div className="desc493">
                <p
                  className="m-0 text-center"
                  style={{
                    color: "rgba(0, 0, 0, 1)",
                    textAlign: "justify",
                    fontSize: "15px",
                  }}
                >
                  Whether you are a startup or an established enterprise, our
                  solutions are designed to scale alongside your business,
                  adapting to changing needs and expanding customer bases.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className="footer" style={{ marginTop: "5rem" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-12">
              <div className="footer-img pb-1">
                <img
                  src={img3}
                  style={{ mixBlendMode: "darken" }}
                  className="footwidth"
                  alt="logo"
                />
              </div>
            </div>
            {/* <div className="col-lg-2 col-md-6 col-12 ">
              <p className="customunder">Home</p>
              <p className="desc21 ">Industries</p>
            </div> */}
            <div className="col-lg-2 col-md-6 col-12 " style={{display:'flex',justifyContent:'center'}}>
              {/* <p className="customunder">Pricing</p> */}
              <p className="desc21 ">
                <NavLink style={{ color: "black" }} to="/contact">
                  Contact Us
                </NavLink>
              </p>
            </div>
            <div className="col-lg-3 col-md-6 col-12 ">
              <p className="desc21">
              <a style={{ color: "black" }} href="/about">About</a></p>
              <p className="desc21 ">
                <a style={{ color: "black" }} href="/privacypolicy">
                  {" "}
                  Privacy & Policy
                </a>
              </p>
              <p className="desc21">
                <a style={{ color: "black" }} href="/termandcondition">
                  Terms & Condition
                </a>
              </p>
              <p className="desc21">
                <a style={{ color: "black" }} href="/refund">
                Refund and Cancellation
                </a>
              </p>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-footer">
                {/* <p className="customunder">Contact Us</p> */}
                <div className="contact_i">
                  <div className="row mt-2">
                    <div
                      className="col-1"
                      style={{ color: "rgba(12, 83, 152, 1)" }}
                    >
                      <ImLocation2 />
                    </div>
                    <div className="col-10  desc21">
                      907 Clover Hill Plaza NIBM Road Kondhwa Pune 411048 MS
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div
                      className="col-1"
                      style={{ color: "rgba(12, 83, 152, 1)" }}
                    >
                      <FaEnvelope />
                    </div>
                    <div className="col-10 desc21">
                      Email:
                      <a
                        className=" text-decoration-none"
                        href="mailto:legal@kycfirst.io"
                      >
                        legal@kycfirst.io
                      </a>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div
                      className="col-1"
                      style={{ color: "rgba(12, 83, 152, 1)" }}
                    >
                      <BsFillTelephoneFill />
                    </div>
                    <div className="col-10 desc21">
                      {" "}
                      Phone:
                      <a
                        className=" text-decoration-none"
                        href="tel:8130515661"
                      >
                        +91 9730074506
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row ">
            <div className="col-12" style={{display:'none'}}>
              <ul className="social-foo">
                <li style={{display:'flex',justifyContent:'center',alignItems:'center',width:'50px'}}>
                  <a href="#">
                    <FaFacebookF />
                  </a>
                </li>
                <li style={{display:'flex',justifyContent:'center',alignItems:'center',width:'50px'}}>
                  <a href="#">
                    <FaInstagram />
                  </a>
                </li>
                <li style={{display:'flex',justifyContent:'center',alignItems:'center',width:'50px'}}>
                  <a href="#">
                    <FaTwitter />
                  </a>
                </li>
                <li style={{display:'flex',justifyContent:'center',alignItems:'center',width:'50px'}}>
                  <a href="#">
                    <FaLinkedinIn />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-12 mt-1 mb-1">
              <p className="text-center desc2">© 2023 - All Right Reserved</p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default About;
