import React from "react";
// import Header from "../../components/Header";
import img1 from "../../assets/Ellipse 27.png";
import img2 from "../../assets/Ellipse 28.png";
import img3 from "../../assets/Ellipse 29.png";
import img4 from "../../assets/Western union.png";
import img5 from "../../assets/money.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  BarChart,
  Bar,
  LabelList,
} from "recharts";
import Breadcupdash from "../../components/Breadcupdash";
import { gettoken } from "../../Localstorage/Store";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import Loadercomp from "../../components/Loadercomp";


// const options = {
//   items: 3,
//   loop: true,
//   autoplay: true,
//   nav: false,
//   // responsiveClass: true,
//   dots: false,
//   // margin: 16,
//   responsive: {
//     1200: {
//       items: 3,
//     },
//     920: {
//       items: 3,
//     },
//     700: {
//       items: 2,
//       // stagePadding: 50,
//     },
//     600: {
//       items: 2,
//       // stagePadding: 20,
//     },
//     504: {
//       items: 2,
//       // stagePadding: 0,
//     },
//     300: {
//       items: 1,
//     },
//     310: {
//       items: 1,
//     },
//   },
//   center: true, // Enable center mode
// };
const Dashboard = () => {






  const [srtloader, setsrtloader] = useState(true);
  const [cusfromdate, setcusfromdate] = useState(null);
  const [remifromdate, setremifromdate] = useState(null);
  const [custodate, setcustodate] = useState(null);
  const [remitodate, setremitodate] = useState(null);

  const [Data,setData] = useState({})
  const [Data2,setData2] = useState({})
  const [Data3,setData3] = useState({})
  const [Data4,setData4] = useState({})
  const [Data5,setData5] = useState({})
const gettokinval = gettoken();
// console.log("dkdkdkdkd",Data)

    useEffect(() => {
        async function fetchData() {
            // setsrtloader(true)
            const config = {
                headers: {
              Authorization: `Bearer ${gettokinval.access}`,
            },
        };
        // https://backend.kycfirst.io/api/subscriptions/
        // let url = `${process.env.REACT_APP_API_URL}api/subscriptions/`;
        //   const response = await axios.get(url, config);
        //   setData(response.data[0]);


          let url2 = `${process.env.REACT_APP_API_URL}api/reporting/remi_stage_counts`;
          const response2 = await axios.get(url2, config);
       setData2(response2.data)

       
      //  let url4 = `${process.env.REACT_APP_API_URL}api/reporting/stage_counts/`;
       let url4 = `${process.env.REACT_APP_API_URL}api/Reporting_cus/stage_counts/`;  
       const response4 = await axios.get(url4, config);
   //  console.log("reports",response)
    setData4(response4.data)


    let url3 = `${process.env.REACT_APP_API_URL}api/reporting/top_remittances/`;
    const response3 = await axios.get(url3, config);
//  console.log("reports 234",response)
 setData3(response3.data)



 let url5 = `${process.env.REACT_APP_API_URL}api/reporting/top_cus/`;
 const response5 = await axios.get(url5, config);
setData5(response5.data)
 setsrtloader(false)
        }

    //     async function recordnumber() {
    //       const config = {
    //           headers: {
    //         Authorization: `Bearer ${gettokinval.access}`,
    //       },
    //   };
    //   // https://backend.kycfirst.io/api/subscriptions/
    //   let url = `${process.env.REACT_APP_API_URL}api/reporting/remi_stage_counts`;
    //     const response = await axios.get(url, config);
    // //  console.log("reports",response)
    //  setData2(response.data)
    //     // setsrtloader(false)



    //   }
    //     async function recordnumbertwo() {
    //       const config = {
    //           headers: {
    //         Authorization: `Bearer ${gettokinval.access}`,
    //       },
    //   };
    //   // https://backend.kycfirst.io/api/subscriptions/
    //   let url = `${process.env.REACT_APP_API_URL}api/Reporting_cus/stage_counts/`;
    //     const response = await axios.get(url, config);
    // //  console.log("reports",response)
    //  setData4(response.data)
    //     // setsrtloader(false)
    //   }
    //     async function top10remi() {
    //       const config = {
    //           headers: {
    //         Authorization: `Bearer ${gettokinval.access}`,
    //       },
    //   };
    //   // https://backend.kycfirst.io/api/subscriptions/
    //   let url = `${process.env.REACT_APP_API_URL}api/reporting/top_remittances/`;
    //     const response = await axios.get(url, config);
    // //  console.log("reports 234",response)
    //  setData3(response.data)
    //     // setsrtloader(false)
    //   }
    //     async function top10customer() {
    //       const config = {
    //           headers: {
    //         Authorization: `Bearer ${gettokinval.access}`,
    //       },
    //   };
    //   // https://backend.kycfirst.io/api/subscriptions/
    //   let url = `${process.env.REACT_APP_API_URL}api/reporting/top_cus/`;
    //     const response = await axios.get(url, config);
    // //  console.log("reports 234",response)
    //  setData5(response.data)
    //     setsrtloader(false)
    //   }
      fetchData();
      // recordnumber();
      // recordnumbertwo();
      // top10remi();
      // top10customer()
    }, []);











  const data = [
    {
      rank: 1,
      name: "ajay",
      number: 5,
    },
    {
      rank: 2,
      name: "fggfds",
      number: 24,
    },
    {
      rank: 3,
      name: "sdfg",
      number: 20,
    },
    {
      rank: 4,
      name: "kjhgfd",
      number: 5,
    },
    {
      rank: 5,
      name: "dfgh",
      number: 30,
    },
    {
      rank: 6,
      name: "sdfgh",
      number: 10,
    },
    {
      rank: 7,
      name: "asdfgh",
      number: 20,
    },
    {
      rank: 8,
      name: "sdf",
      number: 5,
    },
    {
      rank: 9,
      name: "sdfghjk",
      number: 14,
    },
    {
      rank: 10,
      name: "ajsdfghay",
      number: 1,
    },
    {
      rank: 11,
      name: "ajay",
      number: 7,
    },
    {
      rank: 12,
      name: "fggfds",
      number: 10,
    },
    {
      rank: 13,
      name: "sdfg",
      number: 8,
    },
    {
      rank: 14,
      name: "kjhgfd",
      number: 4,
    },
    {
      rank: 15,
      name: "dfgh",
      number: 4,
    },
    {
      rank: 16,
      name: "sdfgh",
      number: 17,
    },
    {
      rank: 17,
      name: "asdfgh",
      number: 12,
    },
    {
      rank: 18,
      name: "sdf",
      number: 23,
    },
    {
      rank: 19,
      name: "sdfghjk",
      number: 14,
    },
    {
      rank: 20,
      name: "ajsdfghay",
      number: 1,
    },
  ];

  const pdata = [
    {
      rank: 5000,
      name: "Mon",
      number: 11000,
    },
    {
      rank: 7000,
      name: "Tue",
      number: 9000,
    },
    {
      rank: 8000,
      name: "Wed",
      number: 5000,
    },
    {
      rank: 4000,
      name: "Thu",
      number: 5700,
    },
    {
      rank: 10000,
      name: "Fri",
      number: 4000,
    },
    {
      rank: 11000,
      name: "Sat",
      number: 3000,
    },
    {
      rank: 2000,
      name: "Sun",
      number: 7000,
    },
  ];
  return (
    <div style={{ width: "100%", minHeight: "100vh" }}>
      {/* <Header /> */}
      <div className="dashboardcontent">
        <Breadcupdash name={"Dashboard"} />
  


  {/* live production code start here  */}
        {srtloader == true ? <div className="container-fuild bg-white"> <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"100%"}}>
        <div className='px-2'> <Loadercomp size={100} /></div>
        </div></div> : <div className="container-fuild py-4" style={{paddingLeft:"0px",paddingRight:"0px"}}>
          <div className="row" style={{padding:'0px 14px'}}>
            {/* {gettokinval.staff == false ? gettokinval.superuser == true ? <div className="col-lg-6 mb-2 col-md-6 col-12 dbox">
              <div className="row bg-white py-3 d-flex align-items-center rounded py-2">
                <div className="col-3">
                <div className="single-pricing2">
                  <div className="head-text">
                    <h3 style={{textAlign:'center'}}>{Data.plan_name} </h3>
                  </div>
                  <div className="price-area229 text-center">
                  ₹{Data.total_amount} 
                  </div>
                  <div className="btn-area">

               
                  </div>
                </div>
                </div>
                  <div className="col-lg-9 col-12 rounded my-lg-0 my-2 ">
              <div className="row">
                <div className="col-12 dtext py-2" style={{fontSize:'24px'}}>Subscription Plan Info</div>
                <div className="col-12 px-2 ">
                   <h5>Plan Name : <span>{Data ? Data.plan_name : ''}</span></h5>
                   <h5>Expired in : <span>{Data ? Data.remaining_days : ''}</span></h5>
                </div>
              </div>
          
        
            
 
            </div>
              </div>
            </div> : '' : ''}
            {gettokinval.staff == false ?  gettokinval.superuser == true ? <div className="col-lg-6 mb-2 col-md-6 col-12 ">
              <div className="row py-3 d-flex align-items-center rounded py-2">
          
                <div className="col-12">
         
                </div>
              </div>
            </div> : '' : ''} */}

  {/* live production code end here  */}

           
            <div className="col-lg-4 mb-2 col-md-6 col-12 dbox">
              <div className="row bg-white py-3 d-flex align-items-center rounded py-2">
                <div className="col-3">
                  <img src={img1} alt="" />
                </div>
                <div className="col-9">
                  <p className="dbold">{Data4.Created + Data4.Checked + Data4.Approved}</p>
                  <p className="pbold">Total customer</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-2 col-md-6 col-12 dbox">
              <div className="row bg-white py-3 d-flex align-items-center rounded py-2">
                <div className="col-3">
                  <img src={img2} alt="" />
                </div>
                <div className="col-9">
                  <p className="dbold">{Data4.Created}</p>
                  <p className="pbold">Customer for Checking</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4  mb-2 col-md-6 col-12 dbox">
              <div className="row bg-white py-3 d-flex align-items-center rounded py-2">
                <div className="col-3">
                  <img src={img3} alt="" />
                </div>
                <div className="col-9">
                  <p className="dbold">{Data4.Checked}</p>
                  <p className="pbold">Customer for Approval</p>
                </div>
              </div>
            </div>


            {/* for customer start here  */}

            <div className="col-lg-4 mb-2 col-md-6 col-12 dbox">
              <div className="row bg-white py-3 d-flex align-items-center rounded py-2">
                <div className="col-3">
                  <img src={img5} alt="" />
                </div>
                <div className="col-9">
                  <p className="dbold">{Data2.Created + Data2.Checked + Data2.Approved}</p>
                  <p className="pbold">Total Remittance</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-2 col-md-6 col-12 dbox">
              <div className="row bg-white py-3 d-flex align-items-center rounded py-2">
                <div className="col-3">
                  <img src={img2} alt="" />
                </div>
                <div className="col-9">
                  <p className="dbold">{Data2.Created}</p>
                  <p className="pbold">Remittance for Checking</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4  mb-2 col-md-6 col-12 dbox">
              <div className="row bg-white py-3 d-flex align-items-center rounded py-2">
                <div className="col-3">
                  <img src={img3} alt="" />
                </div>
                <div className="col-9">
                  <p className="dbold">{Data2.Checked}</p>
                  <p className="pbold">Remittance for Approval</p>
                </div>
              </div>
            </div>
            {/* for customer end here  */}
          </div>


          {/* <section>
        <div className="col-12 py-3 pb-3 mt-4">
          <p className="headline text-center " style={{padding:'0px',margin:'0px'}} >
            Subscription plans
          </p>
        </div>
        <div className="container-fluid py-2">
          <div className="row">
            <OwlCarousel className="owl-theme" id="hideclass" {...options}>
              <div className="owlwidth">
                <div className="single-pricing">
                  <div className="head-text">
                    <h3>Bronze</h3>
                  </div>
                  <div className="price-area">
                    $ 20 <span className="duration">Monthly</span>
                  </div>
              
                  <div className="btn-area">
                    <a href="/Cart">Get Started</a>
                  </div>
                </div>
              </div>

              <div className="owlwidth">
                <div className="single-pricing">
                  <div className="pop-label">Popular</div>
                  <div className="head-text">
                    <h3>Silver </h3>
                  </div>
                  <div className="price-area">
                    $ 40 <span className="duration">Quarterly</span>
                  </div>
                  <div className="btn-area">
                    <a href="/Cart">Get Started</a>
                  </div>
                </div>
              </div>

              <div className="owlwidth">
                <div className="single-pricing">
                  <div className="head-text">
                    <h3>GOLD</h3>
                  </div>
                  <div className="price-area">
                    $ 50 <span className="duration">Half-yearly</span>
                  </div>
                  <div className="btn-area">
                    <a href="/Cart">Get Started</a>
                  </div>
                </div>
              </div>

              <div className="owlwidth">
                <div className="single-pricing">
                  <div className="head-text">
                    <h3>PLATINUM</h3>
                  </div>
                  <div className="price-area">
                    $ 100 <span className="duration">Yearly</span>
                  </div>
                  <div className="btn-area">
                    <a href="/Cart">Get Started</a>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </section> */}
          <div className="row mt-3 mx-1">
            <div className="col-lg-6 col-12  my-lg-0 my-2 ">
              <div className="bg-white specailshow px-3 rounded">
              {/* <div className="bg-white specailshow dbox rounded"> */}
              <div className="row">
                <div className="col-4 dtext py-2 d-flex align-items-center">Remittance</div>
                <div className="col-8 d-flex py-2 justify-content-end" style={{ gap: "7px" }}>
                    <div style={{position:"relative"}}>
                      <label htmlFor="tffyy" style={{display:remifromdate == null ? "block" : "none",position:"absolute",top:"7px",left:"9px",paddingRight:"20px",background:"#ebebeb"}}>From Date</label>
                 <input type="date" style={{background:'#EEEEEE',width:'145px'}} className="form-control" value={remifromdate} onChange={(e)=>{setremifromdate(e.target.value)}} id="fromcus" placeholder="from date" />
                    </div>
                    <div style={{position:"relative"}}>
                      <label htmlFor="fftocusrr" style={{display:remitodate == null ? "block" : "none",position:"absolute",top:"7px",left:"9px",paddingRight:"40px",background:"#ebebeb"}} >To Date</label>
                 <input type="date" style={{background:'#EEEEEE',width:'145px'}}  value={remitodate} onChange={(e)=>{setremitodate(e.target.value)}} className="form-control" id="tocus" />
                    </div>
                  </div>
                <div className="col-12 px-2 ">
                  {" "}
                  <div className="underline"></div>
                </div>
              </div>
              <div className="headtb special">
                <div className="sno" style={{widows:'30%'}}>Serial No.</div>
                <div className="amount">Customer</div>
                <div className="companylogo" style={{width:'30%',display:'flex',justifyContent:'center'}}>Product</div>
              </div>

              {Data3 ? Data3.map((item,index) => (
              <div className="headtb">
                <div className="sno px-3" style={{width:'30%'}}>{index + 1}</div>
                <div className="amount" style={{width:'40%'}}>{item.customer}</div>
                <div className="companylogo d-flex justify-content-center" style={{width:'30%'}}>
                  <div className="centerimg ">
                  <img src={`data:image/webp;base64,${item.product_logo}`} alt="304" style={{width:'45px'}} />
                  </div>
                </div>
                
              </div>
              )) : ''}
             </div>
            </div>


            {/* latest code start here  */}


            <div className="col-lg-6 col-12 my-lg-0 my-2">
              <div className="px-3 rounded bg-white specailshow">
              <div className="row">
                <div className="col-4 dtext py-2 d-flex align-items-center">Customer</div>
                   <div className="col-8 d-flex py-2 justify-content-end" style={{ gap: "7px" }}>
                    <div style={{position:"relative"}}>
                      <label htmlFor="tyy" style={{display:cusfromdate == null ? "block" : "none",position:"absolute",top:"7px",left:"9px",paddingRight:"20px",background:"#ebebeb"}}>From Date</label>
                 <input type="date" style={{background:'#EEEEEE',width:'145px'}} className="form-control" value={cusfromdate} onChange={(e)=>{setcusfromdate(e.target.value)}} id="fromcus" placeholder="from date" />
                    </div>
                    <div style={{position:"relative"}}>
                      <label htmlFor="tocusrr" style={{display:custodate == null ? "block" : "none",position:"absolute",top:"7px",left:"9px",paddingRight:"40px",background:"#ebebeb"}} >To Date</label>
                 <input type="date" style={{background:'#EEEEEE',width:'145px'}}  value={custodate} onChange={(e)=>{setcustodate(e.target.value)}} className="form-control" id="tocus" />
                    </div>
                  </div>
                <div className="col-12 px-2 ">
                  {" "}
                  <div className="underline"></div>
                </div>
              </div>
              <div className="headtb special">
                <div className="sno" style={{widows:'30%'}}>Serial No.</div>
                <div className="amount">Customer</div>
                <div className="companylogo" style={{width:'30%',display:'flex',justifyContent:'center'}}>Mobile No</div>
              </div>

              {Data5 ? Data5.map((item,index) => (
              <div className="headtb">
                <div className="sno px-3" style={{width:'30%'}}>{index + 1}</div>
                <div className="amount" style={{width:'40%'}}>{item.name}</div>
                <div className="amount" style={{width:'35%'}}>{item.mobile_no}</div>
        
                
              </div>
              )) : ''}
             </div>
            </div>

            {/* latest code end here  */}

            {/* <div className="col-lg-7 col-12 dbox">
              <div className="row rounded bg-white ">
                <div className="col-12 d-flex justify-content-between">
                  <div className="dtext py-2">Customer </div>
                  <div className="lastlo d-flex py-2" style={{ gap: "7px" }}>
                    <select
                      name=""
                      id=""
                      className="form-select custselect"
                      aria-label="Default select example"
                    >
                      <option value="qwerty">March</option>
                      <option value="ytrewq">ytrewq</option>
                      <option value="zxcvb">zxcvb</option>
                      <option value="bvcxz">bvcxz</option>
                    </select>
                    <select
                      name=""
                      id=""
                      className="form-select custselect"
                      aria-label="Default select example"
                    >
                      <option value="qwerty">2023</option>
                      <option value="ytrewq">ytrewq</option>
                      <option value="zxcvb">zxcvb</option>
                      <option value="bvcxz">bvcxz</option>
                    </select>
                  </div>
                </div>
                <div className="col-12 px-2 ">
                  {" "}
                  <div className="underline"></div>
                </div>
                <div className="col-12 py-3 ">
                  <ResponsiveContainer width="100%" aspect={2}>
                    <LineChart
                      data={data}
                      margin={{ top: 2, right: 39, bottom: 0, left: 0 }}
                    >
                      <CartesianGrid />
                      <XAxis dataKey="rank" intercept={"preserveStartEnd"} />
                      <YAxis />
                      <Tooltip
                        contentStyle={{
                          background:
                            "linear-gradient(to right, rgba(12, 83, 152, 1), rgba(18, 113, 205, 1))",
                          border: "1px solid red",
                          outline: "none",
                        }}
                      />
                      <Line dataKey="number" stroke="red" />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <div className="row rounded bg-white mt-3">
                <div className="col-12 d-flex justify-content-between">
                  <div className="dtext py-2">Customer</div>
                  <div className="lastlo d-flex py-2" style={{ gap: "7px" }}>
                    <select
                      name=""
                      id=""
                      className="form-select custselect"
                      aria-label="Default select example"
                    >
                      <option value="qwerty">March</option>
                      <option value="ytrewq">ytrewq</option>
                      <option value="zxcvb">zxcvb</option>
                      <option value="bvcxz">bvcxz</option>
                    </select>
                    <select
                      name=""
                      id=""
                      className="form-select custselect"
                      aria-label="Default select example"
                    >
                      <option value="qwerty">2023</option>
                      <option value="ytrewq">ytrewq</option>
                      <option value="zxcvb">zxcvb</option>
                      <option value="bvcxz">bvcxz</option>
                    </select>
                  </div>
                </div>
                <div className="col-12 px-2 ">
                  {" "}
                  <div className="underline"></div>
                </div>
                <div className="col-12 py-3 ">
                  <ResponsiveContainer width="100%" aspect={2}>
                    <BarChart
                      width="100%"
                      aspect={2}
                      data={pdata}
                      margin={{ top: 15, right: 10, left: 20, bottom: 5 }}
                    >
                      <XAxis dataKey="name"></XAxis>
                      <Tooltip
                        contentStyle={{
                          background:
                            "linear-gradient(to right, rgba(12, 83, 152, 1), rgba(18, 113, 205, 1))",
                          border: "1px solid red",
                          outline: "none",
                        }}
                      />
                      <Bar dataKey="rank" fill="#EB011C">
                        <LabelList
                          dataKey="rank"
                          fill="white"
                          angle="90"
                          fontSize={10}
                        />
                      </Bar>
                      <Tooltip
                        contentStyle={{
                          background:
                            "linear-gradient(to right, rgba(12, 83, 152, 1), rgba(18, 113, 205, 1))",
                          border: "1px solid red",
                          outline: "none",
                        }}
                      />
                      <Bar dataKey="number" fill="#0C5398">
                        <LabelList
                          dataKey="number"
                          fill="white"
                          angle="90"
                          fontSize={10}
                        />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div> */}
          </div>
        </div>}
      </div>
    </div>
  );
};

export default Dashboard;
