// import React from 'react'
// import Breadcup from '../../components/Breadcup'
// import Header from '../../components/Header'
// import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import img1 from '../../assets/Group 40.png';
// import img2 from '../../assets/Group 41.png';
// import img3 from '../../assets/asdfg.png';
// // import img4 from '../../assets/aadhar.png';
// import img4 from "../../assets/abc.png";
// import img5 from "../../assets/cba.png";
// import { NavLink, useParams } from 'react-router-dom';
// import { useEffect } from 'react';
// import { useState } from 'react';
// import { gettoken } from '../../Localstorage/Store';
// import axios from 'axios';
// import Loadercomp from '../../components/Loadercomp';
// import ReactImageMagnify from 'react-image-magnify';
// import { getName } from 'country-list';


// const Aagentdetail = () => {
//   const [agentusername,setagentusername] = useState()
//   const [agentname,setagentname] = useState()
//   const [agentlast,setagentlast] = useState()
//   const [agentemail,setagentemail] = useState()
//   const [agentgender,setagentgender] = useState()
//   const [ffn,setffn] = useState()
//   const [agentmobile,setagentmobile] = useState()
//   const [agentchecker,setagentchecker] = useState(false)
//   const [agentmaker,setagentmaker] = useState(false)
//   const [agentapprover,setagentapprover] = useState(false)
//   const [agentcountry,setagentcountry] = useState()
//   const [agentstate,setagentstate] = useState()
//   const [agentcity,setagentcity] = useState()
//   const [agentaddress,setagentaddress] = useState()
//   const [agentpincode,setagentpincode] = useState()
//   const [agentsuperadmin,setagentsuperadmin] = useState()
//   const[defaultv,setdefaultv] = useState('')
//   const[defaultvtwo,setdefaultvtwo] = useState('')
//   const [created_by, setcreated_by] = useState();
//   const [created_at, setcreated_at] = useState();
//   const [agentage, setagentage] = useState();
//   const [agentdob, setagentdob] = useState();
//   const [last_updated_by, setlast_updated_by] = useState();
//   const [last_updated_at, setlast_updated_at] = useState();
//   const [srtloader, setsrtloader] = useState(true);
//   const gettokinval = gettoken();
//   const [photograph, setphotograph] = useState("");
//   const [kycphoto, setkycphoto] = useState('');
//     const [kycfullname, setkycfullname] = useState('');
//     const [kycage, setkycage] = useState('');
//     const [kycgander, setkycgander] = useState('');
//     const [kycdob, setkycdob] = useState('');
//     const [kycaddress, setkycaddress] = useState('');
//     const [kyccountry, setkyccountry] = useState('');
//     const [kycstate, setkycstate] = useState('');
//     const [kyccity, setkyccity] = useState('');
//     const [kycpincode, setkycpincode] = useState('');
//     const [kycemail, setkycemail] = useState('');
//     const [kycphone, setkycphone] = useState('');
//     const [kycfathername, setkycfathername] = useState('');
//     const [documenttype, setdocumenttype] = useState('PAN');
//     const [idnumber, setidnumber] = useState('DTTPA8683J');
//     const [documentimage, setdocumentimage] = useState('ASD');
//     const [documentissue, setdocumentissue] = useState('SD');
//     const [documentexpiry, setdocumentexpiry] = useState('ASD');
//     const [thumbimg2, setthumbimg2] = useState('');
//     const [rifimg2, setrifimg2] = useState('');
//     const [rmfimg2, setrmfimg2] = useState('');
//     const [rrfimg2, setrrfimg2] = useState('');
//     const [rlfimg2, setrlfimg2] = useState('');
//     const [ltfimg2, setltfimg2] = useState('');
//     const [lifimg2, setlifimg2] = useState('');
//     const [lmfimg2, setlmfimg2] = useState('');
//     const [lrfimg2, setlrfimg2] = useState('');
//     const [llfimg2, setllfimg2] = useState('');
//     const [aadhaarImage, setaadhaarImage] = useState('');
//     const [documentimagebacktwo, setdocumentimagebacktwo] = useState('');
//   const {id} = useParams()

//   // fetch agent details ----->start
//   useEffect(() => {
//     async function fetchData() {
//       const config = {
//         headers: {
//           Authorization: `Bearer ${gettokinval.access}`,
//         },
//       };
//       let url = `${process.env.REACT_APP_API_URL}api/agent/${id}/`;
      
//       const response = await axios.get(url, config);
//       // setdefaultv(response.data.company)
//       setagentusername(response.data.username)
//       setagentname(response.data.first_name)
//       setagentlast(response.data.last_name)
//       setagentemail(response.data.email)
//       setagentmobile(response.data.mobile_no.substring(3))
//       setagentaddress(response.data.address)
//       setagentcountry(response.data.country)
//       setagentstate(response.data.state)
//       setagentcity(response.data.city)
//       setagentdob(response.data.dob)
//       setffn(response.data.fathersFullNm)
//       setagentage(response.data.agent_age)
//       setagentpincode(response.data.pincode)
//       setagentgender(response.data.gender)
//       setdefaultv(response.data.company)
//       setdefaultvtwo(response.data.location)
//       setcreated_by(response.data.created_by)
//       setcreated_at(response.data.created_at)
//       setlast_updated_by(response.data.last_updated_by)
//       setlast_updated_at(response.data.last_updated_at)
//       setagentapprover(response.data.Approver)
//       setagentmaker(response.data.Maker)
//       setagentchecker(response.data.Checker)
//       setphotograph(response.data.photograph)
//       setagentsuperadmin(response.data.is_superuser)
//       setidnumber(response.data.document_no)
//       setdocumenttype(response.data.document_type)
//       setdocumentexpiry(response.data.expiry_date)
//       setdocumentissue(response.data.issue_date)
//       setkycfathername(response.data.fathersFullName)
//       setkycage(response.data.age)
//       setkycfullname(response.data.fullName)
//       setkycphoto(response.data.photo)
//       setkycgander(response.data.doc_gender)
//       setkycdob(response.data.doc_dob)
//       setkycaddress(response.data.doc_address)
//       setkyccountry(response.data.doc_country)
//       setkycstate(response.data.doc_state)
//       setkyccity(response.data.doc_city)
//       setkycpincode(response.data.doc_pincode)
//       setkycemail(response.data.doc_email)
//       setkycphone(response.data.doc_mobile)
//       setllfimg2(response.data.l_little_finger)
//       setlrfimg2(response.data.l_ring_finger)
//       setlmfimg2(response.data.l_middle_finger)
//       setlifimg2(response.data.l_index_finger)
//       setltfimg2(response.data.l_thumb)
//       setrlfimg2(response.data.r_little_finger)
//       setrrfimg2(response.data.r_ring_finger)
//       setrmfimg2(response.data.r_middle_finger)
//       setrifimg2(response.data.r_index_finger)
//       setthumbimg2(response.data.r_thumb)
//       setdocumentimagebacktwo(response.data.docImg_back)
//       setaadhaarImage(response.data.docImg_front)
//       setsrtloader(false)
 
//     }
//     fetchData();
//   }, []);
//   // fetch agent details ----->end 
//   return (
//     <div style={{ width: '100%' }}>
//       {/* <Header /> */}
//       <div className="dashboardcontent px-2">
//         {/* <Breadcup name={'Agent'} second={'Approval'} /> */}
//         <div className="container-fuild px-2 desgin1 ">
//           <div className="row bg-white ">
//             <div className="col-lg-12 d-flex justify-content-between py-2" style={{ background: 'hsla(210, 85%, 32%, 0.2)', color: '#0C5398' }}>
//               <p className="m-0 customfont" >Agent Detail</p>
//               <div className="addnew d-block mb-2">
//                 <button className="btn text-white closebtn"><NavLink to='/approvalagentlist/0' style={{ textDecoration: 'none', color: 'white' }}> x Close</NavLink></button>
//               </div>
//             </div>
//             {/* <div className="col-12 py-3 customtext23" style={{ background: 'hsla(210, 85%, 32%, 0.2)', color: '#0C5398' }}>Agent Detail</div> */}
//           </div>
//         </div>
//         {srtloader == true ? <div className="container-fuild bg-white" style={{position:'relative'}}> <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"95%"}}>
//         <div className='px-2'> <Loadercomp size={100} /></div>
//         </div></div> : <div className="container-fuild px-2 pb-4 pt-4 bg-white">
//           <div className="row bg-white pb-4 round" style={{ border: '1px solid #E0E0E0', margin: "10px 0px", borderRadius: '3px' }}>
//           <div className="col-lg-6 px-4">
//                             <div className="row">
//                                 <div className="col-4 offset-3">
//                                     <h5 style={{color:'#0C5398',fontWeight:"700"}} >Kyc First</h5>
//                                 </div>
//                                 <div className="col-4 d-flex" style={{ gap: '22px' }}>
//                                     <h5  style={{color:'#0C5398',fontWeight:"700"}} className=''>Response</h5>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="col-lg-6 px-4">
//                         <div className="row">
//                                 <div className="col-4 offset-3">
//                                     <h5 style={{color:'#0C5398',fontWeight:"700"}} >Kyc First</h5>
//                                 </div>
//                                 <div className="col-4 d-flex" style={{ gap: '22px' }}>
//                                     <h5  style={{color:'#0C5398',fontWeight:"700"}} className=''>Response</h5>
//                                 </div>
//                             </div>
//                         </div>
//             <div className="col-lg-6 px-4">
//               <div className="row">
//                 <div className="col-md-4 col-4">
//                   <label htmlFor="" className="form-label">
//                     Username
//                   </label>
//                 </div>
//                 <div className="col-xl-4 col-lg-6 col-md-7 col-8 d-flex justify-content-start d-flex" style={{ gap: '22px' }}>
//                   <p className='customcolor'>{agentusername}</p>

//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-6 px-4">
//               <div className="row">
//                 <div className="col-md-4 col-4">
//                   <label htmlFor="" className="form-label customw">Email Address </label>
//                 </div>
//                 <div className="col-9">
//                 <div className="col-12 d-flex" style={{ gap: '22px' }}>
//                   <p className='customcolor'>{agentemail}</p>
//                 </div>
//                 <div className="col-12 customcolor">{kycemail}</div>
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-6 px-4">
//               <div className="row">
//                 <div className="col-md-4 col-4">
//                   <label htmlFor="" className="form-label">First Name</label>
//                 </div>
//                 <div className="col-4 d-flex" style={{ gap: '22px' }}>
//                   <p className='customcolor'>{agentname}</p>
//                 </div>
//                   <div className="col-4 customcolor">{kycfullname}</div>
//               </div>
//             </div>
//             <div className="col-lg-6 px-4">
//               <div className="row">
//                 <div className="col-md-4 col-4">
//                   <label htmlFor="" className="form-label">Last Name</label>
//                 </div>
//                 <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-start d-flex" style={{ gap: '22px' }}>
//                   <p className='customcolor'>{agentlast != "undefined" ? agentlast :'' }</p>

//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-6 px-4">
//               <div className="row">
//                 <div className="col-md-4 col-4">
//                   <label htmlFor="" className="form-label customw">Father Name</label>
//                 </div>
//                 <div className="col-4 d-flex" style={{ gap: '22px' }}>
//                   <p className='customcolor'>{ffn}</p>
//                 </div>
//                   <div className="col-4 customcolor">{kycfathername}</div>
//               </div>
//             </div>
//             <div className="col-lg-6 px-4">
//               <div className="row">
//                 <div className="col-md-4 col-4">
//                   <label htmlFor="" className="form-label">Gender</label>
//                 </div>
//                 <div className="col-4 d-flex" style={{ gap: '22px' }}>
//                   <p className='customcolor'>{agentgender}</p>
//                 </div>
//                 <div className="col-4 customcolor">{kycgander}</div> 
//               </div>
//             </div>
//             <div className="col-lg-6 px-4">
//               <div className="row">
//                 <div className="col-md-4 col-4">
//                   <label htmlFor="" className="form-label">Age</label>
//                 </div>
//                 <div className="col-4 d-flex" style={{ gap: '22px' }}>
//                   <p className='customcolor'>{agentage}</p>

//                 </div>
//                 <div className="col-4 customcolor">{kycage}</div> 
//               </div>
//             </div>
//             <div className="col-lg-6 px-4">
//               <div className="row">
//                 <div className="col-md-4 col-4">
//                   <label htmlFor="" className="form-label">DOB</label>
//                 </div>
//                 <div className="col-4 d-flex" style={{ gap: '22px' }}>
//                   <p className='customcolor'>{agentdob}</p>
//                 </div>
//                 <div className="col-4 customcolor">{kycdob}</div>
//               </div>
//             </div>
//             <div className="col-lg-6 px-4">
//               <div className="row">
//                 <div className="col-md-4 col-4">
//                   <label htmlFor="" className="form-label">Company</label>
//                 </div>
//                 <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-start d-flex" style={{ gap: '22px' }}>
//                   <p className='customcolor'>{defaultv}</p>

//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-6 px-4">
//               <div className="row">
//                 <div className="col-md-4 col-4">
//                   <label htmlFor="" className="form-label">Location</label>
//                 </div>
//                 <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-start d-flex" style={{ gap: '22px' }}>
//                   <p className='customcolor'>{defaultvtwo}</p>

//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-6 px-4">
//               <div className="row">
//                 <div className="col-md-4 col-4">
//                   <label htmlFor="" className="form-label">Mobile No.</label>
//                 </div>
//                 <div className="col-4 d-flex" style={{ gap: '22px' }}>
//                   <p className='customcolor'>{agentmobile}</p>
//                 </div>
//                 <div className="col-4 customcolor">{kycphone}</div>
//               </div>
//             </div>
//             <div className="col-lg-6 px-4">
//               <div className="row">
//                 <div className="col-md-4 col-4">
//                   <label htmlFor="" className="form-label">Address</label>
//                 </div>
//                 <div className="col-4 d-flex" style={{ gap: '22px' }}>
//                   <p className='customcolor'>{agentaddress}</p>
//                 </div>
//                 <div className="col-4 customcolor">{kycaddress}</div>
//               </div>
//             </div>
//             <div className="col-lg-6 px-4">
//               <div className="row">
//                 <div className="col-md-4 col-4">
//                   <label htmlFor="" className="form-label">Country</label>
//                 </div>
//                 <div className="col-4 d-flex" style={{ gap: '22px' }}>
//                   <p className='customcolor'>{agentcountry}</p>
//                 </div>
//                 <div className="col-4 customcolor"></div>
//               </div>
//             </div>
//             <div className="col-lg-6 px-4">
//               <div className="row">
//                 <div className="col-md-4 col-4">
//                   <label htmlFor="" className="form-label">State</label>
//                 </div>
//                 <div className="col-4 d-flex" style={{ gap: '22px' }}>
//                   <p className='customcolor'>{agentstate}</p>
//                 </div>
//                 <div className="col-4 customcolor">{kycstate}</div> 
//               </div>
//             </div>
//             <div className="col-lg-6 px-4">
//               <div className="row">
//                 <div className="col-md-4 col-4">
//                   <label htmlFor="" className="form-label">City</label>
//                 </div>
//                 <div className="col-4 d-flex" style={{ gap: '22px' }}>
//                   <p className='customcolor'>{agentcity}</p>
//                 </div>
//                 <div className="col-4 customcolor">{kyccity}</div>
//               </div>
//             </div>
//             <div className="col-lg-6 px-4">
//               <div className="row">
//                 <div className="col-md-4 col-4">
//                   <label htmlFor="" className="form-label">Pincode</label>
//                 </div>
//                 <div className="col-4 d-flex" style={{ gap: '22px' }}>
//                   <p className='customcolor'>{agentpincode}</p>
//                 </div>
//                 <div className="col-4 customcolor">{kycpincode}</div>
//               </div>
//             </div>
//             <div className="col-md-6 px-4 pt-3">
//           <div className="row">
//             <div className="col-4">
//               <label htmlFor="" className="form-label">User Type</label>
//             </div>
//             <div className="col-8">
//               <p className='customcolor'>{agentsuperadmin == true ? "Super Admin" : " Agent"}</p>
//             </div>
//           </div>
//         </div>
//         <div className="col-md-6 px-4 pt-3">
//           <div className="row">
//             <div className="col-4">
//               <label htmlFor="" className="form-label">Created by</label>
//             </div>
//             <div className="col-8">
//             <p className='customcolor ps-4'>{created_by}</p>
//             </div>
//           </div>
//         </div>
//         <div className="col-md-6 px-4 pt-3">
//           <div className="row">
//             <div className="col-4">
//               <label htmlFor="" className="form-label">Created Date & Time</label>
//             </div>
//             <div className="col-8">
//             <p className='customcolor ps-4'>{created_at}</p>
//             </div>
//           </div>
//         </div>
//         <div className="col-md-6 px-4 pt-3">
//           <div className="row">
//             <div className="col-4">
//               <label htmlFor="" className="form-label">Last Modified by </label>
//             </div>
//             <div className="col-8">
//             <p className='customcolor ps-4'>{last_updated_by ? last_updated_by : "NOT MODIFIED"}</p>
//             </div>
//           </div>
//         </div>
//         <div className="col-md-6 px-4 pt-3">
//           <div className="row">
//             <div className="col-4">
//               <label htmlFor="" className="form-label">Last Modified Date & Time </label>
//             </div>
//             <div className="col-8">
//             <p className='customcolor ps-4'>{last_updated_at ? last_updated_at : "NOT MODIFIED"}</p>
//             </div>
//           </div>
//         </div>
//             <div className="col-12 px-4 pt-3">
//               <div className="row">
//                 <div className="col-2">
//                   <label htmlFor="" className="form-label">Role</label>
//                 </div>
//                 {agentchecker == true ?   <div className="col-md-4 col-4"> <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked /> <label htmlFor="" className="form-label" style={{ paddingRight: '6px' }}>Checker</label></div> : ''}
//                {agentmaker == true ? <div className="col-md-4 col-4"> <label htmlFor="" className="form-label" style={{ paddingRight: '6px' }}>Maker</label> <input className="form-check-input" type="checkbox" value="" checked /></div> : ''}
//                 {agentapprover == true ? <div className="col-md-4 col-4"> <label htmlFor="" className="form-label" style={{ paddingRight: '6px' }}>Approver</label> <input className="form-check-input" type="checkbox" value="" checked /></div> : ''}
//               </div>
//             </div>
//             <div className="col-12">
//               <div className="row px-4 pt-3">
//                 <div className="col-6">
//                   <div className="row">
//                     <div className="col-12"><label htmlFor="" className="form-label" style={{ paddingRight: '10px' }}>Biometric Detail</label> </div>
//                     <div className="col-12">
//                     <div className="border round py-3 d-flex justify-content-center">
//                         <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
//      <label htmlFor="">Right Hand</label>
//      <img src={thumbimg2 == '' || thumbimg2 == null ? img5 : img4} alt="rtf" style={{position:"absolute",top:"80px",left:"13px"}} />
//      <img src={rifimg2 == '' || rifimg2 == null ? img5 : img4} alt="rif" style={{position:"absolute",top:"37px",left:"35px"}} />
//      <img src={rmfimg2 == '' || rmfimg2 == null ? img5 : img4} alt="rmf" style={{position:"absolute",top:"30px",left:"65px"}} />
//      <img src={rrfimg2 == '' || rrfimg2 == null ? img5 : img4} alt="rrf" style={{position:"absolute",top:"36px",left:"84px"}} />
//      <img src={rlfimg2 == '' || rlfimg2 == null ? img5 : img4} alt="rlf" style={{position:"absolute",top:"55px",left:"103px"}} />
//                           <img src={img1} alt="qwerty" />
//                           </div>
//                           <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
// <label htmlFor="">Left Hand</label>
// <img src={llfimg2 == '' || llfimg2 == null ? img5 : img4} alt="llf" style={{position:"absolute",top:"56px",left:"13px"}} />
//      <img src={lrfimg2 == '' || lrfimg2 == null ? img5 : img4} alt="lrf" style={{position:"absolute",top:"36px",left:"33px"}} />
//      <img src={lmfimg2 == '' || lmfimg2 == null ? img5 : img4} alt="lmf" style={{position:"absolute",top:"31px",left:"55px"}} />
//      <img src={lifimg2 == '' || lifimg2 == null ? img5 : img4} alt="lif" style={{position:"absolute",top:"38px",left:"80px"}} />
//      <img src={ltfimg2 == '' || ltfimg2 == null ? img5 : img4} alt="ltf" style={{position:"absolute",top:"80px",left:"102px"}} />
//                           <img src={img2} alt="trew" />
//                           </div>
//                         </div>
//                       </div>
//                   </div>
//                   <div className="col-12 py-2 px-4 d-flex justify-content-between">
//                 <button
//                 type='button'
//                   // className="btn1"
//                   style={{backgroundColor:"#0C5398"}}
//                   className='btn btn-primary'
//                   data-bs-toggle="modal"
//                   data-bs-target="#exampleModal5"
//                 >
//                   View
//                 </button>
//               </div>
//                 </div>
//                 <div className="col-6">
//                   <div className="row">
//                     <div className="col-12"><label htmlFor="" className="form-label" style={{ paddingRight: '10px' }}>Photograph</label></div>
//                     <div className="col-12 ">
//                       <div className="border round py-3 d-flex justify-content-center">
//                         {/* <ReactImageMagnify
//                             {...{
//                                 smallImage: {
//                                     alt: 'Wristwatch by Versace',
//                                     isFluidWidth: true,
//                                     src: photograph == null ? img3 : photograph,
//                                     width:140,
//                                     height:162
//                                 },
//                                 largeImage: {
//                                     src: photograph == null ? img3 : photograph,
//                                     width: 1200,
//                                     height: 1800
//                                 },
//                                 enlargedImagePosition: 'over',
//                                 lensStyle: { backgroundColor: 'rgba(0,0,0,.6)' }
//                             }}
                            
//                         /> */}
//                           <div className="row px-2">
//                               <div className="col-6 d-flex justify-content-center">
//                               <button
//                 type='button'
//                 style={{border:'none',outline:'none'}}
//                   data-bs-toggle="modal"
//                   data-bs-target="#exampleModaltwo"
//                 >
//               <img src={photograph == null ? img3 : photograph} alt="zxcvbnm" width="140px" height="162px" />
//               </button>
//                                 {/* <ReactImageMagnify
//                                   {...{
//                                     smallImage: {
//                                       alt: "Wristwatch by Versace",
//                                       isFluidWidth: true,
//                                       src:
//                                         photograph == null ? img3 : photograph,
//                                       width: 140,
//                                       height: 162,
//                                     },
//                                     largeImage: {
//                                       src:
//                                         photograph == null ? img3 : photograph,
//                                       width: 1200,
//                                       height: 1800,
//                                     },
//                                     // enlargedImagePosition: "over",
//                                     lensStyle: {
//                                       backgroundColor: "rgba(0,0,0,.6)",
//                                     },
//                                   }}
//                                 /> */}
//                               </div>
//                               <div className="col-6 d-flex justify-content-center">
//                               <button
//                 type='button'
//                 style={{border:'none',outline:'none'}}
//                   data-bs-toggle="modal"
//                   data-bs-target="#exampleModalthree"
//                 >
//               <img src={`data:image/png;base64,${kycphoto}`} alt="zxcvbnm" width="140px" height="162px" />
//               </button>
//                                 {/* <ReactImageMagnify
//                                   {...{
//                                     smallImage: {
//                                       alt: "Wristwatch by Versace",
//                                       isFluidWidth: true,
//                                       src: `data:image/png;base64,${kycphoto}`,
//                                       width: 140,
//                                       height: 162,
//                                     },
//                                     largeImage: {
//                                       src: `data:image/png;base64,${kycphoto}`,
//                                       width: 1200,
//                                       height: 1800,
//                                     },
//                                     // enlargedImagePosition: "over",
//                                     lensStyle: {
//                                       backgroundColor: "rgba(0,0,0,.6)",
//                                     },
//                                   }}
//                                 /> */}
//                               </div>
//                             </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="col-12">
//               <div className="row">
//                 <div className="col-12 ccolor py-4 px-4">Document </div>
//                 <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
//     <div className="row">
//       <div className="col-lg-4">
//         <label htmlFor="" className="form-label">
//         Document Type 
//         </label>
//       </div>
//       <div className="col-8 d-flex" style={{ gap: '22px' }}>

//                                     <p className='customcolor'>{documenttype}</p>
                                   
//       </div>
//     </div>
//   </div>
//   <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
//     <div className="row">
//       <div className="col-lg-4">
//         <label htmlFor="" className="form-label">
//         Document No.
//         </label>
//       </div>
//       <div className="col-8 d-flex" style={{ gap: '22px' }}>
//       <p className='customcolor'>{idnumber}</p>
                

//       </div>
//     </div>
//   </div>
//   {documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT' ? <div className="col-lg-6 px-4">
//                             <div className="row">
//                                 <div className="col-md-4 col-4">
//                                     <label htmlFor="" className="form-label">Expiry date </label>
//                                 </div>
//                                 <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end d-flex" style={{ gap: '22px' }}>
//                                     <p className='customcolor'>{documentexpiry}</p>
                                  
//                                 </div>
//                             </div>
//                         </div> : ''}
//                         {documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT' ? <div className="col-lg-6 px-4">
//                             <div className="row">
//                                 <div className="col-md-4 col-4">
//                                     <label htmlFor="" className="form-label">Issue date </label>
//                                 </div>
//                                 <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end d-flex" style={{ gap: '22px' }}>
//                                     <p className='customcolor'>{documentissue}</p>
//                                 </div>
//                             </div>
//                         </div> : ''}


//                         <div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
//     <div className="row">
//       <div className="col-lg-4">
//         Document Front Image
//       </div>
//       <div className="col-lg-8">
//       <img src={aadhaarImage} alt="Modified Aadhaar Card" style={{width:"100%",height:"100%"}} />
//       </div>
//       <div className=" offset-lg-4 col-lg-8">
//       <button
// style={{backgroundColor:"#0C5398",marginTop:"5px"}}
// type='button'
// className='btn btn-primary'
//                   data-bs-toggle="modal"
//                   data-bs-target="#exampleModal34"
//                 >
// View
//                 </button>
//       </div>
//     </div>
//   </div>
//   <div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
//     <div className="row">
//       <div className="col-lg-4">
//         Document Back Image
//       </div>
//       <div className="col-lg-8">
//       <img src={documentimagebacktwo} alt="Modified Aadhaar Card" style={{width:"100%",height:"100%"}} />
//       </div>
//       <div className=" offset-lg-4 col-lg-8">
//       <button
// style={{backgroundColor:"#0C5398",marginTop:"5px"}}
// type='button'
// className='btn btn-primary'
//                   data-bs-toggle="modal"
//                   data-bs-target="#exampleModal35"
//                 >
// View
//                 </button>
//       </div>
//     </div>
//   </div>

//                         {/* <div className="col-lg-6 px-4">
//                             <div className="row">
//                                 <div className="col-md-4 col-4">
//                                     <label htmlFor="" className="form-label">Full Name </label>
//                                 </div>
//                                 <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end d-flex" style={{ gap: '22px' }}>
//                                     <p className='customcolor'>{kycfullname}</p>
                            
//                                 </div>
//                             </div>
//                         </div>
//                        <div className="col-lg-6 px-4">
//                             <div className="row">
//                                 <div className="col-md-4 col-4">
//                                     <label htmlFor="" className="form-label">Father Name </label>
//                                 </div>
//                                 <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end d-flex" style={{ gap: '22px' }}>
//                                     <p className='customcolor'>{kycfathername}</p>
                                   
//                                 </div>
//                             </div>
//                         </div>  */}
                        
//              {/* <div className="col-lg-6 px-4">
//                             <div className="row">
//                                 <div className="col-md-4 col-4">
//                                     <label htmlFor="" className="form-label">Age </label>
//                                 </div>
//                                 <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end d-flex" style={{ gap: '22px' }}>
//                                     <p className='customcolor'>{kycage}</p>
                                   
//                                 </div>
//                             </div>
//                         </div>  */}

//                          {/* <div className="col-6 px-4" style={{ paddingTop: "20px" }}>
//                                     <div className="row">
//                                         <div className="col-12"><label htmlFor="" className="form-label" style={{ paddingRight: '10px' }}>Image</label></div>
//                                         <div className="col-12 ">
//                                             <div className="border round py-3 d-flex justify-content-center">
//                                                 <ReactImageMagnify
//                             {...{
//                                 smallImage: {
//                                     alt: 'Wristwatch by Versace',
//                                     isFluidWidth: true,
//                                     src: `data:image/png;base64,${kycphoto}`,
//                                     width:140,
//                                     height:162
//                                 },
//                                 largeImage: {
//                                     src: `data:image/png;base64,${kycphoto}`,
//                                     width: 1200,
//                                     height: 1800
//                                 },
//                                 enlargedImagePosition: 'over',
//                                 lensStyle: { backgroundColor: 'rgba(0,0,0,.6)' }
//                             }}
                            
//                         />
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>  */}
//                 <div className="col-12 py-3 px-4">
//                   <div style={{ background: '#D9D9D9', height: '1px', width: '100%' }}></div>
//                 </div>
//                 <div className="col-12 py-3 px-4 d-flex justify-content-end" >
//                   {/* <div>
//                     <label htmlFor="" className="form-label customw">Checked All </label>

//                   </div> */}
//                   <div className=" d-flex justify-content-end" style={{ gap: '4px' }}>
//                     <button className='btn4'>Cancel</button>
//                     <button className='btn5'>Save</button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>}
//       </div>
//       <div className="modal fade" id="exampleModalthree" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
//             <div className="modal-dialog modal-dialog-centered modal-xl">
//               <div className="modal-content">
//                 <div className="modal-header mod-line">
//                   <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//                 </div>
//                 <div className="modal-body">
//                 <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>
//                 <div className="col-12 px-2 pt-3">
//   <img src={`data:image/png;base64,${kycphoto}`} style={{width:"100%",height:"100%"}} alt="photograph" />
//   </div>
// </div>
//                 </div>
//               </div>
//             </div>
//           </div>  






//           <div className="modal fade" id="exampleModal34" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
//             <div className="modal-dialog modal-dialog-centered modal-xl">
//               <div className="modal-content">
//                 <div className="modal-header mod-line">
//                   {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
//                   <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//                 </div>
//                 <div className="modal-body">

//                 <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>


  
//   <div className="col-12 px-2 pt-3">
//   <img src={aadhaarImage == null ? img3 : aadhaarImage} style={{width:"100%",height:"100%"}} alt="photograph" />
//   </div>
// </div>
//                 </div>
//                 {/* <div className="modal-footer mod-line m-auto">
//                   <button type="button" className="btn closecancel">Cancel</button>
//                   <button type="button" className="btn closebtn text-white">Submit</button>
//                 </div> */}
//               </div>
//             </div>
//           </div>


//           <div className="modal fade" id="exampleModal35" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
//             <div className="modal-dialog modal-dialog-centered modal-xl">
//               <div className="modal-content">
//                 <div className="modal-header mod-line">
//                   {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
//                   <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//                 </div>
//                 <div className="modal-body">

//                 <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>


  
//   <div className="col-12 px-2 pt-3">
//   <img src={documentimagebacktwo == null ? img3 : documentimagebacktwo} style={{width:"100%",height:"100%"}} alt="photograph" />
//   </div>
// </div>
//                 </div>
//                 {/* <div className="modal-footer mod-line m-auto">
//                   <button type="button" className="btn closecancel">Cancel</button>
//                   <button type="button" className="btn closebtn text-white">Submit</button>
//                 </div> */}
//               </div>
//             </div>
//           </div>
//       <div className="modal fade" id="exampleModaltwo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
//             <div className="modal-dialog modal-dialog-centered modal-xl">
//               <div className="modal-content">
//                 <div className="modal-header mod-line">
//                   <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//                 </div>
//                 <div className="modal-body">
//                 <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>
//                 <div className="col-12 px-2 pt-3">
//   <img src={photograph == null ? img3 : photograph} style={{width:"100%",height:"100%"}} alt="photograph" />
//   </div>
// </div>
//                 </div>
//               </div>
//             </div>
//           </div>  
//       <div className="modal fade" id="exampleModal5" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
//             <div className="modal-dialog modal-dialog-centered modal-lg">
//               <div className="modal-content">
//                 <div className="modal-header mod-line">
//                   {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
//                   <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//                 </div>
//                 <div className="modal-body">

//                 <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>

// <div className="col-lg-6 px-2 pt-3">
//     <div className="row">
//       <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
//         <label htmlFor="tf2" className="form-label ">Right Thumb  <span style={{ color: 'red' }}>*</span></label>
//         {/* <input type="file" class="form-control" id='tf2' name="image2" style={{opacity:"0",position:'absolute'}}  onChange={(e) => {handleImageChange11(e)}}  /> */}
//         {/* <label htmlFor="tf"> */}
//         <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: thumbimg2 != '' ? '' : '3rem',width:"20%" }}> {thumbimg2 != '' ? <img src={`data:image/png;base64,${thumbimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>

//       </div>
//     </div>
//   </div>
//   <div className="col-lg-6 px-2 pt-3">
//     <div className="row">
//       <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
//         <label htmlFor="ltf2" className="form-label ">Left Thumb  <span style={{ color: 'red' }}>*</span></label>
//         {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="ltf2" onChange={(e) => {handleImageChange16(e)}} /> */}
//         <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: ltfimg2 != '' ? '' : '3rem',width:"20%" }}> {ltfimg2 != '' ? <img src={`data:image/png;base64,${ltfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
//       </div>
//     </div>
//   </div>
//   <div className="col-lg-6 px-2 pt-3">
//     <div className="row">
//       <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
//         <label htmlFor="rif2" className="form-label ">Right Index Finger <span style={{ color: 'red' }}>*</span></label>
//         {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rif2" onChange={(e) => {handleImageChange12(e)}} /> */}
//         <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rifimg2 != '' ? '' : '3rem',width:"20%" }}> {rifimg2 != '' ? <img src={`data:image/png;base64,${rifimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
//       </div>
//     </div>
//   </div>
//   <div className="col-lg-6 px-2 pt-3">
//     <div className="row">
//       <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
//         <label htmlFor="lif2" className="form-label ">Left Index Finger  <span style={{ color: 'red' }}>*</span></label>
//         {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lif2" onChange={(e) => {handleImageChange17(e)}} /> */}
//         <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lifimg2 != '' ? '' : '3rem',width:"20%" }}> {lifimg2 != '' ? <img src={`data:image/png;base64,${lifimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
//       </div>
//     </div>
//   </div>
//   <div className="col-lg-6 px-2 pt-3">
//     <div className="row">
//       <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
//         <label htmlFor="rmf2" className="form-label ">Right Middle Finger<span style={{ color: 'red' }}>*</span></label>
//         {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rmf2" onChange={(e) => {handleImageChange13(e)}} /> */}
//         <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rmfimg2 != '' ? '' : '3rem',width:"20%" }}> {rmfimg2 != '' ? <img src={`data:image/png;base64,${rmfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
//       </div>
//     </div>

//   </div>
//   <div className="col-lg-6 px-2 pt-3">
//     <div className="row">
//       <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
//         <label htmlFor="lmf2" className="form-label ">Left Middle Finger <span style={{ color: 'red' }}>*</span></label>
//         {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lmf2" onChange={(e) => {handleImageChange18(e)}} /> */}
//         <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lmfimg2 != '' ? '' : '3rem',width:"20%" }}> {lmfimg2 != '' ? <img src={`data:image/png;base64,${lmfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
        
//       </div>
//     </div>
//   </div>
//   <div className="col-lg-6 px-2 pt-3">
//     <div className="row">
//       <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
//         <label htmlFor="rrf2" className="form-label ">Right Ring Finger  <span style={{ color: 'red' }}>*</span></label>
//         {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rrf2" onChange={(e) => {handleImageChange14(e)}} /> */}
//         <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rrfimg2 != '' ? '' : '3rem',width:"20%" }}> {rrfimg2 != '' ? <img src={`data:image/png;base64,${rrfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
       
//       </div>
//     </div>

//   </div>
//   <div className="col-lg-6 px-2 pt-3">
//     <div className="row">
//       <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
//         <label htmlFor="lrf2" className="form-label ">Left Ring Finger  <span style={{ color: 'red' }}>*</span></label>
//         {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lrf2" onChange={(e) => {handleImageChange19(e)}} /> */}
//         <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lrfimg2 != '' ? '' : '3rem',width:"20%" }}> {lrfimg2 != '' ? <img src={`data:image/png;base64,${lrfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
        
//       </div>
//     </div>
//   </div>
//   <div className="col-lg-6 px-2 pt-3">
//     <div className="row">
//       <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
//         <label htmlFor="rlf2" className="form-label ">Right Little Finger  <span style={{ color: 'red' }}>*</span></label>
//         {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rlf2" onChange={(e) => {handleImageChange15(e)}} /> */}
//         <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rlfimg2 != '' ? '' : '3rem',width:"20%" }}> {rlfimg2 != '' ? <img src={`data:image/png;base64,${rlfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
     
//       </div>
//     </div>
//   </div>


 
 
//   <div className="col-lg-6 px-2 pt-3">
//     <div className="row">
//       <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
//         <label htmlFor="llf2" className="form-label ">Left Little Finger  <span style={{ color: 'red' }}>*</span></label>
//         {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="llf2" onChange={(e) => {handleImageChange20(e)}} /> */}
//         <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: llfimg2 != '' ? '' : '3rem',width:"20%" }}> {llfimg2 != '' ? <img src={`data:image/png;base64,${llfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      
//       </div>
//     </div>
//   </div>
// </div>
//                 </div>
//                 {/* <div className="modal-footer mod-line m-auto">
//                   <button type="button" className="btn closecancel">Cancel</button>
//                   <button type="button" className="btn closebtn text-white">Submit</button>
//                 </div> */}
//               </div>
//             </div>
//           </div>
//     </div>
//   )
// }

// export default Aagentdetail

























import React, { useEffect, useState } from "react";
import Breadcup from "../../components/Breadcup";
import Header from "../../components/Header";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import img1 from "../../assets/Group 40.png";
import img2 from "../../assets/Group 41.png";
import img3 from "../../assets/asdfg.png";
// import img4 from "../../assets/aadhar.png";
import img4 from "../../assets/abc.png";
import img5 from "../../assets/cba.png";
import { BsQuestionLg } from "react-icons/bs";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { gettoken } from "../../Localstorage/Store";
import axios from "axios";
import Loadercomp from "../../components/Loadercomp";
import ReactImageMagnify from "react-image-magnify";
import { getName } from "country-list";

const Aagentdetail = () => {
  const nvg = useNavigate();
  const [errorcls, seterrorcls] = useState({});
  const [loader, setloader] = useState(false);
  const [agentusername, setagentusername] = useState();
  const [agentname, setagentname] = useState();
  const [agentlast, setagentlast] = useState();
  const [agentemail, setagentemail] = useState();
  const [ffn, setffn] = useState();
  const [agentgender, setagentgender] = useState();
  const [agentmobile, setagentmobile] = useState();
  const [agentchecker, setagentchecker] = useState(false);
  const [agentmaker, setagentmaker] = useState(false);
  const [agentapprover, setagentapprover] = useState(false);
  const [agentcountry, setagentcountry] = useState();
  const [agentstate, setagentstate] = useState();
  const [agentcity, setagentcity] = useState();
  const [dob, setdob] = useState();
  const [agentage, setagentage] = useState();
  const [agentaddress, setagentaddress] = useState();
  const [agentpincode, setagentpincode] = useState();
  const [agentsuperadmin, setagentsuperadmin] = useState();
  const [defaultv, setdefaultv] = useState("");
  const [defaultvtwo, setdefaultvtwo] = useState("");
  const [usernamecheck, setusernamecheck] = useState(false);
  const [fnamecheck, setfnamecheck] = useState(false);
  const [lnamecheck, setlnamecheck] = useState(false);
  const [photographcheck, setphotographcheck] = useState(false);
  const [aadhar, setaadhar] = useState(false);
  const [biomcheck, setbiomcheck] = useState(false);
  const [mobile_nocheck, setmobile_nocheck] = useState(false);
  const [gendercheck, setgendercheck] = useState(false);
  const [emailIDcheck, setemailIDcheck] = useState(false);
  const [companycheck, setcompanycheck] = useState(false);
  const [locationcheck, setlocationcheck] = useState(false);
  const [addresscheck, setaddresscheck] = useState(false);
  const [countrycheck, setcountrycheck] = useState(false);
  const [statecheck, setstatecheck] = useState(false);
  const [citycheck, setcitycheck] = useState(false);
  const [dobcheck, setdobcheck] = useState(false);

  const [rolecheck, setrolecheck] = useState(false);
  const [documcheck, setdocumcheck] = useState(false);
  const [pincodecheck, setpincodecheck] = useState(false);
  const [srtloader, setsrtloader] = useState(true);
  const gettokinval = gettoken();
  const [documenttypetwo, setdocumenttypetwo] = useState();
  const [idnumbertwo, setidnumbertwo] = useState();
  const [documentissuetwo, setdocumentissuetwo] = useState();
  const [documentexpirytwo, setdocumentexpirytwo] = useState();
  const [modifiedImagenumbertwo, setModifiedImagenumbertwo] = useState(null);
  const [documentimageback2, setdocumentimageback2] = useState(null);
  const [photograph, setphotograph] = useState("");
  const [kycphoto, setkycphoto] = useState("");
  const [kycfullname, setkycfullname] = useState("");
  const [kycfullnametwo, setkycfullnametwo] = useState("");
  const [kycage, setkycage] = useState("");
  const [kycfathername, setkycfathername] = useState("");
    const [kycgander, setkycgander] = useState('');
    const [kycdob, setkycdob] = useState('');
    const [kycaddress, setkycaddress] = useState('');
    const [kyccountry, setkyccountry] = useState('');
    const [kycstate, setkycstate] = useState('');
    const [crtdate, setcrtdate] = useState('');
    const [kyccity, setkyccity] = useState('');
    const [kycpincode, setkycpincode] = useState('');
    const [kycemail, setkycemail] = useState('');
    const [kycphone, setkycphone] = useState('');
  const [documenttype, setdocumenttype] = useState("PAN");
  const [idnumber, setidnumber] = useState("DTTPA8683J");
  const [documentimage, setdocumentimage] = useState("ASD");
  const [documentissue, setdocumentissue] = useState("SD");
  const [documentexpiry, setdocumentexpiry] = useState("ASD");
  const { id } = useParams();
  const [thumbimg2, setthumbimg2] = useState('');
    const [rifimg2, setrifimg2] = useState('');
    const [rmfimg2, setrmfimg2] = useState('');
    const [rrfimg2, setrrfimg2] = useState('');
    const [rlfimg2, setrlfimg2] = useState('');
    const [ltfimg2, setltfimg2] = useState('');
    const [lifimg2, setlifimg2] = useState('');
    const [lmfimg2, setlmfimg2] = useState('');
    const [lrfimg2, setlrfimg2] = useState('');
    const [llfimg2, setllfimg2] = useState('');
    const [aadhaarImage, setaadhaarImage] = useState('');
    const [documentimagebacktwo, setdocumentimagebacktwo] = useState('');
  // fetch agent details ----->start
  useEffect(() => {
    async function fetchData() {
      const config = {
        headers: {
          Authorization: `Bearer ${gettokinval.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}api/agent/${id}/`;

      const response = await axios.get(url, config);
      // setdefaultv(response.data.company)
      setagentusername(response.data.username);
      setagentname(response.data.first_name);
      setagentlast(response.data.last_name);
      setagentemail(response.data.email);
      setagentmobile(response.data.mobile_no.substring(3));
      setagentaddress(response.data.address);
      setagentcountry(response.data.country);
      setagentstate(response.data.state);
      setagentcity(response.data.city);
      setagentpincode(response.data.pincode);
      setagentgender(response.data.gender);
      setdefaultv(response.data.company);
      setdefaultvtwo(response.data.location);
      setagentapprover(response.data.Approver);
      setagentmaker(response.data.Maker);
      setagentchecker(response.data.Checker);
      setffn(response.data.fathersFullNm);
      setphotograph(response.data.photograph);
      setagentsuperadmin(response.data.is_superuser);
      setidnumber(response.data.document_no);
      setdocumenttype(response.data.document_type);
      setdocumentexpiry(response.data.expiry_date);
      setdocumentissue(response.data.issue_date);
      setkycfathername('');
      setkycage('');
      setllfimg2(response.data.l_little_finger)
      setlrfimg2(response.data.l_ring_finger)
      setlmfimg2(response.data.l_middle_finger)
      setlifimg2(response.data.l_index_finger)
      setltfimg2(response.data.l_thumb)
      setrlfimg2(response.data.r_little_finger)
      setrrfimg2(response.data.r_ring_finger)
      setrmfimg2(response.data.r_middle_finger)
      setrifimg2(response.data.r_index_finger)
      setthumbimg2(response.data.r_thumb)
      setkycfullname(response.data.fullName);
      setkycfullnametwo(response.data.fullName_2)
      setkycphoto('');
      setagentage(response.data.agent_age);
      setdob('');
      setkycgander('')
      setkycdob('')
      setdocumentimagebacktwo(response.data.docImg_back)
      setaadhaarImage(response.data.docImg_front)
      setdocumentimageback2(response.data.docImg_back2)
      setModifiedImagenumbertwo(response.data.docImg_front2)
      setdocumentexpirytwo(response.data.expiry_date2)
      setdocumentissuetwo(response.data.issue_date2)
      setidnumbertwo(response.data.document_no2)
      setdocumenttypetwo(response.data.document_type2)
      setkycaddress('')
      setkyccountry('')
      setkycstate('')
      setkyccity('')
      setkycpincode('')
      setkycemail('')
      setkycphone('')

      if(response.data.dob !== undefined){
        const newdate = () => {
          // const num = parseInt(dateString)
           const dateParts = response.data.dob.split('-');
          //  console.log("mmmmm",dateParts)
           const year = parseInt(dateParts[0], 10);
           const month = parseInt(dateParts[1], 10);
           const day = parseInt(dateParts[2], 10);
         
           const months = [
             'January',
             'February',
             'March',
             'April',
             'May',
             'June',
             'July',
             'August',
             'September',
             'October',
             'November',
             'December',
           ];
         
           const formattedDate = `${day} ${months[month - 1]}, ${year}`;
           setcrtdate(formattedDate)
         };
         newdate();
      }
      setsrtloader(false);
    }
    fetchData();
  }, []);

  async function submitform(e) {
    e.preventDefault();
    setloader(true);

    const formdata = new FormData();
    formdata.append("usernameApprove", usernamecheck);
    formdata.append("fnameApprove", fnamecheck);
    formdata.append("lnameApprove", lnamecheck);
    formdata.append("roleApprove", rolecheck);
    formdata.append("mobile_noApprove", mobile_nocheck);
    formdata.append("genderApprove", gendercheck);
    formdata.append("emailApprove", emailIDcheck);
    formdata.append("companyApprove", companycheck);
    formdata.append("locationApprove", locationcheck);
    // formdata.append("dobApprove", dobcheck);
    formdata.append("countryApprove", countrycheck);
    formdata.append("stateApprove", statecheck);
    formdata.append("cityApprove", citycheck);
    formdata.append("pincodeApprove", pincodecheck);
    formdata.append("addressApprove", addresscheck);
    formdata.append("photographApprove", photographcheck);
    formdata.append("document_typeApprove", true);
    formdata.append("issue_dateApprove", true);
    formdata.append("expiry_dateApprove", true);

    // formdata.append("r_thumb", cr_thumb);
    // formdata.append("r_index_finger", cr_index_finger);
    // formdata.append("r_middle_finger", cr_middle_finger);
    // formdata.append("r_ring_finger", cr_ring_finger);
    // formdata.append("r_little_finger", cr_little_finger);
    // formdata.append("l_thumb", cl_thumb);
    // formdata.append("l_index_finger", cl_index_finger);
    // formdata.append("l_middle_finger", cl_middle_finger);
    // formdata.append("l_ring_finger", cl_ring_finger);
    // formdata.append("l_little_finger", cl_little_finger);

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${gettokinval.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}api/agent_approval/${id}/`;
      const response = await axios.patch(url, formdata, config);
      // console.log(response);
      //   setcustresponse(response);
      //   seterrorclass(false);
      setloader(false);
      nvg("/approvalagentlist/1");
      //   setgrandloading(false)
      //   nvg("/Customerlist/1")
    } catch (error) {
      //   setcustresponse({
      //     statusone: 1,
      //     message: "Something Went Wrong Try again",
      //   });
      setloader(false);
      //   setgrandloading(false)
      //   seterrorcls(error.response.data);
      //   seterrorclass(true);
      //   setloading(false)
      nvg("/approvalagentlist/2");
      // console.log(error, "wert");
      seterrorcls(error.response.data);
    }
  }
  return (
    <div style={{ width: "100%" }}>
      {/* <Header /> */}
      <div className="dashboardcontent px-2">
        {/* <Breadcup name={'Agent'} second={'Approval'} /> */}

        <div className="container-fuild px-2 desgin1">
          <div className="row bg-white ">
            <div
              className="col-lg-12 d-flex justify-content-between py-2"
              style={{
                background: "hsla(210, 85%, 32%, 0.2)",
                color: "#0C5398",
              }}
            >
              <p className="m-0 customfont">Agent Approval Detail</p>
              <div className="addnew d-block mb-2">
                <button className="btn text-white closebtn">
                  <NavLink
                    to="/approvalagentlist/0"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    {" "}
                    x Close
                  </NavLink>
                </button>
              </div>
            </div>
            {/* <div className="col-12 py-3 customtext23" style={{ background: 'hsla(210, 85%, 32%, 0.2)', color: '#0C5398' }}>Edit Agent </div> */}
          </div>
        </div>
        {srtloader == true ? (
          <div className="container-fuild bg-white" style={{position:'relative'}}>
            {" "}
            <div
              className="col-12 d-flex justify-content-center"
              style={{ gap: "4px", position: "absolute", width: "95%" }}
            >
              <div className="px-2">
                {" "}
                <Loadercomp size={100} />
              </div>
            </div>
          </div>
        ) : (
          <form onSubmit={submitform}>
            <div className="container-fuild px-2 pb-4 pt-4 bg-white">
              <div
                className="row bg-white pb-4 round"
                style={{
                  border: "1px solid #E0E0E0",
                  margin: "10px 0px",
                  borderRadius: "3px",
                  position:'relative'
                }}
              >
                {/* <div className="col-xl-6 d-xl-block d-none px-4 pt-3">
                  <div className="row">
                    <div className="col-4 offset-3">
                      <h5 style={{ color: "#0C5398", fontWeight: "700" }}>
                        Kyc First
                      </h5>
                    </div>
                    <div className="col-4 d-flex justify-content-between" style={{ gap: "22px" }}>
                      <h5
                        style={{ color: "#0C5398", fontWeight: "700" }}
                        className=""
                      >
                        Response
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 px-4 pt-3">
                <div className="row">
                                <div className="col-5 offset-3">
                                    <h5 style={{color:'#0C5398',fontWeight:"700"}} >Kyc First</h5>
                                </div>
                                <div className="col-3 d-flex" style={{ gap: '22px' }}>
                                    <h5  style={{color:'#0C5398',fontWeight:"700"}} className=''>Response</h5>
                                </div>
                            </div>
                </div> */}
                  <div className="col-12 px-lg-4 px-md-2 pt-3">
                            <div className="row d-flex justify-content-between">
                                <div className="col">
                                    <h5 style={{color:'#0C5398',fontWeight:"700"}} ></h5>
                                </div>
                                <div className="col">
                                    <h5 style={{color:'#0C5398',fontWeight:"700"}} >Kyc First</h5>
                                </div>
                                <div className="col d-flex justify-content-between" style={{ gap: '22px' }}>
                                    <h5  style={{color:'#0C5398',fontWeight:"700"}} className=''>Response 1</h5>
                                </div>
                                <div className="col d-flex justify-content-between" style={{ gap: '22px' }}>
                                    <h5  style={{color:'#0C5398',fontWeight:"700"}} className=''>Response 2</h5>
                                </div>
                            </div>
                        </div>
                {/* <div className="col-xl-6 px-4 pt-3">
                  <div className="row">
                    <div className="col-3">
                      <label htmlFor="" className="form-label">
                        Username
                      </label>
                    </div>
                    <div className="col-9 d-flex justify-content-between" style={{ gap: "22px" }}>
                      <p className="customcolor">{agentusername}</p>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value={usernamecheck}
                        onChange={(e) => {
                          setusernamecheck(!usernamecheck);
                        }}
                        id="flexCheckChecked"
                        required
                      />
                    </div>
                  </div>
                </div> */}
               <div className="col-12 px-lg-4 px-md-2 pt-3">
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="" className="form-label">Username</label>
                                </div>
                                <div className="col d-flex justify-content-between justify-content-between" style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentusername}</p>
                                   
                                </div>
                                 <div className="col  customcolor"></div> 
                                 <div className="col customcolor"></div> 
                            </div>
                        </div>
                {/* <div className="col-xl-6 px-4 pt-3">
                  <div className="row">
                    <div className="col-3">
                      <label htmlFor="" className="form-label customw">
                        Email {" "}
                      </label>
                    </div>
                    <div className="col-9">
                      <div className="row">       
                    <div className="col-xl-12 col-lg-6 col-12 d-flex" style={{ gap: "22px" }}>
                      <p className="customcolor">{agentemail}</p>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value={emailIDcheck}
                        onChange={(e) => {
                          setemailIDcheck(!emailIDcheck);
                        }}
                        id="flexCheckChecked"
                        required
                      />
                    </div>
                    <div className="col-xl-12 col-lg-6 col-12 customcolor">{kycemail}</div>
                    </div>            
                    </div>
                  </div>
                </div> */}



 <div className="col-12 px-lg-4 px-md-2 pt-3">
                            <div className="row d-flex justify-content-center">
                                <div className="col">
                                    <label htmlFor="" className="form-label">Email </label>
                                </div>
                                {/* <div className="col d-flex justify-content-end " style={{flexWrap:"wrap"}}> */}
                                <div className="col d-flex justify-content-between">
                                    <p className='customcolor'>{agentemail}</p>
                                  
                                </div>
                                
                                <div className="col d-flex customcolor" style={{flex:'wrap'}}>{kycemail}</div>
                                <div className="col d-flex customcolor" style={{flex:'wrap'}}>{kycemail}</div>
                                {/* </div> */}

                            </div>
                        </div> 
                {/* <div className="col-xl-6 px-4 pt-3">
                  <div className="row">
                    <div className="col-3">
                      <label htmlFor="" className="form-label">
                        First Name
                      </label>
                    </div>
                    <div className="col-4 d-flex justify-content-between" style={{ gap: "22px" }}>
                      <p className="customcolor">{agentname}</p>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value={fnamecheck}
                        onChange={(e) => {
                          setfnamecheck(!fnamecheck);
                        }}
                        id="flexCheckChecked"
                        required
                      />
                    </div>
                    <div className="col-4 customcolor">{kycfullname}</div>
                  </div>
                </div> */}


 <div className="col-12 px-lg-4 px-md-2 pt-3">
                            <div className="row d-flex justify-content-center">
                                <div className="col">
                                    <label htmlFor="" className="form-label">First Name</label>
                                </div>
                                <div className="col d-flex justify-content-between justify-content-between" style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentname}</p>
                                  
                                </div>
                               <div className="col customcolor">{kycfullname}</div>
                               <div className="col customcolor">{kycfullnametwo}</div>
                            </div>
                        </div> 
                {/* <div className="col-xl-6 px-4 pt-3">
                  <div className="row">
                    <div className="col-3">
                      <label htmlFor="" className="form-label">
                        Last Name
                      </label>
                    </div>
                    <div className="col-9 d-flex justify-content-between" style={{ gap: "22px" }}>
                      <p className="customcolor">
                        {agentlast != "undefined" ? agentlast : ""}
                      </p>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value={lnamecheck}
                        onChange={(e) => {
                          setlnamecheck(!lnamecheck);
                        }}
                        id="flexCheckChecked"
                        required
                      />
                    </div>
                  </div>
                </div> */}


 <div className="col-12 px-lg-4 px-md-2 pt-3">
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="" className="form-label">Last Name</label>
                                </div>
                                <div className="col d-flex justify-content-between justify-content-between" style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentlast != "undefined" && agentlast != "" ? agentlast : ''}</p>
                                    
                                </div>
                               <div className="col  customcolor"></div> 
                               <div className="col customcolor"></div> 
                            </div>
                        </div> 
                {/* <div className="col-xl-6 px-4 pt-3">
                  <div className="row">
                    <div className="col-3">
                      <label htmlFor="" className="form-label customw">
                        Father Name
                      </label>
                    </div>
                    <div className="col-4 d-flex justify-content-between" style={{ gap: "22px" }}>
                      <p className="customcolor">{ffn}</p>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        required
                      />
                    </div>
                    <div className="col-4 customcolor">{kycfathername}</div>
                  </div>
                </div> */}


<div className="col-12 px-lg-4 px-md-2 pt-3">
                            <div className="row d-flex justify-content-center">
                                <div className="col">
                                    <label htmlFor="" className="form-label ">Father Name</label>
                                </div>
                                <div className="col d-flex justify-content-between" style={{ gap: '22px' }}>
                                    <p className='customcolor'>{ffn}</p>
                                   
                                </div>
                               <div className="col customcolor">{kycfathername}</div>
                               <div className="col customcolor">{kycfathername}</div>
                            </div>
                        </div>

                {/* <div className="col-xl-6 px-4 pt-3">
                  <div className="row">
                    <div className="col-3">
                      <label htmlFor="" className="form-label">
                        Gender
                      </label>
                    </div>
                    <div className="col-4 d-flex justify-content-between" style={{ gap: "22px" }}>
                      <p className="customcolor">{agentgender}</p>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value={gendercheck}
                        onChange={(e) => {
                          setgendercheck(!gendercheck);
                        }}
                        id="flexCheckChecked"
                        required
                      />
                    </div>
                    <div className="col-4 customcolor">{kycgander}</div> 
                  </div>
                </div> */}



 <div className="col-12 px-lg-4 px-md-2 pt-3">
                            <div className="row d-flex justify-content-between">
                                <div className="col">
                                    <label htmlFor="" className="form-label">Gender</label>
                                </div>
                                <div className="col d-flex justify-content-between"  style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentgender}</p>
                             
                                </div>
                                <div className="col customcolor">{kycgander}</div>
                                <div className="col customcolor">{kycgander}</div>
                            </div>
                        </div> 
                {/* <div className="col-xl-6 px-4 pt-3">
                  <div className="row">
                    <div className="col-3">
                      <label htmlFor="" className="form-label">
                        Age
                      </label>
                    </div>
                    <div className="col-4 d-flex justify-content-between" style={{ gap: "22px" }}>
                      <p className="customcolor">{agentage}</p>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        required
                      />
                    </div>
                    <div className="col-4 customcolor">{kycage}</div>
                  </div>
                </div> */}

 <div className="col-12 px-lg-4 px-md-2 pt-3">
                            <div className="row d-flex justify-content-between">
                                <div className="col">
                                    <label htmlFor="" className="form-label">Age</label>
                                </div>
                                <div className="col d-flex justify-content-between" style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentage}</p>
                                </div>
                                <div className="col customcolor">{kycage}</div>
                                <div className="col customcolor">{kycage}</div>
                            </div>
                        </div> 

                {/* <div className="col-xl-6 px-4 pt-3">
                  <div className="row">
                    <div className="col-3">
                      <label htmlFor="" className="form-label">
                        {" "}
                        DOB
                      </label>
                    </div>
                    <div className="col-4 d-flex justify-content-between" style={{ gap: "22px" }}>
                      <p className="customcolor">{dob}</p>
                      <input
                        class="form-check-input"
                        type="checkbox"
                  
                        id="flexCheckChecked"
                        required
                      />
                    </div>
                    <div className="col-4 customcolor">{kycdob}</div>
                  </div>
                </div> */}
          <div className="col-12 px-lg-4 px-md-2 pt-3">
                            <div className="row d-flex justify-content-between">
                                <div className="col">
                                    <label htmlFor="" className="form-label">DOB</label>
                                </div>
                                <div className="col d-flex justify-content-between"  style={{ gap: '22px' }}>
                                    <p className='customcolor'>{crtdate}</p>
                                
                                </div>
                               <div className="col customcolor">{kycdob}</div>
                               <div className="col customcolor">{kycdob}</div>
                            </div>
                        </div>
                {/* <div className="col-xl-6 px-4 pt-3">
                  <div className="row">
                    <div className="col-3">
                      <label htmlFor="" className="form-label">
                        Company
                      </label>
                    </div>
                    <div className="col-9 d-flex justify-content-between" style={{ gap: "22px" }}>
                      <p className="customcolor">{defaultv}</p>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value={companycheck}
                        onChange={(e) => {
                          setcompanycheck(!companycheck);
                        }}
                        id="flexCheckChecked"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 px-4 pt-3">
                  <div className="row">
                    <div className="col-3">
                      <label htmlFor="" className="form-label">
                        Location
                      </label>
                    </div>
                    <div className="col-9 d-flex justify-content-between" style={{ gap: "22px" }}>
                      <p className="customcolor">{defaultvtwo}</p>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value={locationcheck}
                        onChange={(e) => {
                          setlocationcheck(!locationcheck);
                        }}
                        id="flexCheckChecked"
                        required
                      />
                    </div>
                  </div>
                </div> */}




               <div className="col-12 px-lg-4 px-md-2 pt-3">
                            <div className="row d-flex justify-content-center">
                                <div className="col">
                                    <label htmlFor="" className="form-label">Mobile No.</label>
                                </div>
                                <div className="col d-flex justify-content-between" style={{ gap: '12px' }}>
                                    <p className='customcolor'>{agentmobile}</p>
                           
                                </div>
                                 <div className="col customcolor">{kycphone}</div> 
                                 <div className="col customcolor">{kycphone}</div> 
                            </div>
                        </div> 
                {/* <div className="col-xl-6 px-4 pt-3">
                  <div className="row">
                    <div className="col-3">
                      <label htmlFor="" className="form-label">
                        Mobile No.
                      </label>
                    </div>
                    <div className="col-4 d-flex justify-content-between" style={{ gap: "12px" }}>
                      <p className="customcolor">{agentmobile}</p>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value={mobile_nocheck}
                        onChange={(e) => {
                          setmobile_nocheck(!mobile_nocheck);
                        }}
                        id="flexCheckChecked"
                        required
                      />
                    </div>
                    <div className="col-4 customcolor">{kycphone}</div> 
                  </div>
                </div> */}
                {/* <div className="col-xl-6 px-4 pt-3">
                  <div className="row">
                    <div className="col-3">
                      <label htmlFor="" className="form-label">
                        Address
                      </label>
                    </div>
                    <div className="col-4 d-flex justify-content-between" style={{ gap: "22px" }}>
                      <p className="customcolor">{agentaddress}</p>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value={addresscheck}
                        onChange={(e) => {
                          setaddresscheck(!addresscheck);
                        }}
                        id="flexCheckChecked"
                        required
                      />
                    </div>
                    <div className="col-4 customcolor">{kycaddress}</div>
                  </div>
                </div> */}
                 <div className="col-12 px-lg-4 px-md-2 pt-3">
                            <div className="row d-flex justify-content-between">
                                <div className="col">
                                    <label htmlFor="" className="form-label">Address</label>
                                </div>
                                <div className="col d-flex justify-content-between"  style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentaddress}</p>
                               
                                </div>
                                <div className="col customcolor">{kycaddress}</div> 
                                <div className="col customcolor">{kycaddress}</div> 
                            </div>
                        </div> 
                {/* <div className="col-xl-6 px-4 pt-3">
                  <div className="row">
                    <div className="col-3">
                      <label htmlFor="" className="form-label">
                        Country
                      </label>
                    </div>
                    <div className="col-4 d-flex justify-content-between" style={{ gap: "22px" }}>
                      <p className="customcolor">{agentcountry}</p>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value={countrycheck}
                        onChange={(e) => {
                          setcountrycheck(!countrycheck);
                        }}
                        id="flexCheckChecked"
                        required
                      />
                    </div>        
                    <div className="col-4 customcolor">{getName(kyccountry)}</div>
                  </div>
                </div> */}

 <div className="col-12 px-lg-4 px-md-2 pt-3">
                            <div className="row d-flex justify-content-between">
                                <div className="col">
                                    <label htmlFor="" className="form-label">Country</label>
                                </div>
                                <div className="col d-flex justify-content-between"  style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentcountry}</p>
                             
                                </div>
                                <div className="col customcolor">{getName(kyccountry)}</div> 
                                <div className="col customcolor">{getName(kyccountry)}</div> 
                            </div>
                        </div> 

                {/* <div className="col-xl-6 px-4 pt-3">
                  <div className="row">
                    <div className="col-3">
                      <label htmlFor="" className="form-label">
                        State
                      </label>
                    </div>
                    <div className="col-4 d-flex justify-content-between" style={{ gap: "22px" }}>
                      <p className="customcolor">{agentstate}</p>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value={statecheck}
                        onChange={(e) => {
                          setstatecheck(!statecheck);
                        }}
                        id="flexCheckChecked"
                        required
                      />
                    </div>
                    <div className="col-4 customcolor">{kycstate}</div> 
                  </div>
                </div> */}

 <div className="col-12 px-lg-4 px-md-2 pt-3">
                            <div className="row d-flex justify-content-between">
                                <div className="col">
                                    <label htmlFor="" className="form-label">State</label>
                                </div>
                                <div className="col d-flex justify-content-between"  style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentstate}</p>
                            
                                </div>
                                <div className="col customcolor">{kycstate}</div> 
                                <div className="col customcolor">{kycstate}</div> 
                            </div>
                        </div> 
                {/* <div className="col-xl-6 px-4 pt-3">
                  <div className="row">
                    <div className="col-3">
                      <label htmlFor="" className="form-label">
                        City
                      </label>
                    </div>
                    <div className="col-4 d-flex justify-content-between" style={{ gap: "22px" }}>
                      <p className="customcolor">{agentcity}</p>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value={citycheck}
                        onChange={(e) => {
                          setcitycheck(!citycheck);
                        }}
                        id="flexCheckChecked"
                        required
                      />
                    </div>
                    <div className="col-4 customcolor">{kyccity}</div>
                  </div>
                </div> */}
                
 <div className="col-12 px-lg-4 px-md-2 pt-3">
                            <div className="row d-flex justify-content-between">
                                <div className="col">
                                    <label htmlFor="" className="form-label">City</label>
                                </div>
                                <div className="col d-flex justify-content-between"  style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentcity}</p>
                               
                                </div>
                                 <div className="col customcolor">{kyccity}</div> 
                                 <div className="col customcolor">{kyccity}</div> 
                            </div>
                        </div> 
                {/* <div className="col-xl-6 px-4 pt-3">
                  <div className="row">
                    <div className="col-3">
                      <label htmlFor="" className="form-label">
                        Pincode
                      </label>
                    </div>
                    <div className="col-4 d-flex justify-content-between" style={{ gap: "22px" }}>
                      <p className="customcolor">{agentpincode}</p>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value={pincodecheck}
                        onChange={(e) => {
                          setpincodecheck(!pincodecheck);
                        }}
                        id="flexCheckChecked"
                        required
                      />
                    </div>
                    <div className="col-4 customcolor">{kycpincode}</div>
                  </div>
                </div> */}
             <div className="col-12 px-lg-4 px-md-2 pt-3">
                            <div className="row d-flex justify-content-between">
                                <div className="col">
                                    <label htmlFor="" className="form-label">Pincode </label>
                                </div>
                                <div className="col d-flex justify-content-between"  style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentpincode}</p>
                                
                                </div>
                               <div className="col customcolor">{kycpincode}</div> 
                               <div className="col customcolor">{kycpincode}</div> 
                            </div>
                        </div> 
                        <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">Company</label>
                                </div>
                                <div className="col-4 d-flex justify-content-between" style={{ gap: '22px' }}>
                                    <p className='customcolor'>{defaultv}</p>
                               
                                </div>
                            </div>
                        </div>
                     <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">Location</label>
                                </div>
                                <div className="col-4 d-flex justify-content-between" style={{ gap: '22px' }}>
                                    <p className='customcolor'>{defaultvtwo}</p>
                                  
                                </div>
                            </div>
                        </div>
                <div className="col-12 px-4 pt-3">
                  <div className="row">
                    <div className="col-2">
                      <label htmlFor="" className="form-label">
                        Role
                      </label>
                
                    </div>
                    {agentchecker == true ? (
                      <div className="col-3">
                        {" "}
                        <label
                          htmlFor=""
                          className="form-label"
                          style={{ paddingRight: "6px" }}
                        >
                          Checker
                        </label>
                      </div>
                    ) : (
                      ""
                    )}

                    {agentmaker == true ? (
                      <div className="col-3">
                        {" "}
                        <label
                          htmlFor=""
                          className="form-label"
                          style={{ paddingRight: "6px" }}
                        >
                          Maker
                        </label>
                      </div>
                    ) : (
                      ""
                    )}
                    {agentapprover == true ? (
                      <div className="col-3">
                        {" "}
                        <label
                          htmlFor=""
                          className="form-label"
                          style={{ paddingRight: "6px" }}
                        >
                          Approver
                        </label>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <div className="row px-4 pt-3">
                    <div className="col-6">
                      <div className="row">
                        <div className="col-12">
                          <label
                            htmlFor=""
                            className="form-label"
                            style={{ paddingRight: "10px" }}
                          >
                            Biometric Detail
                          </label>{" "}
                     
                        </div>
                        <div className="col-12">
                        <div className="border round py-3 d-flex justify-content-center">
                        <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
     <label htmlFor="">Right Hand</label>
     <img src={thumbimg2 == '' || thumbimg2 == null ? img5 : img4} alt="rtf" style={{position:"absolute",top:"80px",left:"13px"}} />
     <img src={rifimg2 == '' || rifimg2 == null ? img5 : img4} alt="rif" style={{position:"absolute",top:"37px",left:"35px"}} />
     <img src={rmfimg2 == '' || rmfimg2 == null ? img5 : img4} alt="rmf" style={{position:"absolute",top:"30px",left:"65px"}} />
     <img src={rrfimg2 == '' || rrfimg2 == null ? img5 : img4} alt="rrf" style={{position:"absolute",top:"36px",left:"84px"}} />
     <img src={rlfimg2 == '' || rlfimg2 == null ? img5 : img4} alt="rlf" style={{position:"absolute",top:"55px",left:"103px"}} />
                          <img src={img1} alt="qwerty" />
                          </div>
                          <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
<label htmlFor="">Left Hand</label>
<img src={llfimg2 == '' || llfimg2 == null ? img5 : img4} alt="llf" style={{position:"absolute",top:"56px",left:"13px"}} />
     <img src={lrfimg2 == '' || lrfimg2 == null ? img5 : img4} alt="lrf" style={{position:"absolute",top:"36px",left:"33px"}} />
     <img src={lmfimg2 == '' || lmfimg2 == null ? img5 : img4} alt="lmf" style={{position:"absolute",top:"31px",left:"55px"}} />
     <img src={lifimg2 == '' || lifimg2 == null ? img5 : img4} alt="lif" style={{position:"absolute",top:"38px",left:"80px"}} />
     <img src={ltfimg2 == '' || ltfimg2 == null ? img5 : img4} alt="ltf" style={{position:"absolute",top:"80px",left:"102px"}} />
                          <img src={img2} alt="trew" />
                          </div>
                        </div>
                        </div>
                      </div>
                      <div className="col-12 py-2 px-4 d-flex justify-content-between">
                <button
                type='button'
                  // className="btn1"
                  style={{backgroundColor:"#0C5398"}}
                  className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal5"
                >
                  View
                </button>
              </div>
                    </div>
                    <div className="col-6">
                      <div className="row">
                        <div className="col-12">
                          <label
                            htmlFor=""
                            className="form-label"
                            style={{ paddingRight: "10px" }}
                          >
                            Photograph
                          </label>
                         
                        </div>
                        <div className="col-12 ">
                          <div className="border round py-3 d-flex justify-content-center">
                            <div className="row px-2">
                              <div className="col-12 d-flex justify-content-center">
                              <button
                type='button'
                style={{border:'none',outline:'none'}}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModaltwo"
                >
              <img src={photograph == null ? img3 : photograph} alt="zxcvbnm" width="140px" height="162px" />
              </button>
                                {/* <ReactImageMagnify
                                  {...{
                                    smallImage: {
                                      alt: "Wristwatch by Versace",
                                      isFluidWidth: true,
                                      src:
                                        photograph == null ? img3 : photograph,
                                      width: 140,
                                      height: 162,
                                    },
                                    largeImage: {
                                      src:
                                        photograph == null ? img3 : photograph,
                                      width: 1200,
                                      height: 1800,
                                    },
                                    // enlargedImagePosition: "over",
                                    lensStyle: {
                                      backgroundColor: "rgba(0,0,0,.6)",
                                    },
                                  }}
                                /> */}
                              </div>
                              {/* <div className="col-6 d-flex justify-content-center">
                              <button
                type='button'
                style={{border:'none',outline:'none'}}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalthree"
                >
              <img src={`data:image/png;base64,${kycphoto}`} alt="zxcvbnm" width="140px" height="162px" />
              </button>
                               
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 ccolor py-3 px-4">
                      Document{" "}
                    
                    </div>
                    <div
                      className="col-lg-6 px-4"
                      style={{ paddingTop: "28px" }}
                    >
                      <div className="row">
                        <div className="col-4">
                          <label htmlFor="" className="form-label">
                            Document Type
                          </label>
                        </div>
                        <div className="col-8 d-flex justify-content-end" style={{ gap: "22px" }}>
                          <p className="customcolor">{documenttype}</p>
                       
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-6 px-4"
                      style={{ paddingTop: "28px" }}
                    >
                      <div className="row">
                        <div className="col-4">
                          <label htmlFor="" className="form-label">
                            Document No.
                          </label>
                          
                        </div>
                        <div className="col-8 d-flex justify-content-end" style={{ gap: "22px" }}>
                          <p className="customcolor">{idnumber}</p>
                         
                        </div>
                      </div>
                    </div>
                    {documenttype == "DRIVING_LICENSE" ||
                    documenttype == "PASSPORT" ? (
                      <div className="col-xl-6 px-4 pt-3">
                        <div className="row">
                          <div className="col-3">
                            <label htmlFor="" className="form-label">
                              Expiry date{" "}
                            </label>
                          </div>
                          <div className="col-9 d-flex justify-content-end" style={{ gap: "22px" }}>
                            <p className="customcolor">{documentexpiry}</p>
                          
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {documenttype == "DRIVING_LICENSE" ||
                    documenttype == "PASSPORT" ? (
                      <div className="col-xl-6 px-4 pt-3">
                        <div className="row">
                          <div className="col-3">
                            <label htmlFor="" className="form-label">
                              Issue date{" "}
                            </label>
                          </div>
                          <div className="col-9 d-flex justify-content-end" style={{ gap: "22px" }}>
                            <p className="customcolor">{documentissue}</p>
                          
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                                            <div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        Document Front Image
      </div>
      <div className="col-lg-8">
      <img src={aadhaarImage} alt="Modified Aadhaar Card" style={{width:"100%",height:"100%"}} />
      </div>
      <div className=" offset-lg-4 col-lg-8">
      <button
style={{backgroundColor:"#0C5398",marginTop:"5px"}}
type='button'
className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal34"
                >
View
                </button>
      </div>
    </div>
  </div>
  <div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        Document Back Image
      </div>
      <div className="col-lg-8">
      <img src={documentimagebacktwo} alt="Modified Aadhaar Card" style={{width:"100%",height:"100%"}} />
      </div>
      <div className=" offset-lg-4 col-lg-8">
      <button
style={{backgroundColor:"#0C5398",marginTop:"5px"}}
type='button'
className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal35"
                >
View
                </button>
      </div>
    </div>
  </div>

                    {loader == true ? (
                      <div
                        className="col-12 d-flex justify-content-center"
                        style={{
                          gap: "4px",
                          position: "absolute",
                          width: "95%",
                        }}
                      >
                        <div className="px-2">
                          {loader == true ? <Loadercomp size={100} /> : ""}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* <div className="col-xl-6 px-4 pt-3">
                      <div className="row">
                        <div className="col-3">
                          <label htmlFor="" className="form-label">
                            Full Name{" "}
                          </label>
                        </div>
                        <div className="col-9 d-flex justify-content-between" style={{ gap: "22px" }}>
                          <p className="customcolor">{kycfullname}</p>
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="2023-04-12"
                            id="flexCheckChecked"
                            required
                          />
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="col-xl-6 px-4 pt-3">
                      <div className="row">
                        <div className="col-3">
                          <label htmlFor="" className="form-label">
                            Father Name{" "}
                          </label>
                        </div>
                        <div className="col-9 d-flex justify-content-between" style={{ gap: "22px" }}>
                          <p className="customcolor">{kycfathername}</p>
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="2023-04-12"
                     
                            id="flexCheckChecked"
                            required
                          />
                        </div>
                      </div>
                    </div> */}

                    {/* <div className="col-xl-6 px-4 pt-3">
                      <div className="row">
                        <div className="col-3">
                          <label htmlFor="" className="form-label">
                            Age{" "}
                          </label>
                        </div>
                        <div className="col-9 d-flex justify-content-between" style={{ gap: "22px" }}>
                          <p className="customcolor">{kycage}</p>
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="2023-04-12"
                            id="flexCheckChecked"
                            required
                          />
                        </div>
                      </div>
                    </div> */}
{/* 
                    <div className="col-xl-6 px-4" style={{ paddingTop: "20px" }}>
                      <div className="row">
                        <div className="col-12">
                          <label
                            htmlFor=""
                            className="form-label"
                            style={{ paddingRight: "10px" }}
                          >
                            Image
                          </label>
                          <input
                            class="form-check-input"
                            type="checkbox"
                            required
                          />
                        </div>
                        <div className="col-12 ">
                          <div className="border round py-3 d-flex justify-content-center">
                            <ReactImageMagnify
                              {...{
                                smallImage: {
                                  alt: "Wristwatch by Versace",
                                  isFluidWidth: true,
                                  src: `data:image/png;base64,${kycphoto}`,
                                  width: 140,
                                  height: 162,
                                },
                                largeImage: {
                                  src: `data:image/png;base64,${kycphoto}`,
                                  width: 1200,
                                  height: 1800,
                                },
                                enlargedImagePosition: "over",
                                lensStyle: {
                                  backgroundColor: "rgba(0,0,0,.6)",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div className="col-12 py-3 px-4">
                      <div
                        style={{
                          background: "#D9D9D9",
                          height: "1px",
                          width: "100%",
                        }}
                      ></div>
                    </div>

                    
                    <div className="col-12 ccolor py-3 px-4">Document 2  </div>
                                
                                <div className="col-lg-6 px-4" style={{ paddingTop: "28px" }}>
      <div className="row">
        <div className="col-4">
          <label htmlFor="" className="form-label">
          Document Type 
          </label>
        </div>
        <div className="col-8 d-flex justify-content-end" style={{ gap: '22px' }}>
  
  
  
  
  
                                      <p className='customcolor'>{documenttypetwo}</p>
                                     
       
        </div>
      </div>
    </div>
    <div className="col-lg-6 px-4" style={{ paddingTop: "28px" }}>
      <div className="row">
        <div className="col-4">
          <label htmlFor="" className="form-label">
          Document No.
          </label>
        </div>
        <div className="col-8 d-flex justify-content-end" style={{ gap: '22px' }}>
        <p className='customcolor'>{idnumbertwo}</p>
                        
  
        </div>
      </div>
    </div>
  
    {documenttypetwo == 'DRIVING_LICENSE' || documenttypetwo == "RESIDENT_PERMIT" || documenttypetwo == "VISA" || documenttypetwo == "REFUGEE_CARD" || documenttypetwo == 'PASSPORT' ? <div className="col-xl-6 px-lg-4 px-2 pt-3">
                              <div className="row">
                                  <div className="col-3">
                                      <label htmlFor="" className="form-label">Expiry date </label>
                                  </div>
                                  <div className="col-9 d-flex justify-content-end" style={{ gap: '22px' }}>
                                      <p className='customcolor'>{documentexpirytwo}</p>
                                      
                                  </div>
                              </div>
                          </div> : ''}
                          {documenttypetwo == 'DRIVING_LICENSE' || documenttypetwo == "RESIDENT_PERMIT" || documenttypetwo == "VISA" || documenttypetwo == "REFUGEE_CARD" || documenttypetwo == 'PASSPORT' ? <div className="col-xl-6 px-lg-4 px-2 pt-3">
                              <div className="row">
                                  <div className="col-3">
                                      <label htmlFor="" className="form-label">Issue date </label>
                                  </div>
                                  <div className="col-9 d-flex justify-content-end" style={{ gap: '22px' }}>
                                      <p className='customcolor'>{documentissuetwo}</p>
                                    
                                  </div>
                              </div>
                          </div> : ''}
                          <div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
      <div className="row">
        <div className="col-lg-4">
          Document Front Image
        </div>
        <div className="col-lg-8">
        <img src={modifiedImagenumbertwo} alt="Modified Aadhaar Card" style={{width:"100%",height:"100%"}} />
        </div>
        <div className=" offset-lg-4 col-lg-8">
        <button
  style={{backgroundColor:"#0C5398",marginTop:"5px"}}
  type='button'
  className='btn btn-primary'
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal3400"
                  >
  View
                  </button>
        </div>
      </div>
    </div>
    <div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
      <div className="row">
        <div className="col-lg-4">
          Document Back Image
        </div>
        <div className="col-lg-8">
        <img src={documentimageback2} alt="Modified Aadhaar Card" style={{width:"100%",height:"100%"}} />
        </div>
        <div className=" offset-lg-4 col-lg-8">
        <button
  style={{backgroundColor:"#0C5398",marginTop:"5px"}}
  type='button'
  className='btn btn-primary'
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal356"
                  >
  View
                  </button>
        </div>
      </div>
    </div>
                    <div className="col-12 py-3 px-4 d-flex justify-content-end">
                      {/* <div
                        className=" d-flex justify-content-end"
                        style={{ gap: "4px" }}
                      >
                        <button className="btn4">Cancel</button>
                        <button type="submit" className="btn5">
                          Approved
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="exampleModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered ">
                  <div className="modal-content">
                    <BsQuestionLg className="question-logo" />
                    {/* <i class="fa fa-exclamation" aria-hidden="true"></i> */}
                    <div className="modal-header mod-line">
                      {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="row gy-3 mt-2">
                        <h1 className="ccedit-h">Confirm Submission</h1>
                        <p className="ccedit-p">
                          Are you sure you want to submit all the selected data?
                          After submission you won’t be able to edit the list
                        </p>
                      </div>
                    </div>
                    <div className="modal-footer mod-line m-auto">
                      <button type="button" className="btn closecancel">
                        Cancel
                      </button>
                      <button type="button" className="btn closebtn text-white">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
      <div className="modal fade" id="exampleModalthree" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>
                <div className="col-12 px-2 pt-3">
  <img src={`data:image/png;base64,${kycphoto}`} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
              </div>
            </div>
          </div>  




          <div className="modal fade" id="exampleModal34" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>


  
  <div className="col-12 px-2 pt-3">
  <img src={aadhaarImage == null ? img3 : aadhaarImage} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>
          <div className="modal fade" id="exampleModal3400" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>


  
  <div className="col-12 px-2 pt-3">
  <img src={modifiedImagenumbertwo == null ? img3 : modifiedImagenumbertwo} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>


          <div className="modal fade" id="exampleModal35" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>


  
  <div className="col-12 px-2 pt-3">
  <img src={documentimagebacktwo == null ? img3 : documentimagebacktwo} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>
          <div className="modal fade" id="exampleModal356" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>


  
  <div className="col-12 px-2 pt-3">
  <img src={documentimageback2 == null ? img3 : documentimageback2} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>
      <div className="modal fade" id="exampleModaltwo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>
                <div className="col-12 px-2 pt-3">
  <img src={photograph == null ? img3 : photograph} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
              </div>
            </div>
          </div>  
      <div className="modal fade" id="exampleModal5" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>

<div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="tf2" className="form-label ">Right Thumb  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" class="form-control" id='tf2' name="image2" style={{opacity:"0",position:'absolute'}}  onChange={(e) => {handleImageChange11(e)}}  /> */}
        {/* <label htmlFor="tf"> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: thumbimg2 != '' ? '' : '3rem',width:"20%" }}> {thumbimg2 != '' ? <img src={`data:image/png;base64,${thumbimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>

      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="ltf2" className="form-label ">Left Thumb  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="ltf2" onChange={(e) => {handleImageChange16(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: ltfimg2 != '' ? '' : '3rem',width:"20%" }}> {ltfimg2 != '' ? <img src={`data:image/png;base64,${ltfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rif2" className="form-label ">Right Index Finger <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rif2" onChange={(e) => {handleImageChange12(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rifimg2 != '' ? '' : '3rem',width:"20%" }}> {rifimg2 != '' ? <img src={`data:image/png;base64,${rifimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="lif2" className="form-label ">Left Index Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lif2" onChange={(e) => {handleImageChange17(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lifimg2 != '' ? '' : '3rem',width:"20%" }}> {lifimg2 != '' ? <img src={`data:image/png;base64,${lifimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rmf2" className="form-label ">Right Middle Finger<span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rmf2" onChange={(e) => {handleImageChange13(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rmfimg2 != '' ? '' : '3rem',width:"20%" }}> {rmfimg2 != '' ? <img src={`data:image/png;base64,${rmfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      </div>
    </div>

  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="lmf2" className="form-label ">Left Middle Finger <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lmf2" onChange={(e) => {handleImageChange18(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lmfimg2 != '' ? '' : '3rem',width:"20%" }}> {lmfimg2 != '' ? <img src={`data:image/png;base64,${lmfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
        
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rrf2" className="form-label ">Right Ring Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rrf2" onChange={(e) => {handleImageChange14(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rrfimg2 != '' ? '' : '3rem',width:"20%" }}> {rrfimg2 != '' ? <img src={`data:image/png;base64,${rrfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
       
      </div>
    </div>

  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="lrf2" className="form-label ">Left Ring Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lrf2" onChange={(e) => {handleImageChange19(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lrfimg2 != '' ? '' : '3rem',width:"20%" }}> {lrfimg2 != '' ? <img src={`data:image/png;base64,${lrfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
        
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rlf2" className="form-label ">Right Little Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rlf2" onChange={(e) => {handleImageChange15(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rlfimg2 != '' ? '' : '3rem',width:"20%" }}> {rlfimg2 != '' ? <img src={`data:image/png;base64,${rlfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
     
      </div>
    </div>
  </div>


 
 
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="llf2" className="form-label ">Left Little Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="llf2" onChange={(e) => {handleImageChange20(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: llfimg2 != '' ? '' : '3rem',width:"20%" }}> {llfimg2 != '' ? <img src={`data:image/png;base64,${llfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      
      </div>
    </div>
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>
    </div>
  );
};

export default Aagentdetail;
