import Header from "../../components/Header";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Switch } from '@mui/material';
import { gettoken } from "../../Localstorage/Store";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Loadercomp from "../../components/Loadercomp";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import csc from 'country-state-city';
import { getCode } from 'country-list';
import Select from "react-select";


const Editlocation = () => {
  const { id } = useParams();
  const nvg = useNavigate();
  const selectoption = useRef();
  const [srtloader, setsrtloader] = useState(true);
  const [defaultv, setdefaultv] = useState("");
  const [lname, setLname] = useState("");
  const [lbranch, setLbranch] = useState("");
  const [lemail, setLemail] = useState("");
  const [lmob, setLmob] = useState("");
  const [ladd, setLadd] = useState("");
  const[lat,setlat] = useState('')
  const[long,setlong] = useState('')
  const [lcountry, setLcountry] = useState("");
  const [lstate, setLstate] = useState("");
  const [lcity, setLcity] = useState("");
  const [lpincode, setLpincode] = useState("");
  const [lclass, setLclass] = useState("");
  const [specialcateerror, setspecialcateerror] = useState(false);
  const [specialcateerrortxt, setspecialcateerrortxt] = useState("");
  const [llocation, setLocation] = useState({});
  const [data, setData] = useState([]);
  const [errorcls, seterrorcls] = useState({});
  const gettokenvalue = gettoken();
  const [loading, setloading] = useState(false);
  const [stateerrormsg, setstateerrormsg] = useState(null);
  const [cityerrormsg, setcityerrormsg] = useState(null);
  const [isstate, setisstate] = useState(false);
  const [iscity, setiscity] = useState(false);
  const [status, setstatus] = useState(false)
  useEffect(() => {
    async function fetchData() {
      const config = {
        headers: {
          Authorization: `Bearer ${gettokenvalue.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}api/location/${id}/`;
      const response = await axios.get(url, config);
      // setdefaultv(response.data.company);
      // setLname(response.data.company)
      setLbranch(response.data.branch_name);
      setLemail(response.data.emailID);
      setLmob(response.data.mobile_no);
      // setLmob(response.data.mobile_no.substring(3));
      setLadd(response.data.address);
      setlong(response.data.longitude);
      setlat(response.data.latitude);
      setLcountry(response.data.country);
      setLstate(response.data.state);
      setLcity(response.data.city);
      setLpincode(response.data.pincode);
      setstatus(response.data.status)
      setsrtloader(false)
    }
    fetchData();
  }, []);




  const [error, setError] = useState(null);
  const [pineror, setpineror] = useState('');
  const [stateerror, setstateerror] = useState('');
  const [cityerror, setcityerror] = useState('');


  useEffect(()=>{
    if(gettokenvalue.staff == false){
      nvg('/dashboard')
    }
  })


  const handleSubmit = () => {
    if(lcountry !== undefined || lcountry !== ''){
      setError(null);
      let createcountry;
      if(lcountry.sortname){
        createcountry = lcountry.sortname;
      }else{
        createcountry = getCode(lcountry)
      }
  
      axios.get(`https://api.zippopotam.us/${createcountry}/${lpincode}`)
        .then((response) => {
          // console.log(response)
          setLstate(response.data.places[0].state)
          setLcity(response.data.places[0]['place name'])
          setpineror('')
          setstateerror('')
          setcityerror('')
        })
        .catch((error) => {
          setError('Failed to fetch location data. Please check the pincode.');
          // console.log(error.response)
          setpineror('Please Check Your Country and Pincode')
        });
    }
  
  };
  
  
  
  const countryfocus = () =>{
    if(lcountry == undefined || lcountry == ''){
      setpineror('Please Select Country First')
    }else{
      setpineror('')
    }
  }






  const countries = csc.getAllCountries();

  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.id,
    ...country
  }));

  const defaultValue = updatedCountries.find((option) => option.label === lcountry);
  let defaultste;
  let defaultrelste;
  let defautcity;
  let defautrealcity;
  let testone;

  if(defaultValue){
     defaultste = csc.getStatesOfCountry(defaultValue.value).map((state) => ({ label: state.name, value: state.id, ...state }));
     defaultrelste = defaultste.find((option) => option.label === lstate);
    }
     if(defaultrelste){
       defautcity = csc.getCitiesOfState(defaultrelste.value).map((city) => ({ label: city.name, value: city.id, ...city }));
       defautrealcity = defautcity.find((option) => option.label === lcity);
      }


  const updatedStates = (countryId) =>
    csc.getStatesOfCountry(countryId)
    .map((state) => ({ label: state.name, value: state.id, ...state }));
  const updatedCities = (stateId) =>
  
    csc.getCitiesOfState(stateId)
    .map((city) => ({ label: city.name, value: city.id, ...city }));

     if(defaultrelste){
       defautcity = csc.getCitiesOfState(defaultrelste.value).map((city) => ({ label: city.name, value: city.id, ...city }));
       defautrealcity = defautcity.find((option) => option.label === lcity);
       testone = defautcity.find((option) => option.label === "shre");
      //  console.log("355544wertyyy",defautrealcity)
      //  console.log("355544",testone)


      }




  // company dropdown here ---->start
  useEffect(() => {
    async function fetchData() {
      const config = {
        headers: {
          Authorization: `Bearer ${gettokenvalue.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}api/active_company/`;
      const response = await axios.get(url, config);
      setData(response.data);
    }
    fetchData();
  }, [setData]);
  // company dropdown here ---->end

  // update location detail -------->start
  async function savebtn(e) {
    e.preventDefault();
    if(lstate == undefined || lstate == '' || lcity == undefined || lcity == '' || lcity == [] || lstate == []){
      if(lstate == undefined || lstate == ''){
        setstateerror('State is Required')
      }else{
        setstateerror('')
      }
      if(lcity == undefined || lcity == ''){
        setcityerror('City is Required')
      }else{
        setcityerror('')
      }
    }else{
    setloading(true)
    const formdata = new FormData();
    // if (lname == "") {
      // formdata.append("company", selectoption.current.value);
      // console.log(selectoption.current.value);
    // } else {
      // formdata.append("company", lname);
    // }

    formdata.append('longitude',long)
    formdata.append('latitude',lat)
    formdata.append("branch_name", lbranch);
    formdata.append("emailID", lemail);
    formdata.append("mobile_no",lmob);
    formdata.append("address", ladd);
    formdata.append('status',status)
    if(lcountry.label){
      formdata.append("country", lcountry.label);
    }
    if(lstate.label){
      formdata.append("state", lstate.label);
    }
    if(lcity.label){
      formdata.append("city", lcity.label);
    }
    formdata.append("pincode", lpincode);

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${gettokenvalue.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}api/location/${id}/`;
      const response = await axios.patch(url, formdata, config);
      setLocation(response);
      setloading(false)
    } catch (error) {
      setLocation({
        statusone: 1,
        message: "Something Went Wrong Try again ",
        erorrmas: error,
      });
      seterrorcls(error.response.data);
      setspecialcateerror(true);
      setloading(false)
    }
}
  }
  // update location detail -------->end

  return (
    <div style={{ width: "100%" }}>
      {/* <Header /> */}
      <div className="dashboardcontent px-2">
        {/* <Breadcup name={'Location'} /> */}
        <div className="container-fuild px-2 desgin1">
          <div className="row bg-white ">
            {/* <div className="col-12 py-3 customtext23" style={{ background: 'hsla(210, 85%, 32%, 0.2)', color: '#0C5398' }}>Add Location</div> */}
            <div
              className="col-lg-12 d-flex justify-content-between py-2"
              style={{
                background: "hsla(210, 85%, 32%, 0.2)",
                color: "#0C5398",
              }}
            >
              <p className="m-0 customfont">Edit Agency </p>
              <div className="addnew d-block mb-2">
                <button className="btn text-white closebtn">
                  <NavLink
                    to="/agencylist/0"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    {" "}
                    x Close
                  </NavLink>
                </button>
              </div>
            </div>
          </div>
        </div>
        {srtloader == true ? <div className="container-fuild bg-white"> <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"100%"}}>
        <div className='px-2'> <Loadercomp size={100} /></div>
        </div></div> : <div className="container-fuild pb-4 pt-3 px-2 bg-white">
          <form onSubmit={savebtn}>
            <div
              className="row bg-white pb-4 round"
              style={{
                border: "1px solid #E0E0E0",
                margin: "10px 0px",
                borderRadius: "3px",
                position:'relative'
              }}
            >
              {/* <div className="col-md-6 px-3" style={{ paddingTop: "29px" }}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Company <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      value={lname}
                      placeholder="Company Name"
                      onChange={(e) => {
                        setLname(e.target.value);
                      }}
                    >
                      {data.map((item) =>
                        item.company_name === defaultv ? (
                          <option
                            key={item.company_id}
                            value={item.company_id}
                            ref={selectoption}
                          >
                            {item.company_name}
                          </option>
                        ) : (
                          ""
                        )
                      )}
                      {data.map((item) =>
                        item.company_name != defaultv ? (
                          <option key={item.company_id} value={item.company_id}>
                            {item.company_name}
                          </option>
                        ) : (
                          ""
                        )
                      )}
                    </select>
                    {specialcateerror === true ? (
                      <span style={{ color: "red" }}>
                        {specialcateerrortxt}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div> */}
              <div className="col-md-6 px-3" style={{ paddingTop: "29px" }}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label customw">
                      Branch Name <span style={{ color: "red" }}>*</span>{" "}
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      className="form-control"
                      value={lbranch}
                      placeholder="Branch Name"
                      readOnly
                      onChange={(e) => {
                        setLbranch(e.target.value);
                      }}
                      required
                    />
                    {errorcls ? (
                      errorcls.branch_name ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.branch_name[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-3" style={{ paddingTop: "29px" }}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Email <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="email"
                      className="form-control"
                      value={lemail}
                      placeholder="Email Address"
                      onChange={(e) => {
                        setLemail(e.target.value);
                      }}
                      required
                    />
                    {errorcls ? (
                      errorcls.emailID ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.emailID[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-3" style={{ paddingTop: "29px" }}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Mobile No. <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    {/* <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">+91</span>
                      </div>
                      <input
                        type="tel"
                        className="form-control"
                        minLength={10}
                        maxLength={10}
                        value={lmob}
                        id="mobileNumber"
                        name="mobileNumber"
                        placeholder="Enter mobile number"
                        onChange={(e) => {
                          setLmob(e.target.value.replace(/[^0-9\ ]/gi, ""));
                        }}
                        required
                      />
                    </div> */}
                                       <PhoneInput
placeholder="Enter phone number"
className="form-control"
value={lmob}
onChange={setLmob}
required
/>
                    {errorcls ? (
                      errorcls.mobile_no ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.mobile_no[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>


              {/* <div className="col-md-6 px-3" style={{paddingTop:'29px'}}>
                            <div className="row">
                                <div className="col-lg-3">
                                    <label htmlFor="" className="form-label">Latitude <span style={{ color: 'red' }}>*</span></label>
                                </div>
                                <div className="col-lg-9">
                                    <input type="text" className="form-control" value={lat} placeholder='Latitude' onChange={(e) => { setlat(e.target.value) }} required />
                                    {errorcls ? errorcls.latitude ? <span style={{color:'red'}}> {errorcls.latitude[0]} </span> : '' : ''}
                                </div>
                            </div>
                        </div>


                        <div className="col-md-6 px-3" style={{paddingTop:'29px'}}>
                            <div className="row">
                                <div className="col-lg-3">
                                    <label htmlFor="" className="form-label">Longitude <span style={{ color: 'red' }}>*</span></label>
                                </div>
                                <div className="col-lg-9">
                                    <input type="text" className="form-control" value={long} placeholder='Longitude' onChange={(e) => { setlong(e.target.value) }} required />
                                    {errorcls ? errorcls.longitude ? <span style={{color:'red'}}> {errorcls.longitude[0]} </span> : '' : ''}
                                </div>
                            </div>
                        </div> */}
      
              <div className="col-md-6 px-3" style={{ paddingTop: "29px" }}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Country <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    {/* <input
                      type="text"
                      className="form-control"
                      value={lcountry}
                      placeholder="Country "
                      onChange={(e) => {
                        setLcountry(e.target.value);
                      }}
                      required
                    /> */}
                        <Select
               placeholder="Select Country"
               options={updatedCountries}
               value={defaultValue}
               onChange={(e) => {
                 setLcountry(e);setLstate([]);setLcity([]);setisstate(true);
               }}
               required
             />
                    {errorcls ? (
                      errorcls.country ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.country[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-3" style={{ paddingTop: "29px" }}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Pincode <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="tel"
                      className="form-control"
                      minLength={6}
                      maxLength={6}
                      value={lpincode}
                      placeholder="Pincode"
                      onChange={(e) => {
                        setLpincode(e.target.value.replace(/[^0-9\ ]/gi, ""));
                      }}
                      required
                    />
                    {errorcls ? (
                      errorcls.pincode ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.pincode[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {/* <div class="input-group ">
  <input type="tel" class="form-control" placeholder="Enter Pincode" style={{border:"1px solid hsl(0, 0%, 80%)"}} maxLength={6} onFocus={countryfocus} 
                  minLength={6} value={lpincode}  onChange={(e) => {setLpincode(e.target.value.replace(/[^0-9]/gi, ""));setLstate('');setLcity('')}} required aria-label="Recipient's username" aria-describedby="button-addon2" />
  <button type="button" class="btn btn-outline-secondary" onClick={handleSubmit} style={{ textDecoration: 'none', color: 'white',backgroundColor:"#165a99" }} id="button-addon2">Search</button>
</div>
{errorcls ? errorcls.pincode ? <span style={{color:'red'}}> {errorcls.pincode[0]} </span> : '' : ''}
{pineror !== '' ? <span style={{color:'red'}}> {pineror} </span> : ''} */}
                  </div>
                </div>
              </div>
        
              <div className="col-md-6 px-3" style={{ paddingTop: "29px" }}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      State <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    {/* <input
                      type="text"
                      className="form-control"
                      value={lstate}
                      placeholder="State "
                      readOnly
                      required
                    /> */}
                    
                    {/* <input
                      type="text"
                      className="form-control"
                      value={lstate}
                      placeholder="State "
                      onChange={(e) => {
                        setLstate(e.target.value);
                      }}
                      required
                    /> */}
                     {lstate != {} ?  <Select
          id="state"
          name="state"
          value={defaultrelste}
          options={updatedStates(lcountry.value ? lcountry.value : defaultValue ? defaultValue.value : null)}
          placeholder="Select State"
          onChange={(e) => {
            setLstate(e);setLcity([]);setiscity(true);setisstate(false);
          }}
          required
        /> :  <Select
        id="state"
        name="state"
        options={updatedStates(lcountry.value ? lcountry.value : defaultValue.value)}
        placeholder="Select State"
        onChange={(e) => {
          setLstate(e);setLcity([]);setiscity(true);setisstate(false);
        }}
        required
      />}
                    {stateerror !== '' ? <span style={{color:'red'}}> {stateerror} </span> : '' }
               {isstate == true ? stateerrormsg !== null ? <span style={{color:'red'}}> {stateerrormsg} </span> : '' : ''}          
                    {errorcls ? (
                      errorcls.state ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.state[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-3" style={{ paddingTop: "29px" }}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      City <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    {/* <input
                      type="text"
                      className="form-control"
                      value={lcity}
                      placeholder="City"
                     readOnly
                      required
                    /> */}
                    {/* <input
                      type="text"
                      className="form-control"
                      value={lcity}
                      placeholder="City"
                      onChange={(e) => {
                        setLcity(e.target.value);
                      }}
                      required
                    /> */}
                    {lcity != {} ?  <Select
          id="city"
          name="city"
          value={defautrealcity}
          options={defaultrelste ? updatedCities(defaultrelste.value): updatedCities(lstate.value)}
          placeholder="Select city"
          onChange={(e) => {
            setLcity(e);setiscity(false);
          }}
          required
        /> :  <Select
        id="city"
        name="city"
        options={updatedCities(lstate.value ? lstate.value : defaultrelste.value)}
        placeholder="Select city"
        onChange={(e) => {
          setLcity(e);setiscity(false);
        }}
        required
      />}
              {cityerror !== '' ? <span style={{color:'red'}}> {cityerror} </span> : ''}
               {iscity == true ? cityerrormsg !== null ? <span style={{color:'red'}}> {cityerrormsg} </span> : '' : ''}          
                    {errorcls ? (
                      errorcls.city ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.city[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-3" style={{ paddingTop: "29px" }}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Address <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      className="form-control"
                      value={ladd}
                      placeholder="Address"
                      onChange={(e) => {
                        setLadd(e.target.value);
                      }}
                      required
                    />
                    {errorcls ? (
                      errorcls.Address ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.Address[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              {gettokenvalue.superuser == true ? <div className="col-md-6 px-3 pt-md-5 pt-4">
          <div className="row">
            <div className="col-lg-3">
              <label htmlFor="" className="form-label">Status <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-9">
            {/* <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" /> */}
            {status == true ? <Switch 
            // handleDiameter={30}
            // uncheckedIcon={false}
            // checkedIcon={false} height={20}
            // width={48}
            // className="react-switch"
            // id="material-switch" boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            // activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
             value={status} name='status' onChange={(e) => {setstatus(!status)}} checked /> : <Switch

             value={status} name='status' onChange={(e) => {setstatus(!status)}} />}
            </div>
          </div>
        </div> : ''}
              {/* <div className="col-md-6 px-3" style={{paddingTop:'29px'}}>
                            <div className="row">
                                <div className="col-lg-3">
                                    <label htmlFor="" className="form-label">Company Logo <span style={{ color: 'red' }}>*</span></label>
                                </div>
                                <div className="col-lg-9">
                                    <input type="file" className="form-control" placeholder='Company Logo' />
                                </div>
                            </div>
                        </div> */}
              {/* <div className="col-md-6 px-3" style={{paddingTop:'29px'}}>
                            <div className="row">
                                <div className="col-lg-3">
                                    <label htmlFor="" className="form-label">Status <span style={{ color: 'red' }}>*</span></label>
                                </div>
                                <div className="col-lg-9">
                                    <Switch />
                                </div>
                            </div>
                        </div> */}
                        {loading == true ? <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"100%",padding : "132px 0px"}}>
        <div className='px-2'>{loading == true ?  <Loadercomp size={100}  /> : ""}</div>
        </div> : <div> </div>}
              <div className="col-12">
                <div className="row">
                  <div
                    className="col-12 py-5 px-4 d-flex justify-content-end"
                    style={{ gap: "4px" }}
                  >
                      {/* <div className='px-2'>{loading == true ?  <Loadercomp /> : ""}</div> */}
                    <button type="reset" className="btn4">
                      Cancel
                    </button>
                    <button type="submit" className="btn5">
                      Save
                    </button>
                  </div>
                </div>
              </div>
              {llocation.status === 200 ? nvg("/agencylist/2") : <div></div>}
            </div>
          </form>
        </div> }
      </div>
    </div>
  );
};

export default Editlocation;
