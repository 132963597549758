import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import img1 from '../../assets/finger.png';
import img6 from '../../assets/Group 41.png';
import img2 from '../../assets/Group 40.png';
import img3 from '../../assets/asdfg.png';
import img4 from "../../assets/abc.png";
import img690 from "../../assets/sign.jpg";
import img5 from "../../assets/cba.png";
import Select from "react-select";
import checkicon from '../../assets/Ellipse 28.png';
import axios from 'axios';
import { gettoken } from '../../Localstorage/Store';
import { NavLink, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import {css} from "@emotion/react";
import Loadercomp from '../../components/Loadercomp';
import ReactImageMagnify from 'react-image-magnify';
import { useRef } from 'react';
import Webcam from 'react-webcam';
import {CaptureFinger,MatchFinger, VerifyFinger} from "../mfs100";
import { FaPray } from 'react-icons/fa';
import csc from 'country-state-city';
const Addremittance = () => {
  const nvg = useNavigate();
  const pickimg = useRef(null)
  const webcamRefs = [useRef(null), useRef(null), useRef(null) ];
  const [hideorshow, sethideorshow] = useState(false);
  const [data, setData] = useState([]);
  const [cussupernumber, setcussupernumber] = useState([]);
  const [cussuperemail, setcussuperemail] = useState([]);
  const [cussupername, setcussupername] = useState([]);
  const [cussuperid, setcussuperid] = useState([]);
  const [datatwo, setDatatwo] = useState([]);
  const [formdatao, setformDatao] = useState({});
  const [formdatao2, setformDatao2] = useState({});
  const [thumbimg, setthumbimg] = useState('');
  const [rifimg, setrifimg] = useState('');
  const [rmfimg, setrmfimg] = useState('');
  const [rrfimg, setrrfimg] = useState('');
  const [rlfimg, setrlfimg] = useState('');
  const [ltfimg, setltfimg] = useState('');
  const [lifimg, setlifimg] = useState('');
  const [lmfimg, setlmfimg] = useState('');
  const [lrfimg, setlrfimg] = useState('');
  const [llfimg, setllfimg] = useState('');
  const [thumberror, setthumberror] = useState(false);
  const [riferror, setriferror] = useState(false);
  const [rmferror, setrmferror] = useState(false);
  const [rrferror, setrrferror] = useState(false);
  const [rlferror, setrlferror] = useState(false);
  const [ltferror, setltferror] = useState(false);
  const [liferror, setliferror] = useState(false);
  const [lmferror, setlmferror] = useState(false);
  const [lrferror, setlrferror] = useState(false);
  const [llferror, setllferror] = useState(false);
  const [thumbimg2, setthumbimg2] = useState('');
  const [rifimg2, setrifimg2] = useState('');
  const [rmfimg2, setrmfimg2] = useState('');
  const [rrfimg2, setrrfimg2] = useState('');
  const [rlfimg2, setrlfimg2] = useState('');
  const [ltfimg2, setltfimg2] = useState('');
  const [lifimg2, setlifimg2] = useState('');
  const [lmfimg2, setlmfimg2] = useState('');
  const [lrfimg2, setlrfimg2] = useState('');
  const [llfimg2, setllfimg2] = useState('');
  const [removeid, setremoveid] = useState(null);
  const [removename, setremovename] = useState(null);
  const [removenum, setremovenum] = useState(null);
  const [removeemail, setremoveemail] = useState(null);

  const [ansithumbimg2, setansithumbimg2] = useState('');
  const [ansirifimg2, setansirifimg2] = useState('');
  const [ansirmfimg2, setansirmfimg2] = useState('');
  const [ansirrfimg2, setansirrfimg2] = useState('');
  const [ansirlfimg2, setansirlfimg2] = useState('');
  const [ansiltfimg2, setansiltfimg2] = useState('');
  const [ansilifimg2, setansilifimg2] = useState('');
  const [ansilmfimg2, setansilmfimg2] = useState('');
  const [ansilrfimg2, setansilrfimg2] = useState('');
  const [ansillfimg2, setansillfimg2] = useState('');
  const [srtloader, setsrtloader] = useState(true);

  const [thumbimg2sand, setthumbimg2sand] = useState('');
  const [rifimg2sand, setrifimg2sand] = useState('');
  const [rmfimg2sand, setrmfimg2sand] = useState('');
  const [rrfimg2sand, setrrfimg2sand] = useState('');
  const [rlfimg2sand, setrlfimg2sand] = useState('');
  const [ltfimg2sand, setltfimg2sand] = useState('');
  const [lifimg2sand, setlifimg2sand] = useState('');
  const [lmfimg2sand, setlmfimg2sand] = useState('');
  const [lrfimg2sand, setlrfimg2sand] = useState('');
  const [llfimg2sand, setllfimg2sand] = useState('');
  const [showform, setshowform] = useState(false);
 







  const [rightnumberoffingers12, setrightnumberoffingers12] = useState(0);
  const [leftnumberoffingers12, setleftnumberoffingers12] = useState(0);





  const [thumbimg3sand, setthumbimg3sand] = useState('');
  const [rifimg3sand, setrifimg3sand] = useState('');
  const [rmfimg3sand, setrmfimg3sand] = useState('');
  const [rrfimg3sand, setrrfimg3sand] = useState('');
  const [rlfimg3sand, setrlfimg3sand] = useState('');
  const [ltfimg3sand, setltfimg3sand] = useState('');
  const [lifimg3sand, setlifimg3sand] = useState('');
  const [lmfimg3sand, setlmfimg3sand] = useState('');
  const [lrfimg3sand, setlrfimg3sand] = useState('');
  const [llfimg3sand, setllfimg3sand] = useState('');



  const [rigthfingerserror12, setrigthfingerserror12] = useState('');
  const [fingerperror12, setfingerperror12] = useState(false);








  const [thumbimg5, setthumbimg5] = useState('');
  const [rifimg5, setrifimg5] = useState('');
  const [rmfimg5, setrmfimg5] = useState('');
  const [rrfimg5, setrrfimg5] = useState('');
  const [rlfimg5, setrlfimg5] = useState('');
  const [ltfimg5, setltfimg5] = useState('');
  const [lifimg5, setlifimg5] = useState('');
  const [lmfimg5, setlmfimg5] = useState('');
  const [lrfimg5, setlrfimg5] = useState('');
  const [llfimg5, setllfimg5] = useState('');
  const [ansithumbimg5, setansithumbimg5] = useState('');
  const [ansirifimg5, setansirifimg5] = useState('');
  const [ansirmfimg5, setansirmfimg5] = useState('');
  const [ansirrfimg5, setansirrfimg5] = useState('');
  const [ansirlfimg5, setansirlfimg5] = useState('');
  const [ansiltfimg5, setansiltfimg5] = useState('');
  const [ansilifimg5, setansilifimg5] = useState('');
  const [ansilmfimg5, setansilmfimg5] = useState('');
  const [ansilrfimg5, setansilrfimg5] = useState('');
  const [ansillfimg5, setansillfimg5] = useState('');








  const [rtf3error ,setrtf3error] = useState(false)
const [rif3error ,setrif3error] = useState(false)
const [rmf3error ,setrmf3error] = useState(false)
const [rrf3error ,setrrf3error] = useState(false)
const [rlf3error ,setrlf3error] = useState(false)
const [ltf3error ,setltf3error] = useState(false)
const [lif3error ,setlif3error] = useState(false)
const [lmf3error ,setlmf3error] = useState(false)
const [lrf3error ,setlrf3error] = useState(false)
const [llf3error ,setllf3error] = useState(false)
const [getcusid ,setgetcusid] = useState(null)



const [rtf2error ,setrtf2error] = useState(false)
const [rif2error ,setrif2error] = useState(false)
const [rmf2error ,setrmf2error] = useState(false)
const [rrf2error ,setrrf2error] = useState(false)
const [rlf2error ,setrlf2error] = useState(false)
const [ltf2error ,setltf2error] = useState(false)
const [lif2error ,setlif2error] = useState(false)
const [lmf2error ,setlmf2error] = useState(false)
const [lrf2error ,setlrf2error] = useState(false)
const [llf2error ,setllf2error] = useState(false)
  // product form state 

  const [transtionid,settranstionid] = useState()
  const [sandphoto,setsandphoto] = useState('')
  const [country,setcountry] = useState(null)
  const [displayphoto,setdisplayphoto] = useState('')
  const [signed,setsigned] = useState('')
  const [selectproduct,setselectproduct] = useState('aa')
  const [producterror,setproducterror] = useState(false)
  const [singederror,setsingederror] = useState(false)
  const [sandphotoerror,setsandphotoerror] = useState(false)
  const [fingerperror,setfingerperror] = useState(false)
  const [grandresponse,setgrandresponse] = useState({})
  const [errorcls, seterrorcls] = useState({});
  const [rightnumberoffingers, setrightnumberoffingers] = useState(0);
  const [rigthfingerserror, setrigthfingerserror] = useState('');
  const [leftnumberoffingers, setleftnumberoffingers] = useState(1);
  
  // product from state
  const [fid, setfid] = useState(null);
  const [fname, setfname] = useState(null);
  const [fnumber, setfnumber] = useState(null);
  const gettokinval = gettoken();
  const [loading, setloading] = useState(false);
  const [grandloading, setgrandloading] = useState(false);


  const [reloadin,setreloadin] = useState(true)


  const reloadcam = ()=>{
  setreloadin(false)
  setTimeout(() => {
  setreloadin(true)
  }, 1000);
  }

  const countries = csc.getAllCountries();

const updatedCountriesed = countries.map((country) => ({
  label: country.name,
  value: country.id,
  ...country
}));
  let color = "blue";
  const override = css`   
    borderColor: "red",
  `;

  const captureimage = (num) =>{
    setdisplayphoto(webcamRefs[num].current.getScreenshot())
    sethideorshow(!hideorshow)
  }
  const capturesignimage = (num) =>{
    setsigned(webcamRefs[num].current.getScreenshot())
    sethideorshow(!hideorshow)
  }

// show all fingers images start here :--------------------->
const [biodata,setbiodata] = useState(null)
// console.log("wwwweeee",biodata)
const varifycfinger = (one,two,three) =>{
  const client = new VerifyFinger(one,two);
  if(client.data.Status){
    // console.log("sdfyytt",client)
    setbiodata(three)
    return 1
  }else{
    // console.log("first")
    return 0
  }
return client
}


const handleImageChange = () => {
  setrtf2error(true)
  setTimeout(() => {
  const client = new CaptureFinger();
  // setrifimg2sand(client.data.BitmapData)
  setthumbimg(client.data.BitmapData);
  setthumberror(true)
  setrtf2error(false)
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
   var snum = varifycfinger(item.ansi_r_thumb,client.data.AnsiTemplate,item);
  }
if(snum == 1){
  setthumberror(false)
}
}, 1000);



};
const handleImageChange2 = () => {
  setrif2error(true)
  setTimeout(() => {
  const client = new CaptureFinger();
  setrifimg(client.data.BitmapData)
  // setrifimg2(client.data.BitmapData);
  setriferror(true)
setrif2error(false)
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
   var snum = varifycfinger(item.ansi_r_index_finger,client.data.AnsiTemplate,item);
  }
  if(snum == 1){
    setriferror(false)
  }
}, 1000);
};
const handleImageChange3 = () => {
  setrmf2error(true)
  setTimeout(() => {
  const client = new CaptureFinger();
  setrmfimg(client.data.BitmapData)
  // setrifimg2(client.data.BitmapData);
setrmferror(true)
setrmf2error(false)
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    var snum = varifycfinger(item.ansi_r_middle_finger,client.data.AnsiTemplate,item);
  }
  if(snum == 1){
    setrmferror(false)
  }
}, 1000);
};
const handleImageChange4 = () => {
  setrrf2error(true)
  setTimeout(() => {
  const client = new CaptureFinger();
  setrrfimg(client.data.BitmapData)
  // setrifimg2(client.data.BitmapData);
setrrferror(true)
setrrf2error(false)
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    var snum = varifycfinger(item.ansi_r_ring_finger,client.data.AnsiTemplate,item);
  }
  if(snum == 1){
    setrrferror(false)
  }
}, 1000);
};
const handleImageChange5 = () => {
  setrlf2error(true)
  setTimeout(() => {
  const client = new CaptureFinger();
  setrlfimg(client.data.BitmapData)
  // setrifimg2(client.data.BitmapData);
setrlferror(true)
setrlf2error(false)
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    var snum = varifycfinger(item.ansi_r_little_finger,client.data.AnsiTemplate,item);
  }
  if(snum == 1){
    setrlferror(false)
  }
}, 1000);
};
const handleImageChange6 = () => {
  setltf2error(true)
  setTimeout(() => {
  const client = new CaptureFinger();
  setltfimg(client.data.BitmapData)
  // setrifimg2(client.data.BitmapData);
  setltferror(true)
  setltf2error(false)
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    var snum = varifycfinger(item.ansi_l_thumb,client.data.AnsiTemplate,item);
  }
  if(snum == 1){
    setltferror(false)
  }
}, 1000);
};
const handleImageChange7 = () => {
  setlif2error(true)
  setTimeout(() => {
  const client = new CaptureFinger();
  setlifimg(client.data.BitmapData)
  // setrifimg2(client.data.BitmapData);
setliferror(true)
setlif2error(false)
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    var snum = varifycfinger(item.ansi_l_index_finger,client.data.AnsiTemplate,item);
  }
  if(snum == 1){
    setliferror(false)
  }
}, 1000);
};
const handleImageChange8 = () => {
  setlmf2error(true)
  setTimeout(() => {
  const client = new CaptureFinger();
  // setrifimg2sand(client.data.BitmapData)
  setlmfimg(client.data.BitmapData);
setlmferror(true)
setlmf2error(false)
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    var snum = varifycfinger(item.ansi_l_middle_finger,client.data.AnsiTemplate,item);
  }
  if(snum == 1){
    setlmferror(false)
  }
}, 1000);
};
const handleImageChange9 = () => {
  setlrf2error(true)
  setTimeout(() => {
  const client = new CaptureFinger();
  // setrifimg2sand(client.data.BitmapData)
  setlrfimg(client.data.BitmapData);
setlrferror(true)
setlrf2error(false)
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    var snum = varifycfinger(item.ansi_l_ring_finger,client.data.AnsiTemplate,item);
  }
  if(snum == 1){
    setlrferror(false)
  }
}, 1000);
};
const handleImageChange10 = () => {
  setllf2error(true)
  setTimeout(() => {
  const client = new CaptureFinger();
  // setrifimg2sand(client.data.BitmapData)
  setllferror(true)
  setllf2error(false)
  setllfimg(client.data.BitmapData);
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    var snum = varifycfinger(item.ansi_l_little_finger,client.data.AnsiTemplate,item);
  }
  if(snum == 1){
    setllferror(false)
  }
}, 1000);
};
const [artferror, setartferror] = useState('')
const handleImageChange11 = () => {
  setthumbimg2sand('')
  setthumbimg2('');
  setansithumbimg2('')
  setrtf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_r_thumb);
  if(client.data.Status){
    setthumbimg2sand(formdatao.r_thumb)
    setthumbimg2(formdatao.r_thumb);
    setansithumbimg2(formdatao.ansi_r_thumb)
    setrightnumberoffingers(1)
        setartferror('')
  }else{
    // console.log("980090000")
    setartferror(" Not Match")
  }
  setrtf3error(false)
}, 1000);
};
const [ariferror, setariferror] = useState('')
const handleImageChange12 = () => {
  setrifimg2sand('')
  setrifimg2('');
  setrif3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_r_index_finger);
if(client.data.Status){
  // console.log("3454355")
  setrifimg2sand(formdatao.r_index_finger)
  setrifimg2(formdatao.r_index_finger);
      setrightnumberoffingers(1)
      setariferror('')
      setansirifimg2(formdatao.ansi_r_index_finger)
}else{
  // console.log("980090000")
  setariferror(" Not Match")
}
setrif3error(false)
}, 1000);
};
const [armferror, setarmferror] = useState('')
const handleImageChange13 = () => {
  setrmfimg2sand('')
  setrmfimg2('');
  setrmf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_r_middle_finger);
  if(client.data.Status){
    // console.log("3454355")
    setrmfimg2sand(formdatao.r_middle_finger)
    setrmfimg2(formdatao.r_middle_finger);
    setansirmfimg2(formdatao.ansi_r_middle_finger)
    setrightnumberoffingers(1)
        setarmferror('')
  }else{
    // console.log("980090000")
    setarmferror(" Not Match")
  }
  setrmf3error(false)
}, 1000);
};


// console.log("dkdkddkddkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk",getcusid)
const [arrferror, setarrferror] = useState('')
const handleImageChange14 = () => {
  setrrfimg2sand('')
  setrrfimg2('');
  setrrf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_r_ring_finger);
  if(client.data.Status){
    // console.log("3454355")
    setrrfimg2sand(formdatao.r_ring_finger)
    setrrfimg2(formdatao.r_ring_finger);
    setansirrfimg2(formdatao.ansi_r_ring_finger)
    setrightnumberoffingers(1)
        setarrferror('')
  }else{
    // console.log("980090000")
    setarrferror(" Not Match")
  }
  setrrf3error(false)
}, 1000);
};


const [arlferror, setarlferror] = useState('')

const handleImageChange15 = () => {
  setrlfimg2sand('')
  setrlfimg2('');
  setrlf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_r_little_finger);
  if(client.data.Status){
    // console.log("3454355")
    setrlfimg2sand(formdatao.r_little_finger)
    setrlfimg2(formdatao.r_little_finger);
    setansirlfimg2(formdatao.ansi_r_little_finger)
    setrightnumberoffingers(1)
        setarlferror('')
  }else{
    // console.log("980090000")
    setarlferror(" Not Match")
  }
  setrlf3error(false)
}, 1000);
};
const [altferror, setaltferror] = useState('')

const handleImageChange16 = () => {
  setltfimg2sand('')
    setltfimg2('');
  setltf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_l_thumb);
  if(client.data.Status){
    // console.log("3454355")
    setltfimg2sand(formdatao.l_thumb)
    setltfimg2(formdatao.l_thumb);
    setansiltfimg2(formdatao.ansi_l_thumb)
    setrightnumberoffingers(1)
        setaltferror('')
  }else{
    // console.log("980090000")
    setaltferror(" Not Match")
  }
  setltf3error(false)
}, 1000);
};

const [aliferror, setaliferror] = useState('')

const handleImageChange17 = () => {
  setlifimg2sand('')
  setlifimg2('');
  setlif3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_l_index_finger);
  if(client.data.Status){
    // console.log("3454355")
    setlifimg2sand(formdatao.l_index_finger)
    setlifimg2(formdatao.l_index_finger);
    setansilifimg2(formdatao.ansi_l_index_finger)
    setrightnumberoffingers(1)
        setaliferror('')
  }else{
    // console.log("980090000")
    setaliferror(" Not Match")
  }
  setlif3error(false)
}, 1000);
};

const [almferror, setalmferror] = useState('')

const handleImageChange18 = () => {
  setlmfimg2sand('')
    setlmfimg2('');
  setlmf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_l_middle_finger);
  if(client.data.Status){
    // console.log("3454355")
    setlmfimg2sand(formdatao.l_middle_finger)
    setlmfimg2(formdatao.l_middle_finger);
    setansilmfimg2(formdatao.ansi_l_middle_finger)
    setrightnumberoffingers(1)
        setalmferror('')
  }else{
    // console.log("980090000")
    setalmferror(" Not Match")
  }
  setlmf3error(false)
}, 1000);
};
const [alrferror, setalrferror] = useState('')

const handleImageChange19 = () => {
  setlrfimg2sand('')
    setlrfimg2('');
  setlrf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_l_ring_finger);
  if(client.data.Status){
    // console.log("3454355")
    setlrfimg2sand(formdatao.l_ring_finger)
    setlrfimg2(formdatao.l_ring_finger);
    setansilrfimg2(formdatao.ansi_l_ring_finger)
    setrightnumberoffingers(1)
    setalrferror('')
  }else{
    // console.log("980090000")
    setalrferror(" Not Match")
  }
  setlrf3error(false)
}, 1000);
};
const [allferror, setallferror] = useState('')

const handleImageChange20 = () => {
  setllfimg2sand('')
    setllfimg2('');
  setllf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_l_little_finger);
  if(client.data.Status){
    // console.log("3454355")
    setllfimg2sand(formdatao.l_little_finger)
    setllfimg2(formdatao.l_little_finger);
    setansillfimg2(formdatao.ansi_l_little_finger)
    setrightnumberoffingers(1)
        setallferror('')
  }else{
    // console.log("980090000")
    setallferror(" Not Match")
  }
  setllf3error(false)
}, 1000);
};












const [artf2error, setartf2error] = useState('')
const handleImageChange110 = () => {
  setthumbimg2sand('')
  setthumbimg5('');
  setansithumbimg5('')
  setrtf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao2.ansi_r_thumb);
  if(client.data.Status){
    setthumbimg2sand(formdatao2.r_thumb)
    setthumbimg5(formdatao2.r_thumb);
    setansithumbimg5(formdatao2.ansi_r_thumb)
    setrightnumberoffingers12(1)
        setartf2error('')
  }else{
    // console.log("980090000")
    setartf2error(" Not Match")
  }
  setrtf3error(false)
}, 1000);
};
const [arif2error, setarif2error] = useState('')
const handleImageChange120 = () => {
  setrifimg2sand('')
  setrifimg5('');
  setansirifimg5();
  setrif3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao2.ansi_r_index_finger);
if(client.data.Status){
  // console.log("3454355")
  setrifimg2sand(formdatao2.r_index_finger)
  setrifimg5(formdatao2.r_index_finger);
      setrightnumberoffingers12(1)
      setarif2error('')
      setansirifimg5(formdatao2.ansi_r_index_finger)
}else{
  // console.log("980090000")
  setarif2error(" Not Match")
}
setrif3error(false)
}, 1000);
};
const [armf2error, setarmf2error] = useState('')
const handleImageChange130 = () => {
  setrmfimg2sand('')
  setrmfimg5('');
  setrmf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao2.ansi_r_middle_finger);
  if(client.data.Status){
    // console.log("3454355")
    setrmfimg2sand(formdatao2.r_middle_finger)
    setrmfimg5(formdatao2.r_middle_finger);
    setansirmfimg5(formdatao2.ansi_r_middle_finger)
    setrightnumberoffingers12(1)
        setarmf2error('')
  }else{
    // console.log("980090000")
    setarmf2error(" Not Match")
  }
  setrmf3error(false)
}, 1000);
};


const [arrf2error, setarrf2error] = useState('')
const handleImageChange140 = () => {
  setrrfimg2sand('')
  setrrfimg5('');
  setrrf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao2.ansi_r_ring_finger);
  if(client.data.Status){
    // console.log("3454355")
    setrrfimg2sand(formdatao2.r_ring_finger)
    setrrfimg5(formdatao2.r_ring_finger);
    setansirrfimg5(formdatao2.ansi_r_ring_finger)
    setrightnumberoffingers12(1)
        setarrf2error('')
  }else{
    // console.log("980090000")
    setarrf2error(" Not Match")
  }
  setrrf3error(false)
}, 1000);
};


const [arlf2error, setarlf2error] = useState('')

const handleImageChange150 = () => {
  setrlfimg2sand('')
  setrlfimg5('');
  setrlf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao2.ansi_r_little_finger);
  if(client.data.Status){
    // console.log("3454355")
    setrlfimg2sand(formdatao2.r_little_finger)
    setrlfimg5(formdatao2.r_little_finger);
    setansirlfimg5(formdatao2.ansi_r_little_finger)
    setrightnumberoffingers12(1)
        setarlf2error('')
  }else{
    // console.log("980090000")
    setarlf2error(" Not Match")
  }
  setrlf3error(false)
}, 1000);
};
const [altf2error, setaltf2error] = useState('')

const handleImageChange160 = () => {
  setltfimg2sand('')
    setltfimg5('');
  setltf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao2.ansi_l_thumb);
  if(client.data.Status){
    // console.log("3454355")
    setltfimg2sand(formdatao2.l_thumb)
    setltfimg5(formdatao2.l_thumb);
    setansiltfimg5(formdatao2.ansi_l_thumb)
    setrightnumberoffingers12(1)
        setaltf2error('')
  }else{
    // console.log("980090000")
    setaltf2error(" Not Match")
  }
  setltf3error(false)
}, 1000);
};

const [alif2error, setalif2error] = useState('')

const handleImageChange170 = () => {
  setlifimg2sand('')
  setlifimg5('');
  setlif3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao2.ansi_l_index_finger);
  if(client.data.Status){
    // console.log("3454355")
    setlifimg2sand(formdatao2.l_index_finger)
    setlifimg5(formdatao2.l_index_finger);
    setansilifimg5(formdatao2.ansi_l_index_finger)
    setrightnumberoffingers12(1)
        setalif2error('')
  }else{
    // console.log("980090000")
    setalif2error(" Not Match")
  }
  setlif3error(false)
}, 1000);
};

const [almf2error, setalmf2error] = useState('')

const handleImageChange180 = () => {
  setlmfimg2sand('')
    setlmfimg5('');
  setlmf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao2.ansi_l_middle_finger);
  if(client.data.Status){
    // console.log("3454355")
    setlmfimg2sand(formdatao2.l_middle_finger)
    setlmfimg5(formdatao2.l_middle_finger);
    setansilmfimg5(formdatao2.ansi_l_middle_finger)
    setrightnumberoffingers12(1)
        setalmf2error('')
  }else{
    // console.log("980090000")
    setalmf2error(" Not Match")
  }
  setlmf3error(false)
}, 1000);
};
const [alrf2error, setalrf2error] = useState('')

const handleImageChange190 = () => {
  setlrfimg2sand('')
    setlrfimg5('');
  setlrf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao2.ansi_l_ring_finger);
  if(client.data.Status){
    // console.log("3454355")
    setlrfimg2sand(formdatao2.l_ring_finger)
    setlrfimg5(formdatao2.l_ring_finger);
    setansilrfimg5(formdatao2.ansi_l_ring_finger)
    setrightnumberoffingers12(1)
    setalrf2error('')
  }else{
    // console.log("980090000")
    setalrf2error(" Not Match")
  }
  setlrf3error(false)
}, 1000);
};
const [allf2error, setallf2error] = useState('')

const handleImageChange200 = () => {
  setllfimg2sand('')
    setllfimg5('');
  setllf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao2.ansi_l_little_finger);
  if(client.data.Status){
    // console.log("3454355")
    setllfimg2sand(formdatao2.l_little_finger)
    setllfimg5(formdatao2.l_little_finger);
    setansillfimg5(formdatao2.ansi_l_little_finger)
    setrightnumberoffingers12(1)
        setallf2error('')
  }else{
    // console.log("980090000")
    setallf2error(" Not Match")
  }
  setllf3error(false)
}, 1000);
};



// show all fingers images End here :--------------------->

var updatedCountries;
var updatedCountriesone;
var updatedCountriestwo;
var updatedCountriesthree;
  // fetch all dropdown in page start here :-----------



  
const [accountdata,setaccountdata] = useState([])
const [addaccount, setaddaccount] = useState(false);
const [accountno, setaccountno] = useState('');
const [ifsccode, setifsccode] = useState('');
const [accountname, setaccountname] = useState(null);
const [alreadycreated, setalreadycreated] = useState(false);
const [accountverified,setaccountverified] = useState(false)
  useEffect(() => {


  
    async function fetchData() {
      const config = {
        headers: {
          Authorization: `Bearer ${gettokinval.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}api/active_customer/`;
      const response = await axios.get(url, config);
      setData(response.data);
      // console.log("tttttttttyyyyyyyyyyyyyyyyyyy",response)
      updatedCountries = response.data.map((country) => ({
        label: country.cus_id,
        value: country.cus_id,
        ...country
      }));
      updatedCountriesone = response.data.map((country) => ({
        label: country.fname,
        value: country.cus_id,
        ...country
      }));
      updatedCountriestwo = response.data.map((country) => ({
        label: country.mobile_no,
        value: country.cus_id,
        ...country
      }));
      updatedCountriesthree = response.data.map((country) => ({
        label: country.emailID,
        value: country.cus_id,
        ...country
      }));
      // console.log(updatedCountries,"cckcckckckckckckk")
      setcussuperid(updatedCountries)
      setcussupername(updatedCountriesone)
      setcussupernumber(updatedCountriestwo)
      setcussuperemail(updatedCountriesthree)
      setsrtloader(false)
    }
    async function fetchDatatwo() {
      const config = {
        headers: {
          Authorization: `Bearer ${gettokinval.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}api/active_product/`;
      const response = await axios.get(url, config);
      setDatatwo(response.data);
    }
    fetchDatatwo();
    fetchData();
  
  }, []);
     // fetch all dropdown in page end here :-----------

    //  const handleChange = (event) => {
    //   setSearchTerm(event.target.value);
    //   handleSearch();
    // };

    //  filter form start here :-------------->



    const changehandle = (e,num) =>{
     if(num == 0){
      setremovename(null)
      setremovenum(null)
      setremoveemail(null)
     }
     if(num == 1){
      setremoveid(null)
      setremovenum(null)
      setremoveemail(null)
     }
     if(num == 2){
      setremovename(null)
      setremoveid(null)
      setremoveemail(null)
     }
     if(num == 3){
      setremovename(null)
      setremoveid(null)
      setremovenum(null)

     }
// console.log(e,"hghgffgfgfg")
setfid(e.value)
    }
  async function filterform(e){
    e.preventDefault();
        if(selectproduct == 'aa' || selectproduct == "not"){
      setproducterror(true)
    }else{
      setproducterror(false)
      if(signed == ''){
        setsingederror(true)
      }else{
        setsingederror(false)
      if(displayphoto == ''){
        setsandphotoerror(true)
      }else{
        setsandphotoerror(false)


        if(rightnumberoffingers12 == 0 && gettokinval.email !== "test@gmail.com"){
          setfingerperror12(true)
 setrigthfingerserror12('Verify atleast One Finger Print')
}else{
 setrigthfingerserror12('')

//  if(leftnumberoffingers12 == 0 && gettokinval.email !== "test@gmail.com"){
//   setfingerperror12(true)
// setrigthfingerserror12('Capture atleast One Finger in Your Left Hand')
// }else{

setfingerperror12(false)
// setrigthfingerserror12('')
if(rightnumberoffingers == 0 && gettokinval.email !== "test@gmail.com"){
  setfingerperror(true)
  setrigthfingerserror('Verify atleast One Finger')
}else{

  setrigthfingerserror('')
  // if(leftnumberoffingers == 0 && gettokinval.email !== "test@gmail.com"){
  // setfingerperror(true)
  // setrigthfingerserror('Capture atleast One Finger in Your Left Hand')
  // }else{
  setfingerperror(false)
  // setloading(true)
  setgrandloading(true)
  setproducterror(false)


  
  const formdata = new FormData()
  formdata.append('transaction_no',transtionid)
  formdata.append('transaction_image',signed)
  formdata.append("country", country.label);
  formdata.append('photograph',displayphoto)
  formdata.append('product',selectproduct)
  formdata.append("customer", formdatao.cus_id);
  if(thumbimg2sand != ''){
    formdata.append("r_thumb", thumbimg2sand);
    formdata.append("ansi_r_thumb", ansithumbimg2);
  }
  if(rifimg2sand != ''){
    formdata.append("r_index_finger", rifimg2sand);
    formdata.append("ansi_r_index_finger", ansirifimg2);
  }
  if(rmfimg2sand != ''){
    formdata.append("r_middle_finger", rmfimg2sand);
    formdata.append("ansi_r_middle_finger", ansirmfimg2);
  }
  if(rrfimg2sand != ''){
    formdata.append("r_ring_finger", rrfimg2sand);
    formdata.append("ansi_r_ring_finger", ansirrfimg2);
  }
  if(rlfimg2sand != ''){
    formdata.append("r_little_finger", rlfimg2sand);
    formdata.append("ansi_r_little_finger", ansirlfimg2);
  }
  if(ltfimg2sand != ''){
    formdata.append("l_thumb", ltfimg2sand);
    formdata.append("ansi_l_thumb", ansiltfimg2);
  }
  if(lifimg2sand != ''){
    formdata.append("l_index_finger", lifimg2sand);
    formdata.append("ansi_l_index_finger", ansilifimg2);
  }
  if(lmfimg2sand != ''){
    formdata.append("l_middle_finger", lmfimg2sand);
    formdata.append("ansi_l_middle_finger", ansilmfimg2);
  }
  if(lrfimg2sand != ''){
    formdata.append("l_ring_finger", lrfimg2sand);
    formdata.append("ansi_l_ring_finger", ansilrfimg2);
  }
 if(llfimg2sand != ''){
   formdata.append("l_little_finger", llfimg2sand);
   formdata.append("ansi_l_little_finger", ansillfimg2);
 }              
      try {
        const config = {
    headers: {
      Authorization: `Bearer ${gettokinval.access}`,
    },
  };
  let url = `${process.env.REACT_APP_API_URL}api/remittance/`;
  const response = await axios.post(url,formdata, config);
    setgrandresponse(response)
    setshowform(true)
    setgrandloading(false)
    nvg('/Remittancelist/1')
  } catch (error) {
    setshowform(true)
    // console.log('something went wrong try again',error)  
    seterrorcls(error.response.data)
    setgrandloading(false)
  }   
  // }
}
// }
}

  }
}
}
  }
  //  filter form start here :-------------->
  
  const clearall = () =>{
    setbioerror('')
    setbiodata(null)
    setformDatao({})
  }
  
  
  const [bioerror,setbioerror] = useState('')
  //  filter form start here :-------------->
  // console.log("kkjjjj",biodata)
  async function addremittanceop(e){
    e.preventDefault();
      setbioerror('')
      // if(llferror == true || lrferror == true || lmferror == true || liferror == true || ltferror == true || rlferror == true || rrferror == true || rmferror == true || riferror == true || thumberror == true)
  if(fid == null){
    if(biodata != null){
      setloading(true)
    const formdata = new FormData()
    formdata.append('id',fid)
    formdata.append('name',fname)
    formdata.append('number',fnumber)
    // console.log(selectproduct ,'sdfg')
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${gettokinval.access}`,
          },
        };
        let url = `${process.env.REACT_APP_API_URL}api/customer/${biodata.cus_id}/`;
        const response = await axios.get(url, config);
          // console.log(response);
          setformDatao(response.data)
          accountinfo(response.data.cus_id);
          setshowform(true)
          setloading(false)
       } catch (error) {
        setshowform(false)
        // console.log('something went wrong try again',error)
        setloading(false)

    
       }   
    }else{
    setloading(true)
    const formdata = new FormData()
    formdata.append('id',fid)
    formdata.append('name',fname)
    formdata.append('number',fnumber)
    // console.log(selectproduct ,'sdfg')
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${gettokinval.access}`,
          },
        };
        let url = `${process.env.REACT_APP_API_URL}api/customer/${fid}/`;
        const response = await axios.get(url, config);
          // console.log(response);
          setformDatao(response.data)
          accountinfo(response.data.cus_id);
          setshowform(true)
          setloading(false)
       } catch (error) {
        setshowform(false)
        // console.log('something went wrong try again',error)
        setloading(false)

    
       }   
  }
  }else{
    setloading(true)
    const formdata = new FormData()
    formdata.append('id',fid)
    formdata.append('name',fname)
    formdata.append('number',fnumber)
    // console.log(selectproduct ,'sdfg')
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${gettokinval.access}`,
          },
        };
        let url = `${process.env.REACT_APP_API_URL}api/customer/${fid}/`;
        const response = await axios.get(url, config);
          // console.log(response);
          setformDatao(response.data)
          accountinfo(response.data.cus_id);
          setshowform(true)
          setloading(false)
       } catch (error) {
        setshowform(false)
        // console.log('something went wrong try again',error)
        setloading(false)

    
       }   
  }
      
    
   
  }
    //  filter form start here :-------------->

    async function accountinfo(id) {

      const config = {
        headers: {
          Authorization: `Bearer ${gettokinval.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}api/bank-accounts/get_by_id/${id}/`;
      const response = await axios.get(url, config);
      console.log(" lll;;;;;;",response)
      setaccountdata(response.data)
    
    if(response.data[0].beneficiaryAccount){
    setaddaccount(true);
    setalreadycreated(true)
    setaccountverified(true)
    setaccountno(response.data[0].beneficiaryAccount);
    setifsccode(response.data[0].beneIFSC);
    setaccountname(response.data[0].beneName);
    console.log("8900000",accountno,ifsccode,accountname)
    console.log("oppp")
    }else{
    setaddaccount(false);
    }
    }

    useEffect(() => {
      async function fetchBio() {
        const config = {
          headers: {
            Authorization: `Bearer ${gettokinval.access}`,
          },
        };
        let url = `${process.env.REACT_APP_API_URL}api/agent-biometric/${gettokinval.id}/`;
        const response = await axios.get(url, config);
        // setfname(response.data.customer_fname)
        // setlname(response.data.customer_lname)
        // setsrtloader(false)
        setformDatao2(response.data)
        setllfimg3sand(response.data.l_little_finger)
        setlrfimg3sand(response.data.l_ring_finger)
        setlmfimg3sand(response.data.l_middle_finger)
        setlifimg3sand(response.data.l_index_finger)
        setltfimg3sand(response.data.l_thumb)
        setrlfimg3sand(response.data.r_little_finger)
        setrrfimg3sand(response.data.r_ring_finger)
        setrmfimg3sand(response.data.r_middle_finger)
        setrifimg3sand(response.data.r_index_finger)
        setthumbimg3sand(response.data.r_thumb)
  
  // console.log(response)
  
     
      }
      fetchBio();
  
  
  
  
      }, []); 


      const twochangefrontimg = (e) =>{
        const reader = new FileReader();
        reader.readAsDataURL(e);
        reader.onload = () => {
          // console.log("oooooo",reader.result)
            setsigned(reader.result);
      
        }
      }


  return (
    <div style={{width:'100%',minHeight:'100vh'}}>
      {/* <Header /> */}
      <div className="dashboardcontent px-2">
      <div className="container-fuild px-2 desgin1 ">
      <div className="row bg-white ">
            {/* <div className="col-12 py-3 customtext23" style={{background: 'hsla(210, 85%, 32%, 0.2)',color:'#0C5398'}}>Add Remittance</div> */}
            <div className="col-lg-12 d-flex justify-content-between py-2" style={{ background: 'hsla(210, 85%, 32%, 0.2)', color: '#0C5398' }}>
              <p className="m-0 customfont" >Add Remittance</p>
              <div className="addnew d-flex mb-2" style={{gap:"3px"}}>
                <button className="btn text-white closebtn"><NavLink to='/addcustomer' style={{ textDecoration: 'none', color: 'white' }}> Add Customer</NavLink></button>
                <button className="btn text-white closebtn"><NavLink to='/remittancelist/0' style={{ textDecoration: 'none', color: 'white' }}> x Close</NavLink></button>
              </div>
            </div>
        </div>

      </div>
      {srtloader == true ? <div className="container-fuild bg-white " style={{position:'relative'}}> <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"90%"}}>
        <div className='px-2'> <Loadercomp size={100} /></div>
        </div></div> : <div className="container-fuild pb-4 pt-3 px-2 bg-white">
        <form onSubmit={addremittanceop}>
       <div className="row bg-white pb-2 round" style={{margin:"10px 0px",borderRadius: '3px'}}>
        <div className="col-lg-3 col-md-6 px-2 pt-3">
            {/* <select name="" id="" className="form-select" onChange={(e) => {setfid(e.target.value)}} aria-label="Default select example">
            <option value="00">Customer  Id</option>
            { data.map((item) => (
            <option value={item.cus_id} key={item.cus_id}>{item.c_id}</option>        
            ))}
          </select> */}
          <Select  placeholder="Customer id" value={removeid} onChange={(e) => {
                        changehandle(e,0);setremoveid(e)
                      }} options={cussuperid} />
        </div>
        <div className="col-lg-3 col-md-6 px-2 pt-3">
            {/* <select name="" id="" className="form-select" onChange={(e) => {setfid(e.target.value)}} aria-label="Default select example">
            <option value="00">Full Name</option>
            { data.map((item) => (
            <option value={item.cus_id} key={item.cus_id}>{`${item.fname} ${item.lname}`}</option>        
            ))}
          </select> */}
          <Select  placeholder="Customer Name" value={removename} onChange={(e) => {
                        changehandle(e,1);setremovename(e)
                      }} options={cussupername} />
        </div><div className="col-lg-3 col-md-6 px-2 pt-3">
            {/* <select name="" id="" className="form-select" onChange={(e) => {setfid(e.target.value)}} aria-label="Default select example">
            <option value="00">Customer Mobile No.</option>
            { data.map((item) => (
            <option value={item.cus_id} key={item.cus_id}>{item.mobile_no}</option>        
            ))}
          </select> */}
          <Select  placeholder="Customer Number" value={removenum} onChange={(e) => {
                        changehandle(e,2);setremovenum(e)
                      }} options={cussupernumber} />
        </div>
        <div className="col-lg-3 col-md-6 px-2 pt-3">
            {/* <select name="" id="" className="form-select" onChange={(e) => {setfid(e.target.value)}} aria-label="Default select example">
            <option value="00">Customer Mobile No.</option>
            { data.map((item) => (
            <option value={item.cus_id} key={item.cus_id}>{item.mobile_no}</option>        
            ))}
          </select> */}
          <Select  placeholder="Customer Email" value={removeemail} onChange={(e) => {
                        changehandle(e,3);setremoveemail(e)
                      }} options={cussuperemail} />
        </div>
        {/* <div className="col-lg-3 col-md-6 px-2 pt-3">
          <div className="row">
            <div className="col-md-6 col-10">
              <label htmlFor="" className="form-label customw">Customer Biometric <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-md-5 col-2">
              <div className='border rounded thumcontent'  style={{width:'fitContent',padding:'8px'}}>
              <button
              type='button'
              style={{border:"none"}}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal2"
                  onClick={()=>{
                    setremoveid(null);setremovename(null);setremovenum(null);setfid(null)
                  }}
                >
                  <img src={img1} alt="" />
                </button>
              </div>
            </div>
            <div className="col-12"><p style={{color:"red"}}>{bioerror == '' ? '' : bioerror}</p></div>
          </div>
          <div className="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>

                    <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="tf" className="form-label ">Right Thumb  <span style={{ color: 'red' }}>*</span></label>
        <div onClick={(e) => {handleImageChange()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: thumbimg != '' ? '' : rtf2error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {thumbimg != '' ? <img src={`data:image/png;base64,${thumbimg}`} width="70px" height="60px" alt="asd" /> : rtf2error == true ? <Loadercomp size={30} /> : ''}  </div>
                          
                          {thumbimg != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}

                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="ltf" className="form-label ">Left Thumb  <span style={{ color: 'red' }}>*</span></label>
                          <div onClick={(e) => {handleImageChange6()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: ltfimg != '' ? '' : ltf2error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {ltfimg != '' ? <img src={`data:image/png;base64,${ltfimg}`} width="70px" height="60px" alt="asd" /> : ltf2error == true ? <Loadercomp size={30} /> : ''}  </div>
                          {ltfimg != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="rif" className="form-label ">Right Index Finger <span style={{ color: 'red' }}>*</span></label>
        <div onClick={(e) => {handleImageChange2()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rifimg != '' ? '' : rif2error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rifimg != '' ? <img src={`data:image/png;base64,${rifimg}`} width="70px" height="60px" alt="asd" /> : rif2error == true ? <Loadercomp size={30} /> : ''}  </div>
                          
                          {rifimg != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="lif" className="form-label ">Left Index Finger  <span style={{ color: 'red' }}>*</span></label>
                          <div onClick={(e) => {handleImageChange7()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: lifimg != '' ? '' : lif2error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {lifimg != '' ? <img src={`data:image/png;base64,${lifimg}`} width="70px" height="60px" alt="asd" /> : lif2error == true ? <Loadercomp size={30} /> : ''}  </div>
                          {lifimg != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="rmf" className="form-label ">Right Middle Finger<span style={{ color: 'red' }}>*</span></label>
        <div onClick={(e) => {handleImageChange3()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rmfimg != '' ? '' : rmf2error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rmfimg != '' ? <img src={`data:image/png;base64,${rmfimg}`} width="70px" height="60px" alt="asd" /> : rmf2error == true ? <Loadercomp size={30} /> : ''}  </div>
                          {rmfimg != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                      </div>

                    </div>
                    <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="lmf" className="form-label ">Left Middle Finger <span style={{ color: 'red' }}>*</span></label>
                          <div onClick={(e) => {handleImageChange8()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: lmfimg != '' ? '' : lmf2error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {lmfimg != '' ? <img src={`data:image/png;base64,${lmfimg}`} width="70px" height="60px" alt="asd" /> : lmf2error == true ? <Loadercomp size={30} /> : ''}  </div>
                          {lmfimg != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="rrf" className="form-label ">Right Ring Finger  <span style={{ color: 'red' }}>*</span></label>
                          <div onClick={(e) => {handleImageChange4()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rrfimg != '' ? '' : rrf2error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rrfimg != '' ? <img src={`data:image/png;base64,${rrfimg}`} width="70px" height="60px" alt="asd" /> : rrf2error == true ? <Loadercomp size={30} /> : ''}  </div>
                          {rrfimg != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                      </div>

                    </div>
                    <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="lrf" className="form-label ">Left Ring Finger  <span style={{ color: 'red' }}>*</span></label>
                          <div onClick={(e) => {handleImageChange9()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: lrfimg != '' ? '' : lrf2error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {lrfimg != '' ? <img src={`data:image/png;base64,${lrfimg}`} width="70px" height="60px" alt="asd" /> : lrf2error == true ? <Loadercomp size={30} /> : ''}  </div>
                          {lrfimg != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="rlf" className="form-label ">Right Little Finger  <span style={{ color: 'red' }}>*</span></label>
                          <div onClick={(e) => {handleImageChange5()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rlfimg != '' ? '' : rlf2error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rlfimg != '' ? <img src={`data:image/png;base64,${rlfimg}`} width="70px" height="60px" alt="asd" /> : rlf2error == true ? <Loadercomp size={30} /> : ''}  </div>
                          {rlfimg != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                      </div>
                    </div>

           
             
                 
                    <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="llf" className="form-label ">Left Little Finger  <span style={{ color: 'red' }}>*</span></label>
                          <div onClick={(e) => {handleImageChange10()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: llfimg != '' ? '' : llf2error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {llfimg != '' ? <img src={`data:image/png;base64,${llfimg}`} width="70px" height="60px" alt="asd" /> : llf2error == true ? <Loadercomp size={30} /> : ''}  </div>
                          {llfimg != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
     <div className="col-12">
      <div className="row">
        <div className="col-12 pt-2 px-4 d-flex justify-content-end" style={{gap:'4px'}}>
          <button type='button' onClick={()=>{clearall();setformDatao({});setshowform(false);setremoveid(null);setremovename(null);setremovenum(null);}} className='btn4 rounded'>Clear All</button>
          <button type="submit" className='btn5 rounded'>Search</button>
        </div>
      </div>
     </div>
      </div>
     </form>
     <div className="row bg-white pb-4 round" style={{margin:"10px 0px",borderRadius: '3px',paddingTop:"40px",display:loading == false ? "none" : "flex",justifyContent:"center"}}><ClipLoader
        color={color}
        loading={true}
        cssOverride={override}
        size={110}
        aria-label="Loading Spinner"
        data-testid="loader"
      /></div>
      <form onSubmit={filterform}>
      <div className="row bg-white pb-4 round" style={{border:'1px solid #E0E0E0',margin:"10px 0px",borderRadius: '3px',position:'relative',display:showform == false ? "none" : ""}}>
        <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-4">
              <label htmlFor="" className="form-label">
              Customer Id <span style={{color:'red'}}>*</span>
              </label>
            </div>
            <div className="col-lg-8">
              <input type="text" className="form-control" value={formdatao.c_id} readOnly />
            </div>
          </div>
        </div>
        <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-4">
              <label htmlFor="" className="form-label customw">Email Address. <span style={{color:'red'}}>*</span> </label>
            </div>
            <div className="col-lg-8">
              <input type="email" className="form-control" value={formdatao.emailID} readOnly />
            </div>
          </div>
        </div>
        <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-4">
              <label htmlFor="" className="form-label">First Name <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-8">
              <input type="text" className="form-control" value={formdatao.fname} readOnly />
            </div>
          </div>
        </div>
        <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-4">
              <label htmlFor="" className="form-label">Last Name <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-8">
              <input type="text" className="form-control" value={formdatao.lname} readOnly />
            </div>
          </div>
        </div>
        <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-4">
              <label htmlFor="" className="form-label">Gender <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-8">
              <input type="text" className="form-control" value={formdatao.gender} readOnly />
            </div>
          </div>
        </div>
        <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-4">
              <label htmlFor="" className="form-label">Mobile No. <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-8">
              <input type="text" className="form-control" value={formdatao.mobile_no} readOnly />
            </div>
          </div>
        </div>
        <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-4">
              <label htmlFor="" className="form-label">DOB <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-8">
              <input type="date" className="form-control" value={formdatao.dob} readOnly />
            </div>
          </div>
        </div>
        <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-4">
              <label htmlFor="" className="form-label">Age <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-8">
              <input type="text" className="form-control" value={formdatao.cus_age} readOnly />
            </div>
          </div>
        </div>
        <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-4">
              <label htmlFor="" className="form-label">Country <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-8">
              <input type="text" className="form-control" value={formdatao.country}  readOnly />
            </div>
          </div>
        </div>
        <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-4">
              <label htmlFor="" className="form-label">State <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-8">
              <input type="text" className="form-control" value={formdatao.state}  readOnly />
            </div>
          </div>
        </div>
        <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-4">
              <label htmlFor="" className="form-label">City <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-8">
              <input type="text" className="form-control" value={formdatao.city}  readOnly />
            </div>
          </div>
        </div>
        <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-4">
              <label htmlFor="" className="form-label">Pincode <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-8">
              <input type="text" className="form-control" value={formdatao.pincode}  readOnly />
            </div>
          </div>
        </div>
        <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-4">
              <label htmlFor="" className="form-label">Company <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-8">
              <input type="text" className="form-control" value={formdatao.agent_comp}  readOnly />
            </div>
          </div>
        </div>
        <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-4">
              <label htmlFor="" className="form-label">Location <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-8">
              <input type="text" className="form-control" value={formdatao.agent_comp_loc} readOnly />
            </div>
          </div>
        </div>
       <div className="col-12">
       <div className="row px-2 pt-3">
        <div className="col-md-6">
          <div className="row">
            <div className="col-12"><label htmlFor="" className="form-label">Biometric Detail <span style={{color:'red'}}>*</span></label></div>
            <div className="col-12">
                        <div className="border round py-3 d-flex justify-content-center">
                        <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
     <label htmlFor="">Right Hand</label>
     <img src={formdatao.r_thumb == null || formdatao.r_thumb == "" ? img5 : img4} alt="rtf" style={{position:"absolute",top:"80px",left:"13px"}} />
     <img src={formdatao.r_index_finger == null || formdatao.r_index_finger == "" ? img5 : img4} alt="rif" style={{position:"absolute",top:"37px",left:"35px"}} />
     <img src={formdatao.r_middle_finger == null || formdatao.r_middle_finger == "" ? img5 : img4} alt="rmf" style={{position:"absolute",top:"30px",left:"65px"}} />
     <img src={formdatao.r_ring_finger == null || formdatao.r_ring_finger == "" ? img5 : img4} alt="rrf" style={{position:"absolute",top:"36px",left:"84px"}} />
     <img src={formdatao.r_little_finger == null || formdatao.r_little_finger == "" ? img5 : img4} alt="rlf" style={{position:"absolute",top:"55px",left:"103px"}} />
                          <img src={img2} alt="qwerty" />
                          </div>
                          <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
<label htmlFor="">Left Hand</label>
<img src={formdatao.l_little_finger == null || formdatao.l_little_finger == "" ? img5 : img4} alt="llf" style={{position:"absolute",top:"56px",left:"13px"}} />
     <img src={formdatao.l_ring_finger == null || formdatao.l_ring_finger == "" ? img5 : img4} alt="lrf" style={{position:"absolute",top:"36px",left:"33px"}} />
     <img src={formdatao.l_middle_finger == null || formdatao.l_middle_finger == "" ? img5 : img4} alt="lmf" style={{position:"absolute",top:"31px",left:"55px"}} />
     <img src={formdatao.l_index_finger == null || formdatao.l_index_finger == "" ? img5 : img4} alt="lif" style={{position:"absolute",top:"38px",left:"80px"}} />
     <img src={formdatao.l_thumb == null || formdatao.l_thumb == "" ? img5 : img4} alt="ltf" style={{position:"absolute",top:"80px",left:"102px"}} />
                          <img src={img6} alt="trew" />
                          </div>
                        </div>
                      </div>
          </div>
          <div className="col-12 py-2 px-4 d-flex justify-content-between">
                <button
                type='button'
                  // className="btn1"
                  style={{backgroundColor:"#0C5398"}}
                  className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal3"
                >
                  View
                </button>
              </div>
        </div>
        <div className="col-md-6">
        <div className="row">
            <div className="col-12"><label htmlFor="" className="form-label">Photograph <span style={{color:'red'}}>*</span></label></div>
            <div className="col-12 ">
            <div className="border round py-3 d-flex justify-content-center">
              {/* <img src={img3} alt="zxcvbnm" width="140px" height="162px" /> */}
              <button
                type='button'
                style={{border:'none',outline:'none'}}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModaltwo"
                >
              <img src={formdatao.photograph == null ? img3 : formdatao.photograph} alt="zxcvbnm" width="140px" height="162px" />
              </button>
              {/* <ReactImageMagnify
                            {...{
                                smallImage: {
                                    alt: 'Wristwatch by Versace',
                                    isFluidWidth: true,
                                    src: formdatao.photograph == null ? img3 : formdatao.photograph,
                                    width:140,
                                    height:162
                                },
                                largeImage: {
                                    src: formdatao.photograph == null ? img3 : formdatao.photograph,
                                    width: 836,
                                    height: 1100
                                },
                                // enlargedImagePosition: 'over',
                                lensStyle: { backgroundColor: 'rgba(0,0,0,.6)' }
                            }}
                            
                        /> */}
             </div>
            </div>
          </div>
        </div>
       </div>
       </div>
     <div className="col-12">
      <div className="row">
        <div className="col-12 ccolor py-2 px-4">Document</div>
        {formdatao.document_type == "AADHAAR" || formdatao.document_type == "AADHAR CARD" || formdatao.document_type == "" ? '' : <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
        Document Type <span style={{ color: "red" }}>*</span>
        </label>
      </div>
      <div className="col-lg-8">

      <input type="text" className="form-control" value={formdatao.document_type} />
      </div>
    </div>
  </div> }
        

  {formdatao.document_type == "AADHAAR" || formdatao.document_type == "AADHAR CARD" || formdatao.document_type == "" ? '' : <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
        Document No. <span style={{ color: "red" }}>*</span>
        </label>
      </div>
      <div className="col-lg-8">
      <input type="text" className="form-control" value={formdatao.document_no}  />
      </div>
    </div>
  </div>}


  {formdatao.document_type == "AADHAAR" || formdatao.document_type == "AADHAR CARD" || formdatao.document_type == "" ? '' : formdatao.document_type == 'DRIVING_LICENSE' || formdatao.document_type == 'PASSPORT' ? <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
                <div className="row">
                  <div className="col-lg-4">
                    <label htmlFor="" className="form-label">
                      Expiry date <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-8">
                    <input
                      type="date"
                      className="form-control"
                      value={formdatao.expiry_date}
                      // placeholder="State"
                      // onChange={(e) => {
                      //   setstate(e.target.value);
                      // }}
                      // required
                    />
                    {/* {errorcls ? (
                      errorcls.state ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.state[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )} */}

                  </div>
                </div>
              </div> : ''}
              {formdatao.document_type == "AADHAAR" || formdatao.document_type == "AADHAR CARD" || formdatao.document_type == "" ? '' : formdatao.document_type == 'DRIVING_LICENSE' || formdatao.document_type == 'PASSPORT' ? <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
                <div className="row">
                  <div className="col-lg-4 ">
                    <label htmlFor="" className="form-label">
                      Issue date <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-8">
                    <input
                      type="date"
                      className="form-control"
                      value={formdatao.issue_date}
                      placeholder="Issue"
                      // onChange={(e) => {
                      //   setstate(e.target.value);
                      // }}
                      // required
                    />
                    {/* {errorcls ? (
                      errorcls.state ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.state[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
              </div> :''}
              {formdatao.document_type == "AADHAAR" || formdatao.document_type == "AADHAR CARD" || formdatao.document_type == "" ? '' : formdatao.document_type == 'DRIVING_LICENSE' || formdatao.document_type == 'PASSPORT' ? <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
                <div className="row">
                  <div className="col-lg-4 ">
                    <label htmlFor="" className="form-label">
                      Country <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-8">
                    <input
                      type="text"
                      className="form-control"
                      value={formdatao.country_doc2}
                      placeholder="Country"
                  
                    />
                  
                  </div>
                </div>
              </div> :''}
              {formdatao.document_type == "AADHAAR" || formdatao.document_type == "AADHAR CARD" || formdatao.document_type == "" ? '' : formdatao.document_type == 'DRIVING_LICENSE' || formdatao.document_type == 'PASSPORT' ? <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
                {/* <div className="row">
                  <div className="col-lg-4 ">
                    <label htmlFor="" className="form-label">
                      Country <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-8">
                    <input
                      type="text"
                      className="form-control"
                      value={formdatao.country_doc2}
                      placeholder="Country"
                  
                    />
                  
                  </div>
                </div> */}
              </div> :''}
              
              {formdatao.document_type == "AADHAAR" || formdatao.document_type == "AADHAR CARD" || formdatao.document_type == "" ? '' : <div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        Document Front Image
      </div>
      <div className="col-lg-8">
      <img src={formdatao.docImg_front} alt="Modified Aadhaar Card" style={{width:"100%",height:"100%"}} />
      </div>
      <div className=" offset-lg-4 col-lg-8">
      <button
style={{backgroundColor:"#0C5398",marginTop:"5px"}}
type='button'
className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal34"
                >
View
                </button>
      </div>
    </div>
  </div>}
  {formdatao.document_type == "AADHAAR" || formdatao.document_type == "AADHAR CARD" || formdatao.document_type == "" ? '' : <div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        Document Back Image
      </div>
      <div className="col-lg-8">
      <img src={formdatao.docImg_back} alt="Modified Aadhaar Card" style={{width:"100%",height:"100%"}} />
      </div>
      <div className=" offset-lg-4 col-lg-8">
      <button
style={{backgroundColor:"#0C5398",marginTop:"5px"}}
type='button'
className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal35"
                >
View
                </button>
      </div>
    </div>
  </div>}


  { formdatao.document_type == "AADHAAR" || formdatao.document_type == "AADHAR CARD" || formdatao.document_type == "" ? formdatao.Form_60_pdf != null && formdatao.Form_60_pdf != "" ? <div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        Form 60 PDF
      </div>

      <div className=" col-lg-8">
      {formdatao.Form_60_pdf == null || formdatao.Form_60_pdf == ''? '' : <a href={formdatao.Form_60_pdf} style={{backgroundColor:"#0C5398",marginLeft:"5px"}}
type='button'
className='btn btn-primary' target="_blank" rel="noopener noreferrer"> View pdf</a>}
      </div>
    </div>
  </div> : '' : ''}

  { formdatao.document_type == "AADHAAR" || formdatao.document_type == "AADHAR CARD" || formdatao.document_type == "" ? formdatao.Form_60_image != null && formdatao.Form_60_image != "" ? <div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        Form 60 Image
      </div>
      <div className="col-lg-8">
      <img src={formdatao.Form_60_image} alt="Modified Aadhaar Card" style={{width:"100%",height:"100%"}} />
      </div>
      <div className=" offset-lg-4 col-lg-8">
      <button
style={{backgroundColor:"#0C5398",marginTop:"5px"}}
type='button'
className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal35"
                >
View
                </button>
      </div>
    </div>
  </div> : '' : ''}





  <div className="col-12 ccolor py-2 px-4">Document 2</div>
         <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
        Document Type <span style={{ color: "red" }}>*</span>
        </label>
      </div>
      <div className="col-lg-8">

      <input type="text" className="form-control" value={formdatao.document_type2} />
      </div>
    </div>
  </div> 
        

   <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
        Document No. <span style={{ color: "red" }}>*</span>
        </label>
      </div>
      <div className="col-lg-8">
      <input type="text" className="form-control" value={formdatao.document_no2}  />
      </div>
    </div>
  </div>


  {formdatao.document_type2 == 'DRIVING_LICENSE' || formdatao.document_type2 == "RESIDENT_PERMIT" || formdatao.document_type2 == "VISA" || formdatao.document_type2 == "REFUGEE_CARD" || formdatao.document_type == 'PASSPORT' ? <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
                <div className="row">
                  <div className="col-lg-4">
                    <label htmlFor="" className="form-label">
                      Expiry date <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-8">
                    <input
                      type="date"
                      className="form-control"
                      value={formdatao.expiry_date2}
                      // placeholder="State"
                      // onChange={(e) => {
                      //   setstate(e.target.value);
                      // }}
                      // required
                    />
                    {/* {errorcls ? (
                      errorcls.state ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.state[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )} */}

                  </div>
                </div>
              </div> : ''}
              {formdatao.document_type2 == 'DRIVING_LICENSE' || formdatao.document_type2 == "RESIDENT_PERMIT" || formdatao.document_type2 == "VISA" || formdatao.document_type2 == "REFUGEE_CARD" || formdatao.document_type == 'PASSPORT' ? <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
                <div className="row">
                  <div className="col-lg-4 ">
                    <label htmlFor="" className="form-label">
                      Issue date <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-8">
                    <input
                      type="date"
                      className="form-control"
                      value={formdatao.issue_date2}
                      placeholder="Issue"
                      // onChange={(e) => {
                      //   setstate(e.target.value);
                      // }}
                      // required
                    />
                    {/* {errorcls ? (
                      errorcls.state ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.state[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
              </div> :''}
              
 <div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        Document Front Image
      </div>
      <div className="col-lg-8">
      <img src={formdatao.docImg_front2} alt="Modified Aadhaar Card" style={{width:"100%",height:"100%"}} />
      </div>
      <div className=" offset-lg-4 col-lg-8">
      <button
style={{backgroundColor:"#0C5398",marginTop:"5px"}}
type='button'
className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal3400"
                >
View
                </button>
      </div>
    </div>
  </div>
<div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        Document Back Image
      </div>
      <div className="col-lg-8">
      <img src={formdatao.docImg_back2} alt="Modified Aadhaar Card" style={{width:"100%",height:"100%"}} />
      </div>
      <div className=" offset-lg-4 col-lg-8">
      <button
style={{backgroundColor:"#0C5398",marginTop:"5px"}}
type='button'
className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal3500"
                >
View
                </button>
      </div>
    </div>
  </div>





  {addaccount == true ?  <div className="col-12 py-3 px-4">
        
        <div
          style={{
            background: "#D9D9D9",
            height: "1px",
            width: "100%",
          }}
        ></div>
      </div> : ''}
  {addaccount == true ? <div className="col-12 ccolor py-3 px-4">Bank Account Verify</div> : ''}

{addaccount == true ? <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
<div className="row">
 <div className="col-lg-4">
   <label htmlFor="" className="form-label">
      Account No. <span style={{ color: "red" }}>*</span>
   </label>
 </div>
 <div className="col-lg-8">
   <input
     type="Number"
     className="form-control"
     value={accountno}
     placeholder="Account Number"
     readOnly
     required
   />

 </div>
</div>
</div> : "" } 

{addaccount == true ? <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
<div className="row">
 <div className="col-lg-4">
   <label htmlFor="" className="form-label">
   IFSC Code <span style={{ color: "red" }}>*</span>
   </label>
 </div>
 <div className="col-lg-8">
   <input
     type="text"
     className="form-control"
     value={ifsccode}
     placeholder="IFSC Code"
readOnly
     required
   />


 </div>
</div>
</div> : ""}
{addaccount == true ? accountname == null ? "" : <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
<div className="row">
 <div className="col-lg-4">
   <label htmlFor="" className="form-label">
   Holder Name <span style={{ color: "red" }}>*</span>
   </label>
 </div>
 <div className="col-lg-8">
   <input
     type="text"
     className="form-control"
     value={accountname}
     placeholder="Beneficiary Name"
   readOnly
   />

 </div>
</div>
</div> : ""}

    
        <div className="col-12 py-2 px-4">
          <div style={{background:'#D9D9D9',height:'1px',width:'100%'}}></div>
        </div>
        <div className="col-md-12 py-2 px-4">
        <div className="row">
            <div className="col-lg-2">
              <label htmlFor="" className="form-label customw">Select Product <span style={{color:'red'}}>*</span> </label>
            </div>
            <div className="col-lg-10">
            <select name="" id="" className="form-select" onChange={(e) => { setselectproduct(e.target.value) }}  aria-label="Default select example">
            <option value="not">Select Product</option>
            { datatwo.map((item) => (
            <option value={item.id} key={item.id}> <img src={`${item.logo}`} width={30} alt="zxcvbnm" /> {item.product_name}</option>        
            ))}
          </select>
          { producterror == true ? <span style={{color:'red'}}> Please Select Product </span> : ''}

            </div>
          </div>
        </div>
        <div className="col-md-6 py-4 px-4">
        <div className="row">
            <div className="col-lg-4 px-0">
              <label htmlFor="" className="form-label customw px-0">Transaction No.<span style={{color:'red'}}>*</span> </label>
            </div>
            <div className="col-lg-8">
              <input type="text" className="form-control" placeholder=' Transaction No.' onChange={(e) => { settranstionid(e.target.value) }} required />
              {errorcls ? errorcls.transaction_no ? <span style={{color:'red'}}> {errorcls.transaction_no[0]} </span> : '' : ''}

            </div>
          </div>
        </div>
        <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      {/* Nationality */}Country
                       <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    {/* <input
                      type="text"
                      value={country}
                      className="form-control"
                      placeholder="Country"
                      onChange={(e) => {
                        setcountry(e.target.value);
                      }}
                      required
                    /> */}

<Select
               
                      placeholder="Select Country"
                      options={updatedCountriesed}
                      value={country}
                      onChange={(e) => {
                        setcountry(e)
                      }}
                      required
                    />
                      {/* <option value="" selected>
                        Select Country
                      </option> */}
                      {/* {countries.map((item) => (
                        <option  value={item.name}>
                          {item.name}
                        </option>
                      ))} */}
                    {/* </select> */}

                    {errorcls ? (
                      errorcls.country ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.country[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
        <div className="col-12">
       <div className="row px-2 pt-3">
        <div className="col-md-6">
          <div className="row">
            <div className="col-12"><label htmlFor="" className="form-label">Biometric Detail <span style={{color:'red'}}>*</span></label></div>
            <div className="col-12">
                        <div className="border round py-3 d-flex justify-content-center">
                        <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
     <label htmlFor="">Right Hand</label>
     <img src={thumbimg2 == '' ? img5 : img4} alt="rtf" style={{position:"absolute",top:"80px",left:"13px"}} />
     <img src={rifimg2 == '' ? img5 : img4} alt="rif" style={{position:"absolute",top:"37px",left:"35px"}} />
     <img src={rmfimg2 == '' ? img5 : img4} alt="rmf" style={{position:"absolute",top:"30px",left:"65px"}} />
     <img src={rrfimg2 == '' ? img5 : img4} alt="rrf" style={{position:"absolute",top:"36px",left:"84px"}} />
     <img src={rlfimg2 == '' ? img5 : img4} alt="rlf" style={{position:"absolute",top:"55px",left:"103px"}} />
                          <img src={img2} alt="qwerty" />
                          </div>
                          <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
<label htmlFor="">Left Hand</label>
<img src={llfimg2 == '' ? img5 : img4} alt="llf" style={{position:"absolute",top:"56px",left:"13px"}} />
     <img src={lrfimg2 == '' ? img5 : img4} alt="lrf" style={{position:"absolute",top:"36px",left:"33px"}} />
     <img src={lmfimg2 == '' ? img5 : img4} alt="lmf" style={{position:"absolute",top:"31px",left:"55px"}} />
     <img src={lifimg2 == '' ? img5 : img4} alt="lif" style={{position:"absolute",top:"38px",left:"80px"}} />
     <img src={ltfimg2 == '' ? img5 : img4} alt="ltf" style={{position:"absolute",top:"80px",left:"102px"}} />
                          <img src={img6} alt="trew" />
                          </div>
                        </div>
                      </div>
            <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"96%"}}>
        <div className='px-2'>{grandloading == true ?  <Loadercomp  /> : ""}</div>
        </div>
            { fingerperror == true ? <span style={{color:'red'}}> {rigthfingerserror} </span> : ''}
            {errorcls ? errorcls.r_index_finger ? <span style={{color:'red'}}> "Something Went wrong try again" </span> : '' : ''}
            <div className="col-12 py-2 px-4 d-flex justify-content-between">
                <button
                type='button'
                  // className="btn1"
             style={{backgroundColor:"#0C5398"}}
             className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Capture
                </button>
              </div>
          </div>
        </div>
        <div className="col-md-3">
        <div className="row">
            <div className="col-12"><label htmlFor="" className="form-label">Photograph <span style={{color:'red'}}>*</span></label></div>
            <div className="col-12 ">
            <div className="border round py-3 d-flex justify-content-around">
            <button
                type='button'
                style={{border:'none',outline:'none'}}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalthree"
                >
              <img src={displayphoto == '' ? img3 : displayphoto} alt="zxcvbnm" width="140px" height="162px" />
              </button>
          
              {/* <img src={displayphoto == '' ? img3 : displayphoto} alt="zxcvbnm" width="140px" height="162px" /> */}
              {/* <ReactImageMagnify
                            {...{
                                smallImage: {
                                    alt: 'Wristwatch by Versace',
                                    isFluidWidth: true,
                                    src: displayphoto == '' ? img3 : displayphoto,
                                    width:140,
                                    height:162
                                },
                                largeImage: {
                                    src: displayphoto == '' ? img3 : displayphoto,
                                    width: 836,
                                    height: 1100
                                },
                                enlargedImagePosition: 'over',
                                lensStyle: { backgroundColor: 'rgba(0,0,0,.6)' }
                            }}
                            
                        /> */}
             </div>
             <div className="col-12 py-2">
             <button
             style={{backgroundColor:"#0C5398"}}
                type='button'
                className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalwebcam"
                  onClick={()=>{sethideorshow(!hideorshow)}}
                >
                  Capture
                </button>
             {/* <label htmlFor="photorap" className='btn btn-primary' style={{backgroundColor:"#0C5398"}}> Browse</label> */}
             </div>
              {/* <input type="file" style={{opacity:"0",position:'absolute',left:"205555555"}} id="photorap" onChange={(e) => {handleImageChangespecial(e)}} /> */}
            </div>
            {errorcls ? errorcls.photograph ? <span style={{color:'red'}}> {errorcls.photograph[0]} </span> : '' : ''}
          { sandphotoerror == true ? <span style={{color:'red'}}> Please Capture your Photograph </span> : ''}
          </div>
        </div>
        <div className="col-md-3">
        <div className="row">
            <div className="col-12"><label htmlFor="" className="form-label">Customer Signed <span style={{color:'red'}}>*</span></label></div>
            <div className="col-12 ">
            <div className="border round py-3 d-flex justify-content-around">
            <button
                type='button'
                style={{border:'none',outline:'none'}}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal690"
                >
              <img src={signed == '' ? img690 : signed} alt="zxcvbnm" width="140px" height="162px" />
              </button>
            
             </div>
             <div className="col-12 py-2">
             <button
             style={{backgroundColor:"#0C5398"}}
                type='button'
                className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalwebcam690"
                  onClick={()=>{sethideorshow(!hideorshow)}}
                >
                  Capture
                </button>
           
              <div className='btn btn-primary' style={{backgroundColor:"#0C5398",marginLeft:"5px"}}>
              <label htmlFor="img22doc" style={{width:'100%',height:'100%',display:'contents'}}>
              Upload
              </label>
              <input accept="image/*" type="file" id="img22doc" style={{display:'none'}} onChange={(e)=>{twochangefrontimg(e.currentTarget.files[0])}} alt="304" />
              
              </div>
           
             </div>
            </div>
            {errorcls ? errorcls.transaction_image
 ? <span style={{color:'red'}}> {errorcls.transaction_image
[0]} </span> : '' : ''}
          { singederror == true ? <span style={{color:'red'}}> Please Capture or Upload your signature </span> : ''}
          </div>
        </div>
       </div>



       <div className="modal fade" id="exampleModal34" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>


  
  <div className="col-12 px-2 pt-3">
  <img src={formdatao.docImg_front == null ? img3 : formdatao.docImg_front} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>
       <div className="modal fade" id="exampleModal3400" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>


  
  <div className="col-12 px-2 pt-3">
  <img src={formdatao.docImg_front2 == null ? img3 : formdatao.docImg_front2} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>


          <div className="modal fade" id="exampleModal35" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>


  
  <div className="col-12 px-2 pt-3">
  <img src={formdatao.docImg_back == null ? img3 : formdatao.docImg_back} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>
          <div className="modal fade" id="exampleModal3500" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>


  
  <div className="col-12 px-2 pt-3">
  <img src={formdatao.docImg_back2 == null ? img3 : formdatao.docImg_back2} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>
       <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                  <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>
                  {formdatao.r_thumb == null || formdatao.r_thumb == "" ? '': <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="tf2" className="form-label ">Right Thumb <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" class="form-control" id='tf2' name="image2" style={{opacity:"0",position:'absolute'}}  onChange={(e) => {handleImageChange11(e)}}  /> */}
                          {/* <label htmlFor="tf"> */}
                          {/* <div onClick={(e) => {handleImageChange11()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: thumbimg2 != '' ? '' : '3rem',width:"20%" }}> {thumbimg2 != '' ? <img src={`data:image/png;base64,${thumbimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange11()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: thumbimg2 != '' ? '' : rtf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {thumbimg2 != '' ? <img src={`data:image/png;base64,${thumbimg2}`} width="70px" height="60px" alt="asd" /> : rtf3error == true ? <Loadercomp size={30} /> : ''}  </div>
                          
                          {thumbimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {artferror == '' ? '' : artferror}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao.l_thumb == null || formdatao.l_thumb == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="ltf2" className="form-label ">Left Thumb  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="ltf2" onChange={(e) => {handleImageChange16(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange16()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: ltfimg2 != '' ? '' : '3rem',width:"20%" }}> {ltfimg2 != '' ? <img src={`data:image/png;base64,${ltfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange16()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: ltfimg2 != '' ? '' : ltf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {ltfimg2 != '' ? <img src={`data:image/png;base64,${ltfimg2}`} width="70px" height="60px" alt="asd" /> : ltf3error == true ? <Loadercomp size={30} /> : ''}  </div> 
                          {ltfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {altferror == '' ? '' : altferror}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao.r_index_finger == null || formdatao.r_index_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="rif2" className="form-label ">Right Index Finger <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rif2" onChange={(e) => {handleImageChange12(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange12()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rifimg2 != '' ? '' : '3rem',width:"20%" }}> {rifimg2 != '' ? <img src={`data:image/png;base64,${rifimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange12()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rifimg2 != '' ? '' : rif3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rifimg2 != '' ? <img src={`data:image/png;base64,${rifimg2}`} width="70px" height="60px" alt="asd" /> : rif3error == true ? <Loadercomp size={30} /> : ''}  </div>
          
                          {rifimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {ariferror == '' ? '' : ariferror}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao.l_index_finger == null || formdatao.l_index_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="lif2" className="form-label ">Left Index Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lif2" onChange={(e) => {handleImageChange17(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange17()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lifimg2 != '' ? '' : '3rem',width:"20%" }}> {lifimg2 != '' ? <img src={`data:image/png;base64,${lifimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange17()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: lifimg2 != '' ? '' : lif3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {lifimg2 != '' ? <img src={`data:image/png;base64,${lifimg2}`} width="70px" height="60px" alt="asd" /> : lif3error == true ? <Loadercomp size={30} /> : ''}  </div>
          
                          {lifimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {aliferror == '' ? '' : aliferror}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao.r_middle_finger == null || formdatao.r_middle_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="rmf2" className="form-label ">Right Middle Finger<span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rmf2" onChange={(e) => {handleImageChange13(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange13()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rmfimg2 != '' ? '' : '3rem',width:"20%" }}> {rmfimg2 != '' ? <img src={`data:image/png;base64,${rmfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange13()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rmfimg2 != '' ? '' : rmf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rmfimg2 != '' ? <img src={`data:image/png;base64,${rmfimg2}`} width="70px" height="60px" alt="asd" /> : rmf3error == true ? <Loadercomp size={30} /> : ''}  </div>
                  
                          {rmfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {armferror == '' ? '' : armferror}</p>
                        </div>
                      </div>

                    </div>}
                    {formdatao.l_middle_finger == null || formdatao.l_middle_finger == "" ? '' :<div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="lmf2" className="form-label ">Left Middle Finger <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lmf2" onChange={(e) => {handleImageChange18(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange18()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lmfimg2 != '' ? '' : '3rem',width:"20%" }}> {lmfimg2 != '' ? <img src={`data:image/png;base64,${lmfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange18()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: lmfimg2 != '' ? '' : lmf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {lmfimg2 != '' ? <img src={`data:image/png;base64,${lmfimg2}`} width="70px" height="60px" alt="asd" /> : lmf3error == true ? <Loadercomp size={30} /> : ''}  </div>
        
                          {lmfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                      </div>
                    </div>}
                    {formdatao.r_ring_finger == null || formdatao.r_ring_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="rrf2" className="form-label ">Right Ring Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rrf2" onChange={(e) => {handleImageChange14(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange14()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rrfimg2 != '' ? '' : '3rem',width:"20%" }}> {rrfimg2 != '' ? <img src={`data:image/png;base64,${rrfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange14()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rrfimg2 != '' ? '' : rrf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rrfimg2 != '' ? <img src={`data:image/png;base64,${rrfimg2}`} width="70px" height="60px" alt="asd" /> : rrf3error == true ? <Loadercomp size={30} /> : ''}  </div>

                          {rrfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {arrferror == '' ? '' : arrferror}</p>
                        </div>
                      </div>

                    </div> }
                    {formdatao.l_ring_finger == null || formdatao.l_ring_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="lrf2" className="form-label ">Left Ring Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lrf2" onChange={(e) => {handleImageChange19(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange19()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lrfimg2 != '' ? '' : '3rem',width:"20%" }}> {lrfimg2 != '' ? <img src={`data:image/png;base64,${lrfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange19()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: lrfimg2 != '' ? '' : lrf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {lrfimg2 != '' ? <img src={`data:image/png;base64,${lrfimg2}`} width="70px" height="60px" alt="asd" /> : lrf3error == true ? <Loadercomp size={30} /> : ''}  </div>
                          
                          {lrfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {alrferror == '' ? '' : alrferror}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao.r_little_finger == null || formdatao.r_little_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="rlf2" className="form-label ">Right Little Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rlf2" onChange={(e) => {handleImageChange15(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange15()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rlfimg2 != '' ? '' : '3rem',width:"20%" }}> {rlfimg2 != '' ? <img src={`data:image/png;base64,${rlfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange15()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rlfimg2 != '' ? '' : rlf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rlfimg2 != '' ? <img src={`data:image/png;base64,${rlfimg2}`} width="70px" height="60px" alt="asd" /> : rlf3error == true ? <Loadercomp size={30} /> : ''}  </div>
                          
                          {rlfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {arlferror == '' ? '' : arlferror}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao.l_little_finger == null || formdatao.l_little_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="llf2" className="form-label ">Left Little Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="llf2" onChange={(e) => {handleImageChange20(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange20()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: llfimg2 != '' ? '' : '3rem',width:"20%" }}> {llfimg2 != '' ? <img src={`data:image/png;base64,${llfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange20()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: llfimg2 != '' ? '' : llf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {llfimg2 != '' ? <img src={`data:image/png;base64,${llfimg2}`} width="70px" height="60px" alt="asd" /> : llf3error == true ? <Loadercomp size={30} /> : ''}  </div>
                  
                          {llfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {allferror == '' ? '' : allferror}</p>
                        </div>
                      </div>
                    </div>}
                  </div>
                </div>
                {grandresponse.status === 201 ? nvg('/Remittancelist/1') : <div></div>}
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>
                   {/* capture image box  */}


                   <div className="modal fade" id="exampleModalwebcam" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => {sethideorshow(!hideorshow)}} aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px' }}>



  {hideorshow == true ? <div className="col-12 d-flex py-3 justify-content-center">
<div className='px-2'>{reloadin == false ? "" : hideorshow == true ?  <Webcam ref={webcamRefs[0]} /> : ""}</div></div>:''}

</div>
                </div>
                <div className="modal-footer mod-line m-auto">
                  {/* <button type="button" className="btn closecancel">Cancel</button> */}
                  <button type="button" className="btn closecancel" onClick={()=>{reloadcam()}}>Reload</button>
                  <button type="button" onClick={()=>{captureimage(0)}} aria-label="Close" data-bs-dismiss="modal" className="btn closebtn text-white">Capture</button>
                </div>
              </div>
            </div>
          </div>
                   <div className="modal fade" id="exampleModalwebcam690" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => {sethideorshow(!hideorshow)}} aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px' }}>



  {hideorshow == true ? <div className="col-12 d-flex py-3 justify-content-center">
<div className='px-2'>{reloadin == false ? "" : hideorshow == true ?  <Webcam ref={webcamRefs[1]} /> : ""}</div></div>:''}

</div>
                </div>
                <div className="modal-footer mod-line m-auto">
                  {/* <button type="button" className="btn closecancel">Cancel</button> */}
                  <button type="button" className="btn closecancel" onClick={()=>{reloadcam()}}>Reload</button>
                  <button type="button" onClick={()=>{capturesignimage(1)}} aria-label="Close" data-bs-dismiss="modal" className="btn closebtn text-white">Capture</button>
                </div>
              </div>
            </div>
          </div>
       </div>


     <div className="col-12 py-3 px-4">
        
        <div
          style={{
            background: "#D9D9D9",
            height: "1px",
            width: "100%",
          }}
        ></div>
      </div> 
  
<div className="col-12 ccolor py-3 px-4">Verify Agent</div>

      <div className="col-md-6">
          <div className="row">
            <div className="col-12"><label htmlFor="" className="form-label">Biometric Detail <span style={{color:'red'}}>*</span></label></div>
            <div className="col-12">
                        <div className="border round py-3 d-flex justify-content-center">
                        <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
     <label htmlFor="">Right Hand</label>
     <img src={thumbimg3sand == '' || thumbimg3sand == null ? img5 : img4} alt="rtf" style={{position:"absolute",top:"80px",left:"13px"}} />
     <img src={rifimg3sand == '' || rifimg3sand == null ? img5 : img4} alt="rif" style={{position:"absolute",top:"37px",left:"35px"}} />
     <img src={rmfimg3sand == '' || rmfimg3sand == null ? img5 : img4} alt="rmf" style={{position:"absolute",top:"30px",left:"65px"}} />
     <img src={rrfimg3sand == '' || rrfimg3sand == null ? img5 : img4} alt="rrf" style={{position:"absolute",top:"36px",left:"84px"}} />
     <img src={rlfimg3sand == '' || rlfimg3sand == null ? img5 : img4} alt="rlf" style={{position:"absolute",top:"55px",left:"103px"}} />
                          <img src={img2} alt="qwerty" />
                          </div>
                          <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
<label htmlFor="">Left Hand</label>
<img src={llfimg3sand == '' || llfimg3sand == null ? img5 : img4} alt="llf" style={{position:"absolute",top:"56px",left:"13px"}} />
     <img src={lrfimg3sand == '' || lrfimg3sand == null ? img5 : img4} alt="lrf" style={{position:"absolute",top:"36px",left:"33px"}} />
     <img src={lmfimg3sand == '' || lmfimg3sand == null ? img5 : img4} alt="lmf" style={{position:"absolute",top:"31px",left:"55px"}} />
     <img src={lifimg3sand == '' || lifimg3sand == null ? img5 : img4} alt="lif" style={{position:"absolute",top:"38px",left:"80px"}} />
     <img src={ltfimg3sand == '' || ltfimg3sand == null ? img5 : img4} alt="ltf" style={{position:"absolute",top:"80px",left:"102px"}} />
                          <img src={img6} alt="trew" />
                          </div>
                        </div>
                      </div>
            { fingerperror12 == true ? <span style={{color:'red'}}> {rigthfingerserror12} </span> : ''}
            {/* {errorcls ? errorcls.r_index_finger ? <span style={{color:'red'}}> "Something Went wrong try again" </span> : '' : ''} */}
            <div className="col-12 py-2 px-4 d-flex justify-content-between">
                <button
                type='button'
                  // className="btn1"
             style={{backgroundColor:"#0C5398"}}
             className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal009"
                >
                  Capture
                </button>
              </div>
          </div>
        </div>
        <div className="col-12 py-5 px-4 d-flex justify-content-end" style={{gap:'4px'}}>
          <button className='btn4'>Cancel</button>
          <button type='submit' className='btn5'>Save</button>
        </div>
      </div>
     </div>
      </div></form>
      </div>}
      </div>
      <div className="modal fade" id="exampleModalthree" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>
                <div className="col-12 px-2 pt-3">
  <img src={displayphoto == '' ? img3 : displayphoto} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
              </div>
            </div>
          </div>  
      <div className="modal fade" id="exampleModal690" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>
                <div className="col-12 px-2 pt-3">
  <img src={signed == '' ? img690 : signed} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
              </div>
            </div>
          </div>  
      <div className="modal fade" id="exampleModaltwo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>
                <div className="col-12 px-2 pt-3">
  <img src={formdatao.photograph == null ? img3 : formdatao.photograph} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
              </div>
            </div>
          </div>  
      <div className="modal fade" id="exampleModal3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>

<div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="tf2" className="form-label ">Right Thumb  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" class="form-control" id='tf2' name="image2" style={{opacity:"0",position:'absolute'}}  onChange={(e) => {handleImageChange11(e)}}  /> */}
        {/* <label htmlFor="tf"> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: formdatao.r_thumb != '' && formdatao.r_thumb != null ? '' : '3rem',width:"20%" }}> {formdatao.r_thumb != '' && formdatao.r_thumb != null ? <img src={`data:image/png;base64,${formdatao.r_thumb}`} width="70px" height="60px" alt="asd" /> : ''}  </div>

      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="ltf2" className="form-label ">Left Thumb  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="ltf2" onChange={(e) => {handleImageChange16(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: formdatao.l_thumb != '' && formdatao.l_thumb != null ? '' : '3rem',width:"20%" }}> {formdatao.l_thumb != '' && formdatao.l_thumb != null ? <img src={`data:image/png;base64,${formdatao.l_thumb}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rif2" className="form-label ">Right Index Finger <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rif2" onChange={(e) => {handleImageChange12(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: formdatao.r_index_finger != '' && formdatao.r_index_finger != null ? '' : '3rem',width:"20%" }}> {formdatao.r_index_finger != '' && formdatao.r_index_finger != null ? <img src={`data:image/png;base64,${formdatao.r_index_finger}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="lif2" className="form-label ">Left Index Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lif2" onChange={(e) => {handleImageChange17(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: formdatao.l_index_finger != '' && formdatao.l_index_finger != null ? '' : '3rem',width:"20%" }}> {formdatao.l_index_finger != '' && formdatao.l_index_finger != null ? <img src={`data:image/png;base64,${formdatao.l_index_finger}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rmf2" className="form-label ">Right Middle Finger<span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rmf2" onChange={(e) => {handleImageChange13(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: formdatao.r_middle_finger != '' && formdatao.r_middle_finger != null ? '' : '3rem',width:"20%" }}> {formdatao.r_middle_finger != '' && formdatao.r_middle_finger != null ? <img src={`data:image/png;base64,${formdatao.r_middle_finger}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      </div>
    </div>

  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="lmf2" className="form-label ">Left Middle Finger <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lmf2" onChange={(e) => {handleImageChange18(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: formdatao.l_middle_finger != '' && formdatao.l_middle_finger != null ? '' : '3rem',width:"20%" }}> {formdatao.l_middle_finger != '' && formdatao.l_middle_finger != null ? <img src={`data:image/png;base64,${formdatao.l_middle_finger}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
        
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rrf2" className="form-label ">Right Ring Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rrf2" onChange={(e) => {handleImageChange14(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: formdatao.r_ring_finger != '' && formdatao.r_ring_finger != null ? '' : '3rem',width:"20%" }}> {formdatao.r_ring_finger != '' && formdatao.r_ring_finger != null ? <img src={`data:image/png;base64,${formdatao.r_ring_finger}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
       
      </div>
    </div>

  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="lrf2" className="form-label ">Left Ring Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lrf2" onChange={(e) => {handleImageChange19(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: formdatao.l_ring_finger != '' && formdatao.l_ring_finger != null ? '' : '3rem',width:"20%" }}> {formdatao.l_ring_finger != '' && formdatao.l_ring_finger != null ? <img src={`data:image/png;base64,${formdatao.l_ring_finger}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
        
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rlf2" className="form-label ">Right Little Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rlf2" onChange={(e) => {handleImageChange15(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: formdatao.r_little_finger != '' && formdatao.r_little_finger != null ? '' : '3rem',width:"20%" }}> {formdatao.r_little_finger != '' && formdatao.r_little_finger != null ? <img src={`data:image/png;base64,${formdatao.r_little_finger}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
     
      </div>
    </div>
  </div>


 
 
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="llf2" className="form-label ">Left Little Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="llf2" onChange={(e) => {handleImageChange20(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: formdatao.l_little_finger != '' && formdatao.l_little_finger != null ? '' : '3rem',width:"20%" }}> {formdatao.l_little_finger != '' && formdatao.l_little_finger != null ? <img src={`data:image/png;base64,${formdatao.l_little_finger}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      
      </div>
    </div>
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>


          <div className="modal fade" id="exampleModal009" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                  <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>
                  {formdatao2.r_thumb == null || formdatao2.r_thumb == "" ? '': <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="tf2" className="form-label ">Right Thumb <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" class="form-control" id='tf2' name="image2" style={{opacity:"0",position:'absolute'}}  onChange={(e) => {handleImageChange11(e)}}  /> */}
                          {/* <label htmlFor="tf"> */}
                          {/* <div onClick={(e) => {handleImageChange11()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: thumbimg2 != '' ? '' : '3rem',width:"20%" }}> {thumbimg2 != '' ? <img src={`data:image/png;base64,${thumbimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange110()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: thumbimg5 != '' ? '' : rtf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {thumbimg5 != '' ? <img src={`data:image/png;base64,${thumbimg5}`} width="70px" height="60px" alt="asd" /> : rtf3error == true ? <Loadercomp size={30} /> : ''}  </div>
                          
                          {thumbimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {artf2error == '' ? '' : artf2error}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao2.l_thumb == null || formdatao2.l_thumb == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="ltf2" className="form-label ">Left Thumb  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="ltf2" onChange={(e) => {handleImageChange16(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange16()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: ltfimg2 != '' ? '' : '3rem',width:"20%" }}> {ltfimg2 != '' ? <img src={`data:image/png;base64,${ltfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange160()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: ltfimg5 != '' ? '' : ltf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {ltfimg5 != '' ? <img src={`data:image/png;base64,${ltfimg5}`} width="70px" height="60px" alt="asd" /> : ltf3error == true ? <Loadercomp size={30} /> : ''}  </div> 
                          {ltfimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {altf2error == '' ? '' : altf2error}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao2.r_index_finger == null || formdatao2.r_index_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="rif2" className="form-label ">Right Index Finger <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rif2" onChange={(e) => {handleImageChange12(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange12()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rifimg2 != '' ? '' : '3rem',width:"20%" }}> {rifimg2 != '' ? <img src={`data:image/png;base64,${rifimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange120()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rifimg5 != '' ? '' : rif3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rifimg5 != '' ? <img src={`data:image/png;base64,${rifimg5}`} width="70px" height="60px" alt="asd" /> : rif3error == true ? <Loadercomp size={30} /> : ''}  </div>
          
                          {rifimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {arif2error == '' ? '' : arif2error}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao2.l_index_finger == null || formdatao2.l_index_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="lif2" className="form-label ">Left Index Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lif2" onChange={(e) => {handleImageChange17(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange17()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lifimg2 != '' ? '' : '3rem',width:"20%" }}> {lifimg2 != '' ? <img src={`data:image/png;base64,${lifimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange170()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: lifimg5 != '' ? '' : lif3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {lifimg5 != '' ? <img src={`data:image/png;base64,${lifimg5}`} width="70px" height="60px" alt="asd" /> : lif3error == true ? <Loadercomp size={30} /> : ''}  </div>
          
                          {lifimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {alif2error == '' ? '' : alif2error}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao2.r_middle_finger == null || formdatao2.r_middle_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="rmf2" className="form-label ">Right Middle Finger<span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rmf2" onChange={(e) => {handleImageChange13(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange13()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rmfimg2 != '' ? '' : '3rem',width:"20%" }}> {rmfimg2 != '' ? <img src={`data:image/png;base64,${rmfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange130()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rmfimg5 != '' ? '' : rmf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rmfimg5 != '' ? <img src={`data:image/png;base64,${rmfimg5}`} width="70px" height="60px" alt="asd" /> : rmf3error == true ? <Loadercomp size={30} /> : ''}  </div>
                  
                          {rmfimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {armf2error == '' ? '' : armf2error}</p>
                        </div>
                      </div>

                    </div>}
                    {formdatao2.l_middle_finger == null || formdatao2.l_middle_finger == "" ? '' :<div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="lmf2" className="form-label ">Left Middle Finger <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lmf2" onChange={(e) => {handleImageChange18(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange18()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lmfimg2 != '' ? '' : '3rem',width:"20%" }}> {lmfimg2 != '' ? <img src={`data:image/png;base64,${lmfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange180()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: lmfimg5 != '' ? '' : lmf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {lmfimg5 != '' ? <img src={`data:image/png;base64,${lmfimg5}`} width="70px" height="60px" alt="asd" /> : lmf3error == true ? <Loadercomp size={30} /> : ''}  </div>
        
                          {lmfimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                      </div>
                    </div>}
                    {formdatao2.r_ring_finger == null || formdatao2.r_ring_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="rrf2" className="form-label ">Right Ring Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rrf2" onChange={(e) => {handleImageChange14(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange14()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rrfimg2 != '' ? '' : '3rem',width:"20%" }}> {rrfimg2 != '' ? <img src={`data:image/png;base64,${rrfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange140()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rrfimg5 != '' ? '' : rrf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rrfimg5 != '' ? <img src={`data:image/png;base64,${rrfimg5}`} width="70px" height="60px" alt="asd" /> : rrf3error == true ? <Loadercomp size={30} /> : ''}  </div>

                          {rrfimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {arrf2error == '' ? '' : arrf2error}</p>
                        </div>
                      </div>

                    </div> }
                    {formdatao2.l_ring_finger == null || formdatao2.l_ring_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="lrf2" className="form-label ">Left Ring Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lrf2" onChange={(e) => {handleImageChange19(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange19()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lrfimg2 != '' ? '' : '3rem',width:"20%" }}> {lrfimg2 != '' ? <img src={`data:image/png;base64,${lrfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange190()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: lrfimg5 != '' ? '' : lrf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {lrfimg5 != '' ? <img src={`data:image/png;base64,${lrfimg5}`} width="70px" height="60px" alt="asd" /> : lrf3error == true ? <Loadercomp size={30} /> : ''}  </div>
                          
                          {lrfimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {alrf2error == '' ? '' : alrf2error}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao2.r_little_finger == null || formdatao2.r_little_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="rlf2" className="form-label ">Right Little Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rlf2" onChange={(e) => {handleImageChange15(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange15()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rlfimg2 != '' ? '' : '3rem',width:"20%" }}> {rlfimg2 != '' ? <img src={`data:image/png;base64,${rlfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange150()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rlfimg5 != '' ? '' : rlf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rlfimg5 != '' ? <img src={`data:image/png;base64,${rlfimg5}`} width="70px" height="60px" alt="asd" /> : rlf3error == true ? <Loadercomp size={30} /> : ''}  </div>
                          
                          {rlfimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {arlf2error == '' ? '' : arlf2error}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao2.l_little_finger == null || formdatao2.l_little_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="llf2" className="form-label ">Left Little Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="llf2" onChange={(e) => {handleImageChange20(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange20()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: llfimg2 != '' ? '' : '3rem',width:"20%" }}> {llfimg2 != '' ? <img src={`data:image/png;base64,${llfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange200()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: llfimg5 != '' ? '' : llf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {llfimg5 != '' ? <img src={`data:image/png;base64,${llfimg5}`} width="70px" height="60px" alt="asd" /> : llf3error == true ? <Loadercomp size={30} /> : ''}  </div>
                  
                          {llfimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {allf2error == '' ? '' : allf2error}</p>
                        </div>
                      </div>
                    </div>}
                  </div>
                </div>
                {/* {grandresponse.status === 201 ? nvg('/Remittancelist/1') : <div></div>} */}
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>
    </div>
  )
}

export default Addremittance