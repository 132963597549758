import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { NavLink, useParams } from "react-router-dom";
import { RiFileExcel2Fill } from "react-icons/ri";
import { gettoken } from "../../Localstorage/Store";
import axios from "axios";
import $ from "jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-dt/js/dataTables.dataTables.min.js";
import "datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css";
import "datatables.net-buttons/js/dataTables.buttons.min.js";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons-bs5/js/buttons.bootstrap5.min.js";
import { CSVLink } from "react-csv";
import { IoIosWallet } from "react-icons/io";
import Balance from "../../context/Balance";
import { useContext } from "react";

const Agentwalletdetail = () => {
  const [data, setData] = useState([]);
  const [name, setname] = useState('');
  const [idno, setidno] = useState(0);
  const [loading, setloading] = useState(true);
  const [balno,setbalno] = useContext(Balance)
  const gettokinval = gettoken();
  const { id } = useParams();
  // console.log("first",data)
  useEffect(() => {
    setidno(id);
    function showAlert() {
      setTimeout(function () {
        setidno(0);
      }, 5000); // 5000 milliseconds (5 seconds) delay
    }
    showAlert();
    async function fetchData() {
      const config = {
        headers: {
          Authorization: `Bearer ${gettokinval.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}api/wallet/`;
      const response = await axios.get(url, config);
      setData(response.data[0].creditdebitentry_set);
      setname(response.data[0].full_name)
      setloading(false);
      $(document).ready(function () {
        $("#myTable").DataTable().destroy();
        $("#myTable").DataTable({
          pageLength: 7,
          language: {
            searchPlaceholder: "Search...",
          },
          dom: "Bfrtip", // Add the buttons to the DataTable
          buttons: ["excel"],
        });
      });
    }
    fetchData();
  }, []);
  return (
    <div className="minheight" style={{ width: "100%", minHeight: "100vh" }}>
      {/* <Header /> */}
      <div className="dashboardcontent px-2">
        {/* <Breadcup name={"Products"} /> */}

        <div className="container-fuild px-2 desgin1 ">
          <div className="row bg-white ">
            {/* <div className="col-12 py-3 customtext23" style={{background: 'hsla(210, 85%, 32%, 0.2)',color:'#0C5398'}}>Add Product</div> */}
            <div
              className="col-lg-12 d-flex justify-content-between py-2"
              style={{
                background: "hsla(210, 85%, 32%, 0.2)",
                color: "#0C5398",
              }}
            >
              <p className="m-0 d-flex justify-content-center align-items-center customfont">
                Wallet History
              </p>
              <div className="addnew d-block mb-2">
                <button className="btn text-white closebtn">
                  <NavLink
                    to="/walletrecharge/1"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    {" "}
                    x Close
                  </NavLink>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid py-3 bg-body">
          <div className="col-md-12 px-2 pt-4">
            <div className="row">
              {/* <div className="col-lg-3">
                    <label htmlFor="" className="form-label"></label>
                  </div> */}
              <div className="col-lg-7 align-items-center d-flex justify-content-start">
                {/* <p className="desc2031"> Wallet History</p> */}
              </div>

              <div className="col-lg-5 d-flex justify-content-end align-items-center">
                <span>
                  {" "}
                  <IoIosWallet fontSize={56} color="rgba(12, 83, 152, 1)" />
                </span>
                <span
                  style={{
                    fontWeight: "700",
                    fontSize: "23px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  ₹ {balno}
                </span>
              </div>
            </div>
          </div>
          <div className="row bg-white py-3">
            <div className="col-9 d-flex" style={{ gap: "9px" }}>
              <div className="row p-0">
                <div className="col-12">
<label htmlFor="">From Date</label>
                </div>
<div className="col-12">
<input
                type="date"
                className="form-control"
                placeholder="From date"
              />
</div>
              </div>
              <div className="row p-0">
                <div className="col-12">
<label htmlFor="">To Date</label>
                </div>
<div className="col-12">
              <input
                type="date"
                className="form-control"
                placeholder="Mobile.No"
              />
</div>
</div>
              {/* <input
                type="text"
                className="form-control"
                placeholder="Transaction Name"
              /> */}
                    <div className="row p-0">
                <div className="col-12">
<label htmlFor="">Transaction Type</label>
                </div>
<div className="col-12">
              <select
                className="form-select"
                aria-label="Default select example"
              >
                <option value="All">All</option>
                <option value="Credit">Credit</option>
                <option value="Debit">Debit</option>
              </select>
              </div>
              </div>
            </div>
            <div
              className="col-3 d-flex justify-content-end align-items-end"
              style={{ gap: "5px" }}
            >
              <div className="d-lg-block">
                <button
                  className="btn"
                  style={{ background: "rgba(217, 217, 217, 1)" }}
                >
                  <span>Clear</span>
                  <span>All</span>
                </button>
              </div>
              <div className="addnew d-lg-block">
                <button
                  className="btn text-white d-flex"
                  style={{ background: "rgba(12, 83, 152, 1)" }}
                >
                  <span> Apply </span> <span> Filter</span>
                </button>
              </div>
            </div>
          </div>

          <div className="row bg-white pb-4 rounded-bottom table-responsive hideserchbar">
            <table id="myTable" className="table">
              <thead className="table-light">
                <tr>
                  <th >S.NO</th>
                  <th >Agent Name</th>
                  <th >Transaction ID</th>
                  <th >Description</th>
                  <th >Amount</th>
                  <th > Transaction Type</th>
                  <th >Date & Time</th>
                </tr>
              </thead>
              <tbody>
                {loading ? <tr><th style={{textAlign:"center"}} colSpan={8}>Loading...</th></tr> : data.map((item, index) => (
                <tr>
                  <td scope="row">{1 + index}</td>
                  <td>{name}</td>
                  <td>{item.transaction_id}</td>
                  <td>{item.description}</td>
                  <td style={{color:item.transaction_type == "Debit" ?'red' : 'green'}} > {item.transaction_type == "Debit" ? `-${item.amount}` : `+${item.amount}`}</td>
                  <td>{item.transaction_type}</td>

                  <td>{item.timestamp}</td>
                </tr>
                // <tr>
                //   <td scope="row">2</td>
                //   <td>ajay</td>
                //   <td>4353453434</td>
                //   <td>azy6049@gmail.com</td>
                //   <td style={{ color: "#15B611" }}>+ 5,000</td>

                //   <td>02/22/2023 12:02:40 PM</td>
                // </tr>
                // <tr>
                //   <td scope="row">3</td>
                //   <td>ajay</td>
                //   <td>4353453434</td>
                //   <td>azy6049@gmail.com</td>
                //   <td style={{ color: "#15B611" }}>+ 5,000</td>

                //   <td>02/22/2023 12:02:40 PM</td>
                // </tr>
                // <tr>
                //   <td scope="row">4</td>
                //   <td>ajay</td>
                //   <td>4353453434</td>
                //   <td>azy6049@gmail.com</td>
                //   <td style={{ color: "#15B611" }}>+ 5,000</td>

                //   <td>02/22/2023 12:02:40 PM</td>
                // </tr>
                // <tr>
                //   <td scope="row">5</td>
                //   <td>ajay</td>
                //   <td>4353453434</td>
                //   <td>azy6049@gmail.com</td>
                //   <td style={{ color: "red" }}>+ 5,000</td>

                //   <td>02/22/2023 12:02:40 PM</td>
                // </tr>
                 ))} 
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Agentwalletdetail;
