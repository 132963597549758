import React, { useEffect, useRef } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import img1 from "../../assets/Group 40.png";
import img2 from "../../assets/Group 41.png";
import img3 from "../../assets/KYC Logo white-bg 2.jpg";
import img4 from "../../assets/abc.png";
import img5 from "../../assets/cba.png";
import img6 from "../../assets/asdfg.png";
import img7 from "../../assets/ul.png";
import checkicon from "../../assets/Ellipse 28.png";
import { FaShoppingCart } from "react-icons/fa";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { ImLocation2 } from "react-icons/im";
import {
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
  BsFillArrowUpCircleFill,
  BsFillArrowDownCircleFill,
} from "react-icons/bs";
import Webcamtwo from "react-webcam";
import { BsFillTelephoneFill } from "react-icons/bs";
import PhoneInput from "react-phone-number-input";
import Select from "react-select";
import { useState } from "react";
import csc from "country-state-city";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { gettoken, removeToken } from "../../Localstorage/Store";
import Loadercomp from "../../components/Loadercomp";
import Webcam from "react-webcam";
import { CaptureFinger } from "../mfs100";
const Checkout = () => {
  const webcamRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  
  const {planidno} = useParams();

  const [notapplicable, setnotapplicable] = useState(false);
  const [data, setData] = useState([]);
  const [agentcountry, setagentcountry] = useState(null);
  const [branchcountry, setbranchcountry] = useState(null);
  const [dbierrortwo, setdbierrortwo] = useState(false);
  const [dbireserrortwo, setdbireserrortwo] = useState("");
  const [errorclass, seterrorclass] = useState(false);
  const [editagenterror, seteditagenterror] = useState(false);
  const [editlocation, seteditlocation] = useState(false);
  const [purchaseerror, setpurchaseerror] = useState(false);
  const [agentstate, setagentstate] = useState(null);
  const [branchstate, setbranchstate] = useState(null);
  const [sandphotoerror, setsandphotoerror] = useState(false);
  const [rigthfingerserror, setrigthfingerserror] = useState("");
  const [fingerperror, setfingerperror] = useState(false);
  const [planinfo, setplaninfo] = useState({});
  const [datatwo, setDatatwo] = useState([]);
  const [lcity, setLcity] = useState("");
  const [ffn, setffn] = useState();
  const [branchcity, setbranchcity] = useState("");
  const [varxtwo, setvarxtwo] = useState(10);
  const [varytwo, setvarytwo] = useState(10);
  const [aadhaarImagetwo, setAadhaarImagetwo] = useState(null);
  const [hideorshowthree, sethideorshowthree] = useState(false);
  const [documenttypetwo, setdocumenttypetwo] = useState();
  const [documentissue, setdocumentissue] = useState();
  const [documentexpiry, setdocumentexpiry] = useState();
  const [documentloader, setdocumentloader] = useState(false);
  const [displayphoto, setdisplayphoto] = useState("");
  const [thumbimg2, setthumbimg2] = useState("");
  const [documentsuperresverify, setdocumentsuperresverify] = useState('');
  const [documentsuperresseond, setdocumentsuperresseond] = useState('');
  const [rifimg2, setrifimg2] = useState("");
  const [rmfimg2, setrmfimg2] = useState("");
  const [rrfimg2, setrrfimg2] = useState("");
  const [rlfimg2, setrlfimg2] = useState("");
  const [ltfimg2, setltfimg2] = useState("");
  const [lifimg2, setlifimg2] = useState("");
  const [lmfimg2, setlmfimg2] = useState("");
  const [lrfimg2, setlrfimg2] = useState("");
  const [llfimg2, setllfimg2] = useState("");
  const [aadhaarImage, setAadhaarImage] = useState(null);
  const [drireserror, setdrireserror] = useState("");
  const [dfierror, setdfierror] = useState(false);
  const [documenterror, setdocumenterror] = useState(false);
  const [idnumber, setidnumber] = useState("");
  const [drireserrortwo, setdrireserrortwo] = useState("");
  const [dfierrortwo, setdfierrortwo] = useState(false);

  // document states

  const [idnumbertwo, setidnumbertwo] = useState();
  const [documentissuetwo, setdocumentissuetwo] = useState();
  const [documentexpirytwo, setdocumentexpirytwo] = useState();
  const [modifiedImagenumbertwo, setModifiedImagenumbertwo] = useState(null);
  const [aadhaarImage2, setAadhaarImage2] = useState(null);
  const [varx2, setvarx2] = useState(10);
  const [vary2, setvary2] = useState(10);
  const [documentimageback2, setdocumentimageback2] = useState(null);
  const [aadhaarback2, setAadhaarback2] = useState(null);
  const [varxtwo2, setvarxtwo2] = useState(10);
  const [varytwo2, setvarytwo2] = useState(10);
  const [hideorshow4, sethideorshow4] = useState(false);
  const [hideorshow3, sethideorshow3] = useState(false);
  const [seconddocopen, setseconddocopen] = useState(true);
  const [documentprivateerror, setdocumentprivateerror] = useState(false);
  const [agentresponse, setagentresponse] = useState({});
  const [doc1, setdoc1] = useState(true);
  const [doc2, setdoc2] = useState(true);

  // personal info

  const [agentname, setagentname] = useState();
  const [agentlast, setagentlast] = useState();
  const [agentgender, setagentgender] = useState("Male");
  const [agentnationality, setagentnationality] = useState("india");
  const [agentmobile, setagentmobile] = useState();
  const [agentcompany, setagentcompany] = useState("");
  const [dob, setdob] = useState();
  const [agentaddress, setagentaddress] = useState();
  const [agentpincode, setagentpincode] = useState();
  const [branch, setbranch] = useState("");
  const [locationaddress, setlocationaddress] = useState("");
  const [bpincode, setbpincode] = useState("");

  const [dataerrorstatus, setDataerrorstatus] = useState(false);
  const [butsub, setbutsub] = useState(false);
  const [dataerrortxt, setDataerrortxt] = useState();

  const [dbireserror, setdbireserror] = useState("");
  const [documentprivateerrortwo, setdocumentprivateerrortwo] = useState(false);
  const [varx, setvarx] = useState(10);
  const [vary, setvary] = useState(10);
  const [hideorshowtwo, sethideorshowtwo] = useState(false);
  const [documenttype, setdocumenttype] = useState("PAN");
  const [modifiedImage, setModifiedImage] = useState(null);
  const [errorcls, seterrorcls] = useState({});
  const [agentr_thumb, setagentr_thumb] = useState("");
  const [agentr_index_finger, setagentr_index_finger] = useState("");
  const [agentr_middle_finger, setagentr_middle_finger] = useState("");
  const [agentr_ring_finger, setagentr_ring_finger] = useState("");
  const [agentr_little_finger, setagentr_little_finger] = useState("");
  const [agentl_thumb, setagentl_thumb] = useState("");
  const [agentl_index_finger, setagentl_index_finger] = useState("");
  const [agentl_middle_finger, setagentl_middle_finger] = useState("");
  const [agentl_ring_finger, setagentl_ring_finger] = useState("");
  const [agentl_little_finger, setagentl_little_finger] = useState("");
  const [ansithumbimg2, setansithumbimg2] = useState("");

  const [loading, setloading] = useState(false);
  const [stateerror, setstateerror] = useState("");
  const [cityerror, setcityerror] = useState("");
  const [ansirifimg2, setansirifimg2] = useState("");
  const [ansirmfimg2, setansirmfimg2] = useState("");
  const [ansirrfimg2, setansirrfimg2] = useState("");
  const [ansirlfimg2, setansirlfimg2] = useState("");
  const [ansiltfimg2, setansiltfimg2] = useState("");
  const [ansilifimg2, setansilifimg2] = useState("");
  const [ansilmfimg2, setansilmfimg2] = useState("");
  const [ansilrfimg2, setansilrfimg2] = useState("");
  const [ansillfimg2, setansillfimg2] = useState("");
  const [rightnumberoffingers, setrightnumberoffingers] = useState(0);
  const [leftnumberoffingers, setleftnumberoffingers] = useState(0);
  const [rtferror, setrtferror] = useState(false);
  const [riferror, setriferror] = useState(false);
  const [rmferror, setrmferror] = useState(false);
  const [rrferror, setrrferror] = useState(false);
  const [rlferror, setrlferror] = useState(false);
  const [ltferror, setltferror] = useState(false);
  const [liferror, setliferror] = useState(false);
  const [lmferror, setlmferror] = useState(false);
  const [lrferror, setlrferror] = useState(false);
  const [llferror, setllferror] = useState(false);
  const [dbierror, setdbierror] = useState(false);
  const [documentimagebacktwo, setdocumentimagebacktwo] = useState(null);
  const [hideorshow, sethideorshow] = useState(false);
  const [srtloader, setsrtloader] = useState(true);
  const countries = csc.getAllCountries();
  const gettokinval = gettoken();
  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.id,
    ...country,
  }));


  const [reloadin,setreloadin] = useState(true)


  const reloadcam = ()=>{
  setreloadin(false)
  setTimeout(() => {
  setreloadin(true)
  }, 1000);
  }
  const updatedStates = (countryId) =>
    csc
      .getStatesOfCountry(countryId)
      .map((state) => ({ label: state.name, value: state.id, ...state }));

  const updatedCities = (stateId) =>
    csc
      .getCitiesOfState(stateId)
      .map((city) => ({ label: city.name, value: city.id, ...city }));







// Decentro Document Verify here
const [idno2, setidno2] = useState(0);
const [idno, setidno] = useState(0);
const [kycfullname, setkycfullname] = useState('');
const [documentsupermsg, setdocumentsupermsg] = useState('');
const [documentsuperres, setdocumentsuperres] = useState('');
const [documentsuperresuniqe, setdocumentsuperresuniqe] = useState('');
const [documentsuperrestwo, setdocumentsuperrestwo] = useState('');
const [documentsupermsguniqe, setdocumentsupermsguniqe] = useState('');
const [kycfullnametwo, setkycfullnametwo] = useState('');
const [checkdocumenterror, setcheckdocumenterror] = useState(false);

const documentverify = async () => {
  if(documenttype == "defualt" || documenttype == undefined || idnumber == undefined || idnumber == ''){
    setdocumenterror(true)
  }else{
    setdocumentloader(true)
    const boolenvalue = true;
    const reason = 'using this api in kfc project'
    var data = {}
    var inputDate = new Date(dob);
    var options = { day: 'numeric', month: 'numeric', year: 'numeric' };
    var formattedDate = inputDate.toLocaleDateString('en-GB', options).replace(/\//g, '-'); 

        if(documenttype == "DRIVING_LICENSE"){
            var inputDate = new Date(dob);
            var options = { year: 'numeric' , month: 'numeric' , day: 'numeric' };
            var formattedDate = inputDate.toLocaleDateString('en-GB', options).replace(/\//g, '-'); 
             data = {
              // "reference_id":`REF${randomNumber}`,
              "document_type":documenttype,
              "id_number": idnumber,
              "dob": formattedDate
            }
          }else{
            data = {
                // "reference_id":`REF${randomNumber}`,
                // "document_type":"VOTERID",
                "document_type":documenttype,
                "id_number": idnumber,
                // "id_number": "YD15593272",
                "dob": formattedDate,
                "consent": boolenvalue,
                "consent_purpose":reason
              }
          }
    
    try {
      const config = {
        headers: {
          accept:'application/json',
          Authorization: `Bearer ${gettokinval.access}`,
        },
      };
      let url = `https://backend.kycfirst.io/api/search/`;
      const response = await axios.post(url, data);
      // console.log("00000000000000000000000",response)
          if(response.data.kycStatus == "SUCCESS"){
      
            // setdocumentgrandres(response.data.data)
            setdocumentsuperres(response.data.kycStatus)
            setdocumentsuperresverify(response.data.kycStatus)
            setdocumentsupermsg(response.data.message)
            setkycfullname(response.data.kycResult.name)
            // setdocumentloader(false)
            setidno(1)
            setTimeout(function() {
              setidno(0)
            }, 7000);
            

        }else{
          
           if(response.data.kycStatus == 'FAILURE'){
            // setdocumentloader(false)
            setdocumentsuperres(response.data.kycStatus)
                setdocumentsupermsg(response.data.error.message)
                setidno(2)
                setTimeout(function() {
                  setidno(0)
                 }, 7000);
                setdocumentloader(false)

           }else{
            setdocumentloader(false)
            setdocumentsuperres(response.data.kycStatus)
                setdocumentsupermsg(response.data.error.message)
                setidno(3)
                setTimeout(function() {
                  setidno(0)
                 }, 7000);
           }

            setdocumentloader(false)
        }

    } catch (error) {

  // console.log("we",error)
  setdocumentloader(false)
    }
  
  
  
  }










  // second document verify here

  if(documenttypetwo == "defualt" || documenttypetwo == undefined || idnumbertwo == undefined || idnumbertwo == '' || dob == undefined || dob == null){
    setdocumenterror(true)
    setdocumentloader(false)
  }else{
    setdocumentloader(true)

    if(documenttypetwo == "AADHAAR" || documenttypetwo == "PASSPORT"){

      try {

        // data = {
        //   "reference_id":`REF${randomNumber}`,
        //   "document_type":documenttypetwo,
        //   "id_number": idnumbertwo,
        //   // "id_number": "939393994930",
        //   "dob": formattedDate
        // }  
   if(documenttypetwo == "PASSPORT"){
    var inputDate = new Date(dob);
    var options = { day: 'numeric', month: 'numeric', year: 'numeric' };
    var formattedDate = inputDate.toLocaleDateString('en-GB', options).replace(/\//g, '/'); 
    data = {
      "document":"passport",
      "service":"Identity",
      "itemId":"653f4d6cfb1f470023468784",
      "accessToken":"n34bgabk4h75licwaay3lha04dyciiirk",
      "task":"fetch",
      "essentials":{
            "fileNumber":idnumbertwo,
            "dob":formattedDate
          }
  }
   }else{
      //  data =  {
      //     "document":"aadhar",
      //     "service":"Identity",
      //     "itemId":"653f4d59fb1f470023468782",
      //     "accessToken":"cv9t6wuuatn4hmix2pnuzj49hv8nm4tg1",
      //     "task":"verifyAadhaar",
      //     "essentials":{
      //           "uid":idnumbertwo
      //         }
      // }

      data =  {
        // "document":"aadhar",
        "document":"pan_aadhar_link",
        "service":"Identity",
        "itemId":"653f4d44fb1f47002346877c",
        "accessToken":"ky16sopjgqdpr2mde2w69gp9pyk3bo19",
        // "task":"verifyAadhaar",
        "task":"panAadhaarLinkStatus",
        "essentials":{
          "number":idnumber,
              "uid":idnumbertwo
            }
    }
    }

        const config = {
          headers: {
            accept:'application/json',
            Authorization: `Bearer ${gettokinval.access}`,
          },
        };
        setdocumentloader(true)
        let url = `${process.env.REACT_APP_API_URL}api/DocumentVerification/`;
        const response = await axios.post(url, data);
var jdata = JSON.parse(response.data);
console.log(jdata)
console.log(response)
if(jdata.service == "Identity"){
setdocumentsuperres("SUCCESS")
setdocumentsuperresseond("SUCCESS")
      setdocumentsuperresuniqe("SUCCESS")
      setdocumentsupermsguniqe("Document Verify Successfully")
      if(jdata.response.result.name){
setkycfullnametwo(jdata.response.result.name)
      }
      setidno2(1)
      setTimeout(function() {
        setidno2(0)
       }, 7000);
      setdocumentloader(false)
}else{
console.log("err0r")
setdocumentsuperresuniqe("FAILURE")
    setdocumentsupermsguniqe(jdata.error.message)
        setidno2(2)
        setTimeout(function() {
          setidno2(0)
         }, 7000);
        setdocumentloader(false)
}
// console.log("sigzy response 2",response.data)
  // if(response.data.kycStatus == "SUCCESS"){
    
  //   if(documenttypetwo == "VOTERID"){
  //       setdocumentsuperres("SUCCESS")
  //       setdocumentsuperresuniqe(response.data.kycStatus)
  //       setdocumentsupermsguniqe(response.data.message)
  //       setkycfullnametwo(response.data.kycResult.name)
  //       setidno2(12)
  //       setTimeout(function() {
  //         setidno2(0)
  //        }, 7000);
        setdocumentloader(false)
  
    
  //   }else{
  //       setdocumentsuperresuniqe(response.data.kycStatus)
  //       setkycfullnametwo(response.data.kycResult.name)
  //       setidno2(12)
  //       setTimeout(function() {
  //         setidno2(0)
  //        }, 7000);
  //       setdocumentloader(false)
   
  //   }
  // }else{
  //   if(response.data.kycStatus == 'FAILURE'){
  //     setdocumentsuperresuniqe(response.data.kycStatus)
  //     setdocumentsupermsguniqe(response.data.error.message)
  //         setidno2(12)
  //         setTimeout(function() {
  //           setidno2(0)
  //          }, 7000);
  //         setdocumentloader(false)
  
  //    }else{
  //     setdocumentsuperresuniqe(response.data.kycStatus)
  //     setdocumentsupermsguniqe(response.data.error.message)
  //    }
  //     setidno2(12)
  //     setTimeout(function() {
  //       setidno2(0)
  //      }, 7000);
  //     setdocumentloader(false)
  // }
        
         
          
      } catch (error) {
    // console.log("we",error)
    setdocumentloader(false)
      }
      
    }else{
    const boolenvalue = true;
    const reason = 'using this api in kfc project'
    var data = {}
    var inputDate = new Date(dob);
    var options = { day: 'numeric', month: 'numeric', year: 'numeric' };
    var formattedDate = inputDate.toLocaleDateString('en-GB', options).replace(/\//g, '-'); 
    // if(documenttypetwo == "AADHAAR"){
    //   var fullname;
    //   if(lname != "undefined" && lname != ""){
    //      fullname = `${fname} ${lname}`;
    //   }else{
    //     fullname = fname;
    //   }
    //    data = {
    //     "reference_id":`REF${randomNumber}`,
    //     "document_type":documenttypetwo,
    //     "aadhaar_id": parseFloat(idnumbertwo),
    //     "full_name": fullname.toUpperCase(),
    //     "dob": formattedDate,
    //     "gender": gender == "Male" ? "M" : "F",
    //     "consent": boolenvalue,
    //     "consent_purpose":reason
    //   }
    // }else{


        if(documenttypetwo == "DRIVING_LICENSE"){
          // console.log("check don")
            var inputDate = new Date(dob);
            const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, '0');
    const day = String(inputDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
             data = {
              "document_type":documenttypetwo,
              "id_number": idnumbertwo,
              "dob": formattedDate
            }
          }else{
            data = {
                "document_type":documenttypetwo,
                "id_number": idnumbertwo,
                "dob": formattedDate,
                "consent": boolenvalue,
                "consent_purpose":reason
              }
          }
    // }
    
    try {
      const config = {
        headers: {
          accept:'application/json',
          Authorization: `Bearer ${gettokinval.access}`,
        },
      };
      let url = `https://backend.kycfirst.io/api/search/`;
      const response = await axios.post(url, data);
   
      if(response.data.kycStatus == "SUCCESS"){
        setdocumentsuperresseond("SUCCESS")
        if(documenttypetwo == "VOTERID"){

            setdocumentsuperresuniqe(response.data.kycStatus)
            setdocumentsupermsguniqe(response.data.message)
            setkycfullnametwo(response.data.kycResult.name)

            setidno2(1)
            setTimeout(function() {
              setidno2(0)
             }, 7000);
            setdocumentloader(false)

          
        }else{
          setdocumentsuperresuniqe(response.data.kycStatus)
            setkycfullnametwo(response.data.kycResult.name)
            setidno2(1)
            setTimeout(function() {
              setidno2(0)
             }, 7000);

            setdocumentloader(false)
   
        }
      }else{
        if(response.data.kycStatus == 'FAILURE'){
          setdocumentsuperresuniqe(response.data.kycStatus)
          setdocumentsupermsguniqe(response.data.error.message)
              setidno2(2)
              setTimeout(function() {
                setidno2(0)
               }, 7000);
              setdocumentloader(false)

         }else{
          setdocumentsuperresuniqe(response.data.kycStatus)
          setdocumentsupermsguniqe(response.data.error.message)
          setidno2(3)
              setTimeout(function() {
                setidno2(0)
               }, 7000);
              setdocumentloader(false)
         }
      }
       
       
    } catch (error) {
  // console.log("we",error)
  setdocumentloader(false)

    }
  
  
  
  }
}

  }








// Decentro Document Verify here








const logoutevt = async () =>{
  removeToken()
  nvg('/login',{state:{id:2}})
        const formdata = new FormData()
        formdata.append('test',"test")
         try {
          
          const config = {
            headers: {
              Authorization: `Bearer ${gettokinval.access}`,
            },
          };
          let url = `${process.env.REACT_APP_API_URL}api/logout/`;
          const response = await axios.post(url, formdata, config);
          nvg('/login',{state:{id:2}})
      
         } catch (error) {
          // console.log({statusone:1,message:'Something Went Wrong Try again',error:error})
         }
      
      
      }

  const submitform = async (e) => {
    e.preventDefault();
    if(agentnationality == "india"){
      if(documentsuperresseond !== "SUCCESS"){
        console.log("first live complete 1")
        setcheckdocumenterror(true)
        setTimeout(() => {
          setcheckdocumenterror(false)
        }, 7000);
      }else{
        console.log("first live complete 1 outer")
      if(documentsuperresverify !== "SUCCESS"){
        console.log("first live complete 2")

        setcheckdocumenterror(true)
        setTimeout(() => {
          setcheckdocumenterror(false)
        }, 7000);
      }else{
        console.log("first live complete 2 outer")
        if(documentsuperres !== "SUCCESS" || documentsuperresuniqe !== "SUCCESS"){
        console.log("first live complete 3 outer")

          setcheckdocumenterror(true)
          setTimeout(() => {
            setcheckdocumenterror(false)
          }, 7000);
          }else{
            if (
              agentstate == undefined ||
              agentstate == "" ||
              agentstate == null ||
              lcity == undefined ||
              lcity == "" ||
              lcity == null
            ) {
              if (agentstate == undefined || agentstate == "" || agentstate == null) {
                setstateerror("State is Required");
              } else {
                setstateerror("");
              }
              if (lcity == undefined || lcity == "" || lcity == null) {
                setcityerror("City is Required");
              } else {
                setcityerror("");
              }
            } else {
              setstateerror("");
              setcityerror("");
              if (agentcompany === "") {
                setDataerrorstatus(true);
                setDataerrortxt("please select Corporate ");
              } else {
                setDataerrorstatus(false);
          
                if (
                  rightnumberoffingers == 0 &&
                  gettokinval.email !== "test@gmail.com"
                ) {
                  setfingerperror(true);
                  setrigthfingerserror("Capture atleast One Finger in Your Right Hand");
                } else {
                  setrigthfingerserror("");
                  if (
                    leftnumberoffingers == 0 &&
                    gettokinval.email !== "test@gmail.com"
                  ) {
                    setfingerperror(true);
                    setrigthfingerserror(
                      "Capture atleast One Finger in Your Left Hand"
                    );
                  } else {
                    setfingerperror(false);
                    if (displayphoto == "") {
                      setsandphotoerror(true);
                    } else {
                      setsandphotoerror(false);
          
                      if (notapplicable == false) {
                        //  pan card not available
          
                        if (documenttype == "defualt" || documenttype == undefined) {
                          setdocumentprivateerror(true);
                        } else {
                          setdocumentprivateerror(false);
                          if (modifiedImage == null) {
                            setdfierror(true);
                            setdrireserror("Please Capture Your Document");
                          } else {
                            setdfierror(false);
          
                            if (documentimagebacktwo == null) {
                              setdbierror(true);
                              setdbireserror("Please Capture Your Document");
                            } else {
                              setdbierror(false);
                              if (
                                documenttypetwo == "defualt" ||
                                documenttypetwo == undefined
                              ) {
                                setdocumentprivateerrortwo(true);
                              } else {
                                setdocumentprivateerrortwo(false);
                                if (modifiedImagenumbertwo == null) {
                                  setdfierrortwo(true);
                                  setdrireserrortwo("Please Capture Your Document");
                                } else {
                                  setdfierrortwo(false);
                                  if (documentimageback2 == null) {
                                    setdbierrortwo(true);
                                    setdbireserrortwo("Please Capture Your Document");
                                  } else {
                                    setdbierrortwo(false);
                                    setsrtloader(true);
                                    // setloading(true);
                                    setfingerperror(false);
                                    const formdata = new FormData();
                                    // formdata.append("username",agentusername);
                                    // formdata.append("email",agentemail);
                                    formdata.append("mobile_no", agentmobile);
                                    formdata.append("nationality", agentnationality);
                                    // formdata.append("password",agentpassword);
                                    formdata.append("first_name", agentname);
                                    formdata.append("last_name", agentlast);
                                    formdata.append("gender", agentgender);
                                    formdata.append("photograph", displayphoto);
                                    formdata.append("fathersFullNm", ffn);
                                    formdata.append("document_type2", documenttypetwo);
                                    formdata.append("document_no2", idnumbertwo);
          
                                    if (agentnationality == "india") {
                                      if (notapplicable == false) {
                                        formdata.append("document_type", documenttype);
                                        formdata.append("document_no", idnumber);
                                        formdata.append("docImg_front", modifiedImage);
                                        formdata.append(
                                          "docImg_back",
                                          documentimagebacktwo
                                        );
                                        if (
                                          documentexpiry != undefined ||
                                          documenttype == "DRIVING_LICENSE" ||
                                          documenttype == "PASSPORT"
                                        ) {
                                          formdata.append(
                                            "expiry_date",
                                            documentexpiry
                                          );
                                        } else {
                                          // console.log("not expiry date");
                                        }
                                        if (
                                          documentissue != undefined ||
                                          documenttype == "DRIVING_LICENSE" ||
                                          documenttype == "PASSPORT"
                                        ) {
                                          formdata.append("issue_date", documentissue);
                                        } else {
                                          // console.log("not issue date");
                                        }
                                      } else {
                                        // if(displaydocument !== ''){
                                        //   formdata.append("Form_60_image", displaydocument);
                                        // }
                                        // if(form60 !== null){
                                        //   formdata.append("Form_60_pdf", form60);
                                        // }
                                      }
                                    } else {
                                      formdata.append("document_type", documenttype);
                                      formdata.append("document_no", idnumber);
                                      formdata.append("docImg_front", modifiedImage);
                                      formdata.append(
                                        "docImg_back",
                                        documentimagebacktwo
                                      );
                                      if (
                                        documentexpiry != undefined ||
                                        documenttype == "DRIVING_LICENSE" ||
                                        documenttype == "PASSPORT"
                                      ) {
                                        formdata.append("expiry_date", documentexpiry);
                                      } else {
                                        // console.log("not expiry date");
                                      }
                                      if (
                                        documentissue != undefined ||
                                        documenttype == "DRIVING_LICENSE" ||
                                        documenttype == "PASSPORT"
                                      ) {
                                        formdata.append("issue_date", documentissue);
                                      } else {
                                        // console.log("not issue date");
                                      }
                                    }
          
                                    if (
                                      documentexpirytwo != undefined ||
                                      documenttypetwo == "DRIVING_LICENSE" ||
                                      documenttypetwo == "PASSPORT" || documenttypetwo == "RESIDENT_PERMIT" || documenttypetwo == "VISA" || documenttypetwo == "REFUGEE_CARD"
                                    ) {
                                      formdata.append("expiry_date2", documentexpirytwo);
                                    } else {
                                      // console.log("not expiry date");
                                    }
                                    if (
                                      documentissuetwo != undefined ||
                                      documenttypetwo == "DRIVING_LICENSE" ||
                                      documenttypetwo == "PASSPORT" || documenttypetwo == "RESIDENT_PERMIT" || documenttypetwo == "VISA" || documenttypetwo == "REFUGEE_CARD"
                                    ) {
                                      formdata.append("issue_date2", documentissuetwo);
                                    } else {
                                      // console.log("not issue date");
                                    }
          
                                    formdata.append("company", agentcompany);
                                    // formdata.append("location", agentlocation);
                                    formdata.append("country", agentcountry.label);
                                    formdata.append("state", agentstate.label);
                                    formdata.append("city", lcity.label);
                                    formdata.append("pincode", agentpincode);
                                    formdata.append("address", agentaddress);
                                    formdata.append("dob", dob);
          
                                    if (agentr_thumb !== "") {
                                      formdata.append("r_thumb", agentr_thumb);
                                      formdata.append("ansi_r_thumb", ansithumbimg2);
                                    }
                                    if (agentr_index_finger !== "") {
                                      formdata.append(
                                        "r_index_finger",
                                        agentr_index_finger
                                      );
                                      formdata.append(
                                        "ansi_r_index_finger",
                                        ansirifimg2
                                      );
                                    }
                                    if (agentr_middle_finger !== "") {
                                      formdata.append(
                                        "r_middle_finger",
                                        agentr_middle_finger
                                      );
                                      formdata.append(
                                        "ansi_r_middle_finger",
                                        ansirmfimg2
                                      );
                                    }
                                    if (agentr_ring_finger !== "") {
                                      formdata.append(
                                        "r_ring_finger",
                                        agentr_ring_finger
                                      );
                                      formdata.append(
                                        "ansi_r_ring_finger",
                                        ansirrfimg2
                                      );
                                    }
                                    if (agentr_little_finger !== "") {
                                      formdata.append(
                                        "r_little_finger",
                                        agentr_little_finger
                                      );
                                      formdata.append(
                                        "ansi_r_little_finger",
                                        ansirlfimg2
                                      );
                                    }
                                    if (agentl_thumb !== "") {
                                      formdata.append("l_thumb", agentl_thumb);
                                      formdata.append("ansi_l_thumb", ansiltfimg2);
                                    }
                                    if (agentl_index_finger !== "") {
                                      formdata.append(
                                        "l_index_finger",
                                        agentl_index_finger
                                      );
                                      formdata.append(
                                        "ansi_l_index_finger",
                                        ansilifimg2
                                      );
                                    }
                                    if (agentl_middle_finger !== "") {
                                      formdata.append(
                                        "l_middle_finger",
                                        agentl_middle_finger
                                      );
                                      formdata.append(
                                        "ansi_l_middle_finger",
                                        ansilmfimg2
                                      );
                                    }
                                    if (agentl_ring_finger !== "") {
                                      formdata.append(
                                        "l_ring_finger",
                                        agentl_ring_finger
                                      );
                                      formdata.append(
                                        "ansi_l_ring_finger",
                                        ansilrfimg2
                                      );
                                    }
                                    if (agentl_little_finger !== "") {
                                      formdata.append(
                                        "l_little_finger",
                                        agentl_little_finger
                                      );
                                      formdata.append(
                                        "ansi_l_little_finger",
                                        ansillfimg2
                                      );
                                    }
          
                                    formdata.append(
                                      "docImg_front2",
                                      modifiedImagenumbertwo
                                    );
                                    formdata.append("docImg_back2", documentimageback2);
                                    // if(documentimageback !== null){
                                    // }
                                    try {
                                      const config = {
                                        headers: {
                                          Authorization: `Bearer ${gettokinval.access}`,
                                        },
                                      };
          
                                      let url = `${process.env.REACT_APP_API_URL}api/agent/${gettokinval.id}/`;
                                      const response = await axios.patch(
                                        url,
                                        formdata,
                                        config
                                      );
                                      setagentresponse(response);
                                      // console.log("first id", response);
                                      if (
                                        response.status == 200 ||
                                        response.status == 201
                                      ) {
                                        const formdatatwo = new FormData();
                                        // formdatatwo.append("branch_name", 'Branch');
                                        formdatatwo.append(
                                          "emailID",
                                          gettokinval.email
                                        );
                                        formdatatwo.append("mobile_no", agentmobile);
                                        formdatatwo.append("address", locationaddress);
                                        formdatatwo.append(
                                          "country",
                                          branchcountry.label
                                        );
                                        formdatatwo.append("state", branchstate.label);
                                        formdatatwo.append("city", branchcity.label);
                                        formdatatwo.append("pincode", bpincode);
                                        let urllocation = `${process.env.REACT_APP_API_URL}api/location/`;
                                        const responselocation = await axios.post(
                                          urllocation,
                                          formdatatwo,
                                          config
                                        );
                                        if (
                                          responselocation.status == 200 ||
                                          responselocation.status == 201
                                        ) {
  
  
  
  
  
                                          if(responselocation.data.id){
                                            
  
  
                                            const formdataloc = new FormData();
                                            formdataloc.append("location", responselocation.data.id);
                                      
                                              const config = {
                                                headers: {
                                                  Authorization: `Bearer ${gettokinval.access}`,
                                                },
                                              };
                  
                                              let urlloc = `${process.env.REACT_APP_API_URL}api/agent/${gettokinval.id}/`;
                                              const reagentresponse = await axios.patch(
                                                urlloc,
                                                formdataloc,
                                                config
                                              );
  
                                            if( reagentresponse.status == 200 ||reagentresponse.status == 201){
  
  
  
  
  
  
                                              
                                          const formdatathree = new FormData();
                                          formdatathree.append("amount", parseFloat(planinfo.total_amount));
                                          formdatathree.append("description", `Buy Subscription - ${gettokinval.id}`);
                                        let url = `${process.env.REACT_APP_API_URL}api/wallet/make_purchase/`;
                                        const dedectresponse = await axios.post(
                                          url,
                                          formdatathree,
                                          config
                                        );
                                        if(dedectresponse.data.id){
                                          const newformdata = {
                                            "service_plan_id": planinfo.sub_id
                                          }
                                          let urlbuy = `${process.env.REACT_APP_API_URL}api/subscriptions/`;
                                          const buyresponse = await axios.post(
                                            urlbuy,
                                            newformdata,
                                            config
                                          );
                                          // console.log(";",buyresponse)
                                          setsrtloader(false);
                                          setbutsub(true);
                                          setTimeout(() => {
                                            //  window.location.reload()
                                            setbutsub(false);
                                            logoutevt()
                                          }, 5000);
                                          setagentmobile("");
                                          // setagentpassword("");
                                          setagentname("");
                                          setagentlast("");
                                          setagentcompany("");
                                          // setagentlocation("");
                                          setagentcountry("");
                                          setLcity("");
                                          setagentstate("");
                                          setagentpincode("");
                                          // setagentstatus("");
                                          // setagentconfirmpassword("");
                                          setidnumber("");
                                          setdocumenttype("");
                                          seterrorclass(false);
                                          setagentaddress("");
                                          //   window.location.reload()
                                        } else {
                                          setsrtloader(false);
                                          setpurchaseerror(true);
                                          setTimeout(() => {
                                            setpurchaseerror(false);
                                          }, 5000);
                                        }
                                            }
  
  
  
  
  
                                          }
  
  
  
  
  
  
  
                                        } else {
                                          // console.log(" 1111111111111111111111111")
                                          setsrtloader(false);
                                          seteditlocation(true);
                                          setTimeout(() => {
                                            seteditlocation(false);
                                          }, 5000);
                                        }
                                        // console.log("location id", response);
                                      } else {
                                        // console.log("2222222222222222222222222")
                                        setsrtloader(false);
                                        seteditagenterror(true);
                                        setTimeout(() => {
                                          seteditagenterror(false);
                                        }, 5000);
                                      }
                                    } catch (error) {
                                      setsrtloader(false);
                                      seteditagenterror(true)
                                      setTimeout(() => {
                                        seteditagenterror(false)
                                      }, 5000);
                                      setagentresponse({
                                        statusone: 1,
                                        message: "Something Went Wrong Try again",
                                      });
                                      seterrorclass(true);
                                      seterrorcls(error.response.data);
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
  
  
  
  
  
                      } else {
                        if (
                          documenttypetwo == "defualt" ||
                          documenttypetwo == undefined
                        ) {
                          setdocumentprivateerrortwo(true);
                        } else {
                          setdocumentprivateerrortwo(false);
                          if (modifiedImagenumbertwo == null) {
                            setdfierrortwo(true);
                            setdrireserrortwo("Please Capture Your Document");
                          } else {
                            setdfierrortwo(false);
                            if (documentimageback2 == null) {
                              setdbierrortwo(true);
                              setdbireserrortwo("Please Capture Your Document");
                            } else {
                              setdbierrortwo(false);
          
                              // setloading(true);
                              setsrtloader(true);
                              setfingerperror(false);
                              const formdata = new FormData();
                              // formdata.append("username",agentusername);
                              // formdata.append("email",agentemail);
                              formdata.append("mobile_no", agentmobile);
                              formdata.append("nationality", agentnationality);
                              // formdata.append("password",agentpassword);
                              formdata.append("first_name", agentname);
                              formdata.append("last_name", agentlast);
                              formdata.append("gender", agentgender);
                              formdata.append("photograph", displayphoto);
                              formdata.append("fathersFullNm", ffn);
                              formdata.append("document_type2", documenttypetwo);
                              formdata.append("document_no2", idnumbertwo);
          
                              if (agentnationality == "india") {
                                if (notapplicable == false) {
                                  formdata.append("document_type", documenttype);
                                  formdata.append("document_no", idnumber);
                                  formdata.append("docImg_front", modifiedImage);
                                  formdata.append("docImg_back", documentimagebacktwo);
                                  if (
                                    documentexpiry != undefined ||
                                    documenttype == "DRIVING_LICENSE" ||
                                    documenttype == "PASSPORT"
                                  ) {
                                    formdata.append("expiry_date", documentexpiry);
                                  } else {
                                    // console.log("not expiry date");
                                  }
                                  if (
                                    documentissue != undefined ||
                                    documenttype == "DRIVING_LICENSE" ||
                                    documenttype == "PASSPORT"
                                  ) {
                                    formdata.append("issue_date", documentissue);
                                  } else {
                                    // console.log("not issue date");
                                  }
                                } else {
                                  // if(displaydocument !== ''){
                                  //   formdata.append("Form_60_image", displaydocument);
                                  // }
                                  // if(form60 !== null){
                                  //   formdata.append("Form_60_pdf", form60);
                                  // }
                                }
                              } else {
                                formdata.append("document_type", documenttype);
                                formdata.append("document_no", idnumber);
                                formdata.append("docImg_front", modifiedImage);
                                formdata.append("docImg_back", documentimagebacktwo);
                                if (
                                  documentexpiry != undefined ||
                                  documenttype == "DRIVING_LICENSE" ||
                                  documenttype == "PASSPORT"
                                ) {
                                  formdata.append("expiry_date", documentexpiry);
                                } else {
                                  // console.log("not expiry date");
                                }
                                if (
                                  documentissue != undefined ||
                                  documenttype == "DRIVING_LICENSE" ||
                                  documenttype == "PASSPORT"
                                ) {
                                  formdata.append("issue_date", documentissue);
                                } else {
                                  // console.log("not issue date");
                                }
                              }
          
                              if (
                                documentexpirytwo != undefined ||
                                documenttypetwo == "DRIVING_LICENSE" ||
                                documenttypetwo == "PASSPORT" || documenttypetwo == "RESIDENT_PERMIT" || documenttypetwo == "VISA" || documenttypetwo == "REFUGEE_CARD"
                              ) {
                                formdata.append("expiry_date2", documentexpirytwo);
                              } else {
                                // console.log("not expiry date");
                              }
                              if (
                                documentissuetwo != undefined ||
                                documenttypetwo == "DRIVING_LICENSE" ||
                                documenttypetwo == "PASSPORT" || documenttypetwo == "RESIDENT_PERMIT" || documenttypetwo == "VISA" || documenttypetwo == "REFUGEE_CARD"
                              ) {
                                formdata.append("issue_date2", documentissuetwo);
                              } else {
                                // console.log("not issue date");
                              }
          
                              formdata.append("company", agentcompany);
                              // formdata.append("location", agentlocation);
                              formdata.append("country", agentcountry.label);
                              formdata.append("state", agentstate.label);
                              formdata.append("city", lcity.label);
                              formdata.append("pincode", agentpincode);
                              formdata.append("address", agentaddress);
                              formdata.append("dob", dob);
          
          
                              if (agentr_thumb !== "") {
                                formdata.append("r_thumb", agentr_thumb);
                                formdata.append("ansi_r_thumb", ansithumbimg2);
                              }
                              if (agentr_index_finger !== "") {
                                formdata.append("r_index_finger", agentr_index_finger);
                                formdata.append("ansi_r_index_finger", ansirifimg2);
                              }
                              if (agentr_middle_finger !== "") {
                                formdata.append(
                                  "r_middle_finger",
                                  agentr_middle_finger
                                );
                                formdata.append("ansi_r_middle_finger", ansirmfimg2);
                              }
                              if (agentr_ring_finger !== "") {
                                formdata.append("r_ring_finger", agentr_ring_finger);
                                formdata.append("ansi_r_ring_finger", ansirrfimg2);
                              }
                              if (agentr_little_finger !== "") {
                                formdata.append(
                                  "r_little_finger",
                                  agentr_little_finger
                                );
                                formdata.append("ansi_r_little_finger", ansirlfimg2);
                              }
                              if (agentl_thumb !== "") {
                                formdata.append("l_thumb", agentl_thumb);
                                formdata.append("ansi_l_thumb", ansiltfimg2);
                              }
                              if (agentl_index_finger !== "") {
                                formdata.append("l_index_finger", agentl_index_finger);
                                formdata.append("ansi_l_index_finger", ansilifimg2);
                              }
                              if (agentl_middle_finger !== "") {
                                formdata.append(
                                  "l_middle_finger",
                                  agentl_middle_finger
                                );
                                formdata.append("ansi_l_middle_finger", ansilmfimg2);
                              }
                              if (agentl_ring_finger !== "") {
                                formdata.append("l_ring_finger", agentl_ring_finger);
                                formdata.append("ansi_l_ring_finger", ansilrfimg2);
                              }
                              if (agentl_little_finger !== "") {
                                formdata.append(
                                  "l_little_finger",
                                  agentl_little_finger
                                );
                                formdata.append("ansi_l_little_finger", ansillfimg2);
                              }
          
                              formdata.append("docImg_front2", modifiedImagenumbertwo);
                              formdata.append("docImg_back2", documentimageback2);
                              // if(documentimageback !== null){
                              // }
                              try {
                                const config = {
                                  headers: {
                                    Authorization: `Bearer ${gettokinval.access}`,
                                  },
                                };
          
                                let url = `${process.env.REACT_APP_API_URL}api/agent/${gettokinval.id}/`;
                                const response = await axios.patch(
                                  url,
                                  formdata,
                                  config
                                );
                                setagentresponse(response);
                                // console.log("first id", response);
                                if (response.status == 200 || response.status == 201) {
                                  const formdatatwo = new FormData();
                                  // formdatatwo.append("branch_name", 'Branch');
                                  formdatatwo.append("emailID", gettokinval.email);
                                  formdatatwo.append("mobile_no", agentmobile);
                                  formdatatwo.append("address", locationaddress);
                                  formdatatwo.append("country", branchcountry.label);
                                  formdatatwo.append("state", branchstate.label);
                                  formdatatwo.append("city", branchcity.label);
                                  formdatatwo.append("pincode", bpincode);
                                  let urllocation = `${process.env.REACT_APP_API_URL}api/location/`;
                                  const responselocation = await axios.post(
                                    urllocation,
                                    formdatatwo,
                                    config
                                  );
                                  if (
                                    responselocation.status == 200 ||
                                    responselocation.status == 201
                                  ) {
  
  
  
  
  
  
  
  
                                    if(responselocation.data.id){
                                            
  
  
                                      const formdataloc = new FormData();
                                      formdataloc.append("location", responselocation.data.id);
                                
                                        const config = {
                                          headers: {
                                            Authorization: `Bearer ${gettokinval.access}`,
                                          },
                                        };
            
                                        let urlloc = `${process.env.REACT_APP_API_URL}api/agent/${gettokinval.id}/`;
                                        const reagentresponse = await axios.patch(
                                          urlloc,
                                          formdataloc,
                                          config
                                        );
  
                                      if( reagentresponse.status == 200 ||reagentresponse.status == 201){
  
  
  
  
  
                                        const formdatathree = new FormData();
                                        // if(idno == 1){
                                          formdatathree.append("amount", parseFloat(planinfo.total_amount));
                                          formdatathree.append("description", `Buy Subscription - ${gettokinval.id} - ${gettokinval.first_name} ${gettokinval.last_name}`);
                                        let url = `${process.env.REACT_APP_API_URL}api/wallet/make_purchase/`;
                                        const dedectresponse = await axios.post(
                                          url,
                                          formdatathree,
                                          config
                                        );
                                        if (dedectresponse.data.id) {
                                          const newformdata = {
                                            "service_plan_id": planinfo.sub_id
                                          }
                                          let urlbuy = `${process.env.REACT_APP_API_URL}api/subscriptions/`;
                                          const buyresponse = await axios.post(
                                            urlbuy,
                                            newformdata,
                                            config
                                          );
                                          // console.log(";",buyresponse)
                                          setsrtloader(false);
                                          setbutsub(true);
                                          setTimeout(() => {
                                            setbutsub(false);
                                            // window.location.reload()
                                          }, 5000);
                                          //  setloading(false);
                                          // setagentusername("");
                                          // setagentemail("");
                                          setagentmobile("");
                                          // setagentpassword("");
                                          setagentname("");
                                          setagentlast("");
                                          setagentcompany("");
                                          // setagentlocation("");
                                          setagentcountry("");
                                          setLcity("");
                                          setagentstate("");
                                          setagentpincode("");
                                          // setagentstatus("");
                                          // setagentconfirmpassword("");
                                          setidnumber("");
                                          setdocumenttype("");
                                          seterrorclass(false);
                                          setagentaddress("");
                                          //   window.location.reload()
                                        } else {
                                          setsrtloader(false);
                                          setpurchaseerror(true);
                                          setTimeout(() => {
                                            setpurchaseerror(false);
                                          }, 5000);
                                        }
                                      }
  
  
  
  
  
                                    }
  
  
  
  
  
  
  
  
                                    
                                   
                                  } else {
  
  
  
  
  
  
  
  
                                    setsrtloader(false);
                                    seteditlocation(true);
                                    setTimeout(() => {
                                      seteditlocation(false);
                                    }, 5000);
                                  }
                                  // console.log("location id", response);
                                } else {
                                  setsrtloader(false);
                                  seteditagenterror(true);
                                  setTimeout(() => {
                                    seteditagenterror(false);
                                  }, 5000);
                                }
          
                              } catch (error) {
                                setsrtloader(false);
                                seteditagenterror(true)
                                setTimeout(() => {
                                  seteditagenterror(false)
                                }, 5000);
                                setagentresponse({
                                  statusone: 1,
                                  message: "Something Went Wrong Try again",
                                });
                                seterrorclass(true);
                                seterrorcls(error.response.data);
                              }
                            }
                          }
                        }
                      }
                      //  pan card not available
          
                    }
                  }
                }
              }
            }
          }
      }
    }
      
    }else{










// without verifction
          if (
            agentstate == undefined ||
            agentstate == "" ||
            agentstate == null ||
            lcity == undefined ||
            lcity == "" ||
            lcity == null
          ) {
            if (agentstate == undefined || agentstate == "" || agentstate == null) {
              setstateerror("State is Required");
            } else {
              setstateerror("");
            }
            if (lcity == undefined || lcity == "" || lcity == null) {
              setcityerror("City is Required");
            } else {
              setcityerror("");
            }
          } else {
            setstateerror("");
            setcityerror("");
            if (agentcompany === "") {
              setDataerrorstatus(true);
              setDataerrortxt("please select Corporate ");
            } else {
              setDataerrorstatus(false);
        
              if (
                rightnumberoffingers == 0 &&
                gettokinval.email !== "test@gmail.com"
              ) {
                setfingerperror(true);
                setrigthfingerserror("Capture atleast One Finger in Your Right Hand");
              } else {
                setrigthfingerserror("");
                if (
                  leftnumberoffingers == 0 &&
                  gettokinval.email !== "test@gmail.com"
                ) {
                  setfingerperror(true);
                  setrigthfingerserror(
                    "Capture atleast One Finger in Your Left Hand"
                  );
                } else {
                  setfingerperror(false);
                  if (displayphoto == "") {
                    setsandphotoerror(true);
                  } else {
                    setsandphotoerror(false);
        
                    if (notapplicable == false) {
                      //  pan card not available
        
                      if (documenttype == "defualt" || documenttype == undefined) {
                        setdocumentprivateerror(true);
                      } else {
                        setdocumentprivateerror(false);
                        if (modifiedImage == null) {
                          setdfierror(true);
                          setdrireserror("Please Capture Your Document");
                        } else {
                          setdfierror(false);
        
                          if (documentimagebacktwo == null) {
                            setdbierror(true);
                            setdbireserror("Please Capture Your Document");
                          } else {
                            setdbierror(false);
                            if (
                              documenttypetwo == "defualt" ||
                              documenttypetwo == undefined
                            ) {
                              setdocumentprivateerrortwo(true);
                            } else {
                              setdocumentprivateerrortwo(false);
                              if (modifiedImagenumbertwo == null) {
                                setdfierrortwo(true);
                                setdrireserrortwo("Please Capture Your Document");
                              } else {
                                setdfierrortwo(false);
                                if (documentimageback2 == null) {
                                  setdbierrortwo(true);
                                  setdbireserrortwo("Please Capture Your Document");
                                } else {
                                  setdbierrortwo(false);
                                  setsrtloader(true);
                                  // setloading(true);
                                  setfingerperror(false);
                                  const formdata = new FormData();
                                  // formdata.append("username",agentusername);
                                  // formdata.append("email",agentemail);
                                  formdata.append("mobile_no", agentmobile);
                                  formdata.append("nationality", agentnationality);
                                  // formdata.append("password",agentpassword);
                                  formdata.append("first_name", agentname);
                                  formdata.append("last_name", agentlast);
                                  formdata.append("gender", agentgender);
                                  formdata.append("photograph", displayphoto);
                                  formdata.append("fathersFullNm", ffn);
                                  formdata.append("document_type2", documenttypetwo);
                                  formdata.append("document_no2", idnumbertwo);
        
                                  if (agentnationality == "india") {
                                    if (notapplicable == false) {
                                      formdata.append("document_type", documenttype);
                                      formdata.append("document_no", idnumber);
                                      formdata.append("docImg_front", modifiedImage);
                                      formdata.append(
                                        "docImg_back",
                                        documentimagebacktwo
                                      );
                                      if (
                                        documentexpiry != undefined ||
                                        documenttype == "DRIVING_LICENSE" ||
                                        documenttype == "PASSPORT"
                                      ) {
                                        formdata.append(
                                          "expiry_date",
                                          documentexpiry
                                        );
                                      } else {
                                        // console.log("not expiry date");
                                      }
                                      if (
                                        documentissue != undefined ||
                                        documenttype == "DRIVING_LICENSE" ||
                                        documenttype == "PASSPORT"
                                      ) {
                                        formdata.append("issue_date", documentissue);
                                      } else {
                                        // console.log("not issue date");
                                      }
                                    } else {
                                      // if(displaydocument !== ''){
                                      //   formdata.append("Form_60_image", displaydocument);
                                      // }
                                      // if(form60 !== null){
                                      //   formdata.append("Form_60_pdf", form60);
                                      // }
                                    }
                                  } else {
                                    formdata.append("document_type", documenttype);
                                    formdata.append("document_no", idnumber);
                                    formdata.append("docImg_front", modifiedImage);
                                    formdata.append(
                                      "docImg_back",
                                      documentimagebacktwo
                                    );
                                    if (
                                      documentexpiry != undefined ||
                                      documenttype == "DRIVING_LICENSE" ||
                                      documenttype == "PASSPORT"
                                    ) {
                                      formdata.append("expiry_date", documentexpiry);
                                    } else {
                                      // console.log("not expiry date");
                                    }
                                    if (
                                      documentissue != undefined ||
                                      documenttype == "DRIVING_LICENSE" ||
                                      documenttype == "PASSPORT"
                                    ) {
                                      formdata.append("issue_date", documentissue);
                                    } else {
                                      // console.log("not issue date");
                                    }
                                  }
        
                                  if (
                                    documentexpirytwo != undefined ||
                                    documenttypetwo == "DRIVING_LICENSE" ||
                                    documenttypetwo == "PASSPORT" || documenttypetwo == "RESIDENT_PERMIT" || documenttypetwo == "VISA" || documenttypetwo == "REFUGEE_CARD"
                                  ) {
                                    formdata.append("expiry_date2", documentexpirytwo);
                                  } else {
                                    // console.log("not expiry date");
                                  }
                                  if (
                                    documentissuetwo != undefined ||
                                    documenttypetwo == "DRIVING_LICENSE" ||
                                    documenttypetwo == "PASSPORT" || documenttypetwo == "RESIDENT_PERMIT" || documenttypetwo == "VISA" || documenttypetwo == "REFUGEE_CARD"
                                  ) {
                                    formdata.append("issue_date2", documentissuetwo);
                                  } else {
                                    // console.log("not issue date");
                                  }
        
                                  formdata.append("company", agentcompany);
                                  // formdata.append("location", agentlocation);
                                  formdata.append("country", agentcountry.label);
                                  formdata.append("state", agentstate.label);
                                  formdata.append("city", lcity.label);
                                  formdata.append("pincode", agentpincode);
                                  formdata.append("address", agentaddress);
                                  formdata.append("dob", dob);
        
                                  if (agentr_thumb !== "") {
                                    formdata.append("r_thumb", agentr_thumb);
                                    formdata.append("ansi_r_thumb", ansithumbimg2);
                                  }
                                  if (agentr_index_finger !== "") {
                                    formdata.append(
                                      "r_index_finger",
                                      agentr_index_finger
                                    );
                                    formdata.append(
                                      "ansi_r_index_finger",
                                      ansirifimg2
                                    );
                                  }
                                  if (agentr_middle_finger !== "") {
                                    formdata.append(
                                      "r_middle_finger",
                                      agentr_middle_finger
                                    );
                                    formdata.append(
                                      "ansi_r_middle_finger",
                                      ansirmfimg2
                                    );
                                  }
                                  if (agentr_ring_finger !== "") {
                                    formdata.append(
                                      "r_ring_finger",
                                      agentr_ring_finger
                                    );
                                    formdata.append(
                                      "ansi_r_ring_finger",
                                      ansirrfimg2
                                    );
                                  }
                                  if (agentr_little_finger !== "") {
                                    formdata.append(
                                      "r_little_finger",
                                      agentr_little_finger
                                    );
                                    formdata.append(
                                      "ansi_r_little_finger",
                                      ansirlfimg2
                                    );
                                  }
                                  if (agentl_thumb !== "") {
                                    formdata.append("l_thumb", agentl_thumb);
                                    formdata.append("ansi_l_thumb", ansiltfimg2);
                                  }
                                  if (agentl_index_finger !== "") {
                                    formdata.append(
                                      "l_index_finger",
                                      agentl_index_finger
                                    );
                                    formdata.append(
                                      "ansi_l_index_finger",
                                      ansilifimg2
                                    );
                                  }
                                  if (agentl_middle_finger !== "") {
                                    formdata.append(
                                      "l_middle_finger",
                                      agentl_middle_finger
                                    );
                                    formdata.append(
                                      "ansi_l_middle_finger",
                                      ansilmfimg2
                                    );
                                  }
                                  if (agentl_ring_finger !== "") {
                                    formdata.append(
                                      "l_ring_finger",
                                      agentl_ring_finger
                                    );
                                    formdata.append(
                                      "ansi_l_ring_finger",
                                      ansilrfimg2
                                    );
                                  }
                                  if (agentl_little_finger !== "") {
                                    formdata.append(
                                      "l_little_finger",
                                      agentl_little_finger
                                    );
                                    formdata.append(
                                      "ansi_l_little_finger",
                                      ansillfimg2
                                    );
                                  }
        
                                  formdata.append(
                                    "docImg_front2",
                                    modifiedImagenumbertwo
                                  );
                                  formdata.append("docImg_back2", documentimageback2);
                                  // if(documentimageback !== null){
                                  // }
                                  try {
                                    const config = {
                                      headers: {
                                        Authorization: `Bearer ${gettokinval.access}`,
                                      },
                                    };
        
                                    let url = `${process.env.REACT_APP_API_URL}api/agent/${gettokinval.id}/`;
                                    const response = await axios.patch(
                                      url,
                                      formdata,
                                      config
                                    );
                                    setagentresponse(response);
                                    // console.log("first id", response);
                                    if (
                                      response.status == 200 ||
                                      response.status == 201
                                    ) {

                                      
                                      const formdatatwo = new FormData();
                                      // formdatatwo.append("branch_name", 'Branch');
                                      formdatatwo.append(
                                        "emailID",
                                        gettokinval.email
                                      );
                                      formdatatwo.append("mobile_no", agentmobile);
                                      formdatatwo.append("address", locationaddress);
                                      formdatatwo.append(
                                        "country",
                                        branchcountry.label
                                      );
                                      formdatatwo.append("state", branchstate.label);
                                      formdatatwo.append("city", branchcity.label);
                                      formdatatwo.append("pincode", bpincode);
                                      let urllocation = `${process.env.REACT_APP_API_URL}api/location/`;
                                      const responselocation = await axios.post(
                                        urllocation,
                                        formdatatwo,
                                        config
                                      );
                                      if (
                                        responselocation.status == 200 ||
                                        responselocation.status == 201
                                      ) {






                                        if(responselocation.data.id){
                                          


                                          const formdataloc = new FormData();
                                          formdataloc.append("location", responselocation.data.id);
                                    
                                            const config = {
                                              headers: {
                                                Authorization: `Bearer ${gettokinval.access}`,
                                              },
                                            };
                
                                            let urlloc = `${process.env.REACT_APP_API_URL}api/agent/${gettokinval.id}/`;
                                            const reagentresponse = await axios.patch(
                                              urlloc,
                                              formdataloc,
                                              config
                                            );

                                          if( reagentresponse.status == 200 ||reagentresponse.status == 201){







                                            const formdatathree = new FormData();
                                            formdatathree.append("amount", parseFloat(planinfo.total_amount));
                                            formdatathree.append("description", `Buy Subscription - ${gettokinval.id}`);
                                          let url = `${process.env.REACT_APP_API_URL}api/wallet/make_purchase/`;
                                          const dedectresponse = await axios.post(
                                            url,
                                            formdatathree,
                                            config
                                          );
                                          if(dedectresponse.data.id){
                                            const newformdata = {
                                              "service_plan_id": planinfo.sub_id
                                            }
                                            let urlbuy = `${process.env.REACT_APP_API_URL}api/subscriptions/`;
                                            const buyresponse = await axios.post(
                                              urlbuy,
                                              newformdata,
                                              config
                                            );
                                            // console.log(";",buyresponse)
                                            setsrtloader(false);
                                            setbutsub(true);
                                            setTimeout(() => {
                                              //  window.location.reload()
                                              setbutsub(false);
                                              logoutevt()
                                            }, 5000);
                                            setagentmobile("");
                                            // setagentpassword("");
                                            setagentname("");
                                            setagentlast("");
                                            setagentcompany("");
                                            // setagentlocation("");
                                            setagentcountry("");
                                            setLcity("");
                                            setagentstate("");
                                            setagentpincode("");
                                            // setagentstatus("");
                                            // setagentconfirmpassword("");
                                            setidnumber("");
                                            setdocumenttype("");
                                            seterrorclass(false);
                                            setagentaddress("");
                                            //   window.location.reload()
                                          } else {
                                            setsrtloader(false);
                                            setpurchaseerror(true);
                                            setTimeout(() => {
                                              setpurchaseerror(false);
                                            }, 5000);
                                          }
                                          }





                                        }







                                        
                                    
                                      } else {
                                        // console.log(" 1111111111111111111111111")
                                        setsrtloader(false);
                                        seteditlocation(true);
                                        setTimeout(() => {
                                          seteditlocation(false);
                                        }, 5000);
                                      }
                                      // console.log("location id", response);
                                    } else {
                                      // console.log("2222222222222222222222222")
                                      setsrtloader(false);
                                      seteditagenterror(true);
                                      setTimeout(() => {
                                        seteditagenterror(false);
                                      }, 5000);
                                    }
                                  } catch (error) {
                                    setsrtloader(false);
                                    seteditagenterror(true)
                                    setTimeout(() => {
                                      seteditagenterror(false)
                                    }, 5000);
                                    setagentresponse({
                                      statusone: 1,
                                      message: "Something Went Wrong Try again",
                                    });
                                    seterrorclass(true);
                                    seterrorcls(error.response.data);
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    } else {
                      if (
                        documenttypetwo == "defualt" ||
                        documenttypetwo == undefined
                      ) {
                        setdocumentprivateerrortwo(true);
                      } else {
                        setdocumentprivateerrortwo(false);
                        if (modifiedImagenumbertwo == null) {
                          setdfierrortwo(true);
                          setdrireserrortwo("Please Capture Your Document");
                        } else {
                          setdfierrortwo(false);
                          if (documentimageback2 == null) {
                            setdbierrortwo(true);
                            setdbireserrortwo("Please Capture Your Document");
                          } else {
                            setdbierrortwo(false);
        
                            // setloading(true);
                            setsrtloader(true);
                            setfingerperror(false);
                            const formdata = new FormData();
                            // formdata.append("username",agentusername);
                            // formdata.append("email",agentemail);
                            formdata.append("mobile_no", agentmobile);
                            formdata.append("nationality", agentnationality);
                            // formdata.append("password",agentpassword);
                            formdata.append("first_name", agentname);
                            formdata.append("last_name", agentlast);
                            formdata.append("gender", agentgender);
                            formdata.append("photograph", displayphoto);
                            formdata.append("fathersFullNm", ffn);
                            formdata.append("document_type2", documenttypetwo);
                            formdata.append("document_no2", idnumbertwo);
        
                            if (agentnationality == "india") {
                              if (notapplicable == false) {
                                formdata.append("document_type", documenttype);
                                formdata.append("document_no", idnumber);
                                formdata.append("docImg_front", modifiedImage);
                                formdata.append("docImg_back", documentimagebacktwo);
                                if (
                                  documentexpiry != undefined ||
                                  documenttype == "DRIVING_LICENSE" ||
                                  documenttype == "PASSPORT"
                                ) {
                                  formdata.append("expiry_date", documentexpiry);
                                } else {
                                  // console.log("not expiry date");
                                }
                                if (
                                  documentissue != undefined ||
                                  documenttype == "DRIVING_LICENSE" ||
                                  documenttype == "PASSPORT"
                                ) {
                                  formdata.append("issue_date", documentissue);
                                } else {
                                  // console.log("not issue date");
                                }
                              } else {
                                // if(displaydocument !== ''){
                                //   formdata.append("Form_60_image", displaydocument);
                                // }
                                // if(form60 !== null){
                                //   formdata.append("Form_60_pdf", form60);
                                // }
                              }
                            } else {
                              formdata.append("document_type", documenttype);
                              formdata.append("document_no", idnumber);
                              formdata.append("docImg_front", modifiedImage);
                              formdata.append("docImg_back", documentimagebacktwo);
                              if (
                                documentexpiry != undefined ||
                                documenttype == "DRIVING_LICENSE" ||
                                documenttype == "PASSPORT"
                              ) {
                                formdata.append("expiry_date", documentexpiry);
                              } else {
                                // console.log("not expiry date");
                              }
                              if (
                                documentissue != undefined ||
                                documenttype == "DRIVING_LICENSE" ||
                                documenttype == "PASSPORT"
                              ) {
                                formdata.append("issue_date", documentissue);
                              } else {
                                // console.log("not issue date");
                              }
                            }
        
                            if (
                              documentexpirytwo != undefined ||
                              documenttypetwo == "DRIVING_LICENSE" ||
                              documenttypetwo == "PASSPORT" || documenttypetwo == "RESIDENT_PERMIT" || documenttypetwo == "VISA" || documenttypetwo == "REFUGEE_CARD"
                            ) {
                              formdata.append("expiry_date2", documentexpirytwo);
                            } else {
                              // console.log("not expiry date");
                            }
                            if (
                              documentissuetwo != undefined ||
                              documenttypetwo == "DRIVING_LICENSE" ||
                              documenttypetwo == "PASSPORT" || documenttypetwo == "RESIDENT_PERMIT" || documenttypetwo == "VISA" || documenttypetwo == "REFUGEE_CARD"
                            ) {
                              formdata.append("issue_date2", documentissuetwo);
                            } else {
                              // console.log("not issue date");
                            }
        
                            formdata.append("company", agentcompany);
                            // formdata.append("location", agentlocation);
                            formdata.append("country", agentcountry.label);
                            formdata.append("state", agentstate.label);
                            formdata.append("city", lcity.label);
                            formdata.append("pincode", agentpincode);
                            formdata.append("address", agentaddress);
                            formdata.append("dob", dob);
        
        
                            if (agentr_thumb !== "") {
                              formdata.append("r_thumb", agentr_thumb);
                              formdata.append("ansi_r_thumb", ansithumbimg2);
                            }
                            if (agentr_index_finger !== "") {
                              formdata.append("r_index_finger", agentr_index_finger);
                              formdata.append("ansi_r_index_finger", ansirifimg2);
                            }
                            if (agentr_middle_finger !== "") {
                              formdata.append(
                                "r_middle_finger",
                                agentr_middle_finger
                              );
                              formdata.append("ansi_r_middle_finger", ansirmfimg2);
                            }
                            if (agentr_ring_finger !== "") {
                              formdata.append("r_ring_finger", agentr_ring_finger);
                              formdata.append("ansi_r_ring_finger", ansirrfimg2);
                            }
                            if (agentr_little_finger !== "") {
                              formdata.append(
                                "r_little_finger",
                                agentr_little_finger
                              );
                              formdata.append("ansi_r_little_finger", ansirlfimg2);
                            }
                            if (agentl_thumb !== "") {
                              formdata.append("l_thumb", agentl_thumb);
                              formdata.append("ansi_l_thumb", ansiltfimg2);
                            }
                            if (agentl_index_finger !== "") {
                              formdata.append("l_index_finger", agentl_index_finger);
                              formdata.append("ansi_l_index_finger", ansilifimg2);
                            }
                            if (agentl_middle_finger !== "") {
                              formdata.append(
                                "l_middle_finger",
                                agentl_middle_finger
                              );
                              formdata.append("ansi_l_middle_finger", ansilmfimg2);
                            }
                            if (agentl_ring_finger !== "") {
                              formdata.append("l_ring_finger", agentl_ring_finger);
                              formdata.append("ansi_l_ring_finger", ansilrfimg2);
                            }
                            if (agentl_little_finger !== "") {
                              formdata.append(
                                "l_little_finger",
                                agentl_little_finger
                              );
                              formdata.append("ansi_l_little_finger", ansillfimg2);
                            }
        
                            formdata.append("docImg_front2", modifiedImagenumbertwo);
                            formdata.append("docImg_back2", documentimageback2);
                            // if(documentimageback !== null){
                            // }
                            try {
                              const config = {
                                headers: {
                                  Authorization: `Bearer ${gettokinval.access}`,
                                },
                              };
        
                              let url = `${process.env.REACT_APP_API_URL}api/agent/${gettokinval.id}/`;
                              const response = await axios.patch(
                                url,
                                formdata,
                                config
                              );
                              setagentresponse(response);
                              // console.log("first id", response);
                              if (response.status == 200 || response.status == 201) {
                                const formdatatwo = new FormData();
                                // formdatatwo.append("branch_name", 'Branch');
                                formdatatwo.append("emailID", gettokinval.email);
                                formdatatwo.append("mobile_no", agentmobile);
                                formdatatwo.append("address", locationaddress);
                                formdatatwo.append("country", branchcountry.label);
                                formdatatwo.append("state", branchstate.label);
                                formdatatwo.append("city", branchcity.label);
                                formdatatwo.append("pincode", bpincode);
                                let urllocation = `${process.env.REACT_APP_API_URL}api/location/`;
                                const responselocation = await axios.post(
                                  urllocation,
                                  formdatatwo,
                                  config
                                );
                                if (
                                  responselocation.status == 200 ||
                                  responselocation.status == 201
                                ) {



                                  if(responselocation.data.id){
                                          


                                    const formdataloc = new FormData();
                                    formdataloc.append("location", responselocation.data.id);
                              
                                      const config = {
                                        headers: {
                                          Authorization: `Bearer ${gettokinval.access}`,
                                        },
                                      };
          
                                      let urlloc = `${process.env.REACT_APP_API_URL}api/agent/${gettokinval.id}/`;
                                      const reagentresponse = await axios.patch(
                                        urlloc,
                                        formdataloc,
                                        config
                                      );

                                    if( reagentresponse.status == 200 ||reagentresponse.status == 201){


                                      const formdatathree = new FormData();
                                      // if(idno == 1){
                                        formdatathree.append("amount",parseFloat(planinfo.total_amount));
                                        formdatathree.append("description", `Buy Subscription - ${gettokinval.id}`);
                                      let url = `${process.env.REACT_APP_API_URL}api/wallet/make_purchase/`;
                                      const dedectresponse = await axios.post(
                                        url,
                                        formdatathree,
                                        config
                                      );
                                      if (dedectresponse.data.id) {
                                        const newformdata = {
                                          "service_plan_id": planinfo.sub_id
                                        }
                                        let urlbuy = `${process.env.REACT_APP_API_URL}api/subscriptions/`;
                                        const buyresponse = await axios.post(
                                          urlbuy,
                                          newformdata,
                                          config
                                        );
                                        // console.log(";",buyresponse)
                                        setsrtloader(false);
                                        setbutsub(true);
                                        setTimeout(() => {
                                          setbutsub(false);
                                          // window.location.reload()
                                        }, 5000);
                                        //  setloading(false);
                                        // setagentusername("");
                                        // setagentemail("");
                                        setagentmobile("");
                                        // setagentpassword("");
                                        setagentname("");
                                        setagentlast("");
                                        setagentcompany("");
                                        // setagentlocation("");
                                        setagentcountry("");
                                        setLcity("");
                                        setagentstate("");
                                        setagentpincode("");
                                        // setagentstatus("");
                                        // setagentconfirmpassword("");
                                        setidnumber("");
                                        setdocumenttype("");
                                        seterrorclass(false);
                                        setagentaddress("");
                                        //   window.location.reload()
                                      } else {
                                        setsrtloader(false);
                                        setpurchaseerror(true);
                                        setTimeout(() => {
                                          setpurchaseerror(false);
                                        }, 5000);
                                      }



                                    }


                                  }








                                  
                                  
                                } else {
                                  setsrtloader(false);
                                  seteditlocation(true);
                                  setTimeout(() => {
                                    seteditlocation(false);
                                  }, 5000);
                                }
                                // console.log("location id", response);
                              } else {
                                setsrtloader(false);
                                seteditagenterror(true);
                                setTimeout(() => {
                                  seteditagenterror(false);
                                }, 5000);
                              }
        
                            } catch (error) {
                              setsrtloader(false);
                              seteditagenterror(true)
                              setTimeout(() => {
                                seteditagenterror(false)
                              }, 5000);
                              setagentresponse({
                                statusone: 1,
                                message: "Something Went Wrong Try again",
                              });
                              seterrorclass(true);
                              seterrorcls(error.response.data);
                            }
                          }
                        }
                      }
                    }
                    //  pan card not available
        
                  }
                }
              }
            }
          }
        
    }

  
  };

  const nvg = useNavigate();
  const navigatefeature = (num) => {
    nvg("/", { state: { id: num } });
  };

  const captureimagethreetwo = (num) => {
    if (documenttypetwo == "AADHAAR") {
      const modifiedImageUrl = backmodifyImage3(
        webcamRefs[num].current.getScreenshot(),
        varxtwo,
        varytwo
      );
      setdocumentimageback2(modifiedImageUrl);
      setAadhaarback2(webcamRefs[num].current.getScreenshot());
      sethideorshow3(!hideorshow3);
    } else {
      setdocumentimageback2(webcamRefs[num].current.getScreenshot());
      sethideorshow3(!hideorshow3);
    }

    // setdisplayphototwo(pickimgtwo.current.getScreenshot())
  };

  const changedocument = (e) => {
    if (e == "india") {
      setdocumenttype("PAN");
    } else {
      setdocumenttype("PASSPORT");
      setnotapplicable(false);
    }
  };

  // capture image from webcam start here
  const captureimagetwo = (num) => {
    // if(num == 0){

    if (documenttype == "AADHAAR") {
      const modifiedImageUrl = modifyImage(
        webcamRefs[num].current.getScreenshot(),
        varx,
        vary
      );
      setModifiedImage(modifiedImageUrl);
      setAadhaarImage(webcamRefs[num].current.getScreenshot());
    } else {
      setModifiedImage(webcamRefs[num].current.getScreenshot());
    }
    // setdisplayphototwo(pickimgtwo.current.getScreenshot())
    sethideorshowtwo(!hideorshowtwo);
    // }else{
    //   console.log("ldlddldldldldldlddldl")
    //   if(documenttype == 'AADHAAR'){
    //     const modifiedImageUrl = modifyImage(pickimgtwo.current.getScreenshot(),varxtwo,varytwo);
    //     setdocumentimagebacktwo(modifiedImageUrl);
    //     setAadhaarImagetwo(pickimgtwo.current.getScreenshot())
    //     sethideorshowthree(!hideorshowthree)
    //   console.log("ldlddldldldldldlddldrrrrrrrrrrl",documentimagebacktwo)
    //   }else{
    //     setdocumentimagebacktwo(pickimgtwo.current.getScreenshot());
    //     sethideorshowthree(!hideorshowthree)
    //   console.log("ldlddldldldldldlddldlyyyyyyyyyyyyyyyy",documentimagebacktwo)

    //   }
    //   console.log("ldlddldldldldldlddldl",documentimagebacktwo)
    // }
  };
  // capture image from webcam end here

  const captureimage = (num) => {
    setdisplayphoto(webcamRefs[num].current.getScreenshot());
    sethideorshow(!hideorshow);
  };

  // capture image from webcam start here
  const captureimagesuper2 = (num) => {
    // if(num == 0){

    if (documenttypetwo == "AADHAAR") {
      const modifiedImageUrl = modifyImagethree(
        webcamRefs[num].current.getScreenshot(),
        varx,
        vary
      );
      setModifiedImagenumbertwo(modifiedImageUrl);
      setAadhaarImage2(webcamRefs[num].current.getScreenshot());
    } else {
      setModifiedImagenumbertwo(webcamRefs[num].current.getScreenshot());
    }
    sethideorshow3(!hideorshow3);
  };
  // capture image from webcam end here
  const changedocumetimgtwo = (e) => {
    // if(e == 'AADHAAR'){
    //   if(modifiedImage !== null){
    //     const modifiedImageUrl = modifyImage(modifiedImage,varx,vary);
    //     setModifiedImage(modifiedImageUrl);
    //     setAadhaarImage(modifiedImage)
    //   }
    // }else{
    setAadhaarImage2(null);
    setModifiedImagenumbertwo(null);
    setAadhaarback2(null);
    setdocumentimageback2(null);
    // }
  };

  const handleImageChange11 = () => {
    setthumbimg2("");
    setagentr_thumb("");
    setrtferror(true);
    setTimeout(() => {
      const client = new CaptureFinger();
      setthumbimg2(client.data.BitmapData);
      setagentr_thumb(client.data.BitmapData);
      setansithumbimg2(client.data.AnsiTemplate);
      if (client.data.BitmapData) {
        setrightnumberoffingers(1);
        setrtferror(false);
      }
    }, 1000);
  };

  const handleImageChange12 = () => {
    setrifimg2("");
    setagentr_index_finger("");
    setriferror(true);
    setTimeout(() => {
      const client = new CaptureFinger();
      setrifimg2(client.data.BitmapData);
      setagentr_index_finger(client.data.BitmapData);
      setansirifimg2(client.data.AnsiTemplate);
      if (client.data.BitmapData) {
        setrightnumberoffingers(1);
        setriferror(false);
      }
    }, 1000);
  };

  const handleImageChange13 = () => {
    setrmfimg2("");
    setagentr_middle_finger("");
    setrmferror(true);
    setTimeout(() => {
      const client = new CaptureFinger();
      setrmfimg2(client.data.BitmapData);
      setagentr_middle_finger(client.data.BitmapData);
      setansirmfimg2(client.data.AnsiTemplate);
      if (client.data.BitmapData) {
        setrightnumberoffingers(1);
        setrmferror(false);
      }
    }, 1000);
  };

  const handleImageChange14 = () => {
    setrrfimg2("");
    setagentr_ring_finger("");
    setrrferror(true);
    setTimeout(() => {
      const client = new CaptureFinger();
      setrrfimg2(client.data.BitmapData);
      setagentr_ring_finger(client.data.BitmapData);
      setansirrfimg2(client.data.AnsiTemplate);
      if (client.data.BitmapData) {
        setrightnumberoffingers(1);
        setrrferror(false);
      }
    }, 1000);
  };

  const handleImageChange15 = () => {
    setrlfimg2("");
    setagentr_little_finger("");
    setrlferror(true);
    setTimeout(() => {
      const client = new CaptureFinger();
      setrlfimg2(client.data.BitmapData);
      setagentr_little_finger(client.data.BitmapData);
      setansirlfimg2(client.data.AnsiTemplate);
      if (client.data.BitmapData) {
        setrightnumberoffingers(1);
        setrlferror(false);
      }
    }, 1000);
  };

  const handleImageChange16 = () => {
    setltfimg2("");
    setagentl_thumb("");
    setltferror(true);
    setTimeout(() => {
      const client = new CaptureFinger();
      setltfimg2(client.data.BitmapData);
      setagentl_thumb(client.data.BitmapData);
      setansiltfimg2(client.data.AnsiTemplate);
      if (client.data.BitmapData) {
        setleftnumberoffingers(1);
        setltferror(false);
      }
    }, 1000);
  };

  const handleImageChange17 = () => {
    setlifimg2("");
    setagentl_index_finger("");
    setliferror(true);
    setTimeout(() => {
      const client = new CaptureFinger();
      setlifimg2(client.data.BitmapData);
      setagentl_index_finger(client.data.BitmapData);
      setansilifimg2(client.data.AnsiTemplate);
      if (client.data.BitmapData) {
        setleftnumberoffingers(1);
        setliferror(false);
      }
    }, 1000);
  };

  const handleImageChange18 = () => {
    setlmfimg2("");
    setagentl_middle_finger("");
    setlmferror(true);
    setTimeout(() => {
      const client = new CaptureFinger();
      setlmfimg2(client.data.BitmapData);
      setagentl_middle_finger(client.data.BitmapData);
      setansilmfimg2(client.data.AnsiTemplate);
      if (client.data.BitmapData) {
        setleftnumberoffingers(1);
        setlmferror(false);
      }
    }, 1000);
  };

  const handleImageChange19 = () => {
    setlrfimg2("");
    setagentl_ring_finger("");
    setlrferror(true);
    setTimeout(() => {
      const client = new CaptureFinger();
      setlrfimg2(client.data.BitmapData);
      setagentl_ring_finger(client.data.BitmapData);
      setansilrfimg2(client.data.AnsiTemplate);
      if (client.data.BitmapData) {
        setleftnumberoffingers(1);
        setlrferror(false);
      }
    }, 1000);
  };

  const handleImageChange20 = () => {
    setllfimg2("");
    setagentl_little_finger("");
    setllferror(true);
    setTimeout(() => {
      const client = new CaptureFinger();
      setllfimg2(client.data.BitmapData);
      setagentl_little_finger(client.data.BitmapData);
      setansillfimg2(client.data.AnsiTemplate);
      if (client.data.BitmapData) {
        setleftnumberoffingers(1);
        setllferror(false);
      }
    }, 1000);
  };




  const changebackimg = (e) =>{



    const reader = new FileReader();
    reader.readAsDataURL(e);
    reader.onload = () => {
      // console.log("oooooo",reader.result)
  
      if(documenttype == 'AADHAAR'){
        const modifiedImageUrl = backmodifyImage(reader.result,varxtwo,varytwo);
        setdocumentimagebacktwo(modifiedImageUrl);
        setAadhaarImagetwo(reader.result)
      }else{
        setdocumentimagebacktwo(reader.result);
    
      }
    }
   
  
    // setdisplayphototwo(pickimgtwo.current.getScreenshot())
  }

  const changefrontimg = (e) =>{
    // console.log("qqqqqqqqqqqqqqqq",URL.createObjectURL(e))
  
    const reader = new FileReader();
    reader.readAsDataURL(e);
    reader.onload = () => {
      // console.log("oooooo",reader.result)
      if(documenttype == 'AADHAAR'){
        const modifiedImageUrl = modifyImage(reader.result,varx,vary);
        setModifiedImage(modifiedImageUrl);
        setAadhaarImage(reader.result)
      }else{
        setModifiedImage(reader.result);
      }
      
    };
  }

  const twochangefrontimg = (e) =>{
    const reader = new FileReader();
    reader.readAsDataURL(e);
    reader.onload = () => {
      // console.log("oooooo",reader.result)
      
      if(documenttypetwo == 'AADHAAR'){
        const modifiedImageUrl = modifyImagethree(reader.result,varx,vary);
        setModifiedImagenumbertwo(modifiedImageUrl);
        setAadhaarImage2(reader.result)
      }else{
        setModifiedImagenumbertwo(reader.result);
      }
  
    }
  }
  
  
  const twochangebackimg = (e) =>{
  
    const reader = new FileReader();
    reader.readAsDataURL(e);
    reader.onload = () => {
      // console.log("oooooo",reader.result)
      
    if(documenttypetwo == 'AADHAAR'){
      const modifiedImageUrl = backmodifyImage3(reader.result,varxtwo,varytwo);
      setdocumentimageback2(modifiedImageUrl);
      setAadhaarback2(reader.result)
    }else{
      setdocumentimageback2(reader.result);
  
    }
    }
    // setdisplayphototwo(pickimgtwo.current.getScreenshot())
  }

  function backmodifyImage(imageDataUrl, xl, yt) {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    // Create an Image object and load the Aadhaar card image data
    const image = new Image();
    image.onload = () => {
      // Set the canvas dimensions to match the image dimensions
      canvas.width = image.width;
      canvas.height = image.height;

      // Draw the image on the canvas
      context.drawImage(image, 0, 0);

      // Modify the pixels in the desired area to hide the numbers
      //const x = xl; X-coordinate of the area to hide
      //const y = yt; // Y-coordinate of the area to hide
      const width = 240; // Width of the area to hide
      const height = 50; // Height of the area to hide
      context.fillStyle = "black";
      context.fillRect(xl, yt, width, height);

      // Generate the modified image URL from the canvas
      const modifiedImageUrl = canvas.toDataURL("image/jpeg");

      // Update the state with the modified image URL
      setdocumentimagebacktwo(modifiedImageUrl);
    };

    // Set the Image object's source to the Aadhaar card image data
    image.src = imageDataUrl;
  }

  function modifyImage(imageDataUrl, xl, yt) {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    // Create an Image object and load the Aadhaar card image data
    const image = new Image();
    image.onload = () => {
      // Set the canvas dimensions to match the image dimensions
      canvas.width = image.width;
      canvas.height = image.height;

      // Draw the image on the canvas
      context.drawImage(image, 0, 0);

      // Modify the pixels in the desired area to hide the numbers
      //const x = xl; X-coordinate of the area to hide
      //const y = yt; // Y-coordinate of the area to hide
      const width = 240; // Width of the area to hide
      const height = 50; // Height of the area to hide
      context.fillStyle = "black";
      context.fillRect(xl, yt, width, height);

      // Generate the modified image URL from the canvas
      const modifiedImageUrl = canvas.toDataURL("image/jpeg");

      // Update the state with the modified image URL
      setModifiedImage(modifiedImageUrl);
    };

    // Set the Image object's source to the Aadhaar card image data
    image.src = imageDataUrl;
  }

  function modifyImagethree(imageDataUrl, xl, yt) {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    // Create an Image object and load the Aadhaar card image data
    const image = new Image();
    image.onload = () => {
      // Set the canvas dimensions to match the image dimensions
      canvas.width = image.width;
      canvas.height = image.height;

      // Draw the image on the canvas
      context.drawImage(image, 0, 0);

      // Modify the pixels in the desired area to hide the numbers
      //const x = xl; X-coordinate of the area to hide
      //const y = yt; // Y-coordinate of the area to hide
      const width = 240; // Width of the area to hide
      const height = 50; // Height of the area to hide
      context.fillStyle = "black";
      context.fillRect(xl, yt, width, height);

      // Generate the modified image URL from the canvas
      const modifiedImageUrl = canvas.toDataURL("image/jpeg");

      // Update the state with the modified image URL
      setModifiedImagenumbertwo(modifiedImageUrl);
    };

    // Set the Image object's source to the Aadhaar card image data
    image.src = imageDataUrl;
  }
  function backmodifyImage3(imageDataUrl, xl, yt) {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    // Create an Image object and load the Aadhaar card image data
    const image = new Image();
    image.onload = () => {
      // Set the canvas dimensions to match the image dimensions
      canvas.width = image.width;
      canvas.height = image.height;

      // Draw the image on the canvas
      context.drawImage(image, 0, 0);

      // Modify the pixels in the desired area to hide the numbers
      //const x = xl; X-coordinate of the area to hide
      //const y = yt; // Y-coordinate of the area to hide
      const width = 240; // Width of the area to hide
      const height = 50; // Height of the area to hide
      context.fillStyle = "black";
      context.fillRect(xl, yt, width, height);

      // Generate the modified image URL from the canvas
      const modifiedImageUrl = canvas.toDataURL("image/jpeg");

      // Update the state with the modified image URL
      setdocumentimageback2(modifiedImageUrl);
    };

    // Set the Image object's source to the Aadhaar card image data
    image.src = imageDataUrl;
  }

  const captureimagethree = (num) => {
    if (documenttype == "AADHAAR") {
      const modifiedImageUrl = backmodifyImage(
        webcamRefs[num].current.getScreenshot(),
        varxtwo,
        varytwo
      );
      setdocumentimagebacktwo(modifiedImageUrl);
      setAadhaarImagetwo(webcamRefs[num].current.getScreenshot());
      sethideorshowthree(!hideorshowthree);
    } else {
      setdocumentimagebacktwo(webcamRefs[num].current.getScreenshot());
      sethideorshowthree(!hideorshowthree);
    }

    // setdisplayphototwo(pickimgtwo.current.getScreenshot())
  };

  useEffect(() => {
    setagentmobile(gettokinval.mobile)

    async function fetchData() {
      // if (id == 1) {
      //   const formdata = {
      //     sub_name: "Silver",
      //     duration_days: 60,
      //     price: 800,
      //     gst_percentage: 18,
      //     sac_code: "12345",
      //   };
      // }

      // if(id == 2){
      // const formdata = {
      //   sub_name: "Silver",
      //   duration_days: 45,
      //   price: 800.0,
      //   gst_percentage: 18,
      //   sac_code: "12345",
      // };

      const config = {
        headers: {
          Authorization: `Bearer ${gettokinval.access}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${process.env.REACT_APP_API_URL}api/service-plans/${planidno}`;
      try {
        const response = await axios.get(url, config);
        setplaninfo(response.data);
        setsrtloader(false);
        // console.log(response);
      } catch (error) {
        // setloading(false)
        // console.log(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const config = {
        headers: {
          Authorization: `Bearer ${gettokinval.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}api/active_company/`;
      const response = await axios.get(url, config);
      setData(response.data);
      //   setstrloading(false)
    }
    fetchData();

    async function fetchDatatwo() {
      const config = {
        headers: {
          Authorization: `Bearer ${gettokinval.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}api/active_product/`;
      const response = await axios.get(url, config);
      setDatatwo(response.data);
    }
    fetchDatatwo();
  }, [setData, setDatatwo]);

  return (
    <div style={{ margin: "0px", padding: "0px" }}>
      <section className="superpadding">
        {srtloader == true ? (
          <div className="container-fuild bg-white">
            {" "}
            <div
              className="col-12 d-flex justify-content-center"
              style={{ gap: "4px", position: "absolute", width: "86%" }}
            >
              <div className="px-2">
                {" "}
                <Loadercomp size={100} />
              </div>
            </div>
          </div>
        ) : (
          <div className="container-fuild pb-4 pt-3 px-2 bg-white">
            <form autoComplete="off" onSubmit={submitform}>
              <div
                className="row bg-white pb-4 px-3 round"
                style={{
                  border: "1px solid #E0E0E0",
                  margin: "10px 0px",
                  borderRadius: "3px",
                  position: "relative",
                }}
              >
                <div className="col-md-12 px-2 pb-3 pt-4">
                  <div className="row">
                    {/* <div className="col-lg-3">
                    <label htmlFor="" className="form-label"></label>
                  </div> */}
                    <div className="col-lg-7 d-flex justify-content-start">
                      <span
                        style={{
                          color: "rgba(12, 83, 152, 1)",
                          fontSize: "24px",
                          fontWeight: "600",
                        }}
                      >
                        {" "}
                        Buy Subscription
                      </span>
                    </div>

                    <div className="col-lg-5 d-flex justify-content-end align-items-center">
                      <NavLink to="/subscriptionplan">
                        <button
                          type="button"
                          className="btn5withoutradius"
                          style={{ height: "62%", background: "#0c5398" }}
                        >
                          Back
                        </button>
                      </NavLink>
                    </div>
                  </div>
                </div>

                {/* next page */}
                {/* <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                    Corporate 
                    </label>
                  </div>
                  <div className="col-lg-9">
                   <select  className="form-select" aria-label="Default select example">
                    <option value="Please select corporate">Please select corporate</option>
                    <option value="agent">agent</option>
                    <option value="company">company</option>
                   </select>
                   
                  </div>
                </div>
              </div> */}
                <div className="col-md-6 px-3" style={{ paddingTop: "29px" }}>
                  <div className="row">
                    <div className="col-lg-3">
                      <label htmlFor="" className="form-label">
                        Corporate 
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        placeholder="Company Name"
                        onChange={(e) => {
                          setagentcompany(e.target.value);
                        }}
                      >
                        <option value="" selected>
                          Select Company
                        </option>
                        {data.map((item) => (
                          <option key={item.company_id} value={item.company_id}>
                            {item.company_name}
                          </option>
                        ))}
                      </select>
                      {dataerrorstatus === true ? (
                        <span style={{ color: "red" }}>{dataerrortxt}</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Product
                    </label>
                  </div>
                  <div className="col-lg-9">
                  <select  className="form-select" aria-label="Default select example">
                    <option value="Please select corporate">Please select Product</option>
                    <option value="agent">agent</option>
                    <option value="company">company</option>
                   </select>
                  </div>
                </div>
              </div> */}

                <div className="col-md-6 px-2 pt-4">
                  <div className="row">
                    <div className="col-lg-3">
                      <label htmlFor="" className="form-label customw">
                        Product {" "}
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <select
                        name=""
                        id=""
                        className="form-select"
                        // onChange={(e) => { setselectproduct(e.target.value) }}
                        aria-label="Default select example"
                      >
                        <option value="not">Select Product</option>
                        {datatwo.map((item) => (
                          <option value={item.id} key={item.id}>
                            {" "}
                            <img
                              src={`${item.logo}`}
                              width={30}
                              alt="zxcvbnm"
                            />{" "}
                            {item.product_name}
                          </option>
                        ))}
                      </select>
                      {/* { producterror == true ? <span style={{color:'red'}}> Please Select Product </span> : ''} */}
                    </div>
                  </div>
                </div>

                <div className="col-md-12 px-2 pt-4">
                  <div className="row">
                    <div className="col-12">
                      <label
                        htmlFor=""
                        className="form-label"
                        style={{
                          color: "rgba(12, 83, 152, 1)",
                          fontSize: "20px",
                          fontWeight: "600",
                        }}
                      >
                        Branch Details
                      </label>
                    </div>
                    <div
                      className="col-12 outerunderline"
                      style={{
                        position: "relative",
                        width: "98%",
                        height: "3px",
                        background: "grey",
                        margin: "0 auto",
                      }}
                    >
                      <div className="specialinderline"></div>
                    </div>
                  </div>
                </div>

                {/* <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor=""  className="form-label customw">
                     <span>Username</span>  
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Username"

                      required
                    />
 
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label customw">
                      Email Address {" "}
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="email"
                      className="form-control"
                   placeholder='Email'
                      required
                    />
             
                  </div>
                </div>
              </div> */}
                {/* <div className="col-md-6 px-2 pt-4">
                  <div className="row">
                    <div className="col-lg-3">
                      <label htmlFor="" className="form-label">
                        Branch Name
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        value={branch}
                        onChange={(e) => {
                          setbranch(e.target.value);
                        }}
                        required
                      />
                    </div>
                  </div>
                </div> */}

                {/* <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Last Name 
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      className="form-control"
                 
                      placeholder="Last Name"
                
                      required
                    />
                  </div>
                </div>
              </div> */}

                {/* <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Gender 
                    </label>
                  </div>
                  <div className="col-lg-9">
                  <select
                      name=""
                      id=""
                      className="form-select"
                   
                      aria-label="Default select example"
                    >
                      <option value="Male">male</option>
                      <option value="Female">female</option>
                      <option value="Others">others</option>
                    </select>
                   
                  </div>
                </div>
              </div> */}

                {/* <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Mobile No. 
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <PhoneInput
placeholder="Enter phone number"
className="form-control"
value={agentmobile}
onChange={setagentmobile}
required
/>
                   
                  </div>
                </div>
              </div> */}

                <div className="col-md-6 px-2 pt-4">
                  <div className="row">
                    <div className="col-lg-3">
                      <label htmlFor="" className="form-label">
                        Country
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <Select
                        placeholder="Select Country"
                        options={updatedCountries}
                        value={branchcountry}
                        onChange={(e) => {
                          setbranchcountry(e);
                        }}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6 px-2 pt-4">
                  <div className="row">
                    <div className="col-lg-3">
                      <label htmlFor="" className="form-label">
                        State
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <Select
                        id="state"
                        name="state"
                        options={updatedStates(
                          branchcountry == null ? null : branchcountry.value
                        )}
                        placeholder="Select State"
                        onChange={(e) => {
                          setbranchstate(e);
                        }}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6 px-3" style={{ paddingTop: "29px" }}>
                  <div className="row">
                    <div className="col-lg-3">
                      <label htmlFor="" className="form-label">
                        City 
                      </label>
                    </div>
                    <div className="col-lg-9">
                      {/* <input type="text" className="form-control" value={lcity} placeholder='City' readOnly required /> */}
                      {/* <input type="text" className="form-control" value={lcity} placeholder='City' onChange={(e) => { setLcity(e.target.value) }} required /> */}
                      <Select
                        placeholder="Select City"
                        id="city"
                        name="city"
                        options={updatedCities(
                          branchstate == null ? null : branchstate.value
                        )}
                        value={branchcity}
                        onChange={(e) => {
                          setbranchcity(e);
                        }}
                        required
                      />
                      {/* {cityerror !== '' ? <span style={{color:'red'}}> {cityerror} </span> : ''} */}
                      {/* {errorcls ? errorcls.city ? <span style={{color:'red'}}> {errorcls.city[0]} </span> : '' : ''} */}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 px-2 pt-4">
                  <div className="row">
                    <div className="col-lg-3">
                      <label htmlFor="" className="form-label">
                        Address
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Address"
                        value={locationaddress}
                        onChange={(e) => {
                          setlocationaddress(e.target.value);
                        }}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 px-2 pt-4">
                  <div className="row">
                    <div className="col-lg-3">
                      <label htmlFor="" className="form-label">
                        Pincode
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <input
                        type="tel"
                        value={bpincode}
                        onChange={(e) => {
                          setbpincode(e.target.value.replace(/[^0-9]/gi, ""));
                        }}
                        className="form-control"
                        maxLength={6}
                        minLength={6}
                        placeholder="Pincode"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-12 px-2 pt-4">
                  <div className="row">
                    <div className="col-12">
                      <label
                        htmlFor=""
                        className="form-label"
                        style={{
                          color: "rgba(12, 83, 152, 1)",
                          fontSize: "20px",
                          fontWeight: "600",
                        }}
                      >
                        Personal Details
                      </label>
                    </div>
                    <div
                      className="col-12 outerunderline"
                      style={{
                        position: "relative",
                        width: "98%",
                        height: "3px",
                        background: "grey",
                        margin: "0 auto",
                      }}
                    >
                      <div className="specialinderline"></div>
                    </div>
                  </div>
                </div>

                {/* <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor=""  className="form-label customw">
                     <span>Username</span>  
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Username"

                      required
                    />
 
                  </div>
                </div>
              </div> */}
                <div className="col-md-6 px-2 pt-4">
                  <div className="row">
                    <div className="col-lg-3">
                      <label htmlFor="" className="form-label customw">
                        Email Address{" "}
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <input
                        type="email"
                        className="form-control"
                        value={gettokinval.email}
                        readOnly
                        placeholder="Email"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 px-2 pt-4">
                  <div className="row">
                    <div className="col-lg-3">
                      <label htmlFor="" className="form-label">
                        Mobile No.
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <PhoneInput
                        placeholder="Enter phone number"
                        className="form-control"
                        value={agentmobile}
                        // onChange={setagentmobile}
                        // readOnly
                        // required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 px-2 pt-4">
                  <div className="row">
                    <div className="col-lg-3">
                      <label htmlFor="" className="form-label">
                        First Name
                      </label>
                    </div>
                    <div className="col-lg-9">
                      {/* <input type="text" className="form-control" placeholder='Gender' value={agentgender} onChange={(e) => {setagentgender(e.target.value)}} required /> */}
                      <input
                        type="text"
                        className="form-control"
                        value={agentname}
                        placeholder="First Name"
                        onChange={(e) => {
                          setagentname(e.target.value);
                        }}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6 px-2 pt-4">
                  <div className="row">
                    <div className="col-lg-3">
                      <label htmlFor="" className="form-label">
                        Last Name
                      </label>
                    </div>
                    <div className="col-lg-9">
                      {/* <input type="text" className="form-control" placeholder='Gender' value={agentgender} onChange={(e) => {setagentgender(e.target.value)}} required /> */}
                      <input
                        type="text"
                        className="form-control"
                        value={agentlast}
                        onChange={(e) => {
                          setagentlast(e.target.value);
                        }}
                        placeholder="Last Name"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
                  <div className="row">
                    <div className="col-lg-3">
                      <label htmlFor="" className="form-label customw">
                        <span>Father Name</span>{" "}
                        
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Father Name"
                        value={ffn}
                        onChange={(e) => {
                          setffn(e.target.value);
                        }}
                        required
                      />
                      {/* {errorcls ? (
                      errorcls.fathersFullNm ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.fathersFullNm[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )} */}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 px-2 pt-4">
                  <div className="row">
                    <div className="col-lg-3">
                      <label htmlFor="" className="form-label">
                        Gender
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <select
                        name=""
                        id=""
                        className="form-select"
                        value={agentgender}
                        onChange={(e) => {
                          setagentgender(e.target.value);
                        }}
                        aria-label="Default select example"
                      >
                        <option value="Male">male</option>
                        <option value="Female">female</option>
                        <option value="Others">others</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
                  <div className="row">
                    <div className="col-lg-3">
                      <label htmlFor="" className="form-label">
                        DOB 
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <input
                        type="date"
                        className="form-control"
                        minLength={6}
                        maxLength={6}
                        value={dob}
                        placeholder="date"
                        onChange={(e) => {
                          setdob(e.target.value);
                        }}
                        required
                      />
                      {errorcls ? (
                      errorcls.dob ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.dob[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                     {documenterror === true ? (
                            dob == undefined || dob == "" || dob == null ? (
                              <span style={{ color: "red" }}>
                                Please Enter Your DOB
                              </span>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 px-2 pt-4">
                  <div className="row">
                    <div className="col-lg-3">
                      <label htmlFor="" className="form-label">
                        Country
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <Select
                        placeholder="Select Country"
                        options={updatedCountries}
                        value={agentcountry}
                        onChange={(e) => {
                          setagentcountry(e);
                        }}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6 px-2 pt-4">
                  <div className="row">
                    <div className="col-lg-3">
                      <label htmlFor="" className="form-label">
                        State
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <Select
                        id="state"
                        name="state"
                        options={updatedStates(
                          agentcountry == null ? null : agentcountry.value
                        )}
                        placeholder="Select State"
                        onChange={(e) => {
                          setagentstate(e);
                        }}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6 px-3" style={{ paddingTop: "29px" }}>
                  <div className="row">
                    <div className="col-lg-3">
                      <label htmlFor="" className="form-label">
                        City 
                      </label>
                    </div>
                    <div className="col-lg-9">
                      {/* <input type="text" className="form-control" value={lcity} placeholder='City' readOnly required /> */}
                      {/* <input type="text" className="form-control" value={lcity} placeholder='City' onChange={(e) => { setLcity(e.target.value) }} required /> */}
                      <Select
                        placeholder="Select City"
                        id="city"
                        name="city"
                        options={updatedCities(
                          agentstate == null ? null : agentstate.value
                        )}
                        value={lcity}
                        onChange={(e) => {
                          setLcity(e);
                        }}
                        required
                      />
                      {/* {cityerror !== '' ? <span style={{color:'red'}}> {cityerror} </span> : ''} */}
                      {/* {errorcls ? errorcls.city ? <span style={{color:'red'}}> {errorcls.city[0]} </span> : '' : ''} */}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 px-2 pt-4">
                  <div className="row">
                    <div className="col-lg-3">
                      <label htmlFor="" className="form-label">
                        Address 
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <input
                        type="text"
                        className="form-control"
                        value={agentaddress}
                        placeholder="Address"
                        onChange={(e) => {
                          setagentaddress(e.target.value);
                        }}
                        required
                      />
                      {errorcls ? (
                        errorcls.address ? (
                          <span style={{ color: "red" }}>
                            {" "}
                            {errorcls.address[0]}{" "}
                          </span>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 px-2 pt-4">
                  <div className="row">
                    <div className="col-lg-3">
                      <label htmlFor="" className="form-label">
                        Pincode
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <input
                        type="tel"
                        className="form-control"
                        value={agentpincode}
                        onChange={(e) => {
                          setagentpincode(
                            e.target.value.replace(/[^0-9]/gi, "")
                          );
                        }}
                        maxLength={6}
                        minLength={6}
                        placeholder="Pincode"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 px-2 pt-4">
                  <div className="row">
                    <div className="col-lg-3">
                      <label htmlFor="" className="form-label">
                        Nationality 
                      </label>
                    </div>
                    <div className="col-lg-9">
                      {/* <input type="text" className="form-control" placeholder='Gender' value={agentgender} onChange={(e) => {setagentgender(e.target.value)}} required /> */}
                      <select
                        className="form-select"
                        value={agentnationality}
                        onChange={(e) => {
                          setagentnationality(e.target.value);
                          changedocument(e.target.value);
                        }}
                        aria-label="Default select example"
                      >
                        <option value="india">India</option>
                        <option value="Others">others</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="row px-4 pt-4">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-12">
                          <label htmlFor="" className="form-label">
                            Biometric Detail{" "}
                            
                          </label>
                        </div>
                        <div className="col-12">
                          <div className="border round py-3 d-flex justify-content-center">
                            <div
                              className="d-flex flex-column align-items-center justify-content-center"
                              style={{ position: "relative" }}
                            >
                              <label htmlFor="">Right Hand</label>
                              <img
                                src={thumbimg2 == "" ? img5 : img4}
                                alt="rtf"
                                style={{
                                  position: "absolute",
                                  top: "80px",
                                  left: "13px",
                                }}
                              />
                              <img
                                src={rifimg2 == "" ? img5 : img4}
                                alt="rif"
                                style={{
                                  position: "absolute",
                                  top: "37px",
                                  left: "35px",
                                }}
                              />
                              <img
                                src={rmfimg2 == "" ? img5 : img4}
                                alt="rmf"
                                style={{
                                  position: "absolute",
                                  top: "30px",
                                  left: "65px",
                                }}
                              />
                              <img
                                src={rrfimg2 == "" ? img5 : img4}
                                alt="rrf"
                                style={{
                                  position: "absolute",
                                  top: "36px",
                                  left: "84px",
                                }}
                              />
                              <img
                                src={rlfimg2 == "" ? img5 : img4}
                                alt="rlf"
                                style={{
                                  position: "absolute",
                                  top: "55px",
                                  left: "103px",
                                }}
                              />
                              <img src={img1} alt="qwerty" />
                            </div>
                            <div
                              className="d-flex flex-column align-items-center justify-content-center"
                              style={{ position: "relative" }}
                            >
                              <label htmlFor="">Left Hand</label>
                              <img
                                src={llfimg2 == "" ? img5 : img4}
                                alt="llf"
                                style={{
                                  position: "absolute",
                                  top: "56px",
                                  left: "13px",
                                }}
                              />
                              <img
                                src={lrfimg2 == "" ? img5 : img4}
                                alt="lrf"
                                style={{
                                  position: "absolute",
                                  top: "36px",
                                  left: "33px",
                                }}
                              />
                              <img
                                src={lmfimg2 == "" ? img5 : img4}
                                alt="lmf"
                                style={{
                                  position: "absolute",
                                  top: "31px",
                                  left: "55px",
                                }}
                              />
                              <img
                                src={lifimg2 == "" ? img5 : img4}
                                alt="lif"
                                style={{
                                  position: "absolute",
                                  top: "38px",
                                  left: "80px",
                                }}
                              />
                              <img
                                src={ltfimg2 == "" ? img5 : img4}
                                alt="ltf"
                                style={{
                                  position: "absolute",
                                  top: "80px",
                                  left: "102px",
                                }}
                              />
                              <img src={img2} alt="trew" />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 py-2 px-4 d-flex justify-content-between">
                          <button
                            type="button"
                            // className="btn1"
                            style={{ backgroundColor: "#0C5398" }}
                            className="btn btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            Capture
                          </button>
                        </div>
                      </div>
                      {fingerperror == true ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {rigthfingerserror}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-12">
                          <label htmlFor="" className="form-label">
                            Photograph 
                          </label>
                        </div>
                        <div className="col-12 ">
                          <div className="border round py-3 d-flex justify-content-center">
                            <button
                              type="button"
                              style={{ border: "none", outline: "none" }}
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModaltwo"
                            >
                              <img
                                src={displayphoto == "" ? img6 : displayphoto}
                                alt="zxcvbnm"
                                width="140px"
                                height="162px"
                              />
                            </button>
                            {/* <ReactImageMagnify
                            {...{
                                smallImage: {
                                    alt: 'Wristwatch by Versace',
                                    isFluidWidth: true,
                                    src: displayphoto == '' ? img3 : displayphoto,
                                    width:140,
                                    height:162
                                },
                                largeImage: {
                                    src: displayphoto == '' ? img3 : displayphoto,
                                    width: 836,
                                    height: 1100
                                },
                                // enlargedImagePosition: 'over',
                                lensStyle: { backgroundColor: 'rgba(0,0,0,.6)' }
                            }}
                            
                        /> */}
                          </div>
                          <div className="col-12 py-2">
                            <button
                              style={{ backgroundColor: "#0C5398" }}
                              type="button"
                              className="btn btn-primary"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModalwebcam"
                              onClick={() => {
                                sethideorshow(!hideorshow);
                              }}
                            >
                              Capture
                            </button>
                            {/* <label htmlFor="photorap" className='btn btn-primary' style={{backgroundColor:"#0C5398"}}> Browse</label> */}
                          </div>
                          {/* <input type="file" style={{opacity:"0",position:'absolute',left:"205555555"}} id="photorap" onChange={(e) => {handleImageChangespecial(e)}} required /> */}
                        </div>
                        {errorcls ? (
                          errorcls.photograph ? (
                            <span style={{ color: "red" }}>
                              {" "}
                              {errorcls.photograph[0]}{" "}
                            </span>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        {sandphotoerror == true ? (
                          <span style={{ color: "red" }}>
                            {" "}
                            Please Capture your Photograph{" "}
                          </span>
                        ) : (
                          ""
                        )}
                        {/* 
                   { sandphotoerror == true ? <span style={{color:'red'}}> Please Select Product </span> : ''} */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="row">
                    <div className="col-12 ccolor py-3 px-4">Document</div>
                    {/* <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
        Document Type 
        </label>
      </div>
      <div className="col-lg-8">
      <select
              name=""
              id=""
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => {
                setdocumenttype(e.target.value);
                changedocumetimg(e.target.value);
              }}
              required
            >
              <option value="defualt">Select Document</option>
              <option value="AADHAAR">AADHAAR CARD</option>
              <option value="PAN">PAN CARD</option>
              <option value="DRIVING_LICENSE">DRIVING LICENSE</option>
              <option value="VOTERID">VOTER ID</option>
              <option value="PASSPORT">PASSPORT</option>
            </select>
            {documenterror === true ? documenttype == undefined || documenttype == "defualt"  ? (
                      <span style={{ color: "red" }}>Please Select Document</span>
                    ) : ( "") : (
                      ""
                    )}
                    {documentprivateerror === true ? documenttype == undefined || documenttype == "defualt"  ? (
                      <span style={{ color: "red" }}>Please Select Document</span>
                    ) : ( "") : (
                      ""
                    )}
                 {errorcls ? errorcls.document_type ? <span style={{color:'red'}}> {errorcls.document_type[0]} </span> : '' : ''}

      </div>
    </div>
  </div> */}
                    {/* {agentnationality == "india" ? <div className="col-md-12 px-2" style={{ paddingTop: "0px" }}>
    <div className="row">
      <div className="col-lg-2">
        <label htmlFor="" className="form-label">
        </label>
      </div>
      <div className="col-lg-8">
      <input class="form-check-input" type="checkbox" value={notapplicable} 
      onChange={(e) => {
                            setnotapplicable(!notapplicable);
                          }} 
                           id="flexCheckChecked" /> {" "}
   Pan Card Not Available
      </div>
    </div>
  </div> : '' } */}

                    <div
                      className="col-md-6 px-2"
                      style={{
                        paddingTop: "28px",
                        display: notapplicable == true ? "none" : "block",
                      }}
                    >
                      <div className="row">
                        <div className="col-lg-4">
                          <label htmlFor="" className="form-label">
                            Document Type{" "}
                            
                          </label>
                        </div>
                        <div className="col-lg-8">
                          <input
                            type="text"
                            className="form-control"
                            value={documenttype}
                            autoComplete="off"
                            placeholder={
                              documenttype == "PAN"
                                ? `PAN No.`
                                : documenttype == "VOTERID"
                                ? `VOTER ID`
                                : documenttype == "PASSPORT"
                                ? `PASSPORT No.`
                                : documenttype == "DRIVING_LICENSE"
                                ? `DRIVING LICENSE No.`
                                : documenttype == "AADHAAR"
                                ? `Aadhar No.`
                                : `Select Document`
                            }
                            readOnly
                            onChange={(e) => {
                              setdocumenttype(e.target.value);
                            }}
                          />
                          {/* {documenterror === true ? idnumber == undefined || idnumber == "" ? (
                      <span style={{ color: "red" }}>Please Enter Your Document No</span>
                    ) : ( "") : (
                      ""
                    )} */}
                          {errorcls ? (
                            errorcls.document_type ? (
                              <span style={{ color: "red" }}>
                                {" "}
                                {errorcls.document_type[0]}{" "}
                              </span>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    <div
                      className="col-md-6 px-2"
                      style={{
                        paddingTop: "28px",
                        display: notapplicable == true ? "none" : "block",
                      }}
                    >
                      <div className="row">
                        <div className="col-lg-4">
                          <label htmlFor="" className="form-label">
                            Document No. 
                          </label>
                        </div>
                        <div className="col-lg-8">
                          {notapplicable == true ? (
                            ""
                          ) : (
                            <input
                              type="text"
                              className="form-control"
                              value={idnumber}
                              autoComplete="off"
                              placeholder={
                                documenttype == "PAN"
                                  ? `PAN No.`
                                  : documenttype == "VOTERID"
                                  ? `VOTER ID`
                                  : documenttype == "PASSPORT"
                                  ? `PASSPORT No.`
                                  : documenttype == "DRIVING_LICENSE"
                                  ? `DRIVING LICENSE No.`
                                  : documenttype == "AADHAAR"
                                  ? `Aadhar No.`
                                  : `Select Document`
                              }
                              onChange={(e) => {
                                setidnumber(e.target.value);
                                setdocumentsuperres('')
                              }}
                              required
                            />
                          )}

                          {documenterror === true ? (
                            idnumber == undefined || idnumber == "" ? (
                              <span style={{ color: "red" }}>
                                Please Enter Your Document No
                              </span>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                          {errorcls ? (
                            errorcls.document_no ? (
                              <span style={{ color: "red" }}>
                                {" "}
                                {errorcls.document_no[0]}{" "}
                              </span>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                 

                    {documenttype == "DRIVING_LICENSE" ||
                    documenttype == "PASSPORT" ? (
                      <div
                        className="col-md-6 px-2"
                        style={{
                          paddingTop: "28px",
                          display: notapplicable == true ? "none" : "block",
                        }}
                      >
                        <div className="row">
                          <div className="col-lg-4">
                            <label htmlFor="" className="form-label">
                              Expiry date{" "}
                              
                            </label>
                          </div>
                          <div className="col-lg-8">
                            <input
                              type="date"
                              className="form-control"
                              value={documentexpiry}
                              // placeholder="State"
                              onChange={(e) => {
                                setdocumentexpiry(e.target.value);
                              }}
                              required
                            />
                            {documenterror === true ? (
                              documentexpiry == undefined ? (
                                <span style={{ color: "red" }}>
                                  Please Ender your Expiry date
                                </span>
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}
                            {/* {errorcls ? (
          errorcls.state ? (
            <span style={{ color: "red" }}>
              {" "}
              {errorcls.state[0]}{" "}
            </span>
          ) : (
            ""
          )
        ) : (
          ""
        )} */}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {documenttype == "DRIVING_LICENSE" ||
                    documenttype == "PASSPORT" ? (
                      <div
                        className="col-md-6 px-2"
                        style={{
                          paddingTop: "28px",
                          display: notapplicable == true ? "none" : "block",
                        }}
                      >
                        <div className="row">
                          <div className="col-lg-4">
                            <label htmlFor="" className="form-label">
                              Issue date 
                            </label>
                          </div>
                          <div className="col-lg-8">
                            <input
                              type="date"
                              className="form-control"
                              value={documentissue}
                              //   placeholder="Issue"
                              onChange={(e) => {
                                setdocumentissue(e.target.value);
                              }}
                              required
                            />
                            {documenterror === true ? (
                              documentissue == undefined ? (
                                <span style={{ color: "red" }}>
                                  Please Ender your Issue date
                                </span>
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}
                            {/* {errorcls ? (
          errorcls.state ? (
            <span style={{ color: "red" }}>
              {" "}
              {errorcls.state[0]}{" "}
            </span>
          ) : (
            ""
          )
        ) : (
          ""
        )} */}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

<div className="col-12 px-2" style={{ paddingTop: "28px",display:"block" }}>
    <div className="row">
      <div className="col-lg-2">
        <label htmlFor="" className="form-label">
        </label>
      </div>
      <div className="col-lg-8 ">
      <input class="form-check-input" type="checkbox" value={doc1} 
      onChange={(e) => {
                            setdoc1(!doc1);
                          }} 
                           id="flexCheckChecked" /> {" "}
   Don't Have Document Images


      </div>
    </div>
  </div>
                    <div
                      className="col-md-6 px-2"
                      style={{
                        paddingTop: "28px",
                        display: notapplicable == true ? "none" : "block",
                      }}
                    >
                      <div className="row">
                        <div className="col-lg-4">
                          <label htmlFor="" className="form-label">
                            Front Image 
                          </label>
                          {modifiedImage !== null &&
                          documenttype == "AADHAAR" ? (
                            <div
                              style={{
                                display: "flex",
                                gap: "5px",
                                padding: "10px 4px",
                              }}
                            >
                              <button
                                onClick={() => {
                                  setvarx(varx - 15);
                                  modifyImage(aadhaarImage, varx, vary);
                                }}
                                style={{ backgroundColor: "#0C5398" }}
                                type="button"
                                className="btn btn-primary"
                              >
                                <BsFillArrowLeftCircleFill />
                              </button>
                              <button
                                onClick={() => {
                                  setvarx(varx + 15);
                                  modifyImage(aadhaarImage, varx, vary);
                                }}
                                style={{ backgroundColor: "#0C5398" }}
                                type="button"
                                className="btn btn-primary"
                              >
                                <BsFillArrowRightCircleFill />
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                          {modifiedImage !== null &&
                          documenttype == "AADHAAR" ? (
                            <div
                              style={{
                                display: "flex",
                                gap: "5px",
                                padding: "0px 4px",
                              }}
                            >
                              <button
                                style={{ backgroundColor: "#0C5398" }}
                                type="button"
                                onClick={() => {
                                  setvary(vary - 15);
                                  modifyImage(aadhaarImage, varx, vary);
                                }}
                                className="btn btn-primary"
                              >
                                <BsFillArrowUpCircleFill />
                              </button>
                              <button
                                style={{ backgroundColor: "#0C5398" }}
                                type="button"
                                onClick={() => {
                                  setvary(vary + 15);
                                  modifyImage(aadhaarImage, varx, vary);
                                }}
                                className="btn btn-primary"
                              >
                                <BsFillArrowDownCircleFill />
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-lg-8 ">
                        {doc1 == true ? <button
                            type="button"
                            style={{
                              border: "none",
                              outline: "none",
                              width: "100%",
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModaldocument"
                            onClick={() => {
                              sethideorshowtwo(!hideorshowtwo);
                            }}
                          >
                            <div
                              style={{
                                border: "1px solid #dee2e6",
                                backgroundColor: "white",
                                width: "100%",
                                height: "162px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {modifiedImage == null ? (
                                <img
                                  src={img7}
                                  alt="zxcvbnm"
                                  width="50%"
                                  height="102px"
                                />
                              ) : (
                                <img
                                  src={modifiedImage}
                                  alt="zxcvbnm"
                                  width="100%"
                                  height="162px"
                                />
                              )}
                            </div>
                          </button> : 
              <div style={{border:"1px solid #dee2e6",backgroundColor:"white",width:"100%",height:"162px",display:"flex",justifyContent:"center",alignItems:"center"}}>
                <label htmlFor="img1doc" style={{width:'100%',height:'100%',display:'contents'}}>
              {modifiedImage == null || modifiedImage == "none" ? <img src={img7} alt="zxcvbnm" width="50%" height="102px" /> : <img src={modifiedImage} alt="zxcvbnm" width="100%" height="162px" />}
              </label>
              <input accept="image/*" type="file" id="img1doc" style={{display:'none'}} onChange={(e)=>{changefrontimg(e.currentTarget.files[0])}} alt="304" />
              </div>
              }

                          {modifiedImage == null ? (
                            ""
                          ) : (
                            <button
                              style={{ backgroundColor: "#0C5398" }}
                              type="button"
                              className="btn btn-primary"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal34"
                            >
                              View
                            </button>
                          )}
                          {/* <input type="file" className="form-control " onChange={handleImageUpload} required /> */}
                          {errorcls ? (
                            errorcls.docImg_front ? (
                              <span style={{ color: "red" }}>
                                {" "}
                                {errorcls.docImg_front[0]}{" "}
                              </span>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}

                          {dfierror == true ? (
                            <span style={{ color: "red" }}>
                              {" "}
                              {drireserror !== "" ? drireserror : ""}{" "}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    {/* back image document */}
                    <div
                      className="col-md-6 px-2"
                      style={{
                        paddingTop: "28px",
                        display: notapplicable == true ? "none" : "block",
                      }}
                    >
                      <div className="row">
                        <div className="col-lg-4">
                          <label htmlFor="" className="form-label">
                            Back Image 
                          </label>
                          {documentimagebacktwo !== null &&
                          documenttype == "AADHAAR" ? (
                            <div
                              style={{
                                display: "flex",
                                gap: "5px",
                                padding: "10px 4px",
                              }}
                            >
                              <button
                                onClick={() => {
                                  setvarxtwo(varxtwo - 15);
                                  backmodifyImage(
                                    aadhaarImagetwo,
                                    varxtwo,
                                    varytwo
                                  );
                                }}
                                style={{ backgroundColor: "#0C5398" }}
                                type="button"
                                className="btn btn-primary"
                              >
                                <BsFillArrowLeftCircleFill />
                              </button>
                              <button
                                onClick={() => {
                                  setvarxtwo(varxtwo + 15);
                                  backmodifyImage(
                                    aadhaarImagetwo,
                                    varxtwo,
                                    varytwo
                                  );
                                }}
                                style={{ backgroundColor: "#0C5398" }}
                                type="button"
                                className="btn btn-primary"
                              >
                                <BsFillArrowRightCircleFill />
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                          {documentimagebacktwo !== null &&
                          documenttype == "AADHAAR" ? (
                            <div
                              style={{
                                display: "flex",
                                gap: "5px",
                                padding: "0px 4px",
                              }}
                            >
                              <button
                                style={{ backgroundColor: "#0C5398" }}
                                type="button"
                                onClick={() => {
                                  setvarytwo(varytwo - 15);
                                  backmodifyImage(
                                    aadhaarImagetwo,
                                    varxtwo,
                                    varytwo
                                  );
                                }}
                                className="btn btn-primary"
                              >
                                <BsFillArrowUpCircleFill />
                              </button>
                              <button
                                style={{ backgroundColor: "#0C5398" }}
                                type="button"
                                onClick={() => {
                                  setvarytwo(varytwo + 15);
                                  backmodifyImage(
                                    aadhaarImagetwo,
                                    varxtwo,
                                    varytwo
                                  );
                                }}
                                className="btn btn-primary"
                              >
                                <BsFillArrowDownCircleFill />
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-lg-8 ">
                        {doc1 == true ? <button
                            type="button"
                            style={{
                              border: "none",
                              outline: "none",
                              width: "100%",
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModaldocumenttwowwe"
                            onClick={() => {
                              sethideorshowthree(!hideorshowthree);
                            }}
                          >
                            <div
                              style={{
                                border: "1px solid #dee2e6",
                                backgroundColor: "white",
                                width: "100%",
                                height: "162px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {documentimagebacktwo == null ? (
                                <img
                                  src={img7}
                                  alt="zxcvbnm"
                                  width="50%"
                                  height="102px"
                                />
                              ) : (
                                <img
                                  src={documentimagebacktwo}
                                  alt="zxcvbnm"
                                  width="100%"
                                  height="162px"
                                />
                              )}
                            </div>
                          </button> :
              <div style={{border:"1px solid #dee2e6",backgroundColor:"white",width:"100%",height:"162px",display:"flex",justifyContent:"center",alignItems:"center"}}>
             <label htmlFor="img2doc" style={{width:'100%',height:'100%',display:'contents'}}>
              {documentimagebacktwo == null || documentimagebacktwo == "none" ? <img src={img7} alt="zxcvbnm" width="50%" height="102px" /> : <img src={documentimagebacktwo} alt="zxcvbnm" width="100%" height="162px" />}
              </label>
              <input accept="image/*" type="file" id="img2doc" style={{display:'none'}} onChange={(e)=>{changebackimg(e.currentTarget.files[0])}} alt="304" />
             
              </div>
             }

                          {documentimagebacktwo == null ? (
                            ""
                          ) : (
                            <button
                              style={{ backgroundColor: "#0C5398" }}
                              type="button"
                              className="btn btn-primary"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal35"
                            >
                              View
                            </button>
                          )}
                          {/* <input type="file" className="form-control " onChange={handleImageUpload} required /> */}
                          {/* {errorcls ? errorcls.docImg_back ? <span style={{color:'red'}}> {errorcls.docImg_back[0]} </span> : '' : ''} */}

                          {dbierror == true ? (
                            <span style={{ color: "red" }}>
                              {" "}
                              {dbireserror !== "" ? dbireserror : ""}{" "}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    {/* {modifiedImage !== null && documenttype == 'AADHAAR' ? <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <div style={{display:"flex",gap:"5px",padding:"10px 4px"}}>
          <button onClick={()=>{setvarx(varx-15);modifyImage(aadhaarImage,varx,vary)}}  style={{backgroundColor:"#0C5398"}}
                type='button'
                className='btn btn-primary'><BsFillArrowLeftCircleFill /></button>
                 <button onClick={()=>{setvarx(varx+15);modifyImage(aadhaarImage,varx,vary)}} style={{backgroundColor:"#0C5398"}}
                type='button'
                className='btn btn-primary'><BsFillArrowRightCircleFill /></button>
        </div>
        <div style={{display:"flex",gap:"5px",padding:"0px 4px"}}>
          <button  style={{backgroundColor:"#0C5398"}}
                type='button'
                onClick={()=>{setvary(vary-15);modifyImage(aadhaarImage,varx,vary)}}
                className='btn btn-primary'><BsFillArrowUpCircleFill /></button>
                 <button  style={{backgroundColor:"#0C5398"}}
                type='button'
                onClick={()=>{setvary(vary+15);modifyImage(aadhaarImage,varx,vary)}}
                className='btn btn-primary'><BsFillArrowDownCircleFill /></button>
        </div>
      </div>
      <div className="col-lg-8">
      <img src={modifiedImage} alt="Modified Aadhaar Card" style={{width:"100%",height:"100%"}} />
      </div>
    </div>
  </div> : aadhaarImage !== null ?<div className="offset-col-6 col-md-6 px-2" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
      </div>
      <div className="col-lg-8">
      <img src={aadhaarImage} alt="Modified Aadhaar Card" style={{width:"100%",height:"100%"}} />
      </div>
    </div>
  </div> : ''} */}

                    {/* <div className="col-md-5 px-2" style={{ paddingTop: "28px",display:notapplicable == false ? "none" : "block" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
        Form 60 
        </label>
      </div>
      <div className="col-lg-8">
        {notapplicable == true ? <input type="file" className="form-control"  onChange={(e) => {
                        setform60(e.target.files[0]);
                      }} /> : ''}

      </div>
    </div>
  </div> */}

                    {/* <div className="col-md-1" style={{ paddingTop: "28px",display:notapplicable == false ? "none" : "block" }}>
    <div className="row">
      <div className="col-lg-12" style={{display:"flex",justifyContent:'center',alignItems:"center",fontSize:'24px',fontWeight:'bold'}}>
        OR 
      </div>
    </div>
  </div> */}
                    {/* {notapplicable == true ? <div className="col-lg-6" >
        <div className="row">
        <div className="col-12 px-4">
  </div>
            <div className="col-12"><label htmlFor="" className="form-label"> <span style={{color:'red'}}></span></label></div>
            <div className="col-12 ">
            <div className="border round py-3 d-flex justify-content-center">
              <div>
              </div>

                     <button
                type='button'
                style={{border:'none',outline:'none'}}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModaltwo123"
                >
              <img src={displaydocument == '' ? img33 : displaydocument} alt="zxcvbnm" width="140px" height="162px" />
              </button>
             </div>
             <div className="col-12 py-2">
             <button
             style={{backgroundColor:"#0C5398"}}
                type='button'
                className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalwebcam123"
                  onClick={()=>{sethideorshow(!hideorshow)}}
                >
                  Capture
                </button>
             </div>
            </div>
          </div>
        </div> : ''}  */}

{idno == 1 ? documentsuperres == "SUCCESS" ? <div className="col-12 px-4 d-flex"> <div className="col-12 alert alert-success mt-3" role="alert">
            <h5 style={{padding:'0px',margin:"0px"}}>
         Verification Successfully
            </h5>
       </div></div> : '' : ''}
       {idno == 2 ? documentsuperres == "FAILURE" ? <div className="col-12 px-4 d-flex"> <div className="col-12 col-offset-2 alert alert-danger mt-3 ms-1" role="alert">
            <h5 style={{padding:'0px',margin:"0px"}}>
         {documentsupermsg}
            </h5>
       </div></div> : '' : '' }
       {idno == 3 ? <div className="col-12 px-4 d-flex"> <div className="col-12 col-offset-2 alert alert-danger mt-3 ms-1" role="alert">
            <h5 style={{padding:'0px',margin:"0px"}}>
         {documentsupermsg}
            </h5>
       </div></div> : '' }

                    {seconddocopen == true ? (
                      <div className="col-12 py-3 px-4">
                        <div
                          style={{
                            background: "#D9D9D9",
                            height: "1px",
                            width: "100%",
                          }}
                        ></div>
                      </div>
                    ) : (
                      ""
                    )}

{documentloader == true ? (
                      <div
                        className="col-12 d-flex justify-content-center"
                        style={{
                          gap: "4px",
                          position: "absolute",
                          width: "92%",
                        }}
                      >
                        <div className="px-2">
                          {documentloader == true ? (
                            <Loadercomp size={100} />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {seconddocopen == true ? (
                      <div className="col-12 ccolor py-3 px-4">Document 2</div>
                    ) : (
                      ""
                    )}

                    {seconddocopen == true ? (
                      <div
                        className="col-md-6 px-2"
                        style={{ paddingTop: "28px" }}
                      >
                        <div className="row">
                          <div className="col-lg-4">
                            <label htmlFor="" className="form-label">
                              Document Type{" "}
                              
                            </label>
                          </div>
                          <div className="col-lg-8">
                            <select
                              name=""
                              id=""
                              className="form-select"
                              aria-label="Default select example"
                              onChange={(e) => {
                                setdocumenttypetwo(e.target.value);
                                changedocumetimgtwo(e.target.value);
                              }}
                              required
                            >
                              <option value="defualt">Select Document</option>
                              {agentnationality == "india" ? (
                                <option value="AADHAAR">AADHAAR CARD</option>
                              ) : (
                                <></>
                              )}
                              {agentnationality == "india" ? (
                                <option value="DRIVING_LICENSE">
                                  DRIVING LICENSE
                                </option>
                              ) : (
                                <></>
                              )}
                              {agentnationality == "india" ? (
                                <option value="VOTERID">VOTER ID</option>
                              ) : (
                                <></>
                              )}
                              {agentnationality == "india" ? (
                                <option value="PASSPORT">PASSPORT</option>
                              ) : (
                                <></>
                              )}
                              {agentnationality == "Others" ? (
                                <option value="VISA">VISA</option>
                              ) : (
                                <></>
                              )}
                              {agentnationality == "Others" ? (
                                <option value="RESIDENT_PERMIT">
                                  RESIDENT PERMIT
                                </option>
                              ) : (
                                <></>
                              )}
                              {agentnationality == "Others" ? (
                                <option value="REFUGEE_CARD">
                                  REFUGEE CARD
                                </option>
                              ) : (
                                <></>
                              )}
                            </select>

                            {documentprivateerrortwo === true ? (
                              documenttypetwo == undefined ||
                              documenttypetwo == "defualt" ? (
                                <span style={{ color: "red" }}>
                                  Please Select Document
                                </span>
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}
                             {documenterror === true ? (
                            documenttypetwo == undefined || documenttypetwo == "" || documenttypetwo == "defualt" ? (
                              <span style={{ color: "red" }}>
                                Please Select document type
                              </span>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                            {/* {errorcls ? errorcls.document_type2 ? <span style={{color:'red'}}> {errorcls.document_type2[0]} </span> : '' : ''} */}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {seconddocopen == true ? (
                      <div
                        className="col-md-6 px-2"
                        style={{ paddingTop: "28px" }}
                      >
                        <div className="row">
                          <div className="col-lg-4">
                            <label htmlFor="" className="form-label">
                              Document No.{" "}
                              
                            </label>
                          </div>
                          <div className="col-lg-8">
                            <input
                              type="text"
                              className="form-control"
                              value={idnumbertwo}
                              placeholder={
                                documenttypetwo == "PAN"
                                  ? `PAN No.`
                                  : documenttypetwo == "VOTERID"
                                  ? `VOTER ID`
                                  : documenttypetwo == "PASSPORT"
                                  ? `PASSPORT No.`
                                  : documenttypetwo == "DRIVING_LICENSE"
                                  ? `DRIVING LICENSE No.`
                                  : documenttypetwo == "AADHAAR"
                                  ? `Aadhar No.`
                                  : documenttypetwo == "VISA"
                                  ? `VISA`
                                  : documenttypetwo == "RESIDENT_PERMIT"
                                  ? `RESIDENT PERMIT`
                                  : documenttypetwo == "REFUGEE_CARD"
                                  ? `REFUGEE CARD`
                                  : `Select Document`
                              }
                              onChange={(e) => {
                                setidnumbertwo(e.target.value);
                                setdocumentsuperresuniqe('')
                              }}
                            />
 {documenterror === true ? (
                            idnumbertwo == undefined || idnumbertwo == "" ? (
                              <span style={{ color: "red" }}>
                                Please Enter Your Document No
                              </span>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                            {errorcls ? (
          errorcls.document_no2 ? (
            <span style={{ color: "red" }}>
              {" "}
              {errorcls.document_no2[0]}{" "}
            </span>
          ) : (
            ""
          )
        ) : (
          ""
        )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {seconddocopen == true ? (
                      documenttypetwo == "DRIVING_LICENSE" || documenttypetwo == "RESIDENT_PERMIT" || documenttypetwo == "VISA" || documenttypetwo == "REFUGEE_CARD" ||
                      documenttypetwo == "PASSPORT" ? (
                        <div
                          className="col-md-6 px-2"
                          style={{ paddingTop: "28px" }}
                        >
                          <div className="row">
                            <div className="col-lg-4">
                              <label htmlFor="" className="form-label">
                                Expiry date{" "}
                                
                              </label>
                            </div>
                            <div className="col-lg-8">
                              <input
                                type="date"
                                className="form-control"
                                value={documentexpirytwo}
                                // placeholder="State"
                                onChange={(e) => {
                                  setdocumentexpirytwo(e.target.value);
                                }}
                                required
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {seconddocopen == true ? (
                      documenttypetwo == "DRIVING_LICENSE" || documenttypetwo == "RESIDENT_PERMIT" || documenttypetwo == "VISA" || documenttypetwo == "REFUGEE_CARD" ||
                      documenttypetwo == "PASSPORT" ? (
                        <div
                          className="col-md-6 px-2"
                          style={{ paddingTop: "28px" }}
                        >
                          <div className="row">
                            <div className="col-lg-4">
                              <label htmlFor="" className="form-label">
                                Issue date{" "}
                                
                              </label>
                            </div>
                            <div className="col-lg-8">
                              <input
                                type="date"
                                className="form-control"
                                value={documentissuetwo}
                                //   placeholder="Issue"
                                onChange={(e) => {
                                  setdocumentissuetwo(e.target.value);
                                }}
                                required
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}


<div className="col-12 px-2" style={{ paddingTop: "28px",display:"block" }}>
    <div className="row">
      <div className="col-lg-2">
        <label htmlFor="" className="form-label">
        </label>
      </div>
      <div className="col-lg-8 ">
      <input class="form-check-input" type="checkbox" value={doc2} 
      onChange={(e) => {
                            setdoc2(!doc2);
                          }} 
                           id="flexCheckChecked" /> {" "}
   Don't Have Document 2 Images


      </div>
    </div>
  </div>
                    {seconddocopen == true ? (
                      <div
                        className="col-md-6 px-2"
                        style={{ paddingTop: "28px" }}
                      >
                        <div className="row">
                          <div className="col-lg-4">
                            <label htmlFor="" className="form-label">
                              Front Image{" "}
                              
                            </label>
                            {modifiedImagenumbertwo !== null &&
                            documenttypetwo == "AADHAAR" ? (
                              <div
                                style={{
                                  display: "flex",
                                  gap: "5px",
                                  padding: "10px 4px",
                                }}
                              >
                                <button
                                  onClick={() => {
                                    setvarx2(varx2 - 15);
                                    modifyImagethree(
                                      aadhaarImage2,
                                      varx2,
                                      vary2
                                    );
                                  }}
                                  style={{ backgroundColor: "#0C5398" }}
                                  type="button"
                                  className="btn btn-primary"
                                >
                                  <BsFillArrowLeftCircleFill />
                                </button>
                                <button
                                  onClick={() => {
                                    setvarx2(varx2 + 15);
                                    modifyImagethree(
                                      aadhaarImage2,
                                      varx2,
                                      vary2
                                    );
                                  }}
                                  style={{ backgroundColor: "#0C5398" }}
                                  type="button"
                                  className="btn btn-primary"
                                >
                                  <BsFillArrowRightCircleFill />
                                </button>
                              </div>
                            ) : (
                              ""
                            )}
                            {modifiedImagenumbertwo !== null &&
                            documenttypetwo == "AADHAAR" ? (
                              <div
                                style={{
                                  display: "flex",
                                  gap: "5px",
                                  padding: "0px 4px",
                                }}
                              >
                                <button
                                  style={{ backgroundColor: "#0C5398" }}
                                  type="button"
                                  onClick={() => {
                                    setvary2(vary2 - 15);
                                    modifyImagethree(
                                      aadhaarImage2,
                                      varx2,
                                      vary2
                                    );
                                  }}
                                  className="btn btn-primary"
                                >
                                  <BsFillArrowUpCircleFill />
                                </button>
                                <button
                                  style={{ backgroundColor: "#0C5398" }}
                                  type="button"
                                  onClick={() => {
                                    setvary2(vary2 + 15);
                                    modifyImagethree(
                                      aadhaarImage2,
                                      varx2,
                                      vary2
                                    );
                                  }}
                                  className="btn btn-primary"
                                >
                                  <BsFillArrowDownCircleFill />
                                </button>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-lg-8 ">
                          {doc2 == true ? <button
                              type="button"
                              style={{
                                border: "none",
                                outline: "none",
                                width: "100%",
                              }}
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModaldocument2"
                              onClick={() => {
                                sethideorshow3(!hideorshow3);
                              }}
                            >
                              <div
                                style={{
                                  border: "1px solid #dee2e6",
                                  backgroundColor: "white",
                                  width: "100%",
                                  height: "162px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {modifiedImagenumbertwo == null ? (
                                  <img
                                    src={img7}
                                    alt="zxcvbnm"
                                    width="50%"
                                    height="102px"
                                  />
                                ) : (
                                  <img
                                    src={modifiedImagenumbertwo}
                                    alt="zxcvbnm"
                                    width="100%"
                                    height="162px"
                                  />
                                )}
                              </div>
                            </button> : 
              <div style={{border:"1px solid #dee2e6",backgroundColor:"white",width:"100%",height:"162px",display:"flex",justifyContent:"center",alignItems:"center"}}>
              <label htmlFor="img22doc" style={{width:'100%',height:'100%',display:'contents'}}>
              {modifiedImagenumbertwo == null ? <img src={img7} alt="zxcvbnm" width="50%" height="102px" /> : <img src={modifiedImagenumbertwo} alt="zxcvbnm" width="100%" height="162px" />}
              </label>
              <input accept="image/*" type="file" id="img22doc" style={{display:'none'}} onChange={(e)=>{twochangefrontimg(e.currentTarget.files[0])}} alt="304" />
              
              </div>
              }

                            {modifiedImagenumbertwo == null ? (
                              ""
                            ) : (
                              <button
                                style={{ backgroundColor: "#0C5398" }}
                                type="button"
                                className="btn btn-primary"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal340"
                              >
                                View
                              </button>
                            )}
                            {errorcls ? (
                              errorcls.docImg_front2 ? (
                                <span style={{ color: "red" }}>
                                  {" "}
                                  {errorcls.docImg_front2[0]}{" "}
                                </span>
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}

                            {dfierrortwo == true ? (
                              <span style={{ color: "red" }}>
                                {" "}
                                {drireserrortwo !== ""
                                  ? drireserrortwo
                                  : ""}{" "}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {/* back image document */}

                    {seconddocopen == true ? (
                      <div
                        className="col-md-6 px-2"
                        style={{ paddingTop: "28px" }}
                      >
                        <div className="row">
                          <div className="col-lg-4">
                            <label htmlFor="" className="form-label">
                              Back Image 
                            </label>
                            {documentimageback2 !== null &&
                            documenttypetwo == "AADHAAR" ? (
                              <div
                                style={{
                                  display: "flex",
                                  gap: "5px",
                                  padding: "10px 4px",
                                }}
                              >
                                <button
                                  onClick={() => {
                                    setvarxtwo2(varxtwo2 - 15);
                                    backmodifyImage3(
                                      aadhaarback2,
                                      varxtwo2,
                                      varytwo2
                                    );
                                  }}
                                  style={{ backgroundColor: "#0C5398" }}
                                  type="button"
                                  className="btn btn-primary"
                                >
                                  <BsFillArrowLeftCircleFill />
                                </button>
                                <button
                                  onClick={() => {
                                    setvarxtwo2(varxtwo2 + 15);
                                    backmodifyImage3(
                                      aadhaarback2,
                                      varxtwo2,
                                      varytwo2
                                    );
                                  }}
                                  style={{ backgroundColor: "#0C5398" }}
                                  type="button"
                                  className="btn btn-primary"
                                >
                                  <BsFillArrowRightCircleFill />
                                </button>
                              </div>
                            ) : (
                              ""
                            )}
                            {documentimageback2 !== null &&
                            documenttypetwo == "AADHAAR" ? (
                              <div
                                style={{
                                  display: "flex",
                                  gap: "5px",
                                  padding: "0px 4px",
                                }}
                              >
                                <button
                                  style={{ backgroundColor: "#0C5398" }}
                                  type="button"
                                  onClick={() => {
                                    setvarytwo2(varytwo2 - 15);
                                    backmodifyImage3(
                                      aadhaarback2,
                                      varxtwo2,
                                      varytwo2
                                    );
                                  }}
                                  className="btn btn-primary"
                                >
                                  <BsFillArrowUpCircleFill />
                                </button>
                                <button
                                  style={{ backgroundColor: "#0C5398" }}
                                  type="button"
                                  onClick={() => {
                                    setvarytwo2(varytwo2 + 15);
                                    backmodifyImage3(
                                      aadhaarback2,
                                      varxtwo2,
                                      varytwo2
                                    );
                                  }}
                                  className="btn btn-primary"
                                >
                                  <BsFillArrowDownCircleFill />
                                </button>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-lg-8 ">


                            {doc2 == true ? <button
                              type="button"
                              style={{
                                border: "none",
                                outline: "none",
                                width: "100%",
                              }}
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModaldocumenttwowwetwo"
                              onClick={() => {
                                sethideorshow3(!hideorshow3);
                              }}
                            >
                              <div
                                style={{
                                  border: "1px solid #dee2e6",
                                  backgroundColor: "white",
                                  width: "100%",
                                  height: "162px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {documentimageback2 == null ? (
                                  <img
                                    src={img7}
                                    alt="zxcvbnm"
                                    width="50%"
                                    height="102px"
                                  />
                                ) : (
                                  <img
                                    src={documentimageback2}
                                    alt="zxcvbnm"
                                    width="100%"
                                    height="162px"
                                  />
                                )}
                              </div>
                            </button> : 

<div style={{border:"1px solid #dee2e6",backgroundColor:"white",width:"100%",height:"162px",display:"flex",justifyContent:"center",alignItems:"center"}}>
<label htmlFor="img33doc" style={{width:'100%',height:'100%',display:'contents'}}>
{documentimageback2 == null ? <img src={img7} alt="zxcvbnm" width="50%" height="102px" /> : <img src={documentimageback2} alt="zxcvbnm" width="100%" height="162px" />}
</label>
<input accept="image/*" type="file" id="img33doc" style={{display:'none'}} onChange={(e)=>{twochangebackimg(e.currentTarget.files[0])}} alt="304" />

</div>
}
                            {documentimageback2 == null ? (
                              ""
                            ) : (
                              <button
                                style={{ backgroundColor: "#0C5398" }}
                                type="button"
                                className="btn btn-primary"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal350"
                              >
                                View
                              </button>
                            )}
                            {/* <input type="file" className="form-control " onChange={handleImageUpload} required /> */}
                            {errorcls ? (
                              errorcls.docImg_back2 ? (
                                <span style={{ color: "red" }}>
                                  {" "}
                                  {errorcls.docImg_back2[0]}{" "}
                                </span>
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}

                            {dbierrortwo == true ? (
                              <span style={{ color: "red" }}>
                                {" "}
                                {dbireserrortwo !== ""
                                  ? dbireserrortwo
                                  : ""}{" "}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                {idno2 == 1 ? documentsuperresuniqe == "SUCCESS" ? <div className="col-12 px-4 d-flex"> <div className="col-12 alert alert-success mt-3" role="alert">
            <h5 style={{padding:'0px',margin:"0px"}}>
         Verification Successfully
            </h5>
       </div></div> : '' : ''}
       {idno2 == 2 ? documentsuperresuniqe == "FAILURE" ? <div className="col-12 px-4 d-flex"> <div className="col-12 col-offset-2 alert alert-danger mt-3 ms-1" role="alert">
            <h5 style={{padding:'0px',margin:"0px"}}>
         {documentsupermsguniqe}
            </h5>
       </div></div> : '' : '' }
       {idno2 == 3 ? <div className="col-12 px-4 d-flex"> <div className="col-12 col-offset-2 alert alert-danger mt-3 ms-1" role="alert">
            <h5 style={{padding:'0px',margin:"0px"}}>
         {documentsupermsguniqe}
            </h5>
       </div></div> : '' }
{agentnationality == "india" ? <div className="col-12 py-4 px-4 d-flex justify-content-end">
        {/* <button className="btn1"> +Add</button> */}
        <button onClick={documentverify} type='button' className="btn2"> Submit</button>
      </div> : ''}
                

                <div className="col-9 py-2 pt-5 px-4 d-flex justify-content-center">
                  {butsub == true ? (
                    <div
                      class="alert alert-success"
                      role="alert"
                      style={{ width: "100%" }}
                    >
                      Buy Subscription Successfully
                    </div>
                  ) : (
                    ""
                  )}
                  {editagenterror == true ? (
                    <div
                      class="alert alert-danger"
                      role="alert"
                      style={{ width: "100%" }}
                    >
                      Something Went Wrong Try Again
                    </div>
                  ) : (
                    ""
                  )}
                  {editlocation == true ? (
                    <div
                      class="alert alert-danger"
                      role="alert"
                      style={{ width: "100%" }}
                    >
                      Something Wrong in Your Branch Details
                    </div>
                  ) : (
                    ""
                  )}
                  {purchaseerror == true ? (
                    <div
                      class="alert alert-success"
                      role="alert"
                      style={{ width: "100%" }}
                    >
                      Something Wrong In Your Account
                    </div>
                  ) : (
                    ""
                  )}
                  {checkdocumenterror == true ? (
                    <div
                      class="alert alert-danger"
                      role="alert"
                      style={{ width: "100%" }}
                    >
                      Please Verify Your Documents
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className="col-3 py-2 pt-5 px-4 d-flex justify-content-end align-items-center"
                  style={{ gap: "4px" }}
                >
                  <button type="submit" className="btn5withoutradius">
                    Buy Subscription
                  </button>
                </div>
              </div>

              <div
                className="modal fade"
                id="exampleModal35"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered modal-xl">
                  <div className="modal-content">
                    <div className="modal-header mod-line">
                      {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div
                        className="row bg-white round"
                        style={{
                          border: "1px solid #E0E0E0",
                          margin: "0px 0px",
                          borderRadius: "3px",
                          paddingBottom: "3rem",
                        }}
                      >
                        <div className="col-12 px-2 pt-3">
                          <img
                            src={
                              documentimagebacktwo == null
                                ? img3
                                : documentimagebacktwo
                            }
                            style={{ width: "100%", height: "100%" }}
                            alt="photograph"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="exampleModal34"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered modal-xl">
                  <div className="modal-content">
                    <div className="modal-header mod-line">
                      {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div
                        className="row bg-white round"
                        style={{
                          border: "1px solid #E0E0E0",
                          margin: "0px 0px",
                          borderRadius: "3px",
                          paddingBottom: "3rem",
                        }}
                      >
                        <div className="col-12 px-2 pt-3">
                          <img
                            src={modifiedImage == null ? img3 : modifiedImage}
                            style={{ width: "100%", height: "100%" }}
                            alt="photograph"
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="exampleModaldocument"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                  <div className="modal-content">
                    <div className="modal-header mod-line">
                      {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        onClick={() => {
                          sethideorshowtwo(!hideorshowtwo);
                        }}
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div
                        className="row bg-white round"
                        style={{
                          border: "1px solid #E0E0E0",
                          margin: "0px 0px",
                          borderRadius: "3px",
                        }}
                      >
                        {hideorshowtwo == true ? (
                          <div className="col-12 d-flex py-3 justify-content-center">
                            <div className="px-2">
                              {reloadin == false ? "" : hideorshowtwo == true ? (
                                <Webcam ref={webcamRefs[0]} />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="modal-footer mod-line m-auto">
                      {/* <button type="button" className="btn closecancel">Cancel</button> */}
                      <button type="button" className="btn closecancel" onClick={()=>{reloadcam()}}>Reload</button>
                      <button
                        type="button"
                        onClick={() => {
                          captureimagetwo(0);
                        }}
                        aria-label="Close"
                        data-bs-dismiss="modal"
                        className="btn closebtn text-white"
                      >
                        Capture
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="exampleModal350"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered modal-xl">
                  <div className="modal-content">
                    <div className="modal-header mod-line">
                      {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div
                        className="row bg-white round"
                        style={{
                          border: "1px solid #E0E0E0",
                          margin: "0px 0px",
                          borderRadius: "3px",
                          paddingBottom: "3rem",
                        }}
                      >
                        <div className="col-12 px-2 pt-3">
                          <img
                            src={
                              documentimageback2 == null
                                ? img3
                                : documentimageback2
                            }
                            style={{ width: "100%", height: "100%" }}
                            alt="photograph"
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="exampleModalwebcam"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                  <div className="modal-content">
                    <div className="modal-header mod-line">
                      {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        onClick={() => {
                          sethideorshow(!hideorshow);
                        }}
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div
                        className="row bg-white round"
                        style={{
                          border: "1px solid #E0E0E0",
                          margin: "0px 0px",
                          borderRadius: "3px",
                        }}
                      >
                        {hideorshow == true ? (
                          <div className="col-12 d-flex py-3 justify-content-center">
                            <div className="px-2">
                              {reloadin == false ? "" : hideorshow == true ? (
                                <Webcam ref={webcamRefs[1]} />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="modal-footer mod-line m-auto">
                    <button type="button" className="btn closecancel" onClick={()=>{reloadcam()}}>Reload</button>
                      {/* <button type="button" className="btn closecancel">Cancel</button> */}
                      <button
                        type="button"
                        onClick={() => {
                          captureimage(1);
                        }}
                        aria-label="Close"
                        data-bs-dismiss="modal"
                        className="btn closebtn text-white"
                      >
                        Capture
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="exampleModal340"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered modal-xl">
                  <div className="modal-content">
                    <div className="modal-header mod-line">
                      {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div
                        className="row bg-white round"
                        style={{
                          border: "1px solid #E0E0E0",
                          margin: "0px 0px",
                          borderRadius: "3px",
                          paddingBottom: "3rem",
                        }}
                      >
                        <div className="col-12 px-2 pt-3">
                          <img
                            src={
                              modifiedImagenumbertwo == null
                                ? img3
                                : modifiedImagenumbertwo
                            }
                            style={{ width: "100%", height: "100%" }}
                            alt="photograph"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="exampleModaldocumenttwowwe"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                  <div className="modal-content">
                    <div className="modal-header mod-line">
                      {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        onClick={() => {
                          sethideorshowthree(!hideorshowthree);
                        }}
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div
                        className="row bg-white round"
                        style={{
                          border: "1px solid #E0E0E0",
                          margin: "0px 0px",
                          borderRadius: "3px",
                        }}
                      >
                        {hideorshowthree == true ? (
                          <div className="col-12 d-flex py-3 justify-content-center">
                            <div className="px-2">
                              {reloadin == false ? "" : hideorshowthree == true ? (
                                <Webcamtwo ref={webcamRefs[2]} />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="modal-footer mod-line m-auto">
                      {/* <button type="button" className="btn closecancel">Cancel</button> */}
                      <button type="button" className="btn closecancel" onClick={()=>{reloadcam()}}>Reload</button>
                      <button
                        type="button"
                        onClick={() => {
                          captureimagethree(2);
                        }}
                        aria-label="Close"
                        data-bs-dismiss="modal"
                        className="btn closebtn text-white"
                      >
                        Capture
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="exampleModaldocument2"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                  <div className="modal-content">
                    <div className="modal-header mod-line">
                      {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        onClick={() => {
                          sethideorshow3(!hideorshow3);
                        }}
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div
                        className="row bg-white round"
                        style={{
                          border: "1px solid #E0E0E0",
                          margin: "0px 0px",
                          borderRadius: "3px",
                        }}
                      >
                        {hideorshow3 == true ? (
                          <div className="col-12 d-flex py-3 justify-content-center">
                            <div className="px-2">
                              {reloadin == false ? "" : hideorshow3 == true ? (
                                <Webcam ref={webcamRefs[3]} />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="modal-footer mod-line m-auto">
                      {/* <button type="button" className="btn closecancel">Cancel</button> */}
                      <button type="button" className="btn closecancel" onClick={()=>{reloadcam()}}>Reload</button>
                      <button
                        type="button"
                        onClick={() => {
                          captureimagesuper2(3);
                        }}
                        aria-label="Close"
                        data-bs-dismiss="modal"
                        className="btn closebtn text-white"
                      >
                        Capture
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="exampleModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                  <div className="modal-content">
                    <div className="modal-header mod-line">
                      {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div
                        className="row bg-white round"
                        style={{
                          border: "1px solid #E0E0E0",
                          margin: "0px 0px",
                          borderRadius: "3px",
                          paddingBottom: "3rem",
                        }}
                      >
                        <div className="col-lg-6 px-2 pt-3">
                          <div className="row">
                            <div
                              className="col-12 d-flex align-items-center justify-content-between"
                              style={{ gap: "15px" }}
                            >
                              <label htmlFor="tf2" className="form-label ">
                                Right Thumb{" "}
                                
                              </label>
                              {/* <input type="file" class="form-control" id='tf2' name="image2" style={{opacity:"0",position:'absolute'}}  onChange={(e) => {handleImageChange11(e)}}  /> */}
                              {/* <label htmlFor="tf"> */}
                              {/* <div onClick={(e) => {handleImageChange11()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: thumbimg2 != '' ? '' : '3rem',width:"20%" }}> {thumbimg2 != '' ? <img src={`data:image/png;base64,${thumbimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
                              <div
                                onClick={(e) => {
                                  handleImageChange11();
                                }}
                                style={{
                                  border: "1px solid #E0E0E0",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  margin: "0px 0px",
                                  borderRadius: "3px",
                                  padding:
                                    thumbimg2 != ""
                                      ? ""
                                      : rtferror == true
                                      ? "8px 0px"
                                      : "0 0 3rem 0",
                                  width: "20%",
                                }}
                              >
                                {" "}
                                {thumbimg2 != "" ? (
                                  <img
                                    src={`data:image/png;base64,${thumbimg2}`}
                                    width="70px"
                                    height="60px"
                                    alt="asd"
                                  />
                                ) : rtferror == true ? (
                                  <Loadercomp size={30} />
                                ) : (
                                  ""
                                )}{" "}
                              </div>
                              {thumbimg2 != "" ? (
                                <img
                                  src={checkicon}
                                  width="25px"
                                  height="25px"
                                  alt="asd"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 px-2 pt-3">
                          <div className="row">
                            <div
                              className="col-12 d-flex align-items-center justify-content-between"
                              style={{ gap: "15px" }}
                            >
                              <label htmlFor="ltf2" className="form-label ">
                                Left Thumb{" "}
                                
                              </label>
                              {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="ltf2" onChange={(e) => {handleImageChange16(e)}} /> */}
                              {/* <div onClick={(e) => {handleImageChange16()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: ltfimg2 != '' ? '' : '3rem',width:"20%" }}> {ltfimg2 != '' ? <img src={`data:image/png;base64,${ltfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
                              <div
                                onClick={(e) => {
                                  handleImageChange16();
                                }}
                                style={{
                                  border: "1px solid #E0E0E0",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  margin: "0px 0px",
                                  borderRadius: "3px",
                                  padding:
                                    ltfimg2 != ""
                                      ? ""
                                      : ltferror == true
                                      ? "8px 0px"
                                      : "0 0 3rem 0",
                                  width: "20%",
                                }}
                              >
                                {" "}
                                {ltfimg2 != "" ? (
                                  <img
                                    src={`data:image/png;base64,${ltfimg2}`}
                                    width="70px"
                                    height="60px"
                                    alt="asd"
                                  />
                                ) : ltferror == true ? (
                                  <Loadercomp size={30} />
                                ) : (
                                  ""
                                )}{" "}
                              </div>
                              {ltfimg2 != "" ? (
                                <img
                                  src={checkicon}
                                  width="25px"
                                  height="25px"
                                  alt="asd"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 px-2 pt-3">
                          <div className="row">
                            <div
                              className="col-12 d-flex align-items-center justify-content-between"
                              style={{ gap: "15px" }}
                            >
                              <label htmlFor="rif2" className="form-label ">
                                Right Index Finger{" "}
                                
                              </label>
                              {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rif2" onChange={(e) => {handleImageChange12(e)}} /> */}
                              {/* <div onClick={(e) => {handleImageChange12()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rifimg2 != '' ? '' : '3rem',width:"20%" }}> {rifimg2 != '' ? <img src={`data:image/png;base64,${rifimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
                              <div
                                onClick={(e) => {
                                  handleImageChange12();
                                }}
                                style={{
                                  border: "1px solid #E0E0E0",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  margin: "0px 0px",
                                  borderRadius: "3px",
                                  padding:
                                    rifimg2 != ""
                                      ? ""
                                      : riferror == true
                                      ? "8px 0px"
                                      : "0 0 3rem 0",
                                  width: "20%",
                                }}
                              >
                                {" "}
                                {rifimg2 != "" ? (
                                  <img
                                    src={`data:image/png;base64,${rifimg2}`}
                                    width="70px"
                                    height="60px"
                                    alt="asd"
                                  />
                                ) : riferror == true ? (
                                  <Loadercomp size={30} />
                                ) : (
                                  ""
                                )}{" "}
                              </div>
                              {rifimg2 != "" ? (
                                <img
                                  src={checkicon}
                                  width="25px"
                                  height="25px"
                                  alt="asd"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 px-2 pt-3">
                          <div className="row">
                            <div
                              className="col-12 d-flex align-items-center justify-content-between"
                              style={{ gap: "15px" }}
                            >
                              <label htmlFor="lif2" className="form-label ">
                                Left Index Finger{" "}
                                
                              </label>
                              {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lif2" onChange={(e) => {handleImageChange17(e)}} /> */}
                              {/* <div onClick={(e) => {handleImageChange17()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lifimg2 != '' ? '' : '3rem',width:"20%" }}> {lifimg2 != '' ? <img src={`data:image/png;base64,${lifimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
                              <div
                                onClick={(e) => {
                                  handleImageChange17();
                                }}
                                style={{
                                  border: "1px solid #E0E0E0",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  margin: "0px 0px",
                                  borderRadius: "3px",
                                  padding:
                                    lifimg2 != ""
                                      ? ""
                                      : liferror == true
                                      ? "8px 0px"
                                      : "0 0 3rem 0",
                                  width: "20%",
                                }}
                              >
                                {" "}
                                {lifimg2 != "" ? (
                                  <img
                                    src={`data:image/png;base64,${lifimg2}`}
                                    width="70px"
                                    height="60px"
                                    alt="asd"
                                  />
                                ) : liferror == true ? (
                                  <Loadercomp size={30} />
                                ) : (
                                  ""
                                )}{" "}
                              </div>
                              {lifimg2 != "" ? (
                                <img
                                  src={checkicon}
                                  width="25px"
                                  height="25px"
                                  alt="asd"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 px-2 pt-3">
                          <div className="row">
                            <div
                              className="col-12 d-flex align-items-center justify-content-between"
                              style={{ gap: "15px" }}
                            >
                              <label htmlFor="rmf2" className="form-label ">
                                Right Middle Finger
                                
                              </label>
                              {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rmf2" onChange={(e) => {handleImageChange13(e)}} /> */}
                              {/* <div onClick={(e) => {handleImageChange13()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rmfimg2 != '' ? '' : '3rem',width:"20%" }}> {rmfimg2 != '' ? <img src={`data:image/png;base64,${rmfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
                              <div
                                onClick={(e) => {
                                  handleImageChange13();
                                }}
                                style={{
                                  border: "1px solid #E0E0E0",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  margin: "0px 0px",
                                  borderRadius: "3px",
                                  padding:
                                    rmfimg2 != ""
                                      ? ""
                                      : rmferror == true
                                      ? "8px 0px"
                                      : "0 0 3rem 0",
                                  width: "20%",
                                }}
                              >
                                {" "}
                                {rmfimg2 != "" ? (
                                  <img
                                    src={`data:image/png;base64,${rmfimg2}`}
                                    width="70px"
                                    height="60px"
                                    alt="asd"
                                  />
                                ) : rmferror == true ? (
                                  <Loadercomp size={30} />
                                ) : (
                                  ""
                                )}{" "}
                              </div>
                              {rmfimg2 != "" ? (
                                <img
                                  src={checkicon}
                                  width="25px"
                                  height="25px"
                                  alt="asd"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 px-2 pt-3">
                          <div className="row">
                            <div
                              className="col-12 d-flex align-items-center justify-content-between"
                              style={{ gap: "15px" }}
                            >
                              <label htmlFor="lmf2" className="form-label ">
                                Left Middle Finger{" "}
                                
                              </label>
                              {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lmf2" onChange={(e) => {handleImageChange18(e)}} /> */}
                              {/* <div onClick={(e) => {handleImageChange18()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lmfimg2 != '' ? '' : '3rem',width:"20%" }}> {lmfimg2 != '' ? <img src={`data:image/png;base64,${lmfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
                              <div
                                onClick={(e) => {
                                  handleImageChange18();
                                }}
                                style={{
                                  border: "1px solid #E0E0E0",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  margin: "0px 0px",
                                  borderRadius: "3px",
                                  padding:
                                    lmfimg2 != ""
                                      ? ""
                                      : lmferror == true
                                      ? "8px 0px"
                                      : "0 0 3rem 0",
                                  width: "20%",
                                }}
                              >
                                {" "}
                                {lmfimg2 != "" ? (
                                  <img
                                    src={`data:image/png;base64,${lmfimg2}`}
                                    width="70px"
                                    height="60px"
                                    alt="asd"
                                  />
                                ) : lmferror == true ? (
                                  <Loadercomp size={30} />
                                ) : (
                                  ""
                                )}{" "}
                              </div>
                              {lmfimg2 != "" ? (
                                <img
                                  src={checkicon}
                                  width="25px"
                                  height="25px"
                                  alt="asd"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 px-2 pt-3">
                          <div className="row">
                            <div
                              className="col-12 d-flex align-items-center justify-content-between"
                              style={{ gap: "15px" }}
                            >
                              <label htmlFor="rrf2" className="form-label ">
                                Right Ring Finger{" "}
                                
                              </label>
                              {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rrf2" onChange={(e) => {handleImageChange14(e)}} /> */}
                              {/* <div onClick={(e) => {handleImageChange14()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rrfimg2 != '' ? '' : '3rem',width:"20%" }}> {rrfimg2 != '' ? <img src={`data:image/png;base64,${rrfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
                              <div
                                onClick={(e) => {
                                  handleImageChange14();
                                }}
                                style={{
                                  border: "1px solid #E0E0E0",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  margin: "0px 0px",
                                  borderRadius: "3px",
                                  padding:
                                    rrfimg2 != ""
                                      ? ""
                                      : rrferror == true
                                      ? "8px 0px"
                                      : "0 0 3rem 0",
                                  width: "20%",
                                }}
                              >
                                {" "}
                                {rrfimg2 != "" ? (
                                  <img
                                    src={`data:image/png;base64,${rrfimg2}`}
                                    width="70px"
                                    height="60px"
                                    alt="asd"
                                  />
                                ) : rrferror == true ? (
                                  <Loadercomp size={30} />
                                ) : (
                                  ""
                                )}{" "}
                              </div>
                              {rrfimg2 != "" ? (
                                <img
                                  src={checkicon}
                                  width="25px"
                                  height="25px"
                                  alt="asd"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 px-2 pt-3">
                          <div className="row">
                            <div
                              className="col-12 d-flex align-items-center justify-content-between"
                              style={{ gap: "15px" }}
                            >
                              <label htmlFor="lrf2" className="form-label ">
                                Left Ring Finger{" "}
                                
                              </label>
                              {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lrf2" onChange={(e) => {handleImageChange19(e)}} /> */}
                              {/* <div onClick={(e) => {handleImageChange19()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lrfimg2 != '' ? '' : '3rem',width:"20%" }}> {lrfimg2 != '' ? <img src={`data:image/png;base64,${lrfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
                              <div
                                onClick={(e) => {
                                  handleImageChange19();
                                }}
                                style={{
                                  border: "1px solid #E0E0E0",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  margin: "0px 0px",
                                  borderRadius: "3px",
                                  padding:
                                    lrfimg2 != ""
                                      ? ""
                                      : lrferror == true
                                      ? "8px 0px"
                                      : "0 0 3rem 0",
                                  width: "20%",
                                }}
                              >
                                {" "}
                                {lrfimg2 != "" ? (
                                  <img
                                    src={`data:image/png;base64,${lrfimg2}`}
                                    width="70px"
                                    height="60px"
                                    alt="asd"
                                  />
                                ) : lrferror == true ? (
                                  <Loadercomp size={30} />
                                ) : (
                                  ""
                                )}{" "}
                              </div>
                              {lrfimg2 != "" ? (
                                <img
                                  src={checkicon}
                                  width="25px"
                                  height="25px"
                                  alt="asd"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 px-2 pt-3">
                          <div className="row">
                            <div
                              className="col-12 d-flex align-items-center justify-content-between"
                              style={{ gap: "15px" }}
                            >
                              <label htmlFor="rlf2" className="form-label ">
                                Right Little Finger{" "}
                                
                              </label>
                              {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rlf2" onChange={(e) => {handleImageChange15(e)}} /> */}
                              {/* <div onClick={(e) => {handleImageChange15()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rlfimg2 != '' ? '' : '3rem',width:"20%" }}> {rlfimg2 != '' ? <img src={`data:image/png;base64,${rlfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
                              <div
                                onClick={(e) => {
                                  handleImageChange15();
                                }}
                                style={{
                                  border: "1px solid #E0E0E0",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  margin: "0px 0px",
                                  borderRadius: "3px",
                                  padding:
                                    rlfimg2 != ""
                                      ? ""
                                      : rlferror == true
                                      ? "8px 0px"
                                      : "0 0 3rem 0",
                                  width: "20%",
                                }}
                              >
                                {" "}
                                {rlfimg2 != "" ? (
                                  <img
                                    src={`data:image/png;base64,${rlfimg2}`}
                                    width="70px"
                                    height="60px"
                                    alt="asd"
                                  />
                                ) : rlferror == true ? (
                                  <Loadercomp size={30} />
                                ) : (
                                  ""
                                )}{" "}
                              </div>
                              {rlfimg2 != "" ? (
                                <img
                                  src={checkicon}
                                  width="25px"
                                  height="25px"
                                  alt="asd"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 px-2 pt-3">
                          <div className="row">
                            <div
                              className="col-12 d-flex align-items-center justify-content-between"
                              style={{ gap: "15px" }}
                            >
                              <label htmlFor="llf2" className="form-label ">
                                Left Little Finger{" "}
                                
                              </label>
                              {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="llf2" onChange={(e) => {handleImageChange20(e)}} /> */}
                              {/* <div onClick={(e) => {handleImageChange20()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: llfimg2 != '' ? '' : '3rem',width:"20%" }}> {llfimg2 != '' ? <img src={`data:image/png;base64,${llfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
                              <div
                                onClick={(e) => {
                                  handleImageChange20();
                                }}
                                style={{
                                  border: "1px solid #E0E0E0",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  margin: "0px 0px",
                                  borderRadius: "3px",
                                  padding:
                                    llfimg2 != ""
                                      ? ""
                                      : llferror == true
                                      ? "8px 0px"
                                      : "0 0 3rem 0",
                                  width: "20%",
                                }}
                              >
                                {" "}
                                {llfimg2 != "" ? (
                                  <img
                                    src={`data:image/png;base64,${llfimg2}`}
                                    width="70px"
                                    height="60px"
                                    alt="asd"
                                  />
                                ) : llferror == true ? (
                                  <Loadercomp size={30} />
                                ) : (
                                  ""
                                )}{" "}
                              </div>
                              {llfimg2 != "" ? (
                                <img
                                  src={checkicon}
                                  width="25px"
                                  height="25px"
                                  alt="asd"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="exampleModaldocumenttwowwetwo"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                  <div className="modal-content">
                    <div className="modal-header mod-line">
                      {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        onClick={() => {
                          sethideorshow3(!hideorshow3);
                        }}
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div
                        className="row bg-white round"
                        style={{
                          border: "1px solid #E0E0E0",
                          margin: "0px 0px",
                          borderRadius: "3px",
                        }}
                      >
                        {hideorshow3 == true ? (
                          <div className="col-12 d-flex py-3 justify-content-center">
                            <div className="px-2">
                              {reloadin == false ? "" : hideorshow3 == true ? (
                                <Webcamtwo ref={webcamRefs[4]} />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="modal-footer mod-line m-auto">
                      {/* <button type="button" className="btn closecancel">Cancel</button> */}
                      <button type="button" className="btn closecancel" onClick={()=>{reloadcam()}}>Reload</button>
                      <button
                        type="button"
                        onClick={() => {
                          captureimagethreetwo(4);
                        }}
                        aria-label="Close"
                        data-bs-dismiss="modal"
                        className="btn closebtn text-white"
                      >
                        Capture
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="exampleModaldocumenttwowwe"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                  <div className="modal-content">
                    <div className="modal-header mod-line">
                      {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        onClick={() => {
                          sethideorshowthree(!hideorshowthree);
                        }}
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div
                        className="row bg-white round"
                        style={{
                          border: "1px solid #E0E0E0",
                          margin: "0px 0px",
                          borderRadius: "3px",
                        }}
                      >
                        {hideorshowthree == true ? (
                          <div className="col-12 d-flex py-3 justify-content-center">
                            <div className="px-2">
                              {reloadin == false ? "" : hideorshowthree == true ? (
                                <Webcamtwo ref={webcamRefs[1]} />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="modal-footer mod-line m-auto">
                      {/* <button type="button" className="btn closecancel">Cancel</button> */}
                      <button type="button" className="btn closecancel" onClick={()=>{reloadcam()}}>Reload</button>
                      <button
                        type="button"
                        onClick={() => {
                          captureimagethree(1);
                        }}
                        aria-label="Close"
                        data-bs-dismiss="modal"
                        className="btn closebtn text-white"
                      >
                        Capture
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
      </section>
    </div>
  );
};

export default Checkout;
