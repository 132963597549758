import React, { useEffect, useState } from 'react'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { NavLink, useParams } from 'react-router-dom';
import { gettoken } from '../../Localstorage/Store';
import axios from 'axios';
import Loadercomp from '../../components/Loadercomp';

const Subscriptiondetail = () => {

  const {id} = useParams()
  
  const [srtloader, setsrtloader] = useState(true);
  const gettokinval = gettoken();
 
  const [crtdate, setcrtdate] = useState('');

  const [planname, setplanname] = useState();
  const [duration, setduration] = useState();
  const [discount, setdiscount] = useState();
  const [price, setprice] = useState();
  const [gst, setgst] = useState();
  const [description, setdescription] = useState();
  const [totalamount, settotalamount] = useState();
  const [isstatus, setisstatus] = useState();


// fetch user details ----->
  useEffect(() => {
    async function fetchOne() {

      const config = {
        headers: {
          Authorization: `Bearer ${gettokinval.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}api/service-plans/${id}/`;
      const response = await axios.get(url, config);
      setsrtloader(false)
      setdescription(response.data.sub_desc)
      setgst(response.data.gst_rate)
      setprice(response.data.price)
      setdiscount(response.data.discount)
      setduration(response.data.duration_days)
      setplanname(response.data.sub_name)
      settotalamount(response.data.total_amount)
      setisstatus(response.data.is_active)


      // if(response.data.dob !== undefined){
      //   const newdate = () => {
      //     // const num = parseInt(dateString)
      //      const dateParts = response.data.dob.split('-');
      //      console.log("mmmmm",dateParts)
      //      const year = parseInt(dateParts[0], 10);
      //      const month = parseInt(dateParts[1], 10);
      //      const day = parseInt(dateParts[2], 10);
         
      //      const months = [
      //        'January',
      //        'February',
      //        'March',
      //        'April',
      //        'May',
      //        'June',
      //        'July',
      //        'August',
      //        'September',
      //        'October',
      //        'November',
      //        'December',
      //      ];
         
      //      const formattedDate = `${day} ${months[month - 1]}, ${year}`;
      //      setcrtdate(formattedDate)
      //    };
      //    newdate();
      // }
    }
    fetchOne();
  }, []);
// fetch user details ----->



  return (
    <div style={{width:'100%'}}>
      {/* <Header /> */}
      <div className="dashboardcontent px-2">
  
      <div className="container-fuild px-2 desgin1">
      <div className="row bg-white ">
            <div className="col-lg-12 d-flex justify-content-between py-2" style={{ background: 'hsla(210, 85%, 32%, 0.2)', color: '#0C5398' }}>
              <p className="m-0 customfont" >Subscription Detail</p>
              <div className="addnew d-flex mb-2" style={{gap:"4px"}}>
                <button className="btn text-white closebtn"><NavLink to='/subscriptionlist/0' style={{ textDecoration: 'none', color: 'white' }}> x Close</NavLink></button>
              </div>
            </div>
        </div>
      </div>
      {srtloader == true ? <div className="container-fuild bg-white"> <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"100%"}}>
        <div className='px-2'> <Loadercomp size={100} /></div>
        </div></div> : <div className="container-fuild pb-4 pt-3 px-2 bg-white" id="pdf-content">
       <div className="row bg-white pb-4 round" style={{border:'1px solid #E0E0E0',margin:"10px 0px",borderRadius: '3px',position:'relative'}}>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Plan Name </label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{planname}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Duration </label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{duration}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Gst </label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{gst} %</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Price </label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>₹{price}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Discount </label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{discount} %</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Description </label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{description}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Status </label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{isstatus == true ? 'Active' : 'inActive'} </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Total Amount </label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>₹{totalamount} </p>
            </div>
          </div>
        </div>
       

 

      </div>
      </div>}
      </div>
    
    </div>
  )
}

export default Subscriptiondetail