import Header from '../../components/Header'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { Switch } from '@mui/material';
import { useState } from 'react';
import axios from 'axios';
import { gettoken } from '../../Localstorage/Store';
import { NavLink, useNavigate } from 'react-router-dom';
import Loadercomp from '../../components/Loadercomp';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import csc from 'country-state-city';
import Select from "react-select";
import { useRef } from 'react';
import { useEffect } from 'react';

const Addcompany = () => {

  const nvg = useNavigate()
  const [cname,setcname] = useState()
  const [cgst,setcgst] = useState()
  const [cemail,setcemail] = useState()
  const [cmobile,setcmobile] = useState()
  const [caddress,setcaddress] = useState()
  const [country,setcountry] = useState()
  const [cstate,setcstate] = useState()
  const [ccity,setccity] = useState()
  const [idno2, setidno2] = useState(0);
  // console.log("uiiuiuiuiuiuiu",country ,cstate ,ccity)
  const [cpincode,setcpincode] = useState()
  const [ccompanylogo,setccompanylogo] = useState('')
  const [cstatus,setcstatus] = useState(true)
  const [compresponse,setcompresponse] = useState({})
  const [errorclass,seterrorclass] = useState(false)
  const [errorcls, seterrorcls] = useState({})
  const [loading, setloading] = useState(false);
  const [error, setError] = useState(null);
  const [pineror, setpineror] = useState('');
  const [kycbuilding_name, setkycbuilding_name] = useState('');
  const [kycdistrict, setkycdistrict] = useState('');
  const [kycstate, setkycstate] = useState('');
  const [kyccompanyname, setkyccompanyname] = useState('');
  const [kycregisterdate, setkycregisterdate] = useState('');
  const [kycpincode, setkycpincode] = useState('');
  const [stateerror, setstateerror] = useState('');
  const [cityerror, setcityerror] = useState('');
  const [documentsuperresuniqe, setdocumentsuperresuniqe] = useState('');
  const [documentsupermsguniqe, setdocumentsupermsguniqe] = useState('');
  const [gstresponsestatus, setgstresponsestatus] = useState(false);

  const gettokinval = gettoken()



  useEffect(()=>{
    if(gettokinval.staff == false){
      nvg('/dashboard')
    }
  })


  const handleSubmit = async () => {


    setloading(true)
    try {
      var data = {
        "document_type":"GSTIN",
        "id_number": cgst,
      }
  
      let url = `https://devbackend.kycfirst.io/api/search/`;
      const response = await axios.post(url, data);
      console.log("gstin ",response.data)
  
      if(response.data.kycStatus == "SUCCESS"){
  
            setdocumentsuperresuniqe(response.data.kycStatus)
            // setkycfullnametwo(response.data.kycResult.name)
            setkycbuilding_name(response.data.kycResult.primaryBusinessContact.address.buildingName);
            setkycdistrict(response.data.kycResult.primaryBusinessContact.address.district)
            setkycstate(response.data.kycResult.primaryBusinessContact.address.stateName)
            setkycpincode(response.data.kycResult.primaryBusinessContact.address.pincode)
            setkyccompanyname(response.data.kycResult.legalName)
            setkycregisterdate(response.data.kycResult.registrationDate)
            setgstresponsestatus(true)
            setidno2(12)
            setTimeout(function() {
              setidno2(0)
             }, 7000);
            setloading(false)
    
      }else{
        if(response.data.kycStatus == 'FAILURE'){
          setdocumentsuperresuniqe(response.data.kycStatus)
          setdocumentsupermsguniqe(response.data.error.message)
              setidno2(12)
              setTimeout(function() {
                setidno2(0)
               }, 7000);
              setloading(false)
      
         }else{
  
          setdocumentsuperresuniqe('FAILURE')
          setdocumentsupermsguniqe(response.data.message)
    setloading(false)

         }
      
         if(response.data.message == "Your IP address is not allowed"){
          setdocumentsuperresuniqe('FAILURE')
          setdocumentsupermsguniqe(response.data.message)
    setloading(false)

         }
          setidno2(12)
          setTimeout(function() {
            setidno2(0)
           }, 7000);
          setloading(false)
      }
  
    } catch (error) {
      console.log("first",error)
    setloading(false)

    }
   
  };



  // const handleSubmit = () => {
  //   if(country !== undefined || country !== ''){
  //     setError(null);

  //     axios.get(`https://api.zippopotam.us/${country.sortname}/${cpincode}`)
  //       .then((response) => {
  //         // console.log(response)
  //         setcstate(response.data.places[0].state)
  //         setccity(response.data.places[0]['place name'])
  //         setpineror('')
  //         setstateerror('')
  //         setcityerror('')
  //       })
  //       .catch((error) => {
  //         setError('Failed to fetch location data. Please check the pincode.');
  //         // console.log(error.response)
  //         setpineror('Please Check Your Country and Pincode')
  //       });
  //   }
  
  // };



  const countryfocus = () =>{
    if(country == undefined || country == ''){
      setpineror('Please Select Country First')
    }else{
      setpineror('')
    }
  }




  const countries = csc.getAllCountries();

const updatedCountries = countries.map((country) => ({
  label: country.name,
  value: country.id,
  ...country
}));

const updatedStates = (countryId) =>
  csc.getStatesOfCountry(countryId)
  .map((state) => ({ label: state.name, value: state.id, ...state }));
const updatedCities = (stateId) =>

  csc.getCitiesOfState(stateId)
  .map((city) => ({ label: city.name, value: city.id, ...city }));
  async function submitform(e){
    e.preventDefault();
    if(documentsuperresuniqe == "SUCCESS"){
    if(cstate == undefined || cstate == '' || ccity == undefined || ccity == ''){
      if(cstate == undefined || cstate == ''){
        setstateerror('State is Required')
      }else{
        setstateerror('')
      }
      if(ccity == undefined || ccity == ''){
        setcityerror('City is Required')
      }else{
        setcityerror('')
      }
    }else{
      setstateerror('')
      setcityerror('')
      const formdata = new FormData()
      formdata.append('company_name',cname)
      formdata.append('tax_id_no',cgst)
      formdata.append('emailID',cemail)
      formdata.append('mobile_no',cmobile)
      formdata.append('address',caddress)
      formdata.append('country',country.label)
      formdata.append('state',cstate.label)
      formdata.append('city',ccity.label)
      formdata.append('pincode',cpincode)
      formdata.append('logo',ccompanylogo)
      formdata.append('status',cstatus)
      
      setloading(true)
     try {
      const config = {
        headers: {
          Authorization: `Bearer ${gettokinval.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}api/company/`;
      const response = await axios.post(url, formdata, config);
        setloading(false)
        setcompresponse(response);
        seterrorclass(false)
  
  
        // setgetresponse(response);
  
     } catch (error) {
      seterrorclass(true)
      setloading(false)
      setcompresponse({statusone:1,message:'Something Went Wrong Try again'})
      seterrorcls(error.response.data)
     }
    }
  }else{
    setidno2(678)
    setTimeout(function() {
      setidno2(0)
     }, 7000);
  }
  }
  



  const formRef = useRef(null);
  const resetform = () =>{
    if (formRef.current) {
      formRef.current.reset(); // Reset the form using the reset() method
      setcname(null)
      setcgst(null)
      setcemail(null)
      setcmobile(null)
      setcaddress(null)
      updatedCities(null)
      updatedStates(null)
      setcpincode(null)
    }
  }


  return (
    <div style={{width:'100%'}}>
      {/* <Header /> */}
      <div className="dashboardcontent px-2">
      {/* <Breadcup name={'Company'} /> */}
      <div className="container-fuild px-2 desgin1 ">
      <div className="row bg-white ">
      <div className="col-lg-12 d-flex justify-content-between py-2" style={{ background: 'hsla(210, 85%, 32%, 0.2)', color: '#0C5398' }}>
              <p className="m-0 customfont" >Add Corporate</p>
              <div className="addnew d-block mb-2">
                <button className="btn text-white closebtn"><NavLink to='/corporatelist/0' style={{ textDecoration: 'none', color: 'white' }}> x Close</NavLink></button>
              </div>
            </div>
        </div>
      </div>
      <div className="container-fuild pb-4 pt-3 px-2 bg-white">
      {compresponse.status === 201 ? nvg('/corporatelist/1') : <div></div>}
       <div className={errorclass === true ? "alert alert-danger mt-3" : "d-none" } role="alert">Something Went Wrong 
       </div>
        <form ref={formRef} onSubmit={submitform}>
       <div className="row bg-white pb-4 round" style={{border:'1px solid #E0E0E0',margin:"10px 0px",borderRadius: '3px'}}>
        <div className="col-md-6 px-3" style={{paddingTop:"28px"}}>

          <div className="row">
            <div className="col-lg-3">
              <label htmlFor="" className="form-label">
              Company <span style={{color:'red'}}>*</span>
              </label>
            </div>
            <div className="col-lg-9">
              <input type="text" className="form-control" value={cname} placeholder='Company Name ' onChange={(e) => {setcname(e.target.value.replace(/[^a-zA-Z\ ]/gi, ""))}} required />
              {errorcls ? errorcls.company_name ? <span style={{color:'red'}}> {errorcls.company_name[0]} </span> : '' : ''}
            </div>
          </div>
        </div>
        <div className="col-md-6 px-3" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-3">
              <label htmlFor="" className="form-label customw">Tax Id <span style={{color:'red'}}>*</span> </label>
            </div>
            {/* <div className="col-lg-9">
              <input type="text" className="form-control" value={cgst} placeholder='GST Identification Number' onChange={(e) => {setcgst(e.target.value)}} required />
              {errorcls ? errorcls.tax_id_no ? <span style={{color:'red'}}> {errorcls.tax_id_no[0]} </span> : '' : ''}
            </div> */}


            <div className="col-lg-9">
<div className="input-group ">
  <input type="text" className="form-control" style={{border:"1px solid hsl(0, 0%, 80%)"}} placeholder="GST Identification Number" 
                  value={cgst}  onChange={(e) => {setcgst(e.target.value)}} required aria-label="Recipient's username" aria-describedby="button-addon2" />
  {cgst == undefined || cgst == "" ? "" : <button type="button" className="btn btn-outline-secondary" onClick={handleSubmit} style={{ textDecoration: 'none', color: 'white',backgroundColor:"#165a99" }} id="button-addon2">Verify</button> }
</div>
{errorcls ? errorcls.tax_id_no ? <span style={{color:'red'}}> {errorcls.tax_id_no[0]} </span> : '' : ''}
{/* {pineror !== '' ? <span style={{color:'red'}}> {pineror} </span> : ''} */}
</div>
          </div>
        </div>
        
        <div className="col-md-6 px-3" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-3">
              <label htmlFor="" className="form-label">Email <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-9">
              <input type="email" className="form-control" value={cemail} placeholder='Email Address' onChange={(e) => {setcemail(e.target.value)}} required />
              {errorcls ? errorcls.emailID ? <span style={{color:'red'}}> {errorcls.emailID[0]} </span> : '' : ''}
            </div>
          </div>
        </div>
        <div className="col-md-6 px-3" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-3">
              <label htmlFor="" className="form-label">Mobile No. <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-9">
            {/* <div className="input-group">
    <div className="input-group-prepend">
      <span className="input-group-text">+91</span>
    </div>
    <input type="tel" className="form-control" id="mobileNumber" maxLength={10} minLength={10} value={cmobile} name="mobileNumber" placeholder="Enter mobile number" onChange={(e) => {setcmobile(e.target.value.replace(/[^0-9]/gi, ""))}} required /> */}
      <PhoneInput
placeholder="Enter phone number"
className="form-control"
value={cmobile}
onChange={setcmobile}
required
/>
    {errorcls ? errorcls.mobile_no ? <span style={{color:'red'}}> {errorcls.mobile_no[0]} </span> : '' : ''}
  {/* </div> */}
            </div>
          </div>
        </div>
  
        <div className="col-md-6 px-3" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-3">
              <label htmlFor="" className="form-label">Country  <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-9">
            <Select
               
               placeholder="Select Country"
               options={updatedCountries}
               value={country}
               onChange={(e) => {
                 setcountry(e);
               }}
               required
             />
              {/* <input type="text" className="form-control" value={country} placeholder='Country ' onChange={(e) => {setcountry(e.target.value.replace(/[^a-zA-Z]/gi, ""))}} required  /> */}
              {errorcls ? errorcls.country ? <span style={{color:'red'}}> {errorcls.country[0]} </span> : '' : ''}
            </div>
          </div>
        </div>
        <div className="col-md-6 px-3" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-3">
              <label htmlFor="" className="form-label">Pincode <span style={{color:'red'}}>*</span></label>
            </div>

            {/* old pincode input */}



            <div className="col-lg-9">
              <input type="tel" className="form-control" maxLength={6}
                  minLength={6} value={cpincode} placeholder='Pincode' onChange={(e) => {setcpincode(e.target.value.replace(/[^0-9]/gi, ""))}} required />
                  {errorcls ? errorcls.pincode ? <span style={{color:'red'}}> {errorcls.pincode[0]} </span> : '' : ''}
            </div>
{/* <div className="col-lg-9">
<div className="input-group ">
  <input type="tel" className="form-control" style={{border:"1px solid hsl(0, 0%, 80%)"}} placeholder="Enter Pincode" maxLength={6} onFocus={countryfocus}
                  minLength={6} value={cpincode}  onChange={(e) => {setcpincode(e.target.value.replace(/[^0-9]/gi, ""))}} required aria-label="Recipient's username" aria-describedby="button-addon2" />
  <button type="button" className="btn btn-outline-secondary" onClick={handleSubmit} style={{ textDecoration: 'none', color: 'white',backgroundColor:"#165a99" }} id="button-addon2">Search</button>
</div>
{errorcls ? errorcls.pincode ? <span style={{color:'red'}}> {errorcls.pincode[0]} </span> : '' : ''}
{pineror !== '' ? <span style={{color:'red'}}> {pineror} </span> : ''}
</div> */}
          </div>
        </div>
    
        <div className="col-md-6 px-3" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-3">
              <label htmlFor="" className="form-label">State  <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-9">
              {/* <input type="text" className="form-control" value={cstate} placeholder='State ' readOnly required /> */}
              <Select
          id="state"
          name="state"
          options={updatedStates(country == null ? null : country.value)}
          placeholder="Select State"
          onChange={(e) => {
            setcstate(e);
          }}
          required
        />
              {errorcls ? errorcls.state ? <span style={{color:'red'}}> {errorcls.state[0]} </span> : '' : ''}
              {stateerror !== '' ? <span style={{color:'red'}}> {stateerror} </span> : '' }
            </div>
          </div>
        </div>
     
       
        <div className="col-md-6 px-3" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-3">
              <label htmlFor="" className="form-label">City <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-9">
              {/* <input type="text" value={ccity} className="form-control" placeholder='City' readOnly required /> */}
              <Select
                     placeholder="Select City"
          id="city"
          name="city"
          options={updatedCities(cstate == null ? null : cstate.value)}
          value={ccity}
          onChange={(e) => {
            setccity(e);
          }}
          required
        />
              {errorcls ? errorcls.city ? <span style={{color:'red'}}> {errorcls.city[0]} </span> : '' : ''}
              {cityerror !== '' ? <span style={{color:'red'}}> {cityerror} </span> : ''}

            </div>
          </div>
        </div>
        {loading == true ? <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"100%",padding : "132px 0px"}}>
        <div className='px-2'>{loading == true ?  <Loadercomp size={100}  /> : ""}</div>
        </div> : <div> </div>}
        <div className="col-md-6 px-3" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-3">
              <label htmlFor="" className="form-label">Address <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-9">
              <input type="text" className="form-control" value={caddress} placeholder='Address ' onChange={(e) => {setcaddress(e.target.value)}} required />
              {errorcls ? errorcls.address ? <span style={{color:'red'}}> {errorcls.address[0]} </span> : '' : ''}
            </div>
          </div>
        </div>
        <div className="col-md-6 px-3" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-3">
              <label htmlFor="" className="form-label">Company Logo <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-9">
              <input type="file" className="form-control" placeholder='Company Logo' onChange={(e) => {setccompanylogo(e.target.files[0])}} required />
              {errorcls ? errorcls.logo ? <span style={{color:'red'}}> {errorcls.logo[0]} </span> : '' : ''}

            </div>
          </div>
        </div>
        {/* <div className="col-md-6 px-3" style={{paddingTop:"28px"}}>
          <div className="row">
            <div className="col-lg-3">
              <label htmlFor="" className="form-label">Status <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-9">
              {cstatus == true ? <Switch  value={cstatus} name='status' onChange={(e) => {setcstatus(!cstatus)}} checked /> : <Switch  value={cstatus} name='status' onChange={(e) => {setcstatus(!cstatus)}} />}
            
            </div>
          </div>
        </div> */}
               {idno2 == 12 ? documentsuperresuniqe == "FAILURE" ? <div className="col-12 px-4 d-flex"> <div className="col-12 col-offset-2 alert alert-danger mt-3 ms-1" role="alert">
            <h5 style={{padding:'0px',margin:"0px"}}>
         {documentsupermsguniqe}
            </h5>
       </div></div> : '' : '' }
       {idno2 == 12 ? documentsuperresuniqe == "SUCCESS" ? <div className="col-12 px-4 d-flex"> <div className="col-12 col-offset-2 alert alert-success mt-3 ms-1" role="alert">
            <h5 style={{padding:'0px',margin:"0px"}}>
            Verification Successfully 
            </h5>
       </div></div> : '' : '' }
       {idno2 == 678 ? <div className="col-12 px-4 d-flex"> <div className="col-12 col-offset-2 alert alert-danger mt-3 ms-1" role="alert">
            <h5 style={{padding:'0px',margin:"0px"}}>
            Please Verify Your GST First 
            </h5>
       </div></div> : '' }
      
     <div className="col-12">
      <div className="row">
        <div className="col-12 py-5 px-3 d-flex justify-content-end" style={{gap:'4px'}}>
          {/* <div className='px-2'>{loading == true ?  <Loadercomp /> : ""}</div> */}
          <button type='reset' onClick={()=>resetform()}  className='btn4'>Cancel</button>
          <button type='submit' className='btn5'>Save</button>
        </div>
      </div>
     </div>
      </div>
      </form>

      <div className={gstresponsestatus == true ? "modal fade show" : "modal fade"} id="exampleModal5" tabindex="-1" style={{display:gstresponsestatus == true ? "block" : "none"}} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={()=>{setgstresponsestatus(false)}} aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>

 
               { kyccompanyname == "" || kyccompanyname == null ? "" : <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="llf2" style={{margin:"0px"}} className="form-label ">Company Name </label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="llf2" onChange={(e) => {handleImageChange20(e)}} /> */}
        <div style={{margin: "0px 0px",width:"90%",textAlign:"end" }} className='customcolor'> {kyccompanyname}  </div>
      
      </div>
    </div>
  </div>}
  {kycbuilding_name == "" || kycbuilding_name == null ? "" : <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="llf2" style={{margin:"0px"}} className="form-label ">Building Name  </label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="llf2" onChange={(e) => {handleImageChange20(e)}} /> */}
        <div style={{ margin: "0px 0px",width:"90%",textAlign:"end" }} className='customcolor'> {kycbuilding_name}  </div>
      
      </div>
    </div>
  </div>}


  {kycstate == "" || kycstate == null ? "" : <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="llf2" style={{margin:"0px"}} className="form-label ">State  </label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="llf2" onChange={(e) => {handleImageChange20(e)}} /> */}
        <div style={{ margin: "0px 0px",width:"90%",textAlign:"end" }} className='customcolor'> {kycstate}  </div>
      
      </div>
    </div>
  </div>}
  {kycdistrict == "" || kycdistrict == null ? "" : <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="llf2" style={{margin:"0px"}} className="form-label ">District  </label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="llf2" onChange={(e) => {handleImageChange20(e)}} /> */}
        <div style={{ margin: "0px 0px",width:"90%",textAlign:"end" }} className='customcolor'> {kycdistrict}  </div>
      
      </div>
    </div>
  </div>}

  {kycregisterdate == "" || kycregisterdate == null ? "" : <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="llf2" style={{margin:"0px"}} className="form-label ">Pincode </label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="llf2" onChange={(e) => {handleImageChange20(e)}} /> */}
        <div style={{ margin: "0px 0px",width:"50%",textAlign:"end" }} className='customcolor'> {kycpincode}   </div>
      
      </div>
    </div>
  </div>}
  {kycregisterdate == "" || kycregisterdate == null ? "" : <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="llf2" style={{margin:"0px"}} className="form-label ">Registion Date </label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="llf2" onChange={(e) => {handleImageChange20(e)}} /> */}
        <div style={{ margin: "0px 0px",width:"50%",textAlign:"end" }} className='customcolor'> {kycregisterdate}   </div>
      
      </div>
    </div>
  </div>}
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>
      </div>
      </div>
    </div>
  )
}

export default Addcompany