import React, { useEffect } from 'react'
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from 'react-pro-sidebar';
import { GiHamburgerMenu } from "react-icons/gi";
import img from "./assets/KYC Logo white-bg 2.jpg";
// import img from "./assets/logo.png"
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import img1 from './assets/dashboard.png';
import img2 from './assets/box 2.png';
import img3 from './assets/office-building 2.png';
import img4 from './assets/customer-service 2.png';
import img5 from './assets/customer 2.png';
import img6 from './assets/transfer-money 2.png';
import img7 from './assets/report 2.png';
import img8 from './assets/check-mark 2.png';
import img9 from './assets/logout 2.png';
import img10 from './assets/validation 2.png';
import img11 from './assets/location.png';
import img02 from './assets/Bar chart.png';
import img90 from './assets/subscriptio.png';
import img990 from './assets/agent wallet.png';
import { gettoken, removeToken, sohstore } from './Localstorage/Store';
import { useState } from 'react';
import axios from 'axios';
import Header from './components/Header';
import Balance from './context/Balance';
import { BsQuestionLg } from 'react-icons/bs';


const Sidebarmenu = ({ children }) => {
  const gettokinval = gettoken();
  const nvg = useNavigate()
  const logoutevt = async () =>{
    removeToken()
      nvg('/')
    const formdata = new FormData()
    formdata.append('test',"test")
     try {
      
      const config = {
        headers: {
          Authorization: `Bearer ${gettokinval.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}api/logout/`;
      const response = await axios.post(url, formdata, config);
      nvg('/')
  
     } catch (error) {
      // console.log({statusone:1,message:'Something Went Wrong Try again',error:error})
     }
  
  
  }
  const [openSubMenu, setOpenSubMenu] = useState(null);

  const handleSubMenuClick = (key) => {
    setOpenSubMenu(key === openSubMenu ? null : key);
  };
  useEffect(()=>{
sohstore(false)
  },[])
  const [hideimg, sethideimg] = useState(false)
  
 const [balno,setbalno] = useState(0)
  const { collapseSidebar } = useProSidebar();
  const hideorshow = () =>{
    sethideimg(!hideimg);
    sohstore(!hideimg)
  }
  const location = useLocation();
  const result = location.pathname.substring(0, location.pathname.lastIndexOf('/'));
  const desiredString = location.pathname.split('/').slice(0, 2).join('/');
  if (location.pathname === '/' || location.pathname === '/login' || location.pathname === '/register' || location.pathname === '/refund' || location.pathname === '/about' || location.pathname === '/contact' || location.pathname === '/privacypolicy' || location.pathname === '/termandcondition' || desiredString === '/forgetpassword' || location.pathname === '/resetpassword' ) {
    return <div style={{ background: location.pathname === '/resetpassword' ?'#ffff' :'#F3F6FA' }}>
      {children}
    </div>
  } else {
    return (
      <div style={{ display: "flex",width:"100%" }}>
              <Balance.Provider value={[balno,setbalno]}>
        <Sidebar className='sidebarcum' defaultCollapsed="close" >
          <div>
            <Menu className="nothover abc">

              <MenuItem className="nothover abc" style={{ borderBottom: '1px solid #D9D9D9' }} icon={<GiHamburgerMenu children='logobtn' fontSize={23} onClick={() => {collapseSidebar();hideorshow()}} color='#0C5398' />}> {hideimg == true ? <img src={img} alt="qwerty" style={{ width: "99%" }} /> : ''}</MenuItem>
            </Menu>
            <Menu>
            {gettokinval.license_key !== null || gettokinval.staff == true || gettokinval.superuser == false ? gettokinval.remaining_days == null ? <NavLink to='/dashboard' className={location.pathname === '/dashboard' ? 'nav active' : 'nav'}><MenuItem className="nothover" icon={<img src={img1} alt='qwert' /> }>  Dashboard </MenuItem></NavLink> : gettokinval.remaining_days > 0 ? <NavLink to='/dashboard' className={location.pathname === '/dashboard' ? 'nav active' : 'nav'}><MenuItem className="nothover" icon={<img src={img1} alt='qwert' /> }>  Dashboard </MenuItem></NavLink> : <NavLink to='/' type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1400" className='nav ' ><MenuItem  icon={<img src={img1} alt='qwerct' />}> Dashboard</MenuItem>
              </NavLink> : <NavLink to='/' type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1400" className='nav ' ><MenuItem  icon={<img src={img1} alt='qwert' /> }>  Dashboard</MenuItem>
              </NavLink> }
             { gettokinval.staff == true ? (<NavLink to='/subscriptionlist/0' className={result === '/editsubscription' || result === '/subscriptiondetail' || location.pathname === '/addsubscription' ? 'nav active' : 'nav'}><MenuItem className="nothover" icon={<img src={img90} alt='qwewrt' style={{width:'40px'}} />}> Subscription </MenuItem>
              </NavLink>) : '' } 
             { gettokinval.staff == true ? (<NavLink to='/agentwalletlist/0' className={result === '/agentwalletdetail' || result === '/editagentWallet' ? 'nav active' : 'nav'}><MenuItem className="nothover" icon={<img src={img990} alt='qwewrt' style={{width:'40px'}} />}> Agent Wallet </MenuItem>
              </NavLink>) : '' } 
             { gettokinval.staff == true ? (<NavLink to='/productlist/0' className={result === '/editproduct' || result === '/productdetail' || location.pathname === '/addproduct' ? 'nav active' : 'nav'}><MenuItem className="nothover" icon={<img src={img2} alt='qwewrt' />}> Product </MenuItem>
              </NavLink>) : '' } 
              {gettokinval.staff == true ? (<NavLink to='/corporatelist/0' className={location.pathname === '/addcorporate' || result === '/corporatedetail' || result === '/editcorporate' ? 'nav active' : 'nav'}><MenuItem className="nothover" icon={<img src={img3} alt='qwerct' />}> Corporate </MenuItem>
              </NavLink>) : ''}
              {gettokinval.staff == true ? (<NavLink to='/agencylist/0' className={location.pathname === '/addagency' || result === '/agencydetail' || result === '/editagency' ? 'nav active' : 'nav'}><MenuItem className="nothover" icon={<img src={img11} alt='qwerct' />}> Agency </MenuItem>
              </NavLink>) : ""}
              {gettokinval.license_key !== null || gettokinval.staff == true || gettokinval.superuser == false ? gettokinval.superuser == true ? gettokinval.remaining_days == null ? <NavLink to='/agentlist/0' className={location.pathname === '/addagent' || result === '/agentdetail' || result === '/editagent' ? 'nav active' : 'nav'}><MenuItem className="nothover" icon={<img src={img4} alt='qwdfgerct' />}> Agent</MenuItem>
              </NavLink> : gettokinval.remaining_days > 0 ? (<NavLink to='/agentlist/0' className={location.pathname === '/addagent' || result === '/agentdetail' || result === '/editagent' ? 'nav active' : 'nav'}><MenuItem className="nothover" icon={<img src={img4} alt='qwdfgerct' />}> Agent</MenuItem>
              </NavLink>) : <NavLink to='/' type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1400" className='nav ' ><MenuItem  icon={<img src={img4} alt='qwerct' />}> Agent</MenuItem>
              </NavLink> : "" : 
              <NavLink to='/' type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1400" className='nav ' ><MenuItem  icon={<img src={img4} alt='qwdfgerct' />}> Agent</MenuItem>
              </NavLink>
              // <button
              //      className=""
              //   type="button"
              //   data-bs-toggle="modal"
              //   data-bs-target="#exampleModal1400"
              // ><MenuItem className="nothover" icon={<img src={img4} alt='qwdfgerct' />}> Agent</MenuItem></button>
              }


{gettokinval.license_key !== null || gettokinval.staff == true || gettokinval.superuser == false ? gettokinval.remaining_days == null ? <NavLink to='/Customerlist/0' className={location.pathname === '/addcustomer' || result === '/customerdetail' || result === '/editcustomer' ? 'nav active' : 'nav'}><MenuItem className="nothover" icon={<img src={img5} alt='qwerct' />}> Customer </MenuItem>
              </NavLink> : gettokinval.remaining_days > 0 ? <NavLink to='/Customerlist/0' className={location.pathname === '/addcustomer' || result === '/customerdetail' || result === '/editcustomer' ? 'nav active' : 'nav'}><MenuItem className="nothover" icon={<img src={img5} alt='qwerct' />}> Customer </MenuItem>
              </NavLink> : <NavLink to='/' type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1400" className='nav ' ><MenuItem  icon={<img src={img5} alt='qwerct' />}> Customer</MenuItem>
              </NavLink> : <NavLink to='/' type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1400" className='nav ' ><MenuItem  icon={<img src={img5} alt='qwerct' />}> Customer</MenuItem>
              </NavLink> }

              {gettokinval.license_key !== null || gettokinval.staff == true || gettokinval.superuser == false ? gettokinval.remaining_days == null ? <NavLink to='/remittancelist/0' className={location.pathname === '/addremittance' || result === '/remittancedetail' || result === '/editremittance' ? 'nav active' : 'nav'}><MenuItem className="nothover" icon={<img src={img6} alt='qwerct' />}> Remittance </MenuItem>
              </NavLink> : gettokinval.remaining_days > 0 ? <NavLink to='/remittancelist/0' className={location.pathname === '/addremittance' || result === '/remittancedetail' || result === '/editremittance' ? 'nav active' : 'nav'}><MenuItem className="nothover" icon={<img src={img6} alt='qwerct' />}> Remittance </MenuItem>
              </NavLink> : <NavLink to='/' type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1400" className='nav ' ><MenuItem  icon={<img src={img6} alt='qwerct' />}> Remittance</MenuItem>
              </NavLink> : <NavLink to='/' type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1400" className='nav ' ><MenuItem  icon={<img src={img6} alt='qwerct' />}> Remittance</MenuItem>
              </NavLink> }
              {gettokinval.remaining_days == null ? gettokinval.license_key !== null || gettokinval.staff == true || gettokinval.superuser == false ? gettokinval.superuser == false ? gettokinval.Approver == true || gettokinval.Checker == true ? (  <SubMenu  title="Submenu 1"
          key="submenu1"
          open={openSubMenu === 'submenu1'}
          onClick={() => handleSubMenuClick('submenu1')} icon={<img src={img8} alt='qwerct' />} label="Checking">
                <NavLink to='/checkcustomerlist/0' className={location.pathname === '/checkcustomerlist' || result === '/checkeditcustomer' || result ==='/checkcustomerdetail' ? 'nav active' : 'nav'}><MenuItem style={{paddingLeft:hideimg == true ? "72px":"30px"}}> Customer</MenuItem></NavLink>
                <NavLink to='/checkagentlist/0' className={location.pathname === '/checkagentlist/0' || result === '/checkagentdetail' || result ==='/checkeditagent' ? 'nav active' : 'nav'}><MenuItem style={{paddingLeft:hideimg == true ? "72px":"30px"}}> Agent</MenuItem></NavLink>
                <NavLink to='/checkremittancelist/0' className={location.pathname === '/checkremittancelist/0' || result === '/checkeditremittance' || result ==='/checkremittancedetail' ? 'nav active' : 'nav'}><MenuItem style={{paddingLeft:hideimg == true ? "72px":"30px"}}>  Remittance</MenuItem></NavLink>
              </SubMenu>) : "" : <SubMenu  title="Submenu 1"
          key="submenu1"
          open={openSubMenu === 'submenu1'}
          onClick={() => handleSubMenuClick('submenu1')} icon={<img src={img8} alt='qwerct' />} label="Checking">
                <NavLink to='/checkcustomerlist/0' className={location.pathname === '/checkcustomerlist' || result === '/checkeditcustomer' || result ==='/checkcustomerdetail' ? 'nav active' : 'nav'}><MenuItem style={{paddingLeft:hideimg == true ? "72px":"30px"}}> Customer</MenuItem></NavLink>
                <NavLink to='/checkagentlist/0' className={location.pathname === '/checkagentlist/0' || result === '/checkagentdetail' || result ==='/checkeditagent' ? 'nav active' : 'nav'}><MenuItem style={{paddingLeft:hideimg == true ? "72px":"30px"}}> Agent</MenuItem></NavLink>
                <NavLink to='/checkremittancelist/0' className={location.pathname === '/checkremittancelist/0' || result === '/checkeditremittance' || result ==='/checkremittancedetail' ? 'nav active' : 'nav'}><MenuItem style={{paddingLeft:hideimg == true ? "72px":"30px"}}>  Remittance</MenuItem></NavLink>
              </SubMenu> : <NavLink to='/' type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1400" className='nav ' ><MenuItem  icon={<img src={img8} alt='qwerct' />}> Checking</MenuItem>
              </NavLink> : gettokinval.remaining_days > 0 ? gettokinval.license_key !== null || gettokinval.staff == true || gettokinval.superuser == false ? gettokinval.superuser == false ? gettokinval.Approver == true || gettokinval.Checker == true ? (  <SubMenu  title="Submenu 1"
          key="submenu1"
          open={openSubMenu === 'submenu1'}
          onClick={() => handleSubMenuClick('submenu1')} icon={<img src={img8} alt='qwerct' />} label="Checking">
                <NavLink to='/checkcustomerlist/0' className={location.pathname === '/checkcustomerlist' || result === '/checkeditcustomer' || result ==='/checkcustomerdetail' ? 'nav active' : 'nav'}><MenuItem style={{paddingLeft:hideimg == true ? "72px":"30px"}}> Customer</MenuItem></NavLink>
                <NavLink to='/checkagentlist/0' className={location.pathname === '/checkagentlist/0' || result === '/checkagentdetail' || result ==='/checkeditagent' ? 'nav active' : 'nav'}><MenuItem style={{paddingLeft:hideimg == true ? "72px":"30px"}}> Agent</MenuItem></NavLink>
                <NavLink to='/checkremittancelist/0' className={location.pathname === '/checkremittancelist/0' || result === '/checkeditremittance' || result ==='/checkremittancedetail' ? 'nav active' : 'nav'}><MenuItem style={{paddingLeft:hideimg == true ? "72px":"30px"}}>  Remittance</MenuItem></NavLink>
              </SubMenu>) : "" : <SubMenu  title="Submenu 1"
          key="submenu1"
          open={openSubMenu === 'submenu1'}
          onClick={() => handleSubMenuClick('submenu1')} icon={<img src={img8} alt='qwerct' />} label="Checking">
                <NavLink to='/checkcustomerlist/0' className={location.pathname === '/checkcustomerlist' || result === '/checkeditcustomer' || result ==='/checkcustomerdetail' ? 'nav active' : 'nav'}><MenuItem style={{paddingLeft:hideimg == true ? "72px":"30px"}}> Customer</MenuItem></NavLink>
                <NavLink to='/checkagentlist/0' className={location.pathname === '/checkagentlist/0' || result === '/checkagentdetail' || result ==='/checkeditagent' ? 'nav active' : 'nav'}><MenuItem style={{paddingLeft:hideimg == true ? "72px":"30px"}}> Agent</MenuItem></NavLink>
                <NavLink to='/checkremittancelist/0' className={location.pathname === '/checkremittancelist/0' || result === '/checkeditremittance' || result ==='/checkremittancedetail' ? 'nav active' : 'nav'}><MenuItem style={{paddingLeft:hideimg == true ? "72px":"30px"}}>  Remittance</MenuItem></NavLink>
              </SubMenu> : <NavLink to='/' type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1400" className='nav ' ><MenuItem  icon={<img src={img8} alt='qwerct' />}> Checking</MenuItem>
              </NavLink> : <NavLink to='/' type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1400" className='nav ' ><MenuItem  icon={<img src={img8} alt='qwerct' />}> Checking</MenuItem>
              </NavLink> }
              {gettokinval.remaining_days == null ? gettokinval.license_key !== null || gettokinval.staff == true || gettokinval.superuser == false ? gettokinval.superuser == false ? gettokinval.Approver == true ? (<SubMenu title="Submenu 2"
          key="submenu2"
          open={openSubMenu === 'submenu2'}
          onClick={() => handleSubMenuClick('submenu2')} icon={<img src={img10} alt='qwerct' />} label="Approval">
                <NavLink to='/approvalcustomerlist/0' className={location.pathname === '/approvalcustomerlist/0' || result === '/approvaleditcustomer' || result ==='/approvalcustomerdetail' ? 'nav active' : 'nav'}><MenuItem style={{paddingLeft:hideimg == true ? "72px":"30px"}}> Customer</MenuItem></NavLink>
                <NavLink to='/approvalagentlist/0' className={location.pathname === '/approvalagentlist/0' || result === '/approvaleditagent' || result ==='/approvalagentdetail' ? 'nav active' : 'nav'}><MenuItem style={{paddingLeft:hideimg == true ? "72px":"30px"}}> Agent</MenuItem></NavLink>
                <NavLink to='/approvalremittancelist/0' className={location.pathname === '/approvalremittancelist' || result === '/approvaleditremittance' || result ==='/approvalremittancedetail' ? 'nav active' : 'nav'}><MenuItem style={{paddingLeft:hideimg == true ? "72px":"30px"}}>  Remittance</MenuItem></NavLink>
              </SubMenu>) : '' : <SubMenu title="Submenu 2"
          key="submenu2"
          open={openSubMenu === 'submenu2'}
          onClick={() => handleSubMenuClick('submenu2')} icon={<img src={img10} alt='qwerct' />} label="Approval">
                <NavLink to='/approvalcustomerlist/0' className={location.pathname === '/approvalcustomerlist/0' || result === '/approvaleditcustomer' || result ==='/approvalcustomerdetail' ? 'nav active' : 'nav'}><MenuItem style={{paddingLeft:hideimg == true ? "72px":"30px"}}> Customer</MenuItem></NavLink>
                <NavLink to='/approvalagentlist/0' className={location.pathname === '/approvalagentlist/0' || result === '/approvaleditagent' || result ==='/approvalagentdetail' ? 'nav active' : 'nav'}><MenuItem style={{paddingLeft:hideimg == true ? "72px":"30px"}}> Agent</MenuItem></NavLink>
                <NavLink to='/approvalremittancelist/0' className={location.pathname === '/approvalremittancelist' || result === '/approvaleditremittance' || result ==='/approvalremittancedetail' ? 'nav active' : 'nav'}><MenuItem style={{paddingLeft:hideimg == true ? "72px":"30px"}}>  Remittance</MenuItem></NavLink>
              </SubMenu> : <NavLink to='/' type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1400" className='nav ' ><MenuItem  icon={<img src={img10} alt='qwerct' />}> Approval</MenuItem>
              </NavLink> : gettokinval.remaining_days > 0 ? gettokinval.license_key !== null || gettokinval.staff == true || gettokinval.superuser == false ? gettokinval.superuser == false ? gettokinval.Approver == true ? (<SubMenu title="Submenu 2"
          key="submenu2"
          open={openSubMenu === 'submenu2'}
          onClick={() => handleSubMenuClick('submenu2')} icon={<img src={img10} alt='qwerct' />} label="Approval">
                <NavLink to='/approvalcustomerlist/0' className={location.pathname === '/approvalcustomerlist/0' || result === '/approvaleditcustomer' || result ==='/approvalcustomerdetail' ? 'nav active' : 'nav'}><MenuItem style={{paddingLeft:hideimg == true ? "72px":"30px"}}> Customer</MenuItem></NavLink>
                <NavLink to='/approvalagentlist/0' className={location.pathname === '/approvalagentlist/0' || result === '/approvaleditagent' || result ==='/approvalagentdetail' ? 'nav active' : 'nav'}><MenuItem style={{paddingLeft:hideimg == true ? "72px":"30px"}}> Agent</MenuItem></NavLink>
                <NavLink to='/approvalremittancelist/0' className={location.pathname === '/approvalremittancelist' || result === '/approvaleditremittance' || result ==='/approvalremittancedetail' ? 'nav active' : 'nav'}><MenuItem style={{paddingLeft:hideimg == true ? "72px":"30px"}}>  Remittance</MenuItem></NavLink>
              </SubMenu>) : '' : <SubMenu title="Submenu 2"
          key="submenu2"
          open={openSubMenu === 'submenu2'}
          onClick={() => handleSubMenuClick('submenu2')} icon={<img src={img10} alt='qwerct' />} label="Approval">
                <NavLink to='/approvalcustomerlist/0' className={location.pathname === '/approvalcustomerlist/0' || result === '/approvaleditcustomer' || result ==='/approvalcustomerdetail' ? 'nav active' : 'nav'}><MenuItem style={{paddingLeft:hideimg == true ? "72px":"30px"}}> Customer</MenuItem></NavLink>
                <NavLink to='/approvalagentlist/0' className={location.pathname === '/approvalagentlist/0' || result === '/approvaleditagent' || result ==='/approvalagentdetail' ? 'nav active' : 'nav'}><MenuItem style={{paddingLeft:hideimg == true ? "72px":"30px"}}> Agent</MenuItem></NavLink>
                <NavLink to='/approvalremittancelist/0' className={location.pathname === '/approvalremittancelist' || result === '/approvaleditremittance' || result ==='/approvalremittancedetail' ? 'nav active' : 'nav'}><MenuItem style={{paddingLeft:hideimg == true ? "72px":"30px"}}>  Remittance</MenuItem></NavLink>
              </SubMenu> : <NavLink to='/' type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1400" className='nav ' ><MenuItem  icon={<img src={img10} alt='qwerct' />}> Approval</MenuItem>
              </NavLink> : <NavLink to='/' type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1400" className='nav ' ><MenuItem  icon={<img src={img10} alt='qwerct' />}> Approval</MenuItem>
              </NavLink> }
              {gettokinval.license_key !== null || gettokinval.staff == true || gettokinval.superuser == false ? gettokinval.remaining_days == null ? <NavLink to='/Logtracklist' className='nav'><MenuItem className="nothover" icon={<img src={img7} alt='qwerct' />}> Reports </MenuItem></NavLink> : gettokinval.remaining_days > 0 ? <NavLink to='/Logtracklist' className='nav'><MenuItem className="nothover" icon={<img src={img7} alt='qwerct' />}> Reports </MenuItem></NavLink> : <NavLink to='/' type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1400" className='nav ' ><MenuItem  icon={<img src={img7} alt='qwerct' />}> Reports</MenuItem>
              </NavLink> : <NavLink to='/' type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1400" className='nav ' ><MenuItem  icon={<img src={img7} alt='qwerct' />}> Reports</MenuItem>
              </NavLink> }         
              {gettokinval.license_key !== null || gettokinval.staff == true || gettokinval.superuser == false ? gettokinval.remaining_days == null ? <NavLink to='/logactivity' className='nav'><MenuItem className="nothover" icon={<img src={img02} alt='qwerct' />}> Login Activity </MenuItem></NavLink> : gettokinval.remaining_days > 0 ? <NavLink to='/logactivity' className='nav'><MenuItem className="nothover" icon={<img src={img02} alt='qwerct' />}> Login Activity </MenuItem></NavLink> : <NavLink to='/' type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1400" className='nav ' ><MenuItem  icon={<img src={img02} alt='qwerct' />}> Login Activity</MenuItem>
              </NavLink> : <NavLink to='/' type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1400" className='nav ' ><MenuItem  icon={<img src={img02} alt='qwerct' />}> Login Activity </MenuItem>
              </NavLink> }        
              <NavLink to='/' onClick={logoutevt} className='nav'><MenuItem className="nothover" icon={<img src={img9} alt='qwerct' />}> Log Out </MenuItem></NavLink>
              {/* <button
                   className="btn5withoutradius"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal1400"
              >Next</button> */}
            </Menu>
          </div>
        </Sidebar>
        <div style={{ width: '100%' }}>
          <Header />
          {children}
        </div>
        </Balance.Provider>
        <div
        className="modal fade"
        id="exampleModal1400"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
          <BsQuestionLg className='question-logo' />
            {/* <span className="question-logo" style={{display:"flex",justifyContent:"center",alignItems:'center'}} ></span> */}
            {/* <i class="fa fa-exclamation" aria-hidden="true"></i> */}
            <div className="modal-header mod-line">
            </div>
            <div className="modal-body">
              <div className="row gy-3 mt-2">
                <h1 className="ccedit-h">Subscription Error</h1>
                <p className="ccedit-p">
                  {gettokinval.license_key == null ? "You Have to Buy Subscription to Access This Tab" : gettokinval.remaining_days > 0 ? "You Have to Buy Subscription to Access This Tab" : "Your Subscription Plan has Expired. Please Renew Your Plan"}
                  
                </p>
              </div>
            </div>
            <div className="modal-footer mod-line m-auto">
            {/* <button type="button" className="btn closecancel" data-bs-dismiss="modal"
                aria-label="Close">Cancel</button> */}
 <NavLink to='/subscriptionplan'>
              <button
 
                type="button"
                className="btn closebtn text-white"
                data-bs-dismiss="modal"
                // aria-label="Close"
              >
                {gettokinval.license_key == null ? "Buy Subscription" : gettokinval.remaining_days > 0 ? "Buy Subscription" : "Renew Subscription" }
              
              </button>
                </NavLink>
            </div>
          </div>
        </div>
      </div>

      </div>
    )
  }

}

export default Sidebarmenu