// import React from 'react'
// import img2 from '../../assets/kyc.png';
// import img3 from "../../assets/KYC Logo white-bg 2.jpg";
// import { useState } from 'react';
// import { useEffect } from 'react';
// import axios from 'axios';
// import { NavLink, useNavigate } from 'react-router-dom';
// import Loadercomp from '../../components/Loadercomp';
// import PhoneInput from 'react-phone-number-input'

// const Register = () => {
//   const [errorcls, seterrorcls] = useState({});
//   const [emailfield,setemailfield] = useState()
//   const [passwordfield,setpasswordfield] = useState()
//   const [loginresponse,setloginresponse] = useState({})
//   const [loading, setloading] = useState(false);
//   const [showregisterresponse, setshowregisterresponse] = useState(false);
//   const [showregisterresponsetwo, setshowregisterresponsetwo] = useState(false);
//   const [idnumber, setidnumber] = useState(0);
//   const [phone ,setphone] = useState()


//   const nvg = useNavigate()
















//   const [fname ,setfname] = useState('')
//   const [email ,setemail] = useState('')
//   const [otp ,setotp] = useState('')
//   const [password ,setpassword] = useState('')
//   const [registerstatus ,setregisterstatus] = useState(false)
//   const [sendotpres ,setsendotpres] = useState(false)
//   const [otpnext ,setotpnext] = useState(false)
//   const [registerpro ,setregisterpro] = useState(false)
//   const [otpshowres ,setotpshowres] = useState(false)
//   const [invalidotp ,setinvalidotp] = useState(false)
//   const [verifyyourotp ,setverifyyourotp] = useState(false)
//   const [otp1 ,setotp1] = useState()
//   const [otp2 ,setotp2] = useState()









//   const navigatelogin = () => {
//     window.location.href = "/login";
//   };
// console.log("kfkfkfkf",phone)
// const abc = 1;
//   const submitfunction = async () =>{
//     // e.preventDefault();
//     console.log("check 3")
//     if(isemail == true && isphone == true){
//       // const formdata = new FormData();
//       // formdata.append("username",phone);
//       // formdata.append("first_name",fname);
//       // formdata.append("email",email);
//       // formdata.append("mobile_no",phone);
//       // formdata.append("password",password);
//   const jsondata = {
//     first_name:fname,
//     username:fname,
//     email:email,
//     mobile_no:phone,
//     password:password
//   }
//       try {
//         setloading(true)
//         let url = `${process.env.REACT_APP_API_URL}api/agent/`;
//         const response = await axios.post(url, jsondata);
//         console.log(response);
      
//         if(response.status == 201 || response.status == 200){
//           nvg('/login',{state:{id:1}})
//           setemail('')
//           setfname('')
//           setpassword('')
//           setphone('')
//           setloading(false)
//           setotp1('')
//           setotp2('')
//           // setregisterstatus(true)
//           seterrorcls({})
//           // setTimeout(function() {
//           //   setregisterstatus(false)
//           //  }, 5000); 
//         }
//       } catch (error) {
//        console.log(error)
//         setloading(false);
//         seterrorcls(error.response.data);
//         setsendotpstate(false)
//       }
//     }else{
//       console.log("error 5050")
//       setverifyyourotp(true)
//       setTimeout(() => {
//         setverifyyourotp(false)
//       }, 5000);
//     }
  
//   }






//   const verifyotp = async () =>{
//     if(otp !== ''){
//       const jsondata = {
//         OTP:otp,
//         email:email
//       }
//       const config = {
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       };
//       const formdata = new FormData();
//       formdata.append("email",email);
//       formdata.append("otp",otp);
//           try {
//             setloading(true)
//             let url = `${process.env.REACT_APP_API_URL}api/verify-otp/`;
//             const response = await axios.post(url,formdata);
//             console.log("ooooooooooooooooooooo",response);
//             if(response.status == 201 || response.status == 200){
//               setotpshowres(true)
//               setregisterpro(true)
//               setotpnext(false)
//               setloading(false)
//               setTimeout(function() {
//                 setotpshowres(false)
//                }, 5000); // 5000 milliseconds (5 seconds) delay
//             }
//           } catch (error) {
//             setloading(false)
//             setinvalidotp(true)
//            console.log(error.response.data.error)
//            setTimeout(function() {
//             setinvalidotp(false)
//            }, 5000); // 5000 milliseconds (5 seconds) delay
//           }
//     }
//   }

//   const [getemailerror,setgetemailerror] = useState(false)
//   const [getemailsuccess,setgetemailsuccess] = useState(false)
//   const [msgemailerror,setmsgemailerror] = useState('')
//   const [getphoneerror,setgetphoneerror] = useState(false)
//   const [getphonesuccess,setgetphonesuccess] = useState(false)
//   const [msgphoneerror,setmsgphoneerror] = useState('')
//   const [sendotpstate,setsendotpstate] = useState(false)
//   const [showeotpres,setshoweotpres] = useState(false)
//   const [isemail,setisemail] = useState(false)
//   const [isphone,setisphone] = useState(false)
//   // const [sendotpres,setsendotpres] = useState(false)




//   const sendotp = async (e) =>{
//     e.preventDefault();


//     if(isemail == true && isphone == true){
//       submitfunction()
//     }else{
//       if(sendotpstate == false){
//         if(email !== ''){
//           const jsondata = {
//             email:email,
//           }
//               try {
//                 setloading(true)
//                 let url = `${process.env.REACT_APP_API_URL}api/send-otp/`;
//                 const response = await axios.post(url, jsondata);
//                 if(response.status == 201 || response.status == 200){
          
//                   // setisemail(true)
      
      
//                   if(phone !== ''){
//                     const jsondata = {
//                       phone:phone,
//                     }
//                         try {
//                           let url = `${process.env.REACT_APP_API_URL}api/send-otp-phone/`;
//                           const response = await axios.post(url, jsondata);
//                           console.log("xxxxxxxxxxxxxxxxxxxxx",response);
//                           if(response.status == 201 || response.status == 200){
//                             // setsendotpres(true)
//                             // setotpnext(true)
//                             //  setisphone(true)
//                             setloading(false)
//                             setsendotpstate(true)
//                             setsendotpres(true)
//                             setTimeout(function() {
//                               setsendotpres(false)
//                              }, 7000);
//                           }
//                         } catch (error) {
//                           isphone(false)
//                          console.log(error)
                
//                         }
//                   }else{
//                     setgetemailerror(true)
//                     setTimeout(() => {
//                       setgetemailerror(false)
//                     }, 7000);
//                   }
      
      
//                 }
//               } catch (error) {
//                 isemail(false)
//                console.log(error)
      
//               }
//         }else{
      
//           setgetemailerror(true)
//           setTimeout(() => {
//             setgetemailerror(false)
//           }, 7000);
//         }
      
      
      
      
      
      
      
      
      
//       }else{
//         // check otps here
      
      
      
      
      
      
      
//         if(otp1 !== '' || otp1 !== undefined || otp2 !== '' || otp2 !== undefined){
//           // const jsondata = {
//           //   otp:otp1,
//           //   phone:phone
//           // }
//           if(otp1 !== ''){
//             const formdata = new FormData();
//             formdata.append("email",email);
//             formdata.append("otp",otp1);
//                 try {
//                   setloading(true)
//                   let url = `${process.env.REACT_APP_API_URL}api/verify-otp/`;
//                   const response = await axios.post(url,formdata);
//                   console.log("ooooooooooooooooooooo",response);
//                   if(response.status == 201 || response.status == 200){
//                     setisemail(true)
//                     setgetemailsuccess(true)
//                     // setshoweotpres(true)
//                     // setregisterpro(true)
//                     // setotpnext(false)
//                     console.log("check 2")
//                     if(isemail == true && isphone == true){
//                       submitfunction()
//                     }
//                     // setloading(false)
//                     // submitfunction()
//                     // setTimeout(function() {
//                     //   setshoweotpres(false)
//                     //  }, 7000);
//                     setgetemailerror(false)
//                   }else{
                  
//                   }
//                 } catch (error) {
//                   // setloading(false)
//                   setisemail(false)
//                     setgetemailsuccess(false)
//                   setgetemailerror(true)
//                   setmsgemailerror(error.response.data.error)
//                 //  setTimeout(function() {
//                 //   setgetemailerror(false)
//                 //  }, 7000); 
//                 }
//           }
      
      
//           if(otp2 !== ''){
//             const formdata = new FormData();
//             formdata.append("phone",phone);
//             formdata.append("otp",otp2);
//                 try {
//                   // setloading(true)
//                   let url = `${process.env.REACT_APP_API_URL}api/verify-otp-phone/`;
//                   const response = await axios.post(url,formdata);
//                   console.log("ooooooooooooooooooooo",response);
//                   if(response.status == 201 || response.status == 200){
//                     setisphone(true)
//                     setgetphonesuccess(true)
//                     // setshoweotpres(true)
//                     if(isemail == true && isphone == true){
//                       submitfunction()
//                     }
//                     setregisterpro(true)
//                     console.log("check 1")
//                     // setotpnext(false)
//                     setloading(false)
//                     setgetphoneerror(false)
//                     submitfunction()
//                     // setTimeout(function() {
//                     //   setshoweotpres(false)
//                     //  }, 7000); // 5000 milliseconds (5 seconds) delay
//                   }else{
                  
//                   }
//                 } catch (error) {
//                   setloading(false)
//                   setisphone(false)
//                   setgetphonesuccess(false)
//                   setgetphoneerror(true)
//                   setmsgphoneerror(error.response.data.error)
//                 //  setTimeout(function() {
//                 //   setgetemailerror(false)
//                 //  }, 7000); // 5000 milliseconds (5 seconds) delay
//                 }
//           }
      
      
//           if(isemail == true && isphone == true){
//             submitfunction()
//           }
      
      
      
//         }
      
//       }
//     }

    
//   }






//   const backhome = () => {
// nvg('/')
//   }



//   return (
//     <div className='container loginpage d-flex justify-content-between align-items-center'>
//     <div className="row">
//     <div className="col-lg-6 lp-left px-lg-1 px-md-1">
//         <div className="container px-lg-2 px-md-1">
// <div className="row mb-4">
//   <div className="col-lg-7 col-8 offset-lg-0">
//   <img src={img3} style={{mixBlendMode:"darken"}} onClick={backhome} className="img-fluid" alt="" />
  
//   </div>
// </div>
// <div className='row mb-4'>
//   <div className="col-lg-10 col-md-10 col-12 offset-lg-0 offset-0">
//   <p className='welcomeback'>Register </p>
//   {/* <h3 className='welcomeback2 mb-3'>Login to your account</h3> */}
//   </div>
// </div>
// <form onSubmit={sendotp} id="form" >
// <div className="row">
//   <div className="col-lg-10 col-md-10 col-12 offset-lg-0">
//   <div
//         className="col-12 px-2 d-flex justify-content-end" 
//       >
//         <div class="alert alert-success" role="alert"  style={{width:'100%',display:sendotpres == true ? 'block' : 'none'}}>
//         OTP will be sent to your number and Email for verification.
// </div>
//       </div>
//   </div>
//   <div className="col-lg-10 col-md-10 col-12 offset-lg-0">
//   <label htmlFor="" className="form-label">
//                       Name <span style={{ color: "red" }}>*</span>
//                     </label>
//   <input type="text" className='form-control customheight mb-2' 
//   // value={emailfield} onChange={(e) => {setemailfield(e.target.value)}} 
//   value={fname}
//   onChange={(e) => {
//     setfname(e.target.value);
//   }}
//   required  placeholder='Enter Your Name' />
//    {errorcls ? (
//                       errorcls.username ? (
//                         <span style={{ color: "red" }}>
//                           {" "}
//                           {errorcls.username[0] == '' ? '' : errorcls.username[0]}{" "}
//                         </span>
//                       ) : (
//                         ""
//                       )
//                     ) : (
//                       ""
//                     )}
//   </div>
//   <div className="col-lg-10 col-md-10 col-12 offset-lg-0">
//   <label htmlFor="" className="form-label">
//                       Email Address <span style={{ color: "red" }}>*</span>
//                     </label>
//   <input type="text" className='form-control customheight mb-2'
//   //  value={emailfield} onChange={(e) => {setemailfield(e.target.value)}}
//   value={email}
//   onChange={(e) => {
//     setemail(e.target.value);
//   }}
//     required  placeholder='Enter Your Email' />
//     {errorcls ? (
//                       errorcls.email ? (
//                         <span style={{ color: "red" }}>
//                           {" "}
//                           {errorcls.email[0] == '' ? '' : errorcls.email[0]}{" "}
//                         </span>
//                       ) : (
//                         ""
//                       )
//                     ) : (
//                       ""
//                     )}
//   </div>
//   <div className="col-lg-10 col-md-10 col-12 offset-lg-0">
//   {/* <label htmlFor="" className="form-label">
//                       Mobile No. <span style={{ color: "red" }}>*</span>
//                     </label>
//   <input type="text" className='form-control customheight mb-3' value={emailfield} onChange={(e) => {setemailfield(e.target.value)}} required  placeholder='Enter Your Mobile No' /> */}
//     <div className="row">
//                   <div className="col-12">
//                     <label htmlFor="" className="form-label">
//                       Phone. No <span style={{ color: "red" }}>*</span>
//                     </label>
//                   </div>
//                   <div className="col-12">
//                   <PhoneInput
// placeholder="Enter phone number"
// className="form-control customheight mb-2"
// value={phone}
// onChange={setphone}
// required
// />

// {errorcls ? (
//                       errorcls.mobile_no ? (
//                         <span style={{ color: "red" }}>
//                           {" "}
//                           {errorcls.mobile_no[0] == '' ? '' : errorcls.mobile_no[0]}{" "}
//                         </span>
//                       ) : (
//                         ""
//                       )
//                     ) : (
//                       ""
//                     )}
// {/* {errorcls ? (
//                       errorcls.mobile_no ? (
//                         <span style={{ color: "red" }}>
//                           {" "}
//                           {errorcls.mobile_no[0] == '' ? '' : errorcls.mobile_no[0]}{" "}
//                         </span>
//                       ) : (
//                         ""
//                       )
//                     ) : (
//                       ""
//                     )} */}
//                   </div>
//                 </div>
//   </div>
//   <div className="col-lg-10 col-md-10 col-12 offset-lg-0">
//   <label htmlFor="" className="form-label">
//                       Password <span style={{ color: "red" }}>*</span>
//                     </label>
//   <input type="password" className='form-control customheight mb-2'
//   //  value={passwordfield} onChange={(e) => {setpasswordfield(e.target.value)}} 
//   value={password}
//   onChange={(e) => {
//     setpassword(e.target.value);
//   }}
//    required placeholder='Password' />
//   </div>

//   {sendotpstate == true ? <div className="col-lg-10 col-md-10 col-12 offset-lg-0" >
            
                
//                     <label htmlFor="" className="form-label">
//                       Email OTP <span style={{ color: "red" }}>*</span>
//                     </label>
             
              
//                     <input
//                       type="text"
//                       className="form-control customheight mb-2"
//                       placeholder="Email OTP"
//                       style={{borderColor :getemailsuccess == false ? getemailerror == true ?'red':'' : 'green'}}
//                       value={otp1}
//                       onChange={(e) => {
//                         setotp1(e.target.value);
//                       }}
//                       required
//                     />
//                      {getemailerror == true ?  <span style={{ color: "red" }}>
//                           {msgemailerror}
//                         </span> : ''}
                       
                 
              
//               </div> : ''}

//               {sendotpstate == true ? <div className="col-lg-10 col-md-10 col-12 offset-lg-0" >
              
              
//                     <label htmlFor="" className="form-label">
//                       Mobile OTP <span style={{ color: "red" }}>*</span>
//                     </label>
            
            
//                     <input
//                       type="text"
//                       className="form-control customheight mb-2"
//                       placeholder="Mobile OTP"
//                       value={otp2}
//                       style={{borderColor :getphonesuccess == false ? getphoneerror == true ?'red':'' : 'green'}}
//                       onChange={(e) => {
//                         setotp2(e.target.value);
//                       }}
//                       required
//                     />


// {getphoneerror == true ?  <span style={{ color: "red" }}>
//                           {msgphoneerror}
//                         </span> : ''}
//                      {/* {errorcls ? (
//                       errorcls.username ? (
//                         <span style={{ color: "red" }}>
//                           {" "}
//                           {errorcls.username[0] == '' ? '' : errorcls.username[0]}{" "}
//                         </span>
//                       ) : (
//                         ""
//                       )
//                     ) : (
//                       ""
//                     )} */}
               
           
//               </div> : ''}
//   <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"91%",zIndex:"3"}}>
//         <div className='px-2'>{loading == true ?  <Loadercomp size={100} /> : ""}</div>
//         </div>
//   {/* <div className="col-lg-10 col-md-10 col-12 offset-lg-0 d-flex justify-content-between"> */}
//   {/* <div className="form-check ">
//   <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//   <label className="form-check-label " htmlFor="flexCheckDefault">
//   Remember me
//   </label>
// </div> */}
//   {/* <div><NavLink to="/resetpassword" style={{textDecoration:"none"}}><p className='customdesgi'>Forgot password ?</p></NavLink></div> */}
//   {/* </div> */}
// </div>
// <div className="row">

// {sendotpstate == true ? <div
//         className="col-lg-10 col-md-10 col-12 offset-lg-0"
        
//       >
        
//         <button type='submit' className="btn text-white mt-4 customcolor2 customheight"  style={{width:'100%',fontWeight:'bold'}}>Submit OTP</button>
//       </div> : ''}
//       {sendotpstate == false ? <div className="col-lg-10 col-md-10 col-12 offset-lg-0">
//   <button className='btn text-white mt-4 customcolor2 customheight'
//   //  onClick={sendotp} 
//   type='submit'
//    style={{width:'100%',fontWeight:'bold'}}>Register</button>
//   </div> : ''}
//   <div className="col-lg-10 col-md-10 col-12 offset-lg-0">
//   <button className=' mt-1 customheight' onClick={backhome} style={{width:'100%',fontWeight:'bold',color:'#0C5398',cursor:'pointer',border:'none',outline:'none'}}>Back to Home</button>
//   </div>
//   <div className="col-lg-10 col-md-10 col-12 offset-lg-0 ">
//     {loginresponse.statusone === 1 ? <div className="alert alert-danger" role="alert">
//            {loginresponse.message}
//        </div> : ''}
       
//   </div>
// </div>
// </form>
//       </div>
//         </div>
//         <div className=" col-6 lp-right isnone">
//         {showregisterresponse == true ? <div className="alert alert-success" style={{margin: "0px",position: 'relative',zIndex: 3,top:'11px',left: '-2px'}} role="alert">
//            Successfully Register
//        </div> : ''}
//        {/* {showregisterresponsetwo == true ? <div className="alert alert-success" style={{margin: "0px",position: 'relative',zIndex: 3,top:'11px',left: '-2px'}} role="alert">
//       Congratulations! You have Successfully Purchased the Subscription Please Login Again
//        </div> : ''} */}
       
//         <div className='overlapimg'>
//         <img src={img2} alt="mnbvc" style={{width:'100%'}} />
//         </div>
//       </div> 
      
//     </div>
//     </div>
//   )
// }

// export default Register





























import React from 'react'
import img2 from '../../assets/kyc.png';
import img3 from "../../assets/KYC Logo white-bg 2.jpg";
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { NavLink, useNavigate } from 'react-router-dom';
import Loadercomp from '../../components/Loadercomp';
import PhoneInput from 'react-phone-number-input'

const Register = () => {
  const [errorcls, seterrorcls] = useState({});
  const [emailfield,setemailfield] = useState()
  const [passwordfield,setpasswordfield] = useState()
  const [loginresponse,setloginresponse] = useState({})
  const [loading, setloading] = useState(false);
  const [showregisterresponse, setshowregisterresponse] = useState(false);
  const [showregisterresponsetwo, setshowregisterresponsetwo] = useState(false);
  const [idnumber, setidnumber] = useState(0);
  const [phone ,setphone] = useState()


  const nvg = useNavigate()
















  const [fname ,setfname] = useState('')
  const [lname ,setlname] = useState('')
  const [email ,setemail] = useState('')
  const [otp ,setotp] = useState('')
  const [password ,setpassword] = useState('')
  const [cpassword ,setcpassword] = useState('')
  const [registerstatus ,setregisterstatus] = useState(false)
  const [sendotpres ,setsendotpres] = useState(false)
  const [otpnext ,setotpnext] = useState(false)
  const [registerpro ,setregisterpro] = useState(false)
  const [otpshowres ,setotpshowres] = useState(false)
  const [invalidotp ,setinvalidotp] = useState(false)
  const [verifyyourotp ,setverifyyourotp] = useState(false)
  const [otp1 ,setotp1] = useState()
  const [otp2 ,setotp2] = useState()









  const navigatelogin = () => {
    window.location.href = "/login";
  };
// console.log("kfkfkfkf",phone)
const abc = 1;
  const submitfunction = async () =>{
    // e.preventDefault();
    // console.log("check 3")
    if(isemail == true && isphone == true){
      // const formdata = new FormData();
      // formdata.append("username",phone);
      // formdata.append("first_name",fname);
      // formdata.append("email",email);
      // formdata.append("mobile_no",phone);
      // formdata.append("password",password);
  const jsondata = {
    first_name:fname,
    last_name:lname,
    username:`${fname} ${lname}`,
    email:email,
    mobile_no:phone,
    password:password
  }
      try {
        setloading(true)
        let url = `${process.env.REACT_APP_API_URL}api/agent/`;
        const response = await axios.post(url, jsondata);
        // console.log(response);
      
        if(response.status == 201 || response.status == 200){
          nvg('/login',{state:{id:1}})
          setemail('')
          setfname('')
          setpassword('')
          setphone('')
          setloading(false)
          setotp1('')
          setotp2('')
          // setregisterstatus(true)
          seterrorcls({})
          // setTimeout(function() {
          //   setregisterstatus(false)
          //  }, 5000); 
        }
      } catch (error) {
      //  console.log(error)
        setloading(false);
        seterrorcls(error.response.data);
        setsendotpstate(false)
      }
    }else{
      // console.log("error 5050")
      setverifyyourotp(true)
      setTimeout(() => {
        setverifyyourotp(false)
      }, 5000);
    }
  
  }






  const verifyotp = async () =>{
    if(otp !== ''){
      const jsondata = {
        OTP:otp,
        email:email
      }
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const formdata = new FormData();
      formdata.append("email",email);
      formdata.append("otp",otp);
          try {
            setloading(true)
            let url = `${process.env.REACT_APP_API_URL}api/verify-otp/`;
            const response = await axios.post(url,formdata);
            // console.log("ooooooooooooooooooooo",response);
            if(response.status == 201 || response.status == 200){
              setotpshowres(true)
              setregisterpro(true)
              setotpnext(false)
              setloading(false)
              setTimeout(function() {
                setotpshowres(false)
               }, 5000); // 5000 milliseconds (5 seconds) delay
            }
          } catch (error) {
            setloading(false)
            setinvalidotp(true)
          //  console.log(error.response.data.error)
           setTimeout(function() {
            setinvalidotp(false)
           }, 5000); // 5000 milliseconds (5 seconds) delay
          }
    }
  }

  const [getemailerror,setgetemailerror] = useState(false)
  const [notmatcherror,setnotmatcherror] = useState(false)
  const [getemailsuccess,setgetemailsuccess] = useState(false)
  const [msgemailerror,setmsgemailerror] = useState('')
  const [getphoneerror,setgetphoneerror] = useState(false)
  const [getphonesuccess,setgetphonesuccess] = useState(false)
  const [msgphoneerror,setmsgphoneerror] = useState('')
  const [sendotpstate,setsendotpstate] = useState(false)
  const [showeotpres,setshoweotpres] = useState(false)
  const [isemail,setisemail] = useState(false)
  const [isphone,setisphone] = useState(false)
  // const [sendotpres,setsendotpres] = useState(false)




  const sendotp = async (e) =>{
    e.preventDefault();

if(password == cpassword){

  if(isemail == true && isphone == true){
    submitfunction()
  }else{
    if(sendotpstate == false){
      if(email !== ''){
        const jsondata = {
          email:email,
        }
            try {
              setloading(true)
              let url = `${process.env.REACT_APP_API_URL}api/send-otp/`;
              const response = await axios.post(url, jsondata);
              if(response.status == 201 || response.status == 200){
        
                // setisemail(true)
    
    
                if(phone !== ''){
                  const jsondata = {
                    phone:phone,
                  }
                      try {
                        let url = `${process.env.REACT_APP_API_URL}api/send-otp-phone/`;
                        const response = await axios.post(url, jsondata);
                        // console.log("xxxxxxxxxxxxxxxxxxxxx",response);
                        if(response.status == 201 || response.status == 200){
                          // setsendotpres(true)
                          // setotpnext(true)
                          //  setisphone(true)
                          setloading(false)
                          setsendotpstate(true)
                          setsendotpres(true)
                          setTimeout(function() {
                            setsendotpres(false)
                           }, 7000);
                        }
                      } catch (error) {
                        isphone(false)
                      //  console.log(error)
              
                      }
                }else{
                  setgetemailerror(true)
                  setTimeout(() => {
                    setgetemailerror(false)
                  }, 7000);
                }
    
    
              }
            } catch (error) {
              isemail(false)
            //  console.log(error)
    
            }
      }else{
    
        setgetemailerror(true)
        setTimeout(() => {
          setgetemailerror(false)
        }, 7000);
      }
    
    
    
    
    
    
    
    
    
    }else{
      // check otps here
    
    
    
    
    
    
    
      if(otp1 !== '' || otp1 !== undefined || otp2 !== '' || otp2 !== undefined){
        // const jsondata = {
        //   otp:otp1,
        //   phone:phone
        // }
        if(otp1 !== ''){
          const formdata = new FormData();
          formdata.append("email",email);
          formdata.append("otp",otp1);
              try {
                setloading(true)
                let url = `${process.env.REACT_APP_API_URL}api/verify-otp/`;
                const response = await axios.post(url,formdata);
                // console.log("ooooooooooooooooooooo",response);
                if(response.status == 201 || response.status == 200){
                  setisemail(true)
                  setgetemailsuccess(true)
                  // setshoweotpres(true)
                  // setregisterpro(true)
                  // setotpnext(false)
                  // console.log("check 2")
                  if(isemail == true && isphone == true){
                    submitfunction()
                  }
                  // setloading(false)
                  // submitfunction()
                  // setTimeout(function() {
                  //   setshoweotpres(false)
                  //  }, 7000);
                  setgetemailerror(false)
                }else{
                
                }
              } catch (error) {
                // setloading(false)
                setisemail(false)
                  setgetemailsuccess(false)
                setgetemailerror(true)
                setmsgemailerror(error.response.data.error)
              //  setTimeout(function() {
              //   setgetemailerror(false)
              //  }, 7000); 
              }
        }
    
    
        if(otp2 !== ''){
          const formdata = new FormData();
          formdata.append("phone",phone);
          formdata.append("otp",otp2);
              try {
                // setloading(true)
                let url = `${process.env.REACT_APP_API_URL}api/verify-otp-phone/`;
                const response = await axios.post(url,formdata);
                // console.log("ooooooooooooooooooooo",response);
                if(response.status == 201 || response.status == 200){
                  setisphone(true)
                  setgetphonesuccess(true)
                  // setshoweotpres(true)
                  if(isemail == true && isphone == true){
                    submitfunction()
                  }
                  setregisterpro(true)
                  // console.log("check 1")
                  // setotpnext(false)
                  setloading(false)
                  setgetphoneerror(false)
                  submitfunction()
                  // setTimeout(function() {
                  //   setshoweotpres(false)
                  //  }, 7000); // 5000 milliseconds (5 seconds) delay
                }else{
                
                }
              } catch (error) {
                setloading(false)
                setisphone(false)
                setgetphonesuccess(false)
                setgetphoneerror(true)
                setmsgphoneerror(error.response.data.error)
              //  setTimeout(function() {
              //   setgetemailerror(false)
              //  }, 7000); // 5000 milliseconds (5 seconds) delay
              }
        }
    
    
        if(isemail == true && isphone == true){
          submitfunction()
        }
    
    
    
      }
    
    }
  }
}else{
  setnotmatcherror(true)
  setTimeout(() => {
    setnotmatcherror(false)
  }, 5000);
}

    
  }






  const backhome = () => {
nvg('/')
  }



  return (
    <div className='container loginpage d-flex justify-content-center align-items-center'>
    <div className="row d-flex justify-content-center">
      {loading == true ? <div className="col-12 d-flex justify-content-center" style={{zIndex:"3"}}>
        <div className='px-2'>{loading == true ?  <Loadercomp size={100} /> : ""}</div>
        </div> : <div className="col-lg-9 bg-white px-lg-1 px-md-1"  style={{ boxShadow: '0 4px 15px 0 rgba(0, 0, 0, 0.15)'}}>
        <div className="container px-lg-2 px-md-1">
<div className="row mb-2" style={{display:'flex',justifyContent:'center'}}>
  <div className="col-lg-7 col-8 offset-lg-0 d-flex justify-content-center">
  <img src={img3} style={{mixBlendMode:"darken",width:'183px'}} onClick={backhome} className="img-fluid" alt="" />
  
  </div>
</div>
<div className='row mb-3 d-flex justify-content-center'>
  <div className="col-lg-12 col-md-12 col-12 offset-lg-0 offset-0 d-flex justify-content-center">
  <p className='welcomeback text-center' style={{fontSize:'32px',fontWeight:'700'}}>Register </p>
  </div>
</div>
<form onSubmit={sendotp} id="form" >
<div className="row">
  <div className="col-lg-12 col-md-12 col-12 offset-lg-0">
  <div
        className="col-12 px-2 d-flex justify-content-end" 
      >
        <div class="alert alert-success" role="alert"  style={{width:'100%',display:sendotpres == true ? 'block' : 'none'}}>
        OTP will be sent to your number and Email for verification.
</div>
        <div class="alert alert-danger" role="alert"  style={{width:'100%',display:notmatcherror == true ? 'block' : 'none'}}>
        Confirm Password didn't Match
</div>
      </div>
  </div>
  <div className="col-lg-6 col-md-6 col-12 px-4 offset-lg-0">
  <label htmlFor="" className="form-label">
                     First Name <span style={{ color: "red" }}>*</span>
                    </label>
  <input type="text" className='form-control px-3 mb-2' 
  value={fname}
  onChange={(e) => {
    setfname(e.target.value);
  }}
  required  placeholder='Enter Your First Name' />
   {errorcls ? (
                      errorcls.username ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.username[0] == '' ? '' : errorcls.username[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
  </div>
  <div className="col-lg-6 col-md-6 col-12 px-4 offset-lg-0">
  <label htmlFor="" className="form-label">
                      Last Name<span style={{ color: "red" }}>*</span>
                    </label>
  <input type="text" className='form-control px-3 mb-2' 
  value={lname}
  onChange={(e) => {
    setlname(e.target.value);
  }}
  required  placeholder='Enter Your Last Name' />
   {errorcls ? (
                      errorcls.username ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.username[0] == '' ? '' : errorcls.username[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
  </div>
  <div className="col-lg-6 col-md-6 col-12 px-4 offset-lg-0">
  <label htmlFor="" className="form-label">
                      Email Address <span style={{ color: "red" }}>*</span>
                    </label>
  <input type="text" className='form-control px-3 mb-2'
  value={email}
  onChange={(e) => {
    setemail(e.target.value);
  }}
    required  placeholder='Enter Your Email' />
    {errorcls ? (
                      errorcls.email ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.email[0] == '' ? '' : errorcls.email[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
  </div>
  {sendotpstate == true ? <div className="col-lg-6 col-md-6 col-12 px-4 offset-lg-0" >
            
                
            <label htmlFor="" className="form-label">
              Email OTP <span style={{ color: "red" }}>*</span>
            </label>
     
      
            <input
              type="text"
              className="form-control  mb-2"
              placeholder="Email OTP"
              style={{borderColor :getemailsuccess == false ? getemailerror == true ?'red':'' : 'green'}}
              value={otp1}
              onChange={(e) => {
                setotp1(e.target.value);
              }}
              required
            />
             {getemailerror == true ?  <span style={{ color: "red" }}>
                  {msgemailerror}
                </span> : ''}
               
         
      
      </div> : ''}
  <div className="col-lg-6 col-md-6 col-12 px-4 offset-lg-0">

    <div className="row">
                  <div className="col-12">
                    <label htmlFor="" className="form-label">
                      Phone. No <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-12">
                  <PhoneInput
placeholder="Enter phone number"
className="form-control  mb-2"
value={phone}
onChange={setphone}
required
/>

{errorcls ? (
                      errorcls.mobile_no ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.mobile_no[0] == '' ? '' : errorcls.mobile_no[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}

                  </div>
                </div>
  </div>

  {sendotpstate == true ? <div className="col-lg-6 col-md-6 col-12 px-4 offset-lg-0" >
              
              
              <label htmlFor="" className="form-label">
                Mobile OTP <span style={{ color: "red" }}>*</span>
              </label>
      
      
              <input
                type="text"
                className="form-control  mb-2"
                placeholder="Mobile OTP"
                value={otp2}
                style={{borderColor :getphonesuccess == false ? getphoneerror == true ?'red':'' : 'green'}}
                onChange={(e) => {
                  setotp2(e.target.value);
                }}
                required
              />


{getphoneerror == true ?  <span style={{ color: "red" }}>
                    {msgphoneerror}
                  </span> : ''}
         
     
        </div> : ''}
  <div className="col-lg-6 col-md-6 col-12 px-4 offset-lg-0">
  <label htmlFor="" className="form-label">
                      Password <span style={{ color: "red" }}>*</span>
                    </label>
  <input type="password" className='form-control px-3 mb-2'
  value={password}
  onChange={(e) => {
    setpassword(e.target.value);
  }}
   required placeholder='Password' />
  </div>
  <div className="col-lg-6 col-md-6 col-12 px-4 offset-lg-0">
  <label htmlFor="" className="form-label">
                    Confirm  Password <span style={{ color: "red" }}>*</span>
                    </label>
  <input type="password" className='form-control px-3 mb-2'
  value={cpassword}
  onChange={(e) => {
    setcpassword(e.target.value);
  }}
   required placeholder='Password' />
  </div>



{/*            
  <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"91%",zIndex:"3"}}>
        <div className='px-2'>{loading == true ?  <Loadercomp size={100} /> : ""}</div>
        </div> */}
</div>
<div className="row">

{sendotpstate == true ? <div
        className="col-lg-12 col-md-12 col-12 px-5 offset-lg-0"
        
      >
        <div className="row d-flex justify-content-center">
          <div className="col-xl-8 col-md-10">

        <button type='submit' className="btn text-white mt-4 customcolor2 "  style={{width:'100%',fontWeight:'bold'}}>Submit OTP</button>
        </div>
        </div>
        
      </div> : ''}
      {sendotpstate == false ? <div className="col-lg-12 col-md-12 col-12 px-5 offset-lg-0">
        <div className="row d-flex justify-content-center">
          <div className="col-xl-8 col-md-10">

        <button className='btn text-white mt-3 customcolor2 '
  //  onClick={sendotp} 
  type='submit'
   style={{width:'100%',fontWeight:'bold'}}>Register</button>
          </div>
        </div>

  </div> : ''}
  <div className="col-lg-12 col-md-12 col-12 px-5 offset-lg-0">
  <div className="row d-flex justify-content-center">
          <div className="col-xl-8 col-md-10">
  <button className=' btn my-2 ' onClick={backhome} style={{width:'100%',backgroundColor:'rgba(12, 83, 152, 0.2)',fontWeight:'bold',color:'#0C5398',cursor:'pointer'}}>Back to Home</button>
  </div>
  </div>
  </div>
  <div className="col-lg-12 col-md-10 col-12 px-5 offset-lg-0 ">
    {loginresponse.statusone === 1 ? <div className="alert alert-danger" role="alert">
           {loginresponse.message}
       </div> : ''}
       
  </div>
</div>
</form>
      </div>
        </div>}
    
    
        {/* <div className=" col-6 lp-right isnone">
        {showregisterresponse == true ? <div className="alert alert-success" style={{margin: "0px",position: 'relative',zIndex: 3,top:'11px',left: '-2px'}} role="alert">
           Successfully Register
       </div> : ''}
       
        <div className='overlapimg'>
        <img src={img2} alt="mnbvc" style={{width:'100%'}} />
        </div>
      </div>  */}
      
    </div>
    </div>
  )
}

export default Register