import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { BsFillEyeFill } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { gettoken } from "../../Localstorage/Store";
import $ from "jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-dt/js/dataTables.dataTables.min.js";
import 'datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css';
import 'datatables.net-buttons/js/dataTables.buttons.min.js';
import 'datatables.net-buttons/js/buttons.html5.min.js';
import 'datatables.net-buttons/js/buttons.print.min.js';
import 'datatables.net-buttons-bs5/js/buttons.bootstrap5.min.js';

const Productlist = () => {
  const [data, setData] = useState([]);
  const [idno, setidno] = useState(0);
  const [loading,setloading] = useState(true);
  const gettokinval = gettoken();
  const {id} = useParams()
  const nvg = useNavigate()
  let gettokenvalue = gettoken()
  useEffect(() => {
    if (gettokenvalue.superuser === false) {
      nvg('/dashboard');
    }else{
   
    }
  })
  useEffect(() => {
    setidno(id)
    function showAlert() {
      setTimeout(function() {
       setidno(0)
      }, 5000); // 5000 milliseconds (5 seconds) delay
    }
    showAlert()
    async function fetchData() {
      const config = {
        headers: {
          Authorization: `Bearer ${gettokinval.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}api/product/`;
      const response = await axios.get(url, config);
      setData(response.data);
      setloading(false)
      $(document).ready(function () {
        $("#myTable").DataTable().destroy();
        $("#myTable").DataTable({
          pageLength: 10,
          language: {
            searchPlaceholder: 'Search...',
            sSearch: '',
          },
          dom: 'Bfrtip', // Add the buttons to the DataTable
          buttons: [
          // 'csv', 'excel', 'print'
          ]
        });
      });
    }
    fetchData();
  }, []);
  useEffect(()=>{
    if(gettokinval.staff == false){
      nvg('/dashboard')
    }
  })
  return (
    <div className="minheight" style={{ width: "100%", minHeight: "100vh" }}>
      {/* <Header /> */}
      <div className="dashboardcontent px-2">
        <div className="container-fuild px-2">
          <div className="row bg-white py-3 rounded-top">
          {idno == 1 ? <div className="col-12 alert alert-success mt-3" role="alert">
            <h5 style={{padding:'0px',margin:"0px"}}>
          Successfully Added
            </h5>
       </div> : <div></div>}
       {idno == 2 ? <div className="col-12 alert alert-success mt-3" role="alert">
            <h5 style={{padding:'0px',margin:"0px"}}>
          Successfully Updated
            </h5>
       </div> : <div></div>}
            <div className="col-lg-3 d-flex justify-content-between">
              <p className="m-0 customfont">Product List</p>
              <div className="addnew d-lg-none d-block mb-2">
                <button className="btn text-white customcolor2">
                  <NavLink
                    to="/addremittance"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    {" "}
                    + Add New
                  </NavLink>
                </button>
              </div>
            </div>
            <div
              className="col-lg-9 d-flex justify-content-end align-items-center"
            >
              <div className="addnew d-lg-block d-none">
                <button className="btn text-white customcolor2">
                  <NavLink
                    to="/addproduct"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    {" "}
                    + Add New
                  </NavLink>
                </button>
              </div>
            </div>
          </div>
          <div className="row bg-white pb-4 rounded-bottom table-responsive">
            <table id={loading ? "MTAB" : "myTable"} className="table">
              <thead className="table-light">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">S.NO</th>
                  <th scope="col">Action</th>
                  <th scope="col">Logo</th>
                  <th scope="col">Product Name</th>
                  <th scope="col">Status</th>
                  <th scope="col">Created By</th>
                  <th scope="col">Created Date & Time</th>
                </tr>
              </thead>
              <tbody>
                {loading ? <tr><th style={{textAlign:"center"}} colSpan={8}>Loading...</th></tr> : data.map((item, index) => (
                  <tr key={item.id}>
                    <td>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckChecked"
                      />
                    </td>
                    <th scope="row">{index + 1}</th>
                    <td>
                      <NavLink to={`/productdetail/${item.id}`}>
                        <BsFillEyeFill
                          style={{ paddingRight: "5px" }}
                          fontSize={21}
                          color="#0C5398"
                        />
                      </NavLink>
                      <NavLink to={`/editproduct/${item.id}`}>
                        <BiEdit fontSize={16} color="#0C5398" />
                      </NavLink>
                    </td>
                    <td>
                      <img src={`${item.logo}`} width={30} alt="zxcvbnm" />
                    </td>
                    <td>{item.product_name}</td>
                    {/* <td>Product 1</td> */}
                    <td>
                      <button
                        className={
                          item.status === true
                            ? "btn btn-success custombtn12 custombtn122"
                            : "btn btn-danger custombtn12 custombtn121"
                        }
                      >
                        {item.status === true ? "Active" : "Inactive"}
                      </button>
                    </td>
                    <td>{item.created_by}</td>
                    <td>{item.created_at}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Productlist;
