import Header from '../../components/Header'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { useEffect,useState } from 'react';
import axios from 'axios';
import { gettoken } from '../../Localstorage/Store';
import { NavLink, useNavigate } from 'react-router-dom';
import Loadercomp from '../../components/Loadercomp';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import csc from 'country-state-city';
import Select from "react-select";

const Addlocation = () => {
    const nvg = useNavigate()
   const[lname,setLname] = useState('none')
   const[lbranch,setLbranch] = useState('')
    const[lemail,setLemail] = useState('')
    const[lmob,setLmob] = useState('')
    const[ladd,setLadd] = useState('')
    const[lcountry,setLcountry] = useState('')
    const[lat,setlat] = useState('')
    const[long,setlong] = useState('')
    const[lstate,setLstate] = useState('')
    const[lcity,setLcity] = useState('')
    const[lpincode,setLpincode] = useState('')
    const[lclass,setLclass] = useState('')
    const[specialcateerror,setspecialcateerror] = useState(false)
    const[specialcateerrortxt,setspecialcateerrortxt] = useState('')
    const[llocation,setLocation] = useState({})
    const [data, setData] = useState([]);
    const [errorcls, seterrorcls] = useState({});
    const [loading, setloading] = useState(false);
    const [strloading, setstrloading] = useState(true);
    const gettokenvalue = gettoken()


    useEffect(()=>{
        if(gettokenvalue.staff == false){
          nvg('/dashboard')
        }
      })





    const [error, setError] = useState(null);
    const [pineror, setpineror] = useState('');
    const [stateerror, setstateerror] = useState('');
    const [cityerror, setcityerror] = useState('');
  
  
  
  
  
  
  
    const handleSubmit = () => {
      if(lcountry !== undefined || lcountry !== ''){
        setError(null);
  
        axios.get(`https://api.zippopotam.us/${lcountry.sortname}/${lpincode}`)
          .then((response) => {
            // console.log(response)
            setLstate(response.data.places[0].state)
            setLcity(response.data.places[0]['place name'])
            setpineror('')
            setstateerror('')
            setcityerror('')
          })
          .catch((error) => {
            setError('Failed to fetch location data. Please check the pincode.');
            // console.log(error.response)
            setpineror('Please Check Your Country and Pincode')
          });
      }
    
    };
  
  
  
    const countryfocus = () =>{
      if(lcountry == undefined || lcountry == ''){
        setpineror('Please Select Country First')
      }else{
        setpineror('')
      }
    }

    const countries = csc.getAllCountries();

const updatedCountries = countries.map((country) => ({
  label: country.name,
  value: country.id,
  ...country
}));

const updatedStates = (countryId) =>
  csc.getStatesOfCountry(countryId)
  .map((state) => ({ label: state.name, value: state.id, ...state }));
const updatedCities = (stateId) =>

  csc.getCitiesOfState(stateId)
  .map((city) => ({ label: city.name, value: city.id, ...city }));

    useEffect(() => {
        async function fetchData() {
    
            const config = {
                headers: {
              Authorization: `Bearer ${gettokenvalue.access}`,
            },
        };
        let url = `${process.env.REACT_APP_API_URL}api/active_company/`;
          const response = await axios.get(url, config);
          setData(response.data);
          setstrloading(false)
        }
        fetchData();
    }, [setData]);
    
    
    async function savebtn(e){
        e.preventDefault();

        if(lstate == undefined || lstate == '' || lcity == undefined || lcity == ''){
            if(lstate == undefined || lstate == ''){
              setstateerror('State is Required')
            }else{
              setstateerror('')
            }
            if(lcity == undefined || lcity == ''){
              setcityerror('City is Required')
            }else{
              setcityerror('')
            }
          }else{
            setstateerror('')
            setcityerror('')

       
        if(lname === ''){
            setspecialcateerror(true)
            setspecialcateerrortxt('please select company')
        }else{
            setspecialcateerror(false)
            setspecialcateerrortxt('')
            setloading(true)
            const formdata = new FormData()
            // formdata.append('company',lname)
        formdata.append('branch_name',lbranch)
        formdata.append('emailID',lemail)
        // formdata.append('longitude',long)
        // formdata.append('latitude',lat)
        formdata.append('mobile_no',lmob)
        formdata.append('address',ladd)
        formdata.append('country',lcountry.label)
        formdata.append('state',lstate.label)
              formdata.append('city',lcity.label)
              formdata.append('pincode',lpincode)

             try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${gettokenvalue.access}`,
                    },
                };
              let url = `${process.env.REACT_APP_API_URL}api/location/`;
              const response = await axios.post(url, formdata, config);
              setLocation(response);
              setLname('')
              setLbranch('')
              setLemail('')
              setLmob('')
              setLcountry('')
              setLcity('')
              setLstate('')
              setLpincode('')
              setLadd('')
              setloading(false)
          
             } catch (error) {
              setLocation({statusone:1,message:'Something Went Wrong Try again ',erorrmas:error})
              seterrorcls(error.response.data)
              setspecialcateerror(true)
              setloading(false)
             }
        }
       
    }
          }

    return (
        <div style={{ width: '100%' }}>
            {/* <Header /> */}
            <div className="dashboardcontent px-2">
                {/* <Breadcup name={'Location'} /> */}
                <div className="container-fuild px-2 desgin1">
                    <div className="row bg-white ">
                        {/* <div className="col-12 py-3 customtext23" style={{ background: 'hsla(210, 85%, 32%, 0.2)', color: '#0C5398' }}>Add Location</div> */}
                        <div className="col-lg-12 d-flex justify-content-between py-2" style={{ background: 'hsla(210, 85%, 32%, 0.2)', color: '#0C5398' }}>
              <p className="m-0 customfont" >Add Agency </p>
              <div className="addnew d-block mb-2">
                <button className="btn text-white closebtn"><NavLink to='/agencylist/0' style={{ textDecoration: 'none', color: 'white' }}> x Close</NavLink></button>
              </div>
            </div>
                    </div>
                </div>
                {strloading == true ? <div className="container-fuild bg-white"> <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"100%"}}>
        <div className='px-2'> <Loadercomp size={100} /></div>
        </div></div> : <div className="container-fuild pb-4 pt-3 px-2 bg-white">
                    <form onSubmit={savebtn} >
                    <div className="row bg-white pb-4 round" style={{ border: '1px solid #E0E0E0', margin: "10px 0px", borderRadius: '3px',position:'relative' }}>
                        {/* <div className="col-md-6 px-3" style={{paddingTop:'29px'}}>
                            <div className="row">
                                <div className="col-lg-3">
                                    <label htmlFor="" className="form-label">
                                        Company <span style={{ color: 'red' }}>*</span>
                                    </label>
                                </div>
                                <div className="col-lg-9">
                                    <select
                  className="form-select"
                  aria-label="Default select example"
                  value={lname} placeholder='Company Name' onChange={(e) => { setLname(e.target.value) }}
                >
                    <option value='' selected>Select Company</option>
                    { data.map((item) => (   
                  <option key={item.company_id} value={item.company_id}>{item.company_name}</option>
                  ))}
                </select>  
                {specialcateerror === true ? <span style={{color:"red"}}>{specialcateerrortxt}</span>: ''}           
                                </div>
                            </div>
                        </div> */}
                        <div className="col-md-6 px-3" style={{paddingTop:'29px'}}>
                            <div className="row">
                                <div className="col-lg-3">
                                    <label htmlFor="" className="form-label customw">Branch Name <span style={{ color: 'red' }}>*</span> </label>
                                </div>
                                <div className="col-lg-9">
                                    <input type="text" className="form-control" value={lbranch} placeholder='Branch Name' onChange={(e) => { setLbranch(e.target.value) }} required/>
                                    {errorcls ? errorcls.branch_name ? <span style={{color:'red'}}> {errorcls.branch_name[0]} </span> : '' : ''}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 px-3" style={{paddingTop:'29px'}}>
                            <div className="row">
                                <div className="col-lg-3">
                                    <label htmlFor="" className="form-label">Email <span style={{ color: 'red' }}>*</span></label>
                                </div>
                                <div className="col-lg-9">
                                    <input type="email" className="form-control" value={lemail} placeholder='Email Address' onChange={(e) => { setLemail(e.target.value) }} required />
                                    {errorcls ? errorcls.emailID ? <span style={{color:'red'}}> {errorcls.emailID[0]} </span> : '' : ''}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 px-3" style={{paddingTop:'29px'}}>
                            <div className="row">
                                <div className="col-lg-3">
                                    <label htmlFor="" className="form-label">Mobile No. <span style={{ color: 'red' }}>*</span></label>
                                </div>
                                <div className="col-lg-9">
                                    {/* <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">+91</span>
                                        </div>
                                        <input type="tel" className="form-control" minLength={10} maxLength={10} value={lmob} id="mobileNumber" name="mobileNumber" placeholder="Enter mobile number" onChange={(e) => { setLmob(e.target.value.replace(/[^0-9\ ]/gi, "")) }} required />
                                    </div> */}
                                    <PhoneInput
placeholder="Enter phone number"
className="form-control"
value={lmob}
onChange={setLmob}
required
/>
                                        {errorcls ? errorcls.mobile_no ? <span style={{color:'red'}}> {errorcls.mobile_no[0]} </span> : '' : ''}
                                </div>
                            </div>
                        </div>





                        {/* <div className="col-md-6 px-3" style={{paddingTop:'29px'}}>
                            <div className="row">
                                <div className="col-lg-3">
                                    <label htmlFor="" className="form-label">Latitude <span style={{ color: 'red' }}>*</span></label>
                                </div>
                                <div className="col-lg-9">
                                    <input type="text" className="form-control" value={lat} placeholder='Latitude' onChange={(e) => { setlat(e.target.value) }} required />
                                    {errorcls ? errorcls.latitude ? <span style={{color:'red'}}> {errorcls.latitude[0]} </span> : '' : ''}
                                </div>
                            </div>
                        </div>


                        <div className="col-md-6 px-3" style={{paddingTop:'29px'}}>
                            <div className="row">
                                <div className="col-lg-3">
                                    <label htmlFor="" className="form-label">Longitude <span style={{ color: 'red' }}>*</span></label>
                                </div>
                                <div className="col-lg-9">
                                    <input type="text" className="form-control" value={long} placeholder='Longitude' onChange={(e) => { setlong(e.target.value) }} required />
                                    {errorcls ? errorcls.longitude ? <span style={{color:'red'}}> {errorcls.longitude[0]} </span> : '' : ''}
                                </div>
                            </div>
                        </div> */}















                   
                        <div className="col-md-6 px-3" style={{paddingTop:'29px'}}>
                            <div className="row">
                                <div className="col-lg-3">
                                    <label htmlFor="" className="form-label">Country  <span style={{ color: 'red' }}>*</span></label>
                                </div>
                                <div className="col-lg-9">
                                    {/* <input type="text" className="form-control" value={lcountry} placeholder='Country ' onChange={(e) => { setLcountry(e.target.value) }} required /> */}
                                    <Select
               
                      placeholder="Select Country"
                      options={updatedCountries}
                      value={lcountry}
                      onChange={(e) => {
                        setLcountry(e);
                      }}
                      required
                    />
                                    {errorcls ? errorcls.country ? <span style={{color:'red'}}> {errorcls.country[0]} </span> : '' : ''}
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 px-3" style={{paddingTop:'29px'}}>
                            <div className="row">
                                <div className="col-lg-3">
                                    <label htmlFor="" className="form-label">Pincode <span style={{ color: 'red' }}>*</span></label>
                                </div>
                                <div className="col-lg-9">
                                {/* <div class="input-group ">
  <input type="tel" class="form-control" placeholder="Enter Pincode" style={{border:"1px solid hsl(0, 0%, 80%)"}} maxLength={6} onFocus={countryfocus}
                  minLength={6} value={lpincode}  onChange={(e) => {setLpincode(e.target.value.replace(/[^0-9]/gi, ""))}} required aria-label="Recipient's username" aria-describedby="button-addon2" />
  <button type="button" class="btn btn-outline-secondary" onClick={handleSubmit} style={{ textDecoration: 'none', color: 'white',backgroundColor:"#165a99" }} id="button-addon2">Search</button>
</div>
{errorcls ? errorcls.pincode ? <span style={{color:'red'}}> {errorcls.pincode[0]} </span> : '' : ''}
{pineror !== '' ? <span style={{color:'red'}}> {pineror} </span> : ''} */}
                                    <input type="tel" className="form-control" minLength={6} maxLength={6} value={lpincode} placeholder='Pincode' onChange={(e) => { setLpincode(e.target.value.replace(/[^0-9\ ]/gi, "")) }} required />
                                    {errorcls ? errorcls.pincode ? <span style={{color:'red'}}> {errorcls.pincode[0]} </span> : '' : ''}
                                </div>
                            </div>
                        </div>
              
                        <div className="col-md-6 px-3" style={{paddingTop:'29px'}}>
                            <div className="row">
                                <div className="col-lg-3">
                                    <label htmlFor="" className="form-label">State  <span style={{ color: 'red' }}>*</span></label>
                                </div>
                                <div className="col-lg-9">
                                    {/* <input type="text" className="form-control" value={lstate} placeholder='State' readOnly required /> */}
                                    {/* <input type="text" className="form-control" value={lstate} placeholder='State 'onChange={(e) => { setLstate(e.target.value) }} required /> */}
                                    <Select
          id="state"
          name="state"
          options={updatedStates(lcountry == null ? null : lcountry.value)}
          placeholder="Select State"
          onChange={(e) => {
            setLstate(e);
          }}
          required
        />
                      {stateerror !== '' ? <span style={{color:'red'}}> {stateerror} </span> : '' }
                                    {errorcls ? errorcls.state ? <span style={{color:'red'}}> {errorcls.state[0]} </span> : '' : ''}
                                </div>
                            </div>
                        </div>
      
                        <div className="col-md-6 px-3" style={{paddingTop:'29px'}}>
                            <div className="row">
                                <div className="col-lg-3">
                                    <label htmlFor="" className="form-label">City <span style={{ color: 'red' }}>*</span></label>
                                </div>
                                <div className="col-lg-9">
                                    {/* <input type="text" className="form-control" value={lcity} placeholder='City' readOnly required /> */}
                                    {/* <input type="text" className="form-control" value={lcity} placeholder='City' onChange={(e) => { setLcity(e.target.value) }} required /> */}
                                    <Select
                     placeholder="Select City"
          id="city"
          name="city"
          options={updatedCities(lstate == null ? null : lstate.value)}
          value={lcity}
          onChange={(e) => {
            setLcity(e);
          }}
          required
        />              {cityerror !== '' ? <span style={{color:'red'}}> {cityerror} </span> : ''}
                                    {errorcls ? errorcls.city ? <span style={{color:'red'}}> {errorcls.city[0]} </span> : '' : ''}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 px-3" style={{paddingTop:'29px'}}>
                            <div className="row">
                                <div className="col-lg-3">
                                    <label htmlFor="" className="form-label">Address <span style={{ color: 'red' }}>*</span></label>
                                </div>
                                <div className="col-lg-9">
                                    <input type="text" className="form-control" value={ladd} placeholder='Address' onChange={(e) => { setLadd(e.target.value) }} required />
                                    {errorcls ? errorcls.Address ? <span style={{color:'red'}}> {errorcls.Address[0]} </span> : '' : ''}
                                </div>
                            </div>
                        </div>
                        {loading == true ? <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"100%",padding : "132px 0px"}}>
        <div className='px-2'>{loading == true ?  <Loadercomp size={100}  /> : ""}</div>
        </div> : <div> </div>}
                       
                     
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12 py-5 px-4 d-flex justify-content-end" style={{ gap: '4px' }}>
                                {/* <div className='px-2'>{loading == true ?  <Loadercomp /> : ""}</div> */}
                                    <button type='reset' className='btn4'>Cancel</button>
                                    <button type='submit' className='btn5'>Save</button>
                                </div>
                            </div>
                        </div>
                        {llocation.status === 201 ? nvg("/agencylist/1") : <div></div>}
                    </div>
                    </form>
                </div>}
                
            </div>
        </div>
    )
}

export default Addlocation