import React, { useRef, useState } from "react";
import img from "../assets/qwerty.png";
import { BsFillEnvelopeFill } from "react-icons/bs";
import img209 from "../assets/kyc wallet.png";
import {
  FaBell,
  FaCog,
  FaEuroSign,
  FaSignature,
  FaUserAlt,
} from "react-icons/fa";
import { IoIosWallet } from "react-icons/io";
import {
  getprivateurl,
  getsoh,
  gettoken,
  privateurl,
  removeToken,
} from "../Localstorage/Store";
import { FaDownload } from "react-icons/fa";
import { useEffect } from "react";
import checkicon from '../assets/Ellipse 28.png';

import { NavLink, useNavigate } from "react-router-dom";
import { AiOutlineDown } from "react-icons/ai";
import img2 from "../assets/KYC Logo white-bg 2.jpg";
import axios from "axios";
import Webcam from "react-webcam";
import { IoIosFingerPrint } from "react-icons/io";
import { BsFillCameraFill } from "react-icons/bs";
import { BsQuestionLg } from "react-icons/bs";
import { CaptureFinger, GetMFS100Info } from "../pages/mfs100";
import Webcamtwo from "react-webcam";
import TranslateComponent from "./TranslateComponent";
import Loadercomp from "./Loadercomp";
import Balance from "../context/Balance";
import { useContext } from "react";

const Header = () => {
  const nvg = useNavigate();
  const buttonRef = useRef(null);
  const buttonReftwo = useRef(null);
  const picking = useRef(null);
  const [name, setname] = useState();
  const [email, setemail] = useState();
  const [thumbimg2, setthumbimg2] = useState('');
  const [rifimg2, setrifimg2] = useState('');
  const [rmfimg2, setrmfimg2] = useState('');
  const [rrfimg2, setrrfimg2] = useState('');
  const [rlfimg2, setrlfimg2] = useState('');
  const [ltfimg2, setltfimg2] = useState('');
  const [lifimg2, setlifimg2] = useState('');
  const [lmfimg2, setlmfimg2] = useState('');
  const [lrfimg2, setlrfimg2] = useState('');
  const [llfimg2, setllfimg2] = useState('');
  const [rtferror ,setrtferror] = useState(false)
const [riferror ,setriferror] = useState(false)
const [rmferror ,setrmferror] = useState(false)
const [rrferror ,setrrferror] = useState(false)
const [rlferror ,setrlferror] = useState(false)
const [ltferror ,setltferror] = useState(false)
const [liferror ,setliferror] = useState(false)
const [lmferror ,setlmferror] = useState(false)
const [lrferror ,setlrferror] = useState(false)
const [llferror ,setllferror] = useState(false)
const [balno,setbalno] = useContext(Balance)
  const [hideorshowthree, sethideorshowthree] = useState(false);
  const [countdown, setcountdowm] = useState(5);
const countref = useRef(null)
const timeref = useRef(null)

  const [loginin,setloginin] = useState(true)

  const addtime = () =>{
    clearInterval(countref.current)
    clearTimeout(timeref.current)
    const expirt = Date.now() + 1800000

    localStorage.setItem("expiretime",expirt);
  }
  function checkforinactivity (){
    const expiretime = localStorage.getItem('expiretime');
    if(expiretime < Date.now()){
      // console.log(" logout")

      if (buttonReftwo.current) {
        buttonReftwo.current.click();
          setcountdowm(5)
        countref.current = setInterval(() => {
          setcountdowm(prev => prev - 1)
        }, 1000);
        timeref.current =  setTimeout(() => {
          clearInterval(countref.current)
          let gettokenvalue = gettoken();
          const logoutevt = async () => {
            removeToken();
            
            const formdata = new FormData();
            formdata.append("test", "test");
            try {
              const config = {
                headers: {
                  Authorization: `Bearer ${gettokenvalue.access}`,
                },
              };
              let url = `${process.env.REACT_APP_API_URL}api/logout/`;
              const response = await axios.post(url, formdata, config);
              nvg("/");
              window.location.reload()
            } catch (error) {
              // console.log({
              //   statusone: 1,
              //   message: "Something Went Wrong Try again",
              //   error: error,
              // });
            }
          };
          logoutevt();
        }, 5000);
      }
      setloginin(false)
    }
  }

  function updateExpireTime(){

    const expirt = Date.now() + 1800000

    localStorage.setItem("expiretime",expirt);
  }



  useEffect(()=>{
const interval = setInterval(() => {
  checkforinactivity();
}, 5000);

return () => clearInterval(interval);
  },[]);


  useEffect(()=>{
    const showbal = async() =>{

      const config = {
        headers: {
          Authorization: `Bearer ${gettokenvalue.access}`,
        },
      };

      let urltwo = `${process.env.REACT_APP_API_URL}api/wallet/`;

      const responsetwo = await axios.get(urltwo, config);
      // console.log(responsetwo)
      setbalno(responsetwo.data[0].balance)
    }
    showbal()
  },[])

useEffect(()=>{

updateExpireTime();

window.addEventListener("click", updateExpireTime);
window.addEventListener("keypress", updateExpireTime);
window.addEventListener("scroll", updateExpireTime);
window.addEventListener("mousemove", updateExpireTime);

return () =>{
  window.addEventListener("click", updateExpireTime);
window.addEventListener("keypress", updateExpireTime);
window.addEventListener("scroll", updateExpireTime);
window.addEventListener("mousemove", updateExpireTime);
}

},[]);





const handleImageChange11 = () => {
  setthumbimg2('')
  setrtferror(true)
  setTimeout(() => {
    const client = new CaptureFinger();
 setthumbimg2(client.data.BitmapData)
    setrtferror(false)
  }, 1000);
  

};
// const handleImageChange12 = (event) => {
//   const file = event.target.files[0];
//   setcr_index_finger(file);

//   if (file) {
//     const reader = new FileReader();

//     reader.addEventListener('load', () => {
//       setrifimg2(reader.result);
//     });

//     reader.readAsDataURL(file);
//   }
// };
const handleImageChange12 = () => {
  setriferror(true)
  setrifimg2('')
  setTimeout(() => {
  const client = new CaptureFinger();
  setrifimg2(client.data.BitmapData)

    setriferror(false)
}, 1000);
};
// const handleImageChange13 = (event) => {
//   const file = event.target.files[0];
//   setcr_middle_finger(file);

//   if (file) {
//     const reader = new FileReader();

//     reader.addEventListener('load', () => {
//       setrmfimg2(reader.result);
//     });

//     reader.readAsDataURL(file);
//   }
// };
const handleImageChange13 = () => {
  setrmfimg2('')
  setrmferror(true)
  setTimeout(() => {
  const client = new CaptureFinger();
  setrmfimg2(client.data.BitmapData)
  setrmferror(false)
}, 1000);
};
// const handleImageChange14 = (event) => {
//   const file = event.target.files[0];
//   setcr_ring_finger(file);

//   if (file) {
//     const reader = new FileReader();

//     reader.addEventListener('load', () => {
//       setrrfimg2(reader.result);

//     });

//     reader.readAsDataURL(file);
//   }
// };
const handleImageChange14 = () => {
  setrrfimg2('')
  setrrferror(true)
  setTimeout(() => {
  const client = new CaptureFinger();
  setrrfimg2(client.data.BitmapData)
  setrrferror(false)
}, 1000);
};
// const handleImageChange15 = (event) => {
//   const file = event.target.files[0];
//   setcr_little_finger(file);

//   if (file) {
//     const reader = new FileReader();

//     reader.addEventListener('load', () => {
//       setrlfimg2(reader.result);
//     });

//     reader.readAsDataURL(file);
//   }
// };
const handleImageChange15 = () => {
  setrlfimg2('')
  setrlferror(true)
  setTimeout(() => {
  const client = new CaptureFinger();
  setrlfimg2(client.data.BitmapData)
  setrlferror(false)
}, 1000);
};
// const handleImageChange16 = (event) => {
//   const file = event.target.files[0];
//   setcl_thumb(file);

//   if (file) {
//     const reader = new FileReader();

//     reader.addEventListener('load', () => {
//       setltfimg2(reader.result);

//     });

//     reader.readAsDataURL(file);
//   }
// };
const handleImageChange16 = () => {
  setltfimg2('')
  setltferror(true)
  setTimeout(() => {
  const client = new CaptureFinger();
  setltfimg2(client.data.BitmapData)
  setltferror(false)
}, 1000);
};
// const handleImageChange17 = (event) => {
//   const file = event.target.files[0];
//   setcl_index_finger(file);

//   if (file) {
//     const reader = new FileReader();

//     reader.addEventListener('load', () => {
//       setlifimg2(reader.result);
//     });

//     reader.readAsDataURL(file);
//   }
// };
const handleImageChange17 = () => {
  setlifimg2('')
  setliferror(true)
  setTimeout(() => {
  const client = new CaptureFinger();
  setlifimg2(client.data.BitmapData)
  setliferror(false)
}, 1000);
};
// const handleImageChange18 = (event) => {
//   const file = event.target.files[0];
//   setcl_middle_finger(file);

//   if (file) {
//     const reader = new FileReader();

//     reader.addEventListener('load', () => {
//       setlmfimg2(reader.result);
//     });

//     reader.readAsDataURL(file);
//   }
// };
const handleImageChange18 = () => {
  setlmfimg2('')
  setlmferror(true)
  setTimeout(() => {
  const client = new CaptureFinger();
  setlmfimg2(client.data.BitmapData)
  setlmferror(false)
}, 1000);
};
// const handleImageChange19 = (event) => {
//   const file = event.target.files[0];
//   setcl_ring_finger(file);

//   if (file) {
//     const reader = new FileReader();

//     reader.addEventListener('load', () => {
//       setlrfimg2(reader.result);
//     });

//     reader.readAsDataURL(file);
//   }
// };
const handleImageChange19 = () => {
  setlrfimg2('')
  setlrferror(true)
  setTimeout(() => {
  const client = new CaptureFinger();
  setlrfimg2(client.data.BitmapData)
  setlrferror(false)
}, 1000);
};
// const handleImageChange20 = (event) => {
//   const file = event.target.files[0];
//   setcl_little_finger(file);

//   if (file) {
//     const reader = new FileReader();

//     reader.addEventListener('load', () => {
//       setllfimg2(reader.result);
//     });

//     reader.readAsDataURL(file);
//   }
// };

const handleImageChange20 = () => {
  setllfimg2('')
  setllferror(true)
  setTimeout(() => {
  const client = new CaptureFinger();
  setllfimg2(client.data.BitmapData)
  setllferror(false)
}, 1000);
};






  let gettokenvalue = gettoken();
  const logoutevt = async () => {
    removeToken();
    nvg("/");
    const formdata = new FormData();
    formdata.append("test", "test");
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${gettokenvalue.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}api/logout/`;
      const response = await axios.post(url, formdata, config);
      nvg("/");
    } catch (error) {
      // console.log({
      //   statusone: 1,
      //   message: "Something Went Wrong Try again",
      //   error: error,
      // });
    }
  };
  const sshh = getsoh();
  useEffect(() => {
    let privateurl = getprivateurl();
    if (gettoken() == null) {
      nvg("/");
    } else {
      // setname(gettokenvalue.username);
      setname(`${gettokenvalue.first_name} ${gettokenvalue.last_name}`);
      setemail(gettokenvalue.email);
    }
  });
  const pickimg = useRef(null);
  const [displayphoto, setdisplayphoto] = useState("");
  useEffect(() => {
    async function checkdevice() {
      setTimeout(() => {
        setdisplayphoto(pickimg.current.getScreenshot());
      }, 3000);
    }
    checkdevice();
  }, []);
  const [biodevice, setbiodevice] = useState(2);
  // biometric status api call here 
  // useEffect(() => {

  // async function checkdevice() {
  //   setTimeout(() => {
  //     const client = new GetMFS100Info();
  //     if (
  //       client.data.ErrorCode == "0" ||
  //       client.data.ErrorDescription == '"Success"'
  //     ) {
  //       setbiodevice(0);
  //     } else {
  //       setbiodevice(1);
  //     }
  //   }, 3000);
  // }
  // checkdevice();
  // }, []);

  const [takeimg1, settakeimg1] = useState("");

  const captureimagethree = (num) => {
    settakeimg1(picking.current.getScreenshot());
    if (num == 3) {
      sethideorshowthree(!hideorshowthree);
    }

    // setdisplayphototwo(pickimgtwo.current.getScreenshot())
  };


  useEffect(() => {
    setTimeout(() => {
      if (displayphoto == "") {
        if (buttonRef.current) {
          buttonRef.current.click();
        }
      }
    }, 5000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (biodevice == 1) {
        if (buttonRef.current) {
          buttonRef.current.click();
        }
      }
    }, 5000);
  }, []);

  return (
    <div
      className="header"
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "0px",
      }}
    >
      <div style={{backgroundColor:"white",paddingLeft:'5px'}}>
        {sshh !== true ? (
          <img src={img2} alt="qwerty" style={{ height: "55px" }} />
        ) : (
          ""
        )}
      </div>
      <div style={{ display: "flex" }}>
        <div className="icongroup">
          <div style={{ width: "0px", height: "0px" }}>
            {" "}
            {displayphoto == "" || biodevice == 1 ? (
              <button
                ref={buttonRef}
                style={{
                  width: "0px",
                  height: "0px",
                  position: "absolute",
                  top: "-45435345345px",
                }}
                type="button"
                className="btn1"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal12"
              ></button>
            ) : (
              ""
            )}
          </div>
          <div className="icon white" style={{ width: "0px", height: "0px" }}>
            {displayphoto == "" ? <Webcam ref={pickimg} /> : ""}
          </div>
          <div style={{ width: "0px", height: "0px" }}>
            {" "}
              <button
                ref={buttonReftwo}
                style={{
                  width: "0px",
                  height: "0px",
                  position: "absolute",
                  top: "-45435345345px",
                }}
                type="button"
                className="btn1"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal144"
              ></button>
          </div>
          <div className="icon white">
            <TranslateComponent />
          </div>
          {/* <div className="icon white">
            {biodevice == 1 || biodevice == 2 ? (
              <div style={{position:"relative"}}>
              <button
              type="button"
              className="btn dropdown-toggle-split"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <IoIosFingerPrint color="red" size="22px" /></button> <ul className="dropdown-menu" style={{padding:"26px 1px"}}>
                
                <div className="col drop-msg d-flex align-items-center ms-3 col-12">
               
              
              <h6>Please install this file in your system</h6>
              
                </div>
              <div className="addnew d-flex justify-content-center" style={{gap:'4px'}}>
              <a
              href="https://download.radiumbox.com/download/MFS100ClientService.exe"
              download='https://download.radiumbox.com/download/MFS100ClientService.exe'
                className="btn closebtn text-white"

              >
                <FaDownload />
              </a>
              <a
              href="https://download.mantratecapp.com/StaticDownload/MantraRDService_1.0.8.exe"
              download='https://download.mantratecapp.com/StaticDownload/MantraRDService_1.0.8.exe'
                className="btn closebtn text-white"

              >
                <FaDownload />
              </a>
              <a
              href="https://download.mantratecapp.com/StaticDownload/MFS100Driver_9.2.0.0.exe"
              download='https://download.mantratecapp.com/StaticDownload/MFS100Driver_9.2.0.0.exe'
                className="btn closebtn text-white"

              >
                <FaDownload />
              </a>

              <a
              href="https://download.mantratecapp.com/StaticDownload/MFS100_ReleaseNotes_108_Windows.pdf"
              download='https://download.mantratecapp.com/StaticDownload/MFS100_ReleaseNotes_108_Windows.pdf'
                className="btn closebtn text-white"

              >
                <FaDownload />
              </a>

              </div>
              </ul></div>
              
            ) : (
              <button
                type='button'
                style={{
                  border: "none",
                  outline: "none",
                  width: "100%",
                  background: "#1066b9",
                }}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal24"
                >
              <IoIosFingerPrint color="#17b713" size="22px" /></button>
            )}
              
          </div> */}
          <div className="icon ">
            {displayphoto == "" ? (
              <BsFillCameraFill color="red" size="22px" />
            ) : (
              <button
                type="button"
                style={{
                  border: "none",
                  outline: "none",
                  width: "100%",
                  background: "#1066b9",
                }}
                data-bs-toggle="modal"
                data-bs-target="#exampleModaldocumenttwowwe12"
                onClick={() => {
                  sethideorshowthree(!hideorshowthree);
                }}
              >
                <BsFillCameraFill color="#17b713" size="22px" />
              </button>
            )}
          </div>
          {/* <div className="icon white"><img src={displayphoto} alt="zxcvbnm" width="100%" height="162px" /></div> */}

          <div className="icon white">
            <div className="btn-group">
              {/* <button type="button" className="btn btn-danger"></button> */}
              <button
                type="button"
                className="btn dropdown-toggle-split"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <FaBell color="white" size="19px" />
                {/* <BsFillEnvelopeFill color="white" size="19px" /> */}
                {/* <span className="visually-hidden">Toggle Dropdown</span> */}
              </button>
              <ul className="dropdown-menu">
                <div className="notification">
                  <h6>Emails</h6>
                  <h6 className="ms-auto">Clear All</h6>
                </div>{" "}
                <hr />
                <div className="col drop-msg d-flex align-items-start col-12">
                  <div className="col-3 d-flex justify-content-center align-items-center">
                    <img src={img} alt="" />
                  </div>
                  <div className="col-9">
                    <h6 className="noti-h">Rahul Gupta</h6>
                    <h6 className="noti">
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    </h6>
                  </div>
                </div>{" "}
                <hr />
                <div className="col drop-msg d-flex align-items-start col-12">
                  <div className="col-3 d-flex justify-content-center align-items-center">
                    <img src={img} alt="" />
                  </div>
                  <div className="col-9">
                    <h6 className="noti-h">Rahul Gupta</h6>
                    <h6 className="noti">
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    </h6>
                  </div>
                </div>
                <div className="">
                  {" "}
                  <hr />
                  <h6 className="text-center">View All</h6>
                </div>
              </ul>
            </div>
          </div>

          {/* <div className="icon white">
            <FaBell size="19px" />
          </div> */}
        </div>
        <div className="userlogo">
          <img src={img} alt="qwerty" />
        </div>
        <div className="sec-center">
          <input
            className="dropdown"
            type="checkbox"
            id="dropdown"
            name="dropdown"
          />
          <label className="for-dropdown" htmlFor="dropdown">
            {name}
            <AiOutlineDown />
          </label>
          <div className="section-dropdown">
            <div
              className="col sec-profile d-flex align-items-center justify-content-center mt-2 ms-1 col-12"
              style={{ flexDirection: "column" }}
            >
              <div className="col">
                <img src={img} alt="" />
              </div>
              <div className="col-12 name-drop">
                <p className="head-txt">{name}</p>
                <p className="head-para">{email}</p>
              </div>
            </div>
            <ul className="p-0">
              <li>
                <NavLink to="/profiledetail">
                  <FaUserAlt /> <span>User Profile</span>{" "}
                </NavLink>
              </li>
              <li>
                <NavLink to="/accountpassword">
                  <FaCog /> <span>Change Password</span>{" "}
                </NavLink>
              </li>
              {/* <li>
                <NavLink to="/logactivity">
                  <FaSignature /> <span>Login Activity</span>{" "}
                </NavLink>{" "}
                <hr />
              </li> */}
              <li onClick={logoutevt}>
                <NavLink to="#">
                  {" "}
                  <FaEuroSign /> <span>Log Out</span>{" "}
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="icon ">
    
              <button
                type="button"
                style={{
                  border: "none",
                  outline: "none",
                  width: "100%",
                  height:'100%',
                  background: "#1066b9",
                }}
              >
                {gettokenvalue.superuser == false && gettokenvalue.staff == false ?  <div className=" d-flex justify-content-end" style={{padding:"0px 8px"}}> 
                  <span>
                     {/* <IoIosWallet fontSize={28} color='white' /> */}
                     <img src={img209} alt="404" style={{width:'30px'}} />
                     </span>    
                <span style={{fontWeight:'400',color:'white',fontSize:'18px',display:'flex',justifyContent:'center',alignItems:'center'}}>  ₹ {parseInt(balno) === 0 ? 0.00 : balno}</span> 
                  </div> :
                   <NavLink to="walletrecharge/1">
                <div className=" d-flex justify-content-end" style={{padding:"0px 8px"}}> 
                  <span> 
                    {/* <IoIosWallet fontSize={28} color='white' /> */}
                    <img src={img209} alt="404" style={{width:'30px'}} />
                    </span>    
                <span style={{fontWeight:'400',color:'white',fontSize:'18px',display:'flex',justifyContent:'center',alignItems:'center'}}>  ₹ {parseInt(balno) === 0 ? 0.00 : balno}</span> 
                  </div>
                  </NavLink>}
              </button>
          
          </div>
      </div>
      {/* <div
        className="modal fade"
        id="exampleModal12"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
            <BsQuestionLg className="question-logo" />
            <div className="modal-header mod-line">
                       </div>
            <div className="modal-body">
              <div className="row gy-3 mt-2">
                <h1 className="ccedit-h">Device Error</h1>
                <p className="ccedit-p">
                  Your Device is not Connected Please Check Your Device
                </p>
              </div>
            </div>
            <div className="modal-footer mod-line m-auto">
              <button
                type="button"
                className="btn closebtn text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div> */}






      <div
        className="modal fade"
        id="exampleModal144"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
            <span className="question-logo" style={{display:"flex",justifyContent:"center",alignItems:'center'}} >{countdown}</span>
            {/* <i class="fa fa-exclamation" aria-hidden="true"></i> */}
            <div className="modal-header mod-line">
              {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
              {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
            </div>
            <div className="modal-body">
              <div className="row gy-3 mt-2">
                <h1 className="ccedit-h">Session Expired</h1>
                <p className="ccedit-p">
                  Your login Session Expired. Would you like to add 30 minutes more
                </p>
              </div>
            </div>
            <div className="modal-footer mod-line m-auto">
              <button type="button" className="btn closecancel" data-bs-dismiss="modal"
                aria-label="Close" onClick={logoutevt}>Cancel</button>
              <button
              onClick={addtime}
                type="button"
                className="btn closebtn text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>








      {/* <div className="modal fade" id="exampleModal24" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>

<div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="tereeeee" className="form-label ">Right Thumb  <span style={{ color: 'red' }}>*</span></label>
        <div onClick={(e) => {handleImageChange11()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: thumbimg2 != '' ? '' : rtferror == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {thumbimg2 != '' ? <img src={`data:image/png;base64,${thumbimg2}`} width="70px" height="60px" alt="asd" /> : rtferror == true ? <Loadercomp size={30} /> : ''}  </div>
        {thumbimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}

      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="ltf2" className="form-label ">Left Thumb  <span style={{ color: 'red' }}>*</span></label>
       <div onClick={(e) => {handleImageChange16()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: ltfimg2 != '' ? '' : ltferror == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {ltfimg2 != '' ? <img src={`data:image/png;base64,${ltfimg2}`} width="70px" height="60px" alt="asd" /> : ltferror == true ? <Loadercomp size={30} /> : ''}  </div>
        {ltfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rif2" className="form-label ">Right Index Finger <span style={{ color: 'red' }}>*</span></label>
       <div onClick={(e) => {handleImageChange12()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rifimg2 != '' ? '' : riferror == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rifimg2 != '' ? <img src={`data:image/png;base64,${rifimg2}`} width="70px" height="60px" alt="asd" /> : riferror == true ? <Loadercomp size={30} /> : ''}  </div>
        {rifimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="lif2" className="form-label ">Left Index Finger  <span style={{ color: 'red' }}>*</span></label>
       <div onClick={(e) => {handleImageChange17()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: lifimg2 != '' ? '' : liferror == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {lifimg2 != '' ? <img src={`data:image/png;base64,${lifimg2}`} width="70px" height="60px" alt="asd" /> : liferror == true ? <Loadercomp size={30} /> : ''}  </div>
        {lifimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rmf2" className="form-label ">Right Middle Finger<span style={{ color: 'red' }}>*</span></label>
        <div onClick={(e) => {handleImageChange13()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rmfimg2 != '' ? '' : rmferror == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rmfimg2 != '' ? <img src={`data:image/png;base64,${rmfimg2}`} width="70px" height="60px" alt="asd" /> : rmferror == true ? <Loadercomp size={30} /> : ''}  </div>
        {rmfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
      </div>
    </div>

  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="lmf2" className="form-label ">Left Middle Finger <span style={{ color: 'red' }}>*</span></label>
        <div onClick={(e) => {handleImageChange18()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: lmfimg2 != '' ? '' : lmferror == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {lmfimg2 != '' ? <img src={`data:image/png;base64,${lmfimg2}`} width="70px" height="60px" alt="asd" /> : lmferror == true ? <Loadercomp size={30} /> : ''}  </div>
        {lmfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rrf2" className="form-label ">Right Ring Finger  <span style={{ color: 'red' }}>*</span></label>
        <div onClick={(e) => {handleImageChange14()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rrfimg2 != '' ? '' : rrferror == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rrfimg2 != '' ? <img src={`data:image/png;base64,${rrfimg2}`} width="70px" height="60px" alt="asd" /> : rrferror == true ? <Loadercomp size={30} /> : ''}  </div>
        {rrfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
      </div>
    </div>

  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="lrf2" className="form-label ">Left Ring Finger  <span style={{ color: 'red' }}>*</span></label>
        <div onClick={(e) => {handleImageChange19()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: lrfimg2 != '' ? '' : lrferror == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {lrfimg2 != '' ? <img src={`data:image/png;base64,${lrfimg2}`} width="70px" height="60px" alt="asd" /> : lrferror == true ? <Loadercomp size={30} /> : ''}  </div>
        {lrfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rlf2" className="form-label ">Right Little Finger  <span style={{ color: 'red' }}>*</span></label>
        <div onClick={(e) => {handleImageChange15()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rlfimg2 != '' ? '' : rlferror == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rlfimg2 != '' ? <img src={`data:image/png;base64,${rlfimg2}`} width="70px" height="60px" alt="asd" /> : rlferror == true ? <Loadercomp size={30} /> : ''}  </div>
        {rlfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
      </div>
    </div>
  </div>

  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="llf2" className="form-label ">Left Little Finger  <span style={{ color: 'red' }}>*</span></label>
       <div onClick={(e) => {handleImageChange20()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: llfimg2 != '' ? '' : llferror == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {llfimg2 != '' ? <img src={`data:image/png;base64,${llfimg2}`} width="70px" height="60px" alt="asd" /> : llferror == true ? <Loadercomp size={30} /> : ''}  </div>
        {llfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
      </div>
    </div>
  </div>
</div>
                </div>
               
              </div>
            </div>
          </div> */}
      <div
        className="modal fade"
        id="exampleModaldocumenttwowwe12"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header mod-line">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={() => {
                  sethideorshowthree(!hideorshowthree);
                }}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div
                className="row bg-white round"
                style={{
                  border: "1px solid #E0E0E0",
                  margin: "0px 0px",
                  borderRadius: "3px",
                }}
              >
                {hideorshowthree == true ? (
                  <div className="col-12 d-flex py-3 justify-content-center">
                    <div className="px-2">
                      {hideorshowthree == true ? (
                        <Webcamtwo ref={picking} />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
      
          
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
