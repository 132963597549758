import React from 'react'
import { gettoken, removeToken } from '../../Localstorage/Store';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { NavLink, useNavigate } from 'react-router-dom';
import Balance from '../../context/Balance';
import { useContext } from 'react';
import { BsQuestionLg } from 'react-icons/bs';

const Renewplan = () => {
     const nvg = useNavigate()
    const gettokinval = gettoken();
    const [Data,setData] = useState({})
    const [balno,setbalno] = useContext(Balance)
    
    useEffect(() => {
      async function fetchData() {
          // setsrtloader(true)
          const config = {
              headers: {
            Authorization: `Bearer ${gettokinval.access}`,
          },
      };
      // https://backend.kycfirst.io/api/subscriptions/
      let url = `${process.env.REACT_APP_API_URL}api/subscriptions/`;
        const response = await axios.get(url, config);
        setData(response.data[0]);
        // setsrtloader(false)
      }
      fetchData();
      // console.log("dashboard bres",Data)
    },[]);
  
    // console.log("first new despendse",Data)


    const logoutevt = async () =>{
        removeToken()
        nvg('/login',{state:{id:7}})
              const formdata = new FormData()
              formdata.append('test',"test")
               try {
                
                const config = {
                  headers: {
                    Authorization: `Bearer ${gettokinval.access}`,
                  },
                };
                let url = `${process.env.REACT_APP_API_URL}api/logout/`;
                const response = await axios.post(url, formdata, config);
                nvg('/login',{state:{id:7}})
            
               } catch (error) {
                // console.log({statusone:1,message:'Something Went Wrong Try again',error:error})
               }
            
            
            }
        

    const submitform = async () =>{
  
        const formdata = new FormData()
        formdata.append('service_plan_id',Data.service_plan)
         try {
          
          const config = {
            headers: {
              Authorization: `Bearer ${gettokinval.access}`,
            },
          };
          let url = `${process.env.REACT_APP_API_URL}api/subscriptions/${Data.service_plan}/renew/`;
          const response = await axios.post(url, formdata, config);
console.log("super response", response)
if(response.data.remaining_days > 0){


    const formdatathree = new FormData();
    // if(idno == 1){
      formdatathree.append("amount", parseFloat(Data.total_amount));
      formdatathree.append("description", `Renew Subscription - ${gettokinval.id} - ${gettokinval.first_name} ${gettokinval.last_name}`);
    let url = `${process.env.REACT_APP_API_URL}api/wallet/make_purchase/`;
    const dedectresponse = await axios.post(
      url,
      formdatathree,
      config
    );
    console.log("make purchase", dedectresponse)
}
          logoutevt()
      
         } catch (error) {
          // console.log({statusone:1,message:'Something Went Wrong Try again',error:error})
         }
    }
  return (
    <div className="container-fuild pb-4 pt-3 px-2 bg-white">
          <form >
            
            <div
              className="row bg-white pb-4 px-3 round"
              style={{
                border: "1px solid #E0E0E0",
                margin: "10px 0px",
                borderRadius: "3px",
                position: "relative",
              }}
            >

             

              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label customw">
                      Email Address{" "}
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="email"
                      readOnly
value={gettokinval.email}
                      className="form-control"
                      // placeholder="Email"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label customw">
                      Mobile No.{" "}
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      readOnly
value={gettokinval.mobile}
                      className="form-control"
                      // placeholder="Mobile"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label customw">
                    Company  {" "}
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      readOnly
value={gettokinval.company_name}
                      className="form-control"
                      // placeholder="Email"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label customw">
                      Location{" "}
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      readOnly
value={gettokinval.branch_name}
                      className="form-control"
                      // placeholder="Email"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label customw">
                      Plan Name{" "}
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      readOnly
value={Data.plan_name}
                      className="form-control"
                      // placeholder="Email"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label customw">
                      Total Amount{" "}
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="number"
                      readOnly
value={Data.total_amount}
                      className="form-control"
                      // placeholder="Email"
                      required
                    />
                  </div>
                </div>
              </div>

    
<div className="col-8 pt-5">
  {/* {rechargesucc == true ? <div class="alert alert-success" role="alert">
 Recharge Successfully
</div> : ''}
  {rechargeerror == true ? <div class="alert alert-danger" role="alert">
 Something Went Wrong Try Again
</div> : ''} */}
</div>
              <div
                className="col-4 py-2 pt-5 px-2 d-flex justify-content-end"
                style={{ gap: "4px" }}
              >

{balno > parseFloat(Data.total_amount) ? <button type="button" onClick={()=>{submitform()}} className="btn5withoutradius">
                  Renew Subscription
                </button> : <button
                   className="btn5withoutradius"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal1450"
              >Renew Subscription</button> }
              
                {/* <button type="button" onClick={()=>{submitform()}} className="btn5withoutradius">
                  Renew Subscription
                </button> */}
      
              </div>
            </div>
            <div
        className="modal fade"
        id="exampleModal1450"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
    
          <BsQuestionLg className='question-logo' />
            {/* <span className="question-logo" style={{display:"flex",justifyContent:"center",alignItems:'center'}} ></span> */}
            {/* <i class="fa fa-exclamation" aria-hidden="true"></i> */}
            <div className="modal-header mod-line">
            </div>
            <div className="modal-body">
              <div className="row gy-3 mt-2">
                <h1 className="ccedit-h">Wallet Error</h1>
                <p className="ccedit-p">
                  Your Wallet Balance is Lower than plan . Please Recharge Your Wallet First
                </p>
              </div>
            </div>
            <div className="modal-footer mod-line m-auto">
            {/* <button type="button" className="btn closecancel" data-bs-dismiss="modal"
                aria-label="Close">Cancel</button> */}
 <NavLink to='/walletrecharge/1'>
              <button
 
                type="button"
                className="btn closebtn text-white"
                data-bs-dismiss="modal"
                // aria-label="Close"
              >
                Wallet Recharge
              </button>
                </NavLink>
            </div>
          </div>
        </div>
      </div>
          </form>
        </div>
  )
}

export default Renewplan