import Header from '../../components/Header'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { Switch } from '@mui/material';
import img1 from '../../assets/AXISBANK 2.png';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { gettoken } from '../../Localstorage/Store';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Loadercomp from '../../components/Loadercomp';

const Companydetail = () => {


  const nvg = useNavigate()
  const [cname,setcname] = useState()
  const [cgst,setcgst] = useState()
  const [cemail,setcemail] = useState()
  const [cmobile,setcmobile] = useState()
  const [caddress,setcaddress] = useState()
  const [country,setcountry] = useState()
  const [cstate,setcstate] = useState()
  const [ccity,setccity] = useState()
  const [cpincode,setcpincode] = useState()
  const [cstatus,setcstatus] = useState()
  const [logohelp, setlogohelp] = useState('')
  const [created_by, setcreated_by] = useState();
  const [created_at, setcreated_at] = useState();
  const [last_updated_by, setlast_updated_by] = useState();
  const [last_updated_at, setlast_updated_at] = useState();
  const [srtloader, setsrtloader] = useState(true);

  const gettokinval = gettoken()
  const {id} = useParams()
  useEffect(()=>{
    if(gettokinval.staff == false){
      nvg('/dashboard')
    }
  })
// fetch company details ----->start
useEffect(() => {
  async function fetchData() {

    const config = {
      headers: {
        Authorization: `Bearer ${gettokinval.access}`,
      },
    };
    let url = `${process.env.REACT_APP_API_URL}api/company/${id}/`;
    const response = await axios.get(url, config);
    // setdefaultv(response.data.company)
    setcname(response.data.company_name)
    setcgst(response.data.tax_id_no)
    setcemail(response.data.emailID)
    setcmobile(response.data.mobile_no.substring(3))
    setcaddress(response.data.address)
    setcountry(response.data.country)
    setcstate(response.data.state)
    setccity(response.data.city)
    setcpincode(response.data.pincode)
      setcstatus(response.data.status)
      setlogohelp(response.data.logo)
      setcreated_by(response.data.created_by)
      setcreated_at(response.data.created_at)
      setlast_updated_by(response.data.last_updated_by)
      setlast_updated_at(response.data.last_updated_at)
      setsrtloader(false)
  }
  fetchData();
}, []);
  return (
    <div style={{width:'100%'}}>
      {/* <Header /> */}
      <div className="dashboardcontent px-2">
      {/* <Breadcup name={'Company'} /> */}
      <div className="container-fuild px-2 desgin1">
      <div className="row bg-white ">
            {/* <div className="col-12 py-3 customtext23" style={{background: 'hsla(210, 85%, 32%, 0.2)',color:'#0C5398'}}>Company Detail</div> */}
            <div className="col-lg-12 d-flex justify-content-between py-2" style={{ background: 'hsla(210, 85%, 32%, 0.2)', color: '#0C5398' }}>
              <p className="m-0 customfont" > Corporate Detail</p>
              <div className="addnew d-block mb-2">
                <button className="btn text-white closebtn"><NavLink to='/corporatelist/0' style={{ textDecoration: 'none', color: 'white' }}> x Close</NavLink></button>
              </div>
            </div>
        </div>
      </div>
      {srtloader == true ? <div className="container-fuild bg-white"> <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"70%"}}>
        <div className='px-2'> <Loadercomp size={100} /></div>
        </div></div> :
      <div className="container-fuild pb-4 pt-3 px-2 bg-white">
       <div className="row bg-white pb-4 round" style={{border:'1px solid #E0E0E0',margin:"10px 0px",borderRadius: '3px'}}>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">
              Company 
              </label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
              <p className='customcolor ps-4'>{cname}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label customw">Tax Id</label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{cgst}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Email </label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{cemail}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Mobile No. </label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{cmobile}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Address </label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{caddress}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Country  </label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{country}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">State  </label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{cstate}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">City </label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{ccity}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Pincode </label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{cpincode}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Company Logo </label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end ps-4">
            <img src={logohelp} alt="qwert" width={40} />
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3" >
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Created by</label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{created_by}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3" >
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Created Date & Time</label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{created_at}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3" >
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Last Modified by </label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{last_updated_by ? last_updated_by : "NOT MODIFIED"}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3" >
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Last Modified Date & Time </label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{last_updated_at ? last_updated_at : "NOT MODIFIED"}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Status </label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>

           {cstatus == true ? "Active" : "Inactive"}
              </p>
            </div>
          </div>
        </div>
      </div>
      </div>}
      </div>
    </div>
  )
}

export default Companydetail