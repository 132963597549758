import React, { useEffect, useRef, useState } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import img3 from "../../assets/KYC Logo white-bg 2.jpg";
import { FaShoppingCart } from "react-icons/fa";
import { ImLocation2 } from "react-icons/im";
import {
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import { NavLink, useNavigate } from "react-router-dom";

const Refund = () => {
  const [topto, settopto] = useState(0);
  const [bottomto, setbottomto] = useState(0);
  const [IsFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const divElement = document.getElementById("myDiv"); // आपके डिव का आईडी यहां उपयोग करें
    const end = document.getElementById("endsidebar"); // आपके डिव का आईडी यहां उपयोग करें
    if (divElement) {
      const rect = divElement.getBoundingClientRect();
      const distanceFromTop = rect.top;
      settopto(distanceFromTop);
    }
    if (end) {
      const rect2 = end.getBoundingClientRect();
      const distanceFromTop2 = rect2.top;
      setbottomto(distanceFromTop2);
    }
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;

    if (scrollPosition > topto && scrollPosition < bottomto) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  window.addEventListener("scroll", handleScroll);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);
  const section5 = useRef(null);
  const section6 = useRef(null);
  const section7 = useRef(null);
  const section8 = useRef(null);
  const section9 = useRef(null);
  const section10 = useRef(null);
  const section11 = useRef(null);
  const section12 = useRef(null);
  const redirecthead = (num) => {
    section1.current?.scrollIntoView({ behavior: "smooth" });
  };

  const redirecthead2 = (num) => {
    section2.current?.scrollIntoView({ behavior: "smooth" });
  };
  const redirecthead3 = (num) => {
    section3.current?.scrollIntoView({ behavior: "smooth" });
  };
  const redirecthead4 = (num) => {
    section4.current?.scrollIntoView({ behavior: "smooth" });
  };
  const redirecthead5 = (num) => {
    section5.current?.scrollIntoView({ behavior: "smooth" });
  };
  const redirecthead6 = (num) => {
    section6.current?.scrollIntoView({ behavior: "smooth" });
  };
  const redirecthead7 = (num) => {
    section7.current?.scrollIntoView({ behavior: "smooth" });
  };
  const redirecthead8 = (num) => {
    section8.current?.scrollIntoView({ behavior: "smooth" });
  };
  const redirecthead9 = (num) => {
    section9.current?.scrollIntoView({ behavior: "smooth" });
  };
  const redirecthead10 = (num) => {
    section10.current?.scrollIntoView({ behavior: "smooth" });
  };
  const redirecthead11 = (num) => {
    section11.current?.scrollIntoView({ behavior: "smooth" });
  };
  const redirecthead12 = (num) => {
    section12.current?.scrollIntoView({ behavior: "smooth" });
  };
  const nvg = useNavigate()
  const navigatefeature = (num) =>{
  nvg('/',{state:{id:num}})
  }

  return (
    <div style={{ margin: "0px", padding: "0px" }}>
 <section>
  <nav className="navbar navbar-expand-lg navbar-light bg-body p-0" aria-label="Eighth navbar example">
    <div className="container-fluid">
      <div className="img-fluid pb-1 imgdivsize">
        <NavLink to="/">
          <img src={img3} className='imgsize' alt="logo" /></NavLink>
      </div>
      <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample07" aria-controls="navbarsExample07" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarsExample07" style={{width:'61%'}}>
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
          <li className="nav-item">
            <p className="nav-link text-black" style={{cursor:'pointer',marginBottom:"0px"}} onClick={()=>{navigatefeature(0)}} role="button" >
            Features
            </p>
          </li>
          <li className="nav-item">
            <p className="nav-link text-black" style={{cursor:'pointer',marginBottom:"0px"}} onClick={()=>{navigatefeature(1)}}>Industries</p>
          </li>
          {/* <li className="nav-item">
            <p className="nav-link text-black" style={{cursor:'pointer',marginBottom:"0px"}} onClick={()=>{navigatefeature(2)}}>Register</p>
          </li> */}
          <li className="nav-item dropdown">
            <a className="nav-link text-black" href="/about">About </a>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link text-black" to="/contact">Contact Us</NavLink>
          </li>
        </ul>
        
        <div className=" p-1 d-flex" style={{gap:'4px'}}>
        <li className="nav-item">
            <p className="bg-white text-primary btn btn-outline-primary" style={{cursor:'pointer',marginBlock:'0px'}} onClick={()=>{navigatefeature(2)}}>Register</p>
          </li>
            <NavLink to="/login" className="btn btn-outline-primary">
            Log in
            </NavLink>
          {/* <button type="button" className='btn' >
            <NavLink to='/cart'>
           <FaShoppingCart style={{fontSize:'24px',color:'red'}} />
           </NavLink>
          </button> */}
        </div>
      </div>
    </div>
  </nav>
</section>

      {/* <section class="banner-refund"> */}
      <section class="banner-term">
        <div class="react-overlay12">
        {/* <div class="react-overlay129"> */}
          <div class="container-fuild">
            <div class="row m-0 d-flex justify-content-center">
              <h4 class=" mt-4 bann-txt page-title ">
              Refund and Cancellation Policy  of KYC First Solution LLP
              </h4>
              {/* <p className='text-white text-center spaceofx'>Our innovative KYC solutions enable businesses across various industries to verify the identities of their customers efficiently, securely, and in a user-friendly manner.</p> */}
              {/* <div className='d-flex justify-content-center'>
                        
                        <button type="button" className="btn btn-outline-primary" style={{background:'transparent',border:'1.4px solid white',color:'white',fontWeight:'500'}}>
                    Get Started
          </button>
                        </div> */}
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="row py-3">
            <div className="col-md-8 px-3">
              <div className="section1" style={{ width: "100%" }}>
                <p className="desc120">
                At KYC First, our primary goal is to provide accurate and reliable identity verification and document authentication services to our valued clients. We understand that sometimes verifications may fail due to various reasons. In such cases, we offer a fair and transparent refund policy to ensure your satisfaction.
                </p>
              </div>

              <div className="section1 pt-2" style={{ width: "100%" }}>
                <p className="title120" ref={section1}>
                1. Verification Failure Refund
                </p>
                <p className="desc120">
                If, during the process of identity verification or document authentication, our services are unable to validate the provided information, and the verification is deemed unsuccessful, you may be eligible for a refund.
                </p>
              </div>

              <div className="section1 pt-2" style={{ width: "100%" }}>
                <p className="title120" ref={section2}>
                2. Eligibility for Refund
                </p>
                <p className="desc120">
                To be eligible for a refund due to verification failure, you must meet the following conditions:
                </p>
                <p className="desc120">
                The verification failure must be due to factors beyond your control, such as system errors or technical issues on our end.
                </p>
                <p className="desc120">
                You must have provided accurate and complete information during the verification process.
The refund request must be made within 30 days from the date of the verification failure.

                </p>
              </div>

              <div className="section1 pt-2" style={{ width: "100%" }}>
                <p className="title120" ref={section3}>
                3. Refund Process
                </p>
                <p className="desc120 pb-2">
                To initiate a refund due to verification failure, follow these steps:
                </p>
                <p className="desc120 pb-2">
                a. Contact our customer support team: Reach out to our customer support team via email at support@kycfirst.com Provide details of the verification failure and any supporting documentation if available.
                </p>
                <p className="desc120">
                b. Assessment of your request: Our support team will thoroughly investigate the verification failure and determine its cause and eligibility for a refund.
                </p>
                <p className="desc120">
                c. Refund processing: If the verification failure is verified and found to meet the eligibility criteria, we will process the refund within 3 working days. The refund will be issued using the same payment method used for the original purchase.
                </p>
              </div>

              <div className="section1 pt-2" style={{ width: "100%" }}>
                <p className="title120" ref={section4}>
                4. Non-Eligibility for Refund
                </p>
                <p className="desc120 pb-2">
                The following circumstances are not eligible for a refund:
                </p>
                <p className="desc120">
                If the refund request is submitted after 30 days from the date of the completed verification.
If the refund request does not provide valid reasons or if it is found to be fraudulent or misleading.
<br />
If the failure in verification or authentication is due to inaccurate or incomplete information provided by the user.
<br />
If the user violates our Terms and Conditions or misuses our services in any way.
                </p>
              </div>

              <div className="section1 pt-2" style={{ width: "100%" }}>
                <p className="title120" ref={section5}>
                5. Additional Information
                </p>
                <p className="desc120">
                The refund policy applies solely to instances of verification failure and does not cover other aspects of our KYC services. <br />KYC First reserves the right to modify or update this refund policy at any time without prior notice. Any changes to the refund policy will be posted on our website.
                </p>
              </div>

              <div className="section1 pt-2" style={{ width: "100%" }}>
                <p className="title120" ref={section6}>
                Contact Us
                </p>
                <p className="desc120 pb-2">
                If you have any questions or concerns regarding our refund policy in case of verification failure or need assistance with the refund process, please contact our customer support team at support@kycfirst.com. We are here to assist you and ensure your satisfaction with our KYC services.
                </p>
               
              </div>

     
              <div id="endsidebar"></div>
            </div>
            <div
              className="col-md-4"
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                className=" row stickylinks"
                style={{
                  width: IsFixed == false ? "107%" : "31%",
                  position: IsFixed == false ? "relative" : "fixed",
                  top: IsFixed == false ? "0px" : "14px",
                }}
              >
                <div className="col-12">
                  {" "}
                  <ul
                    class="list-group hideul"
                    style={{ boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.3)" }}
                  >
                    {/* <li class="list-group-item active" aria-current="true">An active item</li> */}
                    <li
                      class="list-group-item" style={{cursor:'pointer'}}
                      onClick={redirecthead}
                    >
                   1. Verification Failure Refund
                    </li>
                    <li
                      class="list-group-item" style={{cursor:'pointer'}}
                      id="myDiv"
                      onClick={redirecthead2}
                    >
                    2. Eligibility for Refund
                    </li>
                    <li
                      class="list-group-item" style={{cursor:'pointer'}}
                      onClick={redirecthead3}
                    >
                    3. Refund Process
                    </li>
                    <li
                      class="list-group-item" style={{cursor:'pointer'}}
                      onClick={redirecthead4}
                    >
                     4. Non-Eligibility for Refund
                    </li>
                    <li
                      class="list-group-item" style={{cursor:'pointer'}}
                      onClick={redirecthead5}
                    >
                     5. Additional Information
                    </li>
                    <li
                      class="list-group-item" style={{cursor:'pointer'}}
                      onClick={redirecthead6}
                    >
                     6. Contact Us
                    </li>
                  
                  </ul>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className="footer" style={{marginTop: '5rem'}}>
  <div className="container-fluid">
    <div className="row">
      <div className="col-lg-3 col-md-6 col-12">
        <div className="footer-img pb-1">
          <img src={img3} style={{mixBlendMode:'darken'}} className='footwidth'  alt="logo" />
        </div>
      </div>
      {/* <div className="col-lg-2 col-md-6 col-12 ">
        <p className="customunder">Home</p>
        <p className="desc21 ">Industries</p>
      </div> */}
      <div className="col-lg-2 col-md-6 col-12 " style={{display:'flex',justifyContent:'center'}}>
        {/* <p className="customunder">Pricing</p> */}
        <p className="desc21 "><NavLink style={{color:'black'}} to='/contact'>Contact Us</NavLink></p>
      </div>
      <div className="col-lg-3 col-md-6 col-12 ">
      <p> <a style={{ color: "black" }} href="/about">About</a></p>

        <p className="desc21 "><a style={{color:'black'}} href="/privacypolicy"> Privacy & Policy</a></p>
        <p className="desc21"><a style={{color:'black'}} href="/termandcondition">Terms & Condition</a></p>
        <p className="desc21"><a style={{color:'black'}} href="/refund">Refund and Cancellation</a></p>
      </div>
      <div className="col-lg-4 col-md-6 col-12">
        <div className="single-footer">
          {/* <p className="customunder">Contact Us</p> */}
          <div className="contact_i">
            <div className="row mt-2">
              <div className="col-1" style={{color:'rgba(12, 83, 152, 1)'}} ><ImLocation2 /></div>
              <div className="col-10  desc21">907 Clover Hill Plaza NIBM Road Kondhwa Pune 411048 MS</div>
            </div>

            <div className="row mt-2">
              <div className="col-1" style={{color:'rgba(12, 83, 152, 1)'}} ><FaEnvelope /></div>
              <div className="col-10 desc21">Email:<a className=" text-decoration-none" href="mailto:legal@kycfirst.io">
               legal@kycfirst.io</a></div>
            </div>
            <div className="row mt-2">
              <div className="col-1" style={{color:'rgba(12, 83, 152, 1)'}} ><BsFillTelephoneFill /></div>
              <div className="col-10 desc21"> Phone:<a className=" text-decoration-none" href="tel:8130515661">

                 +91 9730074506</a>
              </div>
            </div>
           
          
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div className="row ">
      <div className="col-12" style={{display:'none'}}>
      <ul className="social-foo">
          <li style={{display:'flex',justifyContent:'center',alignItems:'center',width:'50px'}}><a href="#"><FaFacebookF /></a></li>
          <li style={{display:'flex',justifyContent:'center',alignItems:'center',width:'50px'}}><a href="#"><FaInstagram /></a></li>
          <li style={{display:'flex',justifyContent:'center',alignItems:'center',width:'50px'}}><a href="#"><FaTwitter/></a></li>
          <li style={{display:'flex',justifyContent:'center',alignItems:'center',width:'50px'}}><a href="#"><FaLinkedinIn /></a></li>
        </ul>
      </div>
      <div className="col-12 mt-1 mb-1">
        <p className="text-center desc2">© 2023 - All Right Reserved</p>
      </div>
    </div>
  </div>
</footer>
    </div>
  );
};

export default Refund;
