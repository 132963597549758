import React, { useContext, useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import img1 from "../../assets/Group 40.png";
import img2 from "../../assets/Group 41.png";
import img3 from "../../assets/asdfg.png";
import img4 from "../../assets/abc.png";
import img5 from "../../assets/cba.png";
import Webcamtwo from "react-webcam";
import img33 from "../../assets/images.png";
import img7 from "../../assets/ul.png";
import { gettoken } from "../../Localstorage/Store";
import { Switch } from '@mui/material';
import StepWizard from "react-step-wizard";
// import { StepWizard, Step } from 'react-step-wizard';
import axios from "axios";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Loadercomp from "../../components/Loadercomp";
import checkicon from '../../assets/Ellipse 28.png';
import ReactImageMagnify from 'react-image-magnify';
import Webcam from "react-webcam";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import csc from 'country-state-city';
import Select from "react-select";
import {CaptureFinger,MatchFinger} from "../mfs100";
import { BsFillArrowLeftCircleFill,BsFillArrowRightCircleFill,BsFillArrowUpCircleFill,BsFillArrowDownCircleFill, BsQuestionLg } from "react-icons/bs";
import { getCode } from "country-list";
import Balance from "../../context/Balance";

const Editcustomer = () => {

  const documents = [
    {
      id:1,
      name:'VOTER ID',
      value:'VOTERID'
    },
    {
      id:2,
      name:'AADHAR CARD',
      value:'AADHAAR'
    },
    {
      id:3,
      name:'PASSPORT',
      value:'PASSPORT'
    },
    {
      id:4,
      name:'DRIVING LICENSE',
      value:'DRIVING_LICENSE'
    }
    // {
    //   id:5,
    //   name:'PAN CARD',
    //   value:'PAN'
    // }
  ];
  const nvg = useNavigate()
  const {id} = useParams()
  // const pickimg = useRef(null)
  const selectoption = useRef()
  const [displaydocument,setdisplaydocument] = useState('')
  const selectlocationoption = useRef()
  const [dob, setdob] = useState();
  const [fname, setfname] = useState();
  const [emailchage, setemailchage] = useState(false);
  const [lname, setlname] = useState();
  const [form60, setform60] = useState(null);
  const [notapplicable, setnotapplicable] = useState(false);
  const [mobile_no, setmobile_no] = useState();
  const [gender, setgender] = useState("Male");
  const [emailID, setemailID] = useState();
  const [ffn, setffn] = useState();
  const [company, setcompany] = useState('');
  const [location, setlocation] = useState('');
  const [address, setaddress] = useState();
  const [country, setcountry] = useState(null);
  const [doccountry, setdoccountry] = useState(null);
  const [state, setstate] = useState(null);
  const [city, setcity] = useState(null);
  const [isstate, setisstate] = useState(false);
  const [iscity, setiscity] = useState(false);
  const [aadhaarImagetwo, setAadhaarImagetwo] = useState(null);
  const [showerror, setshowerror] = useState(false);
  const stepWizardInstance = useRef(null);
  const [pincode, setpincode] = useState();
  const [dynamicgender, setdynamicgender] = useState();
  const [cr_thumb, setcr_thumb] = useState('');
  const [cr_index_finger, setcr_index_finger] = useState('');
  const [cr_middle_finger, setcr_middle_finger] = useState('');
  const [cr_ring_finger, setcr_ring_finger] = useState('');
  const [cr_little_finger, setcr_little_finger] = useState('');
  const [cl_thumb, setcl_thumb] = useState('');
  const [cl_index_finger, setcl_index_finger] = useState('');
  const [cl_middle_finger, setcl_middle_finger] = useState('');
  const [cl_ring_finger, setcl_ring_finger] = useState('');
  const [cl_little_finger, setcl_little_finger] = useState('');
  const [thumbimg2, setthumbimg2] = useState('');
  const [rifimg2, setrifimg2] = useState('');
  const [rmfimg2, setrmfimg2] = useState('');
  const [rrfimg2, setrrfimg2] = useState('');
  const [rlfimg2, setrlfimg2] = useState('');
  const [ltfimg2, setltfimg2] = useState('');
  const [lifimg2, setlifimg2] = useState('');
  const [lmfimg2, setlmfimg2] = useState('');
  const [lrfimg2, setlrfimg2] = useState('');
  const [llfimg2, setllfimg2] = useState('');
  const [ansithumbimg2, setansithumbimg2] = useState('');
  const [ansirifimg2, setansirifimg2] = useState('');
  const [ansirmfimg2, setansirmfimg2] = useState('');
  const [ansirrfimg2, setansirrfimg2] = useState('');
  const [ansirlfimg2, setansirlfimg2] = useState('');
  const [ansiltfimg2, setansiltfimg2] = useState('');
  const [ansilifimg2, setansilifimg2] = useState('');
  const [ansilmfimg2, setansilmfimg2] = useState('');
  const [ansilrfimg2, setansilrfimg2] = useState('');
  const [ansillfimg2, setansillfimg2] = useState('');
  const[defaultv,setdefaultv] = useState('')
  const[defaultvtwo,setdefaultvtwo] = useState('')
  const [custresponse, setcustresponse] = useState({});
  const [errorclass, seterrorclass] = useState(false);
  const [grandloading, setgrandloading] = useState(false);
  // const [isstate, setisstate] = useState(false);
  // const [iscity, setiscity] = useState(false);


const [isrequired,setisrequired] = useState(false)
  const [rtferror ,setrtferror] = useState(false)
const [riferror ,setriferror] = useState(false)
const [rmferror ,setrmferror] = useState(false)
const [rrferror ,setrrferror] = useState(false)
const [rlferror ,setrlferror] = useState(false)
const [ltferror ,setltferror] = useState(false)
const [liferror ,setliferror] = useState(false)
const [lmferror ,setlmferror] = useState(false)
const [lrferror ,setlrferror] = useState(false)
const [llferror ,setllferror] = useState(false)



const [rightnumberoffingers12, setrightnumberoffingers12] = useState(0);
const [leftnumberoffingers12, setleftnumberoffingers12] = useState(0);


  const [varxtwo, setvarxtwo] = useState(10);
  const [varytwo, setvarytwo] = useState(10);
  const gettokinval = gettoken();
  const [sandphoto,setsandphoto] = useState('')
  const [displayphoto,setdisplayphoto] = useState('')
  const [loading, setloading] = useState(false);
  const [srtloader, setsrtloader] = useState(true);
  const [hideorshowtwo, sethideorshowtwo] = useState(false);
  const [hideorshowthree, sethideorshowthree] = useState(false);
  const [hideorshow, sethideorshow] = useState(false);
  const [documenttype, setdocumenttype] = useState('PAN');
  const [idnumber, setidnumber] = useState();
  const [documentimage, setdocumentimage] = useState();
  const [stateerrormsg, setstateerrormsg] = useState(null);
  const [cityerrormsg, setcityerrormsg] = useState(null);
  const [documentissue, setdocumentissue] = useState();
  const [documentexpiry, setdocumentexpiry] = useState();
  const [documenterror, setdocumenterror] = useState(false);
  const [documenterrorres, setdocumenterrorres] = useState(false);
  const [documentresponse, setdocumentresponse] = useState({});
  const [documentloader, setdocumentloader] = useState(false);
  const [documentstatus, setdocumentstatus] = useState({});
  const [idno, setidno] = useState(0);

  const [status, setstatus] = useState(false)

  const webcamRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
  // for dropdown company and location

  const [data, setData] = useState([]);
  const [datatwo, setDatatwo] = useState([]);
  const [errorcls, seterrorcls] = useState({});
  const [varx, setvarx] = useState(10);
  const [vary, setvary] = useState(10);
  const [documentimageback, setdocumentimageback] = useState(null);
  const [documentimagebacktwo, setdocumentimagebacktwo] = useState(null);
  const [check1, setcheck1] = useState(false);
  const [check2, setcheck2] = useState(false);

  const [aadhaarImage, setAadhaarImage] = useState(null);
  const [modifiedImage, setModifiedImage] = useState(null);
  const [sandphotoerror, setsandphotoerror] = useState(false);



  const [documentpdf1, setdocumentpdf1] = useState(null);
  const [documentpdf2, setdocumentpdf2] = useState(null);
  const [doc1, setdoc1] = useState(true);
  const [doc2, setdoc2] = useState(true);


  const [cnationality,setcnationality] = useState('')
  const [documenttypetwo, setdocumenttypetwo] = useState();
  const [idnumbertwo, setidnumbertwo] = useState();
  const [documentissuetwo, setdocumentissuetwo] = useState();
  const [documentexpirytwo, setdocumentexpirytwo] = useState();
  const [modifiedImagenumbertwo, setModifiedImagenumbertwo] = useState(null);
  const [documentimageback2, setdocumentimageback2] = useState(null);
  const [aadhaarImage2, setAadhaarImage2] = useState(null);
  const [varx2, setvarx2] = useState(10);
  const [vary2, setvary2] = useState(10);
  const [aadhaarback2, setAadhaarback2] = useState(null);
  const [viewdoc1, setviewdoc1] = useState(null);
  const [viewdoc2, setviewdoc2] = useState(null);
  const [varxtwo2, setvarxtwo2] = useState(10);
  const [varytwo2, setvarytwo2] = useState(10);
  const [hideorshow4, sethideorshow4] = useState(false);
  const [hideorshow3, sethideorshow3] = useState(false);
  const [seconddocopen, setseconddocopen] = useState(true);


  const [rigthfingerserror12, setrigthfingerserror12] = useState('');
  const [fingerperror12, setfingerperror12] = useState(false);



  const [thumbimg2sand, setthumbimg2sand] = useState('');
  const [rifimg2sand, setrifimg2sand] = useState('');
  const [rmfimg2sand, setrmfimg2sand] = useState('');
  const [rrfimg2sand, setrrfimg2sand] = useState('');
  const [rlfimg2sand, setrlfimg2sand] = useState('');
  const [ltfimg2sand, setltfimg2sand] = useState('');
  const [lifimg2sand, setlifimg2sand] = useState('');
  const [lmfimg2sand, setlmfimg2sand] = useState('');
  const [lrfimg2sand, setlrfimg2sand] = useState('');
  const [llfimg2sand, setllfimg2sand] = useState('');

  const [megaerror, setmegaerror] = useState(false);
  const [megaerrormsg, setmegaerrormsg] = useState('');

  const [rtf3error ,setrtf3error] = useState(false)
  const [rif3error ,setrif3error] = useState(false)
  const [rmf3error ,setrmf3error] = useState(false)
  const [rrf3error ,setrrf3error] = useState(false)
  const [rlf3error ,setrlf3error] = useState(false)
  const [ltf3error ,setltf3error] = useState(false)
  const [lif3error ,setlif3error] = useState(false)
  const [lmf3error ,setlmf3error] = useState(false)
  const [lrf3error ,setlrf3error] = useState(false)
  const [llf3error ,setllf3error] = useState(false)
  const [isformonecheck ,setisformonecheck] = useState(true)
  const [submitacc ,setsubmitacc] = useState(false)
  const [submitaccmsg ,setsubmitaccmsg] = useState(null)

  
  const [thumbimg5, setthumbimg5] = useState('');
  const [rifimg5, setrifimg5] = useState('');
  const [rmfimg5, setrmfimg5] = useState('');
  const [rrfimg5, setrrfimg5] = useState('');
  const [rlfimg5, setrlfimg5] = useState('');
  const [ltfimg5, setltfimg5] = useState('');
  const [lifimg5, setlifimg5] = useState('');
  const [lmfimg5, setlmfimg5] = useState('');
  const [lrfimg5, setlrfimg5] = useState('');
  const [llfimg5, setllfimg5] = useState('');
  const [ansithumbimg5, setansithumbimg5] = useState('');
  const [ansirifimg5, setansirifimg5] = useState('');
  const [ansirmfimg5, setansirmfimg5] = useState('');
  const [ansirrfimg5, setansirrfimg5] = useState('');
  const [ansirlfimg5, setansirlfimg5] = useState('');
  const [ansiltfimg5, setansiltfimg5] = useState('');
  const [ansilifimg5, setansilifimg5] = useState('');
  const [ansilmfimg5, setansilmfimg5] = useState('');
  const [ansilrfimg5, setansilrfimg5] = useState('');
  const [ansillfimg5, setansillfimg5] = useState('');
  const [showsuperotp ,setshowsuperotp] = useState(false)
  const [passotp ,setpassotp] = useState(true)

  function handleImageUpload(event) {
    const file = event.target.files[0];
  
    // Perform any necessary validation on the uploaded file
  
    // Read the file as a data URL
    const reader = new FileReader();
    reader.onload = () => {
      const imageDataUrl = reader.result;
  
      // Update the state with the uploaded image data
      setAadhaarImage(imageDataUrl);
  
      // Modify the image and generate the modified image URL
      if(documenttype == 'AADHAAR'){
        const modifiedImageUrl = modifyImage(imageDataUrl,varx,vary);
        setModifiedImage(modifiedImageUrl);
      }else{
        setModifiedImage(imageDataUrl);
      }
    };
  
    // Read the uploaded file as a data URL
    reader.readAsDataURL(file);
  }

  function modifyImage(imageDataUrl,xl,yt) {

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
  
    // Create an Image object and load the Aadhaar card image data
    const image = new Image();
    image.onload = () => {
      // Set the canvas dimensions to match the image dimensions
      canvas.width = image.width;
      canvas.height = image.height;
  
      // Draw the image on the canvas
      context.drawImage(image, 0, 0);
  
      // Modify the pixels in the desired area to hide the numbers
      //const x = xl; X-coordinate of the area to hide
      //const y = yt; // Y-coordinate of the area to hide
      const width = 240; // Width of the area to hide
      const height = 50; // Height of the area to hide
      context.fillStyle = 'black';
      context.fillRect(xl, yt, width, height);
  
      // Generate the modified image URL from the canvas
      const modifiedImageUrl = canvas.toDataURL('image/jpeg');
  
      // Update the state with the modified image URL
      setModifiedImage(modifiedImageUrl);
    };
  
    // Set the Image object's source to the Aadhaar card image data
    image.src = imageDataUrl;
  }
  
  const [reloadin,setreloadin] = useState(true)


  const reloadcam = ()=>{
  setreloadin(false)
  setTimeout(() => {
  setreloadin(true)
  }, 1000);
  }

  const handleStepChange = (step) => {
    console.log(`Step changed to ${step}`);
    // You can add custom logic here based on the step change
  };


  const [error, setError] = useState(null);
  const [pineror, setpineror] = useState('');
  const [stateerror, setstateerror] = useState('');
  const [cityerror, setcityerror] = useState('');
  
  
  
  
  
  const handleSubmit = () => {
    if(country !== undefined || country !== '' || country !== null){
      setError(null);
      let createcountry;
      if(country.sortname){
        createcountry = country.sortname;
      }else{
        createcountry = getCode(country)
      }
      axios.get(`https://api.zippopotam.us/${createcountry}/${pincode}`)
        .then((response) => {
          // console.log(response)
          setstate(response.data.places[0].state)
          setcity(response.data.places[0]['place name'])
          setpineror('')
          setstateerror('')
          setcityerror('')
        })
        .catch((error) => {
          setError('Failed to fetch location data. Please check the pincode.');
          // console.log(error.response)
          setpineror('Please Check Your Country and Pincode')
        });
    }
  
  };
  
  
  
  const countryfocus = () =>{
    if(country == undefined || country == '' || country == null){
      setpineror('Please Select Country First')
    }else{
      setpineror('')
    }
  }
  
  








  function modifyImage(imageDataUrl,xl,yt) {

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
  
    // Create an Image object and load the Aadhaar card image data
    const image = new Image();
    image.onload = () => {
      // Set the canvas dimensions to match the image dimensions
      canvas.width = image.width;
      canvas.height = image.height;
  
      // Draw the image on the canvas
      context.drawImage(image, 0, 0);
  
      // Modify the pixels in the desired area to hide the numbers
      //const x = xl; X-coordinate of the area to hide
      //const y = yt; // Y-coordinate of the area to hide
      const width = 240; // Width of the area to hide
      const height = 50; // Height of the area to hide
      context.fillStyle = 'black';
      context.fillRect(xl, yt, width, height);
  
      // Generate the modified image URL from the canvas
      const modifiedImageUrl = canvas.toDataURL('image/jpeg');
  
      // Update the state with the modified image URL
      setModifiedImage(modifiedImageUrl);
    };
  
    // Set the Image object's source to the Aadhaar card image data
    image.src = imageDataUrl;
  }



  function backmodifyImage(imageDataUrl,xl,yt) {

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
  
    // Create an Image object and load the Aadhaar card image data
    const image = new Image();
    image.onload = () => {
      // Set the canvas dimensions to match the image dimensions
      canvas.width = image.width;
      canvas.height = image.height;
  
      // Draw the image on the canvas
      context.drawImage(image, 0, 0);
  
      // Modify the pixels in the desired area to hide the numbers
      //const x = xl; X-coordinate of the area to hide
      //const y = yt; // Y-coordinate of the area to hide
      const width = 240; // Width of the area to hide
      const height = 50; // Height of the area to hide
      context.fillStyle = 'black';
      context.fillRect(xl, yt, width, height);
  
      // Generate the modified image URL from the canvas
      const modifiedImageUrl = canvas.toDataURL('image/jpeg');
  
      // Update the state with the modified image URL
      setdocumentimagebacktwo(modifiedImageUrl);
    };
  
    // Set the Image object's source to the Aadhaar card image data
    image.src = imageDataUrl;
  }
  
// capture image from webcam start here
const captureimage = (num) =>{
  setdisplayphoto(webcamRefs[num].current.getScreenshot())
  sethideorshow(!hideorshow)
}
// capture image from webcam end here

// capture image from webcam start here
const captureimagetwo = (num) =>{
  // if(num == 0){
    
    if(documenttype == 'AADHAAR'){
      const modifiedImageUrl = modifyImage(webcamRefs[num].current.getScreenshot(),varx,vary);
      setModifiedImage(modifiedImageUrl);
      setAadhaarImage(webcamRefs[num].current.getScreenshot())
    }else{
      setModifiedImage(webcamRefs[num].current.getScreenshot());
    }
    // setdisplayphototwo(pickimgtwo.current.getScreenshot())
    sethideorshowtwo(!hideorshowtwo)
  // }else{
  //   console.log("ldlddldldldldldlddldl")
  //   if(documenttype == 'AADHAAR'){
  //     const modifiedImageUrl = modifyImage(pickimgtwo.current.getScreenshot(),varxtwo,varytwo);
  //     setdocumentimagebacktwo(modifiedImageUrl);
  //     setAadhaarImagetwo(pickimgtwo.current.getScreenshot())
  //     sethideorshowthree(!hideorshowthree)
  //   console.log("ldlddldldldldldlddldrrrrrrrrrrl",documentimagebacktwo)
  //   }else{
  //     setdocumentimagebacktwo(pickimgtwo.current.getScreenshot());
  //     sethideorshowthree(!hideorshowthree)
  //   console.log("ldlddldldldldldlddldlyyyyyyyyyyyyyyyy",documentimagebacktwo)
  
  //   }
  //   console.log("ldlddldldldldldlddldl",documentimagebacktwo)
  // }

}
// capture image from webcam end here

const captureimage123 = (num) =>{
  setdisplaydocument(webcamRefs[num].current.getScreenshot())
  sethideorshow(!hideorshow)
}

const captureimagethree = (num) =>{
  if(documenttype == 'AADHAAR'){
    const modifiedImageUrl = backmodifyImage(webcamRefs[num].current.getScreenshot(),varxtwo,varytwo);
    setdocumentimagebacktwo(modifiedImageUrl);
    setAadhaarImagetwo(webcamRefs[num].current.getScreenshot())
    sethideorshowthree(!hideorshowthree)
  // console.log("ldlddldldldldldlddldrrrrrrrrrrl",documentimagebacktwo)
  }else{
    setdocumentimagebacktwo(webcamRefs[num].current.getScreenshot());
    sethideorshowthree(!hideorshowthree)
  // console.log("ldlddldldldldldlddldlyyyyyyyyyyyyyyyy",documentimagebacktwo)

  }
  // console.log("ldlddldldldldldlddldl",documentimagebacktwo)

  // setdisplayphototwo(pickimgtwo.current.getScreenshot())
}



const captureimagethreetwo = (num) =>{
  if(documenttypetwo == 'AADHAAR'){
    const modifiedImageUrl = backmodifyImage3(webcamRefs[num].current.getScreenshot(),varxtwo,varytwo);
    setdocumentimageback2(modifiedImageUrl);
    setAadhaarback2(webcamRefs[num].current.getScreenshot())
    sethideorshow3(!hideorshow3)
  }else{
    setdocumentimageback2(webcamRefs[num].current.getScreenshot());
    sethideorshow3(!hideorshow3)

  }

  // setdisplayphototwo(pickimgtwo.current.getScreenshot())
}






// capture image from webcam start here
const captureimagesuper2 = (num) =>{
  // if(num == 0){
    
    if(documenttypetwo == 'AADHAAR'){
      const modifiedImageUrl = modifyImagethree(webcamRefs[num].current.getScreenshot(),varx,vary);
      setModifiedImagenumbertwo(modifiedImageUrl);
      setAadhaarImage2(webcamRefs[num].current.getScreenshot())
    }else{
      setModifiedImagenumbertwo(webcamRefs[num].current.getScreenshot());
    }
    // setdisplayphototwo(pickimgtwo.current.getScreenshot())
    sethideorshow3(!hideorshow3)
  // }else{
  //   console.log("ldlddldldldldldlddldl")
  //   if(documenttype == 'AADHAAR'){
  //     const modifiedImageUrl = modifyImage(pickimgtwo.current.getScreenshot(),varxtwo,varytwo);
  //     setdocumentimagebacktwo(modifiedImageUrl);
  //     setAadhaarImagetwo(pickimgtwo.current.getScreenshot())
  //     sethideorshowthree(!hideorshowthree)
  //   console.log("ldlddldldldldldlddldrrrrrrrrrrl",documentimagebacktwo)
  //   }else{
  //     setdocumentimagebacktwo(pickimgtwo.current.getScreenshot());
  //     sethideorshowthree(!hideorshowthree)
  //   console.log("ldlddldldldldldlddldlyyyyyyyyyyyyyyyy",documentimagebacktwo)
  
  //   }
  //   console.log("ldlddldldldldldlddldl",documentimagebacktwo)
  // }

}
// capture image from webcam end here
  


function modifyImagethree(imageDataUrl,xl,yt) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  // Create an Image object and load the Aadhaar card image data
  const image = new Image();
  image.onload = () => {
    // Set the canvas dimensions to match the image dimensions
    canvas.width = image.width;
    canvas.height = image.height;

    // Draw the image on the canvas
    context.drawImage(image, 0, 0);

    // Modify the pixels in the desired area to hide the numbers
    //const x = xl; X-coordinate of the area to hide
    //const y = yt; // Y-coordinate of the area to hide
    const width = 240; // Width of the area to hide
    const height = 50; // Height of the area to hide
    context.fillStyle = 'black';
    context.fillRect(xl, yt, width, height);

    // Generate the modified image URL from the canvas
    const modifiedImageUrl = canvas.toDataURL('image/jpeg');

    // Update the state with the modified image URL
    setModifiedImagenumbertwo(modifiedImageUrl);
  };

  // Set the Image object's source to the Aadhaar card image data
  image.src = imageDataUrl;
}





function backmodifyImage3(imageDataUrl,xl,yt) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  // Create an Image object and load the Aadhaar card image data
  const image = new Image();
  image.onload = () => {
    // Set the canvas dimensions to match the image dimensions
    canvas.width = image.width;
    canvas.height = image.height;

    // Draw the image on the canvas
    context.drawImage(image, 0, 0);

    // Modify the pixels in the desired area to hide the numbers
    //const x = xl; X-coordinate of the area to hide
    //const y = yt; // Y-coordinate of the area to hide
    const width = 240; // Width of the area to hide
    const height = 50; // Height of the area to hide
    context.fillStyle = 'black';
    context.fillRect(xl, yt, width, height);

    // Generate the modified image URL from the canvas
    const modifiedImageUrl = canvas.toDataURL('image/jpeg');

    // Update the state with the modified image URL
    setdocumentimageback2(modifiedImageUrl);
  };

  // Set the Image object's source to the Aadhaar card image data
  image.src = imageDataUrl;
}


const changedocumetimgtwo = (e) =>{
  // if(e == 'AADHAAR'){
  //   if(modifiedImage !== null){
  //     const modifiedImageUrl = modifyImage(modifiedImage,varx,vary);
  //     setModifiedImage(modifiedImageUrl);
  //     setAadhaarImage(modifiedImage)
  //   }
  // }else{
    setAadhaarImage2(null);
    setModifiedImagenumbertwo(null)
    setAadhaarback2(null)
    setdocumentimageback2(null)
  // }
}










const [artferror, setartferror] = useState('')
const handleImageChange110 = () => {
  setthumbimg2sand('')
  setthumbimg5('');
  setansithumbimg5('')
  setrtf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_r_thumb);
  if(client.data.Status){
    setthumbimg2sand(formdatao.r_thumb)
    setthumbimg5(formdatao.r_thumb);
    setansithumbimg5(formdatao.ansi_r_thumb)
    setrightnumberoffingers12(1)
        setartferror('')
  }else{
    // console.log("980090000")
    setartferror(" Not Match")
  }
  setrtf3error(false)
}, 1000);
};
const [ariferror, setariferror] = useState('')
const handleImageChange120 = () => {
  setrifimg2sand('')
  setrifimg5('');
  setansirifimg5();
  setrif3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_r_index_finger);
if(client.data.Status){
  // console.log("3454355")
  setrifimg2sand(formdatao.r_index_finger)
  setrifimg5(formdatao.r_index_finger);
      setrightnumberoffingers12(1)
      setariferror('')
      setansirifimg5(formdatao.ansi_r_index_finger)
}else{
  // console.log("980090000")
  setariferror(" Not Match")
}
setrif3error(false)
}, 1000);
};
const [armferror, setarmferror] = useState('')
const handleImageChange130 = () => {
  setrmfimg2sand('')
  setrmfimg5('');
  setrmf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_r_middle_finger);
  if(client.data.Status){
    // console.log("3454355")
    setrmfimg2sand(formdatao.r_middle_finger)
    setrmfimg5(formdatao.r_middle_finger);
    setansirmfimg5(formdatao.ansi_r_middle_finger)
    setrightnumberoffingers12(1)
        setarmferror('')
  }else{
    // console.log("980090000")
    setarmferror(" Not Match")
  }
  setrmf3error(false)
}, 1000);
};


const [arrferror, setarrferror] = useState('')
const handleImageChange140 = () => {
  setrrfimg2sand('')
  setrrfimg5('');
  setrrf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_r_ring_finger);
  if(client.data.Status){
    // console.log("3454355")
    setrrfimg2sand(formdatao.r_ring_finger)
    setrrfimg5(formdatao.r_ring_finger);
    setansirrfimg5(formdatao.ansi_r_ring_finger)
    setrightnumberoffingers12(1)
        setarrferror('')
  }else{
    // console.log("980090000")
    setarrferror(" Not Match")
  }
  setrrf3error(false)
}, 1000);
};


const [arlferror, setarlferror] = useState('')

const handleImageChange150 = () => {
  setrlfimg2sand('')
  setrlfimg5('');
  setrlf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_r_little_finger);
  if(client.data.Status){
    // console.log("3454355")
    setrlfimg2sand(formdatao.r_little_finger)
    setrlfimg5(formdatao.r_little_finger);
    setansirlfimg5(formdatao.ansi_r_little_finger)
    setrightnumberoffingers12(1)
        setarlferror('')
  }else{
    // console.log("980090000")
    setarlferror(" Not Match")
  }
  setrlf3error(false)
}, 1000);
};
const [altferror, setaltferror] = useState('')

const handleImageChange160 = () => {
  setltfimg2sand('')
    setltfimg5('');
  setltf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_l_thumb);
  if(client.data.Status){
    // console.log("3454355")
    setltfimg2sand(formdatao.l_thumb)
    setltfimg5(formdatao.l_thumb);
    setansiltfimg5(formdatao.ansi_l_thumb)
    setrightnumberoffingers12(1)
        setaltferror('')
  }else{
    // console.log("980090000")
    setaltferror(" Not Match")
  }
  setltf3error(false)
}, 1000);
};

const [aliferror, setaliferror] = useState('')

const handleImageChange170 = () => {
  setlifimg2sand('')
  setlifimg5('');
  setlif3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_l_index_finger);
  if(client.data.Status){
    // console.log("3454355")
    setlifimg2sand(formdatao.l_index_finger)
    setlifimg5(formdatao.l_index_finger);
    setansilifimg5(formdatao.ansi_l_index_finger)
    setrightnumberoffingers12(1)
        setaliferror('')
  }else{
    // console.log("980090000")
    setaliferror(" Not Match")
  }
  setlif3error(false)
}, 1000);
};

const [almferror, setalmferror] = useState('')

const handleImageChange180 = () => {
  setlmfimg2sand('')
    setlmfimg5('');
  setlmf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_l_middle_finger);
  if(client.data.Status){
    // console.log("3454355")
    setlmfimg2sand(formdatao.l_middle_finger)
    setlmfimg5(formdatao.l_middle_finger);
    setansilmfimg5(formdatao.ansi_l_middle_finger)
    setrightnumberoffingers12(1)
        setalmferror('')
  }else{
    // console.log("980090000")
    setalmferror(" Not Match")
  }
  setlmf3error(false)
}, 1000);
};
const [alrferror, setalrferror] = useState('')

const handleImageChange190 = () => {
  setlrfimg2sand('')
    setlrfimg5('');
  setlrf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_l_ring_finger);
  if(client.data.Status){
    // console.log("3454355")
    setlrfimg2sand(formdatao.l_ring_finger)
    setlrfimg5(formdatao.l_ring_finger);
    setansilrfimg5(formdatao.ansi_l_ring_finger)
    setrightnumberoffingers12(1)
    setalrferror('')
  }else{
    // console.log("980090000")
    setalrferror(" Not Match")
  }
  setlrf3error(false)
}, 1000);
};
const [allferror, setallferror] = useState('')

const handleImageChange200 = () => {
  setllfimg2sand('')
    setllfimg5('');
  setllf3error(true)
  setTimeout(() => {
  const client = new MatchFinger(80,5000,formdatao.ansi_l_little_finger);
  if(client.data.Status){
    // console.log("3454355")
    setllfimg2sand(formdatao.l_little_finger)
    setllfimg5(formdatao.l_little_finger);
    setansillfimg5(formdatao.ansi_l_little_finger)
    setrightnumberoffingers12(1)
        setallferror('')
  }else{
    // console.log("980090000")
    setallferror(" Not Match")
  }
  setllf3error(false)
}, 1000);
};





const [otploading, setotploading] = useState(false);

const [otp ,setotp] = useState('')
const [otp2 ,setotp2] = useState('')
const [otpshowresmobile ,setotpshowresmobile] = useState(false)
  const [otpshowres ,setotpshowres] = useState(false)
  const [invalidotp ,setinvalidotp] = useState(false)
  const [invalidotpmobile ,setinvalidotpmobile] = useState(false)
  const [verifyyourotp ,setverifyyourotp] = useState(false)

const [sendotpres ,setsendotpres] = useState(false)

const [sendotpresmobile ,setsendotpresmobile] = useState(false)
const [otpnext ,setotpnext] = useState(false)
const [otpnextmobile ,setotpnextmobile] = useState(false)
const [registerpro ,setregisterpro] = useState(true)
const [registerpromobile ,setregisterpromobile] = useState(true)






const verifyotp = async () =>{
  if(otp !== ''){
    const formdata = new FormData();
    formdata.append("email",emailID);
    formdata.append("otp",otp);
        try {
          setotploading(true)
          let url = `${process.env.REACT_APP_API_URL}api/verify-otp/`;
          const response = await axios.post(url,formdata);
          // console.log("ooooooooooooooooooooo",response);
          if(response.status == 201 || response.status == 200){
            setotpshowres(true)
            setregisterpro(true)
            setotpnext(false)
            setotploading(false)
            setTimeout(function() {
              setotpshowres(false)
             }, 5000); // 5000 milliseconds (5 seconds) delay
          }
        } catch (error) {
          setotploading(false)
          setinvalidotp(true)
        //  console.log(error.response.data.error)
         setTimeout(function() {
          setinvalidotp(false)
         }, 5000); // 5000 milliseconds (5 seconds) delay
        }
  }
}



const [changenumber,setchangenumber] = useState(false)
console.log("change number outside",changenumber)

const verifyotpmobile = async () =>{
  if(mobile_no !== getdata.mobile_no){
    if(otp2 !== ''){
  
      const formdata = new FormData();
      formdata.append("phone",mobile_no);
      formdata.append("otp",otp2);
          try {
            setotploading(true)
            let url = `${process.env.REACT_APP_API_URL}api/verify-otp-change_phone/`;
            const response = await axios.post(url,formdata);
            // console.log("ooooooooooooooooooooo",response);
            if(response.status == 201 || response.status == 200){
              setotpshowresmobile(true)
              setregisterpromobile(true)
              setchangenumber(true)
              console.log("change number",changenumber)
              setotpnextmobile(false)
              setotploading(false)

              if(emailchage == true){





                if(otp !== ''){
                  const formdata = new FormData();
                  formdata.append("email",emailID);
                  formdata.append("otp",otp);
                      try {
                        setotploading(true)
                        let url = `${process.env.REACT_APP_API_URL}api/verify-otp/`;
                        const response = await axios.post(url,formdata);
                        // console.log("ooooooooooooooooooooo",response);
                        if(response.status == 201 || response.status == 200){
                          setotpshowres(true)
                          setregisterpro(true)
                          setotpnext(false)
                          setotploading(false)
                          setshowsuperotp(false)
                          setpassotp(true)
                setemailchage(false)
                console.log("popup working now verift")
                // minsubmitform(true,true,false)
                setisrequired(true)
                stepWizardInstance.current.lastStep()
                          setTimeout(function() {
                            setotpshowres(false)
                            setotpshowresmobile(false)
                           }, 5000); // 5000 milliseconds (5 seconds) delay
                           
                        }
                      } catch (error) {
                        setotploading(false)
                        setinvalidotp(true)
                      //  console.log(error.response.data.error)
                       setTimeout(function() {
                        setinvalidotp(false)
                        setotpshowresmobile(false)
                       }, 5000); // 5000 milliseconds (5 seconds) delay
                      }
                }
                // setemailchage(false)
                
                // setshowsuperotp(false)
                
                // console.log("popup working now verift")
                // minsubmitform(true,true,true)
              }else{
                // setemailchage(false)
                console.log("popuptruentrue true")
                // minsubmitform(true,true,false)
              setshowsuperotp(false)
              setpassotp(true)
                setemailchage(false)
                setisrequired(true)
                stepWizardInstance.current.lastStep()
                setTimeout(function() {
                  setotpshowresmobile(false)
                  
                 }, 5000); // 5000 milliseconds (5 seconds) delay
              }
            }
          } catch (error) {
            setotploading(false)
            setinvalidotpmobile(true)
          //  console.log(error.response.data.error)
           setTimeout(function() {
            setinvalidotpmobile(false)
           }, 7000); // 5000 milliseconds (5 seconds) delay
          }
    }

  }else{
    if(otp !== ''){
      const formdata = new FormData();
      formdata.append("email",emailID);
      formdata.append("otp",otp);
          try {
            setotploading(true)
            let url = `${process.env.REACT_APP_API_URL}api/verify-otp/`;
            const response = await axios.post(url,formdata);
            // console.log("ooooooooooooooooooooo",response);
            if(response.status == 201 || response.status == 200){
              setotpshowres(true)
              setregisterpro(true)
              setotpnext(false)
              setotploading(false)
              setshowsuperotp(false)
    setemailchage(false)
    console.log("popup working now verift")
    setpassotp(true)
    setisrequired(true)
                // setemailchage(false)
    // minsubmitform(true,true,false)
    stepWizardInstance.current.lastStep()
              setTimeout(function() {
                setotpshowres(false)
                setotpshowresmobile(false)
               }, 5000); // 5000 milliseconds (5 seconds) delay
            }
          } catch (error) {
            setotploading(false)
            setinvalidotp(true)
          //  console.log(error.response.data.error)
           setTimeout(function() {
            setinvalidotp(false)
            setotpshowresmobile(false)
           }, 5000); // 5000 milliseconds (5 seconds) delay
          }
    }
    
  }
}

const [allreadyemail,setallreadyemail] = useState(false)
const [allreadyemailmsg,setallreadyemailmsg] = useState('')

const sendotp = async (idstatus) =>{
  if(emailID !== ''){
    const jsondata = {
      emailID:emailID,
    }
        try {
          setotploading(true)
          let url = `${process.env.REACT_APP_API_URL}api/cus_send-otp/`;
          const response = await axios.post(url, jsondata);
          // console.log("xxxxxxxxxxxxxxxxxxxxx",response);
          if(response.status == 201 || response.status == 200){
            // setshowsuperotp(true)
            setsendotpres(true)
            setotpnext(true)
            setotploading(false)
            setallreadyemail(false)
            setallreadyemailmsg("")

            if(idstatus == 1){
              sendotpmobile()
            }else{
              setshowsuperotp(true)
            }
            setTimeout(function() {
              setsendotpres(false)
             }, 5000); // 5000 milliseconds (5 seconds) delay
          }
        } catch (error) {
        //  console.log(error)
        setallreadyemail(true)
        setallreadyemailmsg("Email ID already exists.")
         setotploading(false)

        }
  }
}
const [allreadynumber,setallreadynumber] = useState(false)
const [allreadynumbermsg,setallreadynumbermsg] = useState('')
const sendotpmobile = async () =>{

  if(mobile_no !== '' && mobile_no !== undefined && mobile_no !== null){
    const jsondata = {
      phone:mobile_no,
      fname:fname,
    }
        try {
          setotploading(true)
          let url = `${process.env.REACT_APP_API_URL}api/send-otp-change_phone/`;
          const response = await axios.post(url, jsondata);
          // console.log("xxxxxxxxxxxxxxxxxxxxx",response);
          if(response.status == 201 || response.status == 200){
            setshowsuperotp(true)
            setsendotpresmobile(true)
            setallreadynumber(false)
         setallreadynumbermsg("")
            setotpnextmobile(true)
            setotploading(false)
            setallreadynumber(false)
         setallreadynumbermsg("")
            setTimeout(function() {
              setsendotpresmobile(false)

             }, 5000); // 5000 milliseconds (5 seconds) delay
          }
        } catch (error) {
        //  console.log(error)
         setotploading(false)
         setallreadynumber(true)
         setallreadynumbermsg("Mobile number already exists.")

        }
  }
}



  const countries = csc.getAllCountries();

  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.id,
    ...country
  }));
  
  const defaultValue_doc = updatedCountries.find((option) => option.label === doccountry);
  const defaultValue = updatedCountries.find((option) => option.label === country);
  let defaultste;
  let defaultrelste;
  let defautcity;
  let defautrealcity;
  let testone;

  if(defaultValue){
     defaultste = csc.getStatesOfCountry(defaultValue.value).map((state) => ({ label: state.name, value: state.id, ...state }));
     defaultrelste = defaultste.find((option) => option.label === state);
    }
     if(defaultrelste){
       defautcity = csc.getCitiesOfState(defaultrelste.value).map((city) => ({ label: city.name, value: city.id, ...city }));
       defautrealcity = defautcity.find((option) => option.label === city);
      }


  const updatedStates = (countryId) =>
    csc.getStatesOfCountry(countryId)
    .map((state) => ({ label: state.name, value: state.id, ...state }));
  const updatedCities = (stateId) =>
  
    csc.getCitiesOfState(stateId)
    .map((city) => ({ label: city.name, value: city.id, ...city }));

     if(defaultrelste){
       defautcity = csc.getCitiesOfState(defaultrelste.value).map((city) => ({ label: city.name, value: city.id, ...city }));
       defautrealcity = defautcity.find((option) => option.label === city);
       testone = defautcity.find((option) => option.label === "shre");
      //  console.log("355544wertyyy",defautrealcity)
      //  console.log("355544",testone)


      }


  function generateUniqueRandomNumber() {
    return Math.floor(Math.random() * 10000) + Date.now();
  }
  const randomNumber = generateUniqueRandomNumber();
  const documentverify = async () => {
  if(documenttype == "defualt" || documenttype == undefined || documentexpiry == undefined || documentimage == undefined || documentissue == undefined || idnumber == undefined){
    setdocumenterror(true)
  }else{
    setdocumentloader(true)
    const boolenvalue = true;
    const reason = 'using this api in kfc project'
    const data = {
      "reference_id":`REF${randomNumber}`,
      "document_type":documenttype,
      "id_number": idnumber,
      "consent": boolenvalue,
      "consent_purpose":reason
    }
    try {
      const config = {
        headers: {
          accept:'application/json',
          Authorization: `Bearer ${gettokinval.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}api/search/`;
      const response = await axios.post(url, data, config);
      if(response.data.msg){
        setdocumentloader(false)
        setdocumenterrorres(true)
        setidno(2)
        setTimeout(function() {
          setidno(0)
         }, 5000);
      }else{
        setdocumentloader(false)
        const result = JSON.parse(response.data);
        setdocumentresponse(JSON.parse(response.data))
        // console.log("dfghkdfgggkajaykkk",result.status);
        // console.log("dfghkdfgggkajaykkk",result.data.message);
  
        if(documentresponse.status === "SUCCESS"){
          setdocumentloader(false)
          setidno(1)
          setTimeout(function() {
            setidno(0)
           }, 5000); // 5000 milliseconds (5 seconds) delay
          setdocumentloader(false)
  
        }
       
      }
    } catch (error) {
  // console.log("we",error)
  setdocumentloader(false)
    }
  
  
  
  }
  }



  // capture image from webcam start here

// const captureimage = () =>{
//   setsandphoto(pickimg.current.getScreenshot())
//   setdisplayphoto(pickimg.current.getScreenshot())
//   sethideorshow(!hideorshow)
// }
// capture image from webcam end here

// fetch user details ----->

let gettokenvalue = gettoken()
  useEffect(() => {
    if (gettokenvalue.Checker == false && gettokenvalue.Approver === false && gettokinval.superuser === false) {
      nvg('/Customerlist/0');
    }else{
   
    }
  })


// const handleImageChange11 = (event) => {
//   const file = event.target.files[0];
//   setcr_thumb(file);

//   if (file) {
//     const reader = new FileReader();

//     reader.addEventListener('load', () => {
//       setthumbimg2(reader.result);
//     });

//     reader.readAsDataURL(file);
//   }
// };

const documentsecond = [
  {
    id:1,
    name:'VISA',
    value:'VISA'
  },
  {
    id:2,
    name:'RESIDENT PERMIT',
    value:'RESIDENT_PERMIT'
  },
  {
    id:3,
    name:'REFUGEE CARD',
    value:'REFUGEE_CARD'
  },
];
const handleImageChange11 = () => {
  setthumbimg2('')
  setcr_thumb('');
  setansithumbimg2('')
  setrtferror(true)
  setTimeout(() => {
  const client = new CaptureFinger();
 setthumbimg2(client.data.BitmapData)
  setcr_thumb(client.data.BitmapData);
  setansithumbimg2(client.data.AnsiTemplate)
  setrtferror(false)
}, 1000);
};
// const handleImageChange12 = (event) => {
//   const file = event.target.files[0];
//   setcr_index_finger(file);

//   if (file) {
//     const reader = new FileReader();

//     reader.addEventListener('load', () => {
//       setrifimg2(reader.result);
//     });

//     reader.readAsDataURL(file);
//   }
// };
const handleImageChange12 = () => {
  setrifimg2('')
  setcr_index_finger('');
  setansirifimg2('')
  setriferror(true)
  setTimeout(() => {
  const client = new CaptureFinger();
  setrifimg2(client.data.BitmapData)
  setcr_index_finger(client.data.BitmapData);
  setansirifimg2(client.data.AnsiTemplate)
  setriferror(false)
}, 1000);
};
// const handleImageChange13 = (event) => {
//   const file = event.target.files[0];
//   setcr_middle_finger(file);

//   if (file) {
//     const reader = new FileReader();

//     reader.addEventListener('load', () => {
//       setrmfimg2(reader.result);
//     });

//     reader.readAsDataURL(file);
//   }
// };
const handleImageChange13 = () => {
  setrmfimg2('')
  setcr_middle_finger('')
  setansirmfimg2('')
  setrmferror(true)
  setTimeout(() => {
  const client = new CaptureFinger();
  setrmfimg2(client.data.BitmapData)
  setcr_middle_finger(client.data.BitmapData)
  setansirmfimg2(client.data.AnsiTemplate)
  setrmferror(false)
}, 1000);
};
// const handleImageChange14 = (event) => {
//   const file = event.target.files[0];
//   setcr_ring_finger(file);

//   if (file) {
//     const reader = new FileReader();

//     reader.addEventListener('load', () => {
//       setrrfimg2(reader.result);

//     });

//     reader.readAsDataURL(file);
//   }
// };
const handleImageChange14 = () => {
  setrrfimg2('')
  setcr_ring_finger('')
  setansirrfimg2('')
  setrrferror(true)
  setTimeout(() => {
  const client = new CaptureFinger();
  setrrfimg2(client.data.BitmapData)
  setcr_ring_finger(client.data.BitmapData)
  setansirrfimg2(client.data.AnsiTemplate)
  setrrferror(false)
}, 1000);
};
// const handleImageChange15 = (event) => {
//   const file = event.target.files[0];
//   setcr_little_finger(file);

//   if (file) {
//     const reader = new FileReader();

//     reader.addEventListener('load', () => {
//       setrlfimg2(reader.result);
//     });

//     reader.readAsDataURL(file);
//   }
// };
const handleImageChange15 = () => {
  setrlfimg2('')
  setcr_little_finger('')
  setansirlfimg2('')
  setrlferror(true)
  setTimeout(() => {
  const client = new CaptureFinger();
  setrlfimg2(client.data.BitmapData)
  setcr_little_finger(client.data.BitmapData)
  setansirlfimg2(client.data.AnsiTemplate)
  setrlferror(false)
}, 1000);
};
// const handleImageChange16 = (event) => {
//   const file = event.target.files[0];
//   setcl_thumb(file);

//   if (file) {
//     const reader = new FileReader();

//     reader.addEventListener('load', () => {
//       setltfimg2(reader.result);

//     });

//     reader.readAsDataURL(file);
//   }
// };
const handleImageChange16 = () => {
  setltfimg2('')
  setcl_thumb('')
  setansiltfimg2('')
  setltferror(true)
  setTimeout(() => {
  const client = new CaptureFinger();
  setltfimg2(client.data.BitmapData)
  setcl_thumb(client.data.BitmapData)
  setansiltfimg2(client.data.AnsiTemplate)
  setltferror(false)
}, 1000);
};
// const handleImageChange17 = (event) => {
//   const file = event.target.files[0];
//   setcl_index_finger(file);

//   if (file) {
//     const reader = new FileReader();

//     reader.addEventListener('load', () => {
//       setlifimg2(reader.result);
//     });

//     reader.readAsDataURL(file);
//   }
// };
const handleImageChange17 = () => {
  setlifimg2('')
  setcl_index_finger('')
  setansilifimg2('')
  setliferror(true)
  setTimeout(() => {
  const client = new CaptureFinger();
  setlifimg2(client.data.BitmapData)
  setcl_index_finger(client.data.BitmapData)
  setansilifimg2(client.data.AnsiTemplate)
  setliferror(false)
}, 1000);
};
// const handleImageChange18 = (event) => {
//   const file = event.target.files[0];
//   setcl_middle_finger(file);

//   if (file) {
//     const reader = new FileReader();

//     reader.addEventListener('load', () => {
//       setlmfimg2(reader.result);
//     });

//     reader.readAsDataURL(file);
//   }
// };
const handleImageChange18 = () => {
  setlmfimg2('')
  setcl_middle_finger('')
  setansilmfimg2('')
  setlmferror(true)
  setTimeout(() => {
  const client = new CaptureFinger();
  setlmfimg2(client.data.BitmapData)
  setcl_middle_finger(client.data.BitmapData)
  setansilmfimg2(client.data.AnsiTemplate)
  setlmferror(false)
}, 1000);
};
// const handleImageChange19 = (event) => {
//   const file = event.target.files[0];
//   setcl_ring_finger(file);

//   if (file) {
//     const reader = new FileReader();

//     reader.addEventListener('load', () => {
//       setlrfimg2(reader.result);
//     });

//     reader.readAsDataURL(file);
//   }
// };

const changedocument = (e) =>{
  // setdocumentimageback2(null);
  // setModifiedImagenumbertwo(null);
  // setdocumentimagebacktwo(null);
  // setModifiedImage(null);
  if(e == "india"){
    setdocumenttype("PAN");
    setnotapplicable(true)
  }else{
    setdocumenttype("PASSPORT");
    setnotapplicable(false)
  }
  }


const handleImageChange19 = () => {
  setlrfimg2('')
  setcl_ring_finger('')
  setansilrfimg2('')
  setlrferror(true)
  setTimeout(() => {
  const client = new CaptureFinger();
  setlrfimg2(client.data.BitmapData)
  setcl_ring_finger(client.data.BitmapData)
  setansilrfimg2(client.data.AnsiTemplate)
  setlrferror(false)
}, 1000);
};
// const handleImageChange20 = (event) => {
//   const file = event.target.files[0];
//   setcl_little_finger(file);

//   if (file) {
//     const reader = new FileReader();

//     reader.addEventListener('load', () => {
//       setllfimg2(reader.result);
//     });

//     reader.readAsDataURL(file);
//   }
// };

const handleImageChange20 = () => {
  setllfimg2('')
  setcl_little_finger('')
  setansillfimg2('')
  setllferror(true)
  setTimeout(() => {
  const client = new CaptureFinger();
  setllfimg2(client.data.BitmapData)
  setcl_little_finger(client.data.BitmapData)
  setansillfimg2(client.data.AnsiTemplate)
  setllferror(false)
}, 1000);
};


const handleImageChange24 = (event) => {
  const file = event.target.files[0];
  setdocumentimageback(file);

  if (file) {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      setdocumentimagebacktwo(reader.result);

    });

    reader.readAsDataURL(file);
  }
};

const changefrontimg = (e) =>{
  // console.log("qqqqqqqqqqqqqqqq",URL.createObjectURL(e))

  const reader = new FileReader();
  reader.readAsDataURL(e);
  reader.onload = () => {
    // console.log("oooooo",reader.result)
    if(documenttype == 'AADHAAR'){
      const modifiedImageUrl = modifyImage(reader.result,varx,vary);
      setModifiedImage(modifiedImageUrl);
      setAadhaarImage(reader.result)
    }else{
      setModifiedImage(reader.result);
    }
    
  };
}


const changebackimg = (e) =>{



  const reader = new FileReader();
  reader.readAsDataURL(e);
  reader.onload = () => {
    // console.log("oooooo",reader.result)

    if(documenttype == 'AADHAAR'){
      const modifiedImageUrl = backmodifyImage(reader.result,varxtwo,varytwo);
      setdocumentimagebacktwo(modifiedImageUrl);
      setAadhaarImagetwo(reader.result)
    }else{
      setdocumentimagebacktwo(reader.result);
  
    }
  }
 

  // setdisplayphototwo(pickimgtwo.current.getScreenshot())
}



const twochangebackimg = (e) =>{

  const reader = new FileReader();
  reader.readAsDataURL(e);
  reader.onload = () => {
    // console.log("oooooo",reader.result)
    
  if(documenttypetwo == 'AADHAAR'){
    const modifiedImageUrl = backmodifyImage3(reader.result,varxtwo,varytwo);
    setdocumentimageback2(modifiedImageUrl);
    setAadhaarback2(reader.result)
  }else{
    setdocumentimageback2(reader.result);

  }
  }
  // setdisplayphototwo(pickimgtwo.current.getScreenshot())
}

const twochangefrontimg = (e) =>{
  const reader = new FileReader();
  reader.readAsDataURL(e);
  reader.onload = () => {
    // console.log("oooooo",reader.result)
    
    if(documenttypetwo == 'AADHAAR'){
      const modifiedImageUrl = modifyImagethree(reader.result,varx,vary);
      setModifiedImagenumbertwo(modifiedImageUrl);
      setAadhaarImage2(reader.result)
    }else{
      setModifiedImagenumbertwo(reader.result);
    }

  }
}

const [getdata,setgetdata] = useState({})
const [accountdata,setaccountdata] = useState([])
const [addaccount, setaddaccount] = useState(false);
const [accountno, setaccountno] = useState('');
const [ifsccode, setifsccode] = useState('');
const [accountname, setaccountname] = useState(null);
const [alreadycreated, setalreadycreated] = useState(false);
  useEffect(() => {
    async function accountinfo() {

      const config = {
        headers: {
          Authorization: `Bearer ${gettokinval.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}api/bank-accounts/get_by_id/${id}/`;
      const response = await axios.get(url, config);
      setaccountdata(response.data)
console.log(" lll;;;;;;",response)

if(response.data[0].beneficiaryAccount){
  setaddaccount(true);
  setalreadycreated(true)
  setaccountverified(true)
  setaccountno(response.data[0].beneficiaryAccount);
  setifsccode(response.data[0].beneIFSC);
  setaccountname(response.data[0].beneName);
  console.log("8900000",accountno,ifsccode,accountname)
  console.log("oppp")
}else{
  setaddaccount(false);
}
    }



    async function fetchOne() {

      const config = {
        headers: {
          Authorization: `Bearer ${gettokinval.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}api/customer/${id}/`;
      const response = await axios.get(url, config);
      setsrtloader(false)
      setgetdata(response.data)
      setaddress(response.data.address)
      setemailID(response.data.emailID)
      setmobile_no(response.data.mobile_no)
      setlname(response.data.lname)
      setfname(response.data.fname)
      setcountry(response.data.country)
      setdoccountry(response.data.country_doc2)
      setstate(response.data.state)
      setcity(response.data.city)
      setdob(response.data.dob)
      if(response.data.Form_60_image !== null){
        setdisplaydocument(response.data.Form_60_image)
      }
      setidnumbertwo(response.data.document_no2)
      setdocumenttypetwo(response.data.document_type2)
      if(response.data.document_type == "AADHAR CARD" || response.data.document_type == "AADHAAR" && response.data.nationality == "india" ){
        setnotapplicable(true)
      }else{
        setnotapplicable(false)
        setdocumentimagebacktwo(response.data.docImg_back)
         setdocumentimageback(response.data.docImg_back)
        setdocumenttype(response.data.document_type)
        setidnumber(response.data.document_no)
        setdocumentexpiry(response.data.expiry_date)
        setdocumentissue(response.data.issue_date)
        setModifiedImage(response.data.docImg_front)
        setAadhaarImage(response.data.docImg_front)


        if(response.data.docImg_front == "null" || response.data.docImg_front == null || response.data.docImg_front == ''){
          setdoc1(false)
          setviewdoc1(response.data.Doc_1_pdf)
        }
      }

      

      if(response.data.docImg_front2 == "null" || response.data.docImg_front2 == null || response.data.docImg_front2 == ''){
        setdoc2(false)
        setviewdoc2(response.data.Doc_2_pdf)
      }
      setdocumentexpirytwo(response.data.expiry_date2)
      setdocumentissuetwo(response.data.issue_date2)
      setpincode(response.data.pincode)
      setffn(response.data.fathersFullNm)
      setdefaultv(response.data.company)
      setdefaultvtwo(response.data.location)
      setgender(response.data.gender)
      setdisplayphoto(response.data.photograph)
      setllfimg2(response.data.l_little_finger)
      setlrfimg2(response.data.l_ring_finger)
      setlmfimg2(response.data.l_middle_finger)
      setlifimg2(response.data.l_index_finger)
      setltfimg2(response.data.l_thumb)
      setrlfimg2(response.data.r_little_finger)
      setrrfimg2(response.data.r_ring_finger)
      setrmfimg2(response.data.r_middle_finger)
      setrifimg2(response.data.r_index_finger)
      setthumbimg2(response.data.r_thumb)
      setcnationality(response.data.nationality)
      setAadhaarback2(response.data.docImg_back2)
      setdocumentimageback2(response.data.docImg_back2)
      setAadhaarImage2(response.data.docImg_front2)
      setModifiedImagenumbertwo(response.data.docImg_front2)

      setstatus(response.data.status)
    }
    accountinfo();
    fetchOne();
  }, []);
// fetch user details ----->

  useEffect(() => {
    async function fetchData() {

        const config = {
            headers: {
          Authorization: `Bearer ${gettokinval.access}`,
        },
    };
    let url = `${process.env.REACT_APP_API_URL}api/active_company/`;
      const response = await axios.get(url, config);
      setData(response.data);
    }

    async function fetchDatatwo() {

      const config = {
          headers: {
        Authorization: `Bearer ${gettokinval.access}`,
      },
  };
  let url = `${process.env.REACT_APP_API_URL}api/active_location/`;
    const response = await axios.get(url, config);
    setDatatwo(response.data);
  }
    fetchData();
    fetchDatatwo();
}, [setData, setDatatwo]);

// end here dropdown 

  const [balno,setbalno] = useContext(Balance)
 

const [baler,setbaler] = useState(false)

// submit customer form 


async function minsubmitform(numberststus,ischanged,emailstatus,wizardform){
  console.log("new werty")
  if(balno > 10){
    // console.log("check edit 2" )
        // if(registerpro == true && registerpromobile == true){
        // if(registerpromobile == true){
          // console.log('check 3')
   
          console.log("numberstatu555555s",numberststus,"emailid",emailstatus,"wwee wzierd ",stepWizardInstance)
          if(numberststus == true && emailstatus == false){
            console.log("mobile respose")
            if(stepWizardInstance.current.state.activeStep !== 0){
              if(rightnumberoffingers12 == 0 && gettokinval.email !== "test@gmail.com"){
                setfingerperror12(true)
                setrigthfingerserror12('Verify atleast One Finger Print')
              }else{
                setrigthfingerserror12('')
                // if(leftnumberoffingers12 == 0 && gettokinval.email !== "test@gmail.com"){
                //   setfingerperror12(true)
                //   setrigthfingerserror12('Capture atleast One Finger in Your Left Hand')
                //   }else{
          
                    setfingerperror12(false)
                //     setrigthfingerserror12('')
                if(state == undefined || state == '' || city == undefined || city == '' || city == [] || state == []){
                  if(state == undefined || state == ''){
                    setstateerror('State is Required')
                  }else{
                    setstateerror('')
                  }
                  if(city == undefined || city == ''){
                    setcityerror('City is Required')
                  }else{
                    setcityerror('')
                  }
                }else{
                setloading(true)
                const formdata = new FormData();
                formdata.append("fname",fname);
                formdata.append("lname",lname);
                formdata.append("nationality",cnationality);
                // formdata.append("mobile_no",mobile_no.toString());
                formdata.append("gender",gender);
                if(emailID == undefined || emailID == '' || emailID == null){

                }else{
                  formdata.append("emailID",emailID);
                }
                formdata.append("fathersFullNm",ffn);
           
            formdata.append('status',status)
            
            // if(doc2 == true){
              formdata.append("docImg_front2", modifiedImagenumbertwo);
              formdata.append("docImg_back2", documentimageback2);
            // }else{
            //   if( documentpdf2  !== null){
      
            //     formdata.append("docImg_front2", "");
            //     formdata.append("docImg_back2", ""); 
            //     formdata.append("Doc_2_pdf", documentpdf2);
            //   }
            // }
            
            
            
            formdata.append('document_type2',documenttypetwo)
            formdata.append('document_no2',idnumbertwo)
            
            if(documentexpirytwo != undefined || documenttypetwo == 'DRIVING_LICENSE' || documenttypetwo == "RESIDENT_PERMIT" || documenttypetwo == "VISA" || documenttypetwo == "REFUGEE_CARD" || documenttypetwo == 'PASSPORT'){
              formdata.append('expiry_date2',documentexpirytwo)
            }else{
              // console.log('not expiry date')
            }
            if(documentissuetwo != undefined || documenttypetwo == 'DRIVING_LICENSE' || documenttypetwo == "RESIDENT_PERMIT" || documenttypetwo == "VISA" || documenttypetwo == "REFUGEE_CARD" || documenttypetwo == 'PASSPORT'){
              formdata.append('issue_date2',documentissuetwo)
            }else{
              // console.log('not issue date')
            }
            if(cnationality == "india"){
              if(notapplicable == false){
                formdata.append('document_type',documenttype)
                formdata.append('document_no',idnumber)
      
                if(doc1 == true){
                  formdata.append("docImg_front", modifiedImage);
                  formdata.append("docImg_back", documentimagebacktwo);
                }else{
                  // formdata.append("docImg_front", '');
                  // formdata.append("docImg_back", '');
                  formdata.append("docImg_front", modifiedImage);
                  formdata.append("docImg_back", documentimagebacktwo);
                  if(documenttype !== 'PAN' && documenttype !== 'PASSPORT'){
                    
                    formdata.append("Doc_1_pdf", documentpdf1);
                  }
      
                }
                
                if(documentexpiry != undefined || documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT'){
                  formdata.append('expiry_date',documentexpiry)
                }else{
                  // console.log('not expiry date')
                }
                if(documentissue != undefined || documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT'){
                  formdata.append('issue_date',documentissue)
                }else{
                  // console.log('not issue date')
                }
              }else{
                formdata.append('document_type',"AADHAAR")
                if(displaydocument !== ''){
                  formdata.append("Form_60_image", displaydocument);
                }
          
                if(form60 !== null){
                  formdata.append("Form_60_pdf", form60);
                }
              }  
            }else{
              formdata.append('document_type',documenttype)
              formdata.append('document_no',idnumber)
              formdata.append("docImg_front", modifiedImage);
              formdata.append("docImg_back", documentimagebacktwo);
              
              if(documentexpiry != undefined || documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT'){
                formdata.append('expiry_date',documentexpiry)
              }else{
                // console.log('not expiry date')
              }
              if(documentissue != undefined || documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT'){
                formdata.append('issue_date',documentissue)
              }else{
                // console.log('not issue date')
              }
            }
         
           
            if(doccountry){
            if(doccountry.label){
              formdata.append("country_doc2", doccountry.label);
            }}
            if(country.label){
              formdata.append("country", country.label);
            }
            if(state.label){
              formdata.append("state", state.label);
            }
            if(city.label){
              formdata.append("city", city.label);
            }
                formdata.append("dob", dob);
                formdata.append("pincode", pincode);
                formdata.append("address", address);
                // if(sandphoto !== ''){
                  formdata.append('photograph',displayphoto)
                // }
                // if(cr_thumb !== ''){
                //   formdata.append("r_thumb", cr_thumb);
                //   formdata.append("ansi_r_thumb", ansithumbimg2);
                // }
                // if(cr_index_finger !== ''){
                //   formdata.append("r_index_finger", cr_index_finger);
                //   formdata.append("ansi_r_index_finger", ansirifimg2);
                // }
                // if(cr_middle_finger !== ''){
                //   formdata.append("r_middle_finger", cr_middle_finger);
                //   formdata.append("ansi_r_middle_finger", ansirmfimg2);
                // }
                // if(cr_ring_finger !== ''){
                //   formdata.append("r_ring_finger", cr_ring_finger);
                //   formdata.append("ansi_r_ring_finger", ansirrfimg2);
                // }
                // if(cr_little_finger !== ''){
                //   formdata.append("r_little_finger", cr_little_finger);
                //   formdata.append("ansi_r_little_finger", ansirlfimg2);
                // }
                // if(cl_thumb !== ''){
                //   formdata.append("l_thumb", cl_thumb);
                //   formdata.append("ansi_l_thumb", ansiltfimg2);
                // }
                // if(cl_index_finger !== ''){
                //   formdata.append("l_index_finger", cl_index_finger);
                //   formdata.append("ansi_l_index_finger", ansilifimg2);
                // }
                // if(cl_middle_finger !== ''){
                //   formdata.append("l_middle_finger", cl_middle_finger);
                //   formdata.append("ansi_l_middle_finger", ansilmfimg2);
                // }
                // if(cl_ring_finger !== ''){
                //   formdata.append("l_ring_finger", cl_ring_finger);
                //   formdata.append("ansi_l_ring_finger", ansilrfimg2);
                // }
                // if(cl_little_finger !== ''){
                //   formdata.append("l_little_finger", cl_little_finger);
                //   formdata.append("ansi_l_little_finger", ansillfimg2);
                // }
                setgrandloading(true)
                try {
            
                  const config = {
                    headers: {
                      Authorization: `Bearer ${gettokinval.access}`,
                    },
                  };
                  let url = `${process.env.REACT_APP_API_URL}api/customer/${id}/`;
                  const response = await axios.patch(url, formdata, config);
                    setcustresponse(response);



                    if(response.status == 200 || response.status == 201){
                               
                      const formdatabio = new FormData();

                      if(cr_thumb !== ''){
                       formdatabio.append("r_thumb", cr_thumb);
                       formdatabio.append("ansi_r_thumb", ansithumbimg2);
                     }
                     if(cr_index_finger !== ''){
                       formdatabio.append("r_index_finger", cr_index_finger);
                       formdatabio.append("ansi_r_index_finger", ansirifimg2);
                     }
                     if(cr_middle_finger !== ''){
                       formdatabio.append("r_middle_finger", cr_middle_finger);
                       formdatabio.append("ansi_r_middle_finger", ansirmfimg2);
                     }
                     if(cr_ring_finger !== ''){
                       formdatabio.append("r_ring_finger", cr_ring_finger);
                       formdatabio.append("ansi_r_ring_finger", ansirrfimg2);
                     }
                     if(cr_little_finger !== ''){
                       formdatabio.append("r_little_finger", cr_little_finger);
                       formdatabio.append("ansi_r_little_finger", ansirlfimg2);
                     }
                     if(cl_thumb !== ''){
                       formdatabio.append("l_thumb", cl_thumb);
                       formdatabio.append("ansi_l_thumb", ansiltfimg2);
                     }
                     if(cl_index_finger !== ''){
                       formdatabio.append("l_index_finger", cl_index_finger);
                       formdatabio.append("ansi_l_index_finger", ansilifimg2);
                     }
                     if(cl_middle_finger !== ''){
                       formdatabio.append("l_middle_finger", cl_middle_finger);
                       formdatabio.append("ansi_l_middle_finger", ansilmfimg2);
                     }
                     if(cl_ring_finger !== ''){
                       formdatabio.append("l_ring_finger", cl_ring_finger);
                       formdatabio.append("ansi_l_ring_finger", ansilrfimg2);
                     }
                     if(cl_little_finger !== ''){
                       formdatabio.append("l_little_finger", cl_little_finger);
                       formdatabio.append("ansi_l_little_finger", ansillfimg2);
                     }
                         const config = {
                           headers: {
                             Authorization: `Bearer ${gettokinval.access}`,
                           },
                         };
                         let url123 = `${process.env.REACT_APP_API_URL}api/customer/${id}/`;
                         const responsebio = await axios.patch(url123, formdatabio, config);




                    if(responsebio.status == 200 || responsebio.status == 201){
                      const formdatathree = new FormData();
                      // if(idno == 1){
                        formdatathree.append("amount", 10);
                        formdatathree.append("description", `Edit Customer  - ${gettokinval.id} - ${gettokinval.first_name} ${gettokinval.last_name}`);
      
                      // }
                      // if(idno == 2){
                      //   formdatathree.append("amount", 944);
                      // }
                      // if(idno == 3){
                      //   formdatathree.append("amount", 1180);
                      // }
                      // if(idno == 4){
                      //   formdatathree.append("amount", 1416);
                      // }
                      let url = `${process.env.REACT_APP_API_URL}api/wallet/make_purchase/`;
                      const dedectresponse = await axios.post(
                        url,
                        formdatathree,
                        config
                      );
                      if(dedectresponse.data.id){
      
                        console.log("change number inside min sub",changenumber)
                        // if(ischanged == true){ old condition
                          
                        if(mobile_no !== getdata.mobile_no){
                        console.log("change number inside min sub must run",changenumber)
                          
                          const config = {
                            headers: {
                              accept:'application/json',
                              Authorization: `Bearer ${gettokinval.access}`,
                            },
                          };
                          const formdatatwo = new FormData();
                          formdatatwo.append("new_mobile_no",mobile_no);
                           let url2 = `${process.env.REACT_APP_API_URL}api/customer/${id}/change-mobile-no/`;
                           const response = await axios.put(url2,formdatatwo,config);
                           console.log("super response",response);
                        }
      
                        if(addaccount == true){
      
                          // const formdatafive = new FormData();
                          //   formdatafive.append("beneficiaryAccount", accountno);
                          //   formdatafive.append("beneficiaryMobile", mobile_no);
                          //   formdatafive.append("Customer", response.data.cus_id);
                          //   formdatafive.append("beneName", accountname);
                          //   formdatafive.append("beneIFSC", ifsccode);
                          if(alreadycreated == true){
                            const formdatafive = {
                              "beneficiaryMobile": mobile_no,
                              "beneficiaryAccount": accountno,
                              "beneficiaryName": accountname,
                              "customer": response.data.cus_id,
                              "beneIFSC":ifsccode,
                              "beneficiaryIFSC":ifsccode,
                              "beneName":accountname
                          };
                            let url23 = `${process.env.REACT_APP_API_URL}api/bank-accounts/`;
                            const Bankaccountinfo = await axios.post(
                              url23,
                              formdatafive,
                              config
                            );
        
        
                            console.log("Bankaccountinfo is here",Bankaccountinfo)
                          }else{
                             const formdatafive = {
                            "beneficiaryMobile": mobile_no,
                            "beneficiaryAccount": accountno,
                            "beneficiaryName": accountname,
                            "customer": response.data.cus_id,
                            "beneIFSC":ifsccode,
                            "beneficiaryIFSC":ifsccode,
                            "beneName":accountname
                        };
                          let url23 = `${process.env.REACT_APP_API_URL}api/bank-accounts/`;
                          const Bankaccountinfo = await axios.post(
                            url23,
                            formdatafive,
                            config
                          );
      
      
                          console.log("Bankaccountinfo is here",Bankaccountinfo)
                        }
      
                          }
                          
  
                        setloading(false)
                     seterrorclass(false);
      
                     setgrandloading(false)
                     nvg("/Customerlist/2")
                    //  window.location.reload()
                      }
                     }
                    }
                    seterrorclass(false)
                    setloading(false)
                    setgrandloading(false)
                } catch (error) {

                  if(error.code == "ERR_NETWORK"){
                    setmegaerror(true)
                    setmegaerrormsg("Request Entity Too Large. Please Upload your Images less Than 170kb");
                    setTimeout(() => {
                      setmegaerror(false)
                    }, 7000);
                  }else{
                  setcustresponse({statusone:1,message:'Something Went Wrong Try again'})
                  seterrorcls(error.response.data)
                  }
                  seterrorclass(true)
                  setloading(false)
                  setgrandloading(false)
            
                }
                
                
                }
              }
            }else{
              setisrequired(true)
              stepWizardInstance.current.lastStep()
            }
            
            }else{
              console.log("send otp success")
              if(emailstatus == false){
                if(mobile_no !== getdata.mobile_no){
                  sendotpmobile()
                }
              }
              if(emailstatus == true){
                if(mobile_no !== getdata.mobile_no){
                  // sendotpmobile()
                  sendotp(1)
                }else{
                  sendotp(0)
                }
              }
              // sendotp()
            }
            // }
        
        // }
      // }else{
      //   setverifyyourotp(true)
      //   setTimeout(() => {
      //     setverifyyourotp(false)
      //   }, 7000);
      // }
    }else{
      setbaler(true)
      setTimeout(() => {
        setbaler(false)
      }, 7000);
    }
}

const [doberror,setdoberror] = useState(false);
  async function submitform(e) {
    e.preventDefault();
// console.log("check edit")
const currentDate = new Date();
const birthDate = new Date(dob);
const ageDifference = currentDate.getFullYear() - birthDate.getFullYear();
if (ageDifference < 18) {
  setdoberror(true)
}else{
  setdoberror(false)
if(addaccount == true){
  if(accountdata){

    console.log("erttyuu",accountdata)
  }else{
    console.log("mnbvcx",accountdata)
  }
  if(accountverified == true){
   setsubmitacc(false)
   if(passotp == true){
    if(emailchage == false){
      if(isformonecheck == true){ 
        minsubmitform(true,false,false,true)
      }else{
        minsubmitform(true,false,false,false)
      }
      
    }else{
      if(isformonecheck == true){ 
        minsubmitform(true,false,true,true)
      }else{
        minsubmitform(true,false,true,false)
      }
      
    }
  }else{
    if(emailchage == false){
      if(isformonecheck == true){ 
        minsubmitform(true,false,false,true)
      }else{
        minsubmitform(true,false,false,false)
      }
     
    }else{
      if(isformonecheck == true){
        minsubmitform(true,false,true,true)
       }else{
        minsubmitform(true,false,true,false)
      }
      
    }
  }
  }else{
   setsubmitacc(true)
   setsubmitaccmsg("Please Verify Your Account Details")
   setidno(222);
   setTimeout(() => {
     setidno(0);
   }, 6000);
  }
  
 }else{
  if(passotp == true){
    if(emailchage == false){
      if(isformonecheck == true){ 
        minsubmitform(true,false,false,true)
      }else{
        minsubmitform(true,false,false,false)
      }
    }else{
      if(isformonecheck == true){ 
        minsubmitform(true,false,true,true)
      }else{
        minsubmitform(true,false,true,false)
      }
      
    }
  }else{
    if(emailchage == false){
      if(isformonecheck == true){
        minsubmitform(false,false,false,true)
       }else{
        minsubmitform(false,false,false,false)
      }
      
    }else{
      if(isformonecheck == true){
        minsubmitform(false,false,true,true)
       }else{
        minsubmitform(false,false,true,false)
      }
      
    }
  }
 }
}
  }

  const [formdatao, setformDatao] = useState({});


  useEffect(() => {
    async function fetchBio() {
      const config = {
        headers: {
          Authorization: `Bearer ${gettokinval.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}api/agent-biometric/${gettokinval.id}/`;
      const response = await axios.get(url, config);
      // setfname(response.data.customer_fname)
      // setlname(response.data.customer_lname)
      // setsrtloader(false)
      setformDatao(response.data)
      setllfimg2sand(response.data.l_little_finger)
      setlrfimg2sand(response.data.l_ring_finger)
      setlmfimg2sand(response.data.l_middle_finger)
      setlifimg2sand(response.data.l_index_finger)
      setltfimg2sand(response.data.l_thumb)
      setrlfimg2sand(response.data.r_little_finger)
      setrrfimg2sand(response.data.r_ring_finger)
      setrmfimg2sand(response.data.r_middle_finger)
      setrifimg2sand(response.data.r_index_finger)
      setthumbimg2sand(response.data.r_thumb)

// console.log(response)

   
    }
    fetchBio();




    }, []);








    const [bankstatus, setbankstatus] = useState(false);
    const [backstatusmsg, setbackstatusmsg] = useState(null);
    const [accountbankerror,setaccountbankerror] = useState(false)
const [accountverified,setaccountverified] = useState(false)
// const [accountverified,setaccountverified] = useState(false)
const [bankerrorstatus,setbankerrorstatus] = useState(false)
const [bankerrorstatusmsg,setbankerrorstatusmsg] = useState("")

const compareStrings = (str1, str2) => {
  const length = Math.max(str1.length, str2.length);
  let matchCount = 0;

  for (let i = 0; i < length; i++) {
    const char1 = str1[i];
    const char2 = str2[i];

    if (char1 === char2) {
      matchCount++;
    }
  }

  const similarityPercentage = (matchCount / Math.max(str1.length, str2.length)) * 100;
  console.log("match string", similarityPercentage);
  return similarityPercentage;
};

    const verifybankaccount = async () =>{
      if(ifsccode !== "" && accountno !== "" && fname !== undefined && fname !== ""){
       console.log("success is here");
       setaccountbankerror(false)
       setgrandloading(true)
       const jsondata = {
        // "beneficiaryMobile": mobile_no.substring(3),
        "beneficiaryAccount": accountno,
        // "beneficiaryName": bfrname,
        "beneficiaryIFSC": ifsccode
    }
          try {
            let url = `${process.env.REACT_APP_API_URL}api/bank-verification/`;
            const response = await axios.post(url, jsondata);
            console.log("ddertx",response);
//             if(response.data.message){
//  setbankerrorstatus(true)
//  setgrandloading(false)
//  setbankerrorstatusmsg("Please Check Your Account Details Again")
//  setidno(300)
//  setTimeout(() => {
//   setidno(0)
//  }, 6000);
//             }else{
            if(response.data.result.active == "yes"){

console.log("67890")
setaccountname(response.data.result.bankTransfer.beneName)

var bname = response.data.result.bankTransfer.beneName;
var funame = `${fname}${lname}`;
// console.log("prin full name",funame,"get naem",bname);
var matchcount = compareStrings(funame, bname);
if(bname.toUpperCase().includes(fname.toUpperCase())){
// if(matchcount >= 70){
  console.log("dfghjkfd",accountname)
  setaccountverified(true)
  setbankstatus(true);
  setbackstatusmsg("Your Bank Account Verified Successfully");
  setidno(200)
  setgrandloading(false)
  setTimeout(() => {
    setidno(0)
  }, 7000);
}else{
  setshowerror(true)
  console.log("dfghjkfd",accountname)
  setaccountverified(true)
  setbankstatus(true);
  setbackstatusmsg("Your Bank Account Verified Successfully");
  setidno(200)
  setgrandloading(false)
  setTimeout(() => {
    setidno(0)
  }, 7000);
}

            }else{
              console.log("wertyuuiop",response);
              setbankerrorstatus(true)
               setgrandloading(false)
               setbankerrorstatusmsg(response.data.result.reason)
               setidno(300)
               setTimeout(() => {
                setidno(0)
               }, 6000);
            }
          // }
           
          } catch (error) {
          //  console.log(error)
          console.log("eroror here 555",error.response.data.error.message)
          setbankerrorstatus(true)
          setgrandloading(false)
          setbankerrorstatusmsg(error.response.data.error.message)
          setidno(300)
          setTimeout(() => {
           setidno(0)
          }, 6000);
          setaccountverified(false)
           

          }

      }else{
         setaccountbankerror(true)
         console.log("error is here");
      }
    }

  return (
    <div style={{ width: "100%" }}>
      {/* <Header /> */}
      <div className="dashboardcontent px-2">
        <div className="container-fuild px-2 desgin1">
          <div className="row bg-white ">
            <div className="col-lg-12 d-flex justify-content-between py-2" style={{ background: 'hsla(210, 85%, 32%, 0.2)', color: '#0C5398' }}>
              <p className="m-0 customfont" >Edit Customer</p>
              <div className="addnew d-block mb-2">
           {isrequired == true ? <button type="button" className="btn text-white closebtn" onClick={()=>{setaddaccount(!addaccount)}} style={{marginRight:"5px",textDecoration: "none", color: "white"}}>       
              {" "}
            { addaccount == true ? "- Add Account" : "+ Add Account"}     
          </button> : ''}
                <button className="btn text-white closebtn"><NavLink to='/customerlist/0' style={{ textDecoration: 'none', color: 'white' }}> x Close</NavLink></button>
              </div>
            </div>
          </div>
          <div
        className="col-12 py-1 px-2 d-flex justify-content-center" 
      >
        {otploading == true ? <Loadercomp size={70} /> : ''}
      
      </div>
            <div
        className="col-12 py-1 px-2 d-flex justify-content-end" 
      >
      
        <div class="alert alert-danger" role="alert"  style={{width:'100%',display:invalidotp == true ? 'block' : 'none'}}>
        Invalid OTP.
</div>
        <div class="alert alert-danger" role="alert"  style={{width:'100%',display:invalidotpmobile == true ? 'block' : 'none'}}>
        Invalid OTP.
</div>
        {/* <div class="alert alert-success" role="alert"  style={{width:'100%',display:sendotpres == true ? 'block' : 'none'}}>
        OTP sent to your Email Successfully.
</div> */}
        {/* <div class="alert alert-success" role="alert"  style={{width:'100%',display:sendotpresmobile == true ? 'block' : 'none'}}>
        OTP sent to your Mobile Successfully.
</div> */}
        <div class="alert alert-success" role="alert"  style={{width:'100%',display:otpshowres == true ? 'block' : 'none'}}>
        Email Verified Successfully.
</div>
        <div class="alert alert-success" role="alert"  style={{width:'100%',display:otpshowresmobile == true ? 'block' : 'none'}}>
        Mobile Number Verified Successfully.
</div>
<div class="alert alert-danger" role="alert"  style={{width:'100%',display:baler == true ? 'block' : 'none'}}>
        { baler == true ? "Your Wallet Balance is low please Recharge First." : ''}   
</div>
        <div class="alert alert-danger" role="alert"  style={{width:'100%',display:verifyyourotp == true ? 'block' : 'none'}}>
        {/* {registerpro == false ? registerpromobile == false ? "Please Verify Your Email and Phone No ." : "Please Verify Your Email."  : registerpromobile == false ? "Please Verify Your Phone No ." : ""}    */}
        {registerpromobile == false ? "Please Verify Your Phone No ." : ""}   
</div>
      </div>
        </div>
        {srtloader == true ? <div className="container-fuild bg-white"> <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"92%"}}>
        <div className='px-2'> <Loadercomp size={100} /></div>
        </div></div> : <div className="container-fuild pb-4 pt-3 px-2 bg-white">
          <form onSubmit={submitform}>
            <div
              className="row bg-white pb-4 round"
              style={{
                border: "1px solid #E0E0E0",
                margin: "10px 0px",
                borderRadius: "3px",
                position:'relative'
              }}
            >
              <StepWizard ref={stepWizardInstance}  onStepChange={handleStepChange}>
              <div className="row">
              <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      First Name <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      className="form-control"
                      value={fname}
                      placeholder="First Name"
                      onChange={(e) => {
                        setfname(e.target.value);
                      }}
                      required
                    />
                    {errorcls ? errorcls.fname ? <span style={{color:'red'}}> {errorcls.fname[0]} </span> : '' : ''}
                    {accountbankerror === true ? fname == "" || fname == undefined ? (
                      <span style={{ color: "red" }}>Please Enter your First Name </span>
                    ) : ( "") : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Last Name <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      value={lname != "undefined" ? lname :''}
                      onChange={(e) => {
                        setlname(e.target.value);
                      }}
                    
                    />
                    {errorcls ? errorcls.lname ? <span style={{color:'red'}}> {errorcls.lname[0]} </span> : '' : ''}
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label customw">
                      Father Name <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      value={ffn}
                      onChange={(e) => {
                        setffn(e.target.value);
                      }}
                    
                    />
                    {errorcls ? errorcls.fathersFullNm ? <span style={{color:'red'}}> {errorcls.fathersFullNm[0]} </span> : '' : ''}
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label customw">
                      Email Address <span style={{ color: "red" }}>*</span>{" "}
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="email"
                      className="form-control"
                      value={emailID}
                      placeholder="Email Address"
                      onChange={(e) => {
                        setemailID(e.target.value);
                      }}
                      required
                    />
                      {errorcls ? errorcls.emailID ? <span style={{color:'red'}}> {errorcls.emailID[0]} </span> : '' : ''}
                  </div>
                </div>
              </div> */}


              {/* <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Last Name <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      value={lname != "undefined" ? lname :''}
                      onChange={(e) => {
                        setlname(e.target.value);
                      }}
                    
                    />
                    {errorcls ? errorcls.lname ? <span style={{color:'red'}}> {errorcls.lname[0]} </span> : '' : ''}
                  </div>
                </div>
              </div> */}
              <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Gender <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    {/* <input type="text" className="form-control" placeholder='Gender' /> */}
                    {gender == "Male" ? <select
                      name=""
                      id=""
                      className="form-select"
                      value={gender}
                      onChange={(e) => {
                        setgender(e.target.value);
                      }}
                      aria-label="Default select example"
                    >
                      <option value="Male">male</option>
                      <option value="Female">female</option>
                      <option value="Others">others</option>
                    </select> : gender == "Female" ? <select
                      name=""
                      id=""
                      className="form-select"
                      value={gender}
                      onChange={(e) => {
                        setgender(e.target.value);
                      }}
                      aria-label="Default select example"
                    >
                      <option value="Female">female</option>
                      <option value="Male">male</option>
                      <option value="Others">others</option>
                    </select> : <select
                      name=""
                      id=""
                      className="form-select"
                      value={gender}
                      onChange={(e) => {
                        setgender(e.target.value);
                      }}
                      aria-label="Default select example"
                    >
                      <option value="Others">others</option>
                      <option value="Female">female</option>
                      <option value="Male">male</option>
                    </select>}
                    {errorcls ? errorcls.gender ? <span style={{color:'red'}}> {errorcls.gender[0]} </span> : '' : ''}
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 " style={{paddingTop:"28px"}}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Email 
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </label>
                  </div>
                  <div className="col-lg-9">
                    {/* <input
                      type="text"
                      className="form-control"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => {
                        setemail(e.target.value);
                      }}
                      required
                    /> */}
{otpnext == true ? <div className="input-group ">
  <input type="text" className="form-control"   value={emailID}
                      onChange={(e) => {
                        setemailID(e.target.value);
                        setemailchage(true);
                      }} placeholder="Email" />
  {/* <span className="input-group-text" onClick={sendotp} id="basic-addon2">RESEND OTP</span> */}
</div> : <div className="input-group ">
  <input type="text" className="form-control" style={{}}  value={emailID}
                      onChange={(e) => {
                        setemailID(e.target.value);
                        setemailchage(true)
                        setregisterpro(false);
                      }} placeholder="Email"   />
 {/* {registerpro == false ? <span className="input-group-text" onClick={sendotp} id="basic-addon2">SEND OTP</span> : <></>}  */}
</div>}

{allreadyemail ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {allreadyemailmsg}
                        </span>
                      ) : (
                        ""
                       )}
{errorcls ? (
                      errorcls.emailID ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.emailID[0] == '' ? '' : errorcls.emailID[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
            
                  </div>
                 
                </div>
              </div>
              
              <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      DOB <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="date"
                      className="form-control"
                      minLength={6}
                      maxLength={6}
                      value={dob}
                      placeholder="date"
                      onChange={(e) => {setdob(e.target.value)}}
                      required
                    />
                                        {doberror == true ? <span style={{color:'red'}}>You must be at least 18 years old.</span> : ''}
                    {errorcls ? (
                      errorcls.dob ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.dob[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
               {/* {otpnext == true ? <div className="col-md-6 px-2 " style={{paddingTop:"28px"}}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Email OTP <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    
{otpnext == true ? <div className="input-group ">
  <input type="number" className="form-control"   value={otp}
                      onChange={(e) => {
                        setotp(e.target.value);
                      }} placeholder="OTP" aria-label="Recipient's username" aria-describedby="basic-addon2" />
  <span className="input-group-text" onClick={verifyotp} id="basic-addon2">VERIFY OTP</span>
</div> : ''}


            
                  </div>
                 
                </div>
              </div> : ''}  */}
              {/* <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Mobile No. <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    
                    <PhoneInput
placeholder="Enter phone number"
className="form-control"
value={mobile_no}
onChange={setmobile_no}
required
/>
                    {errorcls ? errorcls.mobile_no ? <span style={{color:'red'}}> {errorcls.mobile_no[0]} </span> : '' : ''}
                  </div>
                </div>
              </div> */}

<div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Mobile No. <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    {/* <input
                      type="tel"
                      className="form-control"
                      maxLength={10}
                      minLength={10}
                      value={mobile_no}
                      placeholder="Mobile No"
                      onChange={(e) => {
                        setmobile_no(e.target.value.replace(/[^0-9]/gi, ""));
                      }}
                    /> */}




{otpnextmobile == true ? <div className="input-group ">
<PhoneInput
placeholder="Enter phone number"
className="form-control"
value={mobile_no}
onChange={(e)=>{setmobile_no(e);setpassotp(false);}}
required
/>
  {/* <span className="input-group-text" onClick={sendotpmobile} id="basic-addon2">RESEND OTP</span> */}
</div> : <div className="input-group ">
<PhoneInput
placeholder="Enter phone number"
className="form-control"
value={mobile_no}
onChange={(e)=>{setmobile_no(e);setregisterpromobile(false);setpassotp(false)}}
required
/>
 {/* {registerpromobile == false ? <span className="input-group-text" onClick={sendotpmobile} id="basic-addon2">SEND OTP</span> : <></>}  */}
</div>}





{/* <div className="input-group ">

<PhoneInput
placeholder="Enter phone number"
className="form-control"
value={mobile_no}
onChange={setmobile_no}
required
/>
<span className="input-group-text" onClick={verifyotp} id="basic-addon2">VERIFY OTP</span>
</div> */}
                    {errorcls ? (
                      errorcls.mobile_no ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.mobile_no[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                      {allreadynumber ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {allreadynumbermsg}
                        </span>
                      ) : (
                        ""
                       )}
                  </div>
                </div>
              </div>
              
              <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Address <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      className="form-control"
                      value={address}
                      placeholder="Address"
                      onChange={(e) => {
                        setaddress(e.target.value);
                      }}
                      required
                    />
                 {errorcls ? errorcls.address ? <span style={{color:'red'}}> {errorcls.address[0]} </span> : '' : ''}
                  </div>
                </div>
              </div>
              {/* {otpnextmobile == true ? <div className="col-md-6 px-2 " style={{paddingTop:"28px"}}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Mobile OTP <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                   
{otpnextmobile == true ? <div className="input-group ">
  <input type="number" className="form-control" step={{border:'1px solid #ced4da'}}   value={otp2}
                      onChange={(e) => {
                        setotp2(e.target.value);
                      }} placeholder="OTP" aria-label="Recipient's username" aria-describedby="basic-addon2" />
  <span className="input-group-text" onClick={verifyotpmobile} id="basic-addon2">VERIFY OTP</span>
</div> : ''}


            
                  </div>
                 
                </div>
              </div> : ''} */}
              <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Country <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    {/* <input
                      type="text"
                      value={country}
                      className="form-control"
                      placeholder="Country"
                      onChange={(e) => {
                        setcountry(e.target.value);
                      }}
                      required
                    /> */}
                    <Select
               placeholder="Select Country"
               options={updatedCountries}
               value={defaultValue}
               onChange={(e) => {
                 setcountry(e);setstate([]);setcity([]);setisstate(true);
               }}
               required
             />
                  {errorcls ? errorcls.country ? <span style={{color:'red'}}> {errorcls.country[0]} </span> : '' : ''}
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Pincode <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">

                  <input
                      type="tel"
                      className="form-control"
                      minLength={6}
                      maxLength={6}
                      value={pincode}
                      placeholder="Pincode"
                      onChange={(e) => {
                        setpincode(e.target.value.replace(/[^0-9\ ]/gi, ""));
                      }}
                      required
                    />
                  {/* <div class="input-group ">
  <input type="tel" class="form-control" placeholder="Enter Pincode" maxLength={6} onFocus={countryfocus}
                  minLength={6} value={pincode}  onChange={(e) => {setpincode(e.target.value.replace(/[^0-9]/gi, ""));setstate('');setcity('')}} required aria-label="Recipient's username" aria-describedby="button-addon2" />
  <button type="button" class="btn btn-outline-secondary" onClick={handleSubmit} style={{ textDecoration: 'none', color: 'white',backgroundColor:"#165a99" }} id="button-addon2">Search</button>
</div> */}
{errorcls ? errorcls.pincode ? <span style={{color:'red'}}> {errorcls.pincode[0]} </span> : '' : ''}
{pineror !== '' ? <span style={{color:'red'}}> {pineror} </span> : ''}
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      State <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    {/* <input
                      type="text"
                      className="form-control"
                      value={state}
                      placeholder="State"
                     readOnly
                      required
                    />
                    {stateerror !== '' ? <span style={{color:'red'}}> {stateerror} </span> : '' } */}
                    {/* <input
                      type="text"
                      className="form-control"
                      value={state}
                      placeholder="State"
                      onChange={(e) => {
                        setstate(e.target.value);
                      }}
                      required
                    /> */}
                    {state != {} ?  <Select
          id="state"
          name="state"
          value={defaultrelste}
          options={updatedStates(country.value ? country.value  :defaultValue ? defaultValue.value : null)}
          placeholder="Select State"
          onChange={(e) => {
            setstate(e);setcity([]);setiscity(true);setisstate(false);
          }}
          required
        /> :  <Select
        id="state"
        name="state"
        options={updatedStates(country.value ? country.value : defaultValue.value)}
        placeholder="Select State"
        onChange={(e) => {
          setstate(e);setcity([]);setiscity(true);setisstate(false);
        }}
        required
      />}
               {isstate == true ? stateerrormsg !== null ? <span style={{color:'red'}}> {stateerrormsg} </span> : '' : ''}          
               {errorcls ? errorcls.state ? <span style={{color:'red'}}> {errorcls.state[0]} </span> : '' : ''}

                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      City <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    {/* <input
                      type="text"
                      value={city}
                      className="form-control"
                      placeholder="City"
                     readOnly
                      required
                    />
              {cityerror !== '' ? <span style={{color:'red'}}> {cityerror} </span> : ''} */}

                    {/* <input
                      type="text"
                      value={city}
                      className="form-control"
                      placeholder="City"
                      onChange={(e) => {
                        setcity(e.target.value);
                      }}
                      required
                    /> */}
                     {/* <Select
                     placeholder="Select City"
          id="city"
          name="city"
          options={updatedCities(state == null ? null : state.value)}
          value={city}
          onChange={(e) => {
            setcity(e);
          }}
          required
        /> */}






{city != {} ?  <Select
          id="city"
          name="city"
          value={defautrealcity}
          options={defaultrelste ? updatedCities(defaultrelste.value): updatedCities(state.value)}
          placeholder="Select city"
          onChange={(e) => {
            setcity(e);setiscity(false);
          }}
          required
        /> :  <Select
        id="city"
        name="city"
        options={updatedCities(state.value ? state.value : defaultrelste.value)}
        placeholder="Select city"
        onChange={(e) => {
          setcity(e);setiscity(false);
        }}
        required
      />}

               {iscity == true ? cityerrormsg !== null ? <span style={{color:'red'}}> {cityerrormsg} </span> : '' : ''}          
                   {errorcls ? errorcls.city ? <span style={{color:'red'}}> {errorcls.city[0]} </span> : '' : ''}
                  </div>
                </div>
              </div>


              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                    Nationality <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                    {/* <input type="text" className="form-control" placeholder='Gender' value={agentgender} onChange={(e) => {setagentgender(e.target.value)}} required /> */}
                    <select
                      className="form-select"
                      value={cnationality}
                      onChange={(e) => {
                        setcnationality(e.target.value);
                        changedocument(e.target.value);
                      }}
                      aria-label="Default select example"
                    >
                      {cnationality == 'india' ? <option value="india">India</option> : <option value="Others">Others</option>}
                      {cnationality !== 'india' ? <option value="india">India</option> : <option value="Others">Others</option>}
                    </select>
                  </div>
                </div>
              </div>
    
              {gettokinval.superuser == true ? <div className="col-md-6 px-3 pt-4">
          <div className="row">
            <div className="col-lg-3">
              <label htmlFor="" className="form-label">Status <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-9">
            {/* <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" /> */}
            {status == true ? <Switch 
            // handleDiameter={30}
            // uncheckedIcon={false}
            // checkedIcon={false} height={20}
            // width={48}
            // className="react-switch"
            // id="material-switch" boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            // activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
             value={status} name='status' onChange={(e) => {setstatus(!status)}} checked /> : <Switch

             value={status} name='status' onChange={(e) => {setstatus(!status)}} />}
            </div>
          </div>
        </div> : ''}

        <div
        className="col-12 py-2 px-4 d-flex justify-content-end"
        style={{ gap: "4px" }}
      >
        
          {/* <div className='px-2'>{loading == true ?  <Loadercomp /> : ""}</div> */}
        {/* <button className="btn4">Cancel</button> */}
        {/* next step */}
        <button type='submit' className="btn5">Next</button>
      </div>
              {/* <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Company <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">

<select
                  className="form-select"
                  aria-label="Default select example"
                  value={company} placeholder='Company Name' onChange={(e) => { setcompany(e.target.value) }}
                >
                     { data.map((item) => (   
                        item.company_name === defaultv ?  <option key={item.company_id}value={item.company_id} ref={selectoption} >{item.company_name}</option> :  ''
                  ))}
                   { data.map((item) => (   
                        item.company_name != defaultv ?  <option key={item.company_id}value={item.company_id} >{item.company_name}</option> :  ''
                  ))}

                </select>  

                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2" style={{paddingTop:"28px"}}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Location <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-9">
                  
<select
                  className="form-select"
                  aria-label="Default select example"
                  value={location} placeholder='Company Name' onChange={(e) => { setlocation(e.target.value) }}
                >
                         { datatwo.map((item) => (   
                        item.branch_name === defaultvtwo ?  <option key={item.id}value={item.id} ref={selectlocationoption} >{item.branch_name}</option> :  ''
                  ))}
                   { datatwo.map((item) => (   
                        item.branch_name != defaultvtwo ?  <option key={item.id}value={item.id} >{item.branch_name}</option> :  ''
                  ))}
                </select>  
                  </div>
                </div>
              </div> */}

              </div>
              <div>
              <div className="col-12">
                <div className="row px-4" style={{paddingTop:"28px"}}>
                  <div className="col-6">
                    <div className="row">
                      <div className="col-12">
                        <label htmlFor="" className="form-label">
                          Biometric Detail{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <div className="col-12">
                        <div className="border round py-3 d-flex justify-content-center">
                        <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
     <label htmlFor="">Right Hand</label>
     <img src={thumbimg2 == null || thumbimg2 == '' ? img5 : img4} alt="rtf" style={{position:"absolute",top:"80px",left:"13px"}} />
     <img src={rifimg2 == null || rifimg2 == '' ? img5 : img4} alt="rif" style={{position:"absolute",top:"37px",left:"35px"}} />
     <img src={rmfimg2 == null || rmfimg2 == '' ? img5 : img4} alt="rmf" style={{position:"absolute",top:"30px",left:"65px"}} />
     <img src={rrfimg2 == null || rrfimg2 == '' ? img5 : img4} alt="rrf" style={{position:"absolute",top:"36px",left:"84px"}} />
     <img src={rlfimg2 == null || rlfimg2 == '' ? img5 : img4} alt="rlf" style={{position:"absolute",top:"55px",left:"103px"}} />
                          <img src={img1} alt="qwerty" />
                          </div>
                          <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
<label htmlFor="">Left Hand</label>
<img src={llfimg2 == null || llfimg2 == '' ? img5 : img4} alt="llf" style={{position:"absolute",top:"56px",left:"13px"}} />
     <img src={lrfimg2 == null || lrfimg2 == '' ? img5 : img4} alt="lrf" style={{position:"absolute",top:"36px",left:"33px"}} />
     <img src={lmfimg2 == null || lmfimg2 == '' ? img5 : img4} alt="lmf" style={{position:"absolute",top:"31px",left:"55px"}} />
     <img src={lifimg2 == null || lifimg2 == '' ? img5 : img4} alt="lif" style={{position:"absolute",top:"38px",left:"80px"}} />
     <img src={ltfimg2 == null || ltfimg2 == '' ? img5 : img4} alt="ltf" style={{position:"absolute",top:"80px",left:"102px"}} />
                          <img src={img2} alt="trew" />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 py-2 px-4 d-flex justify-content-between">
                <button
                type="button"
                  // className="btn1"
                  style={{backgroundColor:"#0C5398"}}
                  className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Capture
                </button>
              </div>
                    </div>
                  </div>
                  <div className="col-6">
                  <div className="row">
            <div className="col-12"><label htmlFor="" className="form-label">Photograph <span style={{color:'red'}}>*</span></label></div>
            <div className="col-12 ">
            <div className="border round py-3 d-flex justify-content-center">
              {/* <label htmlFor="photorap"> */}
              {/* <img src={displayphoto == '' ? img3 : displayphoto} alt="zxcvbnm" width="140px" height="162px" /> */}
              <button
                type='button'
                style={{border:'none',outline:'none'}}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModaltwo"
                >
              <img src={displayphoto == null ? img3 : displayphoto} alt="zxcvbnm" width="140px" height="162px" />
              </button>
              {/* <ReactImageMagnify
                            {...{
                                smallImage: {
                                    alt: 'Wristwatch by Versace',
                                    isFluidWidth: true,
                                    src: displayphoto == null ? img3 : displayphoto,
                                    width:140,
                                    height:162
                                },
                                largeImage: {
                                    src: displayphoto == null ? img3 : displayphoto,
                                    width: 836,
                                    height: 1100
                                },
                                // enlargedImagePosition: 'over',
                                lensStyle: { backgroundColor: 'rgba(0,0,0,.6)' }
                            }}
                            
                        /> */}
              {/* </label> */}
             </div>
             <div className="col-12 py-2">
             <button
             style={{backgroundColor:"#0C5398"}}
                type='button'
                className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalwebcam"
                  onClick={()=>{sethideorshow(!hideorshow)}}
                >
                  Capture
                </button>
                
             {/* <label htmlFor="photorap" className='btn btn-primary' style={{backgroundColor:"#0C5398"}}> Browse</label> */}
             </div>
              {/* <input type="file" style={{opacity:"0",position:'absolute',left:"205555555"}} id="photorap" onChange={(e) => {handleImageChangespecial(e)}} /> */}
            </div>
            {errorcls ? errorcls.photograph ? <span style={{color:'red'}}> {errorcls.photograph[0]} </span> : '' : ''}
          {/* { sandphotoerror == true ? <span style={{color:'red'}}> Please Select Product </span> : ''} */}
          </div>
                  </div>
                </div>
                
              </div>
             
              <div className="col-12">
    <div className="row">
      <div className="col-12 ccolor py-3 px-4">Document</div>
      {cnationality == "india" ? <div className="col-md-12 " style={{ paddingTop: "0px",display:documenttype == 'PASSPORT' ? 'none' : 'block' }}>
    <div className="row">
      <div className="col-lg-2">
        <label htmlFor="" className="form-label">
        </label>
      </div>
      <div className="col-lg-8">
        {notapplicable == true ? <input class="form-check-input" type="checkbox" value={notapplicable} 
      onChange={(e) => {
                            setnotapplicable(!notapplicable);
                          }} 
                           id="flexCheckChecked" checked /> : <input class="form-check-input" type="checkbox" value={notapplicable} 
                           onChange={(e) => {
                                                 setnotapplicable(!notapplicable);
                                               }} 
                                                id="flexCheckChecked" />}
       {" "}
   Pan Card Not Available
      </div>
    </div>
  </div> : ''}
      


{notapplicable == true ? '' : <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
        Document Type <span style={{ color: "red" }}>*</span>
        </label>
      </div>
      <div className="col-lg-8">
      {/* <select
              name=""
              id=""
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => {
                setdocumenttype(e.target.value);
              }}
              required
            >
     
              { documents.map((item) => (   
                        item.value === documenttype ?  <option key={item.id}value={item.value} >{item.name}</option> :  ''
                  ))}
                   { documents.map((item) => (   
                        item.value != documenttype ?  <option key={item.id}value={item.value} >{item.name}</option> :  ''
                  ))}
            </select> */}
            <input type="text" className="form-control" value={documenttype} autoComplete="off"  readOnly onChange={(e) => {
                        setdocumenttype(e.target.value);
                      }}
                     />
            {documenterror === true ? documenttype == undefined || documenttype == "defualt"  ? (
                      <span style={{ color: "red" }}>Please Select Document</span>
                    ) : ( "") : (
                      ""
                    )}
                 {errorcls ? errorcls.document_type ? <span style={{color:'red'}}> {errorcls.document_type[0]} </span> : '' : ''}

        {/* {errorcls ? (
          errorcls.state ? (
            <span style={{ color: "red" }}>
              {" "}
              {errorcls.state[0]}{" "}
            </span>
          ) : (
            ""
          )
        ) : (
          ""
        )} */}

      </div>
    </div>
  </div>}







  {/* {cnationality == "india" ? '' : <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
        Document Type <span style={{ color: "red" }}>*</span>
        </label>
      </div>
      <div className="col-lg-8">
    
            <input type="text" className="form-control" value={documenttype} autoComplete="off"  readOnly onChange={(e) => {
                        setdocumenttype(e.target.value);
                      }}
                     />
            {documenterror === true ? documenttype == undefined || documenttype == "defualt"  ? (
                      <span style={{ color: "red" }}>Please Select Document</span>
                    ) : ( "") : (
                      ""
                    )}
                 {errorcls ? errorcls.document_type ? <span style={{color:'red'}}> {errorcls.document_type[0]} </span> : '' : ''}


      </div>
    </div>
  </div>} */}












{notapplicable == true ? '' : <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
        Document No. <span style={{ color: "red" }}>*</span>
        </label>
      </div>
      <div className="col-lg-8">
      <input type="text" className="form-control" value={idnumber} placeholder={documenttype == 'PAN' ? `PAN No.` : documenttype == 'VOTERID' ? `VOTER ID`: documenttype == 'PASSPORT' ? `PASSPORT No.`:  documenttype == 'DRIVING_LICENSE' ? `DRIVING LICENSE No.`:  documenttype == 'AADHAAR' ?`Aadhar No.` : `Select Document`} onChange={(e) => {
                        setidnumber(e.target.value);
                      }}
                     />
                        {documenterror === true ? idnumber == undefined || idnumber == "" ? (
                      <span style={{ color: "red" }}>Please Enter Your Document No</span>
                    ) : ( "") : (
                      ""
                    )}
        {errorcls ? (
          errorcls.document_no ? (
            <span style={{ color: "red" }}>
              {" "}
              {errorcls.document_no[0]}{" "}
            </span>
          ) : (
            ""
          )
        ) : (
          ""
        )}

      </div>
    </div>
  </div>}

  {/* {cnationality == "india" ? '' : <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
        Document No. <span style={{ color: "red" }}>*</span>
        </label>
      </div>
      <div className="col-lg-8">
      <input type="text" className="form-control" value={idnumber} placeholder={documenttype == 'PAN' ? `PAN No.` : documenttype == 'VOTERID' ? `VOTER ID`: documenttype == 'PASSPORT' ? `PASSPORT No.`:  documenttype == 'DRIVING_LICENSE' ? `DRIVING LICENSE No.`:  documenttype == 'AADHAAR' ?`Aadhar No.` : `Select Document`} onChange={(e) => {
                        setidnumber(e.target.value);
                      }}
                     />
                        {documenterror === true ? idnumber == undefined || idnumber == "" ? (
                      <span style={{ color: "red" }}>Please Enter Your Document No</span>
                    ) : ( "") : (
                      ""
                    )}
        {errorcls ? (
          errorcls.document_no ? (
            <span style={{ color: "red" }}>
              {" "}
              {errorcls.document_no[0]}{" "}
            </span>
          ) : (
            ""
          )
        ) : (
          ""
        )}

      </div>
    </div>
  </div>} */}
  


  <div className="col-12 px-4">
  {grandloading == true ? <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"100%"}}>
<div className='px-2'>{grandloading == true ?  <Loadercomp size={100}  /> : ""}</div>
</div> : ''}
  </div>




 




  {documentloader == true ? <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"100%"}}>
<div className='px-2'>{documentloader == true ?  <Loadercomp size={100}  /> : ""}</div>
</div> : ''}


{notapplicable == true ? '' : documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT' ? <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
          Expiry date <span style={{ color: "red" }}>*</span>
        </label>
      </div>
      <div className="col-lg-8">
      {isrequired == true ? <input
          type="date"
          className="form-control"
          value={documentexpiry}
          // placeholder="State"
          onChange={(e) => {
            setdocumentexpiry(e.target.value);
          }}
          required
        /> : ''}
              {documenterror === true ? documentexpiry == undefined ? (
                      <span style={{ color: "red" }}>Please Ender your Expiry date</span>
                    ) : ( "") : (
                      ""
                    )}
        {/* {errorcls ? (
          errorcls.state ? (
            <span style={{ color: "red" }}>
              {" "}
              {errorcls.state[0]}{" "}
            </span>
          ) : (
            ""
          )
        ) : (
          ""
        )} */}

      </div>
    </div>
  </div> : ''}




  {/* {cnationality == 'india' ? '' : documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT' ? <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
          Expiry date <span style={{ color: "red" }}>*</span>
        </label>
      </div>
      <div className="col-lg-8">
        <input
          type="date"
          className="form-control"
          value={documentexpiry}
          // placeholder="State"
          onChange={(e) => {
            setdocumentexpiry(e.target.value);
          }}
          required
        />
              {documenterror === true ? documentexpiry == undefined ? (
                      <span style={{ color: "red" }}>Please Ender your Expiry date</span>
                    ) : ( "") : (
                      ""
                    )}

      </div>
    </div>
  </div> : ''} */}

  {notapplicable == true ? '' : documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT' ? <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
          Issue date <span style={{ color: "red" }}>*</span>
        </label>
      </div>
      <div className="col-lg-8">
      {isrequired == true ? <input
          type="date"
          className="form-control"
          value={documentissue}
        //   placeholder="Issue"
          onChange={(e) => {
            setdocumentissue(e.target.value);
          }}
          required
        /> : ''}
           {documenterror === true ? documentissue == undefined ? (
                      <span style={{ color: "red" }}>Please Ender your Issue date</span>
                    ) : ( "") : (
                      ""
                    )}
        {/* {errorcls ? (
          errorcls.state ? (
            <span style={{ color: "red" }}>
              {" "}
              {errorcls.state[0]}{" "}
            </span>
          ) : (
            ""
          )
        ) : (
          ""
        )} */}
      </div>
    </div>
  </div> : ''}
  {notapplicable == true ? '' : documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT' ? <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
        Country <span style={{ color: "red" }}>*</span>
        </label>
      </div>
      <div className="col-lg-8">
      {isrequired == true ? <Select
               placeholder="Select Country"
               options={updatedCountries}
               value={defaultValue_doc}
               onChange={(e) => {
                 setdoccountry(e);
               }}
               required
             /> : ''}
      
      </div>
    </div>
  </div> : ''}




  <div className="col-12 px-2" style={{ paddingTop: "28px",display:notapplicable == true ? "none" : "block" }}>
    <div className="row">
      <div className="col-lg-2">
        <label htmlFor="" className="form-label">
        </label>
      </div>
      <div className="col-lg-8 ">
        {doc1 == false ? <input class="form-check-input" type="checkbox" value={doc1} 
      onChange={(e) => {
                            setdoc1(!doc1);
                          }} 
                           id="flexCheckChecked" checked /> : <input class="form-check-input" type="checkbox" value={doc1} 
                           onChange={(e) => {
                                                 setdoc1(!doc1);
                                               }} 
                                                id="flexCheckChecked" /> }
       {" "}
   Don't Have Document Images


      </div>
    </div>
  </div>
 
 
 
 
 
 
 
 
 
  {/* <div className="col-md-6 px-2" style={{ paddingTop: "28px",display:notapplicable == true ? "none" : doc1 == false ? "block" : "none" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
        Document PDF <span style={{ color: "red" }}>*</span>
        </label>
      </div>
      <div className="col-lg-8">
        {doc1 == false ? viewdoc1 == null ? <input type="file" className="form-control"  onChange={(e) => {
                        setdocumentpdf1(e.target.files[0]);
                      }} required /> : <input type="file" className="form-control"  onChange={(e) => {
                        setdocumentpdf1(e.target.files[0]);
                      }}  /> : ''}


                       {errorcls ? (
                      errorcls.Doc_1_pdf ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.Doc_1_pdf[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
      </div>
    </div>
  </div> */}

  {/* {cnationality == "india" ? '' : documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT' ? <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
          Issue date <span style={{ color: "red" }}>*</span>
        </label>
      </div>
      <div className="col-lg-8">
        <input
          type="date"
          className="form-control"
          value={documentissue}
          onChange={(e) => {
            setdocumentissue(e.target.value);
          }}
          required
        />
           {documenterror === true ? documentissue == undefined ? (
                      <span style={{ color: "red" }}>Please Ender your Issue date</span>
                    ) : ( "") : (
                      ""
                    )}
      </div>
    </div>
  </div> : ''} */}
  {notapplicable == true ? '' : <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
       Front Image <span style={{ color: "red" }}>*</span>
        </label>
        {modifiedImage !== null && documenttype == 'AADHAAR' ? <div style={{display:"flex",gap:"5px",padding:"10px 4px"}}>
          <button onClick={()=>{setvarx(varx-15);modifyImage(aadhaarImage,varx,vary)}}  style={{backgroundColor:"#0C5398"}}
                type='button'
                className='btn btn-primary'><BsFillArrowLeftCircleFill /></button>
                 <button onClick={()=>{setvarx(varx+15);modifyImage(aadhaarImage,varx,vary)}} style={{backgroundColor:"#0C5398"}}
                type='button'
                className='btn btn-primary'><BsFillArrowRightCircleFill /></button>
        </div> : ''}
        {modifiedImage !== null && documenttype == 'AADHAAR' ? <div style={{display:"flex",gap:"5px",padding:"0px 4px"}}>
          <button  style={{backgroundColor:"#0C5398"}}
                type='button'
                onClick={()=>{setvary(vary-15);modifyImage(aadhaarImage,varx,vary)}}
                className='btn btn-primary'><BsFillArrowUpCircleFill /></button>
                 <button  style={{backgroundColor:"#0C5398"}}
                type='button'
                onClick={()=>{setvary(vary+15);modifyImage(aadhaarImage,varx,vary)}}
                className='btn btn-primary'><BsFillArrowDownCircleFill /></button>
        </div> : ''}
      </div>
      <div className="col-lg-8 ">
      {doc1 == true ? <button
                type='button'
                style={{border:'none',outline:'none',width:"100%"}}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModaldocument"
                  onClick={()=>{sethideorshowtwo(!hideorshowtwo)}}
                >
              <div style={{border:"1px solid #dee2e6",backgroundColor:"white",width:"100%",height:"162px",display:"flex",justifyContent:"center",alignItems:"center"}}>
              {modifiedImage == null ? <img src={img7} alt="zxcvbnm" width="50%" height="102px" /> : <img src={modifiedImage} alt="zxcvbnm" width="100%" height="162px" />}

              </div>
              </button> : 
              <div style={{border:"1px solid #dee2e6",backgroundColor:"white",width:"100%",height:"162px",display:"flex",justifyContent:"center",alignItems:"center"}}>
                <label htmlFor="img1doc" style={{width:'100%',height:'100%',display:'contents'}}>
              {modifiedImage == null || modifiedImage == "none" ? <img src={img7} alt="zxcvbnm" width="50%" height="102px" /> : <img src={modifiedImage} alt="zxcvbnm" width="100%" height="162px" />}
              </label>
              <input accept="image/*" type="file" id="img1doc" style={{display:'none'}} onChange={(e)=>{changefrontimg(e.currentTarget.files[0])}} alt="304" />
              </div>
              }

              {modifiedImage == null ? '' : <button
style={{backgroundColor:"#0C5398"}}
type='button'
className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal34"
                >
View
                </button> }
      {/* <input type="file" className="form-control " onChange={handleImageUpload} required /> */}
      {errorcls ? errorcls.docImg_front ? <span style={{color:'red'}}> {errorcls.docImg_front[0]} </span> : '' : ''}

      {documenterror === true ? documentimage == undefined ? (
                      <span style={{ color: "red" }}>Please Upload your Image</span>
                    ) : ( "") : (
                      ""
                    )}


      </div>
    </div>
  </div>}





  {/* {cnationality == "india" ? '' : <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
       Front Image <span style={{ color: "red" }}>*</span>
        </label>
        {modifiedImage !== null && documenttype == 'AADHAAR' ? <div style={{display:"flex",gap:"5px",padding:"10px 4px"}}>
          <button onClick={()=>{setvarx(varx-15);modifyImage(aadhaarImage,varx,vary)}}  style={{backgroundColor:"#0C5398"}}
                type='button'
                className='btn btn-primary'><BsFillArrowLeftCircleFill /></button>
                 <button onClick={()=>{setvarx(varx+15);modifyImage(aadhaarImage,varx,vary)}} style={{backgroundColor:"#0C5398"}}
                type='button'
                className='btn btn-primary'><BsFillArrowRightCircleFill /></button>
        </div> : ''}
        {modifiedImage !== null && documenttype == 'AADHAAR' ? <div style={{display:"flex",gap:"5px",padding:"0px 4px"}}>
          <button  style={{backgroundColor:"#0C5398"}}
                type='button'
                onClick={()=>{setvary(vary-15);modifyImage(aadhaarImage,varx,vary)}}
                className='btn btn-primary'><BsFillArrowUpCircleFill /></button>
                 <button  style={{backgroundColor:"#0C5398"}}
                type='button'
                onClick={()=>{setvary(vary+15);modifyImage(aadhaarImage,varx,vary)}}
                className='btn btn-primary'><BsFillArrowDownCircleFill /></button>
        </div> : ''}
      </div>
      <div className="col-lg-8 ">
      <button
                type='button'
                style={{border:'none',outline:'none',width:"100%"}}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModaldocument"
                  onClick={()=>{sethideorshowtwo(!hideorshowtwo)}}
                >
              <div style={{border:"1px solid #dee2e6",backgroundColor:"white",width:"100%",height:"162px",display:"flex",justifyContent:"center",alignItems:"center"}}>
              {modifiedImage == null ? <img src={img7} alt="zxcvbnm" width="50%" height="102px" /> : <img src={modifiedImage} alt="zxcvbnm" width="100%" height="162px" />}

              </div>
              </button>

              {modifiedImage == null ? '' : <button
style={{backgroundColor:"#0C5398"}}
type='button'
className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal34"
                >
View
                </button> }
      {errorcls ? errorcls.docImg_front ? <span style={{color:'red'}}> {errorcls.docImg_front[0]} </span> : '' : ''}

      {documenterror === true ? documentimage == undefined ? (
                      <span style={{ color: "red" }}>Please Upload your Image</span>
                    ) : ( "") : (
                      ""
                    )}


      </div>
    </div>
  </div>} */}
  


{/* back image document */}


{notapplicable == true ? '' : <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
       Back Image <span style={{ color: "red" }}>*</span>
        </label>
        {documentimagebacktwo !== null && documenttype == 'AADHAAR' ? <div style={{display:"flex",gap:"5px",padding:"10px 4px"}}>
          <button onClick={()=>{setvarxtwo(varxtwo-15);backmodifyImage(aadhaarImagetwo,varxtwo,varytwo)}}  style={{backgroundColor:"#0C5398"}}
                type='button'
                className='btn btn-primary'><BsFillArrowLeftCircleFill /></button>
                 <button onClick={()=>{setvarxtwo(varxtwo+15);backmodifyImage(aadhaarImagetwo,varxtwo,varytwo)}} style={{backgroundColor:"#0C5398"}}
                type='button'
                className='btn btn-primary'><BsFillArrowRightCircleFill /></button>
        </div> : ''}
        {documentimagebacktwo !== null && documenttype == 'AADHAAR' ? <div style={{display:"flex",gap:"5px",padding:"0px 4px"}}>
          <button  style={{backgroundColor:"#0C5398"}}
                type='button'
                onClick={()=>{setvarytwo(varytwo-15);backmodifyImage(aadhaarImagetwo,varxtwo,varytwo)}}
                className='btn btn-primary'><BsFillArrowUpCircleFill /></button>
                 <button  style={{backgroundColor:"#0C5398"}}
                type='button'
                onClick={()=>{setvarytwo(varytwo+15);backmodifyImage(aadhaarImagetwo,varxtwo,varytwo)}}
                className='btn btn-primary'><BsFillArrowDownCircleFill /></button>
        </div> : ''}
      </div>
      <div className="col-lg-8 ">
      {doc1 == true ? <button
                type='button'
                style={{border:'none',outline:'none',width:"100%"}}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModaldocumenttwowwe"
                  onClick={()=>{sethideorshowthree(!hideorshowthree)}}
                >
              <div style={{border:"1px solid #dee2e6",backgroundColor:"white",width:"100%",height:"162px",display:"flex",justifyContent:"center",alignItems:"center"}}>
              {documentimagebacktwo == null ? <img src={img7} alt="zxcvbnm" width="50%" height="102px" /> : <img src={documentimagebacktwo} alt="zxcvbnm" width="100%" height="162px" />}

              </div>
              </button> :
              <div style={{border:"1px solid #dee2e6",backgroundColor:"white",width:"100%",height:"162px",display:"flex",justifyContent:"center",alignItems:"center"}}>
             <label htmlFor="img2doc" style={{width:'100%',height:'100%',display:'contents'}}>
              {documentimagebacktwo == null || documentimagebacktwo == "none" ? <img src={img7} alt="zxcvbnm" width="50%" height="102px" /> : <img src={documentimagebacktwo} alt="zxcvbnm" width="100%" height="162px" />}
              </label>
              <input accept="image/*" type="file" id="img2doc" style={{display:'none'}} onChange={(e)=>{changebackimg(e.currentTarget.files[0])}} alt="304" />
             
              </div>
             }
              {documentimagebacktwo == null ? '' : <button
style={{backgroundColor:"#0C5398"}}
type='button'
className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal35"
                >
View
                </button> }
      {errorcls ? errorcls.docImg_front ? <span style={{color:'red'}}> {errorcls.docImg_front[0]} </span> : '' : ''}

      {documenterror === true ? documentimage == undefined ? (
                      <span style={{ color: "red" }}>Please Upload your Image</span>
                    ) : ( "") : (
                      ""
                    )}


      </div>
    </div>
  </div> }










  {/* {cnationality == "india" ? '' : <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
       Back Image <span style={{ color: "red" }}>*</span>
        </label>
        {documentimagebacktwo !== null && documenttype == 'AADHAAR' ? <div style={{display:"flex",gap:"5px",padding:"10px 4px"}}>
          <button onClick={()=>{setvarxtwo(varxtwo-15);backmodifyImage(aadhaarImagetwo,varxtwo,varytwo)}}  style={{backgroundColor:"#0C5398"}}
                type='button'
                className='btn btn-primary'><BsFillArrowLeftCircleFill /></button>
                 <button onClick={()=>{setvarxtwo(varxtwo+15);backmodifyImage(aadhaarImagetwo,varxtwo,varytwo)}} style={{backgroundColor:"#0C5398"}}
                type='button'
                className='btn btn-primary'><BsFillArrowRightCircleFill /></button>
        </div> : ''}
        {documentimagebacktwo !== null && documenttype == 'AADHAAR' ? <div style={{display:"flex",gap:"5px",padding:"0px 4px"}}>
          <button  style={{backgroundColor:"#0C5398"}}
                type='button'
                onClick={()=>{setvarytwo(varytwo-15);backmodifyImage(aadhaarImagetwo,varxtwo,varytwo)}}
                className='btn btn-primary'><BsFillArrowUpCircleFill /></button>
                 <button  style={{backgroundColor:"#0C5398"}}
                type='button'
                onClick={()=>{setvarytwo(varytwo+15);backmodifyImage(aadhaarImagetwo,varxtwo,varytwo)}}
                className='btn btn-primary'><BsFillArrowDownCircleFill /></button>
        </div> : ''}
      </div>
      <div className="col-lg-8 ">
      <button
                type='button'
                style={{border:'none',outline:'none',width:"100%"}}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModaldocumenttwowwe"
                  onClick={()=>{sethideorshowthree(!hideorshowthree)}}
                >
              <div style={{border:"1px solid #dee2e6",backgroundColor:"white",width:"100%",height:"162px",display:"flex",justifyContent:"center",alignItems:"center"}}>
              {documentimagebacktwo == null ? <img src={img7} alt="zxcvbnm" width="50%" height="102px" /> : <img src={documentimagebacktwo} alt="zxcvbnm" width="100%" height="162px" />}

              </div>
              </button>
              {documentimagebacktwo == null ? '' : <button
style={{backgroundColor:"#0C5398"}}
type='button'
className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal35"
                >
View
                </button> }
      {errorcls ? errorcls.docImg_front ? <span style={{color:'red'}}> {errorcls.docImg_front[0]} </span> : '' : ''}

      {documenterror === true ? documentimage == undefined ? (
                      <span style={{ color: "red" }}>Please Upload your Image</span>
                    ) : ( "") : (
                      ""
                    )}


      </div>
    </div>
  </div> } */}

  {/* {modifiedImage !== null && documenttype == 'AADHAAR' ? <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <div style={{display:"flex",gap:"5px",padding:"10px 4px"}}>
          <button onClick={()=>{setvarx(varx-15);modifyImage(aadhaarImage,varx,vary)}}  style={{backgroundColor:"#0C5398"}}
                type='button'
                className='btn btn-primary'><BsFillArrowLeftCircleFill /></button>
                 <button onClick={()=>{setvarx(varx+15);modifyImage(aadhaarImage,varx,vary)}} style={{backgroundColor:"#0C5398"}}
                type='button'
                className='btn btn-primary'><BsFillArrowRightCircleFill /></button>
        </div>
        <div style={{display:"flex",gap:"5px",padding:"0px 4px"}}>
          <button  style={{backgroundColor:"#0C5398"}}
                type='button'
                onClick={()=>{setvary(vary-15);modifyImage(aadhaarImage,varx,vary)}}
                className='btn btn-primary'><BsFillArrowUpCircleFill /></button>
                 <button  style={{backgroundColor:"#0C5398"}}
                type='button'
                onClick={()=>{setvary(vary+15);modifyImage(aadhaarImage,varx,vary)}}
                className='btn btn-primary'><BsFillArrowDownCircleFill /></button>
        </div>
      </div>
      <div className="col-lg-8">
      <img src={modifiedImage} alt="Modified Aadhaar Card" style={{width:"100%",height:"100%",maxHeight:"170px"}} />
      </div>
    </div>
  </div> : aadhaarImage !== null ?<div className="offset-col-6 col-md-6 px-2" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
      </div>
      <div className="col-lg-8">
      <img src={aadhaarImage} alt="Modified Aadhaar Card" style={{width:"100%",height:"100%",maxHeight:"170px"}} />
      </div>
    </div>
  </div> : ''} */}




  {/* {modifiedImage !== null && documenttype == 'AADHAAR' ? <div className="offset-col-6 col-md-6 px-2" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
      </div>
      <div className="col-lg-8">
      <img src={documentimagebacktwo} alt="Modified Aadhaar Card" style={{width:"100%",height:"100%"}} />
      </div>
    </div>
  </div> : ''} */}


{/* {documenttype == "AADHAR CARD" ? <div className="col-md-5 px-2" style={{ paddingTop: "28px",display:notapplicable == false ? "none" : "block" }}> */}
{cnationality == "india" ? notapplicable == true ? <div className="col-md-5 px-2" style={{ paddingTop: "28px",display:"block" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
        Form 60 <span style={{ color: "red" }}>*</span>
        </label>
      </div>
      <div className="col-lg-8">
        {/* {notapplicable == true ? */}
         <input type="file" className="form-control"  onChange={(e) => {
                        setform60(e.target.files[0]);
                      }} />
                       {/* : ''} */}
    
      </div>
    </div>
  </div> : '' : ''}



 {cnationality == "india" ? notapplicable == true ? <div className="col-md-1" style={{ paddingTop: "28px",display:"block" }}>
    <div className="row">
      <div className="col-lg-12" style={{display:"flex",justifyContent:'center',alignItems:"center",fontSize:'24px',fontWeight:'bold'}}>
        OR 
      </div>
    </div>
  </div> : '' : '' }
  {cnationality == "india" ? notapplicable == true ? <div className="col-lg-6" >
        <div className="row">
        <div className="col-12 px-4">
  </div>
            <div className="col-12"><label htmlFor="" className="form-label"> <span style={{color:'red'}}></span></label></div>
            <div className="col-12 ">
            <div className="border round py-3 d-flex justify-content-center">
              <div>
              {/* <Webcam /> */}
              </div>

                     <button
                type='button'
                style={{border:'none',outline:'none'}}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModaltwo123"
                >
              <img src={displaydocument == '' ? img33 : displaydocument} alt="zxcvbnm" width="140px" height="162px" />
              </button>
             </div>
             <div className="col-12 py-2">
             <button
             style={{backgroundColor:"#0C5398"}}
                type='button'
                className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalwebcam123"
                  onClick={()=>{sethideorshow(!hideorshow)}}
                >
                  Capture
                </button>
             {/* <label htmlFor="photorap" className='btn btn-primary' > Browse</label> */}
             </div>
              {/* <input type="file" style={{opacity:"0",position:'absolute',left:"205555555"}} id="photorap" onChange={(e) => {handleImageChangespecial(e)}} required /> */}
            </div>
            {errorcls ? errorcls.photograph ? <span style={{color:'red'}}> {errorcls.photograph[0]} </span> : '' : ''}
          { sandphotoerror == true ? <span style={{color:'red'}}> Please Capture your Photograph </span> : ''}
          </div>
        </div> : '' : ''} 




{seconddocopen == true ?  <div className="col-12 py-3 px-4">
        
        <div
          style={{
            background: "#D9D9D9",
            height: "1px",
            width: "100%",
          }}
        ></div>
      </div> : ''}



     {seconddocopen == true ? <div className="col-12 ccolor py-3 px-4">Document 2</div> : ''}




  {seconddocopen == true ? <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
        Document Type <span style={{ color: "red" }}>*</span>
        </label>
      </div>
      <div className="col-lg-8">
      <select
              name=""
              id=""
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => {
                setdocumenttypetwo(e.target.value);
                changedocumetimgtwo(e.target.value);
              }}
              required
            >
              {/* <option value="defualt">Select Document</option> */}
              {/* {documenttype !== 'AADHAAR' ? <option value="AADHAAR">AADHAAR CARD</option> : <></> }
              {documenttype !== 'PAN' ?<option value="PAN">PAN CARD</option> : <></> }
              {documenttype !== 'DRIVING_LICENSE' ?<option value="DRIVING_LICENSE">DRIVING LICENSE</option>: <></> }
              {documenttype !== 'VOTERID' ? <option value="VOTERID">VOTER ID</option> : <></> }
              {documenttype !== 'PASSPORT' ? <option value="PASSPORT">PASSPORT</option> : <></> } */}
              {/* { documents.map((item) => (   
                        item.value === documenttypetwo ?  <option key={item.id}value={item.value} >{item.name}</option> :  ''
                  ))}
                   { documents.map((item) => (   
                        item.value != documenttypetwo ? documenttype == item.value ? '' : <option key={item.id}value={item.value} >{item.name}</option> :  ''
                  ))} */}


{cnationality == 'india' ? documents.map((item) => (   
                        item.value == documenttypetwo ?  <option key={item.id}value={item.value} >{item.name}</option> :  ''
                  )) :  documentsecond.map((item) => (   
                    item.value == documenttypetwo ?  <option key={item.id}value={item.value} >{item.name}</option> :  ''
              ))}
                   { cnationality == 'india' ? documents.map((item) => (   
                        documenttypetwo == item.value ? '' : notapplicable == true && item.value == "AADHAAR" ? "" : <option key={item.id}value={item.value} >{item.name}</option> 
                  )) : documentsecond.map((item) => (   
                    documenttypetwo == item.value ? '' : <option key={item.id}value={item.value} >{item.name}</option> 
              ))}
            </select>
            {/* {documenterror === true ? documenttype == undefined || documenttype == "defualt"  ? (
                      <span style={{ color: "red" }}>Please Select Document</span>
                    ) : ( "") : (
                      ""
                    )}
                     {documentprivateerror === true ? documenttype == undefined || documenttype == "defualt"  ? (
                      <span style={{ color: "red" }}>Please Select Document</span>
                    ) : ( "") : (
                      ""
                    )}
                 {errorcls ? errorcls.document_type ? <span style={{color:'red'}}> {errorcls.document_type[0]} </span> : '' : ''} */}


      </div>
    </div>
  </div> : ''} 












  {seconddocopen == true ? <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
        Document No. <span style={{ color: "red" }}>*</span>
        </label>
      </div>
      <div className="col-lg-8">
      <input type="text" className="form-control" value={idnumbertwo} placeholder={documenttypetwo == 'PAN' ? `PAN No.` : documenttypetwo == 'VOTERID' ? `VOTER ID`: documenttypetwo == 'PASSPORT' ? `PASSPORT No.`:  documenttypetwo == 'DRIVING_LICENSE' ? `DRIVING LICENSE No.`:  documenttypetwo == 'AADHAAR' ?`Aadhar No.` : `Select Document`} onChange={(e) => {
                        setidnumbertwo(e.target.value);
                      }}
                     />
                        {/* {documenterror === true ? idnumber == undefined || idnumber == "" ? (
                      <span style={{ color: "red" }}>Please Enter Your Document No</span>
                    ) : ( "") : (
                      ""
                    )} */}
        {errorcls ? (
          errorcls.document_no2 ? (
            <span style={{ color: "red" }}>
              {" "}
              {errorcls.document_no2[0]}{" "}
            </span>
          ) : (
            ""
          )
        ) : (
          ""
        )}

      </div>
    </div>
  </div> : ''}



  {seconddocopen == true ? documenttypetwo == 'DRIVING_LICENSE' || documenttypetwo == "RESIDENT_PERMIT" || documenttypetwo == "VISA" || documenttypetwo == "REFUGEE_CARD" || documenttypetwo == 'PASSPORT' ? <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
          Expiry date <span style={{ color: "red" }}>*</span>
        </label>
      </div>
      <div className="col-lg-8">
      {isrequired == true ?
        <input
          type="date"
          className="form-control"
          value={documentexpirytwo}
          // placeholder="State"
          onChange={(e) => {
            setdocumentexpirytwo(e.target.value);
          }}
          required
        /> : ''}
              {/* {documenterror === true ? documentexpiry == undefined ? (
                      <span style={{ color: "red" }}>Please Ender your Expiry date</span>
                    ) : ( "") : (
                      ""
                    )} */}

      </div>
    </div>
  </div> : '' : ''}
  {seconddocopen == true ? documenttypetwo == 'DRIVING_LICENSE' || documenttypetwo == "RESIDENT_PERMIT" || documenttypetwo == "VISA" || documenttypetwo == "REFUGEE_CARD" || documenttypetwo == 'PASSPORT' ? <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
          Issue date <span style={{ color: "red" }}>*</span>
        </label>
      </div>
      <div className="col-lg-8">
      {isrequired == true ? <input
          type="date"
          className="form-control"
          value={documentissuetwo}
        //   placeholder="Issue"
          onChange={(e) => {
            setdocumentissuetwo(e.target.value);
          }}
          required
        /> : ''}
           {/* {documenterror === true ? documentissue == undefined ? (
                      <span style={{ color: "red" }}>Please Ender your Issue date</span>
                    ) : ( "") : (
                      ""
                    )} */}

      </div>
    </div>
  </div> : '' : ''}



  <div className="col-12 px-2" style={{ paddingTop: "28px",display:"block" }}>
    <div className="row">
      <div className="col-lg-2">
        <label htmlFor="" className="form-label">
        </label>
      </div>
      <div className="col-lg-8 ">
        {doc2 == false ? <input class="form-check-input" type="checkbox" value={doc2} 
      onChange={(e) => {
                            setdoc2(!doc2);
                          }} 
                           id="flexCheckChecked" checked /> : <input class="form-check-input" type="checkbox" value={doc2} 
                           onChange={(e) => {
                                                 setdoc2(!doc2);
                                               }} 
                                                id="flexCheckChecked" /> }
       {" "}
   Don't Have Document 2 Images


      </div>
    </div>
  </div>
  {/* <div className="col-md-6 px-2" style={{ paddingTop: "28px",display:doc2 == false ? "block" : "none" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
        Document PDF 2<span style={{ color: "red" }}>*</span>
        </label>
      </div>
      <div className="col-lg-8">
        {doc2 == false ? viewdoc2 == null ? <input type="file" className="form-control"  onChange={(e) => {
                        setdocumentpdf2(e.target.files[0]);
                      }} required /> : <input type="file" className="form-control"  onChange={(e) => {
                        setdocumentpdf2(e.target.files[0]);
                      }} /> : '' }

                       {errorcls ? (
                      errorcls.Doc_2_pdf ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.Doc_2_pdf[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
  
      </div>
    </div>
  </div> */}

  {seconddocopen == true ? <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
       Front Image <span style={{ color: "red" }}>*</span>
        </label>
        {modifiedImagenumbertwo !== null && documenttypetwo == 'AADHAAR' ? <div style={{display:"flex",gap:"5px",padding:"10px 4px"}}>
          <button onClick={()=>{setvarx2(varx2-15);modifyImagethree(aadhaarImage2,varx2,vary2)}}  style={{backgroundColor:"#0C5398"}}
                type='button'
                className='btn btn-primary'><BsFillArrowLeftCircleFill /></button>
                 <button onClick={()=>{setvarx2(varx2+15);modifyImagethree(aadhaarImage2,varx2,vary2)}} style={{backgroundColor:"#0C5398"}}
                type='button'
                className='btn btn-primary'><BsFillArrowRightCircleFill /></button>
        </div> : ''}
        {modifiedImagenumbertwo !== null && documenttypetwo == 'AADHAAR' ? <div style={{display:"flex",gap:"5px",padding:"0px 4px"}}>
          <button  style={{backgroundColor:"#0C5398"}}
                type='button'
                onClick={()=>{setvary2(vary2-15);modifyImagethree(aadhaarImage2,varx2,vary2)}}
                className='btn btn-primary'><BsFillArrowUpCircleFill /></button>
                 <button  style={{backgroundColor:"#0C5398"}}
                type='button'
                onClick={()=>{setvary2(vary2+15);modifyImagethree(aadhaarImage2,varx2,vary2)}}
                className='btn btn-primary'><BsFillArrowDownCircleFill /></button>
        </div> : ''}
      </div>
      <div className="col-lg-8 ">
      {doc2 == true ? <button
                type='button'
                style={{border:'none',outline:'none',width:"100%"}}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModaldocument2"
                  onClick={()=>{sethideorshow3(!hideorshow3)}}
                >
              <div style={{border:"1px solid #dee2e6",backgroundColor:"white",width:"100%",height:"162px",display:"flex",justifyContent:"center",alignItems:"center"}}>
              {modifiedImagenumbertwo == null ? <img src={img7} alt="zxcvbnm" width="50%" height="102px" /> : <img src={modifiedImagenumbertwo} alt="zxcvbnm" width="100%" height="162px" />}

              </div>
              </button> : 
              <div style={{border:"1px solid #dee2e6",backgroundColor:"white",width:"100%",height:"162px",display:"flex",justifyContent:"center",alignItems:"center"}}>
              <label htmlFor="img22doc" style={{width:'100%',height:'100%',display:'contents'}}>
              {modifiedImagenumbertwo == null ? <img src={img7} alt="zxcvbnm" width="50%" height="102px" /> : <img src={modifiedImagenumbertwo} alt="zxcvbnm" width="100%" height="162px" />}
              </label>
              <input accept="image/*" type="file" id="img22doc" style={{display:'none'}} onChange={(e)=>{twochangefrontimg(e.currentTarget.files[0])}} alt="304" />
              
              </div>
              }

              {modifiedImagenumbertwo == null ? '' : <button
style={{backgroundColor:"#0C5398"}}
type='button'
className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal340"
                >
View
                </button> }
      {/* <input type="file" className="form-control " onChange={handleImageUpload} required /> */}
      {/* {errorcls ? errorcls.docImg_front ? <span style={{color:'red'}}> {errorcls.docImg_front[0]} </span> : '' : ''}

      {dfierror == true ? <span style={{color:'red'}}> {drireserror !== "" ? drireserror : ''} </span> : ''} */}


      </div>
    </div>
  </div> : ''}

{/* back image document */}
  {seconddocopen == true ? <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
       Back Image <span style={{ color: "red" }}>*</span>
        </label>
        {documentimageback2 !== null && documenttypetwo == 'AADHAAR' ? <div style={{display:"flex",gap:"5px",padding:"10px 4px"}}>
          <button onClick={()=>{setvarxtwo2(varxtwo2-15);backmodifyImage3(aadhaarback2,varxtwo2,varytwo2)}}  style={{backgroundColor:"#0C5398"}}
                type='button'
                className='btn btn-primary'><BsFillArrowLeftCircleFill /></button>
                 <button onClick={()=>{setvarxtwo2(varxtwo2+15);backmodifyImage3(aadhaarback2,varxtwo2,varytwo2)}} style={{backgroundColor:"#0C5398"}}
                type='button'
                className='btn btn-primary'><BsFillArrowRightCircleFill /></button>
        </div> : ''}
        {documentimageback2 !== null && documenttypetwo == 'AADHAAR' ? <div style={{display:"flex",gap:"5px",padding:"0px 4px"}}>
          <button  style={{backgroundColor:"#0C5398"}}
                type='button'
                onClick={()=>{setvarytwo2(varytwo2-15);backmodifyImage3(aadhaarback2,varxtwo2,varytwo2)}}
                className='btn btn-primary'><BsFillArrowUpCircleFill /></button>
                 <button  style={{backgroundColor:"#0C5398"}}
                type='button'
                onClick={()=>{setvarytwo2(varytwo2+15);backmodifyImage3(aadhaarback2,varxtwo2,varytwo2)}}
                className='btn btn-primary'><BsFillArrowDownCircleFill /></button>
        </div> : ''}
      </div>
      <div className="col-lg-8 ">
      {doc2 == true ? <button
                type='button'
                style={{border:'none',outline:'none',width:"100%"}}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModaldocumenttwowwetwo"
                  onClick={()=>{sethideorshow3(!hideorshow3)}}
                >
              <div style={{border:"1px solid #dee2e6",backgroundColor:"white",width:"100%",height:"162px",display:"flex",justifyContent:"center",alignItems:"center"}}>
              {documentimageback2 == null ? <img src={img7} alt="zxcvbnm" width="50%" height="102px" /> : <img src={documentimageback2} alt="zxcvbnm" width="100%" height="162px" />}

              </div>
              </button> : 

<div style={{border:"1px solid #dee2e6",backgroundColor:"white",width:"100%",height:"162px",display:"flex",justifyContent:"center",alignItems:"center"}}>
<label htmlFor="img33doc" style={{width:'100%',height:'100%',display:'contents'}}>
{documentimageback2 == null ? <img src={img7} alt="zxcvbnm" width="50%" height="102px" /> : <img src={documentimageback2} alt="zxcvbnm" width="100%" height="162px" />}
</label>
<input accept="image/*" type="file" id="img33doc" style={{display:'none'}} onChange={(e)=>{twochangebackimg(e.currentTarget.files[0])}} alt="304" />

</div>
}
              {documentimageback2 == null ? '' : <button
style={{backgroundColor:"#0C5398"}}
type='button'
className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal350"
                >
View
                </button> }
      {/* <input type="file" className="form-control " onChange={handleImageUpload} required /> */}
      {/* {errorcls ? errorcls.docImg_back ? <span style={{color:'red'}}> {errorcls.docImg_back[0]} </span> : '' : ''}

      {dbierror == true ? <span style={{color:'red'}}> {dbireserror !== "" ? dbireserror : ''} </span> : ''} */}



      </div>
    </div>
  </div> : ''}








  {addaccount == true ?  <div className="col-12 py-3 px-4">
        
        <div
          style={{
            background: "#D9D9D9",
            height: "1px",
            width: "100%",
          }}
        ></div>
      </div> : ''}







      {addaccount == true ? <div className="col-12 ccolor py-3 px-4">Bank Account Verify</div> : ''}

{addaccount == true ? <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
<div className="row">
 <div className="col-lg-4">
   <label htmlFor="" className="form-label">
      Account No. <span style={{ color: "red" }}>*</span>
   </label>
 </div>
 <div className="col-lg-8">
  {isrequired == true ? <input
     type="Number"
     className="form-control"
     value={accountno}
     placeholder="Account Number"
     onChange={(e) => {
       setaccountno(e.target.value);
       setaccountverified(false);
     }}
     readOnly={alreadycreated == false ?accountverified == true ? true : false : false}
     required
   /> : <input
   type="Number"
   className="form-control"
   value={accountno}
   placeholder="Account Number"
   onChange={(e) => {
     setaccountno(e.target.value);
     setaccountverified(false);
   }}
   readOnly={alreadycreated == false ?accountverified == true ? true : false : false}
 />}
      {accountbankerror === true ? accountno == "" ? (
                 <span style={{ color: "red" }}>Please Enter your Account Number</span>
               ) : ( "") : (
                 ""
               )}

 </div>
</div>
</div> : "" } 

{addaccount == true ? <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
<div className="row">
 <div className="col-lg-4">
   <label htmlFor="" className="form-label">
   IFSC Code <span style={{ color: "red" }}>*</span>
   </label>
 </div>
 <div className="col-lg-8">
  {isrequired == true ? <input
     type="text"
     className="form-control"
     value={ifsccode}
     placeholder="IFSC Code"
     onChange={(e) => {
       setifsccode(e.target.value);
       setaccountverified(false);
     }}
     readOnly={alreadycreated == false ? accountverified == true ? true : false : false}
     required
   /> : <input
   type="text"
   className="form-control"
   value={ifsccode}
   placeholder="IFSC Code"
   onChange={(e) => {
     setifsccode(e.target.value);
     setaccountverified(false);
   }}
   readOnly={alreadycreated == false ? accountverified == true ? true : false : false}
 />}
      {accountbankerror === true ? ifsccode == "" ? (
                 <span style={{ color: "red" }}>Please Enter your IFSC Code</span>
               ) : ( "") : (
                 ""
               )}

 </div>
</div>
</div> : ""}
{addaccount == true ? accountname == null ? "" : <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
<div className="row">
 <div className="col-lg-4">
   <label htmlFor="" className="form-label">
   Holder Name <span style={{ color: "red" }}>*</span>
   </label>
 </div>
 <div className="col-lg-8">
   <input
     type="text"
     className="form-control"
     value={accountname}
     placeholder="Beneficiary Name"
   readOnly
   />

 </div>
</div>
</div> : ""}


{idno == 200 ? bankstatus ==  true ? backstatusmsg !== null ? <div className="col-10 alert offset-1 alert-success mt-3" role="alert">
            <h5 style={{padding:'0px',margin:"0px"}}>
         {backstatusmsg}
            </h5>
       </div> : '' : '' : ''}
  {idno == 300 ? bankerrorstatus ==  true ? bankerrorstatusmsg !== "" ? <div className="col-10 offset-1 alert  alert-danger mt-3" role="alert">
            <h5 style={{padding:'0px',margin:"0px"}}>
         {bankerrorstatusmsg}
            </h5>
       </div> : '' : '' : ''}

       {megaerror == true ? <div className="col-11 col-offset-2 alert alert-danger mt-3 ms-1" role="alert">
            <h5 style={{padding:'0px',margin:"0px"}}>
        {megaerrormsg}
            </h5>
       </div> : ''}

{accountverified == true ? "" : addaccount == true ? <div
        className="col-12 py-2 px-4 mt-2 d-flex justify-content-end"
        style={{ gap: "4px" }}
        
      >
        
          {/* <div className='px-2'>{loading == true ?  <Loadercomp /> : ""}</div> */}
        {/* <button className="btn4">Cancel</button> */}
        <button type='button' onClick={verifybankaccount} className="btn2">Submit</button>
      </div> : ""}

  {seconddocopen == true ?  <div className="col-12 py-3 px-4">
        
        <div
          style={{
            background: "#D9D9D9",
            height: "1px",
            width: "100%",
          }}
        ></div>
      </div> : ''}
  
      {seconddocopen == true ? <div className="col-12 ccolor py-3 px-4">Verify Agent</div> : ''}

      <div className="col-md-6">
          <div className="row">
            <div className="col-12"><label htmlFor="" className="form-label">Biometric Detail <span style={{color:'red'}}>*</span></label></div>
            <div className="col-12">
                        <div className="border round py-3 d-flex justify-content-center">
                        <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
     <label htmlFor="">Right Hand</label>
     <img src={thumbimg2sand == '' || thumbimg2sand == null ? img5 : img4} alt="rtf" style={{position:"absolute",top:"80px",left:"13px"}} />
     <img src={rifimg2sand == '' || rifimg2sand == null ? img5 : img4} alt="rif" style={{position:"absolute",top:"37px",left:"35px"}} />
     <img src={rmfimg2sand == '' || rmfimg2sand == null ? img5 : img4} alt="rmf" style={{position:"absolute",top:"30px",left:"65px"}} />
     <img src={rrfimg2sand == '' || rrfimg2sand == null ? img5 : img4} alt="rrf" style={{position:"absolute",top:"36px",left:"84px"}} />
     <img src={rlfimg2sand == '' || rlfimg2sand == null ? img5 : img4} alt="rlf" style={{position:"absolute",top:"55px",left:"103px"}} />
                          <img src={img1} alt="qwerty" />
                          </div>
                          <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
<label htmlFor="">Left Hand</label>
<img src={llfimg2sand == '' || llfimg2sand == null ? img5 : img4} alt="llf" style={{position:"absolute",top:"56px",left:"13px"}} />
     <img src={lrfimg2sand == '' || lrfimg2sand == null ? img5 : img4} alt="lrf" style={{position:"absolute",top:"36px",left:"33px"}} />
     <img src={lmfimg2sand == '' || lmfimg2sand == null ? img5 : img4} alt="lmf" style={{position:"absolute",top:"31px",left:"55px"}} />
     <img src={lifimg2sand == '' || lifimg2sand == null ? img5 : img4} alt="lif" style={{position:"absolute",top:"38px",left:"80px"}} />
     <img src={ltfimg2sand == '' || ltfimg2sand == null ? img5 : img4} alt="ltf" style={{position:"absolute",top:"80px",left:"102px"}} />
                          <img src={img2} alt="trew" />
                          </div>
                        </div>
                      </div>
            { fingerperror12 == true ? <span style={{color:'red'}}> {rigthfingerserror12} </span> : ''}
            {errorcls ? errorcls.r_index_finger ? <span style={{color:'red'}}> "Something Went wrong try again" </span> : '' : ''}
            <div className="col-12 py-2 px-4 d-flex justify-content-between">
                <button
                type='button'
                  // className="btn1"
             style={{backgroundColor:"#0C5398"}}
             className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal009"
                >
                  Capture
                </button>
              </div>
          </div>
        </div>








  {idno == 1 ? documentresponse != {} ? documentresponse.status == "SUCCESS" ? <div className="col-11 alert alert-success mt-3" role="alert">
            <h5 style={{padding:'0px',margin:"0px"}}>
         Verification Successfully 
            </h5>
       </div> : '' : '' : ''}

       {idno == 2 ? documenterrorres == true ? <div className="col-11 col-offset-2 alert alert-danger mt-3 ms-1" role="alert">
            <h5 style={{padding:'0px',margin:"0px"}}>
         Something Went Wrong Try Again
            </h5>
       </div> : '' : '' }
       {idno == 222 ? submitacc == true ? <div className="col-11 col-offset-2 alert alert-danger mt-3 ms-1" role="alert">
            <h5 style={{padding:'0px',margin:"0px"}}>
        {submitaccmsg}
            </h5>
       </div> : '' : '' }
  
      <div className="col-12 py-1 px-4 d-flex justify-content-start">
        {/* <button type="button" className="btn1" onClick={()=>setseconddocopen(!seconddocopen)}> +Add</button> */}
        {/* <button onClick={documentloader == false ? documentverify : ''} type='button' className="btn2"> Submit</button> */}
      </div>
      <div className="col-12 py-3 px-4">
        
        <div
          style={{
            background: "#D9D9D9",
            height: "1px",
            width: "100%",
          }}
        ></div>
      </div>

      {/* <div className="col-6 py-4 px-4">
<div className="row">
<div className="col-lg-3">
  <label htmlFor="" className="form-label customw">Password <span style={{color:'red'}}>*</span> </label>
</div>
<div className="col-lg-9">
  <input type="text" className="form-control" placeholder='Password' />
</div>
</div>
</div>
<div className="col-6 py-4 px-4">
<div className="row">
<div className="col-lg-3">
  <label htmlFor="" className="form-label customw">Confirm Password <span style={{color:'red'}}>*</span> </label>
</div>
<div className="col-lg-9">
  <input type="text" className="form-control" placeholder='Confirm Password' />
</div>
</div>
</div> */}

      <div
        className="col-12 py-2 px-4 d-flex justify-content-end"
        style={{ gap: "4px" }}
      >
        
          {/* <div className='px-2'>{loading == true ?  <Loadercomp /> : ""}</div> */}
        <button type="button" className="btn4" onClick={()=>{stepWizardInstance.current.firstStep();setisrequired(false)}} >Back</button>
        <button className="btn5">Submit</button>
      </div>
    </div>
  </div>
  </div>
  </StepWizard>

              {custresponse.status === 200 ? nvg("/Customerlist/2") : <div></div>}
            </div>
            <div className="modal fade" id="exampleModaltwo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>
                <div className="col-12 px-2 pt-3">
  <img src={displayphoto == null ? img3 : displayphoto} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
              </div>
            </div>
          </div>  











          <div className="modal fade" id="exampleModalwebcam123" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => {sethideorshow(!hideorshow)}} aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px' }}>

  {hideorshow == true ? <div className="col-12 d-flex py-3 justify-content-center">
<div className='px-2'>{reloadin == false ? "" : hideorshow == true ?  <Webcam ref={webcamRefs[5]} /> : ""}</div></div>:''}

</div>
                </div>
                <div className="modal-footer mod-line m-auto">
                  {/* <button type="button" className="btn closecancel">Cancel</button> */}
                  <button type="button" className="btn closecancel" onClick={()=>{reloadcam()}}>Reload</button>
                  <button type="button" onClick={()=>{captureimage123(5)}} aria-label="Close" data-bs-dismiss="modal" className="btn closebtn text-white">Capture</button>
                </div>
              </div>
            </div>
          </div>



          <div className="modal fade" id="exampleModaltwo123" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>
                <div className="col-12 px-2 pt-3">
  <img src={displaydocument == '' ? img33 : displaydocument} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
              </div>
            </div>
          </div>  



            <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>

<div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="tf2" className="form-label ">Right Thumb  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" class="form-control" id='tf2' name="image2" style={{opacity:"0",position:'absolute'}}  onChange={(e) => {handleImageChange11(e)}}  /> */}
        {/* <label htmlFor="tf"> */}
        {/* <div onClick={(e) => {handleImageChange11()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: thumbimg2 != null ? '' : '3rem',width:"20%" }}> {thumbimg2 != null ? <img src={`data:image/png;base64,${thumbimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange11()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: thumbimg2 != '' && thumbimg2 != null ? '' : rtferror == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {thumbimg2 != '' && thumbimg2 != null ? <img src={`data:image/png;base64,${thumbimg2}`} width="70px" height="60px" alt="asd" /> : rtferror == true ? <Loadercomp size={30} /> : ''}  </div>
        {thumbimg2 != null ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}

      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="ltf2" className="form-label ">Left Thumb  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="ltf2" onChange={(e) => {handleImageChange16(e)}} /> */}
        {/* <div onClick={(e) => {handleImageChange16()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: ltfimg2 != null ? '' : '3rem',width:"20%" }}> {ltfimg2 != null ? <img src={`data:image/png;base64,${ltfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange16()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: ltfimg2 != '' && ltfimg2 != null ? '' : ltferror == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {ltfimg2 != '' && ltfimg2 != null ? <img src={`data:image/png;base64,${ltfimg2}`} width="70px" height="60px" alt="asd" /> : ltferror == true ? <Loadercomp size={30} /> : ''}  </div>
        {ltfimg2 != null ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rif2" className="form-label ">Right Index Finger <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rif2" onChange={(e) => {handleImageChange12(e)}} /> */}
        {/* <div onClick={(e) => {handleImageChange12()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rifimg2 != null ? '' : '3rem',width:"20%" }}> {rifimg2 != null ? <img src={`data:image/png;base64,${rifimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange12()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rifimg2 != '' && rifimg2 != null ? '' : riferror == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rifimg2 != '' && rifimg2 != null ? <img src={`data:image/png;base64,${rifimg2}`} width="70px" height="60px" alt="asd" /> : riferror == true ? <Loadercomp size={30} /> : ''}  </div>
        {rifimg2 != null ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="lif2" className="form-label ">Left Index Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lif2" onChange={(e) => {handleImageChange17(e)}} /> */}
        {/* <div onClick={(e) => {handleImageChange17()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lifimg2 != null ? '' : '3rem',width:"20%" }}> {lifimg2 != null ? <img src={`data:image/png;base64,${lifimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange17()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: lifimg2 != '' && lifimg2 != null ? '' : liferror == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {lifimg2 != '' && lifimg2 != null ? <img src={`data:image/png;base64,${lifimg2}`} width="70px" height="60px" alt="asd" /> : liferror == true ? <Loadercomp size={30} /> : ''}  </div>
        {lifimg2 != null ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rmf2" className="form-label ">Right Middle Finger<span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rmf2" onChange={(e) => {handleImageChange13(e)}} /> */}
        {/* <div onClick={(e) => {handleImageChange13()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rmfimg2 != null ? '' : '3rem',width:"20%" }}> {rmfimg2 != null ? <img src={`data:image/png;base64,${rmfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange13()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rmfimg2 != '' && rmfimg2 != null ? '' : rmferror == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rmfimg2 != '' && rmfimg2 != null ? <img src={`data:image/png;base64,${rmfimg2}`} width="70px" height="60px" alt="asd" /> : rmferror == true ? <Loadercomp size={30} /> : ''}  </div>
        {rmfimg2 != null ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
      </div>
    </div>

  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="lmf2" className="form-label ">Left Middle Finger <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lmf2" onChange={(e) => {handleImageChange18(e)}} /> */}
        {/* <div onClick={(e) => {handleImageChange18()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lmfimg2 != null ? '' : '3rem',width:"20%" }}> {lmfimg2 != null ? <img src={`data:image/png;base64,${lmfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange18()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: lmfimg2 != '' && lmfimg2 != null ? '' : lmferror == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {lmfimg2 != '' && lmfimg2 != null ? <img src={`data:image/png;base64,${lmfimg2}`} width="70px" height="60px" alt="asd" /> : lmferror == true ? <Loadercomp size={30} /> : ''}  </div>
        {lmfimg2 != null ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rrf2" className="form-label ">Right Ring Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rrf2" onChange={(e) => {handleImageChange14(e)}} /> */}
        {/* <div onClick={(e) => {handleImageChange14()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rrfimg2 != null ? '' : '3rem',width:"20%" }}> {rrfimg2 != null ? <img src={`data:image/png;base64,${rrfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange14()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rrfimg2 != '' && rrfimg2 != null ? '' : rrferror == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rrfimg2 != '' && rrfimg2 != null ? <img src={`data:image/png;base64,${rrfimg2}`} width="70px" height="60px" alt="asd" /> : rrferror == true ? <Loadercomp size={30} /> : ''}  </div>
        {rrfimg2 != null ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
      </div>
    </div>

  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="lrf2" className="form-label ">Left Ring Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lrf2" onChange={(e) => {handleImageChange19(e)}} /> */}
        {/* <div onClick={(e) => {handleImageChange19()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lrfimg2 != null ? '' : '3rem',width:"20%" }}> {lrfimg2 != null ? <img src={`data:image/png;base64,${lrfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange19()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: lrfimg2 != '' && lrfimg2 != null ? '' : lrferror == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {lrfimg2 != '' && lrfimg2 != null ? <img src={`data:image/png;base64,${lrfimg2}`} width="70px" height="60px" alt="asd" /> : lrferror == true ? <Loadercomp size={30} /> : ''}  </div>
        {lrfimg2 != null ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rlf2" className="form-label ">Right Little Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rlf2" onChange={(e) => {handleImageChange15(e)}} /> */}
        {/* <div onClick={(e) => {handleImageChange15()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rlfimg2 != null ? '' : '3rem',width:"20%" }}> {rlfimg2 != null ? <img src={`data:image/png;base64,${rlfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange15()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rlfimg2 != '' && rlfimg2 != null ? '' : rlferror == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rlfimg2 != '' && rlfimg2 != null ? <img src={`data:image/png;base64,${rlfimg2}`} width="70px" height="60px" alt="asd" /> : rlferror == true ? <Loadercomp size={30} /> : ''}  </div>
        {rlfimg2 != null ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
      </div>
    </div>
  </div>


 
 
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="llf2" className="form-label ">Left Little Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="llf2" onChange={(e) => {handleImageChange20(e)}} /> */}
        {/* <div onClick={(e) => {handleImageChange20()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: llfimg2 != null ? '' : '3rem',width:"20%" }}> {llfimg2 != null ? <img src={`data:image/png;base64,${llfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange20()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: llfimg2 != '' && llfimg2 != null ? '' : llferror == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {llfimg2 != '' && llfimg2 != null ? <img src={`data:image/png;base64,${llfimg2}`} width="70px" height="60px" alt="asd" /> : llferror == true ? <Loadercomp size={30} /> : ''}  </div>
        {llfimg2 != null ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
      </div>
    </div>
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>

          <div
        className={showerror ? "modal fade show" : "modal fade "}
        id="exampleModal1400"
        tabindex="-1"
        style={{display:showerror ? "block" : 'none'}}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
          
          <BsQuestionLg className='question-logo' />
            {/* <span className="question-logo" style={{display:"flex",justifyContent:"center",alignItems:'center'}} ></span> */}
            {/* <i class="fa fa-exclamation" aria-hidden="true"></i> */}
            <div className="modal-header mod-line">
            </div>
            <div className="modal-body">
              <div className="row gy-3 mt-2">
                <h1 className="ccedit-h">Bank Account Error</h1>
                <p className="ccedit-p">
                Holder Name Not Match.
                  
                </p>
              </div>
            </div>
            <div className="modal-footer mod-line m-auto">
            {/* <button type="button" className="btn closecancel" data-bs-dismiss="modal"
                aria-label="Close">Cancel</button> */}

               <button
                onClick={()=>{setshowerror(!showerror)}}
                type="button"
                className="btn closebtn text-white"
                data-bs-dismiss="modal"
              >
                Proceed
              
              </button> 
              <button
                onClick={()=>{setshowerror(!showerror);setaccountverified(false)}}
                type="button"
                className="btn  text-white"
                data-bs-dismiss="modal"
                style={{background:"grey"}}
                // aria-label="Close"
              >
                Cancel
              
              </button>
  
            </div>
          </div>
        </div>
      </div>


          <div className="modal fade" id="exampleModal009" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                  <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>
                  {formdatao.r_thumb == null || formdatao.r_thumb == "" ? formdatao.l_thumb == null || formdatao.l_thumb == "" ? formdatao.r_index_finger == null || formdatao.r_index_finger == "" ? formdatao.l_index_finger == null || formdatao.l_index_finger == "" ? formdatao.r_middle_finger == null || formdatao.r_middle_finger == "" ? formdatao.l_middle_finger == null || formdatao.l_middle_finger == "" ? formdatao.r_ring_finger == null || formdatao.r_ring_finger == ""  ? formdatao.l_ring_finger == null || formdatao.l_ring_finger == "" ? formdatao.r_little_finger == null || formdatao.r_little_finger == "" ? formdatao.l_little_finger == null || formdatao.l_little_finger == "" ? <div className="col-lg-12 px-2 pt-3">
                      <div className="row">
                        <div className="col-12">
                          <p style={{color:"red",textAlign:'center',fontSize:'26px',fontWeight:'600'}}>
                          Please Submit Your Finger Prints</p>
                        </div>
                      </div>
                    </div> : '' :'' : '' : '' : '' : '' : '' : '' : '' : ''}
                  {formdatao.r_thumb == null || formdatao.r_thumb == "" ? '': <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="tf2" className="form-label ">Right Thumb <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" class="form-control" id='tf2' name="image2" style={{opacity:"0",position:'absolute'}}  onChange={(e) => {handleImageChange11(e)}}  /> */}
                          {/* <label htmlFor="tf"> */}
                          {/* <div onClick={(e) => {handleImageChange11()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: thumbimg2 != '' ? '' : '3rem',width:"20%" }}> {thumbimg2 != '' ? <img src={`data:image/png;base64,${thumbimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange110()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: thumbimg5 != '' ? '' : rtf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {thumbimg5 != '' ? <img src={`data:image/png;base64,${thumbimg5}`} width="70px" height="60px" alt="asd" /> : rtf3error == true ? <Loadercomp size={30} /> : ''}  </div>
                          
                          {thumbimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {artferror == '' ? '' : artferror}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao.l_thumb == null || formdatao.l_thumb == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="ltf2" className="form-label ">Left Thumb  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="ltf2" onChange={(e) => {handleImageChange16(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange16()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: ltfimg2 != '' ? '' : '3rem',width:"20%" }}> {ltfimg2 != '' ? <img src={`data:image/png;base64,${ltfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange160()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: ltfimg5 != '' ? '' : ltf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {ltfimg5 != '' ? <img src={`data:image/png;base64,${ltfimg5}`} width="70px" height="60px" alt="asd" /> : ltf3error == true ? <Loadercomp size={30} /> : ''}  </div> 
                          {ltfimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {altferror == '' ? '' : altferror}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao.r_index_finger == null || formdatao.r_index_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="rif2" className="form-label ">Right Index Finger <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rif2" onChange={(e) => {handleImageChange12(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange12()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rifimg2 != '' ? '' : '3rem',width:"20%" }}> {rifimg2 != '' ? <img src={`data:image/png;base64,${rifimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange120()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rifimg5 != '' ? '' : rif3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rifimg5 != '' ? <img src={`data:image/png;base64,${rifimg5}`} width="70px" height="60px" alt="asd" /> : rif3error == true ? <Loadercomp size={30} /> : ''}  </div>
          
                          {rifimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {ariferror == '' ? '' : ariferror}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao.l_index_finger == null || formdatao.l_index_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="lif2" className="form-label ">Left Index Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lif2" onChange={(e) => {handleImageChange17(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange17()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lifimg2 != '' ? '' : '3rem',width:"20%" }}> {lifimg2 != '' ? <img src={`data:image/png;base64,${lifimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange170()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: lifimg5 != '' ? '' : lif3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {lifimg5 != '' ? <img src={`data:image/png;base64,${lifimg5}`} width="70px" height="60px" alt="asd" /> : lif3error == true ? <Loadercomp size={30} /> : ''}  </div>
          
                          {lifimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {aliferror == '' ? '' : aliferror}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao.r_middle_finger == null || formdatao.r_middle_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="rmf2" className="form-label ">Right Middle Finger<span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rmf2" onChange={(e) => {handleImageChange13(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange13()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rmfimg2 != '' ? '' : '3rem',width:"20%" }}> {rmfimg2 != '' ? <img src={`data:image/png;base64,${rmfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange130()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rmfimg5 != '' ? '' : rmf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rmfimg5 != '' ? <img src={`data:image/png;base64,${rmfimg5}`} width="70px" height="60px" alt="asd" /> : rmf3error == true ? <Loadercomp size={30} /> : ''}  </div>
                  
                          {rmfimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {armferror == '' ? '' : armferror}</p>
                        </div>
                      </div>

                    </div>}
                    {formdatao.l_middle_finger == null || formdatao.l_middle_finger == "" ? '' :<div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="lmf2" className="form-label ">Left Middle Finger <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lmf2" onChange={(e) => {handleImageChange18(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange18()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lmfimg2 != '' ? '' : '3rem',width:"20%" }}> {lmfimg2 != '' ? <img src={`data:image/png;base64,${lmfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange180()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: lmfimg5 != '' ? '' : lmf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {lmfimg5 != '' ? <img src={`data:image/png;base64,${lmfimg5}`} width="70px" height="60px" alt="asd" /> : lmf3error == true ? <Loadercomp size={30} /> : ''}  </div>
        
                          {lmfimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                      </div>
                    </div>}
                    {formdatao.r_ring_finger == null || formdatao.r_ring_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="rrf2" className="form-label ">Right Ring Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rrf2" onChange={(e) => {handleImageChange14(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange14()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rrfimg2 != '' ? '' : '3rem',width:"20%" }}> {rrfimg2 != '' ? <img src={`data:image/png;base64,${rrfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange140()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rrfimg5 != '' ? '' : rrf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rrfimg5 != '' ? <img src={`data:image/png;base64,${rrfimg5}`} width="70px" height="60px" alt="asd" /> : rrf3error == true ? <Loadercomp size={30} /> : ''}  </div>

                          {rrfimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {arrferror == '' ? '' : arrferror}</p>
                        </div>
                      </div>

                    </div> }
                    {formdatao.l_ring_finger == null || formdatao.l_ring_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="lrf2" className="form-label ">Left Ring Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lrf2" onChange={(e) => {handleImageChange19(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange19()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lrfimg2 != '' ? '' : '3rem',width:"20%" }}> {lrfimg2 != '' ? <img src={`data:image/png;base64,${lrfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange190()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: lrfimg5 != '' ? '' : lrf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {lrfimg5 != '' ? <img src={`data:image/png;base64,${lrfimg5}`} width="70px" height="60px" alt="asd" /> : lrf3error == true ? <Loadercomp size={30} /> : ''}  </div>
                          
                          {lrfimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {alrferror == '' ? '' : alrferror}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao.r_little_finger == null || formdatao.r_little_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="rlf2" className="form-label ">Right Little Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rlf2" onChange={(e) => {handleImageChange15(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange15()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rlfimg2 != '' ? '' : '3rem',width:"20%" }}> {rlfimg2 != '' ? <img src={`data:image/png;base64,${rlfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange150()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rlfimg5 != '' ? '' : rlf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rlfimg5 != '' ? <img src={`data:image/png;base64,${rlfimg5}`} width="70px" height="60px" alt="asd" /> : rlf3error == true ? <Loadercomp size={30} /> : ''}  </div>
                          
                          {rlfimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {arlferror == '' ? '' : arlferror}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao.l_little_finger == null || formdatao.l_little_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="llf2" className="form-label ">Left Little Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="llf2" onChange={(e) => {handleImageChange20(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange20()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: llfimg2 != '' ? '' : '3rem',width:"20%" }}> {llfimg2 != '' ? <img src={`data:image/png;base64,${llfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange200()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: llfimg5 != '' ? '' : llf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {llfimg5 != '' ? <img src={`data:image/png;base64,${llfimg5}`} width="70px" height="60px" alt="asd" /> : llf3error == true ? <Loadercomp size={30} /> : ''}  </div>
                  
                          {llfimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {allferror == '' ? '' : allferror}</p>
                        </div>
                      </div>
                    </div>}
                  </div>
                </div>
                {/* {grandresponse.status === 201 ? nvg('/Remittancelist/1') : <div></div>} */}
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>


          <div className={showsuperotp == true ? "modal fade show" : "modal fade"} id="OTPPOPUP" tabindex="-1" style={{display:showsuperotp ? "block" : "none"}}  aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={()=>{setshowsuperotp(false)}} aria-label="Close"></button>
                </div>
                <div className="modal-body">

                  <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '1rem' }}>
                  {/* {otpnextmobile == true ?  */}
                
                  <div className="col-12 ccolor pt-2 px-4 text-center " style={{textDecoration:"underline"}}> VERIFY OTP</div>
                  {mobile_no !== getdata.mobile_no ? <div className="col-md-6 px-2 " style={{paddingTop:"28px"}}>
                <div className="row">
                  <div className="col-lg-12">
                    <label htmlFor="" className="form-label">
                      Mobile OTP <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-12">
                  
{/* {otpnextmobile == true ?  */}
<div className="input-group ">
  <input type="number" className="form-control"   value={otp2}
                      onChange={(e) => {
                        setotp2(e.target.value);
                      }} placeholder="Mobile OTP" />
  {/* <span className="input-group-text" style={{cursor:"pointer"}} onClick={verifyotpmobile} id="basic-addon2">VERIFY OTP</span> */}
</div> 
{/* : ''} */}

            
                  </div>
                 
                </div>
              </div> : ''}

              {emailchage == true ? <div className="col-md-6 px-2 " style={{paddingTop:"28px"}}>
                <div className="row">
                  <div className="col-12">
                    <label htmlFor="" className="form-label">
                      Email OTP <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-12">
                    
{emailchage == true ? <div className="input-group ">
  <input type="number" className="form-control"   value={otp}
                      onChange={(e) => {
                        setotp(e.target.value);
                      }} placeholder="OTP" aria-label="Recipient's username" aria-describedby="basic-addon2" />
  {/* <span className="input-group-text" onClick={verifyotp} id="basic-addon2">VERIFY OTP</span> */}
</div> : ''}


            
                  </div>
                 
                </div>
              </div> : ''} 
              {/* : ''} */}

              {/* {otpnext == true ? */}
               {/* <div className="col-md-6 px-2 " style={{paddingTop:"28px"}}>
                <div className="row">
                  <div className="col-lg-12">
                    <label htmlFor="" className="form-label">
                      Email OTP <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-lg-12"> */}
{/* {otpnext == true ?  */}
{/* <div className="input-group ">
  <input type="number" className="form-control"   value={otp}
                      onChange={(e) => {
                        setotp(e.target.value);
                      }} placeholder="Email OTP" /> */}
  {/* <span className="input-group-text" onClick={verifyotp} style={{cursor:"pointer"}} id="basic-addon2">VERIFY OTP</span> */}
{/* </div>  */}
{/* // : ''} */}
            
                  {/* </div>
                 
                </div>
              </div> */}
               {/* : ''} */}
               <div
        className="col-12 py-1 px-2 d-flex justify-content-end" 
      >
      
        <div class="alert alert-danger" role="alert"  style={{width:'100%',display:invalidotp == true ? 'block' : 'none'}}>
        Invalid OTP.
</div>
</div>
<div
        className="col-12 px-2 d-flex justify-content-end" 
      >
        <div class="alert alert-danger" role="alert"  style={{width:'100%',display:invalidotpmobile == true ? 'block' : 'none'}}>
        Invalid OTP.
</div>
</div>
<div
        className="col-12 px-2 d-flex justify-content-end" 
      >
        <div class="alert alert-success" role="alert"  style={{width:'100%',display:sendotpres == true ? 'block' : 'none'}}>
        OTP sent to your Email Successfully.
</div>
</div>
<div
        className="col-12 px-2 d-flex justify-content-end" 
      >
        <div class="alert alert-success" role="alert"  style={{width:'100%',display:sendotpresmobile == true ? 'block' : 'none'}}>
        OTP sent to your Mobile Successfully.
</div>
</div>
<div
        className="col-12 px-2 d-flex justify-content-end" 
      >
        <div class="alert alert-success" role="alert"  style={{width:'100%',display:otpshowres == true ? 'block' : 'none'}}>
        Email Verified Successfully.
</div>
</div>
<div
        className="col-12 px-2 d-flex justify-content-end" 
      >
        <div class="alert alert-success" role="alert"  style={{width:'100%',display:otpshowresmobile == true ? 'block' : 'none'}}>
        Mobile Number Verified Successfully.
</div>
</div>
<div
        className="col-12 px-2 d-flex justify-content-end" 
      >
        <div class="alert alert-danger" role="alert"  style={{width:'100%',display:verifyyourotp == true ? 'block' : 'none'}}>
        {registerpro == false ? registerpromobile == false ? "Please Verify Your Phone No ." : "Please Verify Your Email."  : registerpromobile == false ? "Please Verify Your Phone No ." : ""}   
</div>
{/* <div class="alert alert-danger" role="alert"  style={{width:'100%',display:baler == true ? 'block' : 'none'}}>
        { baler == true ? "Your Wallet Balance is low please Recharge First." : ''}   
</div> */}
      </div>
                  </div>
                </div>
                {/* {grandresponse.status === 201 ? nvg('/Remittancelist/1') : <div></div>} */}
                <div className="modal-footer mod-line m-auto">
                  <button type="button" onClick={()=>{setshowsuperotp(false)}} className="btn closecancel">Cancel</button>
                  <button type="button" onClick={()=>{verifyotpmobile()}} className="btn closebtn text-white">Verify</button>
                </div>
              </div>
            </div>
          </div>


          <div className="modal fade" id="exampleModaldocumenttwowwetwo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => {sethideorshow3(!hideorshow3)}} aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px' }}>

  {hideorshow3 == true ? <div className="col-12 d-flex py-3 justify-content-center">
<div className='px-2'>{reloadin == false ? "" : hideorshow3 == true ?  <Webcamtwo ref={webcamRefs[4]} /> : ""}</div></div>:''}

</div>
                </div>
                <div className="modal-footer mod-line m-auto">
                  {/* <button type="button" className="btn closecancel">Cancel</button> */}
                  <button type="button" className="btn closecancel" onClick={()=>{reloadcam()}}>Reload</button>
                  <button type="button" onClick={()=>{captureimagethreetwo(4)}} aria-label="Close" data-bs-dismiss="modal" className="btn closebtn text-white">Capture</button>
                </div>
              </div>
            </div>
          </div>


          <div className="modal fade" id="exampleModal340" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>


  
  <div className="col-12 px-2 pt-3">
  <img src={modifiedImagenumbertwo == null ? img3 : modifiedImagenumbertwo} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>
          <div className="modal fade" id="exampleModal350" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>


  
  <div className="col-12 px-2 pt-3">
  <img src={documentimageback2 == null ? img3 : documentimageback2} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>

          <div className="modal fade" id="exampleModaldocument2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => {sethideorshow3(!hideorshow3)}} aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px' }}>

  {hideorshow3 == true ? <div className="col-12 d-flex py-3 justify-content-center">
<div className='px-2'>{reloadin == false ? "" : hideorshow3 == true ?  <Webcam ref={webcamRefs[3]} /> : ""}</div></div>:''}

</div>
                </div>
                <div className="modal-footer mod-line m-auto">
                  {/* <button type="button" className="btn closecancel">Cancel</button> */}
                  <button type="button" className="btn closecancel" onClick={()=>{reloadcam()}}>Reload</button>
                  <button type="button" onClick={()=>{captureimagesuper2(3)}} aria-label="Close" data-bs-dismiss="modal" className="btn closebtn text-white">Capture</button>
                </div>
              </div>
            </div>
          </div>


                    {/* capture image box  */}

                    {/* <div className="modal fade" id="exampleModalwebcam" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">

                  <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => {sethideorshow(!hideorshow)}} aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px' }}>



  {hideorshow == true ? <div className="col-12 d-flex py-3 justify-content-center">
<div className='px-2'>{hideorshow == true ?  <Webcam ref={pickimg} /> : ""}</div></div>:''}

</div>
                </div>
                <div className="modal-footer mod-line m-auto">

                  <button type="button" onClick={()=>{captureimage()}} aria-label="Close" data-bs-dismiss="modal" className="btn closebtn text-white">Capture</button>
                </div>
              </div>
            </div>
          </div> */}



<div className="modal fade" id="exampleModal34" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>


  
  <div className="col-12 px-2 pt-3">
  <img src={modifiedImage == null ? img3 : modifiedImage} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>


          <div className="modal fade" id="exampleModal35" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>


  
  <div className="col-12 px-2 pt-3">
  <img src={documentimagebacktwo == null ? img3 : documentimagebacktwo} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>

          <div className="modal fade" id="exampleModalwebcam" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => {sethideorshow(!hideorshow)}} aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px' }}>

  {hideorshow == true ? <div className="col-12 d-flex py-3 justify-content-center">
<div className='px-2'>{reloadin == false ? "" : hideorshow == true ?  <Webcam ref={webcamRefs[2]} /> : ""}</div></div>:''}

</div>
                </div>
                <div className="modal-footer mod-line m-auto">
                  {/* <button type="button" className="btn closecancel">Cancel</button> */}
                  <button type="button" className="btn closecancel" onClick={()=>{reloadcam()}}>Reload</button>
                  <button type="button" onClick={()=>{captureimage(2)}} aria-label="Close" data-bs-dismiss="modal" className="btn closebtn text-white">Capture</button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="exampleModaldocument" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => {sethideorshowtwo(!hideorshowtwo)}} aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px' }}>

  {hideorshowtwo == true ? <div className="col-12 d-flex py-3 justify-content-center">
<div className='px-2'>{reloadin == false ? "" : hideorshowtwo == true ?  <Webcam ref={webcamRefs[0]} /> : ""}</div></div>:''}

</div>
                </div>
                <div className="modal-footer mod-line m-auto">
                  {/* <button type="button" className="btn closecancel">Cancel</button> */}
                  <button type="button" className="btn closecancel" onClick={()=>{reloadcam()}}>Reload</button>
                  <button type="button" onClick={()=>{captureimagetwo(0)}} aria-label="Close" data-bs-dismiss="modal" className="btn closebtn text-white">Capture</button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="exampleModaldocumenttwowwe" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => {sethideorshowthree(!hideorshowthree)}} aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px' }}>

  {hideorshowthree == true ? <div className="col-12 d-flex py-3 justify-content-center">
<div className='px-2'>{reloadin == false ? "" : hideorshowthree == true ?  <Webcamtwo ref={webcamRefs[1]} /> : ""}</div></div>:''}

</div>
                </div>
                <div className="modal-footer mod-line m-auto">
                  {/* <button type="button" className="btn closecancel">Cancel</button> */}
                  <button type="button" className="btn closecancel" onClick={()=>{reloadcam()}}>Reload</button>
                  <button type="button" onClick={()=>{captureimagethree(1)}} aria-label="Close" data-bs-dismiss="modal" className="btn closebtn text-white">Capture</button>
                </div>
              </div>
            </div>
          </div>
          </form>
        </div> }
      </div>
    </div>
  );
};

export default Editcustomer;
