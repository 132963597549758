import React, { useState } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { gettoken } from "../../Localstorage/Store";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import Loadercomp from "../../components/Loadercomp";

const Addsubscription = () => {
  const nvg = useNavigate();
  const [planname, setplanname] = useState();
  const [duration, setduration] = useState();
  const [discount, setdiscount] = useState();
  const [price, setprice] = useState();
  const [gst, setgst] = useState();
  const [description, setdescription] = useState();

  const [custresponse, setcustresponse] = useState({});
  const [errorclass, seterrorclass] = useState(false);
  const [grandloading, setgrandloading] = useState(false);

  const gettokinval = gettoken();

  const [errorcls, seterrorcls] = useState({});
  const [loading, setloading] = useState(false);
  const [otploading, setotploading] = useState(false);

  const [srtloader, setsrtloader] = useState(false);

  async function submitform(e) {
    e.preventDefault();

    setgrandloading(true);
    // setloading(true)
    const formdata = new FormData();
    formdata.append("sub_name", planname);
    formdata.append("duration_days", duration);
    formdata.append("discount", discount);
    formdata.append("price", price);
    formdata.append("gst_rate", gst);
    formdata.append("sub_desc", description);
    formdata.append("sac_code", 12345);
    formdata.append("is_active",true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${gettokinval.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}api/service-plans/`;
      const response = await axios.post(url, formdata, config);
      setcustresponse(response);
      seterrorclass(false);

      if (response.status == 200 || response.status == 201) {
        setloading(false);
        setgrandloading(false);
        nvg("/subscriptionlist/1");
      }
    } catch (error) {
      setcustresponse({
        statusone: 1,
        message: "Something Went Wrong Try again",
      });
      setgrandloading(false);
      seterrorcls(error.response.data);
      seterrorclass(true);
      setloading(false);
    }
  }

  return (
    <div style={{ width: "100%" }}>
      {/* <Header /> */}
      <div className="dashboardcontent px-2">
        <div className="container-fuild px-2 desgin1">
          <div className="row bg-white ">
            <div
              className="col-lg-12 d-flex justify-content-between py-2"
              style={{
                background: "hsla(210, 85%, 32%, 0.2)",
                color: "#0C5398",
              }}
            >
              <p className="m-0 customfont">Add Subscription</p>
              <div className="addnew d-block mb-2">
                <button className="btn text-white closebtn">
                  <NavLink
                    to="/subscriptionlist/0"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    {" "}
                    x Close
                  </NavLink>
                </button>
              </div>
            </div>

            <div className="col-12 py-1 px-2 d-flex justify-content-center">
              {otploading == true ? <Loadercomp size={70} /> : ""}
            </div>
          </div>
        </div>
        {srtloader == true ? (
          <div className="container-fuild bg-white">
            {" "}
            <div
              className="col-12 d-flex justify-content-center"
              style={{ gap: "4px", position: "absolute", width: "90%" }}
            >
              <div className="px-2">
                {" "}
                <Loadercomp size={100} />
              </div>
            </div>
          </div>
        ) : (
          <div className="container-fuild pb-4 pt-3 px-3 bg-white">
            <form onSubmit={submitform}>
              <div
                className="row bg-white pb-4 round"
                style={{
                  border: "1px solid #E0E0E0",
                  margin: "10px 0px",
                  borderRadius: "3px",
                  position: "relative",
                }}
              >
                <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
                  <div className="row">
                    <div className="col-lg-3">
                      <label htmlFor="" className="form-label">
                        Plan Name <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <input
                        type="text"
                        className="form-control"
                        value={planname}
                        placeholder="Plan Name"
                        onChange={(e) => {
                          setplanname(e.target.value);
                        }}
                        required
                      />
                      {errorcls ? (
                        errorcls.sub_name ? (
                          <span style={{ color: "red" }}>
                            {" "}
                            {errorcls.sub_name[0]}{" "}
                          </span>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
                  <div className="row">
                    <div className="col-lg-3">
                      <label htmlFor="" className="form-label">
                        Duration <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Duration"
                        value={duration}
                        onChange={(e) => {
                          setduration(e.target.value);
                        }}
                      />
                      {errorcls ? (
                        errorcls.duration_days ? (
                          <span style={{ color: "red" }}>
                            {" "}
                            {errorcls.duration_days[0]}{" "}
                          </span>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
                  <div className="row">
                    <div className="col-lg-3">
                      <label htmlFor="" className="form-label customw">
                        <span>Discount</span>{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Discount"
                        value={discount}
                        onChange={(e) => {
                          setdiscount(e.target.value);
                        }}
                        required
                      />
                      {errorcls ? (
                        errorcls.setdiscount ? (
                          <span style={{ color: "red" }}>
                            {" "}
                            {errorcls.setdiscount[0]}{" "}
                          </span>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
                  <div className="row">
                    <div className="col-lg-3">
                      <label htmlFor="" className="form-label">
                        Price <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <input
                        type="number"
                        className="form-control"
                        value={price}
                        placeholder="Price"
                        onChange={(e) => {
                          setprice(e.target.value);
                        }}
                        required
                      />
                      {errorcls ? (
                        errorcls.price ? (
                          <span style={{ color: "red" }}>
                            {" "}
                            {errorcls.price[0]}{" "}
                          </span>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
                  <div className="row">
                    <div className="col-lg-3">
                      <label htmlFor="" className="form-label">
                        Gst <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <input
                        type="number"
                        className="form-control"
                        value={gst}
                        placeholder="Gst"
                        onChange={(e) => {
                          setgst(e.target.value);
                        }}
                        required
                      />
                      {errorcls ? (
                        errorcls.gst_rate ? (
                          <span style={{ color: "red" }}>
                            {" "}
                            {errorcls.gst_rate[0]}{" "}
                          </span>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
                  <div className="row">
                    <div className="col-lg-3">
                      <label htmlFor="" className="form-label">
                        Description <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <input
                        type="text"
                        className="form-control"
                        value={description}
                        placeholder="Description"
                        onChange={(e) => {
                          setdescription(e.target.value);
                        }}
                        required
                      />
                      {errorcls ? (
                        errorcls.sub_desc ? (
                          <span style={{ color: "red" }}>
                            {" "}
                            {errorcls.sub_desc[0]}{" "}
                          </span>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="row">
                    <div className="col-12 px-4">
                      {grandloading == true ? (
                        <div
                          className="col-12 d-flex justify-content-center"
                          style={{
                            gap: "4px",
                            position: "absolute",
                            width: "100%",
                          }}
                        >
                          <div className="px-2">
                            {grandloading == true ? (
                              <Loadercomp size={100} />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div
                      className="col-12 py-2 px-4 d-flex justify-content-end"
                      style={{ gap: "4px" }}
                    >
                      {/* <div className='px-2'>{loading == true ?  <Loadercomp /> : ""}</div> */}
                      <button className="btn4">Cancel</button>
                      <button type="submit" className="btn5">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default Addsubscription;
