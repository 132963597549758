import React, { useEffect } from 'react'
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import img3 from "../../assets/KYC Logo white-bg 2.jpg";
import { FaShoppingCart } from "react-icons/fa";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { ImLocation2 } from "react-icons/im";
import { FaEnvelope,FaFacebookF,FaInstagram,FaTwitter,FaLinkedinIn } from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import { BsQuestionLg } from "react-icons/bs";
import PhoneInput from 'react-phone-number-input'
import Select from "react-select";
import { useState } from 'react';
import csc from 'country-state-city';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { gettoken } from '../../Localstorage/Store';
import Loadercomp from '../../components/Loadercomp';
import { useContext } from 'react';
import Balance from '../../context/Balance';
const Cart = () => {
    const [agentmobile, setagentmobile] = useState();
    const [agentcountry, setagentcountry] = useState(null);
    const [agentstate, setagentstate] = useState(null);
    const [planinfo, setplaninfo] = useState({});
    const [srtloader, setsrtloader] = useState(true);
    const [balno,setbalno] = useContext(Balance)
    const countries = csc.getAllCountries();
const {id} = useParams()
    const gettokinval = gettoken();
    const updatedCountries = countries.map((country) => ({
        label: country.name,
        value: country.id,
        ...country
      }));


      const updatedStates = (countryId) =>
  csc.getStatesOfCountry(countryId)
  .map((state) => ({ label: state.name, value: state.id, ...state }));
    const submitform = ()=>{

    }

    const nvg = useNavigate()
const navigatefeature = (num) =>{
nvg('/',{state:{id:num}})
}






useEffect(() => {
  async function fetchData() {
// if(id == 1){ 
//   const formdata = {
//     sub_name: "Silver",
//     duration_days: 60,
//     price: 800,
//     gst_percentage: 18,
//     sac_code: "12345"
// }
// }
// let formdata = {};
// if(id == 1){ 
//    formdata = {
//     sub_name: "Bronze",
//     duration_days: 30,
//     price: 600,
//     gst_percentage: 18,
//     sac_code: "12345"
// }
// }
// if(id == 2){ 
//    formdata = {
//     sub_name: "Silver",
//     duration_days: 45,
//     price: 800,
//     gst_percentage: 18,
//     sac_code: "12345"
// }
// }

// if(id == 3){ 
//   formdata = {
//    sub_name: "Gold",
//    duration_days: 60,
//    price: 1000,
//    gst_percentage: 18,
//    sac_code: "12345"
// }
// }
// if(id == 4){ 
//   formdata = {
//    sub_name: "Platinum",
//    duration_days: 90,
//    price: 1200,
//    gst_percentage: 18,
//    sac_code: "12345"
// }
// }
    const config = {
      headers: {
        Authorization: `Bearer ${gettokinval.access}`,
        'Content-Type': 'application/json',
      },
    };
  let url = `${process.env.REACT_APP_API_URL}api/service-plans/${id}`;
    try {
      const response = await axios.get(url, config);
      setplaninfo(response.data);
      setsrtloader(false)
// console.log(response)


    } catch (error) {
      // setloading(false)
      // console.log(error)
    }
  }
  fetchData();

}, []);

  return (
    <div style={{margin:'0px',padding:'0px'}}>





<section className='superpadding'>

{srtloader == true ? <div className="container-fuild bg-white"> <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"86%"}}>
        <div className='px-2'> <Loadercomp size={100} /></div>
        </div></div> : <div className="container-fuild pb-4 pt-3 px-2 bg-white">
          <form autoComplete="off" onSubmit={submitform}>
            <div
              className="row bg-white pb-4 px-3 round"
              style={{
                border: "1px solid #E0E0E0",
                margin: "10px 0px",
                borderRadius: "3px",
                position:'relative'
              }}
            >

              
             <div className="col-md-12 px-2 pb-3 pt-4">
                <div className="row">
                  {/* <div className="col-lg-3">
                    <label htmlFor="" className="form-label"></label>
                  </div> */}
                  <div className="col-lg-7 d-flex justify-content-start">
                    <span style={{color:'rgba(12, 83, 152, 1)',fontSize:'24px',fontWeight:'600'}}>
                      {" "}
                     Cart (<span style={{color:'black',fontSize:'27px'}}>Total=</span><span style={{color:'black',fontSize:'27px'}}>₹{planinfo.total_amount}</span>)
                    </span>
                  </div>


                  <div className="col-lg-5 d-flex justify-content-end align-items-center">
                    <NavLink to='/subscriptionplan'>
                  <button type="button" className="btn5withoutradius" style={{height: "62%",
    background: "#0c5398"}}>
                 Back
                </button>
                </NavLink>
                  </div>
                </div>
              </div>
          



              {/* <div className="col-md-12 px-2 pt-4">
                <div className="row">
                  <div className="col-12">
                    <label htmlFor="" className="form-label" style={{color:'rgba(12, 83, 152, 1)',fontSize:'20px',fontWeight:'600'}}>
                    Subscription Details
                    </label>
                  </div>
                  <div className="col-12 outerunderline" style={{position:'relative',width:'98%',height:'3px',background:'grey',margin:'0 auto'}} >
    <div className="specialinderline"></div>
                 
                  </div>
                </div>
              </div> */}

{/* 
            
              <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor=""  className="form-label customw">
                     <span>Plan Name</span>  
                    </label>
                  </div>
                  <div className="col-lg-9 d-flex justify-content-center">
         <p>
{planinfo.sub_name}
         </p>
         
 
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label customw">
                      Duration {" "}
                    </label>
                  </div>
                  <div className="col-lg-9 d-flex justify-content-center">
                 <p>{planinfo.duration_days}</p>
             
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label customw">
                      Price {" "}
                    </label>
                  </div>
                  <div className="col-lg-9 d-flex justify-content-center">
                 <p>₹{planinfo.price}</p>
             
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label customw">
                    GST {" "}
                    </label>
                  </div>
                  <div className="col-lg-9 d-flex justify-content-center">
                 <p>{planinfo.gst_percentage} %</p>
             
                  </div>
                </div>
              </div>
              <div className="col-md-6 offset-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label customw">
                  Total Amount
                    </label>
                  </div>
                  <div className="col-lg-9 d-flex justify-content-center">
                 <p>₹{planinfo.total_amount}</p>
             
                  </div>
                </div>
              </div> */}

<div className="col-md-12 px-2 pt-4">
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-5 mb-4">
                  {/* <div className="owlwidth ">
                <div className="single-pricing2">
                  <div className="pop-label">Popular</div>
                  <div className="head-text">
                    <h3 style={{textAlign:'center'}}>{planinfo.sub_name} </h3>
                  </div>
                  <div className="price-area22 text-center">
                  ₹{planinfo.price} <span className="duration">{planinfo.sub_desc}</span>
                  </div>
                  <div className="btn-area">  
                  </div>
                </div>
              </div> */}








<div className="owlwidth">
                <div className="single-pricing position-relative">
                {/* {planinfo.discount !== "0.00" ? <span class="position-absolute top-0 start-100 p-2 border-light rounded-circle" style={{background:'#FFDD85',transform:"translate(-73%,-50%)",boxShadow: "5px 3px 7px 0 rgba(0, 0, 0, 0.6)"}}>
    <span class="d-flex" style={{padding : parseInt(planinfo.discount) > 9 ? '' : '0px 4px'}}><span style={{color:'red',fontSize:'31px',fontWeight:'700px'}}>{Math.floor(planinfo.discount)}</span><span className="d-flex justify-content-center align-items-center" style={{color:'red',fontSize:'9px',fontWeight:'400px'}}>% <br /> OFF</span></span>
  </span> : ''} */}
                
                  <div className="head-text" style={{padding:'0px',paddingBottom:parseInt(planinfo.discount) == 0 ? '20px' : '0px'}} >
                      <p style={{marginBottom:'0px'}}> <span className="subcarttitle" style={{background:planinfo.sub_name == 'BRONZE' ? '#CD7F32' : planinfo.sub_name == 'SILVER' ? '#A8A8A8' : planinfo.sub_name == 'GOLD' ? '#CBA135' : `linear-gradient(hsla(0, 0%, 35%, 1),hsla(0, 12%, 63%, 1),hsla(0, 0%, 71%, 1),hsla(0, 4%, 47%, 1),hsla(0, 0%, 100%, 1))`}}>{planinfo.sub_name}</span></p>
                  </div>
                  <div className="price-area" style={{display:parseInt(planinfo.discount) == 0 ? 'none' : ''}}>
                   <span className="duration" style={{display:parseInt(planinfo.discount) == 0 ? 'none' : ''}}> Rs.{parseInt(planinfo.price)}</span> +GST
                  </div>
                  <p style={{display:parseInt(planinfo.discount) == 0 ? 'none' : '',color:parseInt(planinfo.discount) == 0 ? 'transparent' : 'red',marginBottom:'0px',textAlign:'center'}}>Now at Just</p>
                  <div className="price-area p-0" style={{display:'flex',flexWrap:'wrap'}}>
                    <span className="normaltxt">Rs.</span>
                   <span className="duration23" style={{fontSize:'28px'}}> {planinfo.discounted_price}</span> <span className="normaltxt"> +GST</span>
                  </div>
                  <p style={{color:'white',marginBottom:'0px',textAlign:'center',fontSize:'30px'}}>{planinfo.sub_desc}</p>
                </div>
              </div>









                  </div>
                  <div className="col-xl-7 col-lg-6 col-md-5">
                  <h3 style={{color:'#282529',fontSize:'20px',fontWeight:'500'}}>Plan Name : <span>{planinfo.sub_name}</span></h3>
                  <h3 style={{color:'#282529',fontSize:'20px',fontWeight:'500'}}>Duration : <span>{planinfo.duration_days}</span></h3>
                 {parseInt(planinfo.discount) == 0 ? '' : <h3 style={{color:'#282529',fontSize:'20px',fontWeight:'500'}}>Discount : <span>{planinfo.discount}%</span></h3> } 
                  <h3 style={{color:'#282529',fontSize:'20px',fontWeight:'500'}}>GST : <span>{planinfo.gst_rate} %</span></h3>
                  <h3 style={{color:'#282529',fontSize:'20px',fontWeight:'500'}}>Price : <span>₹{planinfo.price}</span></h3>
                  <h3 style={{color:'#282529',fontSize:'20px',fontWeight:'500'}}> Total Amount : <span>₹{planinfo.total_amount}</span></h3>
                   
                  </div>
                </div>
              </div>

  
    
              {/* {id == 1 || id == 2 ? <div
        className="col-12 py-2 pt-5 px-2 d-flex justify-content-end"
        style={{ gap: "4px" }}
      >
          { balno < planinfo.total_amount ?  <button
                   className="btn5withoutradius"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal1400"
              >Next</button> : <NavLink to='/checkout'><button type='button' className="btn5withoutradius">Next</button></NavLink>}
        
      </div> : '' }      */}


 <div
        className="col-12 py-2 pt-5 px-2 d-flex justify-content-end"
        style={{ gap: "4px" }}
      >
        
          {balno > parseFloat(planinfo.total_amount) ? <NavLink to={`/checkout/${planinfo.sub_id}`}><button type='button' className="btn5withoutradius">Next</button></NavLink> : <button
                   className="btn5withoutradius"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal1400"
              >Next</button> }
        
      </div> 
      {/* {id == 1 ? <div
        className="col-12 py-2 pt-5 px-2 d-flex justify-content-end"
        style={{ gap: "4px" }}
      >
        
          {balno > 708 ? <NavLink to='/checkout/1'><button type='button' className="btn5withoutradius">Next</button></NavLink> : <button
                   className="btn5withoutradius"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal1400"
              >Next</button> }
        
      </div> :''} */}


      {/* {id == 2 ? <div
        className="col-12 py-2 pt-5 px-2 d-flex justify-content-end"
        style={{ gap: "4px" }}
      >
        
          {balno > 944 ? <NavLink to='/checkout/2'><button type='button' className="btn5withoutradius">Next</button></NavLink> : <button
                   className="btn5withoutradius"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal1400"
              >Next</button> }
        
      </div> :''} */}









      {/* {id == 3 ? <div
        className="col-12 py-2 pt-5 px-2 d-flex justify-content-end"
        style={{ gap: "4px" }}
      >
        
          {balno > 1180 ? <NavLink to='/checkout/3'><button type='button' className="btn5withoutradius">Next</button></NavLink> : <button
                   className="btn5withoutradius"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal1400"
              >Next</button> }
        
      </div> :''}
      {id == 4 ? <div
        className="col-12 py-2 pt-5 px-2 d-flex justify-content-end"
        style={{ gap: "4px" }}
      >
        
          {balno > 1416 ? <NavLink to='/checkout/4'><button type='button' className="btn5withoutradius">Next</button></NavLink> : <button
                   className="btn5withoutradius"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal1400"
              >Next</button> }
        
      </div> :''} */}
              

              {/* next page */}
              {/* <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                    Corporate 
                    </label>
                  </div>
                  <div className="col-lg-9">
                   <select  className="form-select" aria-label="Default select example">
                    <option value="Please select corporate">Please select corporate</option>
                    <option value="agent">agent</option>
                    <option value="company">company</option>
                   </select>
                   
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Product
                    </label>
                  </div>
                  <div className="col-lg-9">
                  <select  className="form-select" aria-label="Default select example">
                    <option value="Please select corporate">Please select Product</option>
                    <option value="agent">agent</option>
                    <option value="company">company</option>
                   </select>
                  </div>
                </div>
              </div>


              <div className="col-md-12 px-2 pt-4">
                <div className="row">
                  <div className="col-12">
                    <label htmlFor="" className="form-label" style={{color:'rgba(12, 83, 152, 1)',fontSize:'20px',fontWeight:'600'}}>
                    Personal Details
                    </label>
                  </div>
                  <div className="col-12 outerunderline" style={{position:'relative',width:'98%',height:'3px',background:'grey',margin:'0 auto'}} >
    <div className="specialinderline"></div>
                 
                  </div>
                </div>
              </div> */}


            
              {/* <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor=""  className="form-label customw">
                     <span>Username</span>  
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Username"

                      required
                    />
 
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label customw">
                      Email Address {" "}
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="email"
                      className="form-control"
                   placeholder='Email'
                      required
                    />
             
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      First Name 
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      className="form-control"
                 
                      placeholder="Name"
                
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Last Name 
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      className="form-control"
                 
                      placeholder="Last Name"
                
                      required
                    />
                  </div>
                </div>
              </div> */}

{/* 
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Gender 
                    </label>
                  </div>
                  <div className="col-lg-9">
                  <select
                      name=""
                      id=""
                      className="form-select"
                   
                      aria-label="Default select example"
                    >
                      <option value="Male">male</option>
                      <option value="Female">female</option>
                      <option value="Others">others</option>
                    </select>
                   
                  </div>
                </div>
              </div>

              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Mobile No. 
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <PhoneInput
placeholder="Enter phone number"
className="form-control"
value={agentmobile}
onChange={setagentmobile}
required
/>
                   
                  </div>
                </div>
              </div>


              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                    Company 
                    </label>
                  </div>
                  <div className="col-lg-9">
                   <select  className="form-select" aria-label="Default select example">
                    <option value="Please select corporate">select Company</option>
                    <option value="agent">axis</option>
                    <option value="company">jio</option>
                   </select>
                   
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Location
                    </label>
                  </div>
                  <div className="col-lg-9">
                  <select  className="form-select" aria-label="Default select example">
                    <option value="Please select corporate">select Location</option>
                    <option value="agent">delhi</option>
                    <option value="company">dwarka</option>
                   </select>
                  </div>
                </div>
              </div>
            
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Country 
                    </label>
                  </div>
                  <div className="col-lg-9">
              
                    <Select
               placeholder="Select Country"
               options={updatedCountries}
               value={agentcountry}
               onChange={(e) => {
                setagentcountry(e);
              }}
               required
             />
                 
                  </div>
                </div>
              </div>

              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      State 
                    </label>
                  </div>
                  <div className="col-lg-9">
                   
                     <Select
          id="state"
          name="state"
          options={updatedStates(agentcountry == null ? null : agentcountry.value)}
          placeholder="Select State"
          onChange={(e) => {
            setagentstate(e);
          }}
          
          required
        />
                
                  </div>
                </div>
              </div> */}



              {/* <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Address 
                    </label>
                  </div>
                  <div className="col-lg-9">
                   
                  <input
                      type="text"
                      className="form-control"
                 
                      placeholder="Address"
                
                      required
                    />
                   
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Pincode 
                    </label>
                  </div>
                  <div className="col-lg-9">
                  <input type="tel" className="form-control" maxLength={6}
                  minLength={6}  placeholder='Pincode' required />

                  </div>
                </div>
              </div>




              <div className="col-md-12 px-2 pt-4">
                <div className="row">
                  <div className="col-12">
                    <label htmlFor="" className="form-label" style={{color:'rgba(12, 83, 152, 1)',fontSize:'20px',fontWeight:'600'}}>
                    Branch Details
                    </label>
                  </div>
                  <div className="col-12 outerunderline" style={{position:'relative',width:'98%',height:'3px',background:'grey',margin:'0 auto'}} >
    <div className="specialinderline"></div>
                 
                  </div>
                </div>
              </div> */}



{/*               
              <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor=""  className="form-label customw">
                     <span>Username</span>  
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Username"

                      required
                    />
 
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label customw">
                      Email Address {" "}
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="email"
                      className="form-control"
                   placeholder='Email'
                      required
                    />
             
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      First Name 
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      className="form-control"
                 
                      placeholder="Name"
                
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Last Name 
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      className="form-control"
                 
                      placeholder="Last Name"
                
                      required
                    />
                  </div>
                </div>
              </div>


              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Gender 
                    </label>
                  </div>
                  <div className="col-lg-9">
                  <select
                      name=""
                      id=""
                      className="form-select"
                   
                      aria-label="Default select example"
                    >
                      <option value="Male">male</option>
                      <option value="Female">female</option>
                      <option value="Others">others</option>
                    </select>
                   
                  </div>
                </div>
              </div>

              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Mobile No. 
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <PhoneInput
placeholder="Enter phone number"
className="form-control"
value={agentmobile}
onChange={setagentmobile}
required
/>
                   
                  </div>
                </div>
              </div> */}


              {/* <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                    Company 
                    </label>
                  </div>
                  <div className="col-lg-9">
                   <select  className="form-select" aria-label="Default select example">
                    <option value="Please select corporate">select Company</option>
                    <option value="agent">axis</option>
                    <option value="company">jio</option>
                   </select>
                   
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Location
                    </label>
                  </div>
                  <div className="col-lg-9">
                  <select  className="form-select" aria-label="Default select example">
                    <option value="Please select corporate">select Location</option>
                    <option value="agent">delhi</option>
                    <option value="company">dwarka</option>
                   </select>
                  </div>
                </div>
              </div>
            
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Country 
                    </label>
                  </div>
                  <div className="col-lg-9">
              
                    <Select
               placeholder="Select Country"
               options={updatedCountries}
               value={agentcountry}
               onChange={(e) => {
                setagentcountry(e);
              }}
               required
             />
                 
                  </div>
                </div>
              </div>

              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      State 
                    </label>
                  </div>
                  <div className="col-lg-9">
                   
                     <Select
          id="state"
          name="state"
          options={updatedStates(agentcountry == null ? null : agentcountry.value)}
          placeholder="Select State"
          onChange={(e) => {
            setagentstate(e);
          }}
          
          required
        />
                
                  </div>
                </div>
              </div>



              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Address 
                    </label>
                  </div>
                  <div className="col-lg-9">
                   
                  <input
                      type="text"
                      className="form-control"
                 
                      placeholder="Address"
                
                      required
                    />
                   
                  </div>
                </div>
              </div>
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Pincode 
                    </label>
                  </div>
                  <div className="col-lg-9">
                  <input type="tel" className="form-control" maxLength={6}
                  minLength={6}  placeholder='Pincode' required />

                  </div>
                </div>
              </div> */}
            
              {/* <div
        className="col-12 py-2 pt-5 px-4 d-flex justify-content-end"
        style={{ gap: "4px" }}
      >
        
        <button type='submit' className="btn5withoutradius">Make Payment</button>
      </div> */}
    
    
            </div>








            <div
        className="modal fade"
        id="exampleModal1400"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
          <BsQuestionLg className='question-logo' />
            {/* <span className="question-logo" style={{display:"flex",justifyContent:"center",alignItems:'center'}} ></span> */}
            {/* <i class="fa fa-exclamation" aria-hidden="true"></i> */}
            <div className="modal-header mod-line">
            </div>
            <div className="modal-body">
              <div className="row gy-3 mt-2">
                <h1 className="ccedit-h">Wallet Error</h1>
                <p className="ccedit-p">
                  Your Wallet Balance is Lower than plan . Please Recharge Your Wallet First
                </p>
              </div>
            </div>
            <div className="modal-footer mod-line m-auto">
            {/* <button type="button" className="btn closecancel" data-bs-dismiss="modal"
                aria-label="Close">Cancel</button> */}
 <NavLink to='/walletrecharge/1'>
              <button
 
                type="button"
                className="btn closebtn text-white"
                data-bs-dismiss="modal"
                // aria-label="Close"
              >
                Wallet Recharge
              </button>
                </NavLink>
            </div>
          </div>
        </div>
      </div>









          </form>

        </div>}
</section>



































    </div>
  )
}

export default Cart