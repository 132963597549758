import React, { useEffect, useRef } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import img3 from "../../assets/KYC Logo white-bg 2.jpg";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { IoIosWallet } from "react-icons/io";
import PhoneInput from "react-phone-number-input";
import Select from "react-select";
import img2 from "../../assets/wallet.png";

import { useState } from "react";
import csc from "country-state-city";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useContext } from "react";
import Balance from "../../context/Balance";
import StripeCheckout from "react-stripe-checkout";
import { gettoken } from "../../Localstorage/Store";
import axios from "axios";
import Loadercomp from "../../components/Loadercomp";
const Walletrecharge = () => {
  const [agentmobile, setagentmobile] = useState();
  const [agentcountry, setagentcountry] = useState(null);
  const [rechargesucc, setrechargesucc] = useState(false);
  const [rechargeerror, setrechargeerror] = useState(false);
  const [loader, setloader] = useState(false);
  const [agentstate, setagentstate] = useState(null);
  const [rechargevalue, setrechargevalue] = useState(6000);
  const [balno,setbalno] = useContext(Balance)
  const { id} = useParams();
  const stript = useRef(null);
  const gettokinval = gettoken();
  const countries = csc.getAllCountries();

  const amountInCents = rechargevalue * 100; // Convert rupees to cents
  const formattedAmount = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  }).format(rechargevalue);
  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.id,
    ...country,
  }));

  const updatedStates = (countryId) =>
    csc
      .getStatesOfCountry(countryId)
      .map((state) => ({ label: state.name, value: state.id, ...state }));
  const submitform = (e) => {
    e.preventDefault();
    if (stript.current) {
      stript.current.click();
    }
  };

  const nvg = useNavigate();
  const navigatefeature = (num) => {
    nvg("/", { state: { id: num } });
  };

// console.log("recharge amount",rechargevalue)


  async function submitformtwo() {
  
    setloader(true)         
    const formdata = new FormData();
                                formdata.append("amount",parseFloat(rechargevalue));
                                formdata.append("description", `Recharge Wallet - ${gettokinval.id} - ${gettokinval.first_name} ${gettokinval.last_name}`);
                                try {
                                  const config = {
                                    headers: {
                                      Authorization: `Bearer ${gettokinval.access}`,
                                    },
                                  };
                                  let url = `${process.env.REACT_APP_API_URL}api/wallet/add_money/`;
                                  let urltwo = `${process.env.REACT_APP_API_URL}api/wallet/`;
                                  const response = await axios.post(url, formdata, config);
                                  // console.log("dkdkdkd",response);
                                  setloader(false)
                                  window.location.reload();
                                  if(response.data.client_secret){
                                    const responsetwo = await axios.get(urltwo, config);
                                    // console.log(responsetwo)
                                    setbalno(responsetwo.data[0].balance)
                                    setrechargesucc(true)
                                    setTimeout(() => {
                                      setrechargesucc(false)
                                      window.location.reload();
                                    }, 5000);
                                  }
                                } catch (error) {
                            //  console.log("first",error)
                             setrechargeerror(true)
                             setloader(false)
                             setTimeout(() => {
                              setrechargeerror(false)
                            }, 5000);
                                }
                          
  }

  const onToken = (token) =>{
    // console.log(token)
    if(token.created){
      submitformtwo();
    }
  }

  const [planinfo, setplaninfo] = useState([]);


  useEffect(() => {
    setloader(true)
    async function fetchData() {
      const config = {
        headers: {
          Authorization: `Bearer ${gettokinval.access}`,
          'Content-Type': 'application/json',
        },
      };
    let url = `${process.env.REACT_APP_API_URL}api/service-plans/`;
      try {
        const response = await axios.get(url, config);
        setplaninfo(response.data);
        if (response.data.length > 0) {
          setrechargevalue(parseFloat(response.data[0].total_amount) +1); // Set the first amount to the state
        }
        setloader(false)
  // console.log(response)
  
      } catch (error) {
        // setloading(false)
        // console.log(error)
      }
    }
    fetchData();
  
  }, []);
  
  return (
    <div style={{ margin: "0px", padding: "0px" }}>
      <section className="superpadding9999">
      {loader == true ? <div className="container-fuild bg-white"> <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"85%"}}>
        <div className='px-2'> <Loadercomp size={100} /></div>
        </div></div> : <div className="container-fuild pb-4 pt-3 px-2 bg-white">
          <form autoComplete="off" onSubmit={submitform}>
            
            <div
              className="row bg-white pb-4 px-3 round"
              style={{
                border: "1px solid #E0E0E0",
                margin: "10px 0px",
                borderRadius: "3px",
                position: "relative",
              }}
            >

              <div className="col-md-12 px-2 pt-2">
                <div className="row">
                  {/* <div className="col-lg-3">
                    <label htmlFor="" className="form-label"></label>
                  </div> */}
                  <div className="col-lg-7 col-6 d-flex justify-content-start">
                    <span>
                      {" "}
                      {/* <IoIosWallet fontSize={56} color="rgba(12, 83, 152, 1)" /> */}
                      <img src={img2} alt="404" style={{width:'48px',paddingRight:'4px'}} />
                    </span>
                    <span
                      style={{
                        fontWeight: "700",
                        fontSize: "23px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {"  "}
                      ₹ {balno}
                    </span>
                  </div>


                  <div className="col-lg-5 col-6 d-flex justify-content-end align-items-center">
                {gettokinval.superuser == false && gettokinval.staff == false ? '' : <NavLink to='/subscriptionplan' style={{marginRight:'4px'}}>
                  <button type="submit" className="btn5withoutradius" style={{height: "62%",
    background: "#0c5398"}}>
      {id == 1 ? 'Subscription plan ': 'Back' }
                 
                </button>
                </NavLink>}  
                    <NavLink to='/wallethistory'>
                  <button type="button" className="btn5withoutradius" style={{height: "62%",
    background: "#0c5398"}}>
                 Wallet History
                </button>
                </NavLink>
                  </div>
                </div>
              </div>

              <div className="col-md-12 px-2 pt-4">
                <div className="row">
                  <div className="col-12">
                    <label
                      htmlFor=""
                      className="form-label"
                      style={{
                        color: "rgba(12, 83, 152, 1)",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                    >
                      {/* Fill Your Location Address For Wallet Recharge   */}
                      Wallet Recharge
                    </label>
                  </div>
                  <div
                    className="col-12 outerunderline"
                    style={{
                      position: "relative",
                      width: "98%",
                      height: "3px",
                      background: "grey",
                      margin: "0 auto",
                    }}
                  >
                    <div className="specialinderline00022"></div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label customw">
                      Email Address{" "}
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="email"
                      readOnly
value={gettokinval.email}
                      className="form-control"
                      placeholder="Email"
                      required
                    />
                  </div>
                </div>
              </div>
{/* 
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Mobile No.
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <PhoneInput
                      placeholder="Enter phone number"
                      className="form-control"
                      value={agentmobile}
                      onChange={setagentmobile}
                      required
                    />
                  </div>
                </div>
              </div> */}
              {/* <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      First Name
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      required
                    />
                  </div>
                </div>
              </div> */}

              {/* <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Last Name
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      required
                    />
                  </div>
                </div>
              </div> */}

              {/* <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Gender
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <select
                      name=""
                      id=""
                      className="form-select"
                      aria-label="Default select example"
                    >
                      <option value="Male">male</option>
                      <option value="Female">female</option>
                      <option value="Others">others</option>
                    </select>
                  </div>
                </div>
              </div> */}

              {/* <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Country
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <Select
                      placeholder="Select Country"
                      options={updatedCountries}
                      value={agentcountry}
                      onChange={(e) => {
                        setagentcountry(e);
                      }}
                      required
                    />
                  </div>
                </div>
              </div> */}

              {/* <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      State
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <Select
                      id="state"
                      name="state"
                      options={updatedStates(
                        agentcountry == null ? null : agentcountry.value
                      )}
                      placeholder="Select State"
                      onChange={(e) => {
                        setagentstate(e);
                      }}
                      required
                    />
                  </div>
                </div>
              </div> */}

              {/* <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Address
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Address"
                      required
                    />
                  </div>
                </div>
              </div> */}
              {/* <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Pincode
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <input
                      type="tel"
                      className="form-control"
                      maxLength={6}
                      minLength={6}
                      placeholder="Pincode"
                      required
                    />
                  </div>
                </div>
              </div> */}

              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="" className="form-label">
                      Recharge Amount
                    </label>
                  </div>
                  <div className="col-lg-9">
                    {/* <select
                    onChange={(e)=>{setrechargevalue(e.target.value)}}
                      className="form-select"
                      aria-label="Default select example"
                    >
                      <option value="6000">6000</option>
                      <option value="8000">8000</option>
                      <option value="12000">12000</option>
                      <option value="14000">14000</option>
                    </select> */}
                      <input
                      type="number"
               
onChange={(e)=>{setrechargevalue(e.target.value)}}
                      className="form-control"
                      placeholder="Amount"
                      required
                    />
                    <div className="boxcontainer d-flex pt-2" style={{gap:"4px",flexWrap:'wrap'}}>
                    { planinfo.map((item,index) => (
                   <div className="minbox" style={{border:'1px solid #ced4da',padding:'3px 6px',fontSize:'13px',color: rechargevalue == parseInt(item.total_amount) +1 ? 'white' : '',background: rechargevalue == parseInt(item.total_amount) +1 ? 'red' : '#e9ecef',borderRadius:'2px'}} onClick={e=>{setrechargevalue(parseInt(item.total_amount) + 1)}}>
                   ₹{parseInt(item.total_amount) + 1}<span style={{fontSize:'9px'}}>(incl. gst)</span> 
                   </div>
                    ))}
                  
            
                    </div>
                  </div>
                </div>
              </div>
<div className="col-9 pt-5">
  {rechargesucc == true ? <div class="alert alert-success" role="alert">
 Recharge Successfully
</div> : ''}
  {rechargeerror == true ? <div class="alert alert-danger" role="alert">
 Something Went Wrong Try Again
</div> : ''}
</div>
              <div
                className="col-3 py-2 pt-5 px-4 d-flex justify-content-end"
                style={{ gap: "4px" }}
              >

<StripeCheckout
ref={stript}
// style={{width:"0px",height:'0px'}}
        token={onToken}
        stripeKey="pk_test_51NYo2LSFkTti3NiNJPCL0xQKkWTOF2uORVmrZX3WlHhn6rsaJbk12B2qtgN8xOjXrmmqv40g9O1CC20YFsLCTnSX00bZJ2PHXt"
        amount={amountInCents}
        currency="INR"
        email={gettokinval.email}
        description="Recharge Wallet">
                <button type="submit" className="btn5withoutradius">
                  Make Payment
                </button>
                  </StripeCheckout>
              </div>
            </div>
          </form>
        </div>}
      
      </section>
    </div>
  );
};

export default Walletrecharge;




































// new


// import React, { useEffect, useRef } from "react";
// import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import img3 from "../../assets/KYC Logo white-bg 2.jpg";
// import { FaShoppingCart } from "react-icons/fa";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
// import { ImLocation2 } from "react-icons/im";
// import {
//   FaEnvelope,
//   FaFacebookF,
//   FaInstagram,
//   FaTwitter,
//   FaLinkedinIn,
// } from "react-icons/fa";
// import { IoIosWallet } from "react-icons/io";
// import { BsFillTelephoneFill } from "react-icons/bs";
// import PhoneInput from "react-phone-number-input";
// import Select from "react-select";
// import { useState } from "react";
// import csc from "country-state-city";
// import { NavLink, useNavigate, useParams } from "react-router-dom";
// import { useContext } from "react";
// import Balance from "../../context/Balance";
// import StripeCheckout from "react-stripe-checkout";
// import { gettoken } from "../../Localstorage/Store";
// import axios from "axios";
// import Loadercomp from "../../components/Loadercomp";
// const Walletrecharge = () => {
//   const [agentmobile, setagentmobile] = useState();
//   const [ccavenueresp, setccavenueresp] = useState({});
//   const [agentcountry, setagentcountry] = useState(null);
//   const [rechargesucc, setrechargesucc] = useState(false);
//   const [rechargeerror, setrechargeerror] = useState(false);
//   const subform = useRef("");

//   const [loader, setloader] = useState(false);
//   const [agentstate, setagentstate] = useState(null);
//   const [rechargevalue, setrechargevalue] = useState(6000);
//   const [balno,setbalno] = useContext(Balance)
//   const { id} = useParams();
//   const stript = useRef(null);
//   const gettokinval = gettoken();
//   const countries = csc.getAllCountries();

//   const amountInCents = rechargevalue * 100; // Convert rupees to cents
//   const formattedAmount = new Intl.NumberFormat('en-IN', {
//     style: 'currency',
//     currency: 'INR',
//   }).format(rechargevalue);
//   const updatedCountries = countries.map((country) => ({
//     label: country.name,
//     value: country.id,
//     ...country,
//   }));

//   const updatedStates = (countryId) =>
//     csc
//       .getStatesOfCountry(countryId)
//       .map((state) => ({ label: state.name, value: state.id, ...state }));



//   const submitform = async (e) => {
//     e.preventDefault();
//     // if (stript.current) {
//     //   stript.current.click();
//     // }





//     setloader(true)
//     const formdata = new FormData()
//     formdata.append('name',gettokinval.first_name)
//     formdata.append("amount",parseFloat(rechargevalue));
//     formdata.append("email",gettokinval.email);
//     formdata.append("phone",gettokinval.mobile);

//    try {

//     let url = `https://7c0f-2402-8100-2756-2be5-d877-a1a0-6e47-667c.ngrok-free.app/initiate-payment/`;
//     const response = await axios.post(url, formdata);
//       console.log("eldlllldld",response);
//       if(response.status == 200 || response.status == 201){
//         if(response.data.encryption){
//           console.log("check 1")
//           setccavenueresp(response.data)
          
//           console.log("check 2",ccavenueresp)
          
//           setTimeout(() => {
//   console.log("check 3",ccavenueresp)
//   subform.current.submit();
//   setloader(false)
// }, 2000);
//         }
//       }

//    } catch (error) {
//     console.log('something went wrong try again',error)
//     // seterrorcls(error.response.data)

//    }
   
//   };



//   const nvg = useNavigate();
//   const navigatefeature = (num) => {
//     nvg("/", { state: { id: num } });
//   };

// console.log("recharge amount",rechargevalue)


//   async function submitformtwo() {
  
//     setloader(true)         
//     const formdata = new FormData();
//                                 formdata.append("amount",parseFloat(rechargevalue));
//                                 formdata.append("description", "Recharge Wallet");
//                                 try {
//                                   const config = {
//                                     headers: {
//                                       Authorization: `Bearer ${gettokinval.access}`,
//                                     },
//                                   };
//                                   let url = `${process.env.REACT_APP_API_URL}api/wallet/add_money/`;
//                                   let urltwo = `${process.env.REACT_APP_API_URL}api/wallet/`;
//                                   const response = await axios.post(url, formdata, config);
//                                   console.log("dkdkdkd",response);
//                                   setloader(false)
//                                   window.location.reload();
//                                   if(response.data.client_secret){
//                                     const responsetwo = await axios.get(urltwo, config);
//                                     console.log(responsetwo)
//                                     setbalno(responsetwo.data[0].balance)
//                                     setrechargesucc(true)
//                                     setTimeout(() => {
//                                       setrechargesucc(false)
//                                       window.location.reload();
//                                     }, 5000);
//                                   }
//                                 } catch (error) {
//                              console.log("first",error)
//                              setrechargeerror(true)
//                              setloader(false)
//                              setTimeout(() => {
//                               setrechargeerror(false)
//                             }, 5000);
//                                 }
                          
//   }

//   const onToken = (token) =>{
//     console.log(token)
//     if(token.created){
//       submitformtwo();
//     }
//   }

//   const [planinfo, setplaninfo] = useState([]);


//   useEffect(() => {
//     setloader(true)
//     async function fetchData() {
//       const config = {
//         headers: {
//           Authorization: `Bearer ${gettokinval.access}`,
//           'Content-Type': 'application/json',
//         },
//       };
//     let url = `${process.env.REACT_APP_API_URL}api/service-plans/`;
//       try {
//         const response = await axios.get(url, config);
//         setplaninfo(response.data);
//         if (response.data.length > 0) {
//           setrechargevalue(parseFloat(response.data[0].total_amount)); // Set the first amount to the state
//         }
//         setloader(false)
//   console.log(response)
  
//       } catch (error) {
//         // setloading(false)
//         console.log(error)
//       }
//     }
//     fetchData();
  
//   }, []);
  
//   return (
//     <div style={{ margin: "0px", padding: "0px" }}>
//       <section className="superpadding">
//       {loader == true ? <div className="container-fuild bg-white"> <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"85%"}}>
//         <div className='px-2'> <Loadercomp size={100} /></div>
//         </div></div> : <div className="container-fuild pb-4 pt-3 px-2 bg-white">
//           <form autoComplete="off" onSubmit={submitform}>
            
//             <div
//               className="row bg-white pb-4 px-3 round"
//               style={{
//                 border: "1px solid #E0E0E0",
//                 margin: "10px 0px",
//                 borderRadius: "3px",
//                 position: "relative",
//               }}
//             >
//               <div className="col-md-12 px-2 pt-4">
//                 <div className="row">
//                   {/* <div className="col-lg-3">
//                     <label htmlFor="" className="form-label"></label>
//                   </div> */}
//                   <div className="col-lg-7 d-flex justify-content-start">
//                     <span>
//                       {" "}
//                       <IoIosWallet fontSize={56} color="rgba(12, 83, 152, 1)" />
//                     </span>
//                     <span
//                       style={{
//                         fontWeight: "700",
//                         fontSize: "23px",
//                         display: "flex",
//                         justifyContent: "center",
//                         alignItems: "center",
//                       }}
//                     >
//                       {" "}
//                       ₹ {balno}
//                     </span>
//                   </div>


//                   <div className="col-lg-5 d-flex justify-content-end align-items-center">
//                 {gettokinval.superuser == false && gettokinval.staff == false ? '' : <NavLink to='/subscriptionplan' style={{marginRight:'4px'}}>
//                   <button type="submit" className="btn5withoutradius" style={{height: "62%",
//     background: "#0c5398"}}>
//       {id == 1 ? 'Subscription plan ': 'Back' }
                 
//                 </button>
//                 </NavLink>}  
//                     <NavLink to='/wallethistory'>
//                   <button type="button" className="btn5withoutradius" style={{height: "62%",
//     background: "#0c5398"}}>
//                  Wallet History
//                 </button>
//                 </NavLink>
//                   </div>
//                 </div>
//               </div>

//               <div className="col-md-12 px-2 pt-4">
//                 <div className="row">
//                   <div className="col-12">
//                     <label
//                       htmlFor=""
//                       className="form-label"
//                       style={{
//                         color: "rgba(12, 83, 152, 1)",
//                         fontSize: "20px",
//                         fontWeight: "600",
//                       }}
//                     >
//                       {/* Fill Your Location Address For Wallet Recharge   */}
//                       Wallet Recharge
//                     </label>
//                   </div>
//                   <div
//                     className="col-12 outerunderline"
//                     style={{
//                       position: "relative",
//                       width: "98%",
//                       height: "3px",
//                       background: "grey",
//                       margin: "0 auto",
//                     }}
//                   >
//                     <div className="specialinderline00022"></div>
//                   </div>
//                 </div>
//               </div>

//               <div className="col-md-6 px-2 pt-4">
//                 <div className="row">
//                   <div className="col-lg-3">
//                     <label htmlFor="" className="form-label customw">
//                       Email Address{" "}
//                     </label>
//                   </div>
//                   <div className="col-lg-9">
//                     <input
//                       type="email"
//                       readOnly
// value={gettokinval.email}
//                       className="form-control"
//                       placeholder="Email"
//                       required
//                     />
//                   </div>
//                 </div>
//               </div>
// {/* 
//               <div className="col-md-6 px-2 pt-4">
//                 <div className="row">
//                   <div className="col-lg-3">
//                     <label htmlFor="" className="form-label">
//                       Mobile No.
//                     </label>
//                   </div>
//                   <div className="col-lg-9">
//                     <PhoneInput
//                       placeholder="Enter phone number"
//                       className="form-control"
//                       value={agentmobile}
//                       onChange={setagentmobile}
//                       required
//                     />
//                   </div>
//                 </div>
//               </div> */}
//               {/* <div className="col-md-6 px-2 pt-4">
//                 <div className="row">
//                   <div className="col-lg-3">
//                     <label htmlFor="" className="form-label">
//                       First Name
//                     </label>
//                   </div>
//                   <div className="col-lg-9">
//                     <input
//                       type="text"
//                       className="form-control"
//                       placeholder="Name"
//                       required
//                     />
//                   </div>
//                 </div>
//               </div> */}

//               {/* <div className="col-md-6 px-2 pt-4">
//                 <div className="row">
//                   <div className="col-lg-3">
//                     <label htmlFor="" className="form-label">
//                       Last Name
//                     </label>
//                   </div>
//                   <div className="col-lg-9">
//                     <input
//                       type="text"
//                       className="form-control"
//                       placeholder="Last Name"
//                       required
//                     />
//                   </div>
//                 </div>
//               </div> */}

//               {/* <div className="col-md-6 px-2 pt-4">
//                 <div className="row">
//                   <div className="col-lg-3">
//                     <label htmlFor="" className="form-label">
//                       Gender
//                     </label>
//                   </div>
//                   <div className="col-lg-9">
//                     <select
//                       name=""
//                       id=""
//                       className="form-select"
//                       aria-label="Default select example"
//                     >
//                       <option value="Male">male</option>
//                       <option value="Female">female</option>
//                       <option value="Others">others</option>
//                     </select>
//                   </div>
//                 </div>
//               </div> */}

//               {/* <div className="col-md-6 px-2 pt-4">
//                 <div className="row">
//                   <div className="col-lg-3">
//                     <label htmlFor="" className="form-label">
//                       Country
//                     </label>
//                   </div>
//                   <div className="col-lg-9">
//                     <Select
//                       placeholder="Select Country"
//                       options={updatedCountries}
//                       value={agentcountry}
//                       onChange={(e) => {
//                         setagentcountry(e);
//                       }}
//                       required
//                     />
//                   </div>
//                 </div>
//               </div> */}

//               {/* <div className="col-md-6 px-2 pt-4">
//                 <div className="row">
//                   <div className="col-lg-3">
//                     <label htmlFor="" className="form-label">
//                       State
//                     </label>
//                   </div>
//                   <div className="col-lg-9">
//                     <Select
//                       id="state"
//                       name="state"
//                       options={updatedStates(
//                         agentcountry == null ? null : agentcountry.value
//                       )}
//                       placeholder="Select State"
//                       onChange={(e) => {
//                         setagentstate(e);
//                       }}
//                       required
//                     />
//                   </div>
//                 </div>
//               </div> */}

//               {/* <div className="col-md-6 px-2 pt-4">
//                 <div className="row">
//                   <div className="col-lg-3">
//                     <label htmlFor="" className="form-label">
//                       Address
//                     </label>
//                   </div>
//                   <div className="col-lg-9">
//                     <input
//                       type="text"
//                       className="form-control"
//                       placeholder="Address"
//                       required
//                     />
//                   </div>
//                 </div>
//               </div> */}
//               {/* <div className="col-md-6 px-2 pt-4">
//                 <div className="row">
//                   <div className="col-lg-3">
//                     <label htmlFor="" className="form-label">
//                       Pincode
//                     </label>
//                   </div>
//                   <div className="col-lg-9">
//                     <input
//                       type="tel"
//                       className="form-control"
//                       maxLength={6}
//                       minLength={6}
//                       placeholder="Pincode"
//                       required
//                     />
//                   </div>
//                 </div>
//               </div> */}

//               <div className="col-md-6 px-2 pt-4">
//                 <div className="row">
//                   <div className="col-lg-3">
//                     <label htmlFor="" className="form-label">
//                       Recharge Amount
//                     </label>
//                   </div>
//                   <div className="col-lg-9">
//                     {/* <select
//                     onChange={(e)=>{setrechargevalue(e.target.value)}}
//                       className="form-select"
//                       aria-label="Default select example"
//                     >
//                       <option value="6000">6000</option>
//                       <option value="8000">8000</option>
//                       <option value="12000">12000</option>
//                       <option value="14000">14000</option>
//                     </select> */}
//                       <input
//                       type="number"
               
// onChange={(e)=>{setrechargevalue(e.target.value)}}
//                       className="form-control"
//                       placeholder="Amount"
//                       // required
//                     />
//                     <div className="boxcontainer d-flex pt-2" style={{gap:"8px"}}>
//                     { planinfo.map((item,index) => (
//                    <div className="minbox" style={{border:'1px solid #ced4da',padding:'4px 8px',color: rechargevalue == parseFloat(item.total_amount) ? 'white' : '',background: rechargevalue == parseFloat(item.total_amount) ? 'red' : '#e9ecef',borderRadius:'2px'}} onClick={e=>{setrechargevalue(parseFloat(item.total_amount))}}>
//                    ₹{parseFloat(item.total_amount)}
//                    </div>
//                     ))}
                  
            
//                     </div>
//                   </div>
//                 </div>
//               </div>
// <div className="col-9 pt-5">
//   {rechargesucc == true ? <div class="alert alert-success" role="alert">
//  Recharge Successfully
// </div> : ''}
//   {rechargeerror == true ? <div class="alert alert-danger" role="alert">
//  Something Went Wrong Try Again
// </div> : ''}
// </div>
//               <div
//                 className="col-3 py-2 pt-5 px-4 d-flex justify-content-end"
//                 style={{ gap: "4px" }}
//               >
// {/* 
// <StripeCheckout
// ref={stript}
//         token={onToken}
//         stripeKey="pk_test_51NYo2LSFkTti3NiNJPCL0xQKkWTOF2uORVmrZX3WlHhn6rsaJbk12B2qtgN8xOjXrmmqv40g9O1CC20YFsLCTnSX00bZJ2PHXt"
//         amount={amountInCents}
//         currency="INR"
//         email={gettokinval.email}
//         description="Recharge Wallet"> */}
//                 <button type="submit" className="btn5withoutradius">
//                   Make Payment
//                 </button>
//                   {/* </StripeCheckout> */}
//               </div>
//             </div>
//           </form>
//         </div>}
      
//       </section>



//       <form action={`${ccavenueresp.cc_url}/transaction/transaction.do?command=initiateTransaction`}  method="post" ref={subform} >
//         <input type="hidden" style={{height:"0px"}} name="encRequest" value={ccavenueresp.encryption}   />
//         <input type="hidden" style={{height:"0px"}} name="access_code" value={ccavenueresp.access_code}   />
//         <input type="submit" style={{height:"0px",border:'none',outline:'none'}} ref={subform} value="submit" />
//       </form>
//     </div>
//   );
// };

// export default Walletrecharge;
