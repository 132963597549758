import React, { useState } from 'react'
import Breadcup from '../../components/Breadcup';
import Header from '../../components/Header';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { IoAlertCircle } from "react-icons/io5";
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { gettoken } from '../../Localstorage/Store';
const Accountpwd = () => {
    const [notmatch,setnotmatch] = useState()
    const [oldpassword,setoldpassword] = useState()
    const [newpassword,setnewpassword] = useState()
    const [newconfirmpassword,setnewconfirmpassword] = useState()
    const [response,setresponse] = useState()
    const [errorcls,seterrorcls] = useState()
    const gettokinval = gettoken()
    


    async function submitform(e){
        e.preventDefault();
    
        if(newpassword == newconfirmpassword){
          const formdata = new FormData()
          formdata.append('current_password',oldpassword)
          formdata.append('new_password',newpassword)
          formdata.append('confirm_new_password',newconfirmpassword)

         try {
          const config = {
            headers: {
              Authorization: `Bearer ${gettokinval.access}`,
            },
          };
          let url = `${process.env.REACT_APP_API_URL}api/password-reset/`;
          const response = await axios.post(url, formdata, config);
        //   console.log(response)
          setresponse(response);
          setnewpassword('')
          setnewconfirmpassword('')
          setoldpassword('')
          setnotmatch(false)
          seterrorcls('')
         } catch (error) {
          setresponse({statusone:1,message:'Something Went Wrong Try again'})
        seterrorcls(error.response.data)
        setnotmatch(false)
         }
        }else{
          setnotmatch(true)
        }
        
      
      }
    

    return (
        <div style={{ width: '100%' }}>
            {/* <Header /> */}
            <div className="dashboardcontent">
                {/* <Breadcup name={'Security'} /> */}

                <div className="container-fuild px-2 desgin1">
                    <div className="row bg-white ">
                        <div className="col-lg-12 d-flex justify-content-between py-2" style={{ background: 'hsla(210, 85%, 32%, 0.2)', color: '#0C5398' }}>
              <p className="m-0 customfont" >Change Password</p>
              <div className="addnew d-block mb-2">
                <button className="btn text-white closebtn"><NavLink to='/dashboard' style={{ textDecoration: 'none', color: 'white' }}> x Close</NavLink></button>
              </div>
            </div>
                    </div>
                </div>
                <div className="container-fuild px-2 pb-4 pt-3 bg-white">
                    <div className="row bg-white pb-4 round" style={{ border: '1px solid #E0E0E0', margin: "10px 0px", borderRadius: '3px' }}>
                        <div className="col-12 mt-5">
                            <ul className="d-flex list-unstyled mt-auto">
                                <li className="me-auto">
                                    <h4>Change Password</h4>
                                    <p >Set a unqiue password to your account</p>
                                </li>
                                <li className="d-flex align-items-center me-3">
                                    {/* <small>Last changed Oct 2, 2022</small> */}
                                </li>
                                <li className="d-flex align-items-center" >
                                    <button type="button" className="btn5" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                        Change Password
                                    </button>
                                </li>
                            </ul>
                        </div>


                        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <form onSubmit={submitform}>
                            <div className="modal-dialog modal-dialog-centered ">
                                <div className="modal-content">
                                    
                                    <div className="modal-header mod-line">
                                    {response ? response.status === 200 ?	<div class="alert alert-success" >
		Password Updated successfully
	</div> : '' : ''}  
                                        {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>

                                    <div className="modal-body">
                                        <div className="row gy-3">
                                            <div className="col-md-6">
                                                <label for="cc-expiration" className="form-label">Old Password</label>
                                                <input type="text" className="form-control" id="cc-expiration" placeholder="old Password" required value={oldpassword} onChange={(e) => {setoldpassword(e.target.value)}} />
                                                {errorcls ? errorcls.non_field_errors ? <span style={{color:'red'}}> {errorcls.non_field_errors[0]} </span> : '' : ''}
                                            </div>
                                        </div>
                                        <div className="row gy-3 mt-2">
                                            <div className="col-md-6">
                                                <label for="cc-name" className="form-label">New Password</label>
                                                <input type="text" className="form-control" id="cc-name" placeholder="New Password" required value={newpassword} onChange={(e) => {setnewpassword(e.target.value)}} />
                                                
                                            </div>

                                            <div className="col-md-6">
                                                <label for="cc-number" className="form-label">Confirm New Password</label>
                                                <input type="text" className="form-control" id="cc-number" placeholder="Confirm New Password" required value={newconfirmpassword} onChange={(e) => {setnewconfirmpassword(e.target.value)}} />
                                                {notmatch == true ? <span style={{color:'red'}}>Confirm Password didn't Match</span> : <span></span>}
                                            </div>
                                            {/* <p className="acc"><IoAlertCircle/> Password should be minimun 8 letter
                                                 and include lower and uppercase letter</p> */}
                                        </div>
                                    </div>
                                    <div className="modal-footer mod-line">
                                        <button type="submit" className="btn btn-primary">Update</button>
                                    </div>
                                </div>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Accountpwd