import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { NavLink, useParams } from "react-router-dom";
import { RiFileExcel2Fill } from "react-icons/ri";
import { gettoken } from "../../Localstorage/Store";
import axios from "axios";
import $ from "jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-dt/js/dataTables.dataTables.min.js";
import 'datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css';
import 'datatables.net-buttons/js/dataTables.buttons.min.js';
import 'datatables.net-buttons/js/buttons.html5.min.js';
import 'datatables.net-buttons/js/buttons.print.min.js';
import 'datatables.net-buttons-bs5/js/buttons.bootstrap5.min.js';
import { CSVLink } from "react-csv";

const Logtracklist = () => {
    const [data, setData] = useState([]);
    const [idno, setidno] = useState(0);
    const [loading,setloading] = useState(true);
    const gettokinval = gettoken();
    const {id} = useParams()
    useEffect(() => {
      setidno(id)
      function showAlert() {
        setTimeout(function() {
         setidno(0)
        }, 5000); // 5000 milliseconds (5 seconds) delay
      }
      showAlert()
      async function fetchData() {
        const config = {
          headers: {
            Authorization: `Bearer ${gettokinval.access}`,
          },
        };
        let url = `${process.env.REACT_APP_API_URL}api/reports/`;
        const response = await axios.get(url, config);
        setData(response.data);
        setloading(false)
        $(document).ready(function () {
          $("#myTable").DataTable().destroy();
          $("#myTable").DataTable({
            pageLength: 10,
            language: {
              searchPlaceholder: 'Search...'
            },
            dom: 'Bfrtip', // Add the buttons to the DataTable
            buttons: ['excel']
          });
        });
      }
      fetchData();
    }, []);
    return (
        <div className="minheight" style={{ width: "100%", minHeight: '100vh' }}>
            {/* <Header /> */}
            <div className="dashboardcontent px-2">
                {/* <Breadcup name={"Products"} /> */}
                <div className="container-fluid py-3 bg-body">
                    <div className="col-lg-12 d-flex justify-content-between">
                        <p className="m-0 customfont">Log Tracking List</p>
                        <div className="addnew d-lg-none d-block mb-2">
                            <button className="btn text-white customcolor2"><NavLink to='/addremittance' style={{ textDecoration: 'none', color: 'white' }}> + Add New</NavLink></button>
                        </div>
                        <div className="d-lg-block">
                            <CSVLink data={data} filename="Reports" className="btn6" style={{textDecoration:"none"}}><RiFileExcel2Fill style={{ color: "green" }} /><span> Export To Excel</span></CSVLink>
                            {/* <button className="btn6"><RiFileExcel2Fill style={{ color: "green" }} /><span> Export To Excel</span></button> */}

                        </div>
                    </div>
                    {/* <div className="row bg-white py-3 rounded-top">
                        <div className="col-lg-12 d-flex justify-content-center align-items-center" style={{ gap: "4px" }}>
                            <div className="">
                                <input type="date" className="form-control" placeholder='From date' />
                            </div>
                            <div className="">
                                <input type="date" className="form-control" placeholder='To date' />
                            </div>
                            <div className="">
                                <input type="text" className="form-control" placeholder='User Id' />
                            </div>
                            <div className="">
                                <input type="text" className="form-control" placeholder='Transaction name ' />
                            </div>

                            <div className="d-lg-block">
                                <button className='btn4'><span>Clear</span><span>All</span></button>
                            </div>
                            <div className="addnew d-lg-block">
                                <button className="btn text-white d-flex customcolor2"><span> Apply</span> <span> Filter</span></button>
                            </div>
                        </div>
                    </div> */}
                    <div className="row bg-white py-3">
            <div className="col-9 d-flex" style={{ gap: "9px" }}>
              <input
                type="date"
                className="form-control"
                placeholder="From date"
              />
              <input
                type="date"
                className="form-control"
              />

              <input
                type="text"
                className="form-control"
                placeholder="User Id"
              />
              <input
                type="text"
                className="form-control"
                placeholder="Transaction Name"
              />
              {/* <select
                className="form-select"
                aria-label="Default select example"
              >
                <option value="All">All</option>
                <option value="Credit">Credit</option>
                <option value="Debit">Debit</option>
              </select> */}
            </div>
            <div
              className="col-3 d-flex justify-content-end"
              style={{ gap: "5px" }}
            >
              <div className="d-lg-block">
                <button
                  className="btn"
                  style={{ background: "rgba(217, 217, 217, 1)" }}
                >
                  <span>Clear</span>
                  <span>All</span>
                </button>
              </div>
              <div className="addnew d-lg-block">
                <button
                  className="btn text-white d-flex"
                  style={{ background: "red" }}
                >
                  <span> Apply </span> <span> Filter</span>
                </button>
              </div>
            </div>
          </div>
                    <div className="row bg-white pb-4 rounded-bottom table-responsive hideserchbar">
                        <table id="myTable" className="table">
                            <thead className="table-light">
                                <tr>
                                    <th scope="col">S.NO</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">User Id</th>
                                    {/* <th scope="col">Type</th>
                                    <th scope="col">Transaction No</th> */}
                                    <th scope="col">Transaction Name</th>
                                    <th scope="col">Modified Field</th>
                                    <th scope="col">old Value</th>
                                    <th scope="col">New Value</th>
                                    <th scope="col">Modified Date & Time</th>

                                </tr>
                            </thead>
                            <tbody>
                            {loading ? <tr><th style={{textAlign:"center"}} colSpan={8}>Loading...</th></tr> : data.map((item, index) => (
                  <tr key={item.id}>
                    <th scope="row">{index + 1}</th>
                    <td>
                    {item.date}
                    </td>
                    <td>{item.user_id}</td>
                    {/* <td>Product 1</td> */}
                    <td>
                        {item.model_name}
                    </td>
                    <td>{item.field_name}</td>
                    <td>{item.old_value}</td>
                    <td>{item.new_value}</td>
                    <td>{item.timestamp}</td>

                  </tr>
                ))}
                                {/* <tr>
                                    <th scope="row">01</th>
                                    <td>11/11/2023</td>
                                    <td>ABCD12Union</td>
                                    <td>TYPE</td>
                                    <td>234567</td>
                                    <td>Western Union</td>
                                    <td>8891</td>
                                    <td>787832</td>
                                    <td>7878909</td>
                                    <td>02/22/2023 12:02:40 PM</td>
                                </tr> */}
                            </tbody>
                        </table>
                    </div>
                    {/* <nav aria-label="Page navigation example ">
                        <ul class="pagination justify-content-end">
                            <li class="page-item">
                                <a class="page-link" href="#" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                    <span class="sr-only"></span>
                                </a>
                            </li>
                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item"><a class="page-link" href="#">...</a></li>
                            <li class="page-item"><a class="page-link" href="#">10</a></li>
                            <li class="page-item">
                                <a class="page-link" href="#" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                    <span class="sr-only"></span>
                                </a>
                            </li>
                        </ul>
                    </nav> */}
                </div>

            </div>
        </div>
    );
};

export default Logtracklist;
