import React, { useState } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import img3 from "../../assets/KYC Logo white-bg 2.jpg";
import img4 from "../../assets/img-1 1.png";
import img5 from "../../assets/Card.png";
import img6 from "../../assets/Onboarding.png";
import img7 from "../../assets/Worldwide.png";
import img8 from "../../assets/Monitor.png";
import img9 from "../../assets/Data security.png";
import img10 from "../../assets/Automation.png";
import img11 from "../../assets/Rectangle 259.png";
import img12 from "../../assets/Rectangle 260.png";
import img13 from "../../assets/Rectangle 261.png";
import img14 from "../../assets/Rectangle 262.png";
import img15 from "../../assets/Ellipse 66.png";
import img16 from "../../assets/Ellipse 67.png";
import img17 from "../../assets/Ellipse 68.png";
import img18 from "../../assets/Ellipse 69.png";
import img19 from "../../assets/Ellipse 70.png";
import img20 from "../../assets/Rectangle 293.png";
import img22 from "../../assets/123.png";
import { FaShoppingCart } from "react-icons/fa";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useRef } from "react";
import { useEffect } from "react";
import { ImLocation2 } from "react-icons/im";
import PhoneInput from 'react-phone-number-input'
import {
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Loadercomp from "../../components/Loadercomp";
import { BorderColor } from "@mui/icons-material";

const options = {
  items: 1,
  loop: true,
  autoplay: true,
  nav: false,
  responsiveClass: true,
  dots: false,
  responsive: {
    1200: {
      items: 1,
      // stagePadding: 50,
    },
    920: {
      items: 1,
    },
    700: {
      items: 1,
    },
    600: {
      items: 1,
    },
    504: {
      items: 1,
    },
    300: {
      items: 1,
    },
    310: {
      items: 1,
    },
  },
};
const Home = () => {
  const nvg = useNavigate();
  const locationhook = useLocation();
  const [fname ,setfname] = useState('')
  const [email ,setemail] = useState('')
  const [otp ,setotp] = useState('')
  const [phone ,setphone] = useState()
  const [password ,setpassword] = useState('')
  const [registerstatus ,setregisterstatus] = useState(false)
  const [loading ,setloading] = useState(false)
  const [sendotpres ,setsendotpres] = useState(false)
  const [otpnext ,setotpnext] = useState(false)
  const [registerpro ,setregisterpro] = useState(false)
  const [otpshowres ,setotpshowres] = useState(false)
  const [invalidotp ,setinvalidotp] = useState(false)
  const [verifyyourotp ,setverifyyourotp] = useState(false)
  const [otp1 ,setotp1] = useState()
  const [otp2 ,setotp2] = useState()

  const [errorcls, seterrorcls] = useState({});
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const redirecthead = (num) => {
    section1.current?.scrollIntoView({ behavior: "smooth" });
  };

  const redirecthead2 = (num) => {
    section2.current?.scrollIntoView({ behavior: "smooth" });
  };
  const redirecthead3 = (num) => {
    section3.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (locationhook.state) {
      if (locationhook.state.id == 0) {
        section1.current?.scrollIntoView({ behavior: "smooth" });
      }

      if (locationhook.state.id == 1) {
        section2.current?.scrollIntoView({ behavior: "smooth" });
      }

      if (locationhook.state.id == 2) {
        section3.current?.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  const navigatelogin = () => {
    window.location.href = "/login";
  };
// console.log("kfkfkfkf",phone)
const abc = 1;
  const submitfunction = async () =>{
    // e.preventDefault();
    // console.log("check 3")
    if(isemail == true && isphone == true){
      // const formdata = new FormData();
      // formdata.append("username",phone);
      // formdata.append("first_name",fname);
      // formdata.append("email",email);
      // formdata.append("mobile_no",phone);
      // formdata.append("password",password);
  const jsondata = {
    first_name:fname,
    username:fname,
    email:email,
    mobile_no:phone,
    password:password
  }
      try {
        setloading(true)
        let url = `${process.env.REACT_APP_API_URL}api/agent/`;
        const response = await axios.post(url, jsondata);
        // console.log(response);
      
        if(response.status == 201 || response.status == 200){
          nvg('/login',{state:{id:1}})
          setemail('')
          setfname('')
          setpassword('')
          setphone('')
          setloading(false)
          setotp1('')
          setotp2('')
          // setregisterstatus(true)
          seterrorcls({})
          // setTimeout(function() {
          //   setregisterstatus(false)
          //  }, 5000); 
        }
      } catch (error) {
      //  console.log(error)
        setloading(false);
        seterrorcls(error.response.data);
        setsendotpstate(false)
      }
    }else{
      // console.log("error 5050")
      setverifyyourotp(true)
      setTimeout(() => {
        setverifyyourotp(false)
      }, 5000);
    }
  
  }






  const verifyotp = async () =>{
    if(otp !== ''){
      const jsondata = {
        OTP:otp,
        email:email
      }
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const formdata = new FormData();
      formdata.append("email",email);
      formdata.append("otp",otp);
          try {
            setloading(true)
            let url = `${process.env.REACT_APP_API_URL}api/verify-otp/`;
            const response = await axios.post(url,formdata);
            // console.log("ooooooooooooooooooooo",response);
            if(response.status == 201 || response.status == 200){
              setotpshowres(true)
              setregisterpro(true)
              setotpnext(false)
              setloading(false)
              setTimeout(function() {
                setotpshowres(false)
               }, 5000); // 5000 milliseconds (5 seconds) delay
            }
          } catch (error) {
            setloading(false)
            setinvalidotp(true)
          //  console.log(error.response.data.error)
           setTimeout(function() {
            setinvalidotp(false)
           }, 5000); // 5000 milliseconds (5 seconds) delay
          }
    }
  }

  const [getemailerror,setgetemailerror] = useState(false)
  const [getemailsuccess,setgetemailsuccess] = useState(false)
  const [msgemailerror,setmsgemailerror] = useState('')
  const [getphoneerror,setgetphoneerror] = useState(false)
  const [getphonesuccess,setgetphonesuccess] = useState(false)
  const [msgphoneerror,setmsgphoneerror] = useState('')
  const [sendotpstate,setsendotpstate] = useState(false)
  const [showeotpres,setshoweotpres] = useState(false)
  const [isemail,setisemail] = useState(false)
  const [isphone,setisphone] = useState(false)
  // const [sendotpres,setsendotpres] = useState(false)

  const [allreadyemail,setallreadyemail] = useState(false)
  const [allreadyemailmsg,setallreadyemailmsg] = useState('')
  const [allreadynumber,setallreadynumber] = useState(false)
  const [allreadynumbermsg,setallreadynumbermsg] = useState('')

  const sendotp = async (e) =>{
    e.preventDefault();


    if(isemail == true && isphone == true){
      submitfunction()
    }else{
      if(sendotpstate == false){
        if(email !== ''){
          const jsondata = {
            email:email,
          }
              try {
                setloading(true)
                let url = `${process.env.REACT_APP_API_URL}api/send-otp/`;
                const response = await axios.post(url, jsondata);
                if(response.status == 201 || response.status == 200){
          
                  // setisemail(true)
                  setallreadyemail(false)
                  setallreadyemailmsg("")
      
                  if(phone !== ''){
                    const jsondata = {
                      phone:phone,
                      username:fname,
                    }
                        try {
                          let url = `${process.env.REACT_APP_API_URL}api/send-otp-phone/`;
                          const response = await axios.post(url, jsondata);
                          // console.log("xxxxxxxxxxxxxxxxxxxxx",response);
                          if(response.status == 201 || response.status == 200){
                            // setsendotpres(true)
                            // setotpnext(true)
                            //  setisphone(true)
                            setloading(false)
                            setsendotpstate(true)
                            setsendotpres(true)
                            setallreadynumber(false)
                            setallreadynumbermsg("")
                            setTimeout(function() {
                              setsendotpres(false)
                             }, 7000);
                          }
                        } catch (error) {
                          // isphone(false)
                        //  console.log(error)
                        setloading(false)
                        setallreadynumber(true)
                        setallreadynumbermsg("Mobile number already exists.")
                
                        }
                  }else{
                    setgetemailerror(true)
                    setTimeout(() => {
                      setgetemailerror(false)
                    }, 7000);
                  }
      
      
                }else{
                  console.log("new error of otp",response);
                }
              } catch (error) {
                console.log("new eeorror 2 of otp",error)
                // isemail(false)
              //  console.log(error)
              setloading(false)
              setallreadyemail(true)
           setallreadyemailmsg("Email ID already exists.")
      
              }
        }else{
      
          setgetemailerror(true)
          setTimeout(() => {
            setgetemailerror(false)
          }, 7000);
        }
      
      
      
      
      
      
      
      
      
      }else{
        // check otps here
      
      
      
      
      
      
      
        if(otp1 !== '' || otp1 !== undefined || otp2 !== '' || otp2 !== undefined){
          // const jsondata = {
          //   otp:otp1,
          //   phone:phone
          // }
          if(otp1 !== ''){
            const formdata = new FormData();
            formdata.append("email",email);
            formdata.append("otp",otp1);
                try {
                  setloading(true)
                  let url = `${process.env.REACT_APP_API_URL}api/verify-otp/`;
                  const response = await axios.post(url,formdata);
                  // console.log("ooooooooooooooooooooo",response);
                  if(response.status == 201 || response.status == 200){
                    setisemail(true)
                    setgetemailsuccess(true)
                    // setshoweotpres(true)
                    // setregisterpro(true)
                    // setotpnext(false)
                    // console.log("check 2")
                    if(isemail == true && isphone == true){
                      submitfunction()
                    }
                    // setloading(false)
                    // submitfunction()
                    // setTimeout(function() {
                    //   setshoweotpres(false)
                    //  }, 7000);
                    setgetemailerror(false)
                  }else{
                  
                  }
                } catch (error) {
                  // setloading(false)
                  setisemail(false)
                    setgetemailsuccess(false)
                  setgetemailerror(true)
                  setmsgemailerror(error.response.data.error)
                //  setTimeout(function() {
                //   setgetemailerror(false)
                //  }, 7000); 
                }
          }
      
      
          if(otp2 !== ''){
            const formdata = new FormData();
            formdata.append("phone",phone);
            formdata.append("otp",otp2);
                try {
                  // setloading(true)
                  let url = `${process.env.REACT_APP_API_URL}api/verify-otp-phone/`;
                  const response = await axios.post(url,formdata);
                  // console.log("ooooooooooooooooooooo",response);
                  if(response.status == 201 || response.status == 200){
                    setisphone(true)
                    setgetphonesuccess(true)
                    // setshoweotpres(true)
                    if(isemail == true && isphone == true){
                      submitfunction()
                    }
                    setregisterpro(true)
                    // console.log("check 1")
                    // setotpnext(false)
                    setloading(false)
                    setgetphoneerror(false)
                    submitfunction()
                    // setTimeout(function() {
                    //   setshoweotpres(false)
                    //  }, 7000); // 5000 milliseconds (5 seconds) delay
                  }else{
                  
                  }
                } catch (error) {
                  setloading(false)
                  setisphone(false)
                  setgetphonesuccess(false)
                  setgetphoneerror(true)
                  setmsgphoneerror(error.response.data.error)
                //  setTimeout(function() {
                //   setgetemailerror(false)
                //  }, 7000); // 5000 milliseconds (5 seconds) delay
                }
          }
      
      
          if(isemail == true && isphone == true){
            submitfunction()
          }
      
      
      
        }
      
      }
    }

    
  }






  return (
    <div style={{ margin: "0px", padding: "0px" }}>
      <section>
        <nav
          className="navbar navbar-expand-lg navbar-light bg-body p-0"
          aria-label="Eighth navbar example"
        >
          <div className="container-fluid">
            <div className="img-fluid pb-1 imgdivsize">
              <NavLink to="/">
                <img src={img3} className="imgsize" alt="logo" />
              </NavLink>
            </div>
            <button
              className="navbar-toggler collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarsExample07"
              aria-controls="navbarsExample07"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarsExample07"
              style={{ width: "61%" }}
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <NavLink
                    className="nav-link text-black"
                    to="#"
                    onClick={redirecthead}
                    role="button"
                  >
                    Features
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link text-black"
                    onClick={redirecthead2}
                    to="#"
                  >
                    Industries
                  </NavLink>
                </li>
               
                <li className="nav-item dropdown">
                  <NavLink className="nav-link text-black" to="/about">
                    About{" "}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-black" href="/contact">
                    Contact Us
                  </a>
                </li>
                 {/* <li className="nav-item">
                  <NavLink
                    className="nav-link text-black"
                    onClick={redirecthead3}
                    to="#"
                  >
                    Register
                  </NavLink>
                </li> */}
              </ul>

              <div className=" p-1 d-flex" style={{gap:'4px'}}>
                 <a
                    onClick={redirecthead3}
                    className="bg-white text-primary btn btn-outline-primary"
                    href="#form"
                  >
                    Register
                  </a>
                <NavLink to="/login" className="btn btn-outline-primary">
                {/* <button
                  type="button"
                  className="btn btn-outline-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModaltwo"
                >
                  Log in
                </button> */}
                Log in
                </NavLink>
                {/* <button type="button" className="btn">
                  <NavLink to="/cart">
                    <FaShoppingCart
                      style={{ fontSize: "24px", color: "red" }}
                    />
                  </NavLink>
                </button> */}
                {/* <button
                type='button'
                style={{border:'none',outline:'none'}}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModaltwo"
                >
              <img src={img3} alt="zxcvbnm" width="140px" height="162px" />
              </button> */}
              </div>
            </div>
          </div>
        </nav>
      </section>

      <div
        className="modal fade"
        id="exampleModaltwo"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-header mod-line">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div
                className="row bg-white round"
                style={{
                  margin: "0px 0px",
                  borderRadius: "3px",
                  paddingBottom: "3rem",
                }}
              >
                <div className="col-12 px-3 pt-3">
                  <p
                    className="title2"
                    style={{ borderBottom: "1px solid #E0E0E0" }}
                  >
                    Key Features of Our KYC Solutions
                  </p>
                </div>
                <div className="col-12 px-3 pt-3">
                  <p className="desc120">
                    Welcome to KYC First Solution LLP . Before using our
                    services, please carefully read and understand the following
                    Terms and Conditions. By accessing and using our services,
                    you acknowledge that you have read, understood, and agreed
                    to be bound by these terms. If you do not agree with any
                    part of these Terms and Conditions, please do not use our
                    services.
                  </p>

                  <h1 className="headline mb-3 ">1. Definitions</h1>
                  <p className="desc120">
                    • "Company," "we," "us," or "our" refers to KYC First
                    Solution LLP , the entity providing the services. "User,"
                    "you," or "your" refers to any individual or entity using
                    our services
                  </p>

                  <h1 className="headline mb-3 ">2. Services</h1>
                  <p className="desc120">
                    KYC First Solution LLP offers Know Your Customer (KYC)
                    solutions and related services for businesses to verify
                    customer identities and comply with applicable regulations.
                    These services include identity verification, document
                    verification, and compliance management.
                  </p>

                  <h1 className="headline mb-3 ">3. User Eligibility</h1>
                  <p className="desc120">
                    By using our services, you represent and warrant that you
                    are at least 18 years old and have the legal capacity to
                    enter into these Terms and Conditions.
                  </p>

                  <h1 className="headline mb-3 ">4. User Account</h1>
                  <p className="desc120">
                    a. To access certain features of our services, you may need
                    to create a user account. You are responsible for
                    maintaining the confidentiality of your account credentials
                    and for any activity that occurs under your account.
                  </p>
                  <p className="desc120">
                    b. You agree to provide accurate, current, and complete
                    information during the registration process. You must
                    promptly update your account information to ensure its
                    accuracy.
                  </p>
                  <p className="desc120">
                    c. You must not share your account credentials with any
                    third party. If you suspect unauthorized use of your
                    account, you must notify us immediately.
                  </p>

                  <button
                    className="btn btn-primary"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#secondlevel"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    style={{
                      width: "100%",
                      fontSize: "17px",
                      background: "rgba(12, 83, 152, 1)",
                    }}
                  >
                    I AGREE
                  </button>
                  <button
                    className="btn btn-outline-primary mt-3"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    style={{ width: "100%", fontSize: "17px" }}
                  >
                    I DISAGREE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="secondlevel"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-header mod-line">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div
                className="row bg-white round"
                style={{
                  margin: "0px 0px",
                  borderRadius: "3px",
                  paddingBottom: "3rem",
                }}
              >
                <div className="col-12 px-3 pt-3">
                  <p
                    className="title2"
                    style={{ borderBottom: "1px solid #E0E0E0" }}
                  >
                    Privacy Policy
                  </p>
                </div>
                <div className="col-12 px-3 pt-3">
                  <p className="desc120">
                    At KYC First Solution LLP, we are committed to safeguarding
                    the privacy and security of your personal information. This
                    Privacy Policy outlines how we collect, use, disclose, and
                    protect the information you provide to us. By using our
                    services and providing your personal information, you
                    consent to the practices described in this policy. We
                    encourage you to read this Privacy Policy carefully to
                    understand how we handle your data.
                  </p>

                  <h1 className="headline mb-3 ">1. Information We Collect</h1>
                  <p className="desc120 pb-2">
                    We collect various types of information to provide and
                    improve our services:
                  </p>
                  <p className="desc120 pb-2">
                    <span style={{ fontWeight: "700" }}>
                      a. Personal Information{" "}
                    </span>
                    : When you use our services, we may collect personal
                    information such as your name, email address, contact
                    number, and other details you voluntarily provide during the
                    registration or verification process.
                  </p>
                  <p className="desc120 pb-2">
                    {" "}
                    <span style={{ fontWeight: "700" }}>
                      {" "}
                      b. Identity Verification Data{" "}
                    </span>{" "}
                    : To comply with KYC regulations, we may collect
                    government-issued identification documents, biometric data,
                    and other information necessary for identity verification.
                  </p>
                  <p className="desc120 pb-2">
                    {" "}
                    <span style={{ fontWeight: "700" }}>
                      {" "}
                      c. Usage Information{" "}
                    </span>{" "}
                    : We may collect information about how you interact with our
                    website, applications, and services. This includes your IP
                    address, device type, browser type, pages visited, and other
                    usage data.
                  </p>
                  <p className="desc120">
                    {" "}
                    <span style={{ fontWeight: "700" }}>
                      {" "}
                      d. Cookies and Similar Technologies
                    </span>{" "}
                    : We use cookies and similar technologies to enhance your
                    user experience, monitor website traffic, and collect usage
                    information. You can manage your cookie preferences through
                    your browser settings.
                  </p>

                  <button
                    className="btn btn-primary"
                    onClick={navigatelogin}
                    style={{
                      width: "100%",
                      fontSize: "17px",
                      background: "rgba(12, 83, 152, 1)",
                    }}
                  >
                    I AGREE
                  </button>
                  <button
                    className="btn btn-outline-primary mt-3"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    style={{ width: "100%", fontSize: "17px" }}
                  >
                    I DISAGREE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="banner-jaipur">
      <div className=" react-overlaynew bannersec" style={{position:'absolute',width:'100%',height:'100%'}}>
      <OwlCarousel className="owl-theme" style={{width:'100%',height:'100%'}} {...options}>
        <img src={img22} alt="304" style={{width:'100%',height:'100%'}}/>
        {/* <img src={img20} alt="304" style={{width:'100%',height:'100%'}}/> */}
        </OwlCarousel>
      </div>
      <div className=" react-overlaynew" style={{position:'absolute',width:'100%',height:'100%',zIndex:4}}>
      </div>
    
        <div class="react-overlay" style={{zIndex:33}} >
          <div class="container-fuild">
            <div class="row m-0 d-flex justify-content-center" style={{zIndex:33}}>
              <h4 class=" mt-4 bann-txt page-title " style={{zIndex:33}}>
                Streamline and Enhance the Know Your Customer Process
              </h4>
              <p className="text-white text-center spaceofx" style={{zIndex:33}}>
                Our innovative KYC solutions enable businesses across various
                industries to verify the identities of their customers
                efficiently, securely, and in a user-friendly manner.
              </p>
              <div className="d-flex justify-content-center">
                {/* <button type="button" className="btn btn-outline-primary" style={{background:'transparent',border:'1.4px solid white',color:'white',fontWeight:'500'}}>
                    Get Started
          </button> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{ marginTop: "5px" }}>
        <div className="container-fuild  px-4 py-3 changedir">
          <div
            className="row align-items-center g-5 "
            style={{ justifyContent: "center" }}
          >
            <div
              className="col-10 col-sm-12 col-lg-6"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img
                src={img4}
                className="d-block mx-lg-auto img-fluid"
                alt="group7"
                width={500}
                height={500}
                loading="lazy"
              />
            </div>
            <div className="col-lg-6">
              <h1 className="title2 mb-3 ">
                Empower Businesses with Comprehensive and Reliable KYC Solutions
              </h1>
              <p className="desc2">
                At KYC First, our mission is to empower businesses with
                comprehensive and reliable KYC solutions that facilitate
                seamless customer onboarding and compliance adherence. We are
                dedicated to safeguarding businesses against potential risks,
                fraudulent activities, and money laundering while promoting a
                secure and trustworthy environment.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="col-12 py-3 pb-3 mt-4">
          <p className="headline text-center " ref={section1}>
            Key Features of Our KYC Solutions
          </p>
        </div>
        <div className="container-fluid px-4 py-2">
          <div className="row">
            <div className="col-md-4 col-sm-6 py-4">
              <div className="bigboss">
                <div className="row p-1 mx-1 custombackground">
                  <div className="col-3">
                    <img
                      src={img5}
                      alt="dkdd"
                      className="size12 p-1 mt-2 rounded"
                    />
                  </div>
                  <div className="col-12 title20">
                    Advanced Identity Verification
                  </div>
                  <div className="col-12 desc14 pb-5">
                    Our cutting-edge technology employs AI-driven identity
                    verification to ensure accurate and real-time validation of
                    customers' identities. From government-issued IDs and
                    passports to biometric data, we offer multi-layered
                    verification checks to minimize the risk of impersonation
                    and identity fraud.
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 py-4">
              <div className="bigboss">
                <div className="row p-1 mx-1 custombackground">
                  <div className="col-3">
                    <img
                      src={img6}
                      alt="dkdd"
                      className="size12 p-1 mt-2 rounded"
                    />
                  </div>
                  <div className="col-12 title20">
                    Seamless Customer Onboarding
                  </div>
                  <div className="col-12 desc14 pb-5">
                    Simplify the onboarding process for your customers with our
                    user-friendly interface. Our KYC solutions are designed to
                    provide a smooth and efficient experience, reducing
                    drop-offs and boosting customer acquisition rates.
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 py-4">
              <div className="bigboss">
                <div className="row p-1 mx-1 custombackground">
                  <div className="col-3">
                    <img
                      src={img7}
                      alt="dkdd"
                      className="size12 p-1 mt-2 rounded"
                    />
                  </div>
                  <div className="col-12 title20">
                    Global Compliance Adherence
                  </div>
                  <div className="col-12 desc14 pb-5">
                    Staying compliant with ever-changing regulatory requirements
                    can be challenging. Our KYC solutions are constantly updated
                    to align with global regulations, ensuring that your
                    business remains compliant no matter where you operate.
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 py-4">
              <div className="bigboss">
                <div className="row p-1 mx-1 custombackground">
                  <div className="col-3">
                    <img
                      src={img8}
                      alt="dkdd"
                      className="size12 p-1 mt-2 rounded"
                    />
                  </div>
                  <div className="col-12 title20">Enhanced Due Diligence</div>
                  <div className="col-12 desc14 pb-5">
                    For high-risk transactions and customer profiles, our
                    Enhanced Due Diligence (EDD) feature enables a deeper
                    analysis of customers' backgrounds, allowing you to make
                    informed decisions while minimizing exposure to risk.
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 py-4">
              <div className="bigboss">
                <div className="row p-1 mx-1 custombackground">
                  <div className="col-3">
                    <img
                      src={img9}
                      alt="dkdd"
                      className="size12 p-1 mt-2 rounded"
                    />
                  </div>
                  <div className="col-12 title20">Robust Data Security</div>
                  <div className="col-12 desc14 pb-5">
                    We understand the importance of safeguarding sensitive
                    customer information. Our KYC solutions implement
                    state-of-the-art data encryption and protection measures to
                    ensure that all data remains secure and confidential.
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 py-4">
              <div className="bigboss">
                <div className="row p-1 mx-1 custombackground">
                  <div className="col-3">
                    <img
                      src={img10}
                      alt="dkdd"
                      className="size12 p-1 mt-2 rounded"
                    />
                  </div>
                  <div className="col-12 title20">Flexible Integration</div>
                  <div className="col-12 desc14 pb-5">
                    Whether you're a financial institution, fintech startup,
                    e-commerce platform, or any other business requiring KYC
                    services, our solutions can seamlessly integrate into your
                    existing infrastructure with minimal disruption.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="royal-sec py-4 ">
        <div className="container-fluid position-relative">
          <div className="row backgroundbox"></div>
          <div className="row">
            <div className="col-12 mt-5" style={{ zIndex: "4" }}>
              <div className="row">
                <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                  <p className="forheading text-center m-0" ref={section2}>
                    Industries We Serve
                  </p>
                  <p className="fordesc text-center spaceofx">
                    Our KYC solutions cater to a diverse range of industries,
                    including but not limited to
                  </p>
                </div>
                <div className="col-md-4 col-sm-6 mt-4">
                  <div className="article">
                    <div className="over-royal" />
                    <img src={img11} alt height="283px" width="100%" />
                    <div className="text-center fw-bold">
                      <p className="p-lg-2 desc23">
                        Banking and Financial Services
                      </p>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-3 col-sm-6 mt-4">
                  <div className="article">
                    <div className="over-royal" />
                    <img src={img12} alt height="283px" width="100%" />
                    <div className="text-center fw-bold">
                      <p className="p-lg-2 desc23">
                        Cryptocurrency and Blockchain
                      </p>
                    </div>
                  </div>
                </div> */}
                <div className="col-md-4 col-sm-6 mt-4">
                  <div className="article">
                    <div className="over-royal" />
                    <img src={img13} alt height="283px" width="100%" />
                    <div className="text-center fw-bold">
                      <p className="p-lg-2 desc23">E-commerce and Retail</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 mt-4">
                  <div className="article">
                    <div className="over-royal" />
                    <img src={img14} alt height="283px" width="100%" />
                    <div className="text-center fw-bold">
                      <p className="p-lg-2 desc23">Telecommunications</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section>
        <div className="col-12 py-3 pb-3 mt-4">
          <p className="headline text-center " ref={section3}>
            Packages & Pricing
          </p>
        </div>
        <div className="container-fluid px-4 py-2">
          <div className="row">
            <OwlCarousel className="owl-theme" id="hideclass" {...options}>
              <div className="owlwidth">
                <div className="single-pricing">
                  <div className="head-text">
                    <h3>Bronze</h3>
                  </div>
                  <div className="price-area">
                    $ 20 <span className="duration">Monthly</span>
                  </div>
              
                  <div className="btn-area">
                    <a href="#">Get Started</a>
                  </div>
                </div>
              </div>

              <div className="owlwidth">
                <div className="single-pricing">
                  <div className="pop-label">Popular</div>
                  <div className="head-text">
                    <h3>Silver </h3>
                  </div>
                  <div className="price-area">
                    $ 40 <span className="duration">Quarterly</span>
                  </div>
                  <div className="btn-area">
                    <a href="#">Get Started</a>
                  </div>
                </div>
              </div>

              <div className="owlwidth">
                <div className="single-pricing">
                  <div className="head-text">
                    <h3>GOLD</h3>
                  </div>
                  <div className="price-area">
                    $ 50 <span className="duration">Half-yearly</span>
                  </div>
                  
                  <div className="btn-area">
                    <a href="#">Get Started</a>
                  </div>
                </div>
              </div>

              <div className="owlwidth">
                <div className="single-pricing">
                  <div className="head-text">
                    <h3>PLATINUM</h3>
                  </div>
                  <div className="price-area">
                    $ 100 <span className="duration">Yearly</span>
                  </div>
            
                  <div className="btn-area">
                    <a href="#">Get Started</a>
                  </div>
                </div>
              </div>
            </OwlCarousel>

            
          </div>
        </div>
      </section> */}

      <section>
        <div className="col-12 py-3 pb-3 mt-4 bg-white">
          <p className="headline text-center ">Why Choose KYC First?</p>
        </div>
        <div className="container-fluid bg-white px-4 extrapadding" >
          <div className="row">
            <div className="col-lg-4 col-md-6 mt-3">
              <div className="imgbox">
                <img src={img15} alt="404" />
              </div>
              <div className="headline234">
                <p className="m-0">Expertise and Experience</p>
              </div>
              <div className="desc493">
                <p
                  className="m-0 text-center"
                  style={{
                    color: "rgba(0, 0, 0, 1)",
                    textAlign: "justify",
                    fontSize: "15px",
                  }}
                >
                  With years of experience in the KYC domain, our team comprises
                  industry experts and technology enthusiasts who understand the
                  challenges faced by businesses in complying with regulations
                  while maintaining smooth operations.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 mt-3">
              <div className="imgbox">
                <img src={img16} alt="404" />
              </div>
              <div className="headline234">
                <p className="m-0">Innovation and Technology</p>
              </div>
              <div className="desc493">
                <p
                  className="m-0 text-center"
                  style={{
                    color: "rgba(0, 0, 0, 1)",
                    textAlign: "justify",
                    fontSize: "15px",
                  }}
                >
                  We pride ourselves on staying at the forefront of
                  technological advancements. Our solutions leverage AI, machine
                  learning, and biometric authentication to deliver unparalleled
                  accuracy and efficiency
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 mt-3">
              <div className="imgbox">
                <img src={img17} alt="404" />
              </div>
              <div className="headline234">
                <p className="m-0">Customer-Centric Approach</p>
              </div>
              <div className="desc493">
                <p
                  className="m-0 text-center"
                  style={{
                    color: "rgba(0, 0, 0, 1)",
                    textAlign: "justify",
                    fontSize: "15px",
                  }}
                >
                  At KYC First, customer satisfaction is our top priority. We
                  provide dedicated customer support and tailor our solutions to
                  meet the unique requirements of each client.
                </p>
              </div>
            </div>

            <div className=" offset-lg-2 col-lg-4 col-md-6 mt-3">
              <div className="imgbox">
                <img src={img18} alt="404" />
              </div>
              <div className="headline234">
                <p className="m-0">Cost-Effective Solutions</p>
              </div>
              <div className="desc493">
                <p
                  className="m-0 text-center"
                  style={{
                    color: "rgba(0, 0, 0, 1)",
                    textAlign: "justify",
                    fontSize: "15px",
                  }}
                >
                  Our KYC services are competitively priced, offering a high
                  return on investment by saving both time and resources
                  associated with manual verification processes.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-3">
              <div className="imgbox">
                <img src={img19} alt="404" />
              </div>
              <div className="headline234">
                <p className="m-0">Scalability and Growth</p>
              </div>
              <div className="desc493">
                <p
                  className="m-0 text-center"
                  style={{
                    color: "rgba(0, 0, 0, 1)",
                    textAlign: "justify",
                    fontSize: "15px",
                  }}
                >
                  Whether you are a startup or an established enterprise, our
                  solutions are designed to scale alongside your business,
                  adapting to changing needs and expanding customer bases.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>












      <section className="royal-sec " style={{background:'white'}}>
        <div className="container-fluid position-relative p-0 m-0 registerdesc">
          <div className="row backimgregister m-0 p-0" >
            <div className="col-12 m-0 p-0">
              <div className="row">
              <div className="col-12">
                <img src={img20} alt="304" style={{width:'100%',height:'1000px'}} />
              </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center m-0" style={{width:'100%'}}>
            <div className="col-md-5 col-12 px-3 desc203 d-flex justify-content-center align-items-center">
              <p className="desc203">
            Let's work together to build a more secure and trustworthy future.
              </p>
            </div>
            <div className="col-md-6 col-11 my-1" style={{zIndex:4,background:'white',borderRadius:'6px'}} >
              <form onSubmit={sendotp} id="form" >
           <div className="row px-3">
           {/* <div className="col-md-6 px-2 " style={{paddingBottom:"20px",paddingTop:"22px"}}>
                <div className="row">
                  <div className="col-12">
                    <label htmlFor="" className="form-label">
                      Username <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Username"
                      value={username}
                      onChange={(e) => {
                        setusername(e.target.value);
                      }}
                      required
                    />
                  </div>
                </div>
              </div> */}

<div className="col-md-6 px-2 exterpadding">
                <div className="row">
                  <div className="col-12">
                    <label htmlFor="" className="form-label">
                      Email <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => {
                        setemail(e.target.value);
                      }}
                      required
                    />
{/* {otpnext == true ? <div className="input-group mb-3">
  <input type="number" className="form-control"   value={otp}
                      onChange={(e) => {
                        setotp(e.target.value);
                      }} placeholder="OTP" aria-label="Recipient's username" aria-describedby="basic-addon2" />
  <span className="input-group-text" onClick={verifyotp} id="basic-addon2">VERIFY OTP</span>
</div> : <div className="input-group mb-3">
  <input type="text" className="form-control"   value={email}
                      onChange={(e) => {
                        setemail(e.target.value);
                      }} placeholder="Email" aria-label="Recipient's username" aria-describedby="basic-addon2" />
  <span className="input-group-text" onClick={sendotp} id="basic-addon2">SEND OTP</span>
</div>} */}

{/* {getemailerror == true ? <span style={{ color: "red" }}>
                         Please Enter Your Email
                        </span> : ''} */}
{allreadyemail ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {allreadyemailmsg}
                        </span>
                      ) : (
                        ""
                       )}
               {errorcls ? (
                      errorcls.email ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.email[0] == '' ? '' : errorcls.email[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
           <div className="col-md-6 px-2 " style={{paddingBottom:"20px",paddingTop:"22px"}}>
                <div className="row">
                  <div className="col-12"  >
                    <label htmlFor="" className="form-label">
                      Username <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      value={fname}
                      onChange={(e) => {
                        setfname(e.target.value);
                      }}
                      required
                    />
                     {errorcls ? (
                      errorcls.username ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.username[0] == '' ? '' : errorcls.username[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6 px-2 " style={{paddingBottom:"20px"}}>
                <div className="row">
                  <div className="col-12">
                    <label htmlFor="" className="form-label">
                      Last Name <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      value={lname}
                      onChange={(e) => {
                        setlname(e.target.value);
                      }}
                    />
              
                  </div>
                </div>
              </div> */}

             

              <div className="col-md-6 px-2 " style={{paddingBottom:"20px"}}>
                <div className="row">
                  <div className="col-12" ref={section3}>
                    <label htmlFor="" className="form-label">
                      Phone. No <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-12">
                  <PhoneInput
placeholder="Enter phone number"
className="form-control"
value={phone}
onChange={setphone}
required
/>
{allreadynumber ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {allreadynumbermsg}
                        </span>
                      ) : (
                        ""
                       )}
{errorcls ? (
                      errorcls.mobile_no ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.mobile_no[0] == '' ? '' : errorcls.mobile_no[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

{/* <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-12">
                    <label htmlFor="" className="form-label">
                      Mobile No. 
                    </label>
                  </div>
                  <div className="col-lg-12">
                    <PhoneInput
placeholder="Enter phone number"
className="form-control"
value={phone}
onChange={setphone}
required
/>
                   
                  </div>
                </div>
              </div> */}
              <div className="col-md-6 px-2 " style={{paddingBottom:"20px"}}>
                <div className="row">
                  <div className="col-12">
                    <label htmlFor="" className="form-label">
                      Password <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-12">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => {
                        setpassword(e.target.value);
                      }}
                      required
                    />
               {errorcls ? (
                      errorcls.password ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.password[0] == '' ? '' : errorcls.password[0] }{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            

              {sendotpstate == false ?  <div
        className="col-12 py-4 px-2 d-flex justify-content-end" >
        <button type='submit' className="btn5withoutradius002">Register</button>
      </div> : ''}
      {sendotpstate == true ? <div className="col-md-6 px-2 " style={{paddingBottom:"20px"}}>
                <div className="row">
                  <div className="col-12"  >
                    <label htmlFor="" className="form-label">
                      Email OTP <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email OTP"
                      style={{borderColor :getemailsuccess == false ? getemailerror == true ?'red':'' : 'green'}}
                      value={otp1}
                      onChange={(e) => {
                        setotp1(e.target.value);
                      }}
                      required
                    />
                     {getemailerror == true ?  <span style={{ color: "red" }}>
                          {msgemailerror}
                        </span> : ''}
                       
                    
                  </div>
                </div>
              </div> : ''}

              {sendotpstate == true ? <div className="col-md-6 px-2 " style={{paddingBottom:"20px"}}>
                <div className="row">
                  <div className="col-12"  >
                    <label htmlFor="" className="form-label">
                      Mobile OTP <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="col-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Mobile OTP"
                      value={otp2}
                      style={{borderColor :getphonesuccess == false ? getphoneerror == true ?'red':'' : 'green'}}
                      onChange={(e) => {
                        setotp2(e.target.value);
                      }}
                      required
                    />


{getphoneerror == true ?  <span style={{ color: "red" }}>
                          {msgphoneerror}
                        </span> : ''}
                     {/* {errorcls ? (
                      errorcls.username ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.username[0] == '' ? '' : errorcls.username[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
              </div> : ''}

              {sendotpstate == true ? <div
        className="col-12 py-4 px-2 d-flex justify-content-end"
        
      >
        
        <button type='submit' className="btn5withoutradius002">Submit OTP</button>
      </div> : ''}
              
              <div
        className="col-12 py-1 px-2 d-flex justify-content-center" 
      >
        {loading == true ? <Loadercomp size={70} /> : ''}
      
      </div>
              <div
        className="col-12 px-2 d-flex justify-content-end" 
      >
        
        <div class="alert alert-success" role="alert"  style={{width:'100%',display:showeotpres == true ? 'block' : 'none'}}>
        Email OTP verified successfully.
</div>
</div>
<div
        className="col-12 px-2 d-flex justify-content-end" 
      >
        <div class="alert alert-success" role="alert"  style={{width:'100%',display:registerstatus == true ? 'block' : 'none'}}>
  Register Successfully
</div>
</div>
<div
        className="col-12 px-2 d-flex justify-content-end" 
      >
        <div class="alert alert-danger" role="alert"  style={{width:'100%',display:invalidotp == true ? 'block' : 'none'}}>
        Invalid OTP.
</div>
</div>
{/* <div
        className="col-12 px-2 d-flex justify-content-end" 
      >
        <div class="alert alert-danger" role="alert"  style={{width:'100%',display:getemailerror == true ? 'block' : 'none'}}>
        Invalid Email OTP.
</div>
</div> */}
        {/* <div class="alert alert-success" role="alert"  style={{width:'100%',display:sendotpres == true ? 'block' : 'none'}}>
        OTP sent Successfully.
</div> */}
            <div
        className="col-12 px-2 d-flex justify-content-end" 
      >
        <div class="alert alert-success" role="alert"  style={{width:'100%',display:otpshowres == true ? 'block' : 'none'}}>
        Email Verified Successfully.
</div>
</div>
{/* <div
        className="col-12 px-2 d-flex justify-content-end" 
      >
        <div class="alert alert-danger" role="alert"  style={{width:'100%',display:verifyyourotp == true ? 'block' : 'none'}}>
        Please Verify Your Email.
</div>
</div> */}
<div
        className="col-12 px-2 d-flex justify-content-end" 
      >
        <div class="alert alert-success" role="alert"  style={{width:'100%',display:sendotpres == true ? 'block' : 'none'}}>
        OTP will be sent to your number and Email for verification.
</div>
      </div>
           </div>
           </form>
            </div>
          </div>
        </div>
      </section>

      <footer className="footer" style={{ marginTop: "5rem" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-12">
              <div className="footer-img pb-1">
                <img
                  src={img3}
                  style={{ mixBlendMode: "darken" }}
                  className="footwidth"
                  alt="logo"
                />
              </div>
            </div>
            {/* <div className="col-lg-2 col-md-6 col-12 ">
              <p className="customunder">Home</p>
              <p className="desc21 ">Industries</p>
            </div> */}
            <div className="col-lg-2 col-md-6 col-12 " style={{display:'flex',justifyContent:'center'}}>
              {/* <p className="customunder">Pricing</p> */}
              <p className="desc21 ">
                <NavLink style={{ color: "black" }} to="/contact">
                  Contact Us
                </NavLink>
              </p>
            </div>
            <div className="col-lg-3 col-md-6 col-12 ">
            <p> <a style={{ color: "black" }} href="/about">About</a></p>

              <p className="desc21 ">
                <a style={{ color: "black" }} href="/privacypolicy">
                  {" "}
                  Privacy & Policy
                </a>
              </p>
              <p className="desc21">
                <a style={{ color: "black" }} href="/termandcondition">
                  Terms & Condition
                </a>
              </p>
              <p className="desc21">
                <a style={{ color: "black" }} href="/refund">
                Refund and Cancellation
                </a>
              </p>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-footer">
                {/* <p className="customunder">Contact Us</p> */}
                <div className="contact_i">
                  <div className="row mt-2">
                    <div
                      className="col-1"
                      style={{ color: "rgba(12, 83, 152, 1)" }}
                    >
                      <ImLocation2 />
                    </div>
                    <div className="col-10  desc21">
                      907 Clover Hill Plaza NIBM Road Kondhwa Pune 411048 MS
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div
                      className="col-1"
                      style={{ color: "rgba(12, 83, 152, 1)" }}
                    >
                      <FaEnvelope />
                    </div>
                    <div className="col-10 desc21">
                      Email:
                      <a
                        className=" text-decoration-none"
                        href="mailto:legal@kycfirst.io"
                      >
                        legal@kycfirst.io
                      </a>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div
                      className="col-1"
                      style={{ color: "rgba(12, 83, 152, 1)" }}
                    >
                      <BsFillTelephoneFill />
                    </div>
                    <div className="col-10 desc21">
                      {" "}
                      Phone:
                      <a
                        className=" text-decoration-none"
                        href="tel:8130515661"
                      >
                        +91 9730074506
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row ">
            <div className="col-12" style={{display:'none'}}>
              <ul className="social-foo">
                <li style={{display:'flex',justifyContent:'center',alignItems:'center',width:'50px'}}>
                  <a href="#">
                    <FaFacebookF />
                  </a>
                </li>
                <li style={{display:'flex',justifyContent:'center',alignItems:'center',width:'50px'}}>
                  <a href="#">
                    <FaInstagram />
                  </a>
                </li>
                <li style={{display:'flex',justifyContent:'center',alignItems:'center',width:'50px'}}>
                  <a href="#">
                    <FaTwitter />
                  </a>
                </li>
                <li style={{display:'flex',justifyContent:'center',alignItems:'center',width:'50px'}}>
                  <a href="#">
                    <FaLinkedinIn />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-12 mt-1 mb-1">
              <p className="text-center desc2">© 2023 - All Right Reserved</p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;
