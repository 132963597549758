// import Breadcup from '../../components/Breadcup'
import Header from "../../components/Header";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import { useEffect } from "react";
import { gettoken } from "../../Localstorage/Store";
import axios from "axios";
import { NavLink } from "react-router-dom";
import Loadercomp from "../../components/Loadercomp";
import $ from "jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-dt/js/dataTables.dataTables.min.js";
import 'datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css';
import 'datatables.net-buttons/js/dataTables.buttons.min.js';
import 'datatables.net-buttons/js/buttons.html5.min.js';
import 'datatables.net-buttons/js/buttons.print.min.js';
import 'datatables.net-buttons-bs5/js/buttons.bootstrap5.min.js';

const Logactivitydetail = () => {
  const [data, setData] = useState([]);
  const [loading, setloading] = useState(true);

  const gettokinval = gettoken();
  useEffect(() => {
    async function fetchData() {
      const config = {
        headers: {
          Authorization: `Bearer ${gettokinval.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}api/loginActivity/`;
      const response = await axios.get(url, config);
      setData(response.data);
      setloading(false)
      $(document).ready(function () {
        $("#myTable").DataTable().destroy();
        $("#myTable").DataTable({
          pageLength: 10,
          language: {
            searchPlaceholder: 'Search...',
            sSearch: '',
          },
          dom: 'Bfrtip', // Add the buttons to the DataTable
          buttons: [
          // 'csv', 'excel', 'print'
          ]
        });
      });
    }
    fetchData();
  }, []);
  return (
    <div className="" style={{ width: "100%" }}>
      {/* <Header /> */}
      <div className="dashboardcontent px-2 ">
        {/* <Breadcup name={'Profile'} /> */}
        <div className="col-12 bg-body">
          <ul className="d-flex list-unstyled p-3 ">
            <li className="me-auto">
              <h4 className="ccedit-h">Login Activity </h4>
              {/* <p className="m-auto">Here is your last 10 login activity log</p> */}
            </li>

            <li className="d-flex align-items-center">
              {/* <button type="button" className="btn5">
                Clear Log
              </button> */}
                <button className="btn5 ms-2" style={{background:"#0C5398"}}><NavLink to='/dashboard' style={{ textDecoration: 'none', color: 'white',background:'#0C5398' }}> x Close</NavLink></button>
            </li>
          </ul>
        
          {loading == true ? <div className="container-fuild bg-white"> <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"90%"}}>
        <div className='px-2'> <Loadercomp size={100} /></div>
        </div></div> : <div className="container-fuild px-2 desgin1 mt-3">
            <div className="row ">
              {/* <div
                className="col-12 py-3 customtext23"
                style={{ color: "#0C5398" }}
              >
                Activity on your account
              </div> */}
            </div>

            <div className="row bg-white pb-4 rounded-bottom table-responsive">
              <table id={loading ? "mytab" : "myTable"} className="table table-borderless table-hover">
                <thead className="table-light">
                  <tr className="text-center text-muted" style={{color:'black'}}>
                    <th scope="col">Sno.</th>
                    <th scope="col">User Id</th>
                    <th scope="col">Device Name</th>
                    <th scope="col">Browser</th>
                    <th scope="col">OS Details</th>
                    <th scope="col">IP Address</th>
                    <th scope="col">Lat</th>
                    <th scope="col">Long</th>
                    <th scope="col">Location</th>
                    <th scope="col">Time In</th>
                    <th scope="col">Time Out</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={item.id} className="text-center">
                      <td>{index + 1}</td>
                      <td>{item.id}</td>
                      <td>{item.device_details}</td>
                      <td>{item.browser_details}</td>
                      <td>{item.os_details}</td>
                      <td>{item.ip_address}</td>
                      <td>{item.lat}</td>
                      <td>{item.long}</td>
                      <td>{item.location}</td>
                      <td>{item.login_time}</td>
                      <td>{item.logout_time}</td>
                    </tr>
                  ))}
                  
                </tbody>
              </table>
            </div>
          </div>}
        </div>
      </div>
    </div>
  );
};

export default Logactivitydetail;
