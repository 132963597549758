import React, { useEffect, useRef, useState } from 'react'
import Header from '../../components/Header'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import img1 from '../../assets/Group 40.png';
import img2 from '../../assets/Group 41.png';
import img3 from '../../assets/asdfg.png';
import img4 from "../../assets/abc.png";
import img5 from "../../assets/cba.png";
import img33 from "../../assets/images.png";
import img from "../../assets/KYC Logo white-bg 1.png";
import { NavLink, useParams } from 'react-router-dom';
import { gettoken } from '../../Localstorage/Store';
import ReactImageMagnify from 'react-image-magnify';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import axios from 'axios';
import Loadercomp from '../../components/Loadercomp';

const Customerdetail = () => {

  const {id} = useParams()
  const [fname, setfname] = useState();
  const [lname, setlname] = useState();
  const [pdfData, setpdfData] = useState([]);

  const [photograph, setphotograph] = useState("");
  const [agentphotograph, setagentphotograph] = useState("");
  const [mobile_no, setmobile_no] = useState();
  const [gender, setgender] = useState();
  const [dob, setdob] = useState();
  const [age, setage] = useState();
  const [emailID, setemailID] = useState();
  const [company, setcompany] = useState();
  const [location, setlocation] = useState();
  const [address, setaddress] = useState();
  const [country, setcountry] = useState();
  const [doccountry, setdoccountry] = useState();
  const [state, setstate] = useState();
  const [ffn, setffn] = useState();
  const [city, setcity] = useState();
  const [pincode, setpincode] = useState();
  const [created_by, setcreated_by] = useState();
  const [created_at, setcreated_at] = useState();
  const [last_updated_by, setlast_updated_by] = useState();
  const [last_updated_at, setlast_updated_at] = useState();
  const [srtloader, setsrtloader] = useState(true);
  const gettokinval = gettoken();
  const [nationality, setnationality] = useState('');
  const [thumbimg2, setthumbimg2] = useState('');
  const [rifimg2, setrifimg2] = useState('');
  const [rmfimg2, setrmfimg2] = useState('');
  const [rrfimg2, setrrfimg2] = useState('');
  const [rlfimg2, setrlfimg2] = useState('');
  const [ltfimg2, setltfimg2] = useState('');
  const [lifimg2, setlifimg2] = useState('');
  const [lmfimg2, setlmfimg2] = useState('');
  const [lrfimg2, setlrfimg2] = useState('');
  const [llfimg2, setllfimg2] = useState('');
  const [thumbimg3, setthumbimg3] = useState('');
  const [rifimg3, setrifimg3] = useState('');
  const [rmfimg3, setrmfimg3] = useState('');
  const [rrfimg3, setrrfimg3] = useState('');
  const [rlfimg3, setrlfimg3] = useState('');
  const [ltfimg3, setltfimg3] = useState('');
  const [lifimg3, setlifimg3] = useState('');
  const [lmfimg3, setlmfimg3] = useState('');
  const [lrfimg3, setlrfimg3] = useState('');
  const [llfimg3, setllfimg3] = useState('');
  const [documenttype, setdocumenttype] = useState();
  const [idnumber, setidnumber] = useState();
  const [documentimage, setdocumentimage] = useState();
  const [documentissue, setdocumentissue] = useState();
  const [documentexpiry, setdocumentexpiry] = useState();
  const [aadhaarImage, setaadhaarImage] = useState();
  const [documentimagebacktwo, setdocumentimagebacktwo] = useState();
  const [crtdate, setcrtdate] = useState('');

  const [documenttypetwo, setdocumenttypetwo] = useState();
  const [idnumbertwo, setidnumbertwo] = useState();
  const [documentissuetwo, setdocumentissuetwo] = useState();
  const [documentexpirytwo, setdocumentexpirytwo] = useState();
  const [modifiedImagenumbertwo, setModifiedImagenumbertwo] = useState(null);
  const [documentimageback2, setdocumentimageback2] = useState(null);

  const [Form_60_image, setForm_60_image] = useState(null);
  const [Form_60_pdf, setForm_60_pdf] = useState(null);
  const [doc1, setdoc1] = useState(null);
  const [doc2, setdoc2] = useState(null);
  const [loader, setloader] = useState(true);






  // const handleDownload = () => {
  //   const input = document.getElementById('pdf-content');

  //   html2canvas(input).then((canvas) => {
  //     const imgData = canvas.toDataURL('image/png');
  //     const pdf = new jsPDF();
  //     const imgProps = pdf.getImageProperties(imgData);
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

  //     pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
  //     pdf.save('download.pdf');
  //   });
  // };





  const [accountdata,setaccountdata] = useState([])
  const [addaccount, setaddaccount] = useState(false);
  const [accountno, setaccountno] = useState('');
  const [ifsccode, setifsccode] = useState('');
  const [accountname, setaccountname] = useState(null);
  const [alreadycreated, setalreadycreated] = useState(false);
  const [accountverified,setaccountverified] = useState(false)



  const getheight = useRef(null);
  const handleDownload = async () => {
    const pdf = new jsPDF();

    const sections = document.querySelectorAll(".pdf-section");

    const options = {
      scale: 2, // Adjust the scale for better image quality
      useCORS: true, // Enable CORS for images
    };

    for (const [index, section] of sections.entries()) {
      const canvas = await html2canvas(section, options);
      const imageData = canvas.toDataURL("image/png");

      if (index > 0) {
        pdf.addPage();
      }

      pdf.addImage(
        imageData,
        "PNG",
        0,
        0,
        pdf.internal.pageSize.getWidth(),
        pdf.internal.pageSize.getHeight()
      ); // You can adjust the positioning and size of the image here
    }

    pdf.save("document.pdf");
  };
const cureentdate = new Date;



// fetch user details ----->
  useEffect(() => {




    async function accountinfo() {

      const config = {
        headers: {
          Authorization: `Bearer ${gettokinval.access}`,
        },
      };
      setloader(true)
      let url = `${process.env.REACT_APP_API_URL}api/bank-accounts/get_acc_list/${id}/`;
      const response = await axios.get(url, config);
      setaccountdata(response.data)
console.log(" lll;;;;;;",response)

if(response.data[0].beneficiaryAccount){
  setaddaccount(true);
  setalreadycreated(true)
  setaccountverified(true)
  setaccountno(response.data[0].beneficiaryAccount);
  setifsccode(response.data[0].beneIFSC);
  setaccountname(response.data[0].beneName);
  console.log("8900000",accountno,ifsccode,accountname)
  console.log("oppp")
}else{
  setaddaccount(false);
}
    }
   

    async function fetchpdf() {
      // setloading(true);
      let url = `https://devbackend.kycfirst.io/api/cus_pdf/${id}`;
      const config = {
        headers: {
          Authorization: `Bearer ${gettokinval.access}`,
        },
      };
      const response = await axios.get(url, config);

      console.log("msg of response", response);
      setpdfData(response.data);
      // setloader(false);
    }
    async function fetchOne() {

      const config = {
        headers: {
          Authorization: `Bearer ${gettokinval.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}api/customer/${id}/`;
      const response = await axios.get(url, config);
      setsrtloader(false)
      setaddress(response.data.address)
      setemailID(response.data.emailID)
      setmobile_no(response.data.mobile_no)
      setlname(response.data.lname)
      setfname(response.data.fname)
      setcountry(response.data.country)
      setdoccountry(response.data.country_doc2)
      setstate(response.data.state)
      setcity(response.data.city)
      setpincode(response.data.pincode)
      setcompany(response.data.agent_comp)
      setlocation(response.data.agent_comp_loc)
      setgender(response.data.gender)
      setdob(response.data.dob)
      setffn(response.data.fathersFullNm)
      setage(response.data.cus_age)
      setcreated_by(response.data.created_by)
      setcreated_at(response.data.created_at)
      setphotograph(response.data.photograph)
      setlast_updated_by(response.data.last_updated_by)
      setlast_updated_at(response.data.last_updated_at)
      setdocumentimagebacktwo(response.data.docImg_back)
      setaadhaarImage(response.data.docImg_front)
      setdocumentexpirytwo(response.data.expiry_date2)
      setdocumentissuetwo(response.data.issue_date2)
      setidnumbertwo(response.data.document_no2)
      setdocumenttypetwo(response.data.document_type2)
      setdocumentimageback2(response.data.docImg_back2)
      setModifiedImagenumbertwo(response.data.docImg_front2)
      setidnumber(response.data.document_no)
      setdocumenttype(response.data.document_type)
      setdocumentexpiry(response.data.expiry_date)
      setdocumentissue(response.data.issue_date)
      setllfimg2(response.data.l_little_finger)
      setlrfimg2(response.data.l_ring_finger)
      setlmfimg2(response.data.l_middle_finger)
      setlifimg2(response.data.l_index_finger)
      setltfimg2(response.data.l_thumb)
      setrlfimg2(response.data.r_little_finger)
      setrrfimg2(response.data.r_ring_finger)
      setrmfimg2(response.data.r_middle_finger)
      setrifimg2(response.data.r_index_finger)
      setthumbimg2(response.data.r_thumb)
      setForm_60_image(response.data.Form_60_image)
      setForm_60_pdf(response.data.Form_60_pdf)
      setdoc1(response.data.Doc_1_pdf)
      setdoc2(response.data.Doc_2_pdf)
      setllfimg3(response.data.agent_l_little_finger)
      setlrfimg3(response.data.agent_l_ring_finger)
      setlmfimg3(response.data.agent_l_middle_finger)
      setlifimg3(response.data.agent_l_index_finger)
      setltfimg3(response.data.agent_l_thumb)
      setrlfimg3(response.data.agent_r_little_finger)
      setrrfimg3(response.data.agent_r_ring_finger)
      setrmfimg3(response.data.agent_r_middle_finger)
      setrifimg3(response.data.agent_r_index_finger)
      setthumbimg3(response.data.agent_r_thumb)
      setagentphotograph(response.data.agent_photograph)
setnationality(response.data.nationality)


      if(response.data.dob !== undefined){
        const newdate = () => {
          // const num = parseInt(dateString)
           const dateParts = response.data.dob.split('-');
          //  console.log("mmmmm",dateParts)
           const year = parseInt(dateParts[0], 10);
           const month = parseInt(dateParts[1], 10);
           const day = parseInt(dateParts[2], 10);
         
           const months = [
             'January',
             'February',
             'March',
             'April',
             'May',
             'June',
             'July',
             'August',
             'September',
             'October',
             'November',
             'December',
           ];
         
           const formattedDate = `${day} ${months[month - 1]}, ${year}`;
           setcrtdate(formattedDate)
           setloader(false)
         };
         newdate();
      }
    }
    fetchpdf()
    accountinfo();
    fetchOne();
  }, []);
// fetch user details ----->


const handleDownloadit = (pdfURL) => {
  // Make sure the PDF link is valid before initiating the download
  if (pdfURL) {
    // You can set a default filename or extract it from the URL
    const filename = 'download.pdf';
    // Trigger the download using a temporary link
    const link = document.createElement('a');
    link.href = pdfURL;
    // link.download = filename;
    link.target = '_blank'; // Open in a new tab/window if needed
    link.click();
  }
};

  return (
    loader == true ? "" : <div style={{width:'100%'}}>



<div ref={getheight} style={{ border: "1.5px solid black",
position:'absolute',top:'-555500px'
,padding: "1px" }}>
          <table
            id="pdf-content"
            className='pdf-section'
            style={{
              width: "100%",
              border: "2px solid #3d3d4a",
              padding: "18px 11px",
            }}
          >
            <tr style={{ border: "3.4px solid #191941", display: "block" }}>
              <td style={{ display: "block", padding: "7px 0px 0px 7px" }}>
                <img src={img} alt="logo" style={{ width: "15%" }} />
              </td>
              <td style={{ padding: "0px 0px 5px 9px", display: "block" }}>
                <span
                  style={{
                    display: "inline-block",
                    width: "30%",
                    fontSize: "18px",
                    fontWeight: 500,
                  }}
                >
                  KYCFirst.io
                </span>
                <span
                  style={{
                    width: "69%",
                    display: "inline-block",
                    textAlign: "end",
                    fontSize: "18px",
                    fontWeight: 500,
                  }}
                >
                  {" "}
                  customer id : {pdfData.c_id}
                </span>
              </td>
            </tr>

            <tr
              style={{
                border: "3.4px solid #191941",
                marginTop: "7px",
                display: "block",
                padding: "8px 0px 4px 0px",
              }}
            >
              <td style={{ display: "flex", padding: "7px 4px 0px 7px" }}>
                <div style={{ width: "50%", display: "flex", gap: "10px" }}>
                  <span style={{ display: "inline-block", width: "26%" }}>
                    This PDF is Generated
                  </span>
                  {/* <input style={{ width: "65%", padding: "0px 6px",height:'25.5px' }} type="text" /> */}
                </div>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    gap: "10px",
                    justifyContent: "center",
                  }}
                >
                  <span style={{ display: "inline-block", width: "26%" }}>
                    date
                  </span>
                  <input style={{ width: "65%", padding: "0px 6px",height:'25.5px' }} type="text" value={cureentdate.toLocaleString()} />
                </div>
              </td>
              <td style={{ display: "flex", padding: "0px 4px 0px 7px" }}>
                <div style={{ width: "50%", display: "flex", gap: "10px" }}>
                  <span style={{ display: "inline-block", width: "26%" }}>
                    Corporate
                  </span>
                  {/* <input style={{ width: "65%", padding: "0px 6px",height:'25.5px' }} type="text" /> */}
                  <span
                            style={{
                              width: "65.3%",
                              padding: "4px 6px",
                              borderLeft: "1px solid #333",
                              borderRight: "1px solid #333",
                              borderTop: "1px solid #333",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={img5}
                              alt="404"
                              style={{ width: "20px" }}
                            />
                          </span>
                </div>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    gap: "10px",
                    justifyContent: "center",
                  }}
                >
                  <span style={{ display: "inline-block", width: "26%" }}>
                    Agent
                  </span>
                  {/* <input style={{ width: "65%", padding: "0px 6px",height:'25.5px' }} type="text" /> */}
                  <span
                            style={{
                              width: "65.5%",
                              padding: "4px 6px",
                              borderLeft: "1px solid #333",
                              borderRight: "1px solid #333",
                              borderBottom: "1px solid #333",
                              borderTop: "1px solid #333",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={img4}
                              alt="404"
                              style={{ width: "20px" }}
                            />
                          </span>
                </div>
              </td>
              <td style={{ display: "flex", padding: "0px 4px 4px 7px" }}>
                <div style={{ width: "50%", display: "flex", gap: "10px" }}>
                  <span style={{ display: "inline-block", width: "26%" }}>
                    User
                  </span>
                  <input style={{ width: "65%", padding: "0px 6px",height:'25.5px' }} value={pdfData.user} type="text" />
                </div>
              </td>
            </tr>

            <tr
              style={{
                border: "3.4px solid #191941",
                marginTop: "7px",
                display: "block",
                padding: "8px 0px 4px 0px",
              }}
            >
              <td style={{ display: "flex", padding: "7px 4px 0px 7px" }}>
                <div style={{ width: "50%", display: "flex", gap: "10px" }}>
                  <span style={{ display: "inline-block", width: "59%" }}>
                    This Document was first created by
                  </span>
                  {/* <input style={{ width: "32%", padding: "0px 6px",height:'25.5px' }} type="text" /> */}
                </div>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    gap: "10px",
                    justifyContent: "center",
                  }}
                >
                  <span style={{ display: "inline-block", width: "26%" }}>
                    date
                  </span>
                  <input style={{ width: "65%", padding: "0px 6px",height:'25.5px' }} type="text" value={pdfData.created_at} />
                  {/* <input style={{ width: "65%", padding: "0px 6px",height:'25.5px' }} type="text" value={cureentdate.toLocaleString()} /> */}
                </div>
              </td>
              <td style={{ display: "flex", padding: "0px 4px 0px 7px" }}>
                <div style={{ width: "50%", display: "flex", gap: "10px" }}>
                  <span style={{ display: "inline-block", width: "26%" }}>
                    Corporate
                  </span>
                  {/* <input style={{ width: "65%", padding: "0px 6px",height:'25.5px' }} type="text" /> */}
                  <span
                            style={{
                              width: "65.3%",
                              padding: "4px 6px",
                              borderLeft: "1px solid #333",
                              borderRight: "1px solid #333",
                              borderTop: "1px solid #333",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={img5}
                              alt="404"
                              style={{ width: "20px" }}
                            />
                          </span>
                </div>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    gap: "10px",
                    justifyContent: "center",
                  }}
                >
                  <span style={{ display: "inline-block", width: "26%" }}>
                    Agent
                  </span>
                  <span
                            style={{
                              width: "65.5%",
                              padding: "4px 6px",
                              borderLeft: "1px solid #333",
                              borderRight: "1px solid #333",
                              borderBottom: "1px solid #333",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={img4}
                              alt="404"
                              style={{ width: "20px" }}
                            />
                          </span>
                  {/* <input style={{ width: "65%", padding: "0px 6px",height:'25.5px' }} type="text" /> */}
                </div>
              </td>
              <td style={{ display: "flex", padding: "0px 4px 4px 7px" }}>
                <div style={{ width: "50%", display: "flex", gap: "10px" }}>
                  <span style={{ display: "inline-block", width: "26%" }}>
                    User
                  </span>
                  <input style={{ width: "65%", padding: "0px 6px",height:'25.5px' }} value={pdfData.created_by} type="text" />
                </div>
              </td>
            </tr>

            <tr
              style={{
                border: "3.4px solid #191941",
                marginTop: "7px",
                display: "block",
                padding: "8px 0px 4px 0px",
              }}
            >
              <td style={{ display: "flex", padding: "7px 4px 0px 7px" }}>
                <div style={{ width: "66%", display: "flex", gap: "10px" }}>
                  <span style={{ display: "inline-block", width: "59%" }}>
                    Customer details
                  </span>
                </div>
                <div style={{ width: "33%", display: "flex", gap: "10px" }}>
                  <span style={{ display: "inline-block", width: "26%" }}>
                    STATUS
                  </span>
                  <input
                    style={{ width: "65%", padding: "0px 6px",height:'25.5px' }}
                    type="text"
                    value={pdfData.status == true ? "Active" : "InActive"}
                  />
                </div>
              </td>

              <td style={{ display: "flex", padding: "0px 4px 0px 6px" }}>
                <div style={{ width: "33%", display: "flex", gap: "10px" }}>
                  <span style={{ display: "inline-block", width: "26%" }}>
                    FIRST NAME
                  </span>
                  <input
                    style={{ width: "65%", padding: "0px 6px",height:'25.5px' }}
                    value={pdfData.fname}
                    type="text"
                  />
                </div>
                <div style={{ width: "33%", display: "flex", gap: "10px" }}>
                  <span style={{ display: "inline-block", width: "26%" }}>
                    LAST NAME
                  </span>
                  <input
                    style={{ width: "65%", padding: "0px 6px",height:'25.5px' }}
                    value={pdfData.lname}
                    type="text"
                  />
                </div>
                <div style={{ width: "33%", display: "flex", gap: "10px" }}>
                  <span style={{ display: "inline-block", width: "26%" }}>
                    NATIONALITY
                  </span>
                  <input
                    style={{ width: "65%", padding: "0px 6px",height:'25.5px' }}
                    value={pdfData.nationality}
                    type="text"
                  />
                </div>
              </td>
              <td style={{ display: "flex", padding: "0px 4px 4px 6px" }}>
                <div style={{ width: "33%", display: "flex", gap: "10px" }}>
                  <span style={{ display: "inline-block", width: "26%" }}>
                    GENDER
                  </span>
                  <input
                    style={{ width: "65%", padding: "0px 6px",height:'25.5px' }}
                    value={pdfData.gender}
                    type="text"
                  />
                </div>
                <div style={{ width: "33%", display: "flex", gap: "10px" }}>
                  <span style={{ display: "inline-block", width: "26%" }}>
                    DOB
                  </span>
                  <input
                    style={{ width: "65%", padding: "0px 6px",height:'25.5px' }}
                    value={pdfData.dob}
                    type="text"
                  />
                </div>
                <div style={{ width: "33%", display: "flex", gap: "10px" }}>
                  <span style={{ display: "inline-block", width: "26%" }}>
                    AGE
                  </span>
                  <input
                    style={{ width: "65%", padding: "0px 6px",height:'25.5px' }}
                    value={pdfData.cus_age}
                    type="text"
                  />
                </div>
              </td>
              <td style={{ padding: "0px 4px 4px 7px" }}>
                <span>CURRENT ADDRESS</span>
              </td>
              <td
                style={{
                  width: "100%",
                  display: "flex",
                  padding: "0px 4px 4px 7px",
                }}
              >
                <div style={{ width: "49%" }}>
                  <div style={{ width: "100%" }}>
                    <input
                      type="text"
                      style={{ width: "97%", padding: "0px 6px",height:'25.5px' }}
                      value={pdfData.address}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <div
                      style={{ width: "100%", display: "flex", gap: "10px" }}
                    >
                      <span style={{ display: "inline-block", width: "25%" }}>
                        STATE
                      </span>
                      <input
                        style={{ width: "70%", padding: "0px 6px",height:'25.5px' }}
                        value={pdfData.state}
                        type="text"
                      />
                    </div>

                    <div
                      style={{ width: "100%", display: "flex", gap: "10px" }}
                    >
                      <span style={{ display: "inline-block", width: "25%" }}>
                        CITY
                      </span>
                      <input
                        style={{ width: "70%", padding: "0px 6px",height:'25.5px' }}
                        value={pdfData.city}
                        type="text"
                      />
                    </div>

                    <div
                      style={{ width: "100%", display: "flex", gap: "10px" }}
                    >
                      <span style={{ display: "inline-block", width: "25%" }}>
                        COUNTRY
                      </span>
                      <input
                        style={{ width: "70%", padding: "0px 6px",height:'25.5px' }}
                        type="text"
                        value={pdfData.country}
                      />
                    </div>
                    <div
                      style={{ width: "100%", display: "flex", gap: "10px" }}
                    >
                      <span style={{ display: "inline-block", width: "25%" }}>
                        ZIPCODE
                      </span>
                      <input
                        style={{ width: "70%", padding: "0px 6px",height:'25.5px' }}
                        type="text"
                        value={pdfData.pincode}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ width: "49%", display: "flex" }}>
                  <div style={{ border: "1px solid black", width: "50%" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                          position: "relative",
                        }}
                      >
                        <label htmlFor="">Right Hand</label>
                        <img
                          src={
                            pdfData.r_thumb == null || pdfData.r_thumb == ""
                              ? img5
                              : img4
                          }
                          alt="rtf"
                          style={{
                            position: "absolute",
                            top: "80px",
                            left: "13px",
                          }}
                        />
                        <img
                          src={
                            pdfData.r_index_finger == null ||
                            pdfData.r_index_finger == ""
                              ? img5
                              : img4
                          }
                          alt="rif"
                          style={{
                            position: "absolute",
                            top: "37px",
                            left: "35px",
                          }}
                        />
                        <img
                          src={
                            pdfData.r_middle_finger == null ||
                            pdfData.r_middle_finger == ""
                              ? img5
                              : img4
                          }
                          alt="rmf"
                          style={{
                            position: "absolute",
                            top: "30px",
                            left: "65px",
                          }}
                        />
                        <img
                          src={
                            pdfData.r_ring_finger == null ||
                            pdfData.r_ring_finger == ""
                              ? img5
                              : img4
                          }
                          alt="rrf"
                          style={{
                            position: "absolute",
                            top: "36px",
                            left: "84px",
                          }}
                        />
                        <img
                          src={
                            pdfData.r_little_finger == null ||
                            pdfData.r_little_finger == ""
                              ? img5
                              : img4
                          }
                          alt="rlf"
                          style={{
                            position: "absolute",
                            top: "55px",
                            left: "103px",
                          }}
                        />
                        <img src={img1} alt="qwerty" />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                          position: "relative",
                        }}
                      >
                        <label htmlFor="">Left Hand</label>
                        <img
                          src={
                            pdfData.l_little_finger == null ||
                            pdfData.l_little_finger == ""
                              ? img5
                              : img4
                          }
                          alt="llf"
                          style={{
                            position: "absolute",
                            top: "56px",
                            left: "13px",
                          }}
                        />
                        <img
                          src={
                            pdfData.l_ring_finger == null ||
                            pdfData.l_ring_finger == ""
                              ? img5
                              : img4
                          }
                          alt="lrf"
                          style={{
                            position: "absolute",
                            top: "36px",
                            left: "33px",
                          }}
                        />
                        <img
                          src={
                            pdfData.l_middle_finger == null ||
                            pdfData.l_middle_finger == ""
                              ? img5
                              : img4
                          }
                          alt="lmf"
                          style={{
                            position: "absolute",
                            top: "31px",
                            left: "55px",
                          }}
                        />
                        <img
                          src={
                            pdfData.l_index_finger == null ||
                            pdfData.l_index_finger == ""
                              ? img5
                              : img4
                          }
                          alt="lif"
                          style={{
                            position: "absolute",
                            top: "38px",
                            left: "80px",
                          }}
                        />
                        <img
                          src={
                            pdfData.l_thumb == null || pdfData.l_thumb == ""
                              ? img5
                              : img4
                          }
                          alt="ltf"
                          style={{
                            position: "absolute",
                            top: "80px",
                            left: "102px",
                          }}
                        />
                        <img src={img2} alt="trew" />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      border: "1px solid black",
                      width: "50%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={pdfData.photograph}
                      alt="zxcvbnm"
                      width="140px"
                      height="162px"
                    />
                  </div>
                </div>
              </td>
              <td
                style={{
                  width: "100%",
                  display: "flex",
                  padding: "0px 4px 4px 7px",
                }}
              >
                <div style={{ width: "100%", display: "flex" }}>
                  <div style={{ width: "12.5%" }}>CELL NO </div>
                  <div style={{ width: "12.5%" }}>SR.NO </div>
                  {/* <div style={{ width: "12.5%" }}>CODE</div> */}
                  <div style={{ width: "12.5%",paddingLeft:'5px' }}>NUMBER</div>
                </div>
              </td>
              <td
                style={{
                  width: "100%",
                  display: "flex",
                  padding: "0px 4px 4px 7px",
                }}
              >
                <div style={{ width: "12.5%" }}></div>
                <div style={{ width: "12.5%", padding: "0px 2px" }}>
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      height: "32px",
                      lineHeight: "33px",
                    }}
                  >
                    {!pdfData.cus_mob_change?.[0] ? '' : pdfData.cus_mob_change.length > 0 ? 1
                      : pdfData.cus_mob_change.length + 1}
                  </span>
                  {!pdfData.cus_mob_change?.[0] ? '' : pdfData?.cus_mob_change.length > 0 ? ""
                    : Array.from(
                        { length: pdfData.cus_mob_change.length },
                        (_, index) => index + 1
                      )
                        .reverse()
                        .map((num, index) => (
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "end",
                              height: "32px",
                              lineHeight: "33px",
                            }}
                          >
                            {num}
                          </span>
                        ))}
                </div>
                <div style={{ width: "25%" }}>
                  <table
                    border={1}
                    cellPadding={5}
                    style={{
                      width: "100%",
                      border: "1px solid black",
                      borderCollapse: "collapse",
                    }}
                  >
                    <tr>
                      {/* <td>code</td> */}
                      <td>{pdfData.mobile_no}</td>
                    </tr>
                    {!pdfData.cus_mob_change?.[0] ? '' : pdfData.cus_mob_change.slice().reverse().map((item, index) => (
                            <tr>
                              {/* <td>code</td> */}
                              <td>{item.old_mobile_no}</td>
                            </tr>
                          ))}
                  </table>
                </div>

                <div style={{ width: "48%", padding: "32px 4px 0px 4px" }}>
                  {!pdfData.cus_mob_change?.[0] ? '' : pdfData.cus_mob_change
                        .slice()
                        .reverse()
                        .map((item, index) => (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              gap: "10px",
                            }}
                          >
                            <span
                              style={{ display: "inline-block", width: "25%" }}
                            >
                              DATE CHANGED
                            </span>
                            <input
                              style={{ width: "70%", padding: "0px 6px",height:'25.5px' }}
                              type="text"
                              value={item.created_at}
                            />
                          </div>
                        ))}
                </div>
              </td>

              <td
                style={{
                  width: "100%",
                  display:  pdfData.emailID == null ? "none" : "flex",
                  padding: "13px 4px 4px 7px",
                }}
              >
                <div style={{ width: "100%", display: "flex" }}>
                  <div style={{ width: "12.5%" }}>EMAIL ID NO </div>
                  <div style={{ width: "12.5%" }}>SR.NO </div>
                </div>
              </td>
              <td
                style={{
                  width: "100%",
                  display:  pdfData.emailID == null ? "none" : "flex",
                  padding: "0px 4px 4px 7px",
                }}
              >
                <div style={{ width: "12.5%" }}></div>
                <div style={{ width: "12.5%", padding: "0px 2px" }}>
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      height: "32px",
                      lineHeight: "33px",
                    }}
                  >
                    1
                  </span>
                </div>
                <div style={{ width: "25%" }}>
                  <table
                    border={1}
                    cellPadding={5}
                    style={{
                      width: "100%",
                      border: "1px solid black",
                      borderCollapse: "collapse",
                    }}
                  >
                    <tr>
                      {/* <td>code</td> */}
                      <td>{pdfData.emailID}</td>
                    </tr>
                  </table>
                </div>
              </td>
              <td
                style={{
                  width: "97.6%",
                  display: "flex",
                  margin: "0 auto",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    border: "1px solid black",
                    padding: "3px 2px 0px 2px",
                  }}
                >
                  <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                    {pdfData.document_type == "AADHAAR" ||
                    pdfData.document_type == "" ||
                    pdfData.document_type == null ? (
                      ""
                    ) : (
                      <div
                        style={{ width: "100%", display: "flex", gap: "10px" }}
                      >
                        <span style={{ display: "inline-block", width: "27%" }}>
                          DOCTYPE
                        </span>
                        <input
                          style={{ width: "70%", padding: "0px 6px",height:'25.5px' }}
                          type="text"
                          value={pdfData.document_type}
                        />
                      </div>
                    )}
                    {pdfData.document_type == "AADHAAR" ||
                    pdfData.document_type == "" ||
                    pdfData.document_type == null ? (
                      ""
                    ) : (
                      <div
                        style={{ width: "100%", display: "flex", gap: "10px" }}
                      >
                        <span style={{ display: "inline-block", width: "27%" }}>
                          NUMBER
                        </span>
                        <input
                          style={{ width: "70%", padding: "0px 6px",height:'25.5px' }}
                          type="text"
                          value={pdfData.document_no}
                        />
                      </div>
                    )}
                  </div>
                  <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                    {pdfData.issue_date == null ||
                    pdfData.issue_date == "" ||
                    pdfData.document_type == "AADHAAR" ||
                    pdfData.document_type == "" ||
                    pdfData.document_type == null ? (
                      ""
                    ) : (
                      <div
                        style={{ width: "100%", display: "flex", gap: "10px" }}
                      >
                        <span style={{ display: "inline-block", width: "27%" }}>
                          ISSUE DT
                        </span>
                        <input
                          style={{ width: "70%", padding: "0px 6px",height:'25.5px' }}
                          type="text"
                          value={pdfData.issue_date}
                        />
                      </div>
                    )}

                    {pdfData.expiry_date == null ||
                    pdfData.expiry_date == "" ||
                    pdfData.document_type == "AADHAAR" ||
                    pdfData.document_type == "" ||
                    pdfData.document_type == null ? (
                      ""
                    ) : (
                      <div
                        style={{ width: "100%", display: "flex", gap: "10px" }}
                      >
                        <span style={{ display: "inline-block", width: "27%" }}>
                          EXP DT
                        </span>
                        <input
                          style={{ width: "70%", padding: "0px 6px",height:'25.5px' }}
                          type="text"
                          value={pdfData.expiry_date}
                        />
                      </div>
                    )}
                  </div>
                  {/* <div style={{ width: "100%" }}>
                    <span>
                      {pdfData.document_type == "AADHAAR" ||
                      pdfData.document_type == "" ||
                      pdfData.document_type == null
                        ? pdfData.Form_60_image == null ||
                          pdfData.Form_60_image == ""
                          ? "Form 60"
                          : "Form 60"
                        : "ADDED BY CORP-AGT-USER"}
                    </span>
                  </div> */}
                  <div
                    style={{ width: "100%", display: "flex", height: "200px" }}
                  >
                    {pdfData.document_type == "AADHAAR" ||
                    pdfData.document_type == "" ||
                    pdfData.document_type == null ? (
                      pdfData.Form_60_image == null ||
                      pdfData.Form_60_image == "" ? (
                        ""
                      ) : (
                        <div
                          style={{
                            width: "50%",
                            height: "100%",
                            border: "1px solid black",
                          }}
                        >
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "200px",
                            }}
                          >
                            <img
                              src={
                                pdfData.document_type == "AADHAAR" ||
                                pdfData.document_type == "" ||
                                pdfData.document_type == null
                                  ? pdfData.Form_60_image == null ||
                                    pdfData.Form_60_image == ""
                                    ? ""
                                    : pdfData.Form_60_image
                                  : pdfData.docImg_front
                              }
                              alt="zxcvbnm"
                              width="100%"
                              height="100%"
                            />
                          </span>
                        </div>
                      )
                    ) : (
                      <div
                        style={{
                          width: "50%",
                          height: "100%",
                          border: "1px solid black",
                        }}
                      >
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "200px",
                          }}
                        >
                          <img
                            src={
                              pdfData.document_type == "AADHAAR" ||
                              pdfData.document_type == "" ||
                              pdfData.document_type == null
                                ? pdfData.Form_60_image == null ||
                                  pdfData.Form_60_image == ""
                                  ? ""
                                  : pdfData.Form_60_image
                                : pdfData.docImg_front
                            }
                            alt="zxcvbnm"
                            width="100%"
                            height="100%"
                          />
                        </span>
                      </div>
                    )}
                    {pdfData.document_type == "AADHAAR" ||
                    pdfData.document_type == "" ||
                    pdfData.document_type == null ? (
                      ""
                    ) : (
                      <div
                        style={{
                          width: "50%",
                          border: "1px solid black",
                          height: "200px",
                        }}
                      >
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "200px",
                          }}
                        >
                          <img
                            src={pdfData.docImg_back}
                            alt="zxcvbnm"
                            width="100%"
                            height="100%"
                          />
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                <div
                  style={{
                    width: "50%",
                    border: "1px solid black",
                    padding: "2px 2px 0px 2px",
                  }}
                >
                  <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                    <div
                      style={{ width: "100%", display: "flex", gap: "10px" }}
                    >
                      <span style={{ display: "inline-block", width: "27%" }}>
                        DOCTYPE
                      </span>
                      <input
                        style={{ width: "70%", padding: "0px 6px",height:'25.5px' }}
                        type="text"
                        value={pdfData.document_type2}
                      />
                    </div>

                    <div
                      style={{ width: "100%", display: "flex", gap: "10px" }}
                    >
                      <span style={{ display: "inline-block", width: "27%" }}>
                        NUMBER
                      </span>
                      <input
                        style={{ width: "70%", padding: "0px 6px",height:'25.5px' }}
                        type="text"
                        value={pdfData.document_no2}
                      />
                    </div>
                  </div>
                  <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                    {pdfData.issue_date2 == null ||
                    pdfData.issue_date2 == "" ? (
                      ""
                    ) : (
                      <div
                        style={{ width: "100%", display: "flex", gap: "10px" }}
                      >
                        <span style={{ display: "inline-block", width: "27%" }}>
                          ISSUE DT
                        </span>
                        <input
                          style={{ width: "70%", padding: "0px 6px",height:'25.5px' }}
                          type="text"
                          value={pdfData.issue_date2}
                        />
                      </div>
                    )}

                    {pdfData.expiry_date2 == null ||
                    pdfData.expiry_date2 == "" ? (
                      ""
                    ) : (
                      <div
                        style={{ width: "100%", display: "flex", gap: "10px" }}
                      >
                        <span style={{ display: "inline-block", width: "27%" }}>
                          EXP DT
                        </span>
                        <input
                          style={{ width: "70%", padding: "0px 6px",height:'25.5px' }}
                          type="text"
                          value={pdfData.expiry_date2}
                        />
                      </div>
                    )}
                  </div>
                  <div style={{ width: "100%" }}>
                    {/* <span>ADDED BY CORP-AGT-USER</span> */}
                  </div>
                  <div style={{ width: "100%", display: "flex" }}>
                    <div
                      style={{
                        width: "50%",
                        height: "100%",
                        border: "1px solid black",
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "200px",
                        }}
                      >
                        <img
                          src={pdfData.docImg_front2}
                          alt="zxcvbnm"
                          width="100%"
                          height="100%"
                        />
                      </span>
                    </div>
                    <div
                      style={{
                        width: "50%",
                        border: "1px solid black",
                        height: "200px",
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "200px",
                        }}
                      >
                        <img
                          src={pdfData.docImg_back2}
                          alt="zxcvbnm"
                          width="100%"
                          height="100%"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
  {!pdfData.rem_customer?.[0] ? '' : pdfData.rem_customer.length > 3 ? '' : <tr
              style={{
                border: "3.4px solid #191941",
                marginTop: "7px",
                display: "block",
                padding: "8px 0px 4px 0px",
              }}
            >
              <td style={{ display: "flex", padding: "7px 4px 0px 7px" }}>
                <div
                  style={{
                    width: "97.6%",
                    paddingTop: "10px",
                    margin: "0 auto",
                  }}
                >
                  <span style={{ display: "inline-block", width: "100%" }}>
                    ID USED FOR
                  </span>
                </div>
              </td>
              
              {pdfData.rem_customer.map((item, index) => (
                <td
                  style={{
                    paddingTop: "20px",
                    width: "97.6%",
                    display: "flex",
                    margin: "0 auto",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      border: "1px solid black",
                      padding: "4px 2px 0px 2px",
                    }}
                  >
                    <div style={{ width: "98.9%", margin: "0 auto" }}>
                      <span>SR.NO</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        width: "98.9%",
                        margin: "0 auto",
                        gap: "5px",
                        paddingBottom: "6px",
                      }}
                    >
                      <div style={{ width: "5%" }}>{index + 1}</div>
                      <div style={{ width: "38%" }}>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            gap: "10px",
                          }}
                        >
                          <span
                            style={{ display: "inline-block", width: "24%" }}
                          >
                            PRODUCT
                          </span>
                          <div
                            style={{
                              width: "70%",
                              padding: "0 7px",
                              border: "1px solid #333",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={`data:image/webp;base64,${item.product_logo}`}
                              width={"25px"}
                              alt=""
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            gap: "10px",
                          }}
                        >
                          <span
                            style={{ display: "inline-block", width: "24%" }}
                          >
                            OTP
                          </span>

                          <span
                            style={{
                              width: "70.5%",
                              padding: "4px 6px",
                              borderLeft: "1px solid #333",
                              borderRight: "1px solid #333",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={img4}
                              alt="404"
                              style={{ width: "20px" }}
                            />
                          </span>
                        </div>
                        <div
                          style={{ width: "100%", display: "flex", gap: "0px" }}
                        >
                          <span
                            style={{
                              display: "inline-block",
                              width: "24%",
                              border: "1px solid black",
                              lineHeight: "27px",
                            }}
                          >
                            CORP
                          </span>
                          <input
                            style={{
                              width: "70%",
                              padding: "0px 6px",height:'25.5px',
                              position: "relative",
                              right: "-8px",
                            }}
                            value="AGT"
                            type="text"
                          />
                        </div>
                      </div>
                      <div
                        style={{ width: "56%", display: "flex", gap: "4px" }}
                      >
                        <div style={{ width: "63%", display: "flex" }}>
                          <span>DETAIL BIO USER BIO DATA</span>
                          <div
                            style={{
                              border: "1px solid black",
                              width: "80%",
                              height: "200px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  position: "relative",
                                }}
                              >
                                <label htmlFor="">Right Hand</label>
                                <img
                                  src={
                                    item.r_thumb == null || item.r_thumb == ""
                                      ? img5
                                      : img4
                                  }
                                  alt="rtf"
                                  style={{
                                    position: "absolute",
                                    top: "80px",
                                    left: "13px",
                                  }}
                                />
                                <img
                                  src={
                                    item.r_index_finger == null ||
                                    item.r_index_finger == ""
                                      ? img5
                                      : img4
                                  }
                                  alt="rif"
                                  style={{
                                    position: "absolute",
                                    top: "37px",
                                    left: "35px",
                                  }}
                                />
                                <img
                                  src={
                                    item.r_middle_finger == null ||
                                    item.r_middle_finger == ""
                                      ? img5
                                      : img4
                                  }
                                  alt="rmf"
                                  style={{
                                    position: "absolute",
                                    top: "30px",
                                    left: "65px",
                                  }}
                                />
                                <img
                                  src={
                                    item.r_ring_finger == null ||
                                    item.r_ring_finger == ""
                                      ? img5
                                      : img4
                                  }
                                  alt="rrf"
                                  style={{
                                    position: "absolute",
                                    top: "36px",
                                    left: "84px",
                                  }}
                                />
                                <img
                                  src={
                                    item.r_little_finger == null ||
                                    item.r_little_finger == ""
                                      ? img5
                                      : img4
                                  }
                                  alt="rlf"
                                  style={{
                                    position: "absolute",
                                    top: "55px",
                                    left: "103px",
                                  }}
                                />
                                <img src={img1} alt="qwerty" />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  position: "relative",
                                }}
                              >
                                <label htmlFor="">Left Hand</label>
                                <img
                                  src={
                                    item.l_little_finger == null ||
                                    item.l_little_finger == ""
                                      ? img5
                                      : img4
                                  }
                                  alt="llf"
                                  style={{
                                    position: "absolute",
                                    top: "56px",
                                    left: "13px",
                                  }}
                                />
                                <img
                                  src={
                                    item.l_ring_finger == null ||
                                    item.l_ring_finger == ""
                                      ? img5
                                      : img4
                                  }
                                  alt="lrf"
                                  style={{
                                    position: "absolute",
                                    top: "36px",
                                    left: "33px",
                                  }}
                                />
                                <img
                                  src={
                                    item.l_middle_finger == null ||
                                    item.l_middle_finger == ""
                                      ? img5
                                      : img4
                                  }
                                  alt="lmf"
                                  style={{
                                    position: "absolute",
                                    top: "31px",
                                    left: "55px",
                                  }}
                                />
                                <img
                                  src={
                                    item.l_index_finger == null ||
                                    item.l_index_finger == ""
                                      ? img5
                                      : img4
                                  }
                                  alt="lif"
                                  style={{
                                    position: "absolute",
                                    top: "38px",
                                    left: "80px",
                                  }}
                                />
                                <img
                                  src={
                                    item.l_thumb == null || item.l_thumb == ""
                                      ? img5
                                      : img4
                                  }
                                  alt="ltf"
                                  style={{
                                    position: "absolute",
                                    top: "80px",
                                    left: "102px",
                                  }}
                                />
                                <img src={img2} alt="trew" />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          style={{ width: "35%", display: "flex", gap: "4px" }}
                        >
                          <span>Country</span>
                          <input
                            type="text"
                            style={{ height: "24px",width:'100%' }}
                            value={item.country}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              ))}
            </tr>}
            
          </table>
          {!pdfData.rem_customer?.[0] ? '' : pdfData.rem_customer.length > 3 ?  <table
            id="pdf-content"
            className='pdf-section'
            style={{
              width: "100%",
              border: "2px solid #3d3d4a",
              padding: "18px 11px",
            }}
          >
            {/* <tr style={{ border: "3.4px solid #191941", display: "block" }}>
              <td style={{ display: "block", padding: "7px 0px 0px 7px" }}>
                <img src={img} alt="logo" style={{ width: "15%" }} />
              </td>
              <td style={{ padding: "0px 0px 5px 9px", display: "block" }}>
                <span
                  style={{
                    display: "inline-block",
                    width: "30%",
                    fontSize: "18px",
                    fontWeight: 500,
                  }}
                >
                  KYCFirst.io
                </span>
                <span
                  style={{
                    width: "69%",
                    display: "inline-block",
                    textAlign: "end",
                    fontSize: "18px",
                    fontWeight: 500,
                  }}
                >
                  {" "}
                  customer id : {pdfData.c_id}
                </span>
              </td>
            </tr>

            <tr
              style={{
                border: "3.4px solid #191941",
                marginTop: "7px",
                display: "block",
                padding: "8px 0px 4px 0px",
              }}
            >
              <td style={{ display: "flex", padding: "7px 4px 0px 7px" }}>
                <div style={{ width: "50%", display: "flex", gap: "10px" }}>
                  <span style={{ display: "inline-block", width: "26%" }}>
                    This PDF is Generated
                  </span>
                  <input style={{ width: "65%", padding: "0px 6px",height:'25.5px' }} type="text" />
                </div>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    gap: "10px",
                    justifyContent: "center",
                  }}
                >
                  <span style={{ display: "inline-block", width: "26%" }}>
                    date
                  </span>
                  <input style={{ width: "65%", padding: "0px 6px",height:'25.5px' }} type="text" />
                </div>
              </td>
              <td style={{ display: "flex", padding: "0px 4px 0px 7px" }}>
                <div style={{ width: "50%", display: "flex", gap: "10px" }}>
                  <span style={{ display: "inline-block", width: "26%" }}>
                    Corporate
                  </span>
                  <input style={{ width: "65%", padding: "0px 6px",height:'25.5px' }} type="text" />
                </div>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    gap: "10px",
                    justifyContent: "center",
                  }}
                >
                  <span style={{ display: "inline-block", width: "26%" }}>
                    Agent
                  </span>
                  <input style={{ width: "65%", padding: "0px 6px",height:'25.5px' }} type="text" />
                </div>
              </td>
              <td style={{ display: "flex", padding: "0px 4px 4px 7px" }}>
                <div style={{ width: "50%", display: "flex", gap: "10px" }}>
                  <span style={{ display: "inline-block", width: "26%" }}>
                    User
                  </span>
                  <input style={{ width: "65%", padding: "0px 6px",height:'25.5px' }} type="text" />
                </div>
              </td>
            </tr> */}


            <tr
              style={{
                border: "3.4px solid #191941",
                marginTop: "7px",
                display: "block",
                padding: "8px 0px 4px 0px",
              }}
            >
              <td style={{ display: "flex", padding: "7px 4px 0px 7px" }}>
                <div
                  style={{
                    width: "97.6%",
                    paddingTop: "10px",
                    margin: "0 auto",
                  }}
                >
                  <span style={{ display: "inline-block", width: "100%" }}>
                    ID USED FOR
                  </span>
                </div>
              </td>
              {pdfData.rem_customer.map((item, index) => (
                <td
                  style={{
                    paddingTop: "20px",
                    width: "97.6%",
                    display: "flex",
                    margin: "0 auto",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      border: "1px solid black",
                      padding: "4px 2px 0px 2px",
                    }}
                  >
                    <div style={{ width: "98.9%", margin: "0 auto" }}>
                      <span>SR.NO</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        width: "98.9%",
                        margin: "0 auto",
                        gap: "5px",
                        paddingBottom: "6px",
                      }}
                    >
                      <div style={{ width: "5%" }}>{index + 1}</div>
                      <div style={{ width: "38%" }}>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            gap: "10px",
                          }}
                        >
                          <span
                            style={{ display: "inline-block", width: "24%" }}
                          >
                            PRODUCT
                          </span>
                          <div
                            style={{
                              width: "70%",
                              padding: "0 7px",
                              border: "1px solid #333",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={`data:image/webp;base64,${item.product_logo}`}
                              width={"25px"}
                              alt=""
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            gap: "10px",
                          }}
                        >
                          <span
                            style={{ display: "inline-block", width: "24%" }}
                          >
                            OTP
                          </span>

                          <span
                            style={{
                              width: "70.5%",
                              padding: "4px 6px",
                              borderLeft: "1px solid #333",
                              borderRight: "1px solid #333",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={img4}
                              alt="404"
                              style={{ width: "20px" }}
                            />
                          </span>
                        </div>
                        <div
                          style={{ width: "100%", display: "flex", gap: "0px" }}
                        >
                          <span
                            style={{
                              display: "inline-block",
                              width: "24%",
                              border: "1px solid black",
                              lineHeight: "27px",
                            }}
                          >
                            CORP
                          </span>
                          <input
                            style={{
                              width: "70%",
                              padding: "0px 6px",height:'25.5px',
                              position: "relative",
                              right: "-8px",
                            }}
                            value="AGT"
                            type="text"
                          />
                        </div>
                      </div>
                      <div
                        style={{ width: "56%", display: "flex", gap: "4px" }}
                      >
                        <div style={{ width: "63%", display: "flex" }}>
                          <span>DETAIL BIO USER BIO DATA</span>
                          <div
                            style={{
                              border: "1px solid black",
                              width: "80%",
                              height: "200px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  position: "relative",
                                }}
                              >
                                <label htmlFor="">Right Hand</label>
                                <img
                                  src={
                                    item.r_thumb == null || item.r_thumb == ""
                                      ? img5
                                      : img4
                                  }
                                  alt="rtf"
                                  style={{
                                    position: "absolute",
                                    top: "80px",
                                    left: "13px",
                                  }}
                                />
                                <img
                                  src={
                                    item.r_index_finger == null ||
                                    item.r_index_finger == ""
                                      ? img5
                                      : img4
                                  }
                                  alt="rif"
                                  style={{
                                    position: "absolute",
                                    top: "37px",
                                    left: "35px",
                                  }}
                                />
                                <img
                                  src={
                                    item.r_middle_finger == null ||
                                    item.r_middle_finger == ""
                                      ? img5
                                      : img4
                                  }
                                  alt="rmf"
                                  style={{
                                    position: "absolute",
                                    top: "30px",
                                    left: "65px",
                                  }}
                                />
                                <img
                                  src={
                                    item.r_ring_finger == null ||
                                    item.r_ring_finger == ""
                                      ? img5
                                      : img4
                                  }
                                  alt="rrf"
                                  style={{
                                    position: "absolute",
                                    top: "36px",
                                    left: "84px",
                                  }}
                                />
                                <img
                                  src={
                                    item.r_little_finger == null ||
                                    item.r_little_finger == ""
                                      ? img5
                                      : img4
                                  }
                                  alt="rlf"
                                  style={{
                                    position: "absolute",
                                    top: "55px",
                                    left: "103px",
                                  }}
                                />
                                <img src={img1} alt="qwerty" />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  position: "relative",
                                }}
                              >
                                <label htmlFor="">Left Hand</label>
                                <img
                                  src={
                                    item.l_little_finger == null ||
                                    item.l_little_finger == ""
                                      ? img5
                                      : img4
                                  }
                                  alt="llf"
                                  style={{
                                    position: "absolute",
                                    top: "56px",
                                    left: "13px",
                                  }}
                                />
                                <img
                                  src={
                                    item.l_ring_finger == null ||
                                    item.l_ring_finger == ""
                                      ? img5
                                      : img4
                                  }
                                  alt="lrf"
                                  style={{
                                    position: "absolute",
                                    top: "36px",
                                    left: "33px",
                                  }}
                                />
                                <img
                                  src={
                                    item.l_middle_finger == null ||
                                    item.l_middle_finger == ""
                                      ? img5
                                      : img4
                                  }
                                  alt="lmf"
                                  style={{
                                    position: "absolute",
                                    top: "31px",
                                    left: "55px",
                                  }}
                                />
                                <img
                                  src={
                                    item.l_index_finger == null ||
                                    item.l_index_finger == ""
                                      ? img5
                                      : img4
                                  }
                                  alt="lif"
                                  style={{
                                    position: "absolute",
                                    top: "38px",
                                    left: "80px",
                                  }}
                                />
                                <img
                                  src={
                                    item.l_thumb == null || item.l_thumb == ""
                                      ? img5
                                      : img4
                                  }
                                  alt="ltf"
                                  style={{
                                    position: "absolute",
                                    top: "80px",
                                    left: "102px",
                                  }}
                                />
                                <img src={img2} alt="trew" />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          style={{ width: "35%", display: "flex", gap: "4px" }}
                        >
                          <span>Country</span>
                          <input
                            type="text"
                            style={{ height: "24px" }}
                            value={item.country}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              ))}
            </tr>
          </table> : ''}
         
        </div>
      {/* <Header /> */}
      <div className="dashboardcontent px-2">
      {/* <Breadcup name={'Customer'} /> */}
      {/* <div className="container py-4">
        
      </div> */}
      <div className="container-fuild px-2 desgin1">
      <div className="row bg-white ">
            {/* <div className="col-12 py-3 customtext23" style={{background: 'hsla(210, 85%, 32%, 0.2)',color:'#0C5398'}}> Custome Detail</div> */}
            <div className="col-lg-12 d-flex justify-content-between py-2" style={{ background: 'hsla(210, 85%, 32%, 0.2)', color: '#0C5398' }}>
              <p className="m-0 customfont" >Customer Detail</p>
              <div className="addnew d-flex mb-2" style={{gap:"4px"}}>
                <button className="btn text-white closebtn" onClick={handleDownload}> PDF</button>
                <button className="btn text-white closebtn"><NavLink to='/customerlist/0' style={{ textDecoration: 'none', color: 'white' }}> x Close</NavLink></button>
              </div>
            </div>
        </div>
      </div>
      {srtloader == true ? <div className="container-fuild bg-white"> <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"100%"}}>
        <div className='px-2'> <Loadercomp size={100} /></div>
        </div></div> : <div className="container-fuild pb-4 pt-3 px-2 bg-white" id="pdf-content">
       <div className="row bg-white pb-4 round" style={{border:'1px solid #E0E0E0',margin:"10px 0px",borderRadius: '3px',position:'relative'}}>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">First Name </label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{fname}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Last Name </label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{lname != "undefined" && lname != "" ? lname : ''}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label customw">Father Name  </label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{ffn}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label customw">Email Address  </label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{emailID}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Gender </label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{gender}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Company </label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{company}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Location </label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{location}</p>
            </div>
          </div>
        </div>
       
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">DOB </label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{crtdate}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Age </label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{age}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Mobile No. </label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{mobile_no}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Address </label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{address}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Country </label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{country}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">State </label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{state}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">City </label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{city}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Pincode</label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{pincode}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Nationality</label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{nationality}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Created by</label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{created_by}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Created Date & Time</label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{created_at}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Last Modified by </label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{last_updated_by ? last_updated_by : "NOT MODIFIED"}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Last Modified Date & Time </label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{last_updated_at ? last_updated_at : "NOT MODIFIED"}</p>
            </div>
          </div>
        </div>
       <div className="col-12">
       <div className="row px-4 pt-3">
        <div className="col-md-6">
          <div className="row">
            <div className="col-12"><label htmlFor="" className="form-label">Biometric Detail </label></div>
            <div className="col-12">
                        <div className="border round py-3 d-flex justify-content-center">
                        <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
     <label htmlFor="">Right Hand</label>
     <img src={thumbimg2 == null ? img5 : img4} alt="rtf" style={{position:"absolute",top:"80px",left:"13px"}} />
     <img src={rifimg2 == null ? img5 : img4} alt="rif" style={{position:"absolute",top:"37px",left:"35px"}} />
     <img src={rmfimg2 == null ? img5 : img4} alt="rmf" style={{position:"absolute",top:"30px",left:"65px"}} />
     <img src={rrfimg2 == null ? img5 : img4} alt="rrf" style={{position:"absolute",top:"36px",left:"84px"}} />
     <img src={rlfimg2 == null ? img5 : img4} alt="rlf" style={{position:"absolute",top:"55px",left:"103px"}} />
                          <img src={img1} alt="qwerty" />
                          </div>
                          <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
<label htmlFor="">Left Hand</label>
<img src={llfimg2 == null ? img5 : img4} alt="llf" style={{position:"absolute",top:"56px",left:"13px"}} />
     <img src={lrfimg2 == null ? img5 : img4} alt="lrf" style={{position:"absolute",top:"36px",left:"33px"}} />
     <img src={lmfimg2 == null ? img5 : img4} alt="lmf" style={{position:"absolute",top:"31px",left:"55px"}} />
     <img src={lifimg2 == null ? img5 : img4} alt="lif" style={{position:"absolute",top:"38px",left:"80px"}} />
     <img src={ltfimg2 == null ? img5 : img4} alt="ltf" style={{position:"absolute",top:"80px",left:"102px"}} />
                          <img src={img2} alt="trew" />
                          </div>
                        </div>
                      </div>
          </div>
          <div className="col-12 py-2 px-4 d-flex justify-content-between">
                <button
                type='button'
                  // className="btn1"
                  style={{backgroundColor:"#0C5398"}}
                  className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  View
                </button>
              </div>
        </div>
        <div className="col-md-6">
        <div className="row">
            <div className="col-12"><label htmlFor="" className="form-label">Photograph </label></div>
            <div className="col-12 ">
            <div className="border round py-3 d-flex justify-content-center">

            <button
                type='button'
                style={{border:'none',outline:'none'}}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModaltwo"
                >
              <img src={photograph == null ? img3 : photograph} alt="zxcvbnm" width="140px" height="162px" />
              </button>

            {/* <ReactImageMagnify
                            {...{
                                smallImage: {
                                    alt: 'Wristwatch by Versace',
                                    isFluidWidth: true,
                                    src: photograph == null ? img3 : photograph,
                                    width:140,
                                    height:162
                                },
                                largeImage: {
                                    src: photograph == null ? img3 : photograph,
                                    width: 836,
                                    height: 1100
                                },
                                // enlargedImagePosition: 'over',
                                lensStyle: { backgroundColor: 'rgba(0,0,0,.6)' }
                            }}
                            
                        /> */}
            {/* <img src={photograph == "" ? img3 : photograph} alt="zxcvbnm"  width="140px" height="162px"  /> */}
             </div>
            </div>
          </div>
        </div>
       </div>
       </div>

       <div className="col-12">
       <div className="row px-4 pt-3">
        <div className="col-md-6">
          <div className="row">
            <div className="col-12"><label htmlFor="" className="form-label">Created Agent Biometric Detail </label></div>
            <div className="col-12">
                        <div className="border round py-3 d-flex justify-content-center">
                        <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
     <label htmlFor="">Right Hand</label>
     <img src={thumbimg3 == null ? img5 : img4} alt="rtf" style={{position:"absolute",top:"80px",left:"13px"}} />
     <img src={rifimg3 == null ? img5 : img4} alt="rif" style={{position:"absolute",top:"37px",left:"35px"}} />
     <img src={rmfimg3 == null ? img5 : img4} alt="rmf" style={{position:"absolute",top:"30px",left:"65px"}} />
     <img src={rrfimg3 == null ? img5 : img4} alt="rrf" style={{position:"absolute",top:"36px",left:"84px"}} />
     <img src={rlfimg3 == null ? img5 : img4} alt="rlf" style={{position:"absolute",top:"55px",left:"103px"}} />
                          <img src={img1} alt="qwerty" />
                          </div>
                          <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
<label htmlFor="">Left Hand</label>
<img src={llfimg3 == null ? img5 : img4} alt="llf" style={{position:"absolute",top:"56px",left:"13px"}} />
     <img src={lrfimg3 == null ? img5 : img4} alt="lrf" style={{position:"absolute",top:"36px",left:"33px"}} />
     <img src={lmfimg3 == null ? img5 : img4} alt="lmf" style={{position:"absolute",top:"31px",left:"55px"}} />
     <img src={lifimg3 == null ? img5 : img4} alt="lif" style={{position:"absolute",top:"38px",left:"80px"}} />
     <img src={ltfimg3 == null ? img5 : img4} alt="ltf" style={{position:"absolute",top:"80px",left:"102px"}} />
                          <img src={img2} alt="trew" />
                          </div>
                        </div>
                      </div>
          </div>
          <div className="col-12 py-2 px-4 d-flex justify-content-between">
                <button
                type='button'
                  // className="btn1"
                  style={{backgroundColor:"#0C5398"}}
                  className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal009"
                >
                  View
                </button>
              </div>
        </div>
        <div className="col-md-6">
        <div className="row">
            <div className="col-12"><label htmlFor="" className="form-label">Created Agent Photograph </label></div>
            <div className="col-12 ">
            <div className="border round py-3 d-flex justify-content-center">

            <button
                type='button'
                style={{border:'none',outline:'none'}}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModaltwothird"
                >
              <img src={agentphotograph == null ? img3 : agentphotograph} alt="zxcvbnm" width="140px" height="162px" />
              </button>

            {/* <ReactImageMagnify
                            {...{
                                smallImage: {
                                    alt: 'Wristwatch by Versace',
                                    isFluidWidth: true,
                                    src: photograph == null ? img3 : photograph,
                                    width:140,
                                    height:162
                                },
                                largeImage: {
                                    src: photograph == null ? img3 : photograph,
                                    width: 836,
                                    height: 1100
                                },
                                // enlargedImagePosition: 'over',
                                lensStyle: { backgroundColor: 'rgba(0,0,0,.6)' }
                            }}
                            
                        /> */}
            {/* <img src={photograph == "" ? img3 : photograph} alt="zxcvbnm"  width="140px" height="162px"  /> */}
             </div>
            </div>
          </div>
        </div>
       </div>
       </div>
      <div className="col-12 ccolor py-3 px-4">Document 1</div> 
       { documenttype == "PAN" || documenttype == "PASSPORT" ? <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Document Type </label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{documenttype}</p>
            </div>
          </div>
        </div> : ''}
        { documenttype == "PAN" || documenttype == "PASSPORT" ?  <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Document No. </label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{idnumber}</p>
            </div>
          </div>
        </div> : ''}
       
        {documenttype == "PAN" || documenttype == "PASSPORT" ? documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT' ? <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Expiry date </label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{documentexpiry}</p>
            </div>
          </div>
        </div> : '' : ''}

        {documenttype == "PAN" || documenttype == "PASSPORT" ? documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT' ? <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Issue date </label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{documentissue}</p>
            </div>
          </div>
        </div> : '' : ''}
        {documenttype == "PAN" || documenttype == "PASSPORT" ? documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT' ? <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Country </label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{doccountry}</p>
            </div>
          </div>
        </div> : '' : ''}
        {documenttype == "PAN" || documenttype == "PASSPORT" ? documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT' ? <div className="col-lg-6 px-4 pt-3">
          {/* <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Country </label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{doccountry}</p>
            </div>
          </div> */}
        </div> : '' : ''}
        { documenttype == "PAN" || documenttype == "PASSPORT" ? aadhaarImage == "null" || aadhaarImage == null || aadhaarImage == '' ? '' : <div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        Document Front Image
      </div>
      <div className="col-lg-8">
      <img src={aadhaarImage} alt="Modified Aadhaar Card" style={{width:"100%",height:"100%"}} />
      </div>
      <div className=" offset-lg-4 col-lg-8">
      <button
style={{backgroundColor:"#0C5398",marginTop:"5px"}}
type='button'
className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal34"
                >
View
                </button>
      </div>
    </div>
  </div> : ''}
  { documenttype == "PAN" || documenttype == "PASSPORT" ? documentimagebacktwo == "null" || documentimagebacktwo == null || documentimagebacktwo == '' ? '' : <div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        Document Back Image
      </div>
      <div className="col-lg-8">
      <img src={documentimagebacktwo} alt="Modified Aadhaar Card" style={{width:"100%",height:"100%"}} />
      </div>
      <div className=" offset-lg-4 col-lg-8">
      <button
style={{backgroundColor:"#0C5398",marginTop:"5px"}}
type='button'
className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal35"
                >
View
                </button>
      </div>
    </div>
  </div> : ''}
  {documenttype == "PAN" || documenttype == "PASSPORT" ? documentimagebacktwo == "null" || documentimagebacktwo == null || documentimagebacktwo == '' ? <div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        Document View PDF
      </div>
      <div className="col-lg-8 d-flex justify-content-center">
      {doc1 == null || doc1 == ''? '' : <a href={doc1} style={{backgroundColor:"#0C5398",marginTop:"5px",marginLeft:"5px"}}
type='button'
className='btn btn-primary' target="_blank" rel="noopener noreferrer"> Document PDF</a>}
      </div>

    </div>
  </div> : '' :''}

  {documenttype == "PAN" || documenttype == "PASSPORT" ? "" :                        <div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        form 60
      </div>
      <div className="col-lg-8 border d-flex justify-content-center">
      <img src={Form_60_image == '' || Form_60_image == null ? img33 : Form_60_image} alt="Modified Aadhaar Card" width="140px" height="162px"  />
      </div>
      <div className=" offset-lg-4 col-lg-8">
      <button
style={{backgroundColor:"#0C5398",marginTop:"5px"}}
type='button'
className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal34910"
                >
View
                </button>
                {/* <button onClick={handleDownloadit(Form_60_pdf)}>Download PDF</button> */}
                {Form_60_pdf == null || Form_60_pdf == ''? '' : <a href={Form_60_pdf} style={{backgroundColor:"#0C5398",marginTop:"5px",marginLeft:"5px"}}
type='button'
className='btn btn-primary' target="_blank" rel="noopener noreferrer"> View pdf</a>}
      </div>
    </div>
  </div>}
  <div className="col-12 py-3 px-4">
        
        <div
          style={{
            background: "#D9D9D9",
            height: "1px",
            width: "100%",
          }}
        ></div>
      </div>
 {/* seond document start here */}
 <div className="col-12 ccolor py-3 px-4">Document 2</div> 

 <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Document Type </label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{documenttypetwo}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Document No. </label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{idnumbertwo}</p>
            </div>
          </div>
        </div>
        {documenttypetwo == 'DRIVING_LICENSE' || documenttypetwo == "RESIDENT_PERMIT" || documenttypetwo == "VISA" || documenttypetwo == "REFUGEE_CARD" || documenttypetwo == 'PASSPORT' ? <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Expiry date </label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{documentexpirytwo}</p>
            </div>
          </div>
        </div> : ''}

        {documenttypetwo == 'DRIVING_LICENSE' || documenttypetwo == "RESIDENT_PERMIT" || documenttypetwo == "VISA" || documenttypetwo == "REFUGEE_CARD" || documenttypetwo == 'PASSPORT' ? <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Issue date </label>
            </div>
            <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor ps-4'>{documentissuetwo}</p>
            </div>
          </div>
        </div> : ''}



        {modifiedImagenumbertwo == "null" || modifiedImagenumbertwo == null || modifiedImagenumbertwo == '' ? <div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        Document 2 View PDF
      </div>
      <div className="col-lg-8 d-flex justify-content-center">
      {doc2 == null || doc2 == ''? '' : <a href={doc2} style={{backgroundColor:"#0C5398",marginTop:"5px",marginLeft:"5px"}}
type='button'
className='btn btn-primary' target="_blank" rel="noopener noreferrer"> Document PDF</a>}
      </div>

    </div>
  </div> : ''}

  {modifiedImagenumbertwo == "null" || modifiedImagenumbertwo == null || modifiedImagenumbertwo == '' ? '' : <div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        Document Front Image
      </div>
      <div className="col-lg-8">
      <img src={modifiedImagenumbertwo} alt="Modified Aadhaar Card" style={{width:"100%",height:"100%"}} />
      </div>
      <div className=" offset-lg-4 col-lg-8">
      <button
style={{backgroundColor:"#0C5398",marginTop:"5px"}}
type='button'
className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal345"
                >
View
                </button>
      </div>
    </div>
  </div>}
      

  {documentimageback2 == "null" || documentimageback2 == null || documentimageback2 == '' ? '' :  <div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        Document Back Image
      </div>
      <div className="col-lg-8">
      <img src={documentimageback2} alt="Modified Aadhaar Card" style={{width:"100%",height:"100%"}} />
      </div>
      <div className=" offset-lg-4 col-lg-8">
      <button
style={{backgroundColor:"#0C5398",marginTop:"5px"}}
type='button'
className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal355"
                >
View
                </button>
      </div>
    </div>
  </div>} 
 


  {addaccount == true ?  <div className="col-12 py-3 px-4">
        
        <div
          style={{
            background: "#D9D9D9",
            height: "1px",
            width: "100%",
          }}
        ></div>
      </div> : ''}







      {/* {addaccount == true ? <div className="col-12 ccolor py-3 px-4">Bank Account Verify</div> : ''} */}
{accountdata.map((item, index) =>(
  <>
  {addaccount == true ? <div className="col-12 ccolor py-3 px-4">Bank Account {index + 1}</div> : ''}
  {addaccount == true ? <div className="col-md-6 px-3" style={{ paddingTop: "28px" }}>
<div className="row">
 <div className="col-md-4 col-4">
   <label htmlFor="" className="form-label">
      Account No. <span style={{ color: "red" }}>*</span>
   </label>
 </div>
 <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor'>{item.beneficiaryAccount}</p>
            </div>
</div>
</div> : "" } 

{addaccount == true ? <div className="col-md-6 px-3" style={{ paddingTop: "28px" }}>
<div className="row">
 <div className="col-md-4 col-4">
   <label htmlFor="" className="form-label">
   IFSC Code <span style={{ color: "red" }}>*</span>
   </label>
 </div>
<div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor'>{item.beneIFSC}</p>
            </div>
</div>
</div> : ""}
{addaccount == true ? accountname == null ? "" : <div className="col-md-6 px-3" style={{ paddingTop: "28px" }}>
<div className="row">
 <div className="col-lg-4">
   <label htmlFor="" className="form-label">
   Holder Name <span style={{ color: "red" }}>*</span>
   </label>
 </div>
 <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            <p className='customcolor'>{item.beneName}</p>
            </div>
 
</div>
</div> : ""}
{addaccount == true ? accountname == null ? "" : <div className="col-md-6 px-3" style={{ paddingTop: "28px" }}>
<div className="row">
 <div className="col-lg-4">
   <label htmlFor="" className="form-label">
   {/* Holder Name <span style={{ color: "red" }}>*</span> */}
   </label>
 </div>
 <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
            {/* <p className='customcolor ps-4'>{item.beneName}</p> */}
            </div>
 
</div>
</div> : ""}
  </>
))}






 {/* seond document end here */}

      </div>
      </div>}
      </div>
      <div className="modal fade" id="exampleModaltwo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>
                <div className="col-12 px-2 pt-3">
  <img src={photograph == null ? img3 : photograph} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
              </div>
            </div>
          </div>  

          <div className="modal fade" id="exampleModaltwothird" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>
                <div className="col-12 px-2 pt-3">
  <img src={agentphotograph == null ? img3 : agentphotograph} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
              </div>
            </div>
          </div>  
              <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>

<div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="tf2" className="form-label ">Right Thumb  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" class="form-control" id='tf2' name="image2" style={{opacity:"0",position:'absolute'}}  onChange={(e) => {handleImageChange11(e)}}  /> */}
        {/* <label htmlFor="tf"> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: thumbimg2 != null && thumbimg2 != "" ? '' : '3rem',width:"20%" }}> {thumbimg2 != null && thumbimg2 != "" ? <img src={`data:image/png;base64,${thumbimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>

      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="ltf2" className="form-label ">Left Thumb  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="ltf2" onChange={(e) => {handleImageChange16(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: ltfimg2 != null ? '' : '3rem',width:"20%" }}> {ltfimg2 != null ? <img src={`data:image/png;base64,${ltfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rif2" className="form-label ">Right Index Finger <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rif2" onChange={(e) => {handleImageChange12(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rifimg2 != null ? '' : '3rem',width:"20%" }}> {rifimg2 != null ? <img src={`data:image/png;base64,${rifimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="lif2" className="form-label ">Left Index Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lif2" onChange={(e) => {handleImageChange17(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lifimg2 != null ? '' : '3rem',width:"20%" }}> {lifimg2 != null ? <img src={`data:image/png;base64,${lifimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rmf2" className="form-label ">Right Middle Finger<span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rmf2" onChange={(e) => {handleImageChange13(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rmfimg2 != null ? '' : '3rem',width:"20%" }}> {rmfimg2 != null ? <img src={`data:image/png;base64,${rmfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      </div>
    </div>

  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="lmf2" className="form-label ">Left Middle Finger <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lmf2" onChange={(e) => {handleImageChange18(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lmfimg2 != null ? '' : '3rem',width:"20%" }}> {lmfimg2 != null ? <img src={`data:image/png;base64,${lmfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
        
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rrf2" className="form-label ">Right Ring Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rrf2" onChange={(e) => {handleImageChange14(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rrfimg2 != null ? '' : '3rem',width:"20%" }}> {rrfimg2 != null ? <img src={`data:image/png;base64,${rrfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
       
      </div>
    </div>

  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="lrf2" className="form-label ">Left Ring Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lrf2" onChange={(e) => {handleImageChange19(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lrfimg2 != null ? '' : '3rem',width:"20%" }}> {lrfimg2 != null ? <img src={`data:image/png;base64,${lrfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
        
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rlf2" className="form-label ">Right Little Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rlf2" onChange={(e) => {handleImageChange15(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rlfimg2 != null ? '' : '3rem',width:"20%" }}> {rlfimg2 != null ? <img src={`data:image/png;base64,${rlfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
     
      </div>
    </div>
  </div>


 
 
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="llf2" className="form-label ">Left Little Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="llf2" onChange={(e) => {handleImageChange20(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: llfimg2 != null ? '' : '3rem',width:"20%" }}> {llfimg2 != null ? <img src={`data:image/png;base64,${llfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      
      </div>
    </div>
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>








          <div className="modal fade" id="exampleModal009" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>

<div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="tf2" className="form-label ">Right Thumb  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" class="form-control" id='tf2' name="image2" style={{opacity:"0",position:'absolute'}}  onChange={(e) => {handleImageChange11(e)}}  /> */}
        {/* <label htmlFor="tf"> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: thumbimg3 != null && thumbimg3 != "" ? '' : '3rem',width:"20%" }}> {thumbimg3 != null && thumbimg3 != "" ? <img src={`data:image/png;base64,${thumbimg3}`} width="70px" height="60px" alt="asd" /> : ''}  </div>

      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="ltf2" className="form-label ">Left Thumb  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="ltf2" onChange={(e) => {handleImageChange16(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: ltfimg3 != null ? '' : '3rem',width:"20%" }}> {ltfimg3 != null ? <img src={`data:image/png;base64,${ltfimg3}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rif2" className="form-label ">Right Index Finger <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rif2" onChange={(e) => {handleImageChange12(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rifimg3 != null ? '' : '3rem',width:"20%" }}> {rifimg3 != null ? <img src={`data:image/png;base64,${rifimg3}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="lif2" className="form-label ">Left Index Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lif2" onChange={(e) => {handleImageChange17(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lifimg3 != null ? '' : '3rem',width:"20%" }}> {lifimg3 != null ? <img src={`data:image/png;base64,${lifimg3}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rmf2" className="form-label ">Right Middle Finger<span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rmf2" onChange={(e) => {handleImageChange13(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rmfimg3 != null ? '' : '3rem',width:"20%" }}> {rmfimg3 != null ? <img src={`data:image/png;base64,${rmfimg3}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      </div>
    </div>

  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="lmf2" className="form-label ">Left Middle Finger <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lmf2" onChange={(e) => {handleImageChange18(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lmfimg3 != null ? '' : '3rem',width:"20%" }}> {lmfimg3 != null ? <img src={`data:image/png;base64,${lmfimg3}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
        
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rrf2" className="form-label ">Right Ring Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rrf2" onChange={(e) => {handleImageChange14(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rrfimg3 != null ? '' : '3rem',width:"20%" }}> {rrfimg3 != null ? <img src={`data:image/png;base64,${rrfimg3}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
       
      </div>
    </div>

  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="lrf2" className="form-label ">Left Ring Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lrf2" onChange={(e) => {handleImageChange19(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lrfimg3 != null ? '' : '3rem',width:"20%" }}> {lrfimg3 != null ? <img src={`data:image/png;base64,${lrfimg3}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
        
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rlf2" className="form-label ">Right Little Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rlf2" onChange={(e) => {handleImageChange15(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rlfimg3 != null ? '' : '3rem',width:"20%" }}> {rlfimg3 != null ? <img src={`data:image/png;base64,${rlfimg3}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
     
      </div>
    </div>
  </div>


 
 
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="llf2" className="form-label ">Left Little Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="llf2" onChange={(e) => {handleImageChange20(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: llfimg3 != null ? '' : '3rem',width:"20%" }}> {llfimg3 != null ? <img src={`data:image/png;base64,${llfimg3}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      
      </div>
    </div>
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>


          <div className="modal fade" id="exampleModal34910" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>


  
  <div className="col-12 px-2 pt-3">
  <img src={Form_60_image == null ? img33 : Form_60_image} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>


          <div className="modal fade" id="exampleModal34" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>


  
  <div className="col-12 px-2 pt-3">
  <img src={aadhaarImage == null ? img3 : aadhaarImage} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>




          <div className="modal fade" id="exampleModal345" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>


  
  <div className="col-12 px-2 pt-3">
  <img src={modifiedImagenumbertwo == null ? img3 : modifiedImagenumbertwo} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>


          <div className="modal fade" id="exampleModal35" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>


  
  <div className="col-12 px-2 pt-3">
  <img src={documentimagebacktwo == null ? img3 : documentimagebacktwo} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>

          <div className="modal fade" id="exampleModal355" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>


  
  <div className="col-12 px-2 pt-3">
  <img src={documentimageback2 == null ? img3 : documentimageback2} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>
    </div>
  )
}

export default Customerdetail