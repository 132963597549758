import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { gettoken } from "../Localstorage/Store";

const Breadcupdash = ({ name }) => {
  const dateone = "2023-09-23";
  const datetwo = "2023-11-29";
  const gettokinval = gettoken();
  const [Data,setData] = useState({})
  useEffect(() => {
    async function fetchData() {
        // setsrtloader(true)
        const config = {
            headers: {
          Authorization: `Bearer ${gettokinval.access}`,
        },
    };
    // https://backend.kycfirst.io/api/subscriptions/
    let url = `${process.env.REACT_APP_API_URL}api/subscriptions/`;
      const response = await axios.get(url, config);
      setData(response.data[0]);
      // setsrtloader(false)
    }
    fetchData();
    // console.log("dashboard bres",Data)
  },[]);
  
  return (
    <div
      className="dashboard"
      style={{ width: "100%" }}
    >
      <div className="row d-flex justify-content-between" style={{padding:'0px 14px'}}>
        <div className="col-6"><p style={{fontWeight:'700', fontSize:'22px',margin:'0px'}}>Dashboard</p></div>
        <div className="col-6 d-flex justify-content-end">
          {Data?.plan_name ? <div className="newdata d-flex" style={{gap:'15px'}}>

            {/* latest code start here  */}

            
           <p style={{fontWeight:'500',fontSize:"14px"}}>Plan Name : <span style={{color:'#CD7F32',fontWeight:"600"}}> {Data?.plan_name}</span></p>
           <p style={{fontWeight:'500',fontSize:"14px"}}>Expired In : <span style={{color:"#0C5398",fontWeight:'600'}}> {Data?.remaining_days} Days</span></p>
          </div> : ''}
        <div className="d-flex justify-content-end" style={{gap:"11px"}}>
        <div className="_width_57">
          {/* <select
            name=""
            id=""
            className="form-select"
            aria-label="Default select example"
          >
            <option value="ytrewq">Western Union</option>
            <option value="qwerty">qwerty</option>
            <option value="zxcvb">zxcvb</option>
            <option value="bvcxz">bvcxz</option>
          </select> */}
        </div>
        <div className="_width_20 addnew">
          {/* <button className="btn text-white customcolor2">
            <NavLink
              to="/addproduct"
              style={{ textDecoration: "none", color: "white" }}
            >
              {" "}
              Apply Filter
            </NavLink>
          </button> */}
        </div>
      </div>
        </div>
        {/* <div className="col-12 d-flex" style={{gap:'5px'}}><input type="text" readOnly value={Data.subscription_date} name="form-control" style={{outline:'none',background:"#EEEEEE",border:"1px solid #737373",width:'100px'}}  /><input type="text" name="form-control" style={{outline:'none',background:"#EEEEEE",border:"1px solid #737373",width:'100px'}} value={datetwo} readOnly  /></div> */}

      </div>
    </div>
  );
};

export default Breadcupdash;
