import React, { useEffect, useRef, useState } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { gettoken } from "../../Localstorage/Store";
import { Switch } from '@mui/material';
import axios from "axios";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Loadercomp from "../../components/Loadercomp";


const Editsubscription = () => {


  const nvg = useNavigate()
  const {id} = useParams()
 

  const [custresponse, setcustresponse] = useState({});
  const [errorclass, seterrorclass] = useState(false);
  const [grandloading, setgrandloading] = useState(false);

  const gettokinval = gettoken();

  const [loading, setloading] = useState(false);
  const [srtloader, setsrtloader] = useState(true);


  const [status, setstatus] = useState(false)


  const [errorcls, seterrorcls] = useState({});
  


  const [planname, setplanname] = useState();
  const [duration, setduration] = useState();
  const [discount, setdiscount] = useState();
  const [price, setprice] = useState();
  const [gst, setgst] = useState();
  const [description, setdescription] = useState();

const [otploading, setotploading] = useState(false);










// fetch user details ----->

let gettokenvalue = gettoken()
  useEffect(() => {
    if (gettokenvalue.Checker == false && gettokenvalue.Approver === false && gettokinval.superuser === false) {
      nvg('/Customerlist/0');
    }else{
   
    }
  })



  useEffect(() => {
    async function fetchOne() {

      const config = {
        headers: {
          Authorization: `Bearer ${gettokinval.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}api/service-plans/${id}/`;
      const response = await axios.get(url, config);
      setsrtloader(false)
      
      setdescription(response.data.sub_desc)
      setgst(response.data.gst_rate)
      setprice(response.data.price)
      setdiscount(response.data.discount)
      setduration(response.data.duration_days)
      setplanname(response.data.sub_name)
      setstatus(response.data.is_active)

      // setstatus(response.data.status)
    }
    fetchOne();
  }, []);
// fetch user details ----->


  async function submitform(e) {
    e.preventDefault();
      
          setloading(true)
          const formdata = new FormData();
          formdata.append("sub_name", planname);
    formdata.append("duration_days", duration);
    formdata.append("discount", discount);
    formdata.append("price", price);
    formdata.append("gst_rate", gst);
    formdata.append("sub_desc", description);
    formdata.append("sac_code", 12345);
     
      formdata.append('is_active',status)
  
      
      
      
     
         
          setgrandloading(true)
          try {
      
            const config = {
              headers: {
                Authorization: `Bearer ${gettokinval.access}`,
              },
            };
            let url = `${process.env.REACT_APP_API_URL}api/service-plans/${id}/`;
            const response = await axios.patch(url, formdata, config);
              setcustresponse(response);
              seterrorclass(false)
              setloading(false)
              setgrandloading(false)
          } catch (error) {
            setcustresponse({statusone:1,message:'Something Went Wrong Try again'})
            seterrorcls(error.response.data)
            seterrorclass(true)
            setloading(false)
            setgrandloading(false)
      
          }
          
          
          
        
    
    
  
  
  }



  return (
    <div style={{ width: "100%" }}>
      {/* <Header /> */}
      <div className="dashboardcontent px-2">
        <div className="container-fuild px-2 desgin1">
          <div className="row bg-white ">
            <div className="col-lg-12 d-flex justify-content-between py-2" style={{ background: 'hsla(210, 85%, 32%, 0.2)', color: '#0C5398' }}>
              <p className="m-0 customfont" >Edit Customer</p>
              <div className="addnew d-block mb-2">
                <button className="btn text-white closebtn"><NavLink to='/subscriptionlist/0' style={{ textDecoration: 'none', color: 'white' }}> x Close</NavLink></button>
              </div>
            </div>
          </div>
          <div
        className="col-12 py-1 px-2 d-flex justify-content-center" 
      >
        {otploading == true ? <Loadercomp size={70} /> : ''}
      
      </div>
    
        </div>
        {srtloader == true ? <div className="container-fuild bg-white"> <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"92%"}}>
        <div className='px-2'> <Loadercomp size={100} /></div>
        </div></div> : <div className="container-fuild pb-4 pt-3 px-2 bg-white">
          <form onSubmit={submitform}>
            <div
              className="row bg-white pb-4 round"
              style={{
                border: "1px solid #E0E0E0",
                margin: "10px 0px",
                borderRadius: "3px",
                position:'relative'
              }}
            >
                 <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
                  <div className="row">
                    <div className="col-lg-3">
                      <label htmlFor="" className="form-label">
                        Plan Name <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <input
                        type="text"
                        className="form-control"
                        value={planname}
                        placeholder="Plan Name"
                        onChange={(e) => {
                          setplanname(e.target.value);
                        }}
                        required
                      />
                      {errorcls ? (
                        errorcls.fname ? (
                          <span style={{ color: "red" }}>
                            {" "}
                            {errorcls.fname[0]}{" "}
                          </span>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
                  <div className="row">
                    <div className="col-lg-3">
                      <label htmlFor="" className="form-label">
                        Duration <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Duration"
                        value={duration}
                        onChange={(e) => {
                          setduration(e.target.value);
                        }}
                      />
                      {errorcls ? (
                        errorcls.lname ? (
                          <span style={{ color: "red" }}>
                            {" "}
                            {errorcls.lname[0]}{" "}
                          </span>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
                  <div className="row">
                    <div className="col-lg-3">
                      <label htmlFor="" className="form-label customw">
                        <span>Discount</span>{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Discount"
                        value={discount}
                        onChange={(e) => {
                          setdiscount(e.target.value);
                        }}
                        required
                      />
                      {errorcls ? (
                        errorcls.fathersFullNm ? (
                          <span style={{ color: "red" }}>
                            {" "}
                            {errorcls.fathersFullNm[0]}{" "}
                          </span>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
                  <div className="row">
                    <div className="col-lg-3">
                      <label htmlFor="" className="form-label">
                        Price <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <input
                        type="number"
                        className="form-control"
                        value={price}
                        placeholder="Price"
                        onChange={(e) => {
                          setprice(e.target.value);
                        }}
                        required
                      />
                      {errorcls ? (
                        errorcls.address ? (
                          <span style={{ color: "red" }}>
                            {" "}
                            {errorcls.address[0]}{" "}
                          </span>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
                  <div className="row">
                    <div className="col-lg-3">
                      <label htmlFor="" className="form-label">
                        Gst <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <input
                        type="number"
                        className="form-control"
                        value={gst}
                        placeholder="Gst"
                        onChange={(e) => {
                          setgst(e.target.value);
                        }}
                        required
                      />
                      {errorcls ? (
                        errorcls.address ? (
                          <span style={{ color: "red" }}>
                            {" "}
                            {errorcls.address[0]}{" "}
                          </span>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 px-2" style={{ paddingTop: "28px" }}>
                  <div className="row">
                    <div className="col-lg-3">
                      <label htmlFor="" className="form-label">
                        Description <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9">
                      <input
                        type="text"
                        className="form-control"
                        value={description}
                        placeholder="Description"
                        onChange={(e) => {
                          setdescription(e.target.value);
                        }}
                        required
                      />
                      {errorcls ? (
                        errorcls.address ? (
                          <span style={{ color: "red" }}>
                            {" "}
                            {errorcls.address[0]}{" "}
                          </span>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
          























    
              {gettokinval.superuser == true ? <div className="col-md-6 px-3 pt-md-5 pt-4">
          <div className="row">
            <div className="col-lg-3">
              <label htmlFor="" className="form-label">Status <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-9">
            {/* <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" /> */}
            {status == true ? <Switch 

             value={status} name='status' onChange={(e) => {setstatus(!status)}} checked /> : <Switch

             value={status} name='status' onChange={(e) => {setstatus(!status)}} />}
            </div>
          </div>
        </div> : ''}
                     
             
              <div className="col-12">
    <div className="row">
   



 
 
 
 
 
 
 
 
       <div
        className="col-12 py-2 px-4 d-flex justify-content-end"
        style={{ gap: "4px" }}
      >
        
        <button className="btn4">Cancel</button>
        <button className="btn5">Save</button>
      </div>
    </div>
  </div>
              {custresponse.status === 200 ? nvg("/subscriptionlist/2") : <div></div>}
            </div>
           
          </form>
        </div> }
      </div>
    </div>
  );
};

export default Editsubscription;
