import React, { useEffect } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import img3 from "../../assets/KYC Logo white-bg 2.jpg";
import { FaShoppingCart } from "react-icons/fa";
import { ImLocation2 } from "react-icons/im";
import {
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import img2 from "../../assets/wallet.png";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { IoIosWallet } from "react-icons/io";
import { BsFillTelephoneFill } from "react-icons/bs";
import PhoneInput from "react-phone-number-input";
import Select from "react-select";
import { useState } from "react";
import csc from "country-state-city";
import { NavLink, useNavigate } from "react-router-dom";
import { BsQuestionLg } from "react-icons/bs";
import { useContext } from "react";
import Balance from "../../context/Balance";
import { gettoken } from "../../Localstorage/Store";
import axios from "axios";
import Loadercomp from "../../components/Loadercomp";
import Renewplan from "./Renewplan";

const options = {
    items: 3,
    loop: true,
    autoplay: true,
    nav: false,
    // responsiveClass: true,
    dots: false,
    // margin: 16,
    responsive: {
      1200: {
        items: 3,
      },
      920: {
        items: 3,
      },
      700: {
        items: 2,
        // stagePadding: 50,
      },
      600: {
        items: 2,
        // stagePadding: 20,
      },
      504: {
        items: 2,
        // stagePadding: 0,
      },
      300: {
        items: 1,
      },
      310: {
        items: 1,
      },
    },
    center: true, // Enable center mode
  };
const Upgradeplan = () => {
  const [agentmobile, setagentmobile] = useState();
  const [agentcountry, setagentcountry] = useState(null);
  const [agentstate, setagentstate] = useState(null);
  const [srtloader, setsrtloader] = useState(true);
  const [planinfo, setplaninfo] = useState([]);
  const [Data,setData] = useState({})
  const [wallet, setwallet] = useState(0);
  const gettokinval = gettoken();
  const countries = csc.getAllCountries();
  const [balno,setbalno] = useContext(Balance)
  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.id,
    ...country,
  }));
  const updatedStates = (countryId) =>
    csc
      .getStatesOfCountry(countryId)
      .map((state) => ({ label: state.name, value: state.id, ...state }));
  const submitform = () => {};

  const nvg = useNavigate();
  const navigatefeature = (num) => {
    nvg("/", { state: { id: num } });
  };

useEffect(()=>{
  if(gettokinval.superuser == false && gettokinval.staff == false ){
    nvg("/dashboard")
  }
},[])




  useEffect(() => {

    async function getsubscription() {
        // setsrtloader(true)
        const config = {
            headers: {
          Authorization: `Bearer ${gettokinval.access}`,
        },
    };
    // https://backend.kycfirst.io/api/subscriptions/
    let url = `${process.env.REACT_APP_API_URL}api/subscriptions/`;
      const response = await axios.get(url, config);
      setData(response.data[0]);
      // setsrtloader(false)
    }
    

    async function fetchData() {
      const config = {
        headers: {
          Authorization: `Bearer ${gettokinval.access}`,
          'Content-Type': 'application/json',
        },
      };
    let url = `${process.env.REACT_APP_API_URL}api/service-plans/`;
      try {
        const response = await axios.get(url, config);
        setplaninfo(response.data);
        setsrtloader(false)
  // console.log(response)
  
      } catch (error) {
        // setloading(false)
        // console.log(error)
      }
    }
    fetchData();
    getsubscription();
  }, []);
  console.log("data ddd cccc",Data)
  return (
    <div style={{ margin: "0px", padding: "0px" }}>
      <section className="superpadding">
        <div className="container-fuild pb-4 pt-3 px-2 bg-white">
          <form autoComplete="off" onSubmit={submitform}>
            <div
              className="row bg-white px-3 round"
              style={{
                border: "1px solid #E0E0E0",
                margin: "10px 0px",
                borderRadius: "3px",
                position: "relative",
                paddingBottom:srtloader == false ? "70px" : '100px'
              }}
           
            >
    
              <div className="col-md-12 px-2 pt-2">
                <div className="row">
                  {/* <div className="col-lg-3">
                    <label htmlFor="" className="form-label"></label>
                  </div> */}
                  <div className="col-lg-7 col-6 d-flex justify-content-start">
                    <span>
                      {" "}
                      {/* <IoIosWallet fontSize={56} color="rgba(12, 83, 152, 1)" /> */}
                      <img src={img2} alt="404" style={{width:'48px',paddingRight:'4px'}} />
                    </span>
                    <span
                      style={{
                        fontWeight: "700",
                        fontSize: "23px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      ₹ {balno}
                    </span>
                  </div>


                  <div className="col-lg-5 col-6 d-flex justify-content-end align-items-center">
                    <NavLink to='/walletrecharge/1'>
                  <button type="submit" className="btn5withoutradius" style={{height: "62%",
    background: "#0c5398"}}>
                 Wallet Recharge
                </button>
                </NavLink>
                    <NavLink to='/subscriptionplan'>
                  <button type="submit" className="btn5withoutradius" style={{height: "62%",marginLeft:"5px",
    background: "#0c5398"}}>
                 Back
                </button>
                </NavLink>
                  </div>
                </div>
              </div>

              <div className="col-md-12 px-2 pt-4">
                <div className="row">
                  <div className="col-12">
                    <label
                      htmlFor=""
                      className="form-label"
                      style={{
                        color: "rgba(12, 83, 152, 1)",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                    >
                      {gettokinval.license_key !== null ? gettokinval.remaining_days > 0 ? "Upgrade Subcription plans" : "Upgrade Subcription plans" : "Upgrade Subcription plans" }
                      
                    </label>
                  </div>
                  <div
                    className="col-12 outerunderline"
                    style={{
                      position: "relative",
                      width: "98%",
                      height: "3px",
                      background: "grey",
                      margin: "0 auto",
                    }}
                  >
                    <div className="specialinderline0002"></div>
                  </div>
                </div>
              </div>

              <section>
        {/* <div className="col-12 py-3 pb-3 mt-4">
          <p className="headline text-center " style={{padding:'0px',margin:'0px'}} >
            Subscription plans
          </p>
        </div> */}
        {gettokinval.license_key !== null ? gettokinval.remaining_days > 0 ? <div className="container-fluid py-2 mt-5" >
          {srtloader == false  ? 
          <div className="row" >
              { planinfo.map((item,index) => (
          <div className="col-xl-3 col-lg-4 col-md-6 p-0 mb-5">
            <NavLink to={`/Cart/${item.sub_id}`}>
              <div className="owlwidth">
                <div className="single-pricing position-relative">
                {item.discount !== "0.00" ? <span class="position-absolute top-0 start-100 p-2 border-light rounded-circle" style={{background:'#FFDD85',transform:"translate(-73%,-50%)",boxShadow: "5px 3px 7px 0 rgba(0, 0, 0, 0.6)"}}>
    <span class="d-flex" style={{padding : parseInt(item.discount) > 9 ? '' : '0px 4px'}}><span style={{color:'red',fontSize:'31px',fontWeight:'700px'}}>{Math.floor(item.discount)}</span><span className="d-flex justify-content-center align-items-center" style={{color:'red',fontSize:'9px',fontWeight:'400px'}}>% <br /> OFF</span></span>
  </span> : ''}
                {/* {item.discount !== "0.00" ? <div className="d-flex justify-content-center">
                      <div className="discount">SAVE {item.discount}%</div>
                  </div> : '' } */}
                
                  <div className="head-text" style={{paddingBottom:parseInt(item.discount) == 0 ? '20px' : ''}} >
                      <p style={{marginBottom:'0px'}}> <span className="subcarttitle" style={{background:item.sub_name == 'BRONZE' ? '#CD7F32' : item.sub_name == 'SILVER' ? '#A8A8A8' : item.sub_name == 'GOLD' ? '#CBA135' : `linear-gradient(hsla(0, 0%, 35%, 1),hsla(0, 12%, 63%, 1),hsla(0, 0%, 71%, 1),hsla(0, 4%, 47%, 1),hsla(0, 0%, 100%, 1))`}}>{item.sub_name}</span></p>
                  </div>
                  {/* <div className="price-area">
                  ₹{item.price} <span className="duration">{item.sub_desc}</span>
                  </div> */}
                  <div className="price-area" style={{display:parseInt(item.discount) == 2 ? 'none' : '',color:parseInt(item.discount) == 0 ? 'transparent' : ''}}>
                   <span className="duration" style={{display:parseInt(item.discount) == 2 ? 'none' : '',color:parseInt(item.discount) == 0 ? 'transparent' : '',textDecoration:parseInt(item.discount) == 0 ? 'none' : ''}}> Rs.{parseInt(item.price)}</span> +GST
                  </div>
                  <p style={{display:parseInt(item.discount) == 2 ? 'none' : '',color:parseInt(item.discount) == 0 ? 'transparent' : 'red',marginBottom:'0px',textAlign:'center'}}>Now at Just</p>
                  <div className="price-area p-0" style={{display:'flex',flexWrap:'wrap'}}>
                    <span className="normaltxt">Rs.</span>
                   <span className="duration23"> {item.discounted_price}</span> <span className="normaltxt"> +GST</span>
                  </div>
                  <p style={{color:'white',marginBottom:'0px',textAlign:'center',fontSize:'30px'}}>{item.sub_desc}</p>
                  {/* <div className="btn-area">
                    <NavLink to={`/Cart/${item.sub_id}`}>Get Started</NavLink>
                  </div> */}
                </div>
              </div></NavLink>
</div>
              ))}
</div> : <div
              className="col-12 d-flex justify-content-center"
              style={{ gap: "4px", position: "absolute", width: "86%" }}
            >
              <div className="px-2">
                {" "}
                <Loadercomp size={100} />
              </div>
            </div>}
          
            

            {/* <div className="col-lg-3 col-md-6">
        <div className="single-pricing best-deal">
          <div className="label-area">
            <span>Popular</span>
          </div>
          <div className="head-text">
            <h3>Standard</h3>
          </div>
          <div className="price-area">
            $45 <span className="duration">Per Month</span>
          </div>
    
          <div className="btn-area">
            <a href="#">Choose Plan</a>
          </div>
        </div>
      </div> */}
        </div> : <div className="container-fluid py-2 mt-5" >
          {srtloader == false  ? 
          <div className="row" >
              { planinfo.map((item,index) => (
                item.sub_name == Data.plan_name ? "" :
          <div className="col-xl-3 col-lg-4 col-md-6 p-0 mb-5">
            <NavLink to={`/upgradeplanform/${item.sub_id}`}>
              <div className="owlwidth">
                <div className="single-pricing position-relative">
                {item.discount !== "0.00" ? <span class="position-absolute top-0 start-100 p-2 border-light rounded-circle" style={{background:'#FFDD85',transform:"translate(-73%,-50%)",boxShadow: "5px 3px 7px 0 rgba(0, 0, 0, 0.6)"}}>
    <span class="d-flex" style={{padding : parseInt(item.discount) > 9 ? '' : '0px 4px'}}><span style={{color:'red',fontSize:'31px',fontWeight:'700px'}}>{Math.floor(item.discount)}</span><span className="d-flex justify-content-center align-items-center" style={{color:'red',fontSize:'9px',fontWeight:'400px'}}>% <br /> OFF</span></span>
  </span> : ''}
                {/* {item.discount !== "0.00" ? <div className="d-flex justify-content-center">
                      <div className="discount">SAVE {item.discount}%</div>
                  </div> : '' } */}
                
                  <div className="head-text" style={{paddingBottom:parseInt(item.discount) == 0 ? '20px' : ''}} >
                      <p style={{marginBottom:'0px'}}> <span className="subcarttitle" style={{background:item.sub_name == 'BRONZE' ? '#CD7F32' : item.sub_name == 'SILVER' ? '#A8A8A8' : item.sub_name == 'GOLD' ? '#CBA135' : `linear-gradient(hsla(0, 0%, 35%, 1),hsla(0, 12%, 63%, 1),hsla(0, 0%, 71%, 1),hsla(0, 4%, 47%, 1),hsla(0, 0%, 100%, 1))`}}>{item.sub_name}</span></p>
                  </div>
                  {/* <div className="price-area">
                  ₹{item.price} <span className="duration">{item.sub_desc}</span>
                  </div> */}
                  <div className="price-area" style={{display:parseInt(item.discount) == 2 ? 'none' : '',color:parseInt(item.discount) == 0 ? 'transparent' : ''}}>
                   <span className="duration" style={{display:parseInt(item.discount) == 2 ? 'none' : '',color:parseInt(item.discount) == 0 ? 'transparent' : '',textDecoration:parseInt(item.discount) == 0 ? 'none' : ''}}> Rs.{parseInt(item.price)}</span> +GST
                  </div>
                  <p style={{display:parseInt(item.discount) == 2 ? 'none' : '',color:parseInt(item.discount) == 0 ? 'transparent' : 'red',marginBottom:'0px',textAlign:'center'}}>Now at Just</p>
                  <div className="price-area p-0" style={{display:'flex',flexWrap:'wrap'}}>
                    <span className="normaltxt">Rs.</span>
                   <span className="duration23"> {item.discounted_price}</span> <span className="normaltxt"> +GST</span>
                  </div>
                  <p style={{color:'white',marginBottom:'0px',textAlign:'center',fontSize:'30px'}}>{item.sub_desc}</p>
                  {/* <div className="btn-area">
                    <NavLink to={`/Cart/${item.sub_id}`}>Get Started</NavLink>
                  </div> */}
                </div>
              </div></NavLink>
</div>
              ))}
</div> : <div
              className="col-12 d-flex justify-content-center"
              style={{ gap: "4px", position: "absolute", width: "86%" }}
            >
              <div className="px-2">
                {" "}
                <Loadercomp size={100} />
              </div>
            </div>}
          
            
        </div> : <div className="container-fluid py-2 mt-5" >
          {srtloader == false  ? 
          <div className="row" >
              { planinfo.map((item,index) => (
          <div className="col-xl-3 col-lg-4 col-md-6 p-0 mb-5">
            <NavLink to={`/Cart/${item.sub_id}`}>
              <div className="owlwidth">
                <div className="single-pricing position-relative">
                {item.discount !== "0.00" ? <span class="position-absolute top-0 start-100 p-2 border-light rounded-circle" style={{background:'#FFDD85',transform:"translate(-73%,-50%)",boxShadow: "5px 3px 7px 0 rgba(0, 0, 0, 0.6)"}}>
    <span class="d-flex" style={{padding : parseInt(item.discount) > 9 ? '' : '0px 4px'}}><span style={{color:'red',fontSize:'31px',fontWeight:'700px'}}>{Math.floor(item.discount)}</span><span className="d-flex justify-content-center align-items-center" style={{color:'red',fontSize:'9px',fontWeight:'400px'}}>% <br /> OFF</span></span>
  </span> : ''}
                {/* {item.discount !== "0.00" ? <div className="d-flex justify-content-center">
                      <div className="discount">SAVE {item.discount}%</div>
                  </div> : '' } */}
                
                  <div className="head-text" style={{paddingBottom:parseInt(item.discount) == 0 ? '20px' : ''}} >
                      <p style={{marginBottom:'0px'}}> <span className="subcarttitle" style={{background:item.sub_name == 'BRONZE' ? '#CD7F32' : item.sub_name == 'SILVER' ? '#A8A8A8' : item.sub_name == 'GOLD' ? '#CBA135' : `linear-gradient(hsla(0, 0%, 35%, 1),hsla(0, 12%, 63%, 1),hsla(0, 0%, 71%, 1),hsla(0, 4%, 47%, 1),hsla(0, 0%, 100%, 1))`}}>{item.sub_name}</span></p>
                  </div>
                  {/* <div className="price-area">
                  ₹{item.price} <span className="duration">{item.sub_desc}</span>
                  </div> */}
                  <div className="price-area" style={{display:parseInt(item.discount) == 2 ? 'none' : '',color:parseInt(item.discount) == 0 ? 'transparent' : ''}}>
                   <span className="duration" style={{display:parseInt(item.discount) == 2 ? 'none' : '',color:parseInt(item.discount) == 0 ? 'transparent' : '',textDecoration:parseInt(item.discount) == 0 ? 'none' : ''}}> Rs.{parseInt(item.price)}</span> +GST
                  </div>
                  <p style={{display:parseInt(item.discount) == 2 ? 'none' : '',color:parseInt(item.discount) == 0 ? 'transparent' : 'red',marginBottom:'0px',textAlign:'center'}}>Now at Just</p>
                  <div className="price-area p-0" style={{display:'flex',flexWrap:'wrap'}}>
                    <span className="normaltxt">Rs.</span>
                   <span className="duration23"> {item.discounted_price}</span> <span className="normaltxt"> +GST</span>
                  </div>
                  <p style={{color:'white',marginBottom:'0px',textAlign:'center',fontSize:'30px'}}>{item.sub_desc}</p>
                  {/* <div className="btn-area">
                    <NavLink to={`/Cart/${item.sub_id}`}>Get Started</NavLink>
                  </div> */}
                </div>
              </div></NavLink>
</div>
              ))}
</div> : <div
              className="col-12 d-flex justify-content-center"
              style={{ gap: "4px", position: "absolute", width: "86%" }}
            >
              <div className="px-2">
                {" "}
                <Loadercomp size={100} />
              </div>
            </div>}
          
            

            {/* <div className="col-lg-3 col-md-6">
        <div className="single-pricing best-deal">
          <div className="label-area">
            <span>Popular</span>
          </div>
          <div className="head-text">
            <h3>Standard</h3>
          </div>
          <div className="price-area">
            $45 <span className="duration">Per Month</span>
          </div>
    
          <div className="btn-area">
            <a href="#">Choose Plan</a>
          </div>
        </div>
      </div> */}
        </div>}
        
      </section>
            </div>
          </form>
        </div>
      </section>
      <div
        className="modal fade"
        // id="exampleModal1400"
        id="exampleModal1400000"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content">
          <BsQuestionLg className='question-logo' />
            {/* <span className="question-logo" style={{display:"flex",justifyContent:"center",alignItems:'center'}} ></span> */}
            {/* <i class="fa fa-exclamation" aria-hidden="true"></i> */}
            <div className="modal-header mod-line">
            </div>
            <div className="modal-body">
              <div className="row gy-3 mt-2">
                <h1 className="ccedit-h">Wallet Error</h1>
                <p className="ccedit-p">
                  Your Wallet Balance is Lower than plan . Please Recharge Your Wallet First
                </p>
              </div>
            </div>
            <div className="modal-footer mod-line m-auto">
            {/* <button type="button" className="btn closecancel" data-bs-dismiss="modal"
                aria-label="Close">Cancel</button> */}
 <NavLink to='/walletrecharge/2'>
              <button
 
                type="button"
                className="btn closebtn text-white"
                data-bs-dismiss="modal"
                // aria-label="Close"
              >
                Wallet Recharge
              </button>
                </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Upgradeplan;
