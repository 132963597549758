import React from 'react'
import Breadcup from '../../components/Breadcup'
import Header from '../../components/Header'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import img1 from '../../assets/Group 40.png';
import img2 from '../../assets/Group 41.png';
import img3 from '../../assets/asdfg.png';
import img4 from "../../assets/abc.png";
import img5 from "../../assets/cba.png";
import checkicon from '../../assets/Ellipse 28.png';
import { BsQuestionLg } from "react-icons/bs";
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Loadercomp from '../../components/Loadercomp';
import { useEffect } from 'react';
import { useState } from 'react';
import { gettoken } from '../../Localstorage/Store';
import axios from 'axios';
import ReactImageMagnify from 'react-image-magnify';
import { getCode, getName } from 'country-list';
import { MatchFinger } from '../mfs100';
const Ceditagent = () => {

    const nvg = useNavigate()
    const [errorcls, seterrorcls] = useState({});
    const [loader, setloader] = useState(false);
    const [agentusername,setagentusername] = useState()
    const [agentname,setagentname] = useState()
    const [agentlast,setagentlast] = useState()
    const [agentemail,setagentemail] = useState()
    const [agentgender,setagentgender] = useState()
    const [agentage,setagentage] = useState()
    const [agentmobile,setagentmobile] = useState()
    const [agentchecker,setagentchecker] = useState(false)
    const [agentmaker,setagentmaker] = useState(false)
    const [documenterror, setdocumenterror] = useState(false);
    const [agentapprover,setagentapprover] = useState(false)
    const [agentcountry,setagentcountry] = useState()
    const [agentstate,setagentstate] = useState()
    const [agentcity,setagentcity] = useState()
    const [agentaddress,setagentaddress] = useState()
    const [agentpincode,setagentpincode] = useState()
    const [ffn,setffn] = useState()
    const [agentsuperadmin,setagentsuperadmin] = useState()
    const[defaultv,setdefaultv] = useState('')
    const[defaultvtwo,setdefaultvtwo] = useState('')
    const [aadhaarImage, setaadhaarImage] = useState('');
    const [documentimagebacktwo, setdocumentimagebacktwo] = useState('');

    const [usernamecheck, setusernamecheck] = useState(false);
    const [fnamecheck, setfnamecheck] = useState(false);
    const [lnamecheck, setlnamecheck] = useState(false);
    const [photographcheck, setphotographcheck] = useState(false);
    const [aadhar, setaadhar] = useState(false);
    const [biomcheck, setbiomcheck] = useState(false);
    const [mobile_nocheck, setmobile_nocheck] = useState(false);
    const [gendercheck, setgendercheck] = useState(false);
    const [emailIDcheck, setemailIDcheck] = useState(false);
    const [companycheck, setcompanycheck] = useState(false);
    const [locationcheck, setlocationcheck] = useState(false);
    const [addresscheck, setaddresscheck] = useState(false);
    const [countrycheck, setcountrycheck] = useState(false);
    const [statecheck, setstatecheck] = useState(false);
    const [citycheck, setcitycheck] = useState(false);
    const [dobcheck, setdobcheck] = useState(false);
    const [rolecheck, setrolecheck] = useState(false);
    const [documcheck, setdocumcheck] = useState(false);
    const [pincodecheck, setpincodecheck] = useState(false);
    // const [dobcheck, setdobcheck] = useState(false);
    const [srtloader, setsrtloader] = useState(true);
    const gettokinval = gettoken();
    const [photograph, setphotograph] = useState("");
    const [documenttype, setdocumenttype] = useState('PAN');
    const [idnumber, setidnumber] = useState('DTTPA8683J');
    const [documentimage, setdocumentimage] = useState('ASD');
    const [documentissue, setdocumentissue] = useState('SD');
    const [documentexpiry, setdocumentexpiry] = useState('ASD');

    const [documenterrorres, setdocumenterrorres] = useState(false);
    const [documentloader, setdocumentloader] = useState(false);
    const [documentgrandres, setdocumentgrandres] = useState({});
    const [dob, setdob] = useState();
    const [documentgranderror, setdocumentgranderror] = useState({});

    const [crtdate, setcrtdate] = useState('');
    const [documentsuperres, setdocumentsuperres] = useState('');
    const [documentsuperresverify, setdocumentsuperresverify] = useState('');
    const [documentsuperresseond, setdocumentsuperresseond] = useState('');
    const [documentsupermsg, setdocumentsupermsg] = useState('');
    const [kycphoto, setkycphoto] = useState('');
    const [kycfullname, setkycfullname] = useState('');
    const [kycage, setkycage] = useState('');
    const [kycfathername, setkycfathername] = useState('');
    const [kycgander, setkycgander] = useState('');
    const [kycdob, setkycdob] = useState('');
    const [kycaddress, setkycaddress] = useState('');
    const [kyccountry, setkyccountry] = useState('');
    const [kycstate, setkycstate] = useState('');
    const [kyccity, setkyccity] = useState('');
    const [kycpincode, setkycpincode] = useState('');
    const [kycemail, setkycemail] = useState('');
    const [kycphone, setkycphone] = useState('');

    const [fingerperror12, setfingerperror12] = useState(false);



    const [rigthfingerserror12, setrigthfingerserror12] = useState('');
    const [thumbimg2sand, setthumbimg2sand] = useState('');
    const [rifimg2sand, setrifimg2sand] = useState('');
    const [rmfimg2sand, setrmfimg2sand] = useState('');
    const [rrfimg2sand, setrrfimg2sand] = useState('');
    const [rlfimg2sand, setrlfimg2sand] = useState('');
    const [ltfimg2sand, setltfimg2sand] = useState('');
    const [lifimg2sand, setlifimg2sand] = useState('');
    const [lmfimg2sand, setlmfimg2sand] = useState('');
    const [lrfimg2sand, setlrfimg2sand] = useState('');
    const [llfimg2sand, setllfimg2sand] = useState('');

    // const [kycfullname, setkycfullname] = useState('ajay singh');
    // const [kycage, setkycage] = useState('34');
    // const [kycfathername, setkycfathername] = useState('mohan lal');
    // const [kycgander, setkycgander] = useState('male');
    // const [kycdob, setkycdob] = useState('23-7-2002');
    // const [kycaddress, setkycaddress] = useState('rz-58 a3-block dharampura njf');
    // const [kyccountry, setkyccountry] = useState('in');
    // const [kycstate, setkycstate] = useState('delhi');
    // const [kyccity, setkyccity] = useState('najfgarh');
    // const [kycpincode, setkycpincode] = useState('110043');
    // const [kycemail, setkycemail] = useState('azy6049@gmail.com');
    // const [kycphone, setkycphone] = useState('3939395030');
    const [kycphonecode, setkycphonecode] = useState('');
    const [idno, setidno] = useState(0);
    const {id} = useParams()
    const [thumbimg2, setthumbimg2] = useState('');
    const [rifimg2, setrifimg2] = useState('');
    const [rmfimg2, setrmfimg2] = useState('');
    const [rrfimg2, setrrfimg2] = useState('');
    const [rlfimg2, setrlfimg2] = useState('');
    const [ltfimg2, setltfimg2] = useState('');
    const [lifimg2, setlifimg2] = useState('');
    const [lmfimg2, setlmfimg2] = useState('');
    const [lrfimg2, setlrfimg2] = useState('');
    const [llfimg2, setllfimg2] = useState('');

    const [rtf3error ,setrtf3error] = useState(false)
    const [rif3error ,setrif3error] = useState(false)
    const [rmf3error ,setrmf3error] = useState(false)
    const [rrf3error ,setrrf3error] = useState(false)
    const [rlf3error ,setrlf3error] = useState(false)
    const [ltf3error ,setltf3error] = useState(false)
    const [lif3error ,setlif3error] = useState(false)
    const [lmf3error ,setlmf3error] = useState(false)
    const [lrf3error ,setlrf3error] = useState(false)
    const [llf3error ,setllf3error] = useState(false)
    const [documentsuperresuniqe, setdocumentsuperresuniqe] = useState('');
    const [documentsupermsguniqe, setdocumentsupermsguniqe] = useState('');
    const [formdatao, setformDatao] = useState({});
    const [idno2, setidno2] = useState(0);
    const [kycfullnametwo, setkycfullnametwo] = useState('');
    const [donotshowmsg, setdonotshowmsg] = useState(false);
    const [documenttypetwo, setdocumenttypetwo] = useState();
    const [idnumbertwo, setidnumbertwo] = useState();
    const [documentissuetwo, setdocumentissuetwo] = useState();
    const [documentexpirytwo, setdocumentexpirytwo] = useState();
    const [modifiedImagenumbertwo, setModifiedImagenumbertwo] = useState(null);
    const [documentimageback2, setdocumentimageback2] = useState(null);
  

    const [thumbimg5, setthumbimg5] = useState('');
    const [rifimg5, setrifimg5] = useState('');
    const [rmfimg5, setrmfimg5] = useState('');
    const [rrfimg5, setrrfimg5] = useState('');
    const [rlfimg5, setrlfimg5] = useState('');
    const [ltfimg5, setltfimg5] = useState('');
    const [lifimg5, setlifimg5] = useState('');
    const [lmfimg5, setlmfimg5] = useState('');
    const [lrfimg5, setlrfimg5] = useState('');
    const [llfimg5, setllfimg5] = useState('');

    
    const [rightnumberoffingers12, setrightnumberoffingers12] = useState(0);
    const [leftnumberoffingers12, setleftnumberoffingers12] = useState(0);

    const [ansithumbimg5, setansithumbimg5] = useState('');
    const [ansirifimg5, setansirifimg5] = useState('');
    const [ansirmfimg5, setansirmfimg5] = useState('');
    const [ansirrfimg5, setansirrfimg5] = useState('');
    const [ansirlfimg5, setansirlfimg5] = useState('');
    const [ansiltfimg5, setansiltfimg5] = useState('');
    const [ansilifimg5, setansilifimg5] = useState('');
    const [ansilmfimg5, setansilmfimg5] = useState('');
    const [ansilrfimg5, setansilrfimg5] = useState('');
    const [ansillfimg5, setansillfimg5] = useState('');
    const [nationality, setnationality] = useState();

    
  function generateUniqueRandomNumber() {
    return Math.floor(Math.random() * 10000) + Date.now();
  }
  const randomNumber = generateUniqueRandomNumber();
    // fetch agent details ----->start
    useEffect(() => {
      async function fetchData() {
        const config = {
          headers: {
            Authorization: `Bearer ${gettokinval.access}`,
          },
        };
        let url = `${process.env.REACT_APP_API_URL}api/agent/${id}/`;
        
        const response = await axios.get(url, config);
        // setdefaultv(response.data.company)
        setagentusername(response.data.username)
        setagentname(response.data.first_name)
        setagentlast(response.data.last_name)
        setagentemail(response.data.email)
        setagentmobile(response.data.mobile_no.substring(3))
        setagentaddress(response.data.address)
        setagentcountry(response.data.country)
        setagentstate(response.data.state)
        setagentcity(response.data.city)
        setagentpincode(response.data.pincode)
        setagentgender(response.data.gender)
        setdefaultv(response.data.company)
        setdefaultvtwo(response.data.location)
        setagentapprover(response.data.Approver)
        setagentmaker(response.data.Maker)
        setagentchecker(response.data.Checker)
        setphotograph(response.data.photograph)
        setdob(response.data.dob)
        setagentsuperadmin(response.data.is_superuser)
        setidnumber(response.data.document_no)
        setdocumenttype(response.data.document_type)
        setdocumentexpiry(response.data.expiry_date)
        setdocumentissue(response.data.issue_date)
        setagentage(response.data.agent_age)
        setffn(response.data.fathersFullNm)
        setllfimg2(response.data.l_little_finger)
        setlrfimg2(response.data.l_ring_finger)
        setlmfimg2(response.data.l_middle_finger)
        setlifimg2(response.data.l_index_finger)
        setltfimg2(response.data.l_thumb)
        setrlfimg2(response.data.r_little_finger)
        setrrfimg2(response.data.r_ring_finger)
        setrmfimg2(response.data.r_middle_finger)
        setrifimg2(response.data.r_index_finger)
        setthumbimg2(response.data.r_thumb)
        setdocumentimagebacktwo(response.data.docImg_back)
        setaadhaarImage(response.data.docImg_front)
        setdocumentimageback2(response.data.docImg_back2)
        setModifiedImagenumbertwo(response.data.docImg_front2)
        setdocumentexpirytwo(response.data.expiry_date2)
        setdocumentissuetwo(response.data.issue_date2)
        setidnumbertwo(response.data.document_no2)
        setdocumenttypetwo(response.data.document_type2)
        setnationality(response.data.nationality)


        if(response.data.dob !== undefined){
          const newdate = () => {
            // const num = parseInt(dateString)
             const dateParts = response.data.dob.split('-');
            //  console.log("mmmmm",dateParts)
             const year = parseInt(dateParts[0], 10);
             const month = parseInt(dateParts[1], 10);
             const day = parseInt(dateParts[2], 10);
           
             const months = [
               'January',
               'February',
               'March',
               'April',
               'May',
               'June',
               'July',
               'August',
               'September',
               'October',
               'November',
               'December',
             ];
           
             const formattedDate = `${day} ${months[month - 1]}, ${year}`;
             setcrtdate(formattedDate)
           };
           newdate();
        }
        setsrtloader(false)
   
      }
      fetchData();
    }, []);






    // const documentverify = async () => {
    //       setdocumentloader(true)
    //       const boolenvalue = true;
    //       const reason = 'using this api in kfc project'
    //       var data = {}
    //       var inputDate = new Date(dob);
    //       var options = { day: 'numeric', month: 'numeric', year: 'numeric' };
    //       var formattedDate = inputDate.toLocaleDateString('en-GB', options).replace(/\//g, '-'); 
    //       if(documenttype == "AADHAAR"){
    //         var fullname;
    //         if(agentlast != "undefined" && agentlast != ""){
    //            fullname = `${agentname} ${agentlast}`;
    //         }else{
    //           fullname = agentname;
    //         }
    //          data = {
    //           "document_type":documenttype,
    //           "aadhaar_id": parseInt(idnumber),
    //           "full_name": fullname.toUpperCase(),
    //           "dob": formattedDate,
    //           "gender": agentgender == "Male" ? "M" : "F",
    //           "consent": boolenvalue,
    //           "consent_purpose":reason
    //         }
    //       }else{
    //          data = {
    //           "document_type":documenttype,
    //           "id_number": idnumber,
    //           "dob": formattedDate,
    //           "consent": boolenvalue,
    //           "consent_purpose":reason
    //         }
    //       }
          
    //       try {
    //         const config = {
    //           headers: {
    //             accept:'application/json',
    //             Authorization: `Bearer ${gettokinval.access}`,
    //           },
    //         };
    //         let url = "httphttps://backend.kycfirst.io/api/search/";
    //         const response = await axios.post(url, data, config);
    //         var check = response.data.data;
    //         console.log("dkdkdkdkdkdkd",response)
    //         if(check){
    //           setdocumentloader(false)
    //           setdocumenterrorres(true)
    //           if(response.data.responseCode == "E00029"){
    //             setdocumentsuperres(response.data.data.kycStatus)
    //             setdocumentsupermsg('Error Code: CR0001. Please contact your Administrator')
    //           }else{
    //           setdocumentsuperres(response.data.data.kycStatus)
    //                   setdocumentsupermsg(response.data.data.message)
    //           }
    //           setidno(2)
    //           setTimeout(function() {
    //             setidno(0)
    //            }, 5000);
    //         }else{
    //           console.log(response)
    //           console.log(response.data)
    //           if(response.data.download.kycStatus == "SUCCESS"){
            
    //               setdocumentgrandres(response.data.data)
    //               setdocumentsuperres(response.data.download.kycStatus)
    //               setkycphoto(response.data.search.kycResult.photo)
    //               setkycfullname(response.data.download.kycResult.personalIdentifiableData.personalDetails.fullName)
    //               setkycfathername(response.data.download.kycResult.personalIdentifiableData.personalDetails.fatherFullname)
    //               setkycdob(response.data.download.kycResult.personalIdentifiableData.personalDetails.dob)
    //               setkycgander(response.data.download.kycResult.personalIdentifiableData.personalDetails.gender)
    //               setkycaddress(response.data.download.kycResult.personalIdentifiableData.personalDetails.corresLine1)
    //               setkyccountry(response.data.download.kycResult.personalIdentifiableData.personalDetails.corresCountry)
    //               setkycstate(response.data.download.kycResult.personalIdentifiableData.personalDetails.corresState)
    //               setkyccity(response.data.download.kycResult.personalIdentifiableData.personalDetails.corresCity)
    //               setkycpincode(response.data.download.kycResult.personalIdentifiableData.personalDetails.corresPin)
    //               setkycemail(response.data.download.kycResult.personalIdentifiableData.personalDetails.email)
    //               setkycphone(response.data.download.kycResult.personalIdentifiableData.personalDetails.mobNum)
    //               setkycphonecode(response.data.download.kycResult.personalIdentifiableData.personalDetails.mobCode)
    //               setkycage(response.data.search.kycResult.age)
    //               setidno(1)
    //               setTimeout(function() {
    //                 setidno(0)
    //                }, 5000);
    //                console.log('fatherfullname',response.data.download.kycResult.personalIdentifiableData.fatherFullname);
    //                console.log('india',response.data.download.kycResult.personalIdentifiableData.personalDetails.corresCountry);
    //               console.log("city",response.data.download.kycResult.personalIdentifiableData.personalDetails.corresCity);
    //               console.log("addrdss",response.data.download.kycResult.personalIdentifiableData.personalDetails.corresLine1);
    //               console.log("pincode",response.data.download.kycResult.personalIdentifiableData.personalDetails.corresPin);
    //               console.log("state",response.data.download.kycResult.personalIdentifiableData.personalDetails.corresState);
    //               console.log("email",response.data.download.kycResult.personalIdentifiableData.personalDetails.email);
    //               setdocumentloader(false)
    //           }else{
                
    //             // console.log("dfghkdfgggkajaykkk",response);
    //             // console.log("dfghkdfgggkajaykkk2",response.data);
    //             // console.log("dfghkdfgggkajaykkk3",response.data.kycStatus);
    //             // console.log("dfghkdfgggkajaykkk3",response.data.message);
    //              var result;
    //              if(response.data.data.kycStatus == 'FAILURE'){
    //               setdocumentsuperres(response.data.data.kycStatus)
    //                   setdocumentsupermsg(response.data.data.message)
    //                   setidno(2)
    //                   setTimeout(function() {
    //                     setidno(0)
    //                    }, 5000);
    //                   setdocumentloader(false)
      
    //              }else{
    //               //  result = JSON.parse(response.data);
    //               // setdocumentgranderror(result)
    //               // setdocumentsuperres(result.status)
    //               setdocumentsuperres(response.data.data.kycStatus)
    //                   setdocumentsupermsg(response.data.data.message)
    //               // if(result.message){
    //               //     setdocumentsupermsg(result.message)
    //               // }else{
    //               //     setdocumentsupermsg(result.data.message)
    //               // }
    //              }
    //               setidno(2)
    //               setTimeout(function() {
    //                 setidno(0)
    //                }, 5000);
    //               // console.log("dfghkdfgggkajaykkk",result.data.message);
    //               // console.log('dfghjkdfghj',documentgranderror);
    //               setdocumentloader(false)
    //           }
              
        
    //           // if(documentresponse.status === "SUCCESS"){
    //           //   setdocumentloader(false)
    //           //   setidno(1)
    //           //   setTimeout(function() {
    //           //     setidno(0)
    //           //    }, 5000); // 5000 milliseconds (5 seconds) delay
    //           //   setdocumentloader(false)
        
    //           // }
             
    //         }
    //       } catch (error) {
    //     console.log("we",error)
    //     setdocumentloader(false)
    //       }
        
        
        
        
    //     }


    const documentverify = async () => {

        if(documenttype == "defualt" || documenttype == undefined || idnumber == undefined){
          setdocumenterror(true)
        }else{
          setdocumentloader(true)
          const boolenvalue = true;
          const reason = 'using this api in kfc project'
          var data = {}
          var inputDate = new Date(dob);
          var options = { day: 'numeric', month: 'numeric', year: 'numeric' };
          var formattedDate = inputDate.toLocaleDateString('en-GB', options).replace(/\//g, '-'); 
       
             if(documenttype == "DRIVING_LICENSE"){
                  var inputDate = new Date(dob);
                  var options = { year: 'numeric' , month: 'numeric' , day: 'numeric' };
                  var formattedDate = inputDate.toLocaleDateString('en-GB', options).replace(/\//g, '-'); 
                   data = {
                    "reference_id":`REF${randomNumber}`,
                    "document_type":documenttype,
                    "id_number": idnumber,
                    "dob": formattedDate
                  }
                }else{
                  data = {
                      "reference_id":`REF${randomNumber}`,
                      // "document_type":"VOTERID",
                      "document_type":documenttype,
                      "id_number": idnumber,
                      // "id_number": "YD15593272",
                      "dob": formattedDate,
                      "consent": boolenvalue,
                      "consent_purpose":reason
                    }
                }
          
          
          try {
            const config = {
              headers: {
                accept:'application/json',
                Authorization: `Bearer ${gettokinval.access}`,
              },
            };
            let url = `https://backend.kycfirst.io/api/search/`;
            const response = await axios.post(url, data);
            // console.log("00000000000000000000000",response)
            
                if(response.data.kycStatus == "SUCCESS"){
            
                  setdocumentsuperres("SUCCESS")
                  setdocumentsuperresverify(response.data.kycStatus)
                  setdocumentsupermsg(response.data.message)
                  setkycfullname(response.data.kycResult.name)
                  setidno(1)
                  setTimeout(function() {
                    setidno(0)
                  }, 7000);
                  // setkycphoto(response.data.search.kycResult.photo)
                  // setkycfathername(response.data.download.kycResult.personalIdentifiableData.personalDetails.fatherFullname)
                  // setkycdob(response.data.download.kycResult.personalIdentifiableData.personalDetails.dob)
                  // setkycgander(response.data.download.kycResult.personalIdentifiableData.personalDetails.gender)
                  // setkycaddress(response.data.download.kycResult.personalIdentifiableData.personalDetails.corresLine1)
                  // setkyccountry(response.data.download.kycResult.personalIdentifiableData.personalDetails.corresCountry)
                  // setkycstate(response.data.download.kycResult.personalIdentifiableData.personalDetails.corresState)
                  // setkyccity(response.data.download.kycResult.personalIdentifiableData.personalDetails.corresCity)
                  // setkycpincode(response.data.download.kycResult.personalIdentifiableData.personalDetails.corresPin)
                  // setkycemail(response.data.download.kycResult.personalIdentifiableData.personalDetails.email)
                  // setkycphone(response.data.download.kycResult.personalIdentifiableData.personalDetails.mobNum)
                  // setkycphonecode(response.data.download.kycResult.personalIdentifiableData.personalDetails.mobCode)
                  // setkycage(response.data.search.kycResult.age)
                  
                  //  console.log('fatherfullname',response.data.download.kycResult.personalIdentifiableData.fatherFullname);
                  //  console.log('india',response.data.download.kycResult.personalIdentifiableData.personalDetails.corresCountry);
                  // console.log("city",response.data.download.kycResult.personalIdentifiableData.personalDetails.corresCity);
                  // console.log("addrdss",response.data.download.kycResult.personalIdentifiableData.personalDetails.corresLine1);
                  // console.log("pincode",response.data.download.kycResult.personalIdentifiableData.personalDetails.corresPin);
                  // console.log("state",response.data.download.kycResult.personalIdentifiableData.personalDetails.corresState);
                  // console.log("email",response.data.download.kycResult.personalIdentifiableData.personalDetails.email);
                  // setdocumentloader(false)
              }else{
                
                // console.log("dfghkdfgggkajaykkk",response);
                // console.log("dfghkdfgggkajaykkk2",response.data);
                // console.log("dfghkdfgggkajaykkk3",response.data.kycStatus);
                // console.log("dfghkdfgggkajaykkk3",response.data.message);
                //  var result;
                 if(response.data.kycStatus == 'FAILURE'){
                  setdocumentsuperres(response.data.kycStatus)
                      setdocumentsupermsg(response.data.error.message)
                      setidno(2)
                      setTimeout(function() {
                        setidno(0)
                       }, 7000);
                      setdocumentloader(false)
      
                 }else{
                  //  result = JSON.parse(response.data);
                  // setdocumentgranderror(result)
                  // setdocumentsuperres(result.status)
                  setdocumentsuperres(response.data.kycStatus)
                      setdocumentsupermsg(response.data.error.message)
                  // if(result.message){
                  //     setdocumentsupermsg(result.message)
                  // }else{
                  //     setdocumentsupermsg(result.data.message)
                  // }
                 }
                  setidno(2)
                  setTimeout(function() {
                    setidno(0)
                   }, 7000);
                  // console.log("dfghkdfgggkajaykkk",result.data.message);
                  // console.log('dfghjkdfghj',documentgranderror);
                  setdocumentloader(false)
              }
              // }
             
              
        
              // if(documentresponse.status === "SUCCESS"){
              //   setdocumentloader(false)
              //   setidno(1)
              //   setTimeout(function() {
              //     setidno(0)
              //    }, 5000); // 5000 milliseconds (5 seconds) delay
              //   setdocumentloader(false)
        
              // }
             
            // }
          } catch (error) {
        // console.log("we",error)
        setdocumentloader(false)
          }
        
        
        
        }
   
  
  
  
  
  
  
  
  
  
  
  
    // second document verify here
  
    if(documenttypetwo == "defualt" || documenttypetwo == undefined || idnumbertwo == undefined || idnumbertwo == ""){
      setdocumenterror(true)
    }else{


      if(documenttypetwo == "AADHAAR" || documenttypetwo == "PASSPORT"){

        try {

          // data = {
          //   "reference_id":`REF${randomNumber}`,
          //   "document_type":documenttypetwo,
          //   "id_number": idnumbertwo,
          //   // "id_number": "939393994930",
          //   "dob": formattedDate
          // }  
     if(documenttypetwo == "PASSPORT"){
      var inputDate = new Date(dob);
      var options = { day: 'numeric', month: 'numeric', year: 'numeric' };
      var formattedDate = inputDate.toLocaleDateString('en-GB', options).replace(/\//g, '/'); 
      data = {
        "document":"passport",
        "service":"Identity",
        "itemId":"653f4d6cfb1f470023468784",
        "accessToken":"n34bgabk4h75licwaay3lha04dyciiirk",
        "task":"fetch",
        "essentials":{
              "fileNumber":idnumbertwo,
              "dob":formattedDate
            }
    }
     }else{
         data =  {
            // "document":"aadhar",
            "document":"pan_aadhar_link",
            "service":"Identity",
            "itemId":"653f4d44fb1f47002346877c",
            "accessToken":"ky16sopjgqdpr2mde2w69gp9pyk3bo19",
            // "task":"verifyAadhaar",
            "task":"panAadhaarLinkStatus",
            "essentials":{
              "number":idnumber,
                  "uid":idnumbertwo
                }
        }
      }

          const config = {
            headers: {
              accept:'application/json',
              Authorization: `Bearer ${gettokinval.access}`,
            },
          };
          setdocumentloader(true)
          let url = `${process.env.REACT_APP_API_URL}api/DocumentVerification/`;
          const response = await axios.post(url, data);
var jdata = JSON.parse(response.data);
console.log(jdata)
console.log(response)
if(jdata.service == "Identity"){
  setdocumentsuperres("SUCCESS")
  setdocumentsuperresseond("SUCCESS")
        setdocumentsuperresuniqe("SUCCESS")
        setdocumentsupermsguniqe("Document Verify Successfully")
        if(jdata.response.result.name){
setkycfullnametwo(jdata.response.result.name)
        }
        setidno2(12)
        setTimeout(function() {
          setidno2(0)
         }, 7000);
        setdocumentloader(false)
}else{
  console.log("err0r")
  setdocumentsuperresuniqe("FAILURE")
      setdocumentsupermsguniqe(jdata.error.message)
          setidno2(12)
          setTimeout(function() {
            setidno2(0)
           }, 7000);
          setdocumentloader(false)
}
// console.log("sigzy response 2",response.data)
    // if(response.data.kycStatus == "SUCCESS"){
      
    //   if(documenttypetwo == "VOTERID"){
    //       setdocumentsuperres("SUCCESS")
    //       setdocumentsuperresuniqe(response.data.kycStatus)
    //       setdocumentsupermsguniqe(response.data.message)
    //       setkycfullnametwo(response.data.kycResult.name)
    //       setidno2(12)
    //       setTimeout(function() {
    //         setidno2(0)
    //        }, 7000);
          setdocumentloader(false)
    
      
    //   }else{
    //       setdocumentsuperresuniqe(response.data.kycStatus)
    //       setkycfullnametwo(response.data.kycResult.name)
    //       setidno2(12)
    //       setTimeout(function() {
    //         setidno2(0)
    //        }, 7000);
    //       setdocumentloader(false)
     
    //   }
    // }else{
    //   if(response.data.kycStatus == 'FAILURE'){
    //     setdocumentsuperresuniqe(response.data.kycStatus)
    //     setdocumentsupermsguniqe(response.data.error.message)
    //         setidno2(12)
    //         setTimeout(function() {
    //           setidno2(0)
    //          }, 7000);
    //         setdocumentloader(false)
    
    //    }else{
    //     setdocumentsuperresuniqe(response.data.kycStatus)
    //     setdocumentsupermsguniqe(response.data.error.message)
    //    }
    //     setidno2(12)
    //     setTimeout(function() {
    //       setidno2(0)
    //      }, 7000);
    //     setdocumentloader(false)
    // }
          
           
            
        } catch (error) {
      // console.log("we",error)
      setdocumentloader(false)
        }
        
      }else{
      // setdocumentloader(true)
      const boolenvalue = true;
      const reason = 'using this api in kfc project'
      var data = {}
      var inputDate = new Date(dob);
      var options = { day: 'numeric', month: 'numeric', year: 'numeric' };
      var formattedDate = inputDate.toLocaleDateString('en-GB', options).replace(/\//g, '-'); 
      if(documenttypetwo == "AADHAAR"){
        var fullname;
        if(agentlast != "undefined" && agentlast != ""){
           fullname = `${agentname} ${agentlast}`;
        }else{
          fullname = agentname;
        }
         data = {
          "reference_id":`REF${randomNumber}`,
          "document_type":documenttypetwo,
          "aadhaar_id": parseInt(idnumbertwo),
          "full_name": fullname.toUpperCase(),
          "dob": formattedDate,
          "consent": boolenvalue,
          "consent_purpose":reason
        }
      }else{
  
  
          if(documenttypetwo == "DRIVING_LICENSE"){
            // console.log("check don")
              var inputDate = new Date(dob);
              // console.log(inputDate,"wertyuioiuytrertyuhg")
              // var options = { year: 'numeric' , month: 'numeric' , day: 'numeric' };
              // var formattedDate = inputDate.toLocaleDateString('en-GB', options).replace(/\//g, '-'); 
              const year = inputDate.getFullYear();
      const month = String(inputDate.getMonth() + 1).padStart(2, '0');
      const day = String(inputDate.getDate()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
               data = {
                "reference_id":`REF${randomNumber}`,
                "document_type":documenttypetwo,
                "id_number": idnumbertwo,
                // "id_number": "939393994930",
                "dob": formattedDate
              }
            }else{
              data = {
                  "reference_id":`REF${randomNumber}`,
                  // "document_type":"VOTERID",
                  "document_type":documenttypetwo,
                  "id_number": idnumbertwo,
                  // "id_number": "YD15593272",
                  "dob": formattedDate,
                  "consent": boolenvalue,
                  "consent_purpose":reason
                }
            }
      }
      
      try {
        const config = {
          headers: {
            accept:'application/json',
            Authorization: `Bearer ${gettokinval.access}`,
          },
        };
        setdocumentloader(true)
        let url = `https://backend.kycfirst.io/api/search/`;
        const response = await axios.post(url, data);
        // var check = response.data.data;
        // if(check){
        //   setdocumenterrorres(true)
        //   if(response.data.responseCode == "E00029"){
        //     setdocumentsuperres(response.data.data.kycStatus)
        //     setdocumentsupermsg('Error Code: CR0001. Please contact your Administrator')
        //   }else{
        //   setdocumentsuperres(response.data.data.kycStatus)
        //           setdocumentsupermsg(response.data.data.message)
        //   }
        //   setidno(2)
        //   setTimeout(function() {
        //     setidno(0)
        //    }, 5000);
        // }else{
        //   console.log("line number 252 ",response)
        //   console.log("line number 252 ",response.data)
  // console.log("[[[[[[[[[[[]]]]]]]]]]]]]]]",response)
  if(response.data.kycStatus == "SUCCESS"){
    
    if(documenttypetwo == "VOTERID"){
        // setdocumentgrandres(response.data)
        setdocumentsuperres("SUCCESS")
        setdocumentsuperresseond("SUCCESS")
        setdocumentsuperresuniqe(response.data.kycStatus)
        setdocumentsupermsguniqe(response.data.message)
        setkycfullnametwo(response.data.kycResult.name)
        // setkycdob(response.data.kycResult.dateOfBirth)
        // setkycgander(response.data.kycResult.gender)
        // setkycstate(response.data.kycResult.state)
        // setkyccity(response.data.kycResult.assemblyConstituency)
        // setkycemail(response.data.kycResult.emailId)
        // setkycphone(response.data.kycResult.mobileNumber)
        // setkycage(response.data.kycResult.age)
        setidno2(12)
        setTimeout(function() {
          setidno2(0)
         }, 7000);
        setdocumentloader(false)
  
    
    }else{
        // setdocumentgrandres(response.data.data)
        setdocumentsuperresseond("SUCCESS")
        setdocumentsuperresuniqe(response.data.kycStatus)
        setkycfullnametwo(response.data.kycResult.name)
        // setkycphoto(response.data.search.kycResult.photo)
        // setkycfathername(response.data.download.kycResult.personalIdentifiableData.personalDetails.fatherFullname)
        // setkycdob(response.data.download.kycResult.personalIdentifiableData.personalDetails.dob)
        // setkycgander(response.data.download.kycResult.personalIdentifiableData.personalDetails.gender)
        // setkycaddress(response.data.download.kycResult.personalIdentifiableData.personalDetails.corresLine1)
        // setkyccountry(response.data.download.kycResult.personalIdentifiableData.personalDetails.corresCountry)
        // setkycstate(response.data.download.kycResult.personalIdentifiableData.personalDetails.corresState)
        // setkyccity(response.data.download.kycResult.personalIdentifiableData.personalDetails.corresCity)
        // setkycpincode(response.data.download.kycResult.personalIdentifiableData.personalDetails.corresPin)
        // setkycemail(response.data.download.kycResult.personalIdentifiableData.personalDetails.email)
        // setkycphone(response.data.download.kycResult.personalIdentifiableData.personalDetails.mobNum)
        // setkycphonecode(response.data.download.kycResult.personalIdentifiableData.personalDetails.mobCode)
        // setkycage(response.data.search.kycResult.age)
        setidno2(12)
        setTimeout(function() {
          setidno2(0)
         }, 7000);
        //  console.log('fatherfullname',response.data.download.kycResult.personalIdentifiableData.fatherFullname);
        //  console.log('india',response.data.download.kycResult.personalIdentifiableData.personalDetails.corresCountry);
        // console.log("city",response.data.download.kycResult.personalIdentifiableData.personalDetails.corresCity);
        // console.log("addrdss",response.data.download.kycResult.personalIdentifiableData.personalDetails.corresLine1);
        // console.log("pincode",response.data.download.kycResult.personalIdentifiableData.personalDetails.corresPin);
        // console.log("state",response.data.download.kycResult.personalIdentifiableData.personalDetails.corresState);
        // console.log("email",response.data.download.kycResult.personalIdentifiableData.personalDetails.email);
        setdocumentloader(false)
   
    }
  }else{
    if(response.data.kycStatus == 'FAILURE'){
      setdocumentsuperresuniqe(response.data.kycStatus)
      setdocumentsupermsguniqe(response.data.error.message)
          setidno2(12)
          setTimeout(function() {
            setidno2(0)
           }, 7000);
          setdocumentloader(false)
  
     }else{
      //  result = JSON.parse(response.data);
      // setdocumentgranderror(result)
      // setdocumentsuperres(result.status)
      setdocumentsuperresuniqe(response.data.kycStatus)
      setdocumentsupermsguniqe(response.data.error.message)
      // if(result.message){
      //     setdocumentsupermsg(result.message)
      // }else{
      //     setdocumentsupermsg(result.data.message)
      // }
     }
      setidno2(12)
      setTimeout(function() {
        setidno2(0)
       }, 7000);
      // console.log("dfghkdfgggkajaykkk",result.data.message);
      // console.log('dfghjkdfghj',documentgranderror);
      setdocumentloader(false)
  }
        
         
          
    
          // if(documentresponse.status === "SUCCESS"){
          //   setdocumentloader(false)
          //   setidno(1)
          //   setTimeout(function() {
          //     setidno(0)
          //    }, 5000); // 5000 milliseconds (5 seconds) delay
          //   setdocumentloader(false)
    
          // }
         
        // }
      } catch (error) {
    // console.log("we",error)
    setdocumentloader(false)
      }
    
    
    
    }
  }
  
    }









    
  const [artferror, setartferror] = useState('')
  const handleImageChange110 = () => {
    setthumbimg2sand('')
    setthumbimg5('');
    setansithumbimg5('')
    setrtf3error(true)
    setTimeout(() => {
    const client = new MatchFinger(80,5000,formdatao.ansi_r_thumb);
    if(client.data.Status){
      setthumbimg2sand(formdatao.r_thumb)
      setthumbimg5(formdatao.r_thumb);
      setansithumbimg5(formdatao.ansi_r_thumb)
      setrightnumberoffingers12(1)
          setartferror('')
    }else{
      // console.log("980090000")
      setartferror(" Not Match")
    }
    setrtf3error(false)
  }, 1000);
  };
  const [ariferror, setariferror] = useState('')
  const handleImageChange120 = () => {
    setrifimg2sand('')
    setrifimg5('');
    setansirifimg5();
    setrif3error(true)
    setTimeout(() => {
    const client = new MatchFinger(80,5000,formdatao.ansi_r_index_finger);
  if(client.data.Status){
    // console.log("3454355")
    setrifimg2sand(formdatao.r_index_finger)
    setrifimg5(formdatao.r_index_finger);
        setrightnumberoffingers12(1)
        setariferror('')
        setansirifimg5(formdatao.ansi_r_index_finger)
  }else{
    // console.log("980090000")
    setariferror(" Not Match")
  }
  setrif3error(false)
  }, 1000);
  };
  const [armferror, setarmferror] = useState('')
  const handleImageChange130 = () => {
    setrmfimg2sand('')
    setrmfimg5('');
    setrmf3error(true)
    setTimeout(() => {
    const client = new MatchFinger(80,5000,formdatao.ansi_r_middle_finger);
    if(client.data.Status){
      // console.log("3454355")
      setrmfimg2sand(formdatao.r_middle_finger)
      setrmfimg5(formdatao.r_middle_finger);
      setansirmfimg5(formdatao.ansi_r_middle_finger)
      setrightnumberoffingers12(1)
          setarmferror('')
    }else{
      // console.log("980090000")
      setarmferror(" Not Match")
    }
    setrmf3error(false)
  }, 1000);
  };
  const [arrferror, setarrferror] = useState('')
  const handleImageChange140 = () => {
    setrrfimg2sand('')
    setrrfimg5('');
    setrrf3error(true)
    setTimeout(() => {
    const client = new MatchFinger(80,5000,formdatao.ansi_r_ring_finger);
    if(client.data.Status){
      // console.log("3454355")
      setrrfimg2sand(formdatao.r_ring_finger)
      setrrfimg5(formdatao.r_ring_finger);
      setansirrfimg5(formdatao.ansi_r_ring_finger)
      setrightnumberoffingers12(1)
          setarrferror('')
    }else{
      // console.log("980090000")
      setarrferror(" Not Match")
    }
    setrrf3error(false)
  }, 1000);
  };
  
  const [arlferror, setarlferror] = useState('')
  
  const handleImageChange150 = () => {
    setrlfimg2sand('')
    setrlfimg5('');
    setrlf3error(true)
    setTimeout(() => {
    const client = new MatchFinger(80,5000,formdatao.ansi_r_little_finger);
    if(client.data.Status){
      // console.log("3454355")
      setrlfimg2sand(formdatao.r_little_finger)
      setrlfimg5(formdatao.r_little_finger);
      setansirlfimg5(formdatao.ansi_r_little_finger)
      setrightnumberoffingers12(1)
          setarlferror('')
    }else{
      // console.log("980090000")
      setarlferror(" Not Match")
    }
    setrlf3error(false)
  }, 1000);
  };
  const [altferror, setaltferror] = useState('')
  
  const handleImageChange160 = () => {
    setltfimg2sand('')
      setltfimg5('');
    setltf3error(true)
    setTimeout(() => {
    const client = new MatchFinger(80,5000,formdatao.ansi_l_thumb);
    if(client.data.Status){
      // console.log("3454355")
      setltfimg2sand(formdatao.l_thumb)
      setltfimg5(formdatao.l_thumb);
      setansiltfimg5(formdatao.ansi_l_thumb)
      setrightnumberoffingers12(1)
          setaltferror('')
    }else{
      // console.log("980090000")
      setaltferror(" Not Match")
    }
    setltf3error(false)
  }, 1000);
  };
  
  const [aliferror, setaliferror] = useState('')
  
  const handleImageChange170 = () => {
    setlifimg2sand('')
    setlifimg5('');
    setlif3error(true)
    setTimeout(() => {
    const client = new MatchFinger(80,5000,formdatao.ansi_l_index_finger);
    if(client.data.Status){
      // console.log("3454355")
      setlifimg2sand(formdatao.l_index_finger)
      setlifimg5(formdatao.l_index_finger);
      setansilifimg5(formdatao.ansi_l_index_finger)
      setrightnumberoffingers12(1)
          setaliferror('')
    }else{
      // console.log("980090000")
      setaliferror(" Not Match")
    }
    setlif3error(false)
  }, 1000);
  };
  
  const [almferror, setalmferror] = useState('')
  
  const handleImageChange180 = () => {
    setlmfimg2sand('')
      setlmfimg5('');
    setlmf3error(true)
    setTimeout(() => {
    const client = new MatchFinger(80,5000,formdatao.ansi_l_middle_finger);
    if(client.data.Status){
      // console.log("3454355")
      setlmfimg2sand(formdatao.l_middle_finger)
      setlmfimg5(formdatao.l_middle_finger);
      setansilmfimg5(formdatao.ansi_l_middle_finger)
      setrightnumberoffingers12(1)
          setalmferror('')
    }else{
      // console.log("980090000")
      setalmferror(" Not Match")
    }
    setlmf3error(false)
  }, 1000);
  };
  const [alrferror, setalrferror] = useState('')
  
  const handleImageChange190 = () => {
    setlrfimg2sand('')
      setlrfimg5('');
    setlrf3error(true)
    setTimeout(() => {
    const client = new MatchFinger(80,5000,formdatao.ansi_l_ring_finger);
    if(client.data.Status){
      // console.log("3454355")
      setlrfimg2sand(formdatao.l_ring_finger)
      setlrfimg5(formdatao.l_ring_finger);
      setansilrfimg5(formdatao.ansi_l_ring_finger)
      setrightnumberoffingers12(1)
      setalrferror('')
    }else{
      // console.log("980090000")
      setalrferror(" Not Match")
    }
    setlrf3error(false)
  }, 1000);
  };
  const [allferror, setallferror] = useState('')
  
  const handleImageChange200 = () => {
    setllfimg2sand('')
      setllfimg5('');
    setllf3error(true)
    setTimeout(() => {
    const client = new MatchFinger(80,5000,formdatao.ansi_l_little_finger);
    if(client.data.Status){
      // console.log("3454355")
      setllfimg2sand(formdatao.l_little_finger)
      setllfimg5(formdatao.l_little_finger);
      setansillfimg5(formdatao.ansi_l_little_finger)
      setrightnumberoffingers12(1)
          setallferror('')
    }else{
      // console.log("980090000")
      setallferror(" Not Match")
    }
    setllf3error(false)
  }, 1000);
  };
    

  



    async function submitform(e) {
        e.preventDefault();
        if(rightnumberoffingers12 == 0 && gettokinval.email !== "test@gmail.com" ){
          setfingerperror12(true)
    setrigthfingerserror12('Verify atleast One Finger Print')
    }else{
    setrigthfingerserror12('')
    // if(leftnumberoffingers12 == 0 && gettokinval.email !== "test@gmail.com"){
    //   setfingerperror12(true)
    // setrigthfingerserror12('Capture atleast One Finger in Your Left Hand')
    // }else{
    
    setfingerperror12(false)
    // setrigthfingerserror12('')
    if(documentsuperresverify == "SUCCESS" || nationality == "Others"){
      if(documentsuperres == "SUCCESS" || nationality == "Others"){
        if(documentsuperresseond == "SUCCESS" || nationality == "Others"){
          
          setloader(true)
      
              const formdata = new FormData();
              formdata.append("usernameCheck", true);
              formdata.append("fnameCheck", true);
              formdata.append("lnameCheck", true);
              formdata.append("roleCheck", true);
              formdata.append("mobile_noCheck", true);
              formdata.append("genderCheck", true);
              formdata.append("emailCheck", true);
              formdata.append("companyCheck", true);
              formdata.append("locationCheck", true);
              formdata.append("dobCheck", true);
              formdata.append("countryCheck", true);
              formdata.append("stateCheck", true);
              formdata.append("cityCheck", true);
              formdata.append("pincodeCheck", true);
              formdata.append("addressCheck", true);
              formdata.append('photographCheck',true)
              formdata.append('document_typeCheck',true)
              formdata.append('issue_dateCheck',true)
              formdata.append("expiry_dateCheck", true);
              // formdata.append("fathersFullName", kycfathername);
          formdata.append("fullName", kycfullname);
          formdata.append("fullName_2", kycfullnametwo);
          // formdata.append("photo", kycphoto);
          // formdata.append("age", kycage);
          // formdata.append("doc_dob", kycdob);
          // formdata.append("doc_gender", kycgander);
          // formdata.append("doc_mobile", `${kycphonecode}${kycphone}`);
          // formdata.append("doc_email", kycemail);
          // console.log("first fff",kycemail)
          // formdata.append("doc_address", kycaddress);
          // formdata.append("doc_country", kyccountry);
          // formdata.append("doc_state", kycstate);
          // formdata.append("doc_city", kyccity);
          // formdata.append("doc_pincode", kycpincode);
              // formdata.append("r_thumb", cr_thumb);
              // formdata.append("r_index_finger", cr_index_finger);
              // formdata.append("r_middle_finger", cr_middle_finger);
              // formdata.append("r_ring_finger", cr_ring_finger);
              // formdata.append("r_little_finger", cr_little_finger);
              // formdata.append("l_thumb", cl_thumb);
              // formdata.append("l_index_finger", cl_index_finger);
              // formdata.append("l_middle_finger", cl_middle_finger);
              // formdata.append("l_ring_finger", cl_ring_finger);
              // formdata.append("l_little_finger", cl_little_finger);
      
              try {
                const config = {
                  headers: {
                    Authorization: `Bearer ${gettokinval.access}`,
                  },
                };
                let url = `${process.env.REACT_APP_API_URL}api/agent_checking/${id}/`;
                const response = await axios.patch(url, formdata, config);
                // console.log(response);
              //   setcustresponse(response);
              //   seterrorclass(false);
              setloader(false)
              nvg("/checkagentlist/1")
              //   setgrandloading(false)
              //   nvg("/Customerlist/1")
              } catch (error) {
              //   setcustresponse({
              //     statusone: 1,
              //     message: "Something Went Wrong Try again",
              //   });
              setloader(false)
              //   setgrandloading(false)
              //   seterrorcls(error.response.data);
              //   seterrorclass(true);
              //   setloading(false)
              nvg("/checkagentlist/2")
              // console.log(error,'wert')
              seterrorcls(error.response.data);
              }
          }else{
              setidno(5)
              setTimeout(function() {
                setidno(0)
               }, 5000);
          }
      }else{
              setidno(5)
              setTimeout(function() {
                setidno(0)
               }, 5000);
          }
    }else{
      setidno(5)
      setTimeout(function() {
        setidno(0)
       }, 5000);
    }
  
        
      // }
    }
      }




      useEffect(() => {
        async function fetchBio() {
          const config = {
            headers: {
              Authorization: `Bearer ${gettokinval.access}`,
            },
          };
          let url = `${process.env.REACT_APP_API_URL}api/agent-biometric/${gettokinval.id}/`;
          const response = await axios.get(url, config);
          // setfname(response.data.customer_fname)
          // setlname(response.data.customer_lname)
          setsrtloader(false)
          setformDatao(response.data)
          setllfimg2sand(response.data.l_little_finger)
          setlrfimg2sand(response.data.l_ring_finger)
          setlmfimg2sand(response.data.l_middle_finger)
          setlifimg2sand(response.data.l_index_finger)
          setltfimg2sand(response.data.l_thumb)
          setrlfimg2sand(response.data.r_little_finger)
          setrrfimg2sand(response.data.r_ring_finger)
          setrmfimg2sand(response.data.r_middle_finger)
          setrifimg2sand(response.data.r_index_finger)
          setthumbimg2sand(response.data.r_thumb)
    
    // console.log(response)
    
       
        }
        fetchBio();
    
    
    
    
        }, []); 
    
    return (
        <div style={{ width: '100%' }}>
            {/* <Header /> */}
            <div className="dashboardcontent px-2">
                {/* <Breadcup name={'Agent'} second={'Checking'} /> */}
                <div className="container-fuild px-2 desgin1 ">
                    <div className="row bg-white ">
                        <div className="col-lg-12 d-flex justify-content-between py-2" style={{ background: 'hsla(210, 85%, 32%, 0.2)', color: '#0C5398' }}>
                            <p className="m-0 customfont" >Edit Checking Agent</p>
                            <div className="addnew d-block mb-2">
                                <button className="btn text-white closebtn"><NavLink to='/checkagentlist/0' style={{ textDecoration: 'none', color: 'white' }}> x Close</NavLink></button>
                            </div>
                        </div>
                        {/* <div className="col-12 py-3 customtext23" style={{ background: 'hsla(210, 85%, 32%, 0.2)', color: '#0C5398' }}>Edit Agent </div> */}
                    </div>
                </div>
                {srtloader == true ? <div className="container-fuild bg-white" style={{position:'relative'}}> <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"95%"}}>
        <div className='px-2'> <Loadercomp size={100} /></div>
        </div></div> : <form onSubmit={submitform} ><div className="container-fuild px-2 pb-4 pt-4 bg-white">
                    <div className="row bg-white pb-4 round" style={{ border: '1px solid #E0E0E0', margin: "10px 0px", borderRadius: '3px',position:'relative' }}>
                    {documentsuperres == "SUCCESS" ?<div className="col-12 px-lg-4 px-md-2 pt-3">
                            <div className="row d-flex justify-content-between">
                                <div className="col">
                                    <h5 style={{color:'#0C5398',fontWeight:"700"}} ></h5>
                                </div>
                                <div className="col">
                                    <h5 style={{color:'#0C5398',fontWeight:"700"}} >Kyc First</h5>
                                </div>
                                <div className="col d-flex justify-content-between" style={{ gap: '22px' }}>
                                    <h5  style={{color:'#0C5398',fontWeight:"700"}} className=''>Response 1</h5>
                                </div>
                                <div className="col d-flex justify-content-between" style={{ gap: '22px' }}>
                                    <h5  style={{color:'#0C5398',fontWeight:"700"}} className=''>Response 2</h5>
                                </div>
                            </div>
                        </div>:''}
                    {/* {documentsuperres == "SUCCESS" ? <div className="col-xl-6 d-xl-block d-none px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-5 offset-3">
                                    <h5 style={{color:'#0C5398',fontWeight:"700"}} >Kyc First</h5>
                                </div>
                                <div className="col-3 d-flex" style={{ gap: '22px' }}>
                                    <h5  style={{color:'#0C5398',fontWeight:"700"}} className=''>Response</h5>
                                </div>
                            </div>
                        </div>:''}
                        {documentsuperres == "SUCCESS" ? <div className="col-xl-6 px-lg-4 px-2 pt-3">
                        <div className="row">
                                <div className="col-5 offset-3">
                                    <h5 style={{color:'#0C5398',fontWeight:"700"}} >Kyc First</h5>
                                </div>
                                <div className="col-3 d-flex" style={{ gap: '22px' }}>
                                    <h5  style={{color:'#0C5398',fontWeight:"700"}} className=''>Response</h5>
                                </div>
                            </div>
                        </div>:''} */}
                         {/* {documentsuperres == "SUCCESS" ?<div className="col-12 px-lg-4 px-md-2 pt-3">
                            <div className="row d-flex justify-content-between">
                                <div className="col">
                                    <h5 style={{color:'#0C5398',fontWeight:"700"}} ></h5>
                                </div>
                                <div className="col">
                                    <h5 style={{color:'#0C5398',fontWeight:"700"}} >Kyc First</h5>
                                </div>
                                <div className="col d-flex justify-content-between" style={{ gap: '22px' }}>
                                    <h5  style={{color:'#0C5398',fontWeight:"700"}} className=''>Response</h5>
                                </div>
                                <div className="col d-flex justify-content-between" style={{ gap: '22px' }}>
                                    <h5  style={{color:'#0C5398',fontWeight:"700"}} className=''>Response</h5>
                                </div>
                            </div>
                        </div>:''} */}





{documentsuperres == "SUCCESS" ? <div className="col-12 px-lg-4 px-md-2 pt-3">
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="" className="form-label">Username</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col d-flex justify-content-between justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentusername}</p>
                                    {/* <input class="form-check-input" type="checkbox" value={usernamecheck} onChange={(e) => {
                            setusernamecheck(!usernamecheck);
                          }} id="flexCheckChecked" required /> */}
                                </div>
                                {documentsuperres == "SUCCESS" ? <div className="col  customcolor"></div> : ''}
                                {documentsuperres == "SUCCESS" ? <div className="col customcolor"></div> : ''}
                            </div>
                        </div> :<div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">
                                        Username
                                    </label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col-4 d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentusername}</p>
                                    {/* <input class="form-check-input" type="checkbox" value={usernamecheck} onChange={(e) => {
                            setusernamecheck(!usernamecheck);
                          }} id="flexCheckChecked" required /> */}
                                </div>
                            </div>
                        </div> }



                        
                        {/* <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">
                                        Username
                                    </label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col-4 d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentusername}</p>
                                    <input class="form-check-input" type="checkbox" value={usernamecheck} onChange={(e) => {
                            setusernamecheck(!usernamecheck);
                          }} id="flexCheckChecked" required />
                                </div>
                            </div>
                        </div> */}








{documentsuperres == "SUCCESS" ?   <div className="col-12 px-lg-4 px-md-2 pt-3">
                            <div className="row d-flex justify-content-center">
                                <div className="col">
                                    <label htmlFor="" className="form-label">Email </label>
                                </div>
                                {/* <div className="col d-flex justify-content-end " style={{flexWrap:"wrap"}}> */}
                                <div className={documentsuperres == "SUCCESS" ? `col d-flex justify-content-between`:`col-12 d-flex justify-content-end`}>
                                    <p className='customcolor'>{agentemail}</p>
                                    {/* <input class="form-check-input" type="checkbox" value={emailIDcheck} onChange={(e) => {
                            setemailIDcheck(!emailIDcheck);
                          }} id="flexCheckChecked"  required /> */}
                                </div>
                                
                                {documentsuperres == "SUCCESS" ? <div className="col d-flex customcolor" style={{flex:'wrap'}}>{kycemail}</div> : ''}
                                {documentsuperres == "SUCCESS" ? <div className="col d-flex customcolor" style={{flex:'wrap'}}>{kycemail}</div> : ''}
                                {/* </div> */}

                            </div>
                        </div> : <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label customw">Email  </label>
                                </div>
                                <div className="col-9">
                                  <div className="row">
                                <div className={documentsuperres == "SUCCESS" ? `col-xl-12 col-lg-5 col-12 d-flex justify-content-between`:`col-12 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentemail}</p>
                                    {/* <input class="form-check-input" type="checkbox" value={emailIDcheck} onChange={(e) => {
                            setemailIDcheck(!emailIDcheck);
                          }} id="flexCheckChecked" required /> */}
                                </div>
                                {documentsuperres == "SUCCESS" ? <div className="col-xl-12 col-lg-4 col-12 customcolor">{kycemail}</div> : ''}
                                </div>
                                </div>
                            </div>
                        </div>}









                        {/* <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label customw">Email  </label>
                                </div>
                                <div className="col-9">
                                  <div className="row">
                                <div className={documentsuperres == "SUCCESS" ? `col-xl-12 col-lg-5 col-12 d-flex justify-content-between`:`col-12 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentemail}</p>
                                    <input class="form-check-input" type="checkbox" value={emailIDcheck} onChange={(e) => {
                            setemailIDcheck(!emailIDcheck);
                          }} id="flexCheckChecked" required />
                                </div>
                                {documentsuperres == "SUCCESS" ? <div className="col-xl-12 col-lg-4 col-12 customcolor">{kycemail}</div> : ''}
                                </div>
                                </div>
                            </div>
                        </div> */}


                        {documentsuperres == "SUCCESS" ? <div className="col-12 px-lg-4 px-md-2 pt-3">
                            <div className="row d-flex justify-content-center">
                                <div className="col">
                                    <label htmlFor="" className="form-label">First Name</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col d-flex justify-content-between justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentname}</p>
                                    <input class="form-check-input" type="checkbox" value={fnamecheck} onChange={(e) => {
                            setfnamecheck(!fnamecheck);
                          }}  id="flexCheckChecked" required />
                                </div>
                                {documentsuperres == "SUCCESS" ? <div className="col customcolor">{kycfullname}</div> : ''}
                                {documentsuperres == "SUCCESS" ? <div className="col customcolor">{kycfullnametwo}</div> : ''}
                            </div>
                        </div> :   <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">First Name</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col-xl-5 col-lg-4 col-5 d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentname}</p>
                                    <input class="form-check-input" type="checkbox" value={fnamecheck} onChange={(e) => {
                            setfnamecheck(!fnamecheck);
                          }} id="flexCheckChecked" required />
                                </div>
                    
                                {documentsuperres == "SUCCESS" ? <div className="col-3 customcolor">{kycfullname}</div> : ''}
                
                            </div>
                        </div> }
                        {/* <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">First Name</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col-xl-5 col-lg-4 col-5 d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentname}</p>
                                    <input class="form-check-input" type="checkbox" value={fnamecheck} onChange={(e) => {
                            setfnamecheck(!fnamecheck);
                          }} id="flexCheckChecked" required />
                                </div>
                    
                                {documentsuperres == "SUCCESS" ? <div className="col-3 customcolor">{kycfullname}</div> : ''}
                
                            </div>
                        </div> */}







{documentsuperres == "SUCCESS" ? <div className="col-12 px-lg-4 px-md-2 pt-3">
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="" className="form-label">Last Name</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col d-flex justify-content-between justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentlast != "undefined" && agentlast != "" ? agentlast : ''}</p>
                                    {/* <input class="form-check-input" type="checkbox" value={lnamecheck} onChange={(e) => {
                            setlnamecheck(!lnamecheck);
                          }} id="flexCheckChecked" required /> */}
                                </div>
                                {documentsuperres == "SUCCESS" ? <div className="col  customcolor"></div> : ''}
                                {documentsuperres == "SUCCESS" ? <div className="col customcolor"></div> : ''}
                            </div>
                        </div> :     <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">Last Name</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col-4 d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentlast != "undefined" && agentlast != "" ? agentlast : ''}</p>
                                    {/* <input class="form-check-input" type="checkbox" value={lnamecheck} onChange={(e) => {
                            setlnamecheck(!lnamecheck);
                          }} id="flexCheckChecked" required /> */}
                                </div>
                            </div>
                        </div> }












                        {/* <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">Last Name</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col-4 d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentlast != "undefined" && agentlast != "" ? agentlast : ''}</p>
                                    <input class="form-check-input" type="checkbox" value={lnamecheck} onChange={(e) => {
                            setlnamecheck(!lnamecheck);
                          }} id="flexCheckChecked" required />
                                </div>
                            </div>
                        </div> */}










{documentsuperres == "SUCCESS" ? <div className="col-12 px-lg-4 px-md-2 pt-3">
                            <div className="row d-flex justify-content-center">
                                <div className="col">
                                    <label htmlFor="" className="form-label ">Father Name</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{ffn}</p>
                                    {/* <input class="form-check-input" type="checkbox" id="flexCheckChecked" required /> */}
                                </div>
                                {documentsuperres == "SUCCESS" ? <div className="col customcolor">{kycfathername}</div> : ''}
                                {documentsuperres == "SUCCESS" ? <div className="col customcolor">{kycfathername}</div> : ''}
                            </div>
                        </div> :       <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">Father Name</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col-4 d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{ffn}</p>
                                    {/* <input class="form-check-input" type="checkbox"  id="flexCheckChecked" required /> */}
                                </div>
                    
                                {documentsuperres == "SUCCESS" ? <div className="col-4 customcolor">{kycfathername}</div> : ''}
                
                            </div>
                        </div>}






                        {/* <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">Father Name</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col-4 d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{ffn}</p>
                                    <input class="form-check-input" type="checkbox"  id="flexCheckChecked" required />
                                </div>
                    
                                {documentsuperres == "SUCCESS" ? <div className="col-4 customcolor">{kycfathername}</div> : ''}
                
                            </div>
                        </div> */}






{documentsuperres == "SUCCESS" ? <div className="col-12 px-lg-4 px-md-2 pt-3">
                            <div className="row d-flex justify-content-center">
                                <div className="col">
                                    <label htmlFor="" className="form-label">Mobile No.</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col d-flex justify-content-between`:`col-9 d-flex justify-content-end justify-content-end`} style={{ gap: '12px' }}>
                                    <p className='customcolor'>{agentmobile}</p>
                                    {/* <input class="form-check-input" type="checkbox" value={mobile_nocheck} onChange={(e) => {
                            setmobile_nocheck(!mobile_nocheck);
                          }} id="flexCheckChecked" required /> */}
                                </div>
                                {documentsuperres == "SUCCESS" ? <div className="col customcolor">{kycphone}</div> : ''}
                                {documentsuperres == "SUCCESS" ? <div className="col customcolor">{kycphone}</div> : ''}
                            </div>
                        </div> : <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">Mobile No.</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col-xl-5 col-lg-4 col-5 d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentmobile}</p>
                                    {/* <input class="form-check-input" type="checkbox" value={mobile_nocheck} onChange={(e) => {
                            setmobile_nocheck(!mobile_nocheck);
                          }} id="flexCheckChecked" required /> */}
                                </div>
                                {documentsuperres == "SUCCESS" ? <div className="col-4 customcolor">{kycphone}</div> : ''}
                            </div>
                        </div>}



                        {/* <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">Mobile No.</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col-xl-5 col-lg-4 col-5 d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentmobile}</p>
                                    <input class="form-check-input" type="checkbox" value={mobile_nocheck} onChange={(e) => {
                            setmobile_nocheck(!mobile_nocheck);
                          }} id="flexCheckChecked" required />
                                </div>
                                {documentsuperres == "SUCCESS" ? <div className="col-4 customcolor">{kycphone}</div> : ''}
                            </div>
                        </div> */}





{documentsuperres == "SUCCESS" ? <div className="col-12 px-lg-4 px-md-2 pt-3">
                            <div className="row d-flex justify-content-between">
                                <div className="col">
                                    <label htmlFor="" className="form-label">Age</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ?`col d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentage}</p>
                                    {/* <input class="form-check-input" type="checkbox"  id="flexCheckChecked" required /> */}
                                </div>
                                {documentsuperres == "SUCCESS" ? <div className="col customcolor">{kycage}</div>:''}
                                {documentsuperres == "SUCCESS" ? <div className="col customcolor">{kycage}</div>:''}
                            </div>
                        </div> : <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">Age</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col-4 d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentage}</p>
                                    {/* <input class="form-check-input" type="checkbox" id="flexCheckChecked" required /> */}
                                </div>
                                {documentsuperres == "SUCCESS" ? <div className="col-3 customcolor">{kycage}</div>:''}
                            </div>
                        </div>}



                        {/* <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">Age</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col-4 d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentage}</p>
                                    <input class="form-check-input" type="checkbox" id="flexCheckChecked" required />
                                </div>
                                {documentsuperres == "SUCCESS" ? <div className="col-3 customcolor">{kycage}</div>:''}
                            </div>
                        </div> */}








{documentsuperres == "SUCCESS" ? <div className="col-12 px-lg-4 px-md-2 pt-3">
                            <div className="row d-flex justify-content-between">
                                <div className="col">
                                    <label htmlFor="" className="form-label">DOB</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col d-flex justify-content-between`:`col-9 d-flex justify-content-end`}  style={{ gap: '22px' }}>
                                    <p className='customcolor'>{crtdate}</p>
                                    <input class="form-check-input" type="checkbox" value={dobcheck} onChange={(e) => {
                            setdobcheck(!dobcheck);
                          }} id="flexCheckChecked" required />
                                </div>
                                {documentsuperres == "SUCCESS" ? <div className="col customcolor">{kycdob}</div> : ''}
                                {documentsuperres == "SUCCESS" ? <div className="col customcolor">{kycdob}</div> : ''}
                            </div>
                        </div> : <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">DOB</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col-xl-5 col-lg-4 col-5 d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{crtdate}</p>
                                    <input class="form-check-input" type="checkbox" value={dobcheck} onChange={(e) => {
                            setdobcheck(!dobcheck);
                          }} id="flexCheckChecked" required />
                                </div>
                                {documentsuperres == "SUCCESS" ? <div className="col-4 customcolor">{kycdob}</div> : ''}

                            </div>
                        </div>}




                        {/* <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">DOB</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col-xl-5 col-lg-4 col-5 d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{crtdate}</p>
                                    <input class="form-check-input" type="checkbox" value={dobcheck} onChange={(e) => {
                            setdobcheck(!dobcheck);
                          }} id="flexCheckChecked" required />
                                </div>
                                {documentsuperres == "SUCCESS" ? <div className="col-4 customcolor">{kycdob}</div> : ''}

                            </div>
                        </div> */}














{documentsuperres == "SUCCESS" ? <></> : <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">Company</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col-4 d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{defaultv}</p>
                                    {/* <input class="form-check-input" type="checkbox" value={companycheck} onChange={(e) => {
                            setcompanycheck(!companycheck);
                          }} id="flexCheckChecked" required /> */}
                                </div>
                            </div>
                        </div>}
                        {documentsuperres == "SUCCESS" ? <></> : <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">Location</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col-4 d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{defaultvtwo}</p>
                                    {/* <input class="form-check-input" type="checkbox" value={locationcheck} onChange={(e) => {
                            setlocationcheck(!locationcheck);
                          }} id="flexCheckChecked" required /> */}
                                </div>
                            </div>
                        </div>}

                        
                        
                        
                        
                        {documentsuperres == "SUCCESS" ? <div className="col-12 px-lg-4 px-md-2 pt-3">
                            <div className="row d-flex justify-content-between">
                                <div className="col">
                                    <label htmlFor="" className="form-label">Gender</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col d-flex justify-content-between`:`col-9 d-flex justify-content-end`}  style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentgender}</p>
                                    <input class="form-check-input" type="checkbox" value={gendercheck} onChange={(e) => {
                            setgendercheck(!gendercheck);
                          }} id="flexCheckChecked" required />
                                </div>
                                {documentsuperres == "SUCCESS" ? <div className="col customcolor">{kycgander}</div> : ''}
                                {documentsuperres == "SUCCESS" ? <div className="col customcolor">{kycgander}</div> : ''}
                            </div>
                        </div> : <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">Gender</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col-4 d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentgender}</p>
                                    <input class="form-check-input" type="checkbox" value={gendercheck} onChange={(e) => {
                            setgendercheck(!gendercheck);
                          }} id="flexCheckChecked" required />
                                </div>
                                {documentsuperres == "SUCCESS" ? <div className="col-4 customcolor">{kycgander}</div> : ''}
                            </div>
                        </div>}









                        {documentsuperres == "SUCCESS" ? <div className="col-12 px-lg-4 px-md-2 pt-3">
                            <div className="row d-flex justify-content-between">
                                <div className="col">
                                    <label htmlFor="" className="form-label">Address</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col d-flex justify-content-between`:`col-9 d-flex justify-content-end`}  style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentaddress}</p>
                                    {/* <input class="form-check-input" type="checkbox" value={addresscheck} onChange={(e) => {
                            setaddresscheck(!addresscheck);
                          }} id="flexCheckChecked" required /> */}
                                </div>
                                {documentsuperres == "SUCCESS" ? <div className="col customcolor">{kycaddress}</div> : ''}
                                {documentsuperres == "SUCCESS" ? <div className="col customcolor">{kycaddress}</div> : ''}
                            </div>
                        </div> : <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">Address</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col-xl-5 col-lg-4 col-5 d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentaddress}</p>
                                    {/* <input class="form-check-input" type="checkbox" value={addresscheck} onChange={(e) => {
                            setaddresscheck(!addresscheck);
                          }} id="flexCheckChecked" required /> */}
                                </div>
                                {documentsuperres == "SUCCESS" ? <div className="col-4 customcolor">{kycaddress}</div> : ''}

                            </div>
                        </div>}
                        
                        {/* <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">Address</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col-xl-5 col-lg-4 col-5 d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentaddress}</p>
                                    <input class="form-check-input" type="checkbox" value={addresscheck} onChange={(e) => {
                            setaddresscheck(!addresscheck);
                          }} id="flexCheckChecked" required />
                                </div>
                                {documentsuperres == "SUCCESS" ? <div className="col-4 customcolor">{kycaddress}</div> : ''}

                            </div>
                        </div> */}










{documentsuperres == "SUCCESS" ? <div className="col-12 px-lg-4 px-md-2 pt-3">
                            <div className="row d-flex justify-content-between">
                                <div className="col">
                                    <label htmlFor="" className="form-label">Country</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col d-flex justify-content-between`:`col-9 d-flex justify-content-end`}  style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentcountry}</p>
                                    <input class="form-check-input" type="checkbox" value={countrycheck} onChange={(e) => {
                            setcountrycheck(!countrycheck);
                          }} id="flexCheckChecked" required />
                                </div>
                                {documentsuperres == "SUCCESS" ? <div className="col customcolor">{getName(kyccountry)}</div> : ''}
                                {documentsuperres == "SUCCESS" ? <div className="col customcolor">{getName(kyccountry)}</div> : ''}
                            </div>
                        </div> : <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">Country</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col-4 d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentcountry}</p>
                                    <input class="form-check-input" type="checkbox" value={countrycheck} onChange={(e) => {
                            setcountrycheck(!countrycheck);
                          }} id="flexCheckChecked" required />
                                </div>
                                {documentsuperres == "SUCCESS" ? <div className="col-4 customcolor">{getName(kyccountry)}</div> : ''}

                            </div>
                        </div>}

                        
                        {/* <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">Country</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col-4 d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentcountry}</p>
                                    <input class="form-check-input" type="checkbox" value={countrycheck} onChange={(e) => {
                            setcountrycheck(!countrycheck);
                          }} id="flexCheckChecked" required />
                                </div>
                                {documentsuperres == "SUCCESS" ? <div className="col-4 customcolor">{getName(kyccountry)}</div> : ''}

                            </div>
                        </div> */}









{documentsuperres == "SUCCESS" ? <div className="col-12 px-lg-4 px-md-2 pt-3">
                            <div className="row d-flex justify-content-between">
                                <div className="col">
                                    <label htmlFor="" className="form-label">State</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col d-flex justify-content-between`:`col-9 d-flex justify-content-end`}  style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentstate}</p>
                                    {/* <input class="form-check-input" type="checkbox" value={statecheck} onChange={(e) => {
                            setstatecheck(!statecheck);
                          }} id="flexCheckChecked" required /> */}
                                </div>
                                {documentsuperres == "SUCCESS" ? <div className="col customcolor">{kycstate}</div> : ''}
                                {documentsuperres == "SUCCESS" ? <div className="col customcolor">{kycstate}</div> : ''}
                            </div>
                        </div> : <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">State</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col-xl-5 col-lg-4 col-4 d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentstate}</p>
                                    {/* <input class="form-check-input" type="checkbox" value={statecheck} onChange={(e) => {
                            setstatecheck(!statecheck);
                          }} id="flexCheckChecked" required /> */}
                                </div>
                                {documentsuperres == "SUCCESS" ? <div className="col-4 customcolor">{kycstate}</div> : ''}

                            </div>
                        </div>}

                        {/* <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">State</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col-xl-5 col-lg-4 col-4 d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentstate}</p>
                                    <input class="form-check-input" type="checkbox" value={statecheck} onChange={(e) => {
                            setstatecheck(!statecheck);
                          }} id="flexCheckChecked" required />
                                </div>
                                {documentsuperres == "SUCCESS" ? <div className="col-4 customcolor">{kycstate}</div> : ''}

                            </div>
                        </div> */}









{documentsuperres == "SUCCESS" ? <div className="col-12 px-lg-4 px-md-2 pt-3">
                            <div className="row d-flex justify-content-between">
                                <div className="col">
                                    <label htmlFor="" className="form-label">City</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col d-flex justify-content-between`:`col-9 d-flex justify-content-end`}  style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentcity}</p>
                                    {/* <input class="form-check-input" type="checkbox" value={citycheck} onChange={(e) => {
                            setcitycheck(!citycheck);
                          }} id="flexCheckChecked" required /> */}
                                </div>
                                {documentsuperres == "SUCCESS" ? <div className="col customcolor">{kyccity}</div> : ''}
                                {documentsuperres == "SUCCESS" ? <div className="col customcolor">{kyccity}</div> : ''}
                            </div>
                        </div> : <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">City</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col-4 d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentcity}</p>
                                    {/* <input class="form-check-input" type="checkbox" value={citycheck} onChange={(e) => {
                            setcitycheck(!citycheck);
                          }} id="flexCheckChecked" required /> */}
                                </div>
                                {documentsuperres == "SUCCESS" ? <div className="col-4 customcolor">{kyccity}</div> : ''}
                            </div>
                        </div>}




                        {/* <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">City</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col-4 d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentcity}</p>
                                    <input class="form-check-input" type="checkbox" value={citycheck} onChange={(e) => {
                            setcitycheck(!citycheck);
                          }} id="flexCheckChecked" required />
                                </div>
                                {documentsuperres == "SUCCESS" ? <div className="col-4 customcolor">{kyccity}</div> : ''}
                            </div>
                        </div> */}






{documentsuperres == "SUCCESS" ? <div className="col-12 px-lg-4 px-md-2 pt-3">
                            <div className="row d-flex justify-content-between">
                                <div className="col">
                                    <label htmlFor="" className="form-label">Pincode </label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col d-flex justify-content-between`:`col-9 d-flex justify-content-end`}  style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentpincode}</p>
                                    {/* <input class="form-check-input" type="checkbox" value={pincodecheck} onChange={(e) => {
                            setpincodecheck(!pincodecheck);
                          }} id="flexCheckChecked" required /> */}
                                </div>
                                {documentsuperres == "SUCCESS" ? <div className="col customcolor">{kycpincode}</div> : ''}
                                {documentsuperres == "SUCCESS" ? <div className="col customcolor">{kycpincode}</div> : ''}
                            </div>
                        </div> :   <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">Pincode</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col-xl-5 col-lg-4 col-5 d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentpincode}</p>
                                    {/* <input class="form-check-input" type="checkbox" value={pincodecheck} onChange={(e) => {
                            setpincodecheck(!pincodecheck);
                          }} id="flexCheckChecked" required /> */}
                                </div>
                                {documentsuperres == "SUCCESS" ? <div className="col-4 customcolor">{kycpincode}</div> : ''}

                            </div>
                        </div>}
                        {/* <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">Pincode</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col-xl-5 col-lg-4 col-5 d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentpincode}</p>
                                    <input class="form-check-input" type="checkbox" value={pincodecheck} onChange={(e) => {
                            setpincodecheck(!pincodecheck);
                          }} id="flexCheckChecked" required />
                                </div>
                                {documentsuperres == "SUCCESS" ? <div className="col-4 customcolor">{kycpincode}</div> : ''}

                            </div>
                        </div> */}

{documentsuperres == "SUCCESS" ? <div className="col-12 px-lg-4 px-md-2 pt-3">
                            <div className="row d-flex justify-content-between">
                                <div className="col">
                                    <label htmlFor="" className="form-label">Pincode </label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col d-flex justify-content-between`:`col-9 d-flex justify-content-end`}  style={{ gap: '22px' }}>
                                    <p className='customcolor'>{agentpincode}</p>
                                    {/* <input class="form-check-input" type="checkbox" value={pincodecheck} onChange={(e) => {
                            setpincodecheck(!pincodecheck);
                          }} id="flexCheckChecked" required /> */}
                                </div>
                                {documentsuperres == "SUCCESS" ? <div className="col customcolor">{kycpincode}</div> : ''}
                                {documentsuperres == "SUCCESS" ? <div className="col customcolor">{kycpincode}</div> : ''}
                            </div>
                        </div> :   <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">Nationality</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col-xl-5 col-lg-4 col-5 d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{nationality}</p>
                                    {/* <input class="form-check-input" type="checkbox"  id="flexCheckChecked" required /> */}
                                </div>
                                {documentsuperres == "SUCCESS" ? <div className="col-4 customcolor">{kycpincode}</div> : ''}

                            </div>
                        </div>}

{documentsuperres == "SUCCESS" ? <div className="col-12 px-lg-4 px-md-2 pt-3">
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="" className="form-label">Company</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col d-flex justify-content-between justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{defaultv}</p>
                                    {/* <input class="form-check-input" type="checkbox" value={companycheck} onChange={(e) => {
                            setcompanycheck(!companycheck);
                          }} id="flexCheckChecked" checked required /> */}
                                </div>
                                {documentsuperres == "SUCCESS" ? <div className="col  customcolor"></div> : ''}
                                {documentsuperres == "SUCCESS" ? <div className="col customcolor"></div> : ''}
                            </div>
                        </div> :<></> }                 
{/* {documentsuperres == "SUCCESS" ? <div className="col-xl-6 px-lg-4 px-md-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">Company</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col-8 d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{defaultv}</p>
                                    <input class="form-check-input" type="checkbox" value={companycheck} onChange={(e) => {
                            setcompanycheck(!companycheck);
                          }} id="flexCheckChecked" required />
                                </div>
                            </div>
                        </div> : <></>} */}





                        
{documentsuperres == "SUCCESS" ? <div className="col-12 px-lg-4 px-md-2 pt-3">
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="" className="form-label">Location</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col d-flex justify-content-between justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{defaultvtwo}</p>
                                    {/* <input class="form-check-input" type="checkbox" value={locationcheck} onChange={(e) => {
                            setlocationcheck(!locationcheck);
                          }} id="flexCheckChecked" checked required /> */}
                                </div>
                                {documentsuperres == "SUCCESS" ? <div className="col  customcolor"></div> : ''}
                                {documentsuperres == "SUCCESS" ? <div className="col customcolor"></div> : ''}
                            </div>
                        </div> :<></> }  


                        {/* {documentsuperres == "SUCCESS" ? <div className="col-xl-6 px-lg-4 px-md-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">Location</label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col-8 d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{defaultvtwo}</p>
                                    <input class="form-check-input" type="checkbox" value={locationcheck} onChange={(e) => {
                            setlocationcheck(!locationcheck);
                          }} id="flexCheckChecked" required />
                                </div>
                            </div>
                        </div> : <></> } */}
                        <div className="col-12 px-4 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">Role</label>
                                    {/* <input className="form-check-input" type="checkbox" value={rolecheck} onChange={(e) => {
                            setrolecheck(!rolecheck);
                          }} /> */}
                                </div>
                                {agentchecker == true ? <div className="col-3">  <label htmlFor="" className="form-label" style={{ paddingRight: '6px' }}>Checker</label></div> : ''}
                         
                                {agentmaker == true ? <div className="col-3"> <label htmlFor="" className="form-label" style={{ paddingRight: '6px' }}>Maker</label> 
                                </div> : ''}
                                {agentapprover == true ?  <div className="col-3"> <label htmlFor="" className="form-label" style={{ paddingRight: '6px' }}>Approver</label> 
                                </div> : ''}
                               
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="row px-4 pt-3">
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-12"><label htmlFor="" className="form-label" style={{ paddingRight: '10px' }}>Biometric Detail</label> 
                                        {/* <input class="form-check-input" type="checkbox" value={biomcheck} onChange={(e) => {
                            setbiomcheck(!biomcheck);
                          }} id="flexCheckChecked" required /> */}
                          </div>
                                        <div className="col-12">
                        <div className="border round py-3 d-flex justify-content-center">
                        <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
     <label htmlFor="">Right Hand</label>
     <img src={thumbimg2 == '' || thumbimg2 == null ? img5 : img4} alt="rtf" style={{position:"absolute",top:"80px",left:"13px"}} />
     <img src={rifimg2 == '' || rifimg2 == null ? img5 : img4} alt="rif" style={{position:"absolute",top:"37px",left:"35px"}} />
     <img src={rmfimg2 == '' || rmfimg2 == null ? img5 : img4} alt="rmf" style={{position:"absolute",top:"30px",left:"65px"}} />
     <img src={rrfimg2 == '' || rrfimg2 == null ? img5 : img4} alt="rrf" style={{position:"absolute",top:"36px",left:"84px"}} />
     <img src={rlfimg2 == '' || rlfimg2 == null ? img5 : img4} alt="rlf" style={{position:"absolute",top:"55px",left:"103px"}} />
                          <img src={img1} alt="qwerty" />
                          </div>
                          <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
<label htmlFor="">Left Hand</label>
<img src={llfimg2 == '' || llfimg2 == null ? img5 : img4} alt="llf" style={{position:"absolute",top:"56px",left:"13px"}} />
     <img src={lrfimg2 == '' || lrfimg2 == null ? img5 : img4} alt="lrf" style={{position:"absolute",top:"36px",left:"33px"}} />
     <img src={lmfimg2 == '' || lmfimg2 == null ? img5 : img4} alt="lmf" style={{position:"absolute",top:"31px",left:"55px"}} />
     <img src={lifimg2 == '' || lifimg2 == null ? img5 : img4} alt="lif" style={{position:"absolute",top:"38px",left:"80px"}} />
     <img src={ltfimg2 == '' || ltfimg2 == null ? img5 : img4} alt="ltf" style={{position:"absolute",top:"80px",left:"102px"}} />
                          <img src={img2} alt="trew" />
                          </div>
                        </div>
                      </div>
                                    </div>
                                    <div className="col-12 py-2 px-4 d-flex justify-content-between">
                <button
                type='button'
                  // className="btn1"
                  style={{backgroundColor:"#0C5398"}}
                  className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal5"
                >
                  View
                </button>
              </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-12"><label htmlFor="" className="form-label" style={{ paddingRight: '10px' }}>Photograph</label>
                                        {/* <input class="form-check-input" type="checkbox" value={photographcheck} onChange={(e) => {
                            setphotographcheck(!photographcheck);
                          }} id="flexCheckChecked" required /> */}
                          </div>
                                        <div className="col-12 ">
                                            <div className="border round py-3 d-flex justify-content-center">
                                                {/* <img src={ photograph ? photograph : img3} alt="zxcvbnm" width="140px" height="162px" /> */}
                                                {/* <ReactImageMagnify
                            {...{
                                smallImage: {
                                    alt: 'Wristwatch by Versace',
                                    isFluidWidth: true,
                                    src: photograph == null ? img3 : photograph,
                                    width:140,
                                    height:162
                                },
                                largeImage: {
                                    src: photograph == null ? img3 : photograph,
                                    width: 836,
                                    height: 1100
                                },
                                enlargedImagePosition: 'over',
                                lensStyle: { backgroundColor: 'rgba(0,0,0,.6)' }
                            }}
                            
                        /> */}
                            <div className="row px-2">
                                                    <div className="col-12 d-flex justify-content-center">
                                                    <button
                type='button'
                style={{border:'none',outline:'none'}}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModaltwo"
                >
              <img src={photograph == null ? img3 : photograph} alt="zxcvbnm" width="140px" height="162px" />
              </button>
                                                    {/* <ReactImageMagnify
                            {...{
                                smallImage: {
                                    alt: 'Wristwatch by Versace',
                                    isFluidWidth: true,
                                    src: photograph == null ? img3 : photograph,
                                    width:140,
                                    height:162
                                },
                                largeImage: {
                                    src: photograph == null ? img3 : photograph,
                                    width: 836,
                                    height: 1100
                                },
                                // enlargedImagePosition: 'over',
                                lensStyle: { backgroundColor: 'rgba(0,0,0,.6)' }
                            }}
                            
                        /> */}
                                                    </div>
                                                    {/* {documentsuperres == "SUCCESS" ? <div className="col-6 d-flex justify-content-center">
                                                    <button
                type='button'
                style={{border:'none',outline:'none'}}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalthree"
                >
              <img src={`data:image/png;base64,${kycphoto}`} alt="zxcvbnm" width="140px" height="162px" />
              </button>
                                                  
                                                    </div> : ''} */}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12 ccolor py-3 px-4">Document </div>
                                
                              <div className="col-lg-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-4">
        <label htmlFor="" className="form-label">
        Document Type 
        </label>
      </div>
      <div className={documentsuperres == "SUCCESS" ? `col-5 d-flex justify-content-between`:`col-8 d-flex justify-content-end`} style={{ gap: '22px' }}>





                                    <p className='customcolor'>{documenttype}</p>
                                    <input class="form-check-input" type="checkbox"
                                    value='2023-04-12'
                        //              value={pincodecheck} onChange={(e) => {
                        //     setpincodecheck(!pincodecheck);
                        //   }} 
                          id="flexCheckChecked" required />
     
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-4">
        <label htmlFor="" className="form-label">
        Document No.
        </label>
      </div>
      <div className={documentsuperres == "SUCCESS" ? `col-5 d-flex justify-content-between`:`col-8 d-flex justify-content-end`} style={{ gap: '22px' }}>
      <p className='customcolor'>{idnumber}</p>
                        <input class="form-check-input" type="checkbox"
                                    value='2023-04-12'
                        //              value={pincodecheck} onChange={(e) => {
                        //     setpincodecheck(!pincodecheck);
                        //   }} 
                          id="flexCheckChecked" required />

      </div>
    </div>
  </div>

  {documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT' ? <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">Expiry date </label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col-4 d-flex justify-content-end`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{documentexpiry}</p>
                                    <input class="form-check-input" type="checkbox"
                                    value='2023-04-12'
                        //              value={pincodecheck} onChange={(e) => {
                        //     setpincodecheck(!pincodecheck);
                        //   }} 
                          id="flexCheckChecked" required />
                                </div>
                            </div>
                        </div> : ''}
                        {documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT' ? <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">Issue date </label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col-4 d-flex justify-content-end`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{documentissue}</p>
                                    <input class="form-check-input" type="checkbox"
                                    value='2023-04-12'
                        //              value={pincodecheck} onChange={(e) => {
                        //     setpincodecheck(!pincodecheck);
                        //   }} 
                          id="flexCheckChecked" required />
                                </div>
                            </div>
                        </div> : ''}
                        <div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        Document Front Image
      </div>
      <div className="col-lg-8">
      <img src={aadhaarImage} alt="Modified Aadhaar Card" style={{width:"100%",height:"100%"}} />
      </div>
      <div className=" offset-lg-4 col-lg-8">
      <button
style={{backgroundColor:"#0C5398",marginTop:"5px"}}
type='button'
className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal34"
                >
View
                </button>
      </div>
    </div>
  </div>
  <div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        Document Back Image
      </div>
      <div className="col-lg-8">
      <img src={documentimagebacktwo} alt="Modified Aadhaar Card" style={{width:"100%",height:"100%"}} />
      </div>
      <div className=" offset-lg-4 col-lg-8">
      <button
style={{backgroundColor:"#0C5398",marginTop:"5px"}}
type='button'
className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal35"
                >
View
                </button>
      </div>
    </div>
  </div>


  {donotshowmsg == false ? idno == 1 ? documentsuperres == "SUCCESS" ? <div className="col-12 px-4 d-flex"> <div className="col-12 alert alert-success mt-3" role="alert">
            <h5 style={{padding:'0px',margin:"0px"}}>
         Verification Successfully 
            </h5>
       </div></div> : '' : '' : ''}
       {idno == 2 ? documentsuperres == "FAILURE" ? <div className="col-12 px-4 d-flex"> <div className="col-12 col-offset-2 alert alert-danger mt-3 ms-1" role="alert">
            <h5 style={{padding:'0px',margin:"0px"}}>
         {documentsupermsg}
            </h5>
       </div></div> : '' : '' }


  <div className="col-12 py-3 px-4">
                                    <div style={{ background: '#D9D9D9', height: '1px', width: '100%' }}></div>
                                </div>


                                <div className="col-12 ccolor py-3 px-4">Document 2</div>
                                
                                <div className="col-lg-6 px-4" style={{ paddingTop: "28px" }}>
      <div className="row">
        <div className="col-4">
          <label htmlFor="" className="form-label">
          Document Type 
          </label>
        </div>
        <div className={documentsuperres == "SUCCESS" ? `col-5 d-flex justify-content-end`:`col-8 d-flex justify-content-end`} style={{ gap: '22px' }}>
  
  
  
  
  
                                      <p className='customcolor'>{documenttypetwo}</p>
                                      <input class="form-check-input" type="checkbox"
                                      value='2023-04-12'
                          //              value={pincodecheck} onChange={(e) => {
                          //     setpincodecheck(!pincodecheck);
                          //   }} 
                            id="flexCheckChecked" required />
       
        </div>
      </div>
    </div>
    <div className="col-lg-6 px-4" style={{ paddingTop: "28px" }}>
      <div className="row">
        <div className="col-4">
          <label htmlFor="" className="form-label">
          Document No.
          </label>
        </div>
        <div className={documentsuperres == "SUCCESS" ? `col-5 d-flex justify-content-end`:`col-8 d-flex justify-content-end`} style={{ gap: '22px' }}>
        <p className='customcolor'>{idnumbertwo}</p>
                          <input class="form-check-input" type="checkbox"
                                      value='2023-04-12'
                          //              value={pincodecheck} onChange={(e) => {
                          //     setpincodecheck(!pincodecheck);
                          //   }} 
                            id="flexCheckChecked" required />
  
        </div>
      </div>
    </div>
  
    {documenttypetwo == 'DRIVING_LICENSE' || documenttypetwo == "RESIDENT_PERMIT" || documenttypetwo == "VISA" || documenttypetwo == "REFUGEE_CARD" || documenttypetwo == 'PASSPORT' ? <div className="col-xl-6 px-lg-4 px-4 pt-3">
                              <div className="row">
                                  <div className="col-3">
                                      <label htmlFor="" className="form-label">Expiry date </label>
                                  </div>
                                  <div className={documentsuperres == "SUCCESS" ? `col-5 d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                      <p className='customcolor'>{documentexpirytwo}</p>
                                      <input class="form-check-input" type="checkbox"
                                      value='2023-04-12'
                          //              value={pincodecheck} onChange={(e) => {
                          //     setpincodecheck(!pincodecheck);
                          //   }} 
                            id="flexCheckChecked" required />
                                  </div>
                              </div>
                          </div> : ''}
                          {documenttypetwo == 'DRIVING_LICENSE' || documenttypetwo == "RESIDENT_PERMIT" || documenttypetwo == "VISA" || documenttypetwo == "REFUGEE_CARD" || documenttypetwo == 'PASSPORT' ? <div className="col-xl-6 px-lg-4 px-4 pt-3">
                              <div className="row">
                                  <div className="col-3">
                                      <label htmlFor="" className="form-label">Issue date </label>
                                  </div>
                                  <div className={documentsuperres == "SUCCESS" ? `col-5 d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                      <p className='customcolor'>{documentissuetwo}</p>
                                      <input class="form-check-input" type="checkbox"
                                      value='2023-04-12'
                          //              value={pincodecheck} onChange={(e) => {
                          //     setpincodecheck(!pincodecheck);
                          //   }} 
                            id="flexCheckChecked" required />
                                  </div>
                              </div>
                          </div> : ''}
                          <div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
      <div className="row">
        <div className="col-lg-4">
          Document Front Image
        </div>
        <div className="col-lg-8">
        <img src={modifiedImagenumbertwo} alt="Modified Aadhaar Card" style={{width:"100%",height:"100%"}} />
        </div>
        <div className=" offset-lg-4 col-lg-8">
        <button
  style={{backgroundColor:"#0C5398",marginTop:"5px"}}
  type='button'
  className='btn btn-primary'
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal3400"
                  >
  View
                  </button>
        </div>
      </div>
    </div>
    <div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
      <div className="row">
        <div className="col-lg-4">
          Document Back Image
        </div>
        <div className="col-lg-8">
        <img src={documentimageback2} alt="Modified Aadhaar Card" style={{width:"100%",height:"100%"}} />
        </div>
        <div className=" offset-lg-4 col-lg-8">
        <button
  style={{backgroundColor:"#0C5398",marginTop:"5px"}}
  type='button'
  className='btn btn-primary'
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal3500"
                  >
  View
                  </button>
        </div>
      </div>
    </div>
  {documentloader == true ? <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"95%"}}>
<div className='px-2'>{documentloader == true ?  <Loadercomp size={100}  /> : ""}</div>
</div> : ''}
              {loader == true ? <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"95%"}}>
        <div className='px-2'>{loader == true ?  <Loadercomp size={100}  /> : ""}</div>
        </div> : ''}


{/* 
        {documentsuperres == "SUCCESS" ? <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">Full Name </label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col-4 d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{kycfullname}</p>
                                    <input class="form-check-input" type="checkbox"
                                    value='2023-04-12'
                          id="flexCheckChecked" required />
                                </div>
                            </div>
                        </div> : ''} */}
        {/* {documentsuperres == "SUCCESS" ? <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">Father Name </label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col-4 d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{kycfathername}</p>
                                    <input class="form-check-input" type="checkbox"
                                    value='2023-04-12'
                          id="flexCheckChecked" required />
                                </div>
                            </div>
                        </div> : ''} */}
{/*                         
                        {documentsuperres == "SUCCESS" ? <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">Age </label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col-4 d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    <p className='customcolor'>{kycage}</p>
                                    <input class="form-check-input" type="checkbox"
                                    value='2023-04-12'
                          id="flexCheckChecked" required />
                                </div>
                            </div>
                        </div> : ''} */}


                        






                        {/* {documentsuperres == "SUCCESS" ? <div className="col-6 px-4" style={{ paddingTop: "20px" }}>
                                    <div className="row">
                                        <div className="col-12"><label htmlFor="" className="form-label" style={{ paddingRight: '10px' }}>Image</label><input class="form-check-input" type="checkbox"  id="flexCheckChecked" required /></div>
                                        <div className="col-12 ">
                                            <div className="border round py-3 d-flex justify-content-center">
                                                <ReactImageMagnify
                            {...{
                                smallImage: {
                                    alt: 'Wristwatch by Versace',
                                    isFluidWidth: true,
                                    src: `data:image/png;base64,${kycphoto}`,
                                    width:140,
                                    height:162
                                },
                                largeImage: {
                                    src: `data:image/png;base64,${kycphoto}`,
                                    width: 836,
                                    height: 1100
                                },
                                enlargedImagePosition: 'over',
                                lensStyle: { backgroundColor: 'rgba(0,0,0,.6)' }
                            }}
                            
                        />
                                            </div>
                                        </div>
                                    </div>
                                </div> : ''} */}










{/* 
                        <div className="col-xl-6 px-lg-4 px-2 pt-3">
                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="" className="form-label">Image </label>
                                </div>
                                <div className={documentsuperres == "SUCCESS" ? `col-4 d-flex justify-content-between`:`col-9 d-flex justify-content-end`} style={{ gap: '22px' }}>
                                    {kycphoto == '' ? <img src={img4} alt="qwert" /> : <img src={`data:image/png;base64,${kycphoto}`} alt="qwert" />}
                               
                                    <input class="form-check-input" type="checkbox"
                                    value='2023-04-12'
    
                          id="flexCheckChecked" required />
                                </div>
                            </div>
                        </div> */}


















                                {loader == true ? <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"95%"}}>
        <div className='px-2'>{loader == true ?  <Loadercomp size={100}  /> : ""}</div>
        </div> : ''}
        {/* {idno == 1 ? documentsuperres == "SUCCESS" ? <div className="col-12 px-4 d-flex"> <div className="col-12 alert alert-success mt-3" role="alert">
            <h5 style={{padding:'0px',margin:"0px"}}>
         Verification Successfully 
            </h5>
       </div></div> : '' : ''}
       {idno == 5 ? <div className="col-12 px-4 d-flex"> <div className="col-12 col-offset-2 alert alert-danger mt-3 ms-1" role="alert">
            <h5 style={{padding:'0px',margin:"0px"}}>
         {`Please Verify your ${documenttype} First`}
            </h5>
       </div></div> : '' }
       {idno == 2 ? documentsuperres == "FAILURE" ? <div className="col-12 px-4 d-flex"> <div className="col-12 col-offset-2 alert alert-danger mt-3 ms-1" role="alert">
            <h5 style={{padding:'0px',margin:"0px"}}>
         {documentsupermsg}
            </h5>
       </div></div> : '' : '' }
       {idno == 2 ? documentsuperres == "UNKNOWN" ? <div className="col-12 px-4 d-flex"> <div className="col-12 col-offset-2 alert alert-danger mt-3 ms-1" role="alert">
            <h5 style={{padding:'0px',margin:"0px"}}>
         {documentsupermsg}
            </h5>
       </div></div> : '' : '' } */}

{idno2 == 12 ? documentsuperresuniqe == "FAILURE" ? <div className="col-12 px-4 d-flex"> <div className="col-12 col-offset-2 alert alert-danger mt-3 ms-1" role="alert">
            <h5 style={{padding:'0px',margin:"0px"}}>
         {documentsupermsguniqe}
            </h5>
       </div></div> : '' : '' }
       {idno2 == 12 ? documentsuperresuniqe == "SUCCESS" ? <div className="col-12 px-4 d-flex"> <div className="col-12 col-offset-2 alert alert-success mt-3 ms-1" role="alert">
            <h5 style={{padding:'0px',margin:"0px"}}>
            Verification Successfully 
            </h5>
       </div></div> : '' : '' }

       {idno == 5 ? <div className="col-12 px-4 d-flex"> <div className="col-12 col-offset-2 alert alert-danger mt-3 ms-1" role="alert">
            <h5 style={{padding:'0px',margin:"0px"}}>
         {`Please Verify your Documents First`}
            </h5>
       </div></div> : '' }

       <div className="col-12 py-3 px-4">
                                    <div style={{ background: '#D9D9D9', height: '1px', width: '100%' }}></div>
                                </div>
                                {nationality == "india" ? <div className="col-12 py-4 px-4 d-flex justify-content-end">
        {/* <button className="btn1"> +Add</button> */}
        <button onClick={documentverify} type='button' className="btn2"> Submit</button>
      </div> : ''}
                                <div className="col-12 py-3 px-4">
                                    <div style={{ background: '#D9D9D9', height: '1px', width: '100%' }}></div>
                                </div>






                                <div className="col-12 ccolor py-3 px-4">Verify Agent</div>

<div className="col-md-6">
    <div className="row">
      <div className="col-12"><label htmlFor="" className="form-label">Biometric Detail <span style={{color:'red'}}>*</span></label></div>
      <div className="col-12">
                  <div className="border round py-3 d-flex justify-content-center">
                  <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
<label htmlFor="">Right Hand</label>
<img src={thumbimg2sand == '' || thumbimg2sand == null  ? img5 : img4} alt="rtf" style={{position:"absolute",top:"80px",left:"13px"}} />
<img src={rifimg2sand == '' || rifimg2sand == null  ? img5 : img4} alt="rif" style={{position:"absolute",top:"37px",left:"35px"}} />
<img src={rmfimg2sand == '' || rmfimg2sand == null  ? img5 : img4} alt="rmf" style={{position:"absolute",top:"30px",left:"65px"}} />
<img src={rrfimg2sand == '' || rrfimg2sand == null  ? img5 : img4} alt="rrf" style={{position:"absolute",top:"36px",left:"84px"}} />
<img src={rlfimg2sand == '' || rlfimg2sand == null  ? img5 : img4} alt="rlf" style={{position:"absolute",top:"55px",left:"103px"}} />
                    <img src={img1} alt="qwerty" />
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
<label htmlFor="">Left Hand</label>
<img src={llfimg2sand == '' || llfimg2sand == null  ? img5 : img4} alt="llf" style={{position:"absolute",top:"56px",left:"13px"}} />
<img src={lrfimg2sand == '' || lrfimg2sand == null  ? img5 : img4} alt="lrf" style={{position:"absolute",top:"36px",left:"33px"}} />
<img src={lmfimg2sand == '' || lmfimg2sand == null  ? img5 : img4} alt="lmf" style={{position:"absolute",top:"31px",left:"55px"}} />
<img src={lifimg2sand == '' || lifimg2sand == null  ? img5 : img4} alt="lif" style={{position:"absolute",top:"38px",left:"80px"}} />
<img src={ltfimg2sand == '' || ltfimg2sand == null  ? img5 : img4} alt="ltf" style={{position:"absolute",top:"80px",left:"102px"}} />
                    <img src={img2} alt="trew" />
                    </div>
                  </div>
                </div>
      { fingerperror12 == true ? <span style={{color:'red'}}> {rigthfingerserror12} </span> : ''}
      {/* {errorcls ? errorcls.r_index_finger ? <span style={{color:'red'}}> "Something Went wrong try again" </span> : '' : ''} */}
      <div className="col-12 py-2 px-4 d-flex justify-content-between">
          <button
          type='button'
            // className="btn1"
       style={{backgroundColor:"#0C5398"}}
       className='btn btn-primary'
            data-bs-toggle="modal"
            data-bs-target="#exampleModal009"
          >
            Capture
          </button>
        </div>
    </div>
  </div>
                                <div className="col-12 py-3 px-4 d-flex justify-content-end" >
                
                                    <div className=" d-flex justify-content-end" style={{ gap: '4px' }}>
                                        <button className='btn4'>Cancel</button>
                                        <button type='submit' className='btn5'>Checked</button>
                                        {/* <button className='btn5' data-bs-toggle="modal" data-bs-target="#exampleModal">Submit</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>








                    <div className="modal fade" id="exampleModal34" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>


  
  <div className="col-12 px-2 pt-3">
  <img src={aadhaarImage == null ? img3 : aadhaarImage} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>


          <div className="modal fade" id="exampleModal009" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                  <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>
                  {formdatao.r_thumb == null || formdatao.r_thumb == "" ? '': <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="tf2" className="form-label ">Right Thumb <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" class="form-control" id='tf2' name="image2" style={{opacity:"0",position:'absolute'}}  onChange={(e) => {handleImageChange11(e)}}  /> */}
                          {/* <label htmlFor="tf"> */}
                          {/* <div onClick={(e) => {handleImageChange11()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: thumbimg2 != '' ? '' : '3rem',width:"20%" }}> {thumbimg2 != '' ? <img src={`data:image/png;base64,${thumbimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange110()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: thumbimg5 != '' ? '' : rtf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {thumbimg5 != '' ? <img src={`data:image/png;base64,${thumbimg5}`} width="70px" height="60px" alt="asd" /> : rtf3error == true ? <Loadercomp size={30} /> : ''}  </div>
                          
                          {thumbimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {artferror == '' ? '' : artferror}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao.l_thumb == null || formdatao.l_thumb == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="ltf2" className="form-label ">Left Thumb  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="ltf2" onChange={(e) => {handleImageChange16(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange16()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: ltfimg2 != '' ? '' : '3rem',width:"20%" }}> {ltfimg2 != '' ? <img src={`data:image/png;base64,${ltfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange160()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: ltfimg5 != '' ? '' : ltf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {ltfimg5 != '' ? <img src={`data:image/png;base64,${ltfimg5}`} width="70px" height="60px" alt="asd" /> : ltf3error == true ? <Loadercomp size={30} /> : ''}  </div> 
                          {ltfimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {altferror == '' ? '' : altferror}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao.r_index_finger == null || formdatao.r_index_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="rif2" className="form-label ">Right Index Finger <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rif2" onChange={(e) => {handleImageChange12(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange12()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rifimg2 != '' ? '' : '3rem',width:"20%" }}> {rifimg2 != '' ? <img src={`data:image/png;base64,${rifimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange120()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rifimg5 != '' ? '' : rif3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rifimg5 != '' ? <img src={`data:image/png;base64,${rifimg5}`} width="70px" height="60px" alt="asd" /> : rif3error == true ? <Loadercomp size={30} /> : ''}  </div>
          
                          {rifimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {ariferror == '' ? '' : ariferror}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao.l_index_finger == null || formdatao.l_index_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="lif2" className="form-label ">Left Index Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lif2" onChange={(e) => {handleImageChange17(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange17()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lifimg2 != '' ? '' : '3rem',width:"20%" }}> {lifimg2 != '' ? <img src={`data:image/png;base64,${lifimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange170()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: lifimg5 != '' ? '' : lif3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {lifimg5 != '' ? <img src={`data:image/png;base64,${lifimg5}`} width="70px" height="60px" alt="asd" /> : lif3error == true ? <Loadercomp size={30} /> : ''}  </div>
          
                          {lifimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {aliferror == '' ? '' : aliferror}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao.r_middle_finger == null || formdatao.r_middle_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="rmf2" className="form-label ">Right Middle Finger<span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rmf2" onChange={(e) => {handleImageChange13(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange13()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rmfimg2 != '' ? '' : '3rem',width:"20%" }}> {rmfimg2 != '' ? <img src={`data:image/png;base64,${rmfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange130()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rmfimg5 != '' ? '' : rmf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rmfimg5 != '' ? <img src={`data:image/png;base64,${rmfimg5}`} width="70px" height="60px" alt="asd" /> : rmf3error == true ? <Loadercomp size={30} /> : ''}  </div>
                  
                          {rmfimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {armferror == '' ? '' : armferror}</p>
                        </div>
                      </div>

                    </div>}
                    {formdatao.l_middle_finger == null || formdatao.l_middle_finger == "" ? '' :<div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="lmf2" className="form-label ">Left Middle Finger <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lmf2" onChange={(e) => {handleImageChange18(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange18()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lmfimg2 != '' ? '' : '3rem',width:"20%" }}> {lmfimg2 != '' ? <img src={`data:image/png;base64,${lmfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange180()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: lmfimg5 != '' ? '' : lmf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {lmfimg5 != '' ? <img src={`data:image/png;base64,${lmfimg5}`} width="70px" height="60px" alt="asd" /> : lmf3error == true ? <Loadercomp size={30} /> : ''}  </div>
        
                          {lmfimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                      </div>
                    </div>}
                    {formdatao.r_ring_finger == null || formdatao.r_ring_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="rrf2" className="form-label ">Right Ring Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rrf2" onChange={(e) => {handleImageChange14(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange14()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rrfimg2 != '' ? '' : '3rem',width:"20%" }}> {rrfimg2 != '' ? <img src={`data:image/png;base64,${rrfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange140()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rrfimg5 != '' ? '' : rrf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rrfimg5 != '' ? <img src={`data:image/png;base64,${rrfimg5}`} width="70px" height="60px" alt="asd" /> : rrf3error == true ? <Loadercomp size={30} /> : ''}  </div>

                          {rrfimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {arrferror == '' ? '' : arrferror}</p>
                        </div>
                      </div>

                    </div> }
                    {formdatao.l_ring_finger == null || formdatao.l_ring_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="lrf2" className="form-label ">Left Ring Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lrf2" onChange={(e) => {handleImageChange19(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange19()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lrfimg2 != '' ? '' : '3rem',width:"20%" }}> {lrfimg2 != '' ? <img src={`data:image/png;base64,${lrfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange190()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: lrfimg5 != '' ? '' : lrf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {lrfimg5 != '' ? <img src={`data:image/png;base64,${lrfimg5}`} width="70px" height="60px" alt="asd" /> : lrf3error == true ? <Loadercomp size={30} /> : ''}  </div>
                          
                          {lrfimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {alrferror == '' ? '' : alrferror}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao.r_little_finger == null || formdatao.r_little_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="rlf2" className="form-label ">Right Little Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rlf2" onChange={(e) => {handleImageChange15(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange15()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rlfimg2 != '' ? '' : '3rem',width:"20%" }}> {rlfimg2 != '' ? <img src={`data:image/png;base64,${rlfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange150()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: rlfimg5 != '' ? '' : rlf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {rlfimg5 != '' ? <img src={`data:image/png;base64,${rlfimg5}`} width="70px" height="60px" alt="asd" /> : rlf3error == true ? <Loadercomp size={30} /> : ''}  </div>
                          
                          {rlfimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {arlferror == '' ? '' : arlferror}</p>
                        </div>
                      </div>
                    </div>}
                    {formdatao.l_little_finger == null || formdatao.l_little_finger == "" ? '' : <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="llf2" className="form-label ">Left Little Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="llf2" onChange={(e) => {handleImageChange20(e)}} /> */}
                          {/* <div onClick={(e) => {handleImageChange20()}} style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: llfimg2 != '' ? '' : '3rem',width:"20%" }}> {llfimg2 != '' ? <img src={`data:image/png;base64,${llfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div> */}
        <div onClick={(e) => {handleImageChange200()}} style={{ border:'1px solid #E0E0E0',display:"flex",justifyContent:'center',alignItems:"center", margin: "0px 0px", borderRadius: '3px', padding: llfimg5 != '' ? '' : llf3error == true ? '8px 0px' : '0 0 3rem 0', width:"20%" }}> {llfimg5 != '' ? <img src={`data:image/png;base64,${llfimg5}`} width="70px" height="60px" alt="asd" /> : llf3error == true ? <Loadercomp size={30} /> : ''}  </div>
                  
                          {llfimg5 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''}
                        </div>
                        <div className="col-12">
                          <p style={{color:"red"}}>
                          {allferror == '' ? '' : allferror}</p>
                        </div>
                      </div>
                    </div>}
                  </div>
                </div>
                {/* {grandresponse.status === 201 ? nvg('/Remittancelist/1') : <div></div>} */}
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>

          <div className="modal fade" id="exampleModal3500" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>


  
  <div className="col-12 px-2 pt-3">
  <img src={documentimageback2 == null ? img3 : documentimageback2} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>
          <div className="modal fade" id="exampleModal3400" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>


  
  <div className="col-12 px-2 pt-3">
  <img src={modifiedImagenumbertwo == null ? img3 : modifiedImagenumbertwo} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>

          <div className="modal fade" id="exampleModal35" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>


  
  <div className="col-12 px-2 pt-3">
  <img src={documentimagebacktwo == null ? img3 : documentimagebacktwo} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>

          <div className="modal fade" id="exampleModal356" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>


  
  <div className="col-12 px-2 pt-3">
  <img src={documentimageback2 == null ? img3 : documentimageback2} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>













                    <div className="modal fade" id="exampleModalthree" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>
                <div className="col-12 px-2 pt-3">
  <img src={`data:image/png;base64,${kycphoto}`} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
              </div>
            </div>
          </div>  
                    <div className="modal fade" id="exampleModaltwo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>
                <div className="col-12 px-2 pt-3">
  <img src={photograph == null ? img3 : photograph} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
              </div>
            </div>
          </div>  
                    <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered ">
                            <div className="modal-content">
                                <BsQuestionLg className='question-logo' />
                                {/* <i class="fa fa-exclamation" aria-hidden="true"></i> */}
                                <div className="modal-header mod-line">
                                    {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">

                                    <div className="row gy-3 mt-2">
                                        <h1 className='ccedit-h'>Confirm Submission</h1>
                                        <p className="ccedit-p">Are you sure you want to submit all the selected data?
                                            After submission you won’t be able to edit the list</p>
                                    </div>
                                </div>
                                <div className="modal-footer mod-line m-auto">
                                    <button type="button" className="btn closecancel">Cancel</button>
                                    <button type="button" className="btn closebtn text-white">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div></form>}
            </div>

            <div className="modal fade" id="exampleModal5" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>

<div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="tf2" className="form-label ">Right Thumb  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" class="form-control" id='tf2' name="image2" style={{opacity:"0",position:'absolute'}}  onChange={(e) => {handleImageChange11(e)}}  /> */}
        {/* <label htmlFor="tf"> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: thumbimg2 != '' && thumbimg2 != null ? '' : '3rem',width:"20%" }}> {thumbimg2 != '' && thumbimg2 != null ? <img src={`data:image/png;base64,${thumbimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>

      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="ltf2" className="form-label ">Left Thumb  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="ltf2" onChange={(e) => {handleImageChange16(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: ltfimg2 != '' && ltfimg2 != null ? '' : '3rem',width:"20%" }}> {ltfimg2 != '' && ltfimg2 != null ? <img src={`data:image/png;base64,${ltfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rif2" className="form-label ">Right Index Finger <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rif2" onChange={(e) => {handleImageChange12(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rifimg2 != '' && rifimg2 != null ? '' : '3rem',width:"20%" }}> {rifimg2 != '' && rifimg2 != null ? <img src={`data:image/png;base64,${rifimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="lif2" className="form-label ">Left Index Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lif2" onChange={(e) => {handleImageChange17(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lifimg2 != '' && lifimg2 != null ? '' : '3rem',width:"20%" }}> {lifimg2 != '' && lifimg2 != null ? <img src={`data:image/png;base64,${lifimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rmf2" className="form-label ">Right Middle Finger<span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rmf2" onChange={(e) => {handleImageChange13(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rmfimg2 != '' && rmfimg2 != null ? '' : '3rem',width:"20%" }}> {rmfimg2 != '' && rmfimg2 != null ? <img src={`data:image/png;base64,${rmfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      </div>
    </div>

  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="lmf2" className="form-label ">Left Middle Finger <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lmf2" onChange={(e) => {handleImageChange18(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lmfimg2 != '' && lmfimg2 != null ? '' : '3rem',width:"20%" }}> {lmfimg2 != '' && lmfimg2 != null ? <img src={`data:image/png;base64,${lmfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
        
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rrf2" className="form-label ">Right Ring Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rrf2" onChange={(e) => {handleImageChange14(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rrfimg2 != '' && rrfimg2 != null ? '' : '3rem',width:"20%" }}> {rrfimg2 != '' && rrfimg2 != null ? <img src={`data:image/png;base64,${rrfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
       
      </div>
    </div>

  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="lrf2" className="form-label ">Left Ring Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lrf2" onChange={(e) => {handleImageChange19(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lrfimg2 != '' && lrfimg2 != null ? '' : '3rem',width:"20%" }}> {lrfimg2 != '' && lrfimg2 != null ? <img src={`data:image/png;base64,${lrfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
        
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rlf2" className="form-label ">Right Little Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rlf2" onChange={(e) => {handleImageChange15(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rlfimg2 != '' && rlfimg2 != null ? '' : '3rem',width:"20%" }}> {rlfimg2 != '' && rlfimg2 != null ? <img src={`data:image/png;base64,${rlfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
     
      </div>
    </div>
  </div>


 
 
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="llf2" className="form-label ">Left Little Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="llf2" onChange={(e) => {handleImageChange20(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: llfimg2 != '' && llfimg2 != null ? '' : '3rem',width:"20%" }}> {llfimg2 != '' && llfimg2 != null ? <img src={`data:image/png;base64,${llfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      
      </div>
    </div>
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
    )
}

export default Ceditagent