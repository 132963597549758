import React from 'react'
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import img3 from "../../assets/KYC Logo white-bg 2.jpg";
import { FaShoppingCart } from "react-icons/fa";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { ImLocation2 } from "react-icons/im";
import { FaEnvelope,FaFacebookF,FaInstagram,FaTwitter,FaLinkedinIn } from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import PhoneInput from 'react-phone-number-input'
import Select from "react-select";
import { useState } from 'react';
import csc from 'country-state-city';
import { NavLink, useNavigate } from 'react-router-dom';
const Contact = () => {
    const [agentmobile, setagentmobile] = useState();
    const [agentcountry, setagentcountry] = useState(null);
    const [agentstate, setagentstate] = useState(null);
    const countries = csc.getAllCountries();


    const updatedCountries = countries.map((country) => ({
        label: country.name,
        value: country.id,
        ...country
      }));


    
    const submitform = ()=>{

    }



    const nvg = useNavigate()
const navigatefeature = (num) =>{
nvg('/',{state:{id:num}})
}

  return (
    <div style={{margin:'0px',padding:'0px'}}>

<section>
  <nav className="navbar navbar-expand-lg navbar-light bg-body p-0" aria-label="Eighth navbar example">
    <div className="container-fluid">
      <div className="img-fluid pb-1 imgdivsize">
        <NavLink to="/">
          <img src={img3} className='imgsize' alt="logo" /></NavLink>
      </div>
      <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample07" aria-controls="navbarsExample07" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarsExample07" style={{width:'61%'}}>
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
          <li className="nav-item">
            <p className="nav-link text-black" style={{cursor:'pointer',marginBlock:'0px'}} onClick={()=>{navigatefeature(0)}} role="button" >
            Features
            </p>
          </li>
          <li className="nav-item">
            <p className="nav-link text-black" style={{cursor:'pointer',marginBlock:'0px'}} onClick={()=>{navigatefeature(1)}}>Industries</p>
          </li>
          {/* <li className="nav-item">
            <p className="nav-link text-black" style={{cursor:'pointer',marginBlock:'0px'}} onClick={()=>{navigatefeature(2)}}>Register</p>
          </li> */}
          <li className="nav-item dropdown">
            <a className="nav-link text-black" href="/about">About </a>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link text-black" to="/contact">Contact Us</NavLink>
          </li>
        </ul>
        
        <div className=" p-1 d-flex" style={{gap:'4px'}}>
        <li className="nav-item">
            <p className="bg-white text-primary btn btn-outline-primary" style={{cursor:'pointer',marginBlock:'0px'}} onClick={()=>{navigatefeature(2)}}>Register</p>
          </li>
        <NavLink to="/login" className="btn btn-outline-primary">
            Log in
 </NavLink>
          {/* <button type="button" className='btn' >
            <NavLink to="/cart">
           <FaShoppingCart style={{fontSize:'24px',color:'red'}} />
           </NavLink>
          </button> */}
        </div>
      </div>
    </div>
  </nav>
</section>



<section className='superpadding'>
          <form autoComplete="off" onSubmit={submitform}>
<div className="container-fuild  bg-white" style={{overflow:'hidden'}}>
            <div className="row">
              <div className="col-md-5 col-none banner-contact " >

        <div class="react-overlay125" >
            <div class="container-fuild">
                <div class="row m-0 d-flex pb-1 justify-content-center">
                <div className="img-fluid pb-4 imgdivsize">
        {/* <a href="home.html">
          <img src={img3} className='imgsize123' alt="logo" style={{mixBlendMode:'darken'}} /></a> */}
      </div>
                   
                    <p className='desc345 pb-2'>Seamless customer onboarding and compliance adherence.</p>

                    <div className="contact_i">
            <div className="row mt-2">
              <div className="col-1" style={{color:'red'}} ><ImLocation2 /></div>
              <div className="col-lg-11 col-10  desc21">907 Clover Hill Plaza NIBM Road Kondhwa Pune 411048 MS</div>
            </div>

            <div className="row mt-3">
              <div className="col-1" style={{color:'red'}} ><FaEnvelope /></div>
              <div className="col-lg-11 col-10 desc21">Email:<a className=" text-decoration-none" href="mailto:legal@kycfirst.io">
               legal@kycfirst.io</a></div>
            </div>
            <div className="row mt-3">
              <div className="col-1" style={{color:'red'}} ><BsFillTelephoneFill /></div>
              <div className="col-lg-11 col-10 desc21"> Phone:<a className=" text-decoration-none" href="tel:8130515661">

                 +91 9730074506</a>
              </div>
            </div>
{/* <div className="row contact">
            <div className="col-12">
      <ul className="social-foo d-flex mt-4">
          <li><a href="#"><FaFacebookF /></a></li>
          <li><a href="#"><FaInstagram /></a></li>
          <li><a href="#"><FaTwitter/></a></li>
          <li><a href="#"><FaLinkedinIn /></a></li>
        </ul>
      </div>
      </div> */}
           
          
          </div>
                    {/* <div className='d-flex justify-content-center'>
                        
                        <button type="button" className="btn btn-outline-primary" style={{background:'transparent',border:'1.4px solid white',color:'white',fontWeight:'500'}}>
                    Get Started
          </button>
                        </div> */}
                </div>

            </div>
        </div>



              </div>
<div className="col-md-7 col-12">  <div
              className="row bg-white pb-4 px-3 round"
              style={{
                // border: "1px solid #E0E0E0",
                margin: "10px 0px",
                borderRadius: "3px",
                position:'relative'
              }}
            >
           
          
      


    
              
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-12">
                    <label htmlFor="" className="form-label">
                      First Name 
                    </label>
                  </div>
                  <div className="col-lg-12">
                    {/* <input type="text" className="form-control" placeholder='Gender' value={agentgender} onChange={(e) => {setagentgender(e.target.value)}} required /> */}
                    <input
                      type="text"
                      className="form-control"
                 
                      placeholder="Name"
                
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-12">
                    <label htmlFor="" className="form-label">
                      Last Name 
                    </label>
                  </div>
                  <div className="col-lg-12">
                    {/* <input type="text" className="form-control" placeholder='Gender' value={agentgender} onChange={(e) => {setagentgender(e.target.value)}} required /> */}
                    <input
                      type="text"
                      className="form-control"
                 
                      placeholder="Last Name"
                
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-12">
                    <label htmlFor="" className="form-label customw">
                      Email Address {" "}
                    </label>
                  </div>
                  <div className="col-lg-12">
                    <input
                      type="email"
                      className="form-control"
                   placeholder='Email'
                      required
                    />
             
                  </div>
                </div>
              </div>

              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-12">
                    <label htmlFor="" className="form-label">
                      Mobile No. 
                    </label>
                  </div>
                  <div className="col-lg-12">
                    <PhoneInput
placeholder="Enter phone number"
className="form-control"
value={agentmobile}
onChange={setagentmobile}
required
/>
                   
                  </div>
                </div>
              </div>


              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-12">
                    <label htmlFor="" className="form-label">
                    Company 
                    </label>
                  </div>
                  <div className="col-lg-12">
                   <select  className="form-select" aria-label="Default select example">
                    <option value="Please select corporate">select Company</option>
                    <option value="agent">axis</option>
                    <option value="company">jio</option>
                   </select>
                   
                  </div>
                </div>
              </div>
            
              <div className="col-md-6 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-12">
                    <label htmlFor="" className="form-label">
                      Country 
                    </label>
                  </div>
                  <div className="col-lg-12">
              
                    <Select
               placeholder="Select Country"
               options={updatedCountries}
               value={agentcountry}
               onChange={(e) => {
                setagentcountry(e);
              }}
               required
             />
                 
                  </div>
                </div>
              </div>


              <div className="col-md-12 px-2 pt-4">
                <div className="row">
                  <div className="col-lg-12">
                    <label htmlFor="" className="form-label">
                      Message 
                    </label>
                  </div>
                  <div className="col-lg-12">
              <textarea name="" id="" style={{width:'100%',height:'80px',border:'1px solid #ced4da'}}></textarea>
                 
                  </div>
                </div>
              </div>






      
            
              <div
        className="col-12 py-2 pt-5 px-4 d-flex justify-content-end"
        style={{ gap: "4px" }}
      >
        
        <button type='submit' className="btn5withoutradius">Submit</button>
      </div>
    
    
            </div></div>
            </div>
          



















        </div>
          </form>
</section>






























<footer className="footer" style={{marginTop: '5rem'}}>
  <div className="container-fluid">
    <div className="row">
      <div className="col-lg-3 col-md-6 col-12">
        <div className="footer-img pb-1">
          <img src={img3} style={{mixBlendMode:'darken'}} className='footwidth'  alt="logo" />
        </div>
      </div>
      {/* <div className="col-lg-2 col-md-6 col-12 ">
        <p className="customunder">Home</p>
        <p className="desc21 ">Industries</p>
      </div> */}
      <div className="col-lg-2 col-md-6 col-12 " style={{display:'flex',justifyContent:'center'}}>
        {/* <p className="customunder">Pricing</p> */}
        <p className="desc21 "><NavLink style={{color:'black'}} to='/contact'>Contact Us</NavLink></p>
      </div>
      <div className="col-lg-3 col-md-6 col-12 ">
     <p> <a style={{ color: "black" }} href="/about">About</a></p>
        <p className="desc21 "><a style={{color:'black'}} href="/privacypolicy"> Privacy & Policy</a></p>
        <p className="desc21"><a style={{color:'black'}} href="/termandcondition">Terms & Condition</a></p>
        <p className="desc21"><a style={{color:'black'}} href="/refund">Refund and Cancellation</a></p>
      </div>
      <div className="col-lg-4 col-md-6 col-12">
        <div className="single-footer">
          {/* <p className="customunder">Contact Us</p> */}
          <div className="contact_i">
            <div className="row mt-2">
              <div className="col-1" style={{color:'rgba(12, 83, 152, 1)'}} ><ImLocation2 /></div>
              <div className="col-10  desc21">907 Clover Hill Plaza NIBM Road Kondhwa Pune 411048 MS</div>
            </div>

            <div className="row mt-2">
              <div className="col-1" style={{color:'rgba(12, 83, 152, 1)'}} ><FaEnvelope /></div>
              <div className="col-10 desc21">Email:<a className=" text-decoration-none" href="mailto:legal@kycfirst.io">
               legal@kycfirst.io</a></div>
            </div>
            <div className="row mt-2">
              <div className="col-1" style={{color:'rgba(12, 83, 152, 1)'}} ><BsFillTelephoneFill /></div>
              <div className="col-10 desc21"> Phone:<a className=" text-decoration-none" href="tel:8130515661">

                 +91 9730074506</a>
              </div>
            </div>
           
          
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div className="row ">
      <div className="col-12" style={{display:'none'}}>
      <ul className="social-foo">
          <li style={{display:'flex',justifyContent:'center',alignItems:'center',width:'50px'}}><a href="#"><FaFacebookF /></a></li>
          <li style={{display:'flex',justifyContent:'center',alignItems:'center',width:'50px'}}><a href="#"><FaInstagram /></a></li>
          <li style={{display:'flex',justifyContent:'center',alignItems:'center',width:'50px'}}><a href="#"><FaTwitter/></a></li>
          <li style={{display:'flex',justifyContent:'center',alignItems:'center',width:'50px'}}><a href="#"><FaLinkedinIn /></a></li>
        </ul>
      </div>
      <div className="col-12 mt-1 mb-1">
        <p className="text-center desc2">© 2023 - All Right Reserved</p>
      </div>
    </div>
  </div>
</footer>








    </div>
  )
}

export default Contact