import React, { useEffect } from 'react'
import Header from '../../components/Header'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { Switch } from '@mui/material';
// import Switch from 'react-switch'
import { useState } from 'react';
import axios from 'axios';
import { gettoken } from '../../Localstorage/Store';
import { NavLink, useNavigate } from 'react-router-dom';
import Loadercomp from '../../components/Loadercomp';
import { useRef } from 'react';

const Addproduct = () => {
  const nvg = useNavigate()
  const [getresponse,setgetresponse] = useState({})
  const [name, setname] = useState('')
  const [logo, setlogo] = useState('')
  const [status, setstatus] = useState(true)
  const [response, setresponse] = useState()
  const [errorcls, seterrorcls] = useState({});
  const [loading, setloading] = useState(false);
  const gettokinval = gettoken()

  useEffect(()=>{
    if(gettokinval.staff == false){
      nvg('/dashboard')
    }
  })
async function submitform(e){
  e.preventDefault();
  setloading(true)
  const formdata = new FormData()
  formdata.append('product_name',name)
  formdata.append('logo',logo)
  // if(status == true){
  //   formdata.append('status',true)
  // }else{
  //   formdata.append('status',false)
  // }

 try {
  const config = {
    headers: {
      Authorization: `Bearer ${gettokinval.access}`,
    },
  };
  let url = `${process.env.REACT_APP_API_URL}api/product/`;
  const response = await axios.post(url, formdata, config);
    // console.log(response);
    setgetresponse(response);
  setname('')
  setlogo('')
  setstatus('')
  setloading(false)
 } catch (error) {
  // console.log('something went wrong try again',error)
  seterrorcls(error.response.data)
  setloading(false)
 }
 
}
const formRef = useRef(null);
const resetform = () =>{
  if (formRef.current) {
    formRef.current.reset(); // Reset the form using the reset() method
    setname('')
  }
}

  return (
    <div style={{width:'100%',minHeight:'100vh'}}>
      {/* <Header /> */}
    
      <div className="dashboardcontent px-2">
      {/* <Breadcup name={'Products'} /> */}
      {/* <div className="container py-4">
        
      </div> */}
      <div className="container-fuild px-2 desgin1 ">
      <div className="row bg-white ">
            {/* <div className="col-12 py-3 customtext23" style={{background: 'hsla(210, 85%, 32%, 0.2)',color:'#0C5398'}}>Add Product</div> */}
            <div className="col-lg-12 d-flex justify-content-between py-2" style={{ background: 'hsla(210, 85%, 32%, 0.2)', color: '#0C5398' }}>
              <p className="m-0 customfont" >Add Product</p>
              <div className="addnew d-block mb-2">
                <button className="btn text-white closebtn"><NavLink to='/productlist/0' style={{ textDecoration: 'none', color: 'white' }}> x Close</NavLink></button>
              </div>
            </div>
        </div>
      </div>
      <div className="container-fuild pb-4 pt-3 px-2 bg-white">
        <form ref={formRef} onSubmit={submitform} id='addproduct'>
       <div className="row bg-white pb-4 round" style={{border:'1px solid #E0E0E0',margin:"10px 0px",borderRadius: '3px'}}>
        <div className="col-md-6 px-4 pt-md-5 pt-4">
          <div className="row">
            <div className="col-lg-3">
              <label htmlFor="" className="form-label">
              Name <span style={{color:'red'}}>*</span>
              </label>
            </div>
            <div className="col-lg-9">
              <input type="text" className="form-control" placeholder='Product Name' name='product_name' value={name} onChange={(e) => {setname(e.target.value)}} required/>
              {errorcls ? errorcls.product_name ? <span style={{color:'red'}}> {errorcls.product_name[0]} </span> : '' : ''}
            </div>
          </div>
        </div>
        <div className="col-md-6 px-4 pt-md-5 pt-4">
          <div className="row">
            <div className="col-lg-3">
              <label htmlFor="" className="form-label customw">Product Logo <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-9">
            <input type="file" className="form-control" name='image' id="customFile"  onChange={(e) => {setlogo(e.target.files[0])}} />
            {errorcls ? errorcls.logo ? <span style={{color:'red'}}> {errorcls.logo[0]} </span> : '' : ''}
            </div>
          </div>
        </div>
        {/* <div className="col-md-6 px-4 pt-md-5 pt-4">
          <div className="row">
            <div className="col-lg-3">
              <label htmlFor="" className="form-label">Status <span style={{color:'red'}}>*</span></label>
            </div>
            <div className="col-lg-9">
            {status == true ? <Switch handleDiameter={30}
             value={status} onChange={(e) => {setstatus(!status)}} checked /> : <Switch handleDiameter={30}
             value={status} onChange={(e) => {setstatus(!status)}} /> }
            
            </div>
          </div>
        </div> */}
     <div className="col-md-12">
      <div className="row">
        <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"70%"}}>
        <div className='px-2'>{loading == true ?  <Loadercomp /> : ""}</div>
        </div>
        <div className="col-12 py-5 px-4 d-flex justify-content-end" style={{gap:'4px'}}>
          <button type='reset' onClick={()=>resetform()} className='btn4' >Cancel</button>
          <button type='submit' className='btn5'>Save</button>
        </div>
      </div>
     </div>
     <div className="col-12">
      <div className="row">
        <div className="col-12">
          {/* {getresponse.status === 201 ? <div class="alert alert-success" role="alert">
          successfully add
       </div> : <div></div>} */}
       {getresponse.status === 201 ? nvg('/productlist/1') : <div></div>}
       {getresponse.status === 203 ? <div class="alert alert-danger" role="alert">
           Something Went Wrong 
       </div> : <div></div>}
        </div>
      </div>
     </div>
      </div>
      </form>
      </div>
      </div>
    </div>
  )
}

export default Addproduct