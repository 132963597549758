// import React from 'react'
// import Breadcup from '../../components/Breadcup'
// import Header from '../../components/Header'
// import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import img1 from '../../assets/Group 40.png';
// import img2 from '../../assets/Group 41.png';
// import img3 from '../../assets/asdfg.png';
// import img4 from "../../assets/abc.png";
// import img5 from "../../assets/cba.png";
// import { NavLink, useParams } from 'react-router-dom';
// import { useState } from 'react';
// import { useEffect } from 'react';
// import axios from 'axios';
// import { gettoken } from '../../Localstorage/Store';
// import Loadercomp from '../../components/Loadercomp';
// import ReactImageMagnify from 'react-image-magnify';

// const Cremittancedetail = () => {


//   const {id} = useParams()
//   // const [data, setData] = useState({});
//   const gettokinval = gettoken();
//   const [fname, setfname] = useState();
//   const [lname, setlname] = useState();
//   const [mobile_no, setmobile_no] = useState();
//   const [gender, setgender] = useState("MALE");
//   const [emailID, setemailID] = useState();
//   const [dob, setdob] = useState();
//   const [age, setage] = useState();
//   const [company, setcompany] = useState("");
//   const [location, setlocation] = useState("");
//   const [address, setaddress] = useState();
//   const [country, setcountry] = useState();
//   const [state, setstate] = useState();
//   const [city, setcity] = useState();
//   const [pincode, setpincode] = useState();
//   const [transaction_no, settransaction_no] = useState();
//   const [selectedprod, setselectedprod] = useState();
//   const [photograph, setphotograph] = useState("");
//   const [cr_thumb, setcr_thumb] = useState("");
//   const [cr_index_finger, setcr_index_finger] = useState();
//   const [cr_middle_finger, setcr_middle_finger] = useState();
//   const [cr_ring_finger, setcr_ring_finger] = useState();
//   const [cr_little_finger, setcr_little_finger] = useState();
//   const [cl_thumb, setcl_thumb] = useState();
//   const [cl_index_finger, setcl_index_finger] = useState();
//   const [cl_middle_finger, setcl_middle_finger] = useState();
//   const [cl_ring_finger, setcl_ring_finger] = useState();
//   const [cl_little_finger, setcl_little_finger] = useState();
//   const [thumbimg2, setthumbimg2] = useState('');
//     const [rifimg2, setrifimg2] = useState('');
//     const [rmfimg2, setrmfimg2] = useState('');
//     const [rrfimg2, setrrfimg2] = useState('');
//     const [rlfimg2, setrlfimg2] = useState('');
//     const [ltfimg2, setltfimg2] = useState('');
//     const [lifimg2, setlifimg2] = useState('');
//     const [lmfimg2, setlmfimg2] = useState('');
//     const [lrfimg2, setlrfimg2] = useState('');
//     const [llfimg2, setllfimg2] = useState('');

//     const [thumbimg3, setthumbimg3] = useState('');
//     const [rifimg3, setrifimg3] = useState('');
//     const [rmfimg3, setrmfimg3] = useState('');
//     const [rrfimg3, setrrfimg3] = useState('');
//     const [rlfimg3, setrlfimg3] = useState('');
//     const [ltfimg3, setltfimg3] = useState('');
//     const [lifimg3, setlifimg3] = useState('');
//     const [lmfimg3, setlmfimg3] = useState('');
//     const [lrfimg3, setlrfimg3] = useState('');
//     const [llfimg3, setllfimg3] = useState('');
//   const [documenttype, setdocumenttype] = useState('PAN');
//   const [idnumber, setidnumber] = useState('DTTPA8683J');
//   const [documentimage, setdocumentimage] = useState('ASD');
//   const [documentissue, setdocumentissue] = useState('SD');
//   const [documentexpiry, setdocumentexpiry] = useState('ASD');
//   const [status, setstatus] = useState()
//   const [created_by, setcreated_by] = useState();
//   const [created_at, setcreated_at] = useState();
//   const [last_updated_by, setlast_updated_by] = useState();
//   const [last_updated_at, setlast_updated_at] = useState();
//   const [srtloader, setsrtloader] = useState(true);
//   const [customer, setcustomer] = useState();
//   const [customerphotograph, setcustomerphotograph] = useState("");
//   const [aadhaarImage, setaadhaarImage] = useState();
//   const [documentimagebacktwo, setdocumentimagebacktwo] = useState();
//   const [cthumbimg2, setcthumbimg2] = useState('');
//   const [crifimg2, setcrifimg2] = useState('');
//   const [crmfimg2, setcrmfimg2] = useState('');
//   const [crrfimg2, setcrrfimg2] = useState('');
//   const [crlfimg2, setcrlfimg2] = useState('');
//   const [cltfimg2, setcltfimg2] = useState('');
//   const [clifimg2, setclifimg2] = useState('');
//   const [clmfimg2, setclmfimg2] = useState('');
//   const [clrfimg2, setclrfimg2] = useState('');
//   const [cllfimg2, setcllfimg2] = useState('');
//   const [crtdate, setcrtdate] = useState('');

// //fetch user record ----> start
//   useEffect(() => {
//     async function fetchData() {
//       const config = {
//         headers: {
//           Authorization: `Bearer ${gettokinval.access}`,
//         },
//       };
//       let url = `${process.env.REACT_APP_API_URL}api/remittance/${id}/`;
//       const response = await axios.get(url, config);
//       console.log(response)
//       // setname(response.data.product_name)
//       // setlogohelp(response.data.logo)
//       setsrtloader(false)
//       setfname(response.data.customer_fname)
//       setlname(response.data.customer_lname)
//       setgender(response.data.customer_gender)
//       setmobile_no(response.data.customer_mobile_no)
//       setcompany(response.data.customer_company)
//       setlocation(response.data.customer_company_location)
//       setcountry(response.data.customer_country)
//       setstate(response.data.customer_state)
//       setaddress(response.data.customer_address)
//       setcustomer(response.data.customer)
//       setdob(response.data.customer_dob)
//       setage(response.data.customer_age)
//       setcity(response.data.customer_city)
//       setpincode(response.data.customer_pincode)
//       setemailID(response.data.customer_emailID)
//       setphotograph(response.data.photograph)
//       setcustomerphotograph(response.data.customer_photograph)
//       settransaction_no(response.data.transaction_no)
//       setselectedprod(response.data.product)
//       setidnumber(response.data.customer_document_no)
//         setdocumenttype(response.data.customer_document_type)
//         setdocumentexpiry(response.data.customer_doc_expiry_date)
//         setdocumentissue(response.data.customer_doc_issue_date)
//         setstatus(response.data.status)
//       setcreated_by(response.data.created_by)
//       setcreated_at(response.data.created_at)
//       setlast_updated_by(response.data.last_updated_by)
//       setlast_updated_at(response.data.last_updated_at)
//       setllfimg2(response.data.l_little_finger)
//       setlrfimg2(response.data.l_ring_finger)
//       setlmfimg2(response.data.l_middle_finger)
//       setlifimg2(response.data.l_index_finger)
//       setltfimg2(response.data.l_thumb)
//       setrlfimg2(response.data.r_little_finger)
//       setrrfimg2(response.data.r_ring_finger)
//       setrmfimg2(response.data.r_middle_finger)
//       setrifimg2(response.data.r_index_finger)
//       setthumbimg2(response.data.r_thumb)
//       setcllfimg2(response.data.customer_l_little_finger)
//       setclrfimg2(response.data.customer_l_ring_finger)
//       setclmfimg2(response.data.customer_l_middle_finger)
//       setclifimg2(response.data.customer_l_index_finger)
//       setcltfimg2(response.data.customer_l_thumb)
//       setcrlfimg2(response.data.customer_r_little_finger)
//       setcrrfimg2(response.data.customer_r_ring_finger)
//       setcrmfimg2(response.data.customer_r_middle_finger)
//       setcrifimg2(response.data.customer_r_index_finger)
//       setcthumbimg2(response.data.customer_r_thumb)
//       setdocumentimagebacktwo(response.data.customer_docImg_back)
//       setaadhaarImage(response.data.customer_docImg_front)
//       if(response.data.customer_dob !== undefined){
//         const newdate = () => {
//           // const num = parseInt(dateString)
//            const dateParts = response.data.customer_dob.split('-');
//            console.log("mmmmm",dateParts)
//            const year = parseInt(dateParts[0], 10);
//            const month = parseInt(dateParts[1], 10);
//            const day = parseInt(dateParts[2], 10);
         
//            const months = [
//              'January',
//              'February',
//              'March',
//              'April',
//              'May',
//              'June',
//              'July',
//              'August',
//              'September',
//              'October',
//              'November',
//              'December',
//            ];
         
//            const formattedDate = `${day} ${months[month - 1]}, ${year}`;
//            setcrtdate(formattedDate)
//          };
//          newdate();
//       }
//     }
//     fetchData();
//   }, []);
// //fetch user record ----> end

//   return (
//     <div style={{ width: '100%' }}>
//       {/* <Header /> */}
//       <div className="dashboardcontent px-2">
//         {/* <Breadcup name={'Remittance'} second={'Checking'} /> */}
       
//         <div className="container-fuild px-2 desgin1">
//           <div className="row bg-white ">
//             <div className="col-lg-12 d-flex justify-content-between py-2" style={{ background: 'hsla(210, 85%, 32%, 0.2)', color: '#0C5398' }}>
//               <p className="m-0 customfont" >Remittance Detail</p>
//               <div className="addnew d-block mb-2">
//                 <button className="btn text-white closebtn"><NavLink to='/checkremittancelist/0' style={{ textDecoration: 'none', color: 'white' }}> x Close</NavLink></button>
//               </div>
//             </div>
//             {/* <div className="col-12 py-3 customtext23" style={{background: 'hsla(210, 85%, 32%, 0.2)',color:'#0C5398'}}> Remittance Detail</div> */}
//           </div>
//         </div>
//         {srtloader == true ? <div className="container-fuild bg-white" style={{position:"relative"}}> <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"95%"}}>
//         <div className='px-2'> <Loadercomp size={100} /></div>
//         </div></div> :  <div className="container-fuild pb-4 pt-3 px-2 bg-white">
//        <div className="row bg-white pb-4 round" style={{border:'1px solid #E0E0E0',margin:"10px 0px",borderRadius: '3px'}}>
//         <div className="col-lg-6 px-4 pt-3">
//           <div className="row">
//             <div className="col-md-4 col-4">
//               <label htmlFor="" className="form-label">
//               Customer Id  
//               </label>
//             </div>
//             <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
//             <p className='customcolor'>{customer}</p>
//             </div>
//           </div>
//         </div>
//         <div className="col-lg-6 px-4 pt-3">
//           <div className="row">
//             <div className="col-md-4 col-4">
//               <label htmlFor="" className="form-label customw">Email Address  </label>
//             </div>
//             <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
//             <p className='customcolor'>{emailID}</p>
//             </div>
//           </div>
//         </div>
//         <div className="col-lg-6 px-4 pt-3">
//           <div className="row">
//             <div className="col-md-4 col-4">
//               <label htmlFor="" className="form-label">First Name </label>
//             </div>
//             <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
//             <p className='customcolor'>{fname}</p>
//             </div>
//           </div>
//         </div>
//         <div className="col-lg-6 px-4 pt-3">
//           <div className="row">
//             <div className="col-md-4 col-4">
//               <label htmlFor="" className="form-label">Last Name </label>
//             </div>
//             <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
//             <p className='customcolor'>{lname}</p>
//             </div>
//           </div>
//         </div>
//         <div className="col-lg-6 px-4 pt-3">
//           <div className="row">
//             <div className="col-md-4 col-4">
//               <label htmlFor="" className="form-label">Gender </label>
//             </div>
//             <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
//             <p className='customcolor'>{gender}</p>
//             </div>
//           </div>
//         </div>
//         <div className="col-lg-6 px-4 pt-3">
//           <div className="row">
//             <div className="col-md-4 col-4">
//               <label htmlFor="" className="form-label">Mobile No. </label>
//             </div>
//             <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
//             <p className='customcolor'>{mobile_no}</p>
//             </div>
//           </div>
//         </div>
//         <div className="col-lg-6 px-4 pt-3">
//           <div className="row">
//             <div className="col-md-4 col-4">
//               <label htmlFor="" className="form-label">Company </label>
//             </div>
//             <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
//             <p className='customcolor'>{company}</p>
//             </div>
//           </div>
//         </div>
//         <div className="col-lg-6 px-4 pt-3">
//           <div className="row">
//             <div className="col-md-4 col-4">
//               <label htmlFor="" className="form-label">Location </label>
//             </div>
//             <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
//             <p className='customcolor'>{location}</p>
//             </div>
//           </div>
//         </div>
//         <div className="col-lg-6 px-4 pt-3">
//           <div className="row">
//             <div className="col-md-4 col-4">
//               <label htmlFor="" className="form-label">DOB </label>
//             </div>
//             <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
//             <p className='customcolor'>{crtdate}</p>
//             </div>
//           </div>
//         </div>
//         <div className="col-lg-6 px-4 pt-3">
//           <div className="row">
//             <div className="col-md-4 col-4">
//               <label htmlFor="" className="form-label">Age </label>
//             </div>
//             <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
//             <p className='customcolor'>{age}</p>
//             </div>
//           </div>
//         </div>
//         <div className="col-lg-6 px-4 pt-3">
//           <div className="row">
//             <div className="col-md-4 col-4">
//               <label htmlFor="" className="form-label">Address </label>
//             </div>
//             <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
//             <p className='customcolor'>{address}</p>
//             </div>
//           </div>
//         </div>
//         <div className="col-lg-6 px-4 pt-3">
//           <div className="row">
//             <div className="col-md-4 col-4">
//               <label htmlFor="" className="form-label">Country </label>
//             </div>
//             <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
//             <p className='customcolor'>{country}</p>
//             </div>
//           </div>
//         </div>
//         <div className="col-lg-6 px-4 pt-3">
//           <div className="row">
//             <div className="col-md-4 col-4">
//               <label htmlFor="" className="form-label">State </label>
//             </div>
//             <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
//             <p className='customcolor'>{state}</p>
//             </div>
//           </div>
//         </div>
//         <div className="col-lg-6 px-4 pt-3">
//           <div className="row">
//             <div className="col-md-4 col-4">
//               <label htmlFor="" className="form-label">City  </label>
//             </div>
//             <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
//             <p className='customcolor'>{city}</p>
//             </div>
//           </div>
//         </div>
//         <div className="col-lg-6 px-4 pt-3">
//           <div className="row">
//             <div className="col-md-4 col-4">
//               <label htmlFor="" className="form-label">Pincode  </label>
//             </div>
//             <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
//             <p className='customcolor'>{pincode}</p>
//             </div>
//           </div>
//         </div>
//        <div className="col-12">
//        <div className="row px-4 pt-3">
//         <div className="col-md-6">
//           <div className="row">
//             <div className="col-12"><label htmlFor="" className="form-label">Biometric Detail </label></div>
//             <div className="col-12">
//             <div className="border round py-3 d-flex justify-content-center">
//                         <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
//      <label htmlFor="">Right Hand</label>
//      <img src={cthumbimg2 == '' || cthumbimg2 == null ? img5 : img4} alt="rtf" style={{position:"absolute",top:"80px",left:"13px"}} />
//      <img src={crifimg2 == '' || crifimg2 == null ? img5 : img4} alt="rif" style={{position:"absolute",top:"37px",left:"35px"}} />
//      <img src={crmfimg2 == '' || crmfimg2 == null ? img5 : img4} alt="rmf" style={{position:"absolute",top:"30px",left:"65px"}} />
//      <img src={crrfimg2 == '' || crrfimg2 == null ? img5 : img4} alt="rrf" style={{position:"absolute",top:"36px",left:"84px"}} />
//      <img src={crlfimg2 == '' || crlfimg2 == null ? img5 : img4} alt="rlf" style={{position:"absolute",top:"55px",left:"103px"}} />
//                           <img src={img1} alt="qwerty" />
//                           </div>
//                           <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
// <label htmlFor="">Left Hand</label>
// <img src={cllfimg2 == '' || cllfimg2 == null ? img5 : img4} alt="llf" style={{position:"absolute",top:"56px",left:"13px"}} />
//      <img src={clrfimg2 == '' || clrfimg2 == null ? img5 : img4} alt="lrf" style={{position:"absolute",top:"36px",left:"33px"}} />
//      <img src={clmfimg2 == '' || clmfimg2 == null ? img5 : img4} alt="lmf" style={{position:"absolute",top:"31px",left:"55px"}} />
//      <img src={clifimg2 == '' || clifimg2 == null ? img5 : img4} alt="lif" style={{position:"absolute",top:"38px",left:"80px"}} />
//      <img src={cltfimg2 == '' || cltfimg2 == null ? img5 : img4} alt="ltf" style={{position:"absolute",top:"80px",left:"102px"}} />
//                           <img src={img2} alt="trew" />
//                           </div>
//                         </div>
//                       </div>
//           </div>
//           <div className="col-12 py-2 px-4 d-flex justify-content-between">
//                 <button
//                 type='button'
//                   // className="btn1"
//                   style={{backgroundColor:"#0C5398"}}
//                   className='btn btn-primary'
//                   data-bs-toggle="modal"
//                   data-bs-target="#exampleModal6"
//                 >
//                   View
//                 </button>
//               </div>
//         </div>
//         <div className="col-md-6">
//         <div className="row">
//             <div className="col-12"><label htmlFor="" className="form-label">Photograph </label></div>
//             <div className="col-12 ">
//             <div className="border round py-3 d-flex justify-content-center">
//               {/* <img src={photograph} alt="zxcvbnm" width="140px" height="162px" /> */}
//               <button
//                 type='button'
//                 style={{border:'none',outline:'none'}}
//                   data-bs-toggle="modal"
//                   data-bs-target="#exampleModalthree"
//                 >
//               <img src={customerphotograph == null ? img3 : customerphotograph} alt="zxcvbnm" width="140px" height="162px" />
//               </button>
//               {/* <ReactImageMagnify
//                             {...{
//                                 smallImage: {
//                                     alt: 'Wristwatch by Versace',
//                                     isFluidWidth: true,
//                                     src: customerphotograph == null ? img3 : customerphotograph,
//                                     width:140,
//                                     height:162
//                                 },
//                                 largeImage: {
//                                     src: customerphotograph == null ? img3 : customerphotograph,
//                                     width: 1200,
//                                     height: 1800
//                                 },
//                                 // enlargedImagePosition: 'over',
//                                 lensStyle: { backgroundColor: 'rgba(0,0,0,.6)' }
//                             }}
                            
//                         /> */}
//              </div>
//             </div>
//           </div>
//         </div>
//        </div>
//        </div>
//      <div className="col-12">
//       <div className="row">
//         <div className="col-12 ccolor py-3 px-4">Document</div>
      
//         <div className="col-lg-6 px-4" style={{ paddingTop: "28px" }}>
//     <div className="row">
//       <div className="col-md-4 col-4">
//         <label htmlFor="" className="form-label">
//         Document Type 
//         </label>
//       </div>
//       <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end d-flex" style={{ gap: '22px' }}>





//                                     <p className='customcolor'>{documenttype}</p>
     
//       </div>
//     </div>
//   </div>


//   <div className="col-lg-6 px-4" style={{ paddingTop: "28px" }}>
//     <div className="row">
//       <div className="col-md-4 col-4">
//         <label htmlFor="" className="form-label">
//         Document No.
//         </label>
//       </div>
//       <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end d-flex" style={{ gap: '22px' }}>
//       <p className='customcolor'>{idnumber}</p>

//       </div>
//     </div>
//   </div>



// {documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT' ? <div className="col-lg-6 px-4">
//                             <div className="row">
//                                 <div className="col-md-4 col-4">
//                                     <label htmlFor="" className="form-label">Expiry date </label>
//                                 </div>
//                                 <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end d-flex" style={{ gap: '22px' }}>
//                                     <p className='customcolor'>{documentexpiry}</p>
//                                     <input class="form-check-input" type="checkbox"
//                                     value='2023-04-12'
//                         //              value={pincodecheck} onChange={(e) => {
//                         //     setpincodecheck(!pincodecheck);
//                         //   }} 
//                           id="flexCheckChecked" required />
//                                 </div>
//                             </div>
//                         </div> : ''}
//                         {documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT' ? <div className="col-lg-6 px-4">
//                             <div className="row">
//                                 <div className="col-md-4 col-4">
//                                     <label htmlFor="" className="form-label">Issue date </label>
//                                 </div>
//                                 <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end d-flex" style={{ gap: '22px' }}>
//                                     <p className='customcolor'>{documentissue}</p>
//                                     <input class="form-check-input" type="checkbox"
//                                     value='2023-04-12'
//                         //              value={pincodecheck} onChange={(e) => {
//                         //     setpincodecheck(!pincodecheck);
//                         //   }} 
//                           id="flexCheckChecked" required />
//                                 </div>
//                             </div>
//                         </div> : ''}
//                         <div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
//     <div className="row">
//       <div className="col-lg-4">
//         Document Front Image
//       </div>
//       <div className="col-lg-8">
//       <img src={aadhaarImage} alt="no found" style={{width:"100%",height:"100%"}} />
//       </div>
//       <div className=" offset-lg-4 col-lg-8">
//       <button
// style={{backgroundColor:"#0C5398",marginTop:"5px"}}
// type='button'
// className='btn btn-primary'
//                   data-bs-toggle="modal"
//                   data-bs-target="#exampleModal34"
//                 >
// View
//                 </button>
//       </div>
//     </div>
//   </div>
//         {documenttype == 'AADHAAR' ?<div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
//     <div className="row">
//       <div className="col-lg-4">
//         Document Back Image
//       </div>
//       <div className="col-lg-8">
//       <img src={documentimagebacktwo} alt="no found" style={{width:"100%",height:"100%"}} />
//       </div>
//       <div className=" offset-lg-4 col-lg-8">
//       <button
// style={{backgroundColor:"#0C5398",marginTop:"5px"}}
// type='button'
// className='btn btn-primary'
//                   data-bs-toggle="modal"
//                   data-bs-target="#exampleModal35"
//                 >
// View
//                 </button>
//       </div>
//     </div>
//   </div> : ''}
//         <div className="col-12 py-5 px-4">
//           <div style={{background:'#D9D9D9',height:'1px',width:'100%'}}></div>
//         </div>

//         <div className="col-lg-6 px-4 pt-3">
//           <div className="row">
//             <div className="col-md-4 col-4">
//               <label htmlFor="" className="form-label customw">Select Product  </label>
//             </div>
//             <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
//             <p className='customcolor ps-2'>{selectedprod}</p>
//             </div>
//           </div>
//         </div>
//         <div className="col-lg-6 px-4 pt-3">
//           <div className="row">
//             <div className="col-md-4 col-4">
//               <label htmlFor="" className="form-label customw">Transaction No. </label>
//             </div>
//             <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
//             <p className='customcolor ps-2' >{transaction_no}</p>
//             </div>
//           </div>
//         </div>
//         <div className="col-lg-6 px-4 pt-3">
//           <div className="row">
//             <div className="col-md-4 col-4">
//               <label htmlFor="" className="form-label customw">Created by </label>
//             </div>
//             <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
//             <p className='customcolor'>{created_by}</p>
//             </div>
//           </div>
//         </div>
//         <div className="col-lg-6 px-4 pt-3">
//           <div className="row">
//             <div className="col-md-4 col-4">
//               <label htmlFor="" className="form-label customw">Created Date & Time </label>
//             </div>
//             <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
//             <p className='customcolor'>{created_at}</p>
//             </div>
//           </div>
//         </div>
//         <div className="col-lg-6 px-4 pt-3">
//           <div className="row">
//             <div className="col-md-4 col-4">
//               <label htmlFor="" className="form-label customw">Last Modified by </label>
//             </div>
//             <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
//             <p className='customcolor'>{last_updated_by == null ? "NOT MODIFIED" : last_updated_by}</p>
//             </div>
//           </div>
//         </div>
//         <div className="col-lg-6 px-4 pt-3">
//           <div className="row">
//             <div className="col-md-4 col-4">
//               <label htmlFor="" className="form-label customw">Last Modified Date & Time   </label>
//             </div>
//             <div className="col-lg-6 col-md-7 col-8 d-flex justify-content-end">
//             <p className='customcolor'>{last_updated_at == null ? "NOT MODIFIED" : last_updated_at}</p>
//             </div>
//           </div>
//         </div>

//         <div className="col-12">
//        <div className="row px-4 pt-3">
//        <div className="col-md-6">
//           <div className="row">
//             <div className="col-12"><label htmlFor="" className="form-label">Biometric Detail <span style={{color:'red'}}>*</span></label></div>
//             <div className="col-12">
//                         <div className="border round py-3 d-flex justify-content-center">
//                         <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
//      <label htmlFor="">Right Hand</label>
//      <img src={thumbimg2 == '' || thumbimg2 == null ? img5 : img4} alt="rtf" style={{position:"absolute",top:"80px",left:"13px"}} />
//      <img src={rifimg2 == '' || rifimg2 == null ? img5 : img4} alt="rif" style={{position:"absolute",top:"37px",left:"35px"}} />
//      <img src={rmfimg2 == '' || rmfimg2 == null ? img5 : img4} alt="rmf" style={{position:"absolute",top:"30px",left:"65px"}} />
//      <img src={rrfimg2 == '' || rrfimg2 == null ? img5 : img4} alt="rrf" style={{position:"absolute",top:"36px",left:"84px"}} />
//      <img src={rlfimg2 == '' || rlfimg2 == null ? img5 : img4} alt="rlf" style={{position:"absolute",top:"55px",left:"103px"}} />
//                           <img src={img1} alt="qwerty" />
//                           </div>
//                           <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
// <label htmlFor="">Left Hand</label>
// <img src={llfimg2 == '' || llfimg2 == null ? img5 : img4} alt="llf" style={{position:"absolute",top:"56px",left:"13px"}} />
//      <img src={lrfimg2 == '' || lrfimg2 == null ? img5 : img4} alt="lrf" style={{position:"absolute",top:"36px",left:"33px"}} />
//      <img src={lmfimg2 == '' || lmfimg2 == null ? img5 : img4} alt="lmf" style={{position:"absolute",top:"31px",left:"55px"}} />
//      <img src={lifimg2 == '' || lifimg2 == null ? img5 : img4} alt="lif" style={{position:"absolute",top:"38px",left:"80px"}} />
//      <img src={ltfimg2 == '' || ltfimg2 == null ? img5 : img4} alt="ltf" style={{position:"absolute",top:"80px",left:"102px"}} />
//                           <img src={img2} alt="trew" />
//                           </div>
//                         </div>
//                       </div>
//                       <div className="col-12 py-2 px-4 d-flex justify-content-between">
//                 <button
//                 type='button'
//                   // className="btn1"
//                   style={{backgroundColor:"#0C5398"}}
//                   className='btn btn-primary'
//                   data-bs-toggle="modal"
//                   data-bs-target="#exampleModal5"
//                 >
//                   View
//                 </button>
//               </div>
//           </div>
//         </div>
//         <div className="col-md-6">
//         <div className="row">
//             <div className="col-12"><label htmlFor="" className="form-label">Photograph </label></div>
//             <div className="col-12 ">
//             <div className="border round py-3 d-flex justify-content-center">
//               {/* <img src={photograph == "" ? img3 : photograph} alt="zxcvbnm"  width="140px" height="162px"  /> */}
//               <button
//                 type='button'
//                 style={{border:'none',outline:'none'}}
//                   data-bs-toggle="modal"
//                   data-bs-target="#exampleModaltwo"
//                 >
//               <img src={photograph == null ? img3 : photograph} alt="zxcvbnm" width="140px" height="162px" />
//               </button>
//               {/* <ReactImageMagnify
//                             {...{
//                                 smallImage: {
//                                     alt: 'Wristwatch by Versace',
//                                     isFluidWidth: true,
//                                     src: photograph == null ? img3 : photograph,
//                                     width:140,
//                                     height:162
//                                 },
//                                 largeImage: {
//                                     src: photograph == null ? img3 : photograph,
//                                     width: 1200,
//                                     height: 1800
//                                 },
//                                 // enlargedImagePosition: 'over',
//                                 lensStyle: { backgroundColor: 'rgba(0,0,0,.6)' }
//                             }}
                            
//                         /> */}
//              </div>
//             </div>
//           </div>
//         </div>
//        </div>
       
//        </div>
//         {/* <div className="col-12 py-5 px-4 d-flex justify-content-end" style={{gap:'4px'}}>
//           <button className='btn4'>Cancel</button>
//           <button className='btn5'>Save</button>
//         </div> */}
//       </div>
//      </div>
//       </div>
//       </div>}
//       </div>


//       <div className="modal fade" id="exampleModalthree" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
//             <div className="modal-dialog modal-dialog-centered modal-xl">
//               <div className="modal-content">
//                 <div className="modal-header mod-line">
//                   <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//                 </div>
//                 <div className="modal-body">
//                 <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>
//                 <div className="col-12 px-2 pt-3">
//   <img src={customerphotograph == null ? img3 : customerphotograph} style={{width:"100%",height:"100%"}} alt="photograph" />
//   </div>
// </div>
//                 </div>
//               </div>
//             </div>
//           </div>  


//       <div className="modal fade" id="exampleModaltwo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
//             <div className="modal-dialog modal-dialog-centered modal-xl">
//               <div className="modal-content">
//                 <div className="modal-header mod-line">
//                   <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//                 </div>
//                 <div className="modal-body">
//                 <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>
//                 <div className="col-12 px-2 pt-3">
//   <img src={photograph == null ? img3 : photograph} style={{width:"100%",height:"100%"}} alt="photograph" />
//   </div>
// </div>
//                 </div>
//               </div>
//             </div>
//           </div>  


//           <div className="modal fade" id="exampleModal5" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
//             <div className="modal-dialog modal-dialog-centered modal-lg">
//               <div className="modal-content">
//                 <div className="modal-header mod-line">
//                   {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
//                   <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//                 </div>
//                 <div className="modal-body">

//                 <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>

// <div className="col-lg-6 px-2 pt-3">
//     <div className="row">
//       <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
//         <label htmlFor="tf2" className="form-label ">Right Thumb  <span style={{ color: 'red' }}>*</span></label>
//         {/* <input type="file" class="form-control" id='tf2' name="image2" style={{opacity:"0",position:'absolute'}}  onChange={(e) => {handleImageChange11(e)}}  /> */}
//         {/* <label htmlFor="tf"> */}
//         <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: thumbimg2 != '' && thumbimg2 != null ? '' : '3rem',width:"20%" }}> {thumbimg2 != '' && thumbimg2 != null ? <img src={`data:image/png;base64,${thumbimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>

//       </div>
//     </div>
//   </div>
//   <div className="col-lg-6 px-2 pt-3">
//     <div className="row">
//       <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
//         <label htmlFor="ltf2" className="form-label ">Left Thumb  <span style={{ color: 'red' }}>*</span></label>
//         {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="ltf2" onChange={(e) => {handleImageChange16(e)}} /> */}
//         <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: ltfimg2 != '' && ltfimg2 != null ? '' : '3rem',width:"20%" }}> {ltfimg2 != '' && ltfimg2 != null ? <img src={`data:image/png;base64,${ltfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
//       </div>
//     </div>
//   </div>
//   <div className="col-lg-6 px-2 pt-3">
//     <div className="row">
//       <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
//         <label htmlFor="rif2" className="form-label ">Right Index Finger <span style={{ color: 'red' }}>*</span></label>
//         {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rif2" onChange={(e) => {handleImageChange12(e)}} /> */}
//         <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rifimg2 != '' && rifimg2 != null ? '' : '3rem',width:"20%" }}> {rifimg2 != '' && rifimg2 != null ? <img src={`data:image/png;base64,${rifimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
//       </div>
//     </div>
//   </div>
//   <div className="col-lg-6 px-2 pt-3">
//     <div className="row">
//       <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
//         <label htmlFor="lif2" className="form-label ">Left Index Finger  <span style={{ color: 'red' }}>*</span></label>
//         {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lif2" onChange={(e) => {handleImageChange17(e)}} /> */}
//         <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lifimg2 != '' && lifimg2 != null ? '' : '3rem',width:"20%" }}> {lifimg2 != '' && lifimg2 != null ? <img src={`data:image/png;base64,${lifimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
//       </div>
//     </div>
//   </div>
//   <div className="col-lg-6 px-2 pt-3">
//     <div className="row">
//       <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
//         <label htmlFor="rmf2" className="form-label ">Right Middle Finger<span style={{ color: 'red' }}>*</span></label>
//         {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rmf2" onChange={(e) => {handleImageChange13(e)}} /> */}
//         <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rmfimg2 != '' && rmfimg2 != null ? '' : '3rem',width:"20%" }}> {rmfimg2 != '' && rmfimg2 != null ? <img src={`data:image/png;base64,${rmfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
//       </div>
//     </div>

//   </div>
//   <div className="col-lg-6 px-2 pt-3">
//     <div className="row">
//       <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
//         <label htmlFor="lmf2" className="form-label ">Left Middle Finger <span style={{ color: 'red' }}>*</span></label>
//         {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lmf2" onChange={(e) => {handleImageChange18(e)}} /> */}
//         <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lmfimg2 != '' && lmfimg2 != null ? '' : '3rem',width:"20%" }}> {lmfimg2 != '' && lmfimg2 != null ? <img src={`data:image/png;base64,${lmfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
        
//       </div>
//     </div>
//   </div>
//   <div className="col-lg-6 px-2 pt-3">
//     <div className="row">
//       <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
//         <label htmlFor="rrf2" className="form-label ">Right Ring Finger  <span style={{ color: 'red' }}>*</span></label>
//         {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rrf2" onChange={(e) => {handleImageChange14(e)}} /> */}
//         <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rrfimg2 != '' && rrfimg2 != null ? '' : '3rem',width:"20%" }}> {rrfimg2 != '' && rrfimg2 != null ? <img src={`data:image/png;base64,${rrfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
       
//       </div>
//     </div>

//   </div>
//   <div className="col-lg-6 px-2 pt-3">
//     <div className="row">
//       <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
//         <label htmlFor="lrf2" className="form-label ">Left Ring Finger  <span style={{ color: 'red' }}>*</span></label>
//         {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lrf2" onChange={(e) => {handleImageChange19(e)}} /> */}
//         <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lrfimg2 != '' && lrfimg2 != null ? '' : '3rem',width:"20%" }}> {lrfimg2 != '' && lrfimg2 != null ? <img src={`data:image/png;base64,${lrfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
        
//       </div>
//     </div>
//   </div>
//   <div className="col-lg-6 px-2 pt-3">
//     <div className="row">
//       <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
//         <label htmlFor="rlf2" className="form-label ">Right Little Finger  <span style={{ color: 'red' }}>*</span></label>
//         {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rlf2" onChange={(e) => {handleImageChange15(e)}} /> */}
//         <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rlfimg2 != '' && rlfimg2 != null ? '' : '3rem',width:"20%" }}> {rlfimg2 != '' && rlfimg2 != null ? <img src={`data:image/png;base64,${rlfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
     
//       </div>
//     </div>
//   </div>


 
 
//   <div className="col-lg-6 px-2 pt-3">
//     <div className="row">
//       <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
//         <label htmlFor="llf2" className="form-label ">Left Little Finger  <span style={{ color: 'red' }}>*</span></label>
//         {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="llf2" onChange={(e) => {handleImageChange20(e)}} /> */}
//         <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: llfimg2 != '' && llfimg2 != null ? '' : '3rem',width:"20%" }}> {llfimg2 != '' && llfimg2 != null ? <img src={`data:image/png;base64,${llfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      
//       </div>
//     </div>
//   </div>
// </div>
//                 </div>
//                 {/* <div className="modal-footer mod-line m-auto">
//                   <button type="button" className="btn closecancel">Cancel</button>
//                   <button type="button" className="btn closebtn text-white">Submit</button>
//                 </div> */}
//               </div>
//             </div>
//           </div>




//           <div className="modal fade" id="exampleModal34" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
//             <div className="modal-dialog modal-dialog-centered modal-xl">
//               <div className="modal-content">
//                 <div className="modal-header mod-line">
//                   {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
//                   <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//                 </div>
//                 <div className="modal-body">

//                 <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>


  
//   <div className="col-12 px-2 pt-3">
//   <img src={aadhaarImage == null ? img3 : aadhaarImage} style={{width:"100%",height:"100%"}} alt="photograph" />
//   </div>
// </div>
//                 </div>
//                 {/* <div className="modal-footer mod-line m-auto">
//                   <button type="button" className="btn closecancel">Cancel</button>
//                   <button type="button" className="btn closebtn text-white">Submit</button>
//                 </div> */}
//               </div>
//             </div>
//           </div>


//           <div className="modal fade" id="exampleModal35" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
//             <div className="modal-dialog modal-dialog-centered modal-xl">
//               <div className="modal-content">
//                 <div className="modal-header mod-line">
//                   {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
//                   <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//                 </div>
//                 <div className="modal-body">

//                 <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>


  
//   <div className="col-12 px-2 pt-3">
//   <img src={documentimagebacktwo == null ? img3 : documentimagebacktwo} style={{width:"100%",height:"100%"}} alt="photograph" />
//   </div>
// </div>
//                 </div>
//                 {/* <div className="modal-footer mod-line m-auto">
//                   <button type="button" className="btn closecancel">Cancel</button>
//                   <button type="button" className="btn closebtn text-white">Submit</button>
//                 </div> */}
//               </div>
//             </div>
//           </div>

//           <div className="modal fade" id="exampleModal6" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
//             <div className="modal-dialog modal-dialog-centered modal-lg">
//               <div className="modal-content">
//                 <div className="modal-header mod-line">
//                   {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
//                   <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//                 </div>
//                 <div className="modal-body">

//                 <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>

// <div className="col-6 px-2 pt-3">
//     <div className="row">
//       <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
//         <label htmlFor="tf2" className="form-label ">Right Thumb  <span style={{ color: 'red' }}>*</span></label>
//         {/* <input type="file" class="form-control" id='tf2' name="image2" style={{opacity:"0",position:'absolute'}}  onChange={(e) => {handleImageChange11(e)}}  /> */}
//         {/* <label htmlFor="tf"> */}
//         <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: cthumbimg2 != '' && cthumbimg2 != null ? '' : '3rem',width:"20%" }}> {cthumbimg2 != '' && cthumbimg2 != null ? <img src={`data:image/png;base64,${cthumbimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>

//       </div>
//     </div>
//   </div>
//   <div className="col-6 px-2 pt-3">
//     <div className="row">
//       <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
//         <label htmlFor="ltf2" className="form-label ">Left Thumb  <span style={{ color: 'red' }}>*</span></label>
//         {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="ltf2" onChange={(e) => {handleImageChange16(e)}} /> */}
//         <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: cltfimg2 != '' && cltfimg2 != null ? '' : '3rem',width:"20%" }}> {cltfimg2 != '' && cltfimg2 != null ? <img src={`data:image/png;base64,${cltfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
//       </div>
//     </div>
//   </div>
//   <div className="col-6 px-2 pt-3">
//     <div className="row">
//       <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
//         <label htmlFor="rif2" className="form-label ">Right Index Finger <span style={{ color: 'red' }}>*</span></label>
//         {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rif2" onChange={(e) => {handleImageChange12(e)}} /> */}
//         <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: crifimg2 != '' && crifimg2 != null ? '' : '3rem',width:"20%" }}> {crifimg2 != '' && crifimg2 != null ? <img src={`data:image/png;base64,${crifimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
//       </div>
//     </div>
//   </div>
//   <div className="col-6 px-2 pt-3">
//     <div className="row">
//       <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
//         <label htmlFor="lif2" className="form-label ">Left Index Finger  <span style={{ color: 'red' }}>*</span></label>
//         {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lif2" onChange={(e) => {handleImageChange17(e)}} /> */}
//         <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: clifimg2 != '' && clifimg2 != null ? '' : '3rem',width:"20%" }}> {clifimg2 != '' && clifimg2 != null ? <img src={`data:image/png;base64,${clifimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
//       </div>
//     </div>
//   </div>
//   <div className="col-6 px-2 pt-3">
//     <div className="row">
//       <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
//         <label htmlFor="rmf2" className="form-label ">Right Middle Finger<span style={{ color: 'red' }}>*</span></label>
//         {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rmf2" onChange={(e) => {handleImageChange13(e)}} /> */}
//         <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: crmfimg2 != '' && crmfimg2 != null ? '' : '3rem',width:"20%" }}> {crmfimg2 != '' && crmfimg2 != null ? <img src={`data:image/png;base64,${crmfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
//       </div>
//     </div>

//   </div>
//   <div className="col-6 px-2 pt-3">
//     <div className="row">
//       <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
//         <label htmlFor="lmf2" className="form-label ">Left Middle Finger <span style={{ color: 'red' }}>*</span></label>
//         {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lmf2" onChange={(e) => {handleImageChange18(e)}} /> */}
//         <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: clmfimg2 != '' && clmfimg2 != null ? '' : '3rem',width:"20%" }}> {clmfimg2 != '' && clmfimg2 != null ? <img src={`data:image/png;base64,${clmfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
        
//       </div>
//     </div>
//   </div>
//   <div className="col-6 px-2 pt-3">
//     <div className="row">
//       <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
//         <label htmlFor="rrf2" className="form-label ">Right Ring Finger  <span style={{ color: 'red' }}>*</span></label>
//         {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rrf2" onChange={(e) => {handleImageChange14(e)}} /> */}
//         <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: crrfimg2 != '' && crrfimg2 != null ? '' : '3rem',width:"20%" }}> {crrfimg2 != '' && crrfimg2 != null ? <img src={`data:image/png;base64,${crrfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
       
//       </div>
//     </div>

//   </div>
//   <div className="col-6 px-2 pt-3">
//     <div className="row">
//       <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
//         <label htmlFor="lrf2" className="form-label ">Left Ring Finger  <span style={{ color: 'red' }}>*</span></label>
//         {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lrf2" onChange={(e) => {handleImageChange19(e)}} /> */}
//         <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: clrfimg2 != '' && crrfimg2 != null ? '' : '3rem',width:"20%" }}> {clrfimg2 != '' && crrfimg2 != null ? <img src={`data:image/png;base64,${clrfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
        
//       </div>
//     </div>
//   </div>
//   <div className="col-6 px-2 pt-3">
//     <div className="row">
//       <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
//         <label htmlFor="rlf2" className="form-label ">Right Little Finger  <span style={{ color: 'red' }}>*</span></label>
//         {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rlf2" onChange={(e) => {handleImageChange15(e)}} /> */}
//         <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: crlfimg2 != '' && crrfimg2 != null ? '' : '3rem',width:"20%" }}> {crlfimg2 != '' && crrfimg2 != null ? <img src={`data:image/png;base64,${crlfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
     
//       </div>
//     </div>
//   </div>


 
 
//   <div className="col-6 px-2 pt-3">
//     <div className="row">
//       <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
//         <label htmlFor="llf2" className="form-label ">Left Little Finger  <span style={{ color: 'red' }}>*</span></label>
//         {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="llf2" onChange={(e) => {handleImageChange20(e)}} /> */}
//         <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: cllfimg2 != '' && cllfimg2 != null ? '' : '3rem',width:"20%" }}> {cllfimg2 != '' && cllfimg2 != null ? <img src={`data:image/png;base64,${cllfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      
//       </div>
//     </div>
//   </div>
// </div>
//                 </div>
//                 {/* <div className="modal-footer mod-line m-auto">
//                   <button type="button" className="btn closecancel">Cancel</button>
//                   <button type="button" className="btn closebtn text-white">Submit</button>
//                 </div> */}
//               </div>
//             </div>
//           </div>
//     </div>
//   )
// }

// export default Cremittancedetail























import React, { useRef } from 'react'
import Header from '../../components/Header'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import img1 from '../../assets/Group 40.png';
import img2 from '../../assets/Group 41.png';
import img3 from '../../assets/asdfg.png';
import img4 from "../../assets/abc.png";
import img5 from "../../assets/cba.png";
import img from "../../assets/KYC Logo white-bg 1.png";
import img690 from "../../assets/sign.jpg";
import { NavLink, useParams } from 'react-router-dom';
import { gettoken } from '../../Localstorage/Store';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import Loadercomp from '../../components/Loadercomp';
import ReactImageMagnify from 'react-image-magnify';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
const Cremittancedetail = () => {
  
  const {id} = useParams()
  // const [data, setData] = useState({});
  const gettokinval = gettoken();
  const [fname, setfname] = useState();
  const [lname, setlname] = useState();
  const [mobile_no, setmobile_no] = useState();
  const [gender, setgender] = useState("MALE");
  const [emailID, setemailID] = useState();
  const [company, setcompany] = useState("");
  const [location, setlocation] = useState("");
  const [address, setaddress] = useState();
  const [country, setcountry] = useState();
  const [doccountry, setdoccountry] = useState();
  const [formdatao, setformDatao] = useState({});

  const [state, setstate] = useState();
  const [city, setcity] = useState();
  const [pincode, setpincode] = useState();
  const [dob, setdob] = useState();
  const [transaction_no, settransaction_no] = useState();
  const [selectedprod, setselectedprod] = useState();
  const [photograph, setphotograph] = useState("");
  const [cr_thumb, setcr_thumb] = useState("");
  const [cr_index_finger, setcr_index_finger] = useState();
  const [cr_middle_finger, setcr_middle_finger] = useState();
  const [cr_ring_finger, setcr_ring_finger] = useState();
  const [cr_little_finger, setcr_little_finger] = useState();
  const [cl_thumb, setcl_thumb] = useState();
  const [cl_index_finger, setcl_index_finger] = useState();
  const [cl_middle_finger, setcl_middle_finger] = useState();
  const [cl_ring_finger, setcl_ring_finger] = useState();
  const [cl_little_finger, setcl_little_finger] = useState();
  const [thumbimg, setthumbimg] = useState('');
  const [rifimg, setrifimg] = useState('');
  const [rmfimg, setrmfimg] = useState('');
  const [rrfimg, setrrfimg] = useState('');
  const [rlfimg, setrlfimg] = useState('');
  const [ltfimg, setltfimg] = useState('');
  const [lifimg, setlifimg] = useState('');
  const [lmfimg, setlmfimg] = useState('');
  const [lrfimg, setlrfimg] = useState('');
  const [llfimg, setllfimg] = useState('');
  const [documenttypetwo, setdocumenttypetwo] = useState();
  const [idnumbertwo, setidnumbertwo] = useState();
  const [thumbimg2, setthumbimg2] = useState('');
  const [pcountry, setpcountry] = useState('');
  const [rifimg2, setrifimg2] = useState('');
  const [rmfimg2, setrmfimg2] = useState('');
  const [rrfimg2, setrrfimg2] = useState('');
  const [rlfimg2, setrlfimg2] = useState('');
  const [ltfimg2, setltfimg2] = useState('');
  const [lifimg2, setlifimg2] = useState('');
  const [lmfimg2, setlmfimg2] = useState('');
  const [lrfimg2, setlrfimg2] = useState('');
  const [llfimg2, setllfimg2] = useState('');
  const [status, setstatus] = useState()
  const [age, setage] = useState()
  const [created_by, setcreated_by] = useState();
  const [created_at, setcreated_at] = useState();
  const [last_updated_by, setlast_updated_by] = useState();
  const [modifiedImage, setmodifiedImage] = useState();
  const [backimage, setbackimage] = useState();
  const [last_updated_at, setlast_updated_at] = useState();
  const [srtloader, setsrtloader] = useState(true);
  const [customer, setcustomer] = useState();
  const [documenttype, setdocumenttype] = useState();
  const [idnumber, setidnumber] = useState();
  const [documentimage, setdocumentimage] = useState();
  const [documentissue, setdocumentissue] = useState();
  const [documentexpiry, setdocumentexpiry] = useState();
  const [customerphotograph, setcustomerphotograph] = useState();



  const [accountdata,setaccountdata] = useState([])
  const [addaccount, setaddaccount] = useState(false);
  const [accountno, setaccountno] = useState('');
  const [ifsccode, setifsccode] = useState('');
  const [accountname, setaccountname] = useState(null);
  const [alreadycreated, setalreadycreated] = useState(false);
  const [accountverified,setaccountverified] = useState(false)
  async function accountinfo(id) {

    const config = {
      headers: {
        Authorization: `Bearer ${gettokinval.access}`,
      },
    };
    let url = `${process.env.REACT_APP_API_URL}api/bank-accounts/get_by_id/${id}/`;
    const response = await axios.get(url, config);
    console.log(" lll;;;;;;",response)
    setaccountdata(response.data)
  
  if(response.data[0].beneficiaryAccount){
  setaddaccount(true);
  setalreadycreated(true)
  setaccountverified(true)
  setaccountno(response.data[0].beneficiaryAccount);
  setifsccode(response.data[0].beneIFSC);
  setaccountname(response.data[0].beneName);
  console.log("8900000",accountno,ifsccode,accountname)
  console.log("oppp")
  }else{
  setaddaccount(false);
  }
  }

  const [signed,setsigned] = useState('')


//fetch user record ----> start
  useEffect(() => {
    async function fetchData() {
      const config = {
        headers: {
          Authorization: `Bearer ${gettokinval.access}`,
        },
      };
      let url = `${process.env.REACT_APP_API_URL}api/remittance/${id}/`;
      const response = await axios.get(url, config);
      // console.log(response)
      // setname(response.data.product_name)
      // setlogohelp(response.data.logo)
      setsrtloader(false)
      setformDatao(response.data)
      setfname(response.data.customer_fname)
      setlname(response.data.customer_lname)
      setgender(response.data.customer_gender)
      setmobile_no(response.data.customer_mobile_no)
      setcompany(response.data.customer_company)
      setlocation(response.data.customer_company_location)
      setcountry(response.data.customer_country)
      setdoccountry(response.data.customer_country_doc2)
      setstate(response.data.customer_state)
      setaddress(response.data.customer_address)
      setcustomer(response.data.customer_id)
      setidnumber(response.data.customer_document_no)
      setdocumenttype(response.data.customer_document_type)
      setcustomerphotograph(response.data.customer_photograph)
      setdocumentexpiry(response.data.expiry_date)
      setdocumentissue(response.data.issue_date)
      setdob(response.data.customer_dob)
      setage(response.data.customer_age)
      setcity(response.data.customer_city)
      setpincode(response.data.customer_pincode)
      setemailID(response.data.customer_emailID)
      setphotograph(response.data.photograph)
      settransaction_no(response.data.transaction_no)
      setselectedprod(response.data.product)
        setstatus(response.data.status)
        setpcountry(response.data.country)
      setcreated_by(response.data.created_by)
      setcreated_at(response.data.created_at)
      setlast_updated_by(response.data.last_updated_by)
      setlast_updated_at(response.data.last_updated_at)
      setllfimg2(response.data.l_little_finger)
      setlrfimg2(response.data.l_ring_finger)
      setlmfimg2(response.data.l_middle_finger)
      setlifimg2(response.data.l_index_finger)
      setltfimg2(response.data.l_thumb)
      setrlfimg2(response.data.r_little_finger)
      setrrfimg2(response.data.r_ring_finger)
      setrmfimg2(response.data.r_middle_finger)
      setrifimg2(response.data.r_index_finger)
      setthumbimg2(response.data.r_thumb)
      setidnumbertwo(response.data.customer_document_no2)
      setdocumenttypetwo(response.data.customer_document_type2)
      setmodifiedImage(response.data.customer_docImg_front)
      setbackimage(response.data.customer_docImg_back)
      accountinfo(response.data.customer);
      setsigned(response.data.transaction_image)
    }
    fetchData();
  }, []);
//fetch user record ----> end

const getheight = useRef(null);
// console.log(window)

// const handleDownload = () => {
//   const input = document.getElementById('pdf-content');

//   html2canvas(input).then((canvas) => {
//     const imgData = canvas.toDataURL('image/png');
//     const pdf = new jsPDF();
//     const imgProps = pdf.getImageProperties(imgData);
//     const pdfWidth = pdf.internal.pageSize.getWidth();
//     const pdfHeight = (getheight.current.clientHeight * pdfWidth) / imgProps.width;

//     pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
//     pdf.save('download.pdf');
//   });
// };

const handleDownload = async () => {
  const pdf = new jsPDF();

  const sections = document.querySelectorAll('.pdf-section');

  const options = {
    scale: 2, // Adjust the scale for better image quality
    useCORS: true, // Enable CORS for images
  };

  for (const [index, section] of sections.entries()) {
    const canvas = await html2canvas(section, options);
    const imageData = canvas.toDataURL('image/png');

    if (index > 0) {
      pdf.addPage();
    }

    pdf.addImage(imageData, 'PNG', 0, 0,pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight()); // You can adjust the positioning and size of the image here
  }

  pdf.save('document.pdf');
};


  return (
    <div style={{width:'100%'}}>
      {/* <Header /> */}
      <div className="dashboardcontent px-2">
      {/* <Breadcup name={'Remittance'} /> */}
      {/* <div className="container py-4">
        
      </div> */}
      <div className="container-fuild px-2 desgin1">
      <div className="row bg-white ">
            {/* <div className="col-12 py-3 customtext23" style={{background: 'hsla(210, 85%, 32%, 0.2)',color:'#0C5398'}}> Remittance Detail</div> */}
            <div className="col-lg-12 d-flex justify-content-between py-2" style={{ background: 'hsla(210, 85%, 32%, 0.2)', color: '#0C5398' }}>
              <p className="m-0 customfont" >Remittance Checking Detail</p>
              <div className="addnew d-flex mb-2" style={{gap:"4px"}}>
              {/* <button className="btn text-white closebtn" onClick={handleDownload}> PDF</button> */}
                <button className="btn text-white closebtn"><NavLink to='/checkremittancelist/0' style={{ textDecoration: 'none', color: 'white' }}> x Close</NavLink></button>
              </div>
            </div>
        </div>
      </div>
      {srtloader == true ? <div className="container-fuild bg-white"> <div className="col-12 d-flex justify-content-center" style={{gap:'4px' , position:'absolute',width:"90%"}}>
        <div className='px-2'> <Loadercomp size={100} /></div>
        </div></div> :  <div className="container-fuild pb-4 pt-3 px-2 bg-white" id="pdf-content" ref={getheight}>
       <div className="row bg-white pb-4 round" style={{border:'1px solid #E0E0E0',margin:"10px 0px",borderRadius: '3px',position:'relative'}}>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-lg-4 col-4">
              <label htmlFor="" className="form-label">
              Customer Id  
              </label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor'>{customer}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label customw">Email Address  </label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor'>{emailID}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">First Name </label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor'>{fname}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Last Name </label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor'>{lname}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Gender </label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor'>{gender}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Mobile No. </label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor'>{mobile_no}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Company </label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor'>{company}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Location </label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor'>{location}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">DOB </label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor'>{dob}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Age </label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor'>{age}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Address </label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor'>{address}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Country </label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor'>{country}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">State </label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor'>{state}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">City  </label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor'>{city}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label">Pincode  </label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor'>{pincode}</p>
            </div>
          </div>
        </div>
       <div className="col-12">
       <div className="row px-4 pt-3">
       <div className="col-md-6">
          <div className="row">
            <div className="col-12"><label htmlFor="" className="form-label">Biometric Detail <span style={{color:'red'}}>*</span></label></div>
            <div className="col-12">
                        <div className="border round py-3 d-flex justify-content-center">
                        <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
     <label htmlFor="">Right Hand</label>
     <img src={formdatao.r_thumb == null || formdatao.r_thumb == "" ? img5 : img4} alt="rtf" style={{position:"absolute",top:"80px",left:"13px"}} />
     <img src={formdatao.r_index_finger == null || formdatao.r_index_finger == "" ? img5 : img4} alt="rif" style={{position:"absolute",top:"37px",left:"35px"}} />
     <img src={formdatao.r_middle_finger == null || formdatao.r_middle_finger == "" ? img5 : img4} alt="rmf" style={{position:"absolute",top:"30px",left:"65px"}} />
     <img src={formdatao.r_ring_finger == null || formdatao.r_ring_finger == "" ? img5 : img4} alt="rrf" style={{position:"absolute",top:"36px",left:"84px"}} />
     <img src={formdatao.r_little_finger == null || formdatao.r_little_finger == "" ? img5 : img4} alt="rlf" style={{position:"absolute",top:"55px",left:"103px"}} />
                          <img src={img1} alt="qwerty" />
                          </div>
                          <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
<label htmlFor="">Left Hand</label>
<img src={formdatao.l_little_finger == null || formdatao.l_little_finger == "" ? img5 : img4} alt="llf" style={{position:"absolute",top:"56px",left:"13px"}} />
     <img src={formdatao.l_ring_finger == null || formdatao.l_ring_finger == "" ? img5 : img4} alt="lrf" style={{position:"absolute",top:"36px",left:"33px"}} />
     <img src={formdatao.l_middle_finger == null || formdatao.l_middle_finger == "" ? img5 : img4} alt="lmf" style={{position:"absolute",top:"31px",left:"55px"}} />
     <img src={formdatao.l_index_finger == null || formdatao.l_index_finger == "" ? img5 : img4} alt="lif" style={{position:"absolute",top:"38px",left:"80px"}} />
     <img src={formdatao.l_thumb == null || formdatao.l_thumb == "" ? img5 : img4} alt="ltf" style={{position:"absolute",top:"80px",left:"102px"}} />
                          <img src={img2} alt="trew" />
                          </div>
                        </div>
                      </div>
          </div>
          <div className="col-12 py-2 px-4 d-flex justify-content-between">
                <button
                type='button'
                style={{backgroundColor:"#0C5398"}}
                className='btn btn-primary'
                  // className="btn1"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal3"
                >
                  View
                </button>
              </div>
        </div>
        <div className="col-md-6">
        <div className="row">
            <div className="col-12"><label htmlFor="" className="form-label">Photograph </label></div>
            <div className="col-12 ">
            <div className="border round py-3 d-flex justify-content-center">
            <button
                type='button'
                style={{border:'none',outline:'none'}}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalthree"
                >
              <img src={customerphotograph == null ? img3 : customerphotograph} alt="zxcvbnm" width="140px" height="162px" />
              </button>
            {/* <ReactImageMagnify
                            {...{
                                smallImage: {
                                    alt: 'Wristwatch by Versace',
                                    isFluidWidth: true,
                                    src: customerphotograph == null ? img3 : customerphotograph,
                                    width:140,
                                    height:162
                                },
                                largeImage: {
                                    src: customerphotograph == null ? img3 : customerphotograph,
                                    width: 836,
                                    height: 1100
                                },
                                // enlargedImagePosition: 'over',
                                lensStyle: { backgroundColor: 'rgba(0,0,0,.6)' }
                            }}
                            
                        /> */}
              {/* <img src={img3} alt="zxcvbnm" width="140px" height="162px" /> */}
             </div>
            </div>
          </div>
        </div>
       </div>
       </div>
     <div className="col-12">
      <div className="row">
        <div className="col-12 ccolor py-3 px-4">Document</div>

        { documenttype == "AADHAAR" || documenttype == "AADHAR CARD" || documenttype == "" ? formdatao.customer_Form_60_pdf != null && formdatao.customer_Form_60_pdf != "" ? <div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        Form 60 PDF
      </div>

      <div className=" col-lg-8">
      {formdatao.customer_Form_60_pdf == null || formdatao.customer_Form_60_pdf == ''? '' : <a href={formdatao.customer_Form_60_pdf} style={{backgroundColor:"#0C5398",marginLeft:"5px"}}
type='button'
className='btn btn-primary' target="_blank" rel="noopener noreferrer"> View pdf</a>}
      </div>
    </div>
  </div> : '' : ''}

  { documenttype == "AADHAAR" || documenttype == "AADHAR CARD" || documenttype == "" ? formdatao.customer_Form_60_image != null && formdatao.customer_Form_60_image != "" ? <div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        Form 60 Image
      </div>
      <div className="col-lg-8">
      <img src={formdatao.customer_Form_60_image} alt="no found" style={{width:"100%",height:"100%"}} />
      </div>
      <div className=" offset-lg-4 col-lg-8">
      <button
style={{backgroundColor:"#0C5398",marginTop:"5px"}}
type='button'
className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal35"
                >
View
                </button>
      </div>
    </div>
  </div> : '' : ''}
  {documenttype == "AADHAAR" || documenttype == "AADHAR CARD" || documenttype == "" ? '' : <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
        Document Type 
        </label>
      </div>
      <div className="col-lg-8">
      <p className='customcolor ps-4'>{documenttype}</p>
      {/* <input type="text" className="form-control" value={documenttype} /> */}
      </div>
    </div>
  </div> }


  {documenttype == "AADHAAR" || documenttype == "AADHAR CARD" || documenttype == "" ? '' : <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
        Document No. 
        </label>
      </div>
      <div className="col-lg-8">
      {/* <input type="text" className="form-control" value={idnumber}  /> */}
      <p className='customcolor ps-4'>{idnumber}</p>
      </div>
    </div>
  </div>}



  {documenttype == "AADHAAR" || documenttype == "AADHAR CARD" || documenttype == "" ? '' : documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT' ? <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
                <div className="row">
                  <div className="col-lg-4">
                    <label htmlFor="" className="form-label">
                      Expiry date 
                    </label>
                  </div>
                  <div className="col-lg-8">
                    {/* <input
                      type="date"
                      className="form-control"
                      value={formdatao.customer_doc_expiry_date}
                      // placeholder="State"
                      // onChange={(e) => {
                      //   setstate(e.target.value);
                      // }}
                      // required
                    /> */}
                    {/* {errorcls ? (
                      errorcls.state ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.state[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )} */}
<p className='customcolor ps-4'>{formdatao.customer_doc_expiry_date}</p>
                  </div>
                </div>
              </div> : ''}
              {documenttype == "AADHAAR" || documenttype == "AADHAR CARD" || documenttype == "" ? '' : documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT' ? <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
                <div className="row">
                  <div className="col-lg-4 ">
                    <label htmlFor="" className="form-label">
                      Issue date 
                    </label>
                  </div>
                  <div className="col-lg-8">
                    {/* <input
                      type="date"
                      className="form-control"
                      value={formdatao.customer_doc_issue_date}
                      placeholder="Issue"
                      // onChange={(e) => {
                      //   setstate(e.target.value);
                      // }}
                      // required
                    /> */}
                    <p className='customcolor ps-4'>{formdatao.customer_doc_issue_date}</p>
                    {/* {errorcls ? (
                      errorcls.state ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.state[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
              </div> :''}
              {documenttype == "AADHAAR" || documenttype == "AADHAR CARD" || documenttype == "" ? '' : documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT' ? <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
                <div className="row">
                  <div className="col-lg-4 ">
                    <label htmlFor="" className="form-label">
                      Country 
                    </label>
                  </div>
                  <div className="col-lg-8">
                  
                 
                    <p className='customcolor ps-4'>{formdatao.customer_country_doc2}</p>
                
                  </div>
                </div>
              </div> :''}
              {documenttype == "AADHAAR" || documenttype == "AADHAR CARD" || documenttype == "" ? '' : documenttype == 'DRIVING_LICENSE' || documenttype == 'PASSPORT' ? <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
                {/* <div className="row">
                  <div className="col-lg-4 ">
                    <label htmlFor="" className="form-label">
                      Country 
                    </label>
                  </div>
                  <div className="col-lg-8">
                  
                 
                    <p className='customcolor ps-4'>{formdatao.customer_country_doc2}</p>
                
                  </div>
                </div> */}
              </div> :''}


              {documenttype == "AADHAAR" || documenttype == "AADHAR CARD" || documenttype == "" ? '' : <div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        Document Front Image
      </div>
      <div className="col-lg-8">
      <img src={formdatao.customer_docImg_front} alt="no found" style={{width:"100%",height:"100%"}} />
      </div>
      <div className=" offset-lg-4 col-lg-8">
      <button
style={{backgroundColor:"#0C5398",marginTop:"5px"}}
type='button'
className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal34"
                >
View
                </button>
      </div>
    </div>
  </div>}


  {documenttype == "AADHAAR" || documenttype == "AADHAR CARD" || documenttype == "" ? '' : <div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        Document Back Image
      </div>
      <div className="col-lg-8">
      <img src={formdatao.customer_docImg_back} alt="no found" style={{width:"100%",height:"100%"}} />
      </div>
      <div className=" offset-lg-4 col-lg-8">
      <button
style={{backgroundColor:"#0C5398",marginTop:"5px"}}
type='button'
className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal35"
                >
View
                </button>
      </div>
    </div>
  </div> }


  <div className="col-12 ccolor py-2 px-4">Document 2</div>
        <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
        Document Type 
        </label>
      </div>
      <div className="col-lg-8">

      {/* <input type="text" className="form-control" value={documenttypetwo} /> */}
      <p className='customcolor ps-4'>{documenttypetwo}</p>
      </div>
    </div>
  </div>


  <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        <label htmlFor="" className="form-label">
        Document No. 
        </label>
      </div>
      <div className="col-lg-8">
      {/* <input type="text" className="form-control" value={idnumbertwo}  /> */}
      <p className='customcolor ps-4'>{idnumbertwo}</p>
      </div>
    </div>
  </div>



  {documenttypetwo == 'DRIVING_LICENSE' || documenttypetwo == "RESIDENT_PERMIT" || documenttypetwo == "VISA" || documenttypetwo == "REFUGEE_CARD" || documenttypetwo == 'PASSPORT' ? <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
                <div className="row">
                  <div className="col-lg-4">
                    <label htmlFor="" className="form-label">
                      Expiry date 
                    </label>
                  </div>
                  <div className="col-lg-8">
                    {/* <input
                      type="date"
                      className="form-control"
                      value={formdatao.customer_doc_expiry_date2}
                      // placeholder="State"
                      // onChange={(e) => {
                      //   setstate(e.target.value);
                      // }}
                      // required
                    /> */}
                    <p className='customcolor ps-4'>{formdatao.customer_doc_expiry_date2}</p>
                    {/* {errorcls ? (
                      errorcls.state ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.state[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )} */}

                  </div>
                </div>
              </div> : ''}
              {documenttypetwo == 'DRIVING_LICENSE' || documenttypetwo == "RESIDENT_PERMIT" || documenttypetwo == "VISA" || documenttypetwo == "REFUGEE_CARD" || documenttypetwo == 'PASSPORT' ? <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
                <div className="row">
                  <div className="col-lg-4 ">
                    <label htmlFor="" className="form-label">
                      Issue date 
                    </label>
                  </div>
                  <div className="col-lg-8">
                    {/* <input
                      type="date"
                      className="form-control"
                      value={formdatao.customer_doc_issue_date2}
                      placeholder="Issue"
                      // onChange={(e) => {
                      //   setstate(e.target.value);
                      // }}
                      // required
                    /> */}
                    <p className='customcolor ps-4'>{formdatao.customer_doc_issue_date2}</p>
                    {/* {errorcls ? (
                      errorcls.state ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          {errorcls.state[0]}{" "}
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
              </div> :''}
              <div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        Document Front Image
      </div>
      <div className="col-lg-8">
      <img src={formdatao.customer_docImg_front2} alt="no found" style={{width:"100%",height:"100%"}} />
      </div>
      <div className=" offset-lg-4 col-lg-8">
      <button
style={{backgroundColor:"#0C5398",marginTop:"5px"}}
type='button'
className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal34"
                >
View
                </button>
      </div>
    </div>
  </div>
    <div className="offset-col-6 col-md-6 px-4" style={{ paddingTop: "28px" }}>
    <div className="row">
      <div className="col-lg-4">
        Document Back Image
      </div>
      <div className="col-lg-8">
      <img src={formdatao.customer_docImg_back2} alt="no found" style={{width:"100%",height:"100%"}} />
      </div>
      <div className=" offset-lg-4 col-lg-8">
      <button
style={{backgroundColor:"#0C5398",marginTop:"5px"}}
type='button'
className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal35"
                >
View
                </button>
      </div>
    </div>
  </div>





  {addaccount == true ?  <div className="col-12 py-3 px-4">
        
        <div
          style={{
            background: "#D9D9D9",
            height: "1px",
            width: "100%",
          }}
        ></div>
      </div> : ''}
  {addaccount == true ? <div className="col-12 ccolor py-3 px-4">Bank Account Verify</div> : ''}

{addaccount == true ? <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
<div className="row">
 <div className="col-lg-4">
   <label htmlFor="" className="form-label">
      Account No. 
   </label>
 </div>
 <div className="col-lg-8">
            <p className='customcolor' style={{textAlign:'end'}}>{accountno}</p>

 </div>
</div>
</div> : "" } 

{addaccount == true ? <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
<div className="row">
 <div className="col-lg-4">
   <label htmlFor="" className="form-label ">
   IFSC Code 
   </label>
 </div>
 <div className="col-lg-8">
            <p className='customcolor ' style={{textAlign:'end'}}>{ifsccode}</p>


 </div>
</div>
</div> : ""}
{addaccount == true ? accountname == null ? "" : <div className="col-md-6 px-4" style={{ paddingTop: "28px" }}>
<div className="row">
 <div className="col-lg-4">
   <label htmlFor="" className="form-label">
   Holder Name 
   </label>
 </div>
 <div className="col-lg-8">

            <p className='customcolor' style={{textAlign:'end'}}>{accountname}</p>
 </div>
</div>
</div> : ""}
        {/* <div className="col-12 py-5 px-4 d-flex justify-content-between">
          <button className='btn1'>Add</button>
          <button className='btn2'>Submit</button>
        </div> */}
        <div className="col-12 py-5 px-4">
          <div style={{background:'#D9D9D9',height:'1px',width:'100%'}}></div>
        </div>

        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label customw">Select Product  </label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor ps-2'>{selectedprod}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-6 col-4">
              <label htmlFor="" className="form-label ">Transaction No. </label>
            </div>
            <div className="col-lg-6 col-md-6 col-8 d-flex justify-content-end">
            <p className='customcolor ' >{transaction_no}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label customw">Country  </label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor ps-2'>{pcountry}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-6 col-4">
              <label htmlFor="" className="form-label customw">Created by </label>
            </div>
            <div className="col-lg-6 col-md-6 col-8 d-flex justify-content-end">
            <p className='customcolor'>{created_by}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label ">Created Date & Time </label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor'>{created_at}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-6 col-4">
              <label htmlFor="" className="form-label ">Last Modified by </label>
            </div>
            <div className="col-lg-6 col-md-6 col-8 d-flex justify-content-end">
            <p className='customcolor'>{last_updated_by == null ? "NOT MODIFIED" : last_updated_by}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4 pt-3">
          <div className="row">
            <div className="col-md-4 col-4">
              <label htmlFor="" className="form-label ">Last Modified Date & Time   </label>
            </div>
            <div className="col-lg-6 col-md-8 col-8 d-flex justify-content-end">
            <p className='customcolor'>{last_updated_at == null ? "NOT MODIFIED" : last_updated_at}</p>
            </div>
          </div>
        </div>

        <div className="col-12">
       <div className="row px-4 pt-3">
       <div className="col-md-6">
          <div className="row">
            <div className="col-12"><label htmlFor="" className="form-label">Biometric Detail <span style={{color:'red'}}>*</span></label></div>
            <div className="col-12">
                        <div className="border round py-3 d-flex justify-content-center">
                        <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
     <label htmlFor="">Right Hand</label>
     <img src={thumbimg2 == '' || thumbimg2 == null ? img5 : img4} alt="rtf" style={{position:"absolute",top:"80px",left:"13px"}} />
     <img src={rifimg2 == '' || rifimg2 == null ? img5 : img4} alt="rif" style={{position:"absolute",top:"37px",left:"35px"}} />
     <img src={rmfimg2 == '' || rmfimg2 == null ? img5 : img4} alt="rmf" style={{position:"absolute",top:"30px",left:"65px"}} />
     <img src={rrfimg2 == '' || rrfimg2 == null ? img5 : img4} alt="rrf" style={{position:"absolute",top:"36px",left:"84px"}} />
     <img src={rlfimg2 == '' || rlfimg2 == null ? img5 : img4} alt="rlf" style={{position:"absolute",top:"55px",left:"103px"}} />
                          <img src={img1} alt="qwerty" />
                          </div>
                          <div className="d-flex flex-column align-items-center justify-content-center" style={{position:"relative"}}>
<label htmlFor="">Left Hand</label>
<img src={llfimg2 == '' || llfimg2 == null ? img5 : img4} alt="llf" style={{position:"absolute",top:"56px",left:"13px"}} />
     <img src={lrfimg2 == '' || lrfimg2 == null ? img5 : img4} alt="lrf" style={{position:"absolute",top:"36px",left:"33px"}} />
     <img src={lmfimg2 == '' || lmfimg2 == null ? img5 : img4} alt="lmf" style={{position:"absolute",top:"31px",left:"55px"}} />
     <img src={lifimg2 == '' || lifimg2 == null ? img5 : img4} alt="lif" style={{position:"absolute",top:"38px",left:"80px"}} />
     <img src={ltfimg2 == '' || ltfimg2 == null ? img5 : img4} alt="ltf" style={{position:"absolute",top:"80px",left:"102px"}} />
                          <img src={img2} alt="trew" />
                          </div>
                        </div>
                      </div>
            <div className="col-12 py-2 px-4 d-flex justify-content-between">
                <button
                  // className="btn1"
                  style={{backgroundColor:"#0C5398"}}
                  className='btn btn-primary'
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  View
                </button>
              </div>
          </div>
        </div>
        <div className="col-md-3">
        <div className="row">
            <div className="col-12"><label htmlFor="" className="form-label">Photograph </label></div>
            <div className="col-12 ">
              <div>
              <div className="border round py-3 d-flex justify-content-center">
              <button
                type='button'
                style={{border:'none',outline:'none'}}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModaltwo"
                >
              <img src={photograph == null ? img3 : photograph} alt="zxcvbnm" width="140px" height="162px" />
              </button>
            {/* <ReactImageMagnify
                            {...{
                                smallImage: {
                                    alt: 'Wristwatch by Versace',
                                    isFluidWidth: true,
                                    src: photograph == null ? img3 : photograph,
                                    width:140,
                                    height:162
                                },
                                largeImage: {
                                    src: photograph == null ? img3 : photograph,
                                    width: 836,
                                    height: 1100
                                },
                                // enlargedImagePosition: 'over',
                                lensStyle: { backgroundColor: 'rgba(0,0,0,.6)' }
                            }}
                            
                        /> */}
              {/* <img src={photograph == "" ? img3 : photograph} alt="zxcvbnm"  width="140px" height="162px"  /> */}
             </div>
              </div>
          
            </div>
          </div>
        </div>
        <div className="col-md-3">
        <div className="row">
            <div className="col-12"><label htmlFor="" className="form-label">Customer Signed </label></div>
            <div className="col-12 ">
              <div>
              <div className="border round py-3 d-flex justify-content-center">
              <button
                type='button'
                style={{border:'none',outline:'none'}}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal690"
                >
              <img src={signed == '' || signed == null ? img690 : signed} alt="zxcvbnm" width="140px" height="162px" />
              </button>
            {/* <ReactImageMagnify
                            {...{
                                smallImage: {
                                    alt: 'Wristwatch by Versace',
                                    isFluidWidth: true,
                                    src: photograph == null ? img3 : photograph,
                                    width:140,
                                    height:162
                                },
                                largeImage: {
                                    src: photograph == null ? img3 : photograph,
                                    width: 836,
                                    height: 1100
                                },
                                // enlargedImagePosition: 'over',
                                lensStyle: { backgroundColor: 'rgba(0,0,0,.6)' }
                            }}
                            
                        /> */}
              {/* <img src={photograph == "" ? img3 : photograph} alt="zxcvbnm"  width="140px" height="162px"  /> */}
             </div>
              </div>
          
            </div>
          </div>
        </div>
       </div>
       
       </div>
        {/* <div className="col-12 py-5 px-4 d-flex justify-content-end" style={{gap:'4px'}}>
          <button className='btn4'>Cancel</button>
          <button className='btn5'>Save</button>
        </div> */}
      </div>
     </div>

     {/* first one section one */}






     <div className="col-12" style={{position:'absolute',top:"-33333333333333px"}}>


    <table
    id='pdf-content'
    className='pdf-section'
      style={{
        width: "100%",
        border: "2px solid #3d3d4a",
        padding: "18px 11px",
      }}
    >
      <tr style={{ border: "3.4px solid #191941", display: "block" }}>
        <td style={{ display: "block", padding: "7px 0px 0px 7px" }}>
          <img src={img} alt="logo" style={{ width: "15%" }} />
        </td>
        <td style={{ padding: "0px 0px 5px 9px", display: "block" }}>
          <span
            style={{
              display: "inline-block",
              width: "30%",
              fontSize: "18px",
              fontWeight: 500,
            }}
          >
            KYCFirst.io
          </span>
          <span
            style={{
              width: "69%",
              display: "inline-block",
              textAlign: "end",
              fontSize: "18px",
              fontWeight: 500,
            }}
          >
            {" "}
            customer id : 393928284949329294
          </span>
        </td>
      </tr>

      <tr
        style={{
          border: "3.4px solid #191941",
          marginTop: "7px",
          display: "block",
          padding: "8px 0px 4px 0px",
        }}
      >
        <td style={{ display: "flex", padding: "7px 4px 0px 7px" }}>
          <div style={{ width: "50%", display: "flex", gap: "10px" }}>
            <span style={{ display: "inline-block", width: "26%" }}>
              This PDF is Generated
            </span>
            <input style={{ width: "65%", padding: "6px" }} type="text" />
          </div>
          <div
            style={{
              width: "50%",
              display: "flex",
              gap: "10px",
              justifyContent: "center",
            }}
          >
            <span style={{ display: "inline-block", width: "26%" }}>
              date
            </span>
            <input style={{ width: "65%", padding: "6px" }} type="text" />
          </div>
        </td>
        <td style={{ display: "flex", padding: "0px 4px 0px 7px" }}>
          <div style={{ width: "50%", display: "flex", gap: "10px" }}>
            <span style={{ display: "inline-block", width: "26%" }}>
              Corporate
            </span>
            <input style={{ width: "65%", padding: "6px" }} type="text" />
          </div>
          <div
            style={{
              width: "50%",
              display: "flex",
              gap: "10px",
              justifyContent: "center",
            }}
          >
            <span style={{ display: "inline-block", width: "26%" }}>
              Agent
            </span>
            <input style={{ width: "65%", padding: "6px" }} type="text" />
          </div>
        </td>
        <td style={{ display: "flex", padding: "0px 4px 4px 7px" }}>
          <div style={{ width: "50%", display: "flex", gap: "10px" }}>
            <span style={{ display: "inline-block", width: "26%" }}>
              User
            </span>
            <input style={{ width: "65%", padding: "6px" }} type="text" />
          </div>
        </td>
      </tr>

      <tr
        style={{
          border: "3.4px solid #191941",
          marginTop: "7px",
          display: "block",
          padding: "8px 0px 4px 0px",
        }}
      >
        <td style={{ display: "flex", padding: "7px 4px 0px 7px" }}>
          <div style={{ width: "50%", display: "flex", gap: "10px" }}>
            <span style={{ display: "inline-block", width: "59%" }}>
              This Document was first created by
            </span>
            <input style={{ width: "32%", padding: "6px" }} type="text" />
          </div>
          <div
            style={{
              width: "50%",
              display: "flex",
              gap: "10px",
              justifyContent: "center",
            }}
          >
            <span style={{ display: "inline-block", width: "26%" }}>
              date
            </span>
            <input style={{ width: "65%", padding: "6px" }} type="text" />
          </div>
        </td>
        <td style={{ display: "flex", padding: "0px 4px 0px 7px" }}>
          <div style={{ width: "50%", display: "flex", gap: "10px" }}>
            <span style={{ display: "inline-block", width: "26%" }}>
              Corporate
            </span>
            <input style={{ width: "65%", padding: "6px" }} type="text" />
          </div>
          <div
            style={{
              width: "50%",
              display: "flex",
              gap: "10px",
              justifyContent: "center",
            }}
          >
            <span style={{ display: "inline-block", width: "26%" }}>
              Agent
            </span>
            <input style={{ width: "65%", padding: "6px" }} type="text" />
          </div>
        </td>
        <td style={{ display: "flex", padding: "0px 4px 4px 7px" }}>
          <div style={{ width: "50%", display: "flex", gap: "10px" }}>
            <span style={{ display: "inline-block", width: "26%" }}>
              User
            </span>
            <input style={{ width: "65%", padding: "6px" }} type="text" />
          </div>
        </td>
      </tr>

      <tr
        style={{
          border: "3.4px solid #191941",
          marginTop: "7px",
          display: "block",
          padding: "8px 0px 4px 0px",
        }}
      >
        <td style={{ display: "flex", padding: "7px 4px 0px 7px" }}>
          <div style={{ width: "66%", display: "flex", gap: "10px" }}>
            <span style={{ display: "inline-block", width: "59%" }}>
              Customer details
            </span>
          </div>
          <div style={{ width: "33%", display: "flex", gap: "10px" }}>
            <span style={{ display: "inline-block", width: "26%" }}>
              STATUS
            </span>
            <input style={{ width: "65%", padding: "6px" }} type="text" />
          </div>
        </td>

        <td style={{ display: "flex", padding: "0px 4px 0px 6px" }}>
          <div style={{ width: "33%", display: "flex", gap: "10px" }}>
            <span style={{ display: "inline-block", width: "26%" }}>
              First Name
            </span>
            <input style={{ width: "65%", padding: "6px" }} type="text" />
          </div>
          <div style={{ width: "33%", display: "flex", gap: "10px" }}>
            <span style={{ display: "inline-block", width: "26%" }}>
              Last Name
            </span>
            <input style={{ width: "65%", padding: "6px" }} type="text" />
          </div>
          <div style={{ width: "33%", display: "flex", gap: "10px" }}>
            <span style={{ display: "inline-block", width: "26%" }}>
              NATIONALITY
            </span>
            <input style={{ width: "65%", padding: "6px" }} type="text" />
          </div>
        </td>
        <td style={{ display: "flex", padding: "0px 4px 4px 6px" }}>
          <div style={{ width: "33%", display: "flex", gap: "10px" }}>
            <span style={{ display: "inline-block", width: "26%" }}>
              GENDER
            </span>
            <input style={{ width: "65%", padding: "6px" }} type="text" />
          </div>
          <div style={{ width: "33%", display: "flex", gap: "10px" }}>
            <span style={{ display: "inline-block", width: "26%" }}>DOB</span>
            <input style={{ width: "65%", padding: "6px" }} type="text" />
          </div>
          <div style={{ width: "33%", display: "flex", gap: "10px" }}>
            <span style={{ display: "inline-block", width: "26%" }}>AGE</span>
            <input style={{ width: "65%", padding: "6px" }} type="text" />
          </div>
        </td>
        <td style={{ padding: "0px 4px 4px 7px" }}>
          <span>CURRENT ADDRESS</span>
        </td>
        <td
          style={{
            width: "100%",
            display: "flex",
            padding: "0px 4px 4px 7px",
          }}
        >
          <div style={{ width: "49%" }}>
            <div style={{ width: "100%" }}>
              <input type="text" style={{ width: "97%", padding: "6px" }} />
              <input type="text" style={{ width: "97%", padding: "6px" }} />
            </div>
            <div style={{ width: "100%" }}>
              <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "25%" }}>
                  STATE
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>

              <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "25%" }}>
                  CITY
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>

              <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "25%" }}>
                  COUNTRY
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>
              <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "25%" }}>
                  ZIPCODE
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>
            </div>
          </div>
          <div style={{ width: "49%", display: "flex" }}>
            <div style={{ border: "1px solid black", width: "50%" }}></div>
            <div style={{ border: "1px solid black", width: "50%" }}></div>
          </div>
        </td>
        <td
          style={{
            width: "100%",
            display: "flex",
            padding: "0px 4px 4px 7px",
          }}
        >
          <div style={{ width: "100%", display: "flex" }}>
            <div style={{ width: "12.5%" }}>CELL NO </div>
            <div style={{ width: "12.5%" }}>SR.NO </div>
            <div style={{ width: "12.5%" }}>CODE</div>
            <div style={{ width: "12.5%" }}>NUMBER</div>
          </div>
        </td>
        <td
          style={{
            width: "100%",
            display: "flex",
            padding: "0px 4px 4px 7px",
          }}
        >
          <div style={{ width: "12.5%" }}></div>
          <div style={{ width: "12.5%", padding: "0px 2px" }}>
            <span
              style={{
                display: "flex",
                justifyContent: "end",
                height: "32px",
              }}
            >
              1
            </span>
            <span
              style={{
                display: "flex",
                justifyContent: "end",
                height: "32px",
              }}
            >
              2
            </span>
            <span
              style={{
                display: "flex",
                justifyContent: "end",
                height: "32px",
              }}
            >
              3
            </span>
            <span
              style={{
                display: "flex",
                justifyContent: "end",
                height: "32px",
              }}
            >
              4
            </span>
            <span
              style={{
                display: "flex",
                justifyContent: "end",
                height: "32px",
              }}
            >
              5
            </span>
          </div>
          <div style={{ width: "25%" }}>
            <table
              border={1}
              cellPadding={5}
              style={{
                width: "100%",
                border: "1px solid black",
                borderCollapse: "collapse",
              }}
            >
              <tr>
                <td>code</td>
                <td>1233</td>
              </tr>
              <tr>
                <td>code</td>
                <td>1234</td>
              </tr>
              <tr>
                <td>code</td>
                <td>1234</td>
              </tr>
              <tr>
                <td>code</td>
                <td>1234</td>
              </tr>
              <tr>
                <td>code</td>
                <td>1234</td>
              </tr>
            </table>
          </div>

          <div style={{ width: "48%", padding: "32px 4px 0px 4px" }}>
            <div style={{ width: "100%", display: "flex", gap: "10px" }}>
              <span style={{ display: "inline-block", width: "25%" }}>
                DATE CHANGED
              </span>
              <input style={{ width: "70%", padding: "6px" }} type="text" />
            </div>
            <div style={{ width: "100%", display: "flex", gap: "10px" }}>
              <span style={{ display: "inline-block", width: "25%" }}>
                DATE CHANGED
              </span>
              <input style={{ width: "70%", padding: "6px" }} type="text" />
            </div>
            <div style={{ width: "100%", display: "flex", gap: "10px" }}>
              <span style={{ display: "inline-block", width: "25%" }}>
                DATE CHANGED
              </span>
              <input style={{ width: "70%", padding: "6px" }} type="text" />
            </div>
            <div style={{ width: "100%", display: "flex", gap: "10px" }}>
              <span style={{ display: "inline-block", width: "25%" }}>
                DATE CHANGED
              </span>
              <input style={{ width: "70%", padding: "6px" }} type="text" />
            </div>
          </div>
        </td>

        <td
          style={{
            width: "100%",
            display: "flex",
            padding: "13px 4px 4px 7px",
          }}
        >
          <div style={{ width: "100%", display: "flex" }}>
            <div style={{ width: "12.5%" }}>EMAIL ID NO </div>
            <div style={{ width: "12.5%" }}>SR.NO </div>
          </div>
        </td>
        <td
          style={{
            width: "100%",
            display: "flex",
            padding: "0px 4px 4px 7px",
          }}
        >
          <div style={{ width: "12.5%" }}></div>
          <div style={{ width: "12.5%", padding: "0px 2px" }}>
            <span
              style={{
                display: "flex",
                justifyContent: "end",
                height: "32px",
              }}
            >
              1
            </span>
            <span
              style={{
                display: "flex",
                justifyContent: "end",
                height: "32px",
              }}
            >
              2
            </span>
            <span
              style={{
                display: "flex",
                justifyContent: "end",
                height: "32px",
              }}
            >
              3
            </span>
            <span
              style={{
                display: "flex",
                justifyContent: "end",
                height: "32px",
              }}
            >
              4
            </span>
            <span
              style={{
                display: "flex",
                justifyContent: "end",
                height: "32px",
              }}
            >
              5
            </span>
          </div>
          <div style={{ width: "25%" }}>
            <table
              border={1}
              cellPadding={5}
              style={{
                width: "100%",
                border: "1px solid black",
                borderCollapse: "collapse",
              }}
            >
              <tr>
                <td>code</td>
                <td>1233</td>
              </tr>
              <tr>
                <td>code</td>
                <td>1234</td>
              </tr>
              <tr>
                <td>code</td>
                <td>1234</td>
              </tr>
              <tr>
                <td>code</td>
                <td>1234</td>
              </tr>
              <tr>
                <td>code</td>
                <td>1234</td>
              </tr>
            </table>
          </div>

          <div style={{ width: "48%", padding: "32px 4px 0px 4px" }}>
            <div style={{ width: "100%", display: "flex", gap: "10px" }}>
              <span style={{ display: "inline-block", width: "25%" }}>
                DATE CHANGED
              </span>
              <input style={{ width: "70%", padding: "6px" }} type="text" />
            </div>
            <div style={{ width: "100%", display: "flex", gap: "10px" }}>
              <span style={{ display: "inline-block", width: "25%" }}>
                DATE CHANGED
              </span>
              <input style={{ width: "70%", padding: "6px" }} type="text" />
            </div>
            <div style={{ width: "100%", display: "flex", gap: "10px" }}>
              <span style={{ display: "inline-block", width: "25%" }}>
                DATE CHANGED
              </span>
              <input style={{ width: "70%", padding: "6px" }} type="text" />
            </div>
            <div style={{ width: "100%", display: "flex", gap: "10px" }}>
              <span style={{ display: "inline-block", width: "25%" }}>
                DATE CHANGED
              </span>
              <input style={{ width: "70%", padding: "6px" }} type="text" />
            </div>
          </div>
        </td>

  
      </tr>





    </table>


    {/* second section part 2 */}

    <table
    className='pdf-section'
    id='pdf-content'
      style={{
        width: "100%",
        border: "2px solid #3d3d4a",
        padding: "18px 11px",
      }}
    >
      <tr style={{ border: "3.4px solid #191941", display: "block" }}>
        <td style={{ display: "block", padding: "7px 0px 0px 7px" }}>
          <img src={img} alt="logo" style={{ width: "15%" }} />
        </td>
        <td style={{ padding: "0px 0px 5px 9px", display: "block" }}>
          <span
            style={{
              display: "inline-block",
              width: "30%",
              fontSize: "18px",
              fontWeight: 500,
            }}
          >
            KYCFirst.io
          </span>
          <span
            style={{
              width: "69%",
              display: "inline-block",
              textAlign: "end",
              fontSize: "18px",
              fontWeight: 500,
            }}
          >
            {" "}
            customer id : 393928284949329294
          </span>
        </td>
      </tr>



      <tr
        style={{
          border: "3.4px solid #191941",
          marginTop: "7px",
          display: "block",
          padding: "8px 0px 4px 0px",
        }}
      >
        
        <td
          style={{
            width: "97.6%",
            display: "flex",
            // border:'1px solid black',
            margin: "0 auto",
          }}
        >
          <div
            style={{
              width: "50%",
              border: "1px solid black",
              padding: "3px 2px 0px 2px",
            }}
          >
            <div style={{ width: "100%", display: "flex", gap: "10px" }}>
              <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "27%" }}>
                  DOCTYPE
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>

              <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "27%" }}>
                  NUMBER
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>
            </div>
            <div style={{ width: "100%", display: "flex", gap: "10px" }}>
              <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "27%" }}>
                  ISSUE DT
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>

              <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "27%" }}>
                  EXP DT
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <span>ADDED BY CORP-AGT-USER</span>
            </div>
            <div style={{ width: "100%", display: "flex", height: "200px" }}>
              <div style={{ width: "50%", border: "1px solid black" }}>
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  VALID DOC 1 FRONT
                </span>
              </div>
              <div style={{ width: "50%", border: "1px solid black" }}>
                <span>VALID DOC 1 BACK</span>
              </div>
            </div>
          </div>

          <div
            style={{
              width: "50%",
              border: "1px solid black",
              padding: "2px 2px 0px 2px",
            }}
          >
            <div style={{ width: "100%", display: "flex", gap: "10px" }}>
              <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "27%" }}>
                  DOCTYPE
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>

              <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "27%" }}>
                  NUMBER
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>
            </div>
            <div style={{ width: "100%", display: "flex", gap: "10px" }}>
              <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "27%" }}>
                  ISSUE DT
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>

              <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "27%" }}>
                  EXP DT
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <span>ADDED BY CORP-AGT-USER</span>
            </div>
            <div style={{ width: "100%", display: "flex" }}>
              <div
                style={{
                  width: "50%",
                  border: "1px solid black",
                  height: "200px",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  VALID DOC 1 FRONT
                </span>
              </div>
              <div style={{ width: "50%", border: "1px solid black" }}>
                <span>VALID DOC 1 BACK</span>
              </div>
            </div>
          </div>
        </td>

        <td style={{ padding: "5px 4px 4px 7px" }}>
          <span>CUSTOMERS DOCUMENTS UPDATED RECORD</span>
        </td>

        <td
          style={{
            width: "97.6%",
            display: "flex",
            // border:'1px solid black',
            margin: "0 auto",
          }}
        >
          <div
            style={{
              width: "50%",
              border: "1px solid black",
              padding: "4px 2px 0px 2px",
            }}
          >
            <div style={{ width: "100%", display: "flex", gap: "10px" }}>
              <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "27%" }}>
                  DOCTYPE
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>

              <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "27%" }}>
                  NUMBER
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>
            </div>
            <div style={{ width: "100%", display: "flex", gap: "10px" }}>
              <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "27%" }}>
                  ISSUE DT
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>

              <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "27%" }}>
                  EXP DT
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <span>ADDED BY CORP-AGT-USER</span>
            </div>
            <div style={{ width: "100%", display: "flex", height: "200px" }}>
              <div style={{ width: "50%", border: "1px solid black" }}>
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  VALID DOC 1 FRONT
                </span>
              </div>
              <div style={{ width: "50%", border: "1px solid black" }}>
                <span>VALID DOC 1 BACK</span>
              </div>
            </div>
          </div>

          <div
            style={{
              width: "50%",
              border: "1px solid black",
              padding: "3px 2px 0px 2px",
            }}
          >
            <div style={{ width: "100%", display: "flex", gap: "10px" }}>
              <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "27%" }}>
                  DOCTYPE
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>

              <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "27%" }}>
                  NUMBER
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>
            </div>
            <div style={{ width: "100%", display: "flex", gap: "10px" }}>
              <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "27%" }}>
                  ISSUE DT
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>

              <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "27%" }}>
                  EXP DT
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <span>ADDED BY CORP-AGT-USER</span>
            </div>
            <div style={{ width: "100%", display: "flex" }}>
              <div
                style={{
                  width: "50%",
                  border: "1px solid black",
                  height: "200px",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  VALID DOC 1 FRONT
                </span>
              </div>
              <div style={{ width: "50%", border: "1px solid black" }}>
                <span>VALID DOC 1 BACK</span>
              </div>
            </div>
          </div>
        </td>

        <td
          style={{
            paddingTop: "20px",
            width: "97.6%",
            display: "flex",
            margin: "0 auto",
          }}
        >
          <div
            style={{
              width: "50%",
              border: "1px solid black",
              padding: "4px 2px 0px 2px",
            }}
          >
            <div style={{ width: "100%", display: "flex", gap: "10px" }}>
              <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "27%" }}>
                  DOCTYPE
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>

              <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "27%" }}>
                  NUMBER
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>
            </div>
            <div style={{ width: "100%", display: "flex", gap: "10px" }}>
              <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "27%" }}>
                  ISSUE DT
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>

              <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "27%" }}>
                  EXP DT
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <span>ADDED BY CORP-AGT-USER</span>
            </div>
            <div style={{ width: "100%", display: "flex", height: "200px" }}>
              <div style={{ width: "50%", border: "1px solid black" }}>
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  VALID DOC 1 FRONT
                </span>
              </div>
              <div style={{ width: "50%", border: "1px solid black" }}>
                <span>VALID DOC 1 BACK</span>
              </div>
            </div>
          </div>

          <div
            style={{
              width: "50%",
              border: "1px solid black",
              padding: "3px 2px 0px 2px",
            }}
          >
            <div style={{ width: "100%", display: "flex", gap: "10px" }}>
              <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "27%" }}>
                  DOCTYPE
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>

              <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "27%" }}>
                  NUMBER
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>
            </div>
            <div style={{ width: "100%", display: "flex", gap: "10px" }}>
              <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "27%" }}>
                  ISSUE DT
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>

              <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "27%" }}>
                  EXP DT
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <span>ADDED BY CORP-AGT-USER</span>
            </div>
            <div style={{ width: "100%", display: "flex" }}>
              <div
                style={{
                  width: "50%",
                  border: "1px solid black",
                  height: "200px",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  VALID DOC 1 FRONT
                </span>
              </div>
              <div style={{ width: "50%", border: "1px solid black" }}>
                <span>VALID DOC 1 BACK</span>
              </div>
            </div>
          </div>
        </td>
      </tr>




      {/* <tr
        style={{
          border: "3.4px solid #191941",
          marginTop: "7px",
          display: "block",
          padding: "8px 0px 4px 0px",
        }}
      >
        <td style={{ display: "flex", padding: "7px 4px 0px 7px" }}>
          <div style={{ width: "97.6%",paddingTop:'10px',margin:'0 auto' }}>
            <span style={{ display: "inline-block", width: "100%" }}>
              ID USED FOR
            </span>
          </div>
        </td>




        <td
          style={{
            paddingTop: "20px",
            width: "97.6%",
            display: "flex",
            margin: "0 auto",
          }}
        >
          <div
            style={{
              width: "100%",
              border: "1px solid black",
              padding: "4px 2px 0px 2px",
            }}
          >
        <div style={{width:'98.9%',margin:'0 auto' }}>
          <span>SR.NO</span>
        </div>
        <div style={{display:'flex',width:'98.9%',margin:'0 auto',gap:'5px',paddingBottom:"6px" }}>
          <div style={{width:"5%"}}>
            11
          </div>
          <div style={{width:'38%'}}>
          <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "24%" }}>
                  PRODUCT
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>
              <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "24%" }}>
                  OTP
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>
              <div style={{ width: "100%", display: "flex", gap: "0px" }}>
                <span style={{ display: "inline-block", width: "24%",border:'1px solid black' }}>
                  CORP
                </span>
                <input style={{ width: "70%", padding: "6px" }} value="AGT" type="text" />
              </div>  
          </div>
          <div style={{width:'56%',display:'flex',gap:'4px'}}>
         <div style={{width:'63%',display:"flex"}}>
          <span>
          DETAIL BIO USER BIO DATA
          </span>
          <div style={{border:"1px solid black",width:'80%',height:'200px'}}>

          </div>
         </div>

         <div style={{width:'35%',display:"flex",gap:'4px'}}>
          <span>
         Country
          </span>
        <input type="text" style={{height:'24px'}} />
         </div>
          </div>
         
        </div>
        
          </div>


          
        </td>


        <td
          style={{
            paddingTop: "20px",
            width: "97.6%",
            display: "flex",
            margin: "0 auto",
          }}
        >
          <div
            style={{
              width: "100%",
              border: "1px solid black",
              padding: "4px 2px 0px 2px",
            }}
          >
        <div style={{width:'98.9%',margin:'0 auto' }}>
          <span>SR.NO</span>
        </div>
        <div style={{display:'flex',width:'98.9%',margin:'0 auto',gap:'5px',paddingBottom:"6px" }}>
          <div style={{width:"5%"}}>
            11
          </div>
          <div style={{width:'38%'}}>
          <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "24%" }}>
                  PRODUCT
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>
              <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "24%" }}>
                  OTP
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>
              <div style={{ width: "100%", display: "flex", gap: "0px" }}>
                <span style={{ display: "inline-block", width: "24%",border:'1px solid black' }}>
                  CORP
                </span>
                <input style={{ width: "70%", padding: "6px" }} value="AGT" type="text" />
              </div>  
          </div>
          <div style={{width:'56%',display:'flex',gap:'4px'}}>
         <div style={{width:'63%',display:"flex"}}>
          <span>
          DETAIL BIO USER BIO DATA
          </span>
          <div style={{border:"1px solid black",width:'80%',height:'200px'}}>

          </div>
         </div>

         <div style={{width:'35%',display:"flex",gap:'4px'}}>
          <span>
         Country
          </span>
        <input type="text" style={{height:'24px'}} />
         </div>
          </div>
         
        </div>
        
          </div>


          
        </td>



        <td
          style={{
            paddingTop: "20px",
            width: "97.6%",
            display: "flex",
            margin: "0 auto",
          }}
        >
          <div
            style={{
              width: "100%",
              border: "1px solid black",
              padding: "4px 2px 0px 2px",
            }}
          >
        <div style={{width:'98.9%',margin:'0 auto' }}>
          <span>SR.NO</span>
        </div>
        <div style={{display:'flex',width:'98.9%',margin:'0 auto',gap:'5px',paddingBottom:"6px" }}>
          <div style={{width:"5%"}}>
            11
          </div>
          <div style={{width:'38%'}}>
          <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "24%" }}>
                  PRODUCT
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>
              <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "24%" }}>
                  OTP
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>
              <div style={{ width: "100%", display: "flex", gap: "0px" }}>
                <span style={{ display: "inline-block", width: "24%",border:'1px solid black' }}>
                  CORP
                </span>
                <input style={{ width: "70%", padding: "6px" }} value="AGT" type="text" />
              </div>  
          </div>
          <div style={{width:'56%',display:'flex',gap:'4px'}}>
         <div style={{width:'63%',display:"flex"}}>
          <span>
          DETAIL BIO USER BIO DATA
          </span>
          <div style={{border:"1px solid black",width:'80%',height:'200px'}}>

          </div>
         </div>

         <div style={{width:'35%',display:"flex",gap:'4px'}}>
          <span>
         Country
          </span>
        <input type="text" style={{height:'24px'}} />
         </div>
          </div>
         
        </div>
        
          </div>


          
        </td>
      </tr> */}
    </table>





    {/* third section part 3 */}



    <table
    className='pdf-section'
    id='pdf-content'
      style={{
        width: "100%",
        border: "2px solid #3d3d4a",
        padding: "18px 11px",
      }}
    >
      <tr style={{ border: "3.4px solid #191941", display: "block" }}>
        <td style={{ display: "block", padding: "7px 0px 0px 7px" }}>
          <img src={img} alt="logo" style={{ width: "15%" }} />
        </td>
        <td style={{ padding: "0px 0px 5px 9px", display: "block" }}>
          <span
            style={{
              display: "inline-block",
              width: "30%",
              fontSize: "18px",
              fontWeight: 500,
            }}
          >
            KYCFirst.io
          </span>
          <span
            style={{
              width: "69%",
              display: "inline-block",
              textAlign: "end",
              fontSize: "18px",
              fontWeight: 500,
            }}
          >
            {" "}
            customer id : 393928284949329294
          </span>
        </td>
      </tr>



  



      <tr
        style={{
          border: "3.4px solid #191941",
          marginTop: "7px",
          display: "block",
          padding: "8px 0px 4px 0px",
        }}
      >
        <td style={{ display: "flex", padding: "7px 4px 0px 7px" }}>
          <div style={{ width: "97.6%",paddingTop:'10px',margin:'0 auto' }}>
            <span style={{ display: "inline-block", width: "100%" }}>
              ID USED FOR
            </span>
          </div>
        </td>




        <td
          style={{
            paddingTop: "20px",
            width: "97.6%",
            display: "flex",
            margin: "0 auto",
          }}
        >
          <div
            style={{
              width: "100%",
              border: "1px solid black",
              padding: "4px 2px 0px 2px",
            }}
          >
        <div style={{width:'98.9%',margin:'0 auto' }}>
          <span>SR.NO</span>
        </div>
        <div style={{display:'flex',width:'98.9%',margin:'0 auto',gap:'5px',paddingBottom:"6px" }}>
          <div style={{width:"5%"}}>
            11
          </div>
          <div style={{width:'38%'}}>
          <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "24%" }}>
                  PRODUCT
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>
              <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "24%" }}>
                  OTP
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>
              <div style={{ width: "100%", display: "flex", gap: "0px" }}>
                <span style={{ display: "inline-block", width: "24%",border:'1px solid black' }}>
                  CORP
                </span>
                <input style={{ width: "70%", padding: "6px" }} value="AGT" type="text" />
              </div>  
          </div>
          <div style={{width:'56%',display:'flex',gap:'4px'}}>
         <div style={{width:'63%',display:"flex"}}>
          <span>
          DETAIL BIO USER BIO DATA
          </span>
          <div style={{border:"1px solid black",width:'80%',height:'200px'}}>

          </div>
         </div>

         <div style={{width:'35%',display:"flex",gap:'4px'}}>
          <span>
         Country
          </span>
        <input type="text" style={{height:'24px'}} />
         </div>
          </div>
         
        </div>
        
          </div>


          
        </td>


        <td
          style={{
            paddingTop: "20px",
            width: "97.6%",
            display: "flex",
            margin: "0 auto",
          }}
        >
          <div
            style={{
              width: "100%",
              border: "1px solid black",
              padding: "4px 2px 0px 2px",
            }}
          >
        <div style={{width:'98.9%',margin:'0 auto' }}>
          <span>SR.NO</span>
        </div>
        <div style={{display:'flex',width:'98.9%',margin:'0 auto',gap:'5px',paddingBottom:"6px" }}>
          <div style={{width:"5%"}}>
            11
          </div>
          <div style={{width:'38%'}}>
          <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "24%" }}>
                  PRODUCT
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>
              <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "24%" }}>
                  OTP
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>
              <div style={{ width: "100%", display: "flex", gap: "0px" }}>
                <span style={{ display: "inline-block", width: "24%",border:'1px solid black' }}>
                  CORP
                </span>
                <input style={{ width: "70%", padding: "6px" }} value="AGT" type="text" />
              </div>  
          </div>
          <div style={{width:'56%',display:'flex',gap:'4px'}}>
         <div style={{width:'63%',display:"flex"}}>
          <span>
          DETAIL BIO USER BIO DATA
          </span>
          <div style={{border:"1px solid black",width:'80%',height:'200px'}}>

          </div>
         </div>

         <div style={{width:'35%',display:"flex",gap:'4px'}}>
          <span>
         Country
          </span>
        <input type="text" style={{height:'24px'}} />
         </div>
          </div>
         
        </div>
        
          </div>


          
        </td>



        <td
          style={{
            paddingTop: "20px",
            width: "97.6%",
            display: "flex",
            margin: "0 auto",
          }}
        >
          <div
            style={{
              width: "100%",
              border: "1px solid black",
              padding: "4px 2px 0px 2px",
            }}
          >
        <div style={{width:'98.9%',margin:'0 auto' }}>
          <span>SR.NO</span>
        </div>
        <div style={{display:'flex',width:'98.9%',margin:'0 auto',gap:'5px',paddingBottom:"6px" }}>
          <div style={{width:"5%"}}>
            11
          </div>
          <div style={{width:'38%'}}>
          <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "24%" }}>
                  PRODUCT
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>
              <div style={{ width: "100%", display: "flex", gap: "10px" }}>
                <span style={{ display: "inline-block", width: "24%" }}>
                  OTP
                </span>
                <input style={{ width: "70%", padding: "6px" }} type="text" />
              </div>
              <div style={{ width: "100%", display: "flex", gap: "0px" }}>
                <span style={{ display: "inline-block", width: "24%",border:'1px solid black' }}>
                  CORP
                </span>
                <input style={{ width: "70%", padding: "6px" }} value="AGT" type="text" />
              </div>  
          </div>
          <div style={{width:'56%',display:'flex',gap:'4px'}}>
         <div style={{width:'63%',display:"flex"}}>
          <span>
          DETAIL BIO USER BIO DATA
          </span>
          <div style={{border:"1px solid black",width:'80%',height:'200px'}}>

          </div>
         </div>

         <div style={{width:'35%',display:"flex",gap:'4px'}}>
          <span>
         Country
          </span>
        <input type="text" style={{height:'24px'}} />
         </div>
          </div>
         
        </div>
        
          </div>


          
        </td>
      </tr>
    </table>
    </div>

      </div>
      </div>}
      </div>
      <div className="modal fade" id="exampleModal34" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>


  
  <div className="col-12 px-2 pt-3">
  <img src={formdatao.customer_docImg_front == null ? img3 : formdatao.customer_docImg_front} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>
          <div className="modal fade" id="exampleModal35" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>


  
  <div className="col-12 px-2 pt-3">
  <img src={formdatao.customer_docImg_back == null ? img3 : formdatao.customer_docImg_back} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>
          <div className="modal fade" id="exampleModal690" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>
                <div className="col-12 px-2 pt-3">
  <img src={signed == '' || signed == null ? img690 : signed} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
              </div>
            </div>
          </div>  
      <div className="modal fade" id="exampleModalthree" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>
                <div className="col-12 px-2 pt-3">
  <img src={customerphotograph == null ? img3 : customerphotograph} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
              </div>
            </div>
          </div>  
      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                  <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>

                  <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="tf2" className="form-label ">Right Thumb  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" class="form-control" id='tf2' name="image2" style={{opacity:"0",position:'absolute'}}  onChange={(e) => {handleImageChange11(e)}}  /> */}
                          {/* <label htmlFor="tf"> */}
                          <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: thumbimg2 != '' && thumbimg2 != null ? '' : '3rem',width:"20%" }}> {thumbimg2 != '' && thumbimg2 != null ? <img src={`data:image/png;base64,${thumbimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
                          {/* {thumbimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''} */}

                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="ltf2" className="form-label ">Left Thumb  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="ltf2" onChange={(e) => {handleImageChange16(e)}} /> */}
                          <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: ltfimg2 != '' && ltfimg2 != null ? '' : '3rem',width:"20%" }}> {ltfimg2 != '' && ltfimg2 != null ? <img src={`data:image/png;base64,${ltfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
                          {/* {ltfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''} */}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="rif2" className="form-label ">Right Index Finger <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rif2" onChange={(e) => {handleImageChange12(e)}} /> */}
                          <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rifimg2 != '' && rifimg2 != null ? '' : '3rem',width:"20%" }}> {rifimg2 != '' && rifimg2 != null ? <img src={`data:image/png;base64,${rifimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
                          {/* {rifimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''} */}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="lif2" className="form-label ">Left Index Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lif2" onChange={(e) => {handleImageChange17(e)}} /> */}
                          <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lifimg2 != '' && lifimg2 != null ? '' : '3rem',width:"20%" }}> {lifimg2 != '' && lifimg2 != null ? <img src={`data:image/png;base64,${lifimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
                          {/* {lifimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''} */}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="rmf2" className="form-label ">Right Middle Finger<span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rmf2" onChange={(e) => {handleImageChange13(e)}} /> */}
                          <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rmfimg2 != '' && rmfimg2 != null ? '' : '3rem',width:"20%" }}> {rmfimg2 != '' && rmfimg2 != null ? <img src={`data:image/png;base64,${rmfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
                          {/* {rmfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''} */}
                        </div>
                      </div>

                    </div>
                    <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="lmf2" className="form-label ">Left Middle Finger <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lmf2" onChange={(e) => {handleImageChange18(e)}} /> */}
                          <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lmfimg2 != '' && lmfimg2 != null ? '' : '3rem',width:"20%" }}> {lmfimg2 != '' && lmfimg2 != null ? <img src={`data:image/png;base64,${lmfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
                          {/* {lmfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''} */}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="rrf2" className="form-label ">Right Ring Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rrf2" onChange={(e) => {handleImageChange14(e)}} /> */}
                          <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rrfimg2 != '' && rrfimg2 != null ? '' : '3rem',width:"20%" }}> {rrfimg2 != '' && rrfimg2 != null ? <img src={`data:image/png;base64,${rrfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
                          {/* {rrfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''} */}
                        </div>
                      </div>

                    </div>
                    <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="lrf2" className="form-label ">Left Ring Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lrf2" onChange={(e) => {handleImageChange19(e)}} /> */}
                          <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: lrfimg2 != '' && lrfimg2 != null ? '' : '3rem',width:"20%" }}> {lrfimg2 != '' && lrfimg2 != null ? <img src={`data:image/png;base64,${lrfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
                          {/* {lrfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''} */}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="rlf2" className="form-label ">Right Little Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rlf2" onChange={(e) => {handleImageChange15(e)}} /> */}
                          <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: rlfimg2 != '' && rlfimg2 != null ? '' : '3rem',width:"20%" }}> {rlfimg2 != '' && rlfimg2 != null ? <img src={`data:image/png;base64,${rlfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
                          {/* {rlfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''} */}
                        </div>
                      </div>
                    </div>
               
        
           
  
                    <div className="col-lg-6 px-2 pt-3">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
                          <label htmlFor="llf2" className="form-label ">Left Little Finger  <span style={{ color: 'red' }}>*</span></label>
                          {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="llf2" onChange={(e) => {handleImageChange20(e)}} /> */}
                          <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: llfimg2 != '' && llfimg2 != null ? '' : '3rem',width:"20%" }}> {llfimg2 != '' && llfimg2 != null ? <img src={`data:image/png;base64,${llfimg2}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
                          {/* {llfimg2 != '' ? <img src={checkicon} width="25px" height="25px" alt="asd" /> : ''} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* {grandresponse.status === 201 ? nvg('/Remittancelist/1') : <div></div>} */}
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>
          <div className="modal fade" id="exampleModaltwo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>
                <div className="col-12 px-2 pt-3">
  <img src={photograph == null ? img3 : photograph} style={{width:"100%",height:"100%"}} alt="photograph" />
  </div>
</div>
                </div>
              </div>
            </div>
          </div>  
             <div className="modal fade" id="exampleModal3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header mod-line">
                  {/* <h5 className="modal-title" id="exampleModalLabel"></h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                <div className="row bg-white round" style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: '3rem' }}>

<div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="tf2" className="form-label ">Right Thumb  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" class="form-control" id='tf2' name="image2" style={{opacity:"0",position:'absolute'}}  onChange={(e) => {handleImageChange11(e)}}  /> */}
        {/* <label htmlFor="tf"> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: formdatao.r_thumb != '' && formdatao.r_thumb != null ? '' : '3rem',width:"20%" }}> {formdatao.r_thumb != '' && formdatao.r_thumb != null ? <img src={`data:image/png;base64,${formdatao.r_thumb}`} width="70px" height="60px" alt="asd" /> : ''}  </div>

      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="ltf2" className="form-label ">Left Thumb  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="ltf2" onChange={(e) => {handleImageChange16(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: formdatao.l_thumb != '' && formdatao.l_thumb != null ? '' : '3rem',width:"20%" }}> {formdatao.l_thumb != '' && formdatao.l_thumb != null ? <img src={`data:image/png;base64,${formdatao.l_thumb}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rif2" className="form-label ">Right Index Finger <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rif2" onChange={(e) => {handleImageChange12(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: formdatao.r_index_finger != '' && formdatao.r_index_finger != null ? '' : '3rem',width:"20%" }}> {formdatao.r_index_finger != '' && formdatao.r_index_finger != null ? <img src={`data:image/png;base64,${formdatao.r_index_finger}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="lif2" className="form-label ">Left Index Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lif2" onChange={(e) => {handleImageChange17(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: formdatao.l_index_finger != '' && formdatao.l_index_finger != null ? '' : '3rem',width:"20%" }}> {formdatao.l_index_finger != '' && formdatao.l_index_finger != null ? <img src={`data:image/png;base64,${formdatao.l_index_finger}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rmf2" className="form-label ">Right Middle Finger<span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"205555555"}} id="rmf2" onChange={(e) => {handleImageChange13(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: formdatao.r_middle_finger != '' && formdatao.r_middle_finger != null ? '' : '3rem',width:"20%" }}> {formdatao.r_middle_finger != '' && formdatao.r_middle_finger != null ? <img src={`data:image/png;base64,${formdatao.r_middle_finger}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      </div>
    </div>

  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="lmf2" className="form-label ">Left Middle Finger <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lmf2" onChange={(e) => {handleImageChange18(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: formdatao.l_middle_finger != '' && formdatao.l_middle_finger != null ? '' : '3rem',width:"20%" }}> {formdatao.l_middle_finger != '' && formdatao.l_middle_finger != null ? <img src={`data:image/png;base64,${formdatao.l_middle_finger}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
        
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rrf2" className="form-label ">Right Ring Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rrf2" onChange={(e) => {handleImageChange14(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: formdatao.r_ring_finger != '' && formdatao.r_ring_finger != null ? '' : '3rem',width:"20%" }}> {formdatao.r_ring_finger != '' && formdatao.r_ring_finger != null ? <img src={`data:image/png;base64,${formdatao.r_ring_finger}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
       
      </div>
    </div>

  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="lrf2" className="form-label ">Left Ring Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="lrf2" onChange={(e) => {handleImageChange19(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: formdatao.l_ring_finger != '' && formdatao.l_ring_finger != null ? '' : '3rem',width:"20%" }}> {formdatao.l_ring_finger != '' && formdatao.l_ring_finger != null ? <img src={`data:image/png;base64,${formdatao.l_ring_finger}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
        
      </div>
    </div>
  </div>
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="rlf2" className="form-label ">Right Little Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="rlf2" onChange={(e) => {handleImageChange15(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: formdatao.r_little_finger != '' && formdatao.r_little_finger != null ? '' : '3rem',width:"20%" }}> {formdatao.r_little_finger != '' && formdatao.r_little_finger != null ? <img src={`data:image/png;base64,${formdatao.r_little_finger}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
     
      </div>
    </div>
  </div>


 
 
  <div className="col-lg-6 px-2 pt-3">
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-between" style={{gap:"15px"}}>
        <label htmlFor="llf2" className="form-label ">Left Little Finger  <span style={{ color: 'red' }}>*</span></label>
        {/* <input type="file" className="form-control" style={{opacity:"0",position:'absolute',left:"2035555555"}} id="llf2" onChange={(e) => {handleImageChange20(e)}} /> */}
        <div style={{ border: '1px solid #E0E0E0', margin: "0px 0px", borderRadius: '3px', paddingBottom: formdatao.l_little_finger != '' && formdatao.l_little_finger != null ? '' : '3rem',width:"20%" }}> {formdatao.l_little_finger != '' && formdatao.l_little_finger != null ? <img src={`data:image/png;base64,${formdatao.l_little_finger}`} width="70px" height="60px" alt="asd" /> : ''}  </div>
      
      </div>
    </div>
  </div>
</div>
                </div>
                {/* <div className="modal-footer mod-line m-auto">
                  <button type="button" className="btn closecancel">Cancel</button>
                  <button type="button" className="btn closebtn text-white">Submit</button>
                </div> */}
              </div>
            </div>
          </div>
    </div>
  )
}

export default Cremittancedetail